import React from 'react';
import useScrollToHash from 'src/hooks/useScrollToHash';
import About from './components/About';
import CompanyHero from './components/CompanyHero';
import LandingFooter from './components/LandingFooter';
import LandingHeader from './components/LandingHeader';
import LatestNews from './components/LatestNews';
import MeetTheTeam from './components/MeetTheTeam';

function Company() {
  useScrollToHash();

  return (
    <div className="App landing-page">
      <LandingHeader />
      <CompanyHero />
      <About />
      <MeetTheTeam />
      <LatestNews />
      <LandingFooter />
    </div>
  );
}

export default Company;
