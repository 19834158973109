import React from 'react';
import { Skeleton } from 'antd';

function NoData() {
  return (
    <div className="no-data" data-testid="no-data">
      <Skeleton />;
    </div>
  );
}

export default NoData;
