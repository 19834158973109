import { Radio } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export default function FormFilter() {
  const [formFilter, setFormFilter] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  return (
    <Radio.Group
      value={formFilter}
      onChange={({ target }) => setFormFilter(target.value)}
      size="small"
      className="form-filter"
    >
      <Radio.Button value="resin_pellets">Resin / Pellets</Radio.Button>
      <Radio.Button value="bales">Bales</Radio.Button>
      <Radio.Button value="regrind_flakes">Flakes</Radio.Button>
    </Radio.Group>
  );
}
