import React, { useMemo } from 'react';

import { Button, Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getMarketPriceLatestRaw } from 'src/Query/marketPrice';
import { Link } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadAntdTable } from 'src/hooks/useDownloadAntdTable';

function CircularIndices() {
  const { data, isLoading: isActivityLoading } = useQuery(
    ['market-price-latest', 'circular'],
    () => getMarketPriceLatestRaw({ circular: true, all: true, rank: 1 })
  );
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'source_name',
        sorter: (a, b) =>
          a.source_name ? a.source_name.localeCompare(b.source_name) : -1,
        render: (d, record) => (
          <Link
            to={`/marketprice/cohort?market_price_ticker_id=${record.market_price_ticker_id}`}
          >
            {d}
          </Link>
        )
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : -1)
      },
      {
        title: 'Form',
        dataIndex: 'form',
        sorter: (a, b) => (a.form ? a.form.localeCompare(b.form) : -1)
      },
      {
        title: 'Region',
        dataIndex: 'region',
        sorter: (a, b) => (a.region ? a.region.localeCompare(b.region) : -1)
      },
      {
        title: 'Latest timestamp',
        dataIndex: 'timestamp',
        sorter: (a, b) =>
          a.timestamp ? a.timestamp.localeCompare(b.timestamp) : -1
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        sorter: (a, b) =>
          a.currency ? a.currency.localeCompare(b.currency) : -1
      },
      {
        title: 'High',
        dataIndex: 'high',
        sorter: (a, b) => (a.high ? a.high - b.high : -1)
      },
      {
        title: 'Low',
        dataIndex: 'low',
        sorter: (a, b) => (a.low ? a.low - b.low : -1)
      },
      {
        title: 'Units',
        dataIndex: 'units',
        sorter: (a, b) => (a.units ? a.units.localeCompare(b.units) : -1)
      },
      {
        title: 'Country',
        dataIndex: 'country_name',
        sorter: (a, b) =>
          a.country_name ? a.country_name.localeCompare(b.country_name) : -1
      },
      {
        title: 'City',
        dataIndex: 'city',
        sorter: (a, b) => (a.city ? a.city.localeCompare(b.city) : -1)
      }
    ],
    []
  );
  const download = useDownloadAntdTable(columns, data, `circular-indices`);

  return (
    <div className="p-xl">
      <Button onClick={download}>
        <DownloadOutlined />
      </Button>

      <Table pagination={false} dataSource={data} columns={columns} />
    </div>
  );
}

export default CircularIndices;
