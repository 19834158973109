import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getSearchFiltered } from 'src/Query/search';
import { SearchOutlined } from '@ant-design/icons';
import SearchResults from './SearchResults';

const { useForm } = Form;

/* eslint-disable no-underscore-dangle */

function SearchFilteredPage({ filters }) {
  const group_by = 'url';
  const [query, setQuery] = useState();
  const { data, isFetching, refetch } = useQuery(
    ['search', query],
    () => getSearchFiltered({ semantic_search: query, ...filters }),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false
    }
  );
  const [form] = useForm();
  useEffect(() => {
    setQuery();
    form.resetFields();
  }, [filters]);
  const search = (values) => {
    setQuery(values.search);
  };
  const onPressEnter = () => {
    form.submit();
  };
  useEffect(() => {
    if (query) refetch();
  }, [query]);

  return (
    <div className="search-page">
      <Form name="basic" onFinish={search}>
        <Form.Item
          name="search"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            onPressEnter={onPressEnter}
            prefix={<SearchOutlined />}
            placeholder="Search Circular"
          />
        </Form.Item>
      </Form>
      <SearchResults data={query && data} isFetching={isFetching} />
    </div>
  );
}

SearchFilteredPage.propTypes = {
  filters: PropTypes.object
};

export default SearchFilteredPage;
