import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Button, Select, Dropdown, Modal, Tooltip } from 'antd';
import {
  BlockOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileTextFilled,
  FilterOutlined,
  LinkOutlined,
  LoginOutlined,
  MessageOutlined,
  SettingFilled
} from '@ant-design/icons';
import { sentenceCase } from 'change-case';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { evaluateSupplier, patchRFQ } from 'src/Mutation';
import useGetRfq from 'src/Query/useGetRfq';
import useMetaProperty from 'src/hooks/useMetaProperty';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import optimalShippingRoute from 'src/utils/optimalShippingRoute';
import shippingEmmissions from 'src/utils/shippingEmissions';
import prettyNumber from 'src/components/utils/prettyNumber';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import TechnicalDataSheet from 'src/pages/supplier/TechnicalDataSheet';
import ShippingRoute from 'src/pages/concierge/ProposalBuilder/ShippingRoute';
import Sorter from 'src/pages/concierge/ProposalBuilder/Sorter';
import useProposalBuilderFilters, {
  getLatestSearchFromLocalStorage
} from 'src/pages/concierge/ProposalBuilder/useProposalBuilderFilters';
import SourceSingleFilled from 'src/components/icons/SourceSingleFilled';
import SourceSingleOutlined from 'src/components/icons/SourceSingleOutlined';
import SourceMultipleFilled from 'src/components/icons/SourceMultipleFilled';
import SourceMultipleOutlined from 'src/components/icons/SourceMultipleOutlined';

import propertyColumn from './propertyColumn';
import numericalPropertyColumn from './numericalPropertyColumn';

import 'src/pages/concierge/Concierge.less';

export const sortableColumnTitle = (title, sortKey) => {
  function SortableColumnTitle({ sortOrder, sortColumn }) {
    return (
      <>
        {title}&nbsp;
        <Sorter
          sortOrder={
            isSortedColumn(sortKey, sortColumn) ? sortOrder : undefined
          }
        />
      </>
    );
  }
  SortableColumnTitle.propTypes = {
    sortOrder: PropTypes.string,
    sortColumn: PropTypes.string
  };

  return SortableColumnTitle;
};

const evaluationToStatus = (evaluation) => {
  if (!evaluation) return undefined;
  const status = evaluation.peak_status;
  if (status)
    return {
      status,
      notes: evaluation[`${status}_notes`],
      timestamp: evaluation[`${status}_at`],
      disqualified: evaluation.disqualified
    };
  return undefined;
};

const leftColumns = (anonymize, supplierEvaluations, rfqFilter) => {
  const ordering = getLatestSearchFromLocalStorage(rfqFilter)?.ordering;
  return [
    {
      title: sortableColumnTitle(
        'Supplier name',
        anonymize ? 'anonymized_name' : 'name'
      ),
      sortKey: 'company__name',
      sorter: true,
      defaultSortOrder: ordering?.includes('company__name')
        ? ordering.startsWith('-')
          ? 'descend'
          : 'ascend'
        : null,
      dataIndex: anonymize ? 'anonymized_name' : 'name',
      fixed: 'left',
      width: 200,
      render: (supplier_name, supplierOrTds) => {
        if (Object.keys(supplierOrTds).includes('tdss')) {
          // Supplier row (parent row)
          const supplier = supplierOrTds;
          return (
            <>
              <span className="company">
                {supplier.website ? (
                  <a
                    href={supplier.website}
                    title={supplier_name}
                    target="_blank"
                  >
                    {supplier_name}
                  </a>
                ) : (
                  supplier_name
                )}
                &nbsp;
                <SupplierDataSourceIndicator
                  supplier={supplier}
                  rfq={rfqFilter}
                />
              </span>
              <span className="subcontent">
                {filterLocationsArray(
                  supplier?.locations.map((loc) => locationRender(loc))
                ).join(' | ')}
              </span>
            </>
          );
        }

        // TDS row (child row)
        const tds = supplierOrTds;
        return (
          <span>
            <span className="thin">Source:</span> {tds?.source || '(Unknown)'}
            &nbsp;
            {rfqFilter?.project_tdss?.some(
              (projectTdsUuid) => projectTdsUuid === tds.uuid
            ) ? (
              <ProjectTdsIcon />
            ) : (
              <SourceSingleOutlined />
            )}
            <br />
            <span className="thin">SKU:</span> {tds.sku}
          </span>
        );
      },
      rfqRender: (rfq) => `${rfq?.company?.name} (Buyer)`
    },
    {
      title: sortableColumnTitle('TDS', 'documents'),
      sortKey: 'documents_count',
      sorter: true,
      defaultSortOrder: ordering?.includes('documents_count')
        ? ordering.startsWith('-')
          ? 'descend'
          : 'ascend'
        : null,
      dataIndex: 'documents',
      fixed: 'left',
      width: 70,
      render: (documents) =>
        documents?.map((doc) =>
          doc.url ? (
            <a key={doc.uuid} title={doc.title} href={doc.url} target="_blank">
              <FileTextFilled />
            </a>
          ) : (
            <Typography.Text key={doc.uuid} underline>
              <FileTextFilled />{' '}
            </Typography.Text>
          )
        )
    },
    {
      title: sortableColumnTitle('Updated', 'updated_at'),
      sortKey: 'latest_update',
      sorter: true,
      defaultSortOrder: ordering?.includes('latest_update')
        ? ordering.startsWith('-')
          ? 'descend'
          : 'ascend'
        : null,
      dataIndex: 'updated_at',
      fixed: 'left',
      width: 100,
      render: (updatedAt) =>
        updatedAt ? (
          <>
            <span className="thin">{dayjs(updatedAt).fromNow()}</span>

            <span className="subcontent">
              {dayjs(updatedAt).format('MM/DD/YYYY')}
            </span>
          </>
        ) : (
          'Never updated'
        )
    },

    ...(rfqFilter
      ? [
          {
            title: 'Status',
            key: 'status',
            dataIndex: 'uuid',
            render: (uuid) => {
              const evaluation =
                supplierEvaluations && supplierEvaluations[uuid];
              const evaluationStatus = evaluationToStatus(evaluation);
              if (evaluationStatus) {
                return (
                  <div className={`eval-status-tag ${evaluationStatus.status}`}>
                    <Tooltip
                      title={
                        evaluationStatus.notes && (
                          <pre>{evaluationStatus.notes}</pre>
                        )
                      }
                    >
                      {evaluationStatus.disqualified && <CloseCircleOutlined />}
                      &nbsp;
                      {sentenceCase(evaluationStatus.status)}
                    </Tooltip>
                  </div>
                );
              }
              return undefined;
            },
            fixed: 'left',
            width: 90,
            rfqRender: () => undefined
          }
        ]
      : [])
  ];
};

const rightColumns = (rfq) => {
  const ordering = getLatestSearchFromLocalStorage(rfq)?.ordering;
  return [
    {
      title: sortableColumnTitle('Cap. (lbs)', 'capacity_lbs'),
      sorter: true,
      sortKey: 'greatest_quantity',
      defaultSortOrder: ordering?.includes('greatest_quantity')
        ? ordering.startsWith('-')
          ? 'descend'
          : 'ascend'
        : null,
      dataIndex: 'capacity_lbs',
      width: 90,
      render: (_, supplier) => (
        <>
          <span>
            {prettyNumber(supplier?.capacity_lbs || supplier?.capacity_lbs)}
          </span>
          {supplier?.capacity_lbs && supplier.capacity_lbs ? (
            <span className="subcontent">
              {prettyNumber(supplier.capacity_lbs)}
            </span>
          ) : null}
        </>
      ),
      rfqRender: (rfqFilter) => `${prettyNumber(rfqFilter?.capacity_lbs)}/mo`
    },
    ...(rfq
      ? [
          {
            title: 'Total Dist. (MI)',
            dataIndex: 'locations',
            key: 'distance',
            width: 180,
            render: (locs) => (
              <ShippingRoute
                possibleOrigins={locs}
                possibleDestinations={rfq?.shipping_addresses}
              />
            ),
            rfqRender: (rfqFilter) =>
              rfqFilter?.shipping_addresses
                ?.map((address) => locationRender(address))
                .join('\n')
          },
          {
            title: 'CO2e (Mt)',
            dataIndex: 'locations',
            key: 'co2',
            width: 90,
            render: (locs) => {
              const [, , distanceKm] = optimalShippingRoute(
                locs,
                rfq?.shipping_addresses
              );
              if (!distanceKm) return '-';
              return prettyNumber(
                shippingEmmissions(distanceKm, rfq?.capacity_lbs, 'truck')
              );
            },
            rfqRender: () => ''
          }
        ]
      : [])
  ];
};

/* eslint-disable react/no-unstable-nested-components */
export default function usePbColumns() {
  // Fetch Meta properties to use in table columns
  const typeProp = useMetaProperty('type');
  const formProp = useMetaProperty('form');
  const gradeProp = useMetaProperty('grade');
  const colorProp = useMetaProperty('color');
  const numericalProperties = useMetaNumericalProperty();

  // Table controls
  const [enabledNumericalPropertyColumns, setEnabledNumericalPropertyColumns] =
    useState([]);

  const [params] = useSearchParams();
  const anonymized = params.get('anonymized') === 'true';

  // Get RFQ to filter by from URL
  const rfqId = params.get('rfqId');
  const { data: rfqFilter } = useGetRfq();

  // TDS filtering
  const { filters, pagination } = useProposalBuilderFilters(rfqId, rfqFilter);

  const supplierEvaluations = useMemo(
    () =>
      rfqFilter?.supplier_evaluation?.reduce((acc, evaluation) => {
        acc[evaluation.supplier] = evaluation;
        return acc;
      }, {}) || {},
    [rfqFilter]
  );

  const queryClient = useQueryClient();

  const { mutate: mutateEvaluateSupplier } = useMutation({
    mutationFn: evaluateSupplier,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['requestForQuote', rfqId]
      });
    }
  });

  const { mutate: mutatePatchRFQ } = useMutation({
    mutationFn: patchRFQ,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['requestForQuote', rfqId]
      });
    }
  });

  const evaluate = (uuid, attribute, value) => {
    if (rfqId) {
      mutateEvaluateSupplier({ uuid, attribute, value, rfqId });
    }
  };
  const [editTdsModalOpen, setEditTdsModalOpen] = useState(false);
  const [editTdsModalUuid, setEditTdsModalUuid] = useState();
  const [editTdsModalSupplierUuid, setEditTdsModalSupplierUuid] = useState();
  const [editColumnsModalOpen, setEditColumnsModalOpen] = useState();

  const editTdsInModal = (uuid) => {
    setEditTdsModalOpen(true);
    setEditTdsModalUuid(uuid);
    setEditTdsModalSupplierUuid(undefined);
  };

  const createTdsModal = (supplier) => {
    setEditTdsModalOpen(true);
    setEditTdsModalUuid(undefined);
    setEditTdsModalSupplierUuid(supplier);
  };

  const submitTds = (tds) => {
    queryClient.invalidateQueries({
      queryKey: ['suppliers']
    });
    const supplierUuid = editTdsModalSupplierUuid || tds?.company?.uuid;
    if (supplierUuid) {
      evaluate(supplierUuid, 'screened', true);
    }
  };

  const editTableColumn = {
    title: (
      <>
        <button
          onClick={() => setEditColumnsModalOpen(true)}
          className="bare"
          type="button"
          title="Manage table columns"
        >
          <SettingFilled />
        </button>
        <Modal
          open={editColumnsModalOpen}
          footer={null}
          onCancel={() => setEditColumnsModalOpen(false)}
          title="Numerical Property Columns"
        >
          <Select
            mode="multiple"
            allowClear
            value={enabledNumericalPropertyColumns}
            onChange={setEnabledNumericalPropertyColumns}
            optionFilterProp="label"
            options={numericalProperties.map((p) => ({
              label: `${p.code} (${p.name})`,
              value: p.uuid
            }))}
            placeholder="Numerical Properties..."
            style={{ width: '100%' }}
          />
        </Modal>
        {editTdsModalOpen && (
          <TechnicalDataSheet
            open={!!editTdsModalOpen}
            tdsId={editTdsModalUuid}
            supplierId={editTdsModalSupplierUuid}
            onSuccess={submitTds}
            canDuplicate={!!editTdsModalUuid}
            hideModal={() => setEditTdsModalOpen(false)}
          />
        )}
      </>
    ),
    dataIndex: 'uuid',
    width: 50,
    fixed: 'right',
    render: (uuid, tds) => {
      const isSupplierRow = !!tds.name;
      return (
        <Dropdown
          overlayClassName="proposal-builder--table--dropdown"
          menu={{
            items: [
              ...(isSupplierRow
                ? [
                    {
                      key: '0',
                      label: (
                        <button
                          className="bare"
                          type="button"
                          onClick={() => createTdsModal(uuid)}
                        >
                          <EditOutlined /> Create TDS
                        </button>
                      )
                    }
                  ]
                : []),
              ...(uuid && !isSupplierRow
                ? [
                    {
                      key: '1',
                      label: (
                        <button
                          className="bare"
                          type="button"
                          onClick={() => editTdsInModal(uuid)}
                        >
                          <EditOutlined /> Edit TDS
                        </button>
                      )
                    }
                  ]
                : []),
              ...(uuid && !isSupplierRow
                ? [
                    {
                      key: 'make-project',
                      label: (
                        <button
                          className="bare"
                          type="button"
                          onClick={() =>
                            mutatePatchRFQ({
                              uuid: rfqId,
                              project_tdss: [...rfqFilter.project_tdss, uuid]
                            })
                          }
                        >
                          <ProjectTdsIcon /> Make Project TDS
                        </button>
                      )
                    }
                  ]
                : []),
              {
                key: '2',
                label: (
                  <a
                    href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/${
                      isSupplierRow ? 'company' : 'technicaldatasheet'
                    }/${uuid}`}
                    target="_blank"
                  >
                    <LinkOutlined /> View in admin
                  </a>
                )
              },
              ...(rfqId && isSupplierRow
                ? filterEvaluationItems(supplierEvaluations?.[uuid]).map(
                    (item) => ({
                      key: item.attribute,
                      label: (
                        <button
                          className="bare"
                          type="button"
                          onClick={() =>
                            evaluate(uuid, item.attribute, item.value)
                          }
                        >
                          {item.icon}&nbsp;
                          {item.label}
                        </button>
                      )
                    })
                  )
                : [])
            ]
          }}
          trigger="click"
        >
          <Button shape="circle" size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      );
    },
    rfqRender: () => ''
  };

  // Enable numerical columns relevant for the selected RFQ / filtered on
  useEffect(() => {
    const mnpFiltersUuids = filters
      ? Object.keys(filters)
          .filter((key) => key.startsWith('mnp.'))
          .map((key) => key.split('.')[1])
      : [];

    const rfqFilterMnpUuids =
      rfqFilter?.material_numerical_properties?.map(
        (mnp) => mnp.property.uuid
      ) || [];

    const allMnpUuids = new Set([...mnpFiltersUuids, ...rfqFilterMnpUuids]);

    if (allMnpUuids.size > 0)
      setEnabledNumericalPropertyColumns(Array.from(allMnpUuids));
    else setEnabledNumericalPropertyColumns([]);
  }, [rfqFilter, filters]);

  // Create table columns dynamically based on filters and RFQ
  const numericalColumns = useMemo(
    () =>
      numericalProperties
        ?.filter((p) =>
          !enabledNumericalPropertyColumns
            ? false
            : enabledNumericalPropertyColumns?.includes(p.uuid)
        )
        .map((p) => numericalPropertyColumn(p)),
    [numericalProperties, enabledNumericalPropertyColumns]
  );
  const columns = useMemo(() => {
    const allColumns = [];

    allColumns.push(...leftColumns(anonymized, supplierEvaluations, rfqFilter));

    if (typeProp) allColumns.push(propertyColumn(typeProp));
    if (formProp) allColumns.push(propertyColumn(formProp, 120));

    allColumns.push(...rightColumns(rfqFilter));

    if (gradeProp) allColumns.push(propertyColumn(gradeProp));
    if (colorProp) allColumns.push(propertyColumn(colorProp));

    if (numericalColumns && numericalColumns.length)
      allColumns.push(...numericalColumns);

    allColumns.push(editTableColumn);

    return allColumns;
  }, [
    typeProp,
    formProp,
    anonymized,
    rfqFilter,
    numericalColumns,
    editTableColumn
  ]);

  return columns;
}

function isSortedColumn(dataIndex, sortColumn) {
  if (!sortColumn) return false;

  if (Array.isArray(dataIndex)) {
    if (!Array.isArray(sortColumn.dataIndex)) return false;

    return sortColumn.dataIndex.every(
      (dataIndexKey, i) => dataIndexKey === dataIndex[i]
    );
  }

  return dataIndex === sortColumn.dataIndex || dataIndex === sortColumn.sortKey;
}

function filterLocationsArray(arr) {
  return Array.from(new Set(arr));
}

const evaluationItems = [
  {
    label: 'Matched',
    attribute: 'matched',
    value: true,
    icon: <BlockOutlined />
  },
  {
    label: 'Undo matched',
    attribute: 'matched',
    value: false,
    icon: <BlockOutlined />
  },
  {
    label: 'Contacted',
    attribute: 'contacted',
    value: true,
    icon: <LoginOutlined />
  },
  {
    label: 'Undo contacted',
    attribute: 'contacted',
    value: false,
    icon: <LoginOutlined />
  },
  {
    label: 'Engaged',
    attribute: 'engaged',
    value: true,
    icon: <MessageOutlined />
  },
  {
    label: 'Undo engaged',
    attribute: 'engaged',
    value: false,
    icon: <MessageOutlined />
  },
  {
    label: 'Screened',
    attribute: 'screened',
    value: true,
    icon: <FilterOutlined />
  },
  {
    label: 'Undo screened',
    attribute: 'screened',
    value: false,
    icon: <FilterOutlined />
  },
  {
    label: 'Approved',
    attribute: 'approved',
    value: true,
    icon: <CheckCircleOutlined />
  },
  {
    label: 'Undo approved',
    attribute: 'approved',
    value: false,
    icon: <CheckCircleOutlined />
  },
  {
    label: 'Disqualified',
    attribute: 'disqualified',
    value: true,
    icon: <CloseCircleOutlined />
  },
  {
    label: 'Undo disqualified',
    attribute: 'disqualified',
    value: false,
    icon: <CloseCircleOutlined />
  }
];

function filterEvaluationItems(evaluation) {
  return evaluationItems.filter(
    (item) =>
      (evaluation && evaluation[item.attribute] !== item.value) ||
      (!evaluation && item.value)
  );
}

function SupplierDataSourceIndicator({ supplier, rfq }) {
  if (!rfq && !supplier?.tdss?.length) return null;

  if (
    rfq?.project_tdss?.some((projectTdsUuid) =>
      supplier.tdss.some(
        ({ uuid: supplierTdsUuid }) => supplierTdsUuid === projectTdsUuid
      )
    )
  ) {
    return <ProjectTdsIcon />;
  }

  const multiple = supplier.tdss.length > 1;
  const confirmedBySupplier = supplier.tdss.some((tds) =>
    tds?.source?.includes('supplier')
  );

  if (multiple)
    return confirmedBySupplier ? (
      <SourceMultipleFilled />
    ) : (
      <SourceMultipleOutlined />
    );

  return confirmedBySupplier ? (
    <SourceSingleFilled />
  ) : (
    <SourceSingleOutlined />
  );
}
SupplierDataSourceIndicator.propTypes = {
  supplier: PropTypes.object,
  rfq: PropTypes.object
};

export function ProjectTdsIcon() {
  return (
    <span className="project-tds-indicator">
      <SourceSingleFilled />
    </span>
  );
}
