import React from 'react';
import PropTypes from 'prop-types';

function SplashContent({ title, subtitle, footer, children }) {
  return <div className="splash-content">{children}</div>;
}

SplashContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string
};

export default SplashContent;
