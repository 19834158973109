import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { colord } from 'colord';
import PropTypes from 'prop-types';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
/* eslint-enable import/no-unresolved */

import { useMarketPriceLatestPriceList } from 'src/Query/marketPrice';
import AnalyticsTile from 'src/components/analytics/AnalyticsTile';
import { sourcesListDetailsArray, sourcesListNamesArray } from './utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
);

const colors = [
  '#F5222D',
  '#722ED1',
  '#EB2F96',
  '#D48806',
  '#2F54EB',
  '#08979C',
  '#391085'
];

const barChartOptions = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const skeletonColor = '#ececec';

const barChartOptionsSkeleton = {
  // animations: false,
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        display: false
      },
      border: {
        color: skeletonColor
      }
    },
    y: {
      ticks: {
        display: false
      },
      border: {
        color: skeletonColor
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const dataSkeleonValues = [1, 3, 2];

const dataSkeleton = {
  labels: ['1', '2', '3'],
  datasets: [
    {
      barPercentage: 0.45,
      categoryPercentage: 1,
      label: 'Price',
      data: dataSkeleonValues,
      backgroundColor:
        dataSkeleonValues.map((price, index) =>
          colord(skeletonColor)
            .lighten(0.03 * index)
            .toHex()
        ) || []
    }
  ]
};

const priceMid = (price) =>
  (price && (Number(price.high) + Number(price.low)) / 2) || 0;

export default function AnalyticsTickerBarChart({
  tickers,
  colorSeed,
  titles
}) {
  const { isLoading, prices } = useMarketPriceLatestPriceList(tickers);

  const baseColor = colors[colorSeed || 0];
  const colorIncrement = prices && 0.25 / prices.length;
  const data = useMemo(
    () => ({
      labels: tickers?.map((ticker) => ticker.title) || [],
      datasets: [
        {
          barPercentage: 0.45,
          categoryPercentage: 1,
          label: 'Price',
          data: prices?.map((price) => priceMid(price)) || [],
          backgroundColor:
            prices?.map((price, index) =>
              colord(baseColor)
                .lighten(colorIncrement * index)
                .toHex()
            ) || []
        }
      ]
    }),
    [prices]
  );

  const latest1 =
    (Array.isArray(prices) &&
      dayjs(prices.find((p) => !!p)?.timestamp).format('MMM DD, YYYY')) ||
    '';

  const tickersArray = prices?.map((p) => p?.tickers) || [];
  const sourceDetails = sourcesListDetailsArray(tickersArray);
  const source = sourcesListNamesArray(tickersArray);
  return (
    <AnalyticsTile
      source={source}
      sourceDetails={sourceDetails}
      latest={latest1}
      titles={titles}
    >
      {isLoading ? (
        <Bar data={dataSkeleton} options={barChartOptionsSkeleton} />
      ) : (
        <Bar data={data} options={barChartOptions} />
      )}
    </AnalyticsTile>
  );
}

AnalyticsTickerBarChart.propTypes = {
  tickers: PropTypes.arrayOf(PropTypes.object),
  colorSeed: PropTypes.number,
  titles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};
