import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

import ProjectIcon from 'src/components/icons/Project';

import { findProperties } from 'src/utils/properties';
import LogisticsIcon from 'src/components/icons/LogisticsIcon';
import MapMarkerIcon from 'src/components/icons/MapMarkerIcon';
import prettyNumber, {
  prettyNumberRound
} from 'src/components/utils/prettyNumber';
import CalendarIcon from 'src/components/icons/Calendar';
import SupplierAvatar from 'src/pages/rfq/proposal/supply/SupplierAvatar';
import { RightOutlined } from '@ant-design/icons';
import './ProjectDetailsSidebar.less';

const formatPropertyList = (list) => {
  if (Array.isArray(list)) {
    return list.map((o) => o.value).join(', ');
  }
  return '-';
};

const formatLocation = (location) => {
  if (location.city && location.country)
    return `${location.city}, ${location.country}`;
  if (location.city) return location.city;
  if (location.country) return location.country;
  return '';
};

export default function ProjectDetailsSidebar({
  children,
  requestForQuote,
  projects,
  activeProjectIndex,
  setActiveProjectIndex
}) {
  const types = formatPropertyList(findProperties(requestForQuote, 'type'));
  const forms = formatPropertyList(findProperties(requestForQuote, 'form'));
  const colorProperties = findProperties(requestForQuote, 'color');
  const colors = formatPropertyList(colorProperties);
  const locations = requestForQuote.shipping_addresses
    .map((o) => formatLocation(o))
    .join('/');

  const deals = useMemo(() => projects.map((o) => o.deal), [projects]);

  return (
    <div className="project-details-sidebar">
      <div className="project-details-sidebar--sidebar">
        <h1>{requestForQuote.project_title || requestForQuote.title}</h1>
        <div className="tag">Procurement</div>
        <h3>Product Summary</h3>
        <div className="project-details-sidebar--summary">
          <div>
            <ProjectIcon className /> <span className="type">{types}</span> -{' '}
            <span className="form">{forms}</span>
          </div>
          <div>
            <span
              style={{ background: colorsToHex(colorProperties) }}
              className="color-icon"
            />{' '}
            <span className="color">{colors}</span>
          </div>
          <div>
            <LogisticsIcon />{' '}
            <span className="quantity">
              {prettyNumber(requestForQuote.quantity)}&nbsp;
              {requestForQuote.quantity_units}
            </span>{' '}
            <span className="duration">
              ({prettyNumberRound(requestForQuote.duration_months)} MO.)
            </span>
          </div>
          <div>
            {locations && (
              <>
                <MapMarkerIcon /> <span className="shipping">{locations}</span>
              </>
            )}
          </div>
          <div>
            {locations && (
              <>
                <CalendarIcon /> <span className="due-data">26/01/2024</span>
              </>
            )}
          </div>
        </div>
        <Divider />
        <h3>Suppliers</h3>
        {deals.map(
          (deal, index) =>
            deal.suppliers[0] && (
              <SupplierNav
                key={`${deal.uuid}/{supplier.uuid}`}
                supplier={deal.suppliers[0]}
                active={activeProjectIndex === index}
                onClick={() => setActiveProjectIndex(index)}
              />
            )
        )}
      </div>
      {children}
    </div>
  );
}

ProjectDetailsSidebar.propTypes = {
  requestForQuote: PropTypes.object,
  projects: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  activeProjectIndex: PropTypes.number,
  setActiveProjectIndex: PropTypes.func
};

const DEFAULT_COLOR = '#444444';
const colorsToHex = (colors) => {
  if (colors?.length === 1) return colorToHex(colors[0]);
  return DEFAULT_COLOR;
};

const colorMap = {
  Black: '#101010',
  White: '#f8f8f8',
  Clear: '#e8e8f8',
  Red: '#f5222d',
  'White, blows Clear': '#e8e8f8',
  Natural: '#8c8c20',
  Mixed: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)',
  Purple: '#722ed1',
  Blue: '#2f54eb',
  Grey: '#8c8c8c',
  Green: '#52c41a',
  Yellow: '#fadb14',
  Orange: '#fa8c16',
  Multi: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)',
  Custom: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)'
};

const colorToHex = (color) => colorMap[color.value] || DEFAULT_COLOR;

function SupplierNav({ supplier, onClick, active }) {
  return (
    <div
      className={`project-supplier-nav ${active ? 'active' : ''}`}
      onClick={onClick}
      role="presentation"
    >
      <SupplierAvatar supplier={supplier} />
      <h1>{supplier.name}</h1>
      <RightOutlined />
    </div>
  );
}

SupplierNav.propTypes = {
  supplier: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func
};
