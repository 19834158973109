import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';

function TabsWithRoutes({ activeKey, children, items }) {
  const navigate = useNavigate();
  const relativeTo = activeKey ? '..' : '.';
  return (
    <Tabs
      activeKey={activeKey}
      onChange={(key) => {
        navigate(`${relativeTo}/${key}`, { relative: 'path' });
      }}
      items={items}
      className="tabs-with-routes"
    >
      {children}
    </Tabs>
  );
}

TabsWithRoutes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  activeKey: PropTypes.string,
  items: PropTypes.array
};

export default TabsWithRoutes;
