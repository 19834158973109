import React from 'react';

// Ported from color_space Rust library: https://github.com/ChevyRay/color_space/

export const extractNumFromStr = (str) =>
  parseFloat(String(str).match(/-?\d+(\.\d+)?/)?.[0]);

const minmaxRgb = (a) => Math.max(Math.min(a, 255), 0);

export const colorSpaceXyzToRgb = (_x, _y, _z) => {
  const x = _x / 100.0;
  const y = _y / 100.0;
  const z = _z / 100.0;
  const r1 = x * 3.2404542 + y * -1.5371385 + z * -0.4985314;
  const g1 = x * -0.969266 + y * 1.8760108 + z * 0.041556;
  const b1 = x * 0.0556434 + y * -0.2040259 + z * 1.0572252;
  const r = minmaxRgb(
    255.0 * (r1 > 0.0031308 ? 1.055 * r1 ** (1.0 / 2.4) - 0.055 : 12.92 * r1)
  );
  const g = minmaxRgb(
    255.0 * (g1 > 0.0031308 ? 1.055 * g1 ** (1.0 / 2.4) - 0.055 : 12.92 * g1)
  );
  const b = minmaxRgb(
    255.0 * (b1 > 0.0031308 ? 1.055 * b1 ** (1.0 / 2.4) - 0.055 : 12.92 * b1)
  );
  return [r, g, b];
};

export const colorSpaceHunterLabToRgb = (lStr, aStr, bStr) => {
  const l = extractNumFromStr(lStr);
  const a = extractNumFromStr(aStr);
  const b = extractNumFromStr(bStr);
  const x1 = (a / 17.5) * (l / 10.0);
  const y1 = (l * l) / 100.0;
  const z1 = ((b / 7.0) * l) / 10.0;
  const x = (x1 + y1) / 1.02;
  const y = y1;
  const z = -(z1 - y1) / 0.847;
  return colorSpaceXyzToRgb(x, y, z);
};

export const colorSpaceCieLabToRgb = (lStr, aStr, bStr) => {
  const l = extractNumFromStr(lStr);
  const a = extractNumFromStr(aStr);
  const b = extractNumFromStr(bStr);
  const y1 = (l + 16.0) / 116.0;
  const x1 = a / 500.0 + y1;
  const z1 = y1 - b / 200.0;
  const x3 = x1 ** 3;
  const y3 = y1 ** 3;
  const z3 = z1 ** 3;
  const x = 95.047 * (x3 > 0.008856 ? x3 : (x3 - 16.0 / 116.0) / 7.787);
  const y = 100.0 * (y3 > 0.008856 ? y3 : (y3 - 16.0 / 116.0) / 7.787);
  const z = 108.883 * (z3 > 0.008856 ? z3 : (z3 - 16.0 / 116.0) / 7.787);
  return colorSpaceXyzToRgb(x, y, z);
};

export const rgbToHex = (rgb) =>
  `#${rgb
    .map((x) => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

// deprecated from v1, see valueToSpaceSpec
export const colorSpaceSpec = (space, hex, lab) => {
  if (space === 'rgb') return `RGB(${hex})`;
  if (space === 'hunter') {
    if (Array.isArray(lab) && lab.length === 3) {
      const r = `HunterLab(${lab[0]},${lab[1]},${lab[2]})`;
      return r;
    }
    return 'HunterLab()';
  }
  if (space === 'lab') {
    if (Array.isArray(lab) && lab.length === 3)
      return `CieLab(${lab[0]},${lab[1]},${lab[2]})`;
    return 'CieLab()';
  }
  return undefined;
};

export const spaceSpecToValue = (spec) => {
  if (typeof spec !== 'string') return undefined;
  const parts = spec.split(':');
  if (parts[0] === 'rgb') {
    return {
      colorSpace: parts[0],
      hex: parts[1]
    };
  }
  if (parts[0] === 'hunter' || parts[0] === 'lab') {
    const numberParts = parts[1].split(',');
    if (numberParts.length !== 4 && numberParts.length !== 3) return undefined;
    return {
      colorSpace: parts[0],
      lab: numberParts.slice(0, 3),
      delta: numberParts[3]
    };
  }
  return undefined;
};

export const valueToSpaceSpec = (value) => {
  if (value?.colorSpace === 'rgb' && value.hex) {
    return `${value.colorSpace}:${value.hex}`;
  }
  if (value?.colorSpace === 'hunter' || value?.colorSpace === 'lab') {
    return `${value.colorSpace}:${value.lab.join(',')},${value.delta}`;
  }
  return undefined;
};

export const valueToHex = (value) => {
  if (value?.colorSpace === 'rgb' && value.hex) {
    if (value.hex.includes('#')) return value.hex;

    return `#${value.hex}`;
  }
  if (value?.colorSpace === 'hunter') {
    return rgbToHex(colorSpaceHunterLabToRgb(...value.lab));
  }
  if (value?.colorSpace === 'lab') {
    return rgbToHex(colorSpaceCieLabToRgb(...value.lab));
  }
  return undefined;
};

export const spaceSpecToDescription = (spec) => {
  if (typeof spec !== 'string') return undefined;
  const parts = spec.split(':');
  if (parts[0] === 'rgb') {
    return `RGB: ${parts[1]}`;
  }
  if (parts[0] === 'hunter') {
    const numberParts = parts[1].split(',');
    numberParts.length = 3;
    return `Hunter L/a/b: ${numberParts.join(' / ')}`;
  }
  if (parts[0] === 'lab') {
    const numberParts = parts[1].split(',');
    numberParts.length = 3;
    return `CIE L/a/b: ${numberParts.join(' / ')}`;
  }
  return undefined;
};

export const renderColorSpec = (colorSpec) => {
  const [specType, colorValue] = colorSpec.split(':');

  if (specType === 'rgb' && hexToRgb(colorValue)) {
    const { r, g, b } = hexToRgb(colorValue);
    return (
      <>
        RGB
        <br />
        R: {r}
        <br />
        G: {g}
        <br />
        B: {b}
      </>
    );
  }

  if (specType === 'hunter') {
    const [L, a, b, delta] = colorValue.split(',');
    return (
      <>
        Hunter L/a/b:
        <br />
        L: {L}
        <br />
        a: {a}
        <br />
        b: {b}
      </>
    );
  }

  if (specType === 'lab') {
    const [L, a, b, delta] = colorValue.split(',');
    return (
      <>
        CIE L/a/b:
        <br />
        L: {L}
        <br />
        a: {a}
        <br />
        b: {b}
      </>
    );
  }

  return spaceSpecToDescription(colorSpec);
};
