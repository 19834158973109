import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from 'src/utils/authentication';
import { usePerformance } from './hooks';

const GA_MEASUREMENT_ID = 'G-WMPLQXR8V7';

// this component depends on the GA script tag in index.html which wasn't working
// when added from helmet. If the "G-*"" ID changes, then change it in index.html too
const prodScript = (internal, optOut, user) => {
  if (internal) {
    window[`ga-disable-${GA_MEASUREMENT_ID}`] = true;
    return;
  }

  /* eslint-disable */
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag('js', new Date());

  if (internal) gtag('set', { 'traffic_type': 'internal' });
  const options = {};
  if (optOut) {
    options["client_storage"] = 'none';
  }
  if (user) {
    options["client_id"] = user.uuid;
  }
  gtag('config', GA_MEASUREMENT_ID, options);
  // take out GTM, because it seems to be messing up internal traffic filter
  // and we don't use it right now
  // if (!internal) {
  //   // setup Google Tag Manager
  //   (function (w, d, s, l, i) {
  //     w[l] = w[l] || [];
  //     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //     const f = d.getElementsByTagName(s)[0];
  //     const j = d.createElement(s);
  //     const dl = l != 'dataLayer' ? `&l=${l}` : '';
  //     j.async = true;
  //     j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, 'script', 'dataLayer', 'GTM-NNR2LD3');
  // }
  /* eslint-enable */
};

// eslint-disable-next-line no-console
const defaultScript = () => console.log('No GA script installed');

const gaScripts = {
  'circular.co': prodScript,
  'www.circular.co': prodScript,
  localhost: prodScript
};

const roleFromUser = (user) =>
  user ? (user.is_staff ? 'staff' : 'user') : 'public';

const internalFromUser = (user) =>
  (user && user.email && user.email.slice(-11) === 'circular.co') || false;

function GoogleAnalytics() {
  const optOut = usePerformance();
  const { data: user } = useUser();
  // it is quite hard to refactor useUser to let us know when it is done
  // (i.e. authentication has succeeded or failed)
  // so we just back off for a few seconds to give authentication a chance to complete
  const [waitIsOver, setWaitIsOver] = useState(false);
  const location = useLocation();
  const { hostname } = window.location;
  const gaScript = gaScripts[hostname];

  useEffect(() => {
    setTimeout(() => setWaitIsOver(true), 4000);
  });

  useEffect(() => {
    if (waitIsOver) {
      if (gaScript) {
        const role = roleFromUser(user);
        const internal = internalFromUser(user);
        const companyName = user?.company?.name;
        // eslint-disable-next-line no-console
        console.log('setting role to', role, internal);
        // I believe that we can't initialize the GA scripts until we have
        // populated the data layer, so we delay this until the user data is loaded
        gaScript(internal, optOut, user);
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: 'userData',
        //   user_role: role,
        //   user_company: companyName
        // });
      } else {
        // eslint-disable-next-line no-console
        console.log('No GA script');
      }
    }
  }, [user, waitIsOver, optOut]);

  useEffect(() => {
    if (Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'pageview'
      });
    }
  }, [location]);

  return null;
}

export default GoogleAnalytics;
