import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Typography,
  Alert,
  Select,
  Row,
  Col,
  Checkbox,
  Space
} from 'antd';
import { InlineWidget } from 'react-calendly';
import './OnboardingPortal.less';
import UploadDocuments from 'src/components/form/UploadDocuments';
import { getOnboarding } from 'src/Query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { createOrUpdateOnbording, submitRFQ } from 'src/Mutation';
import BarePageLayout from 'src/components/layout/BarePageLayout';
import CircularLogo from 'src/components/icons/CircularLogo';
import useCalendlyEvent from 'src/hooks/useCalendlyEvent';
import useCalendlyCallback from 'src/hooks/useCalendlyCallback';
import { useUser } from 'src/utils/authentication';
import { useNavigate } from 'react-router-dom';

const formatEmailList = (members) => {
  const list = members?.map((o) => o.email) || [];
  if (list.length > 1) {
    return `${list.slice(0, list.length - 1).join(', ')} and ${
      list[list.length - 1]
    }`;
  }
  return list[0];
};

const teamRoles = [
  { value: 'esg', label: 'ESG' },
  { value: 'finance', label: 'Finance' },
  { value: 'legal', label: 'Legal' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'operations', label: 'Operations' },
  { value: 'procurement', label: 'Procurement' },
  { value: 'sales', label: 'Sales' },
  { value: 'technology', label: 'Technology' }
];

function OnboardingPortal() {
  const [inviteNumber, setInviteNumber] = useState(3);
  const [formErrors, setFormErrors] = useState({});
  const [teamsDirty, setTeamsDirty] = useState(false);
  const [eventId, setEventId] = useState();
  const [rfqId, setRfqId] = useState();
  const [error, setError] = useState();
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(['onboarding'], () =>
    getOnboarding()
  );

  const setFormError = (type, message) => {
    const newValue = { ...formErrors };
    newValue[type] = message;
    setFormErrors(newValue);
  };

  const navigate = useNavigate();

  const onboardingData = Array.isArray(data) ? data[0] : undefined;
  useEffect(() => {
    if (onboardingData?.team.length) {
      setInviteNumber(0);
    }
  }, [onboardingData]);
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: createOrUpdateOnbording,
    onError: () => {
      setError('Error submitting your data. Please try again or contact us');
    },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['onboarding']
      });
      const done = !!(
        variables.kickoff_event_id &&
        variables.request_for_quote &&
        variables.reason_chose_circular_detail
      );
      if (done) navigate('/onboarding/status');
    }
  });
  const updateOnboarding = (body) => {
    const uuid = onboardingData?.uuid;
    setError(undefined);
    mutate({ uuid, ...body });
    if (body.team) {
      setTeamsDirty(false);
    }
  };

  const { data: user } = useUser();

  const { mutate: mutateRfq, isLoading: isRfqMutating } = useMutation({
    mutationFn: submitRFQ,
    onSuccess: (response) => {
      setRfqId(response.uuid);
      updateOnboarding({ request_for_quote: response.uuid });
    },
    onError: (e) => {
      setError(
        'Error submitting your project data. Please try again or contact us'
      );
    }
  });

  const createRfq = (values) => {
    const goal_description = values.insight;
    const product_description = values.details;
    const sourcing_priorities = values.sourcing;
    const documents_ids = values.documents?.map((o) => o.uuid) || [];
    setError(undefined);
    mutateRfq({
      goal_description,
      product_description,
      sourcing_priorities,
      documents_ids
    });
  };

  useCalendlyCallback((newEventId) => {
    setEventId(newEventId);
    setFormError('event', undefined);
    updateOnboarding({ kickoff_event_id: newEventId });
  });

  const [teamForm] = Form.useForm();
  const teamSection = useRef();
  const teamFormChanged = (changed, all) => {
    // changed.forEach(async (c) => {
    //   const { name } = c;
    //   if (Array.isArray(name) && name[0] === 'emails') {
    //     try {
    //       const result = await teamForm.validateFields([['roles', name[1]]]);
    //     } catch (ex) {
    //       // ignore
    //     }
    //   }
    // });
    // // teamForm.validateFields(undefined, { force: true });
    // setTeamsDirty(true);
    // setFormError('team', undefined);
  };
  const buildTeamPayload = (values) => ({
    team: values?.emails
      ?.map((email, index) => ({
        email,
        role: values.roles[index]
      }))
      .filter((o) => o.email)
  });

  const validateTeamRole = (value, index) => {
    const values = teamForm.getFieldsValue();
    const email = values.emails[index];
    if ((email && value) || (!email && !value)) return Promise.resolve();
    return Promise.reject(new Error('Role must be set'));
  };

  const [whyForm] = Form.useForm();
  const whySection = useRef();
  const whyFormChanged = (changed, all) => {
    setFormError('why', undefined);
  };

  const buildWhyPayload = (values) => ({
    reason_chose_circular: values.reason_chose_circular?.join('; '),
    reason_chose_circular_detail: values.reason_chose_circular_detail
  });

  const [projectForm] = Form.useForm();
  const projecttSection = useRef();
  const projectFormChanged = (changed, all) => {
    setFormError('project', undefined);
  };

  const eventSection = useRef();

  const hasErrors = async (form) => {
    try {
      const errors = await form.validateFields();
      return false;
    } catch (ex) {
      return ex.errorFields.length ? ex.errorFields : false;
    }
  };

  const eventBookedId = eventId || onboardingData?.kickoff_event_id;
  const submitAll = async () => {
    const errors = {};
    if (await hasErrors(teamForm)) {
      if (!Object.keys(errors).length)
        teamSection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
      errors.team = 'Please invite at least one team member and specify roles.';
    }
    if (await hasErrors(whyForm)) {
      if (!Object.keys(errors).length)
        whySection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
      errors.why = 'Please provide feedback on why you chose Circular.';
    }
    if (await hasErrors(projectForm)) {
      if (!Object.keys(errors).length)
        projecttSection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
      errors.project = 'Please provide details of your first project.';
    }
    if (!eventBookedId) {
      if (!Object.keys(errors).length)
        eventSection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
      errors.event = 'Please book a kick-off meeting.';
    }
    setFormErrors(errors);
    if (!Object.keys(errors).length) {
      if (rfqId) {
        submitOnboarding(rfqId);
      } else {
        createRfq(projectForm.getFieldsValue());
      }
    }
  };

  const submitOnboarding = (rfqIdIn) => {
    const payload = {
      ...buildTeamPayload(teamForm.getFieldsValue()),
      ...buildWhyPayload(whyForm.getFieldsValue())
    };
    if (eventId) {
      payload.kickoff_event_id = eventId;
    }
    if (rfqIdIn) {
      payload.request_for_quote = rfqIdIn;
    }
    updateOnboarding(payload);
  };

  const teamEmails = () => {
    const team = onboardingData?.team;
    if (team) return team.map((o) => o.email) || [];
    return [];
  };

  const busy = isLoading || isMutating || isRfqMutating;
  const hideSectionButtons = false;
  const sectionButtonStyle = hideSectionButtons
    ? { display: 'none' }
    : undefined;
  const sectionInviteButtonStyle =
    hideSectionButtons && !(teamsDirty && onboardingData)
      ? { display: 'none' }
      : undefined;

  const teamDone = onboardingData?.team.length;
  const whyDone = !!onboardingData?.reason_chose_circular_detail;
  const projectDone = onboardingData?.request_for_quote;
  const eventDone = !!eventBookedId;
  if (teamDone && whyDone && projectDone && eventDone) {
    navigate('/onboarding/status');
  }
  return (
    <BarePageLayout>
      <Helmet>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        />
      </Helmet>
      <div className="onboarding-portal">
        {error && <Alert message={error} type="error" />}
        <div className="top-logo">
          <CircularLogo light />
        </div>
        <section>
          <Typography.Title level={2}>Welcome to Circular.co!</Typography.Title>
          <p>
            Our platform makes it easier for you to search, procure, and manage
            PCR supply. We want to get you started on that journey immediately.
            Please complete the following steps to set up your account and give
            us the information we need to get going (est. 15 mins). We’ll take
            over from there and guide you through how the platform works on a
            kickoff call. If you have any questions, you can contact us via the
            chat icon in the lower right corner. Complete the following steps to
            get started:
          </p>
          <p>Complete the following steps to get started:</p>
          <table className="nlist">
            <tbody>
              <tr className="nlist-header">
                <td>1.</td>
                <td>Add your team</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Invite your team to join you on the Circular platform. Track
                  supplier searches, negotiate with suppliers, and manage your
                  projects with your team.
                </td>
              </tr>
              <tr className="nlist-header">
                <td>2.</td>
                <td>Tell us why you chose Circular</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Help us understand the pain points you currently experience in
                  sourcing PCR suppliers and what you're looking for in your
                  Circular.co experience. This makes sure we learn from past
                  issues and find practical solutions as fast as possible.
                </td>
              </tr>
              <tr className="nlist-header">
                <td>3.</td>
                <td>Start your first project</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Hit the ground running by starting your first project. Upload
                  project documents (technical datasheets, purchase orders,
                  material quality and testing requirements, etc.) and describe
                  your project goals to help our team curate the best search.
                </td>
              </tr>
              <tr className="nlist-header">
                <td>4.</td>
                <td>Schedule your kick-off call</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Schedule a kick-off call with your team and the entire
                  Circular team. We will use this call to walk you and your team
                  through the Circular platform and discuss your first project
                  in detail.
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="invite-team-members" ref={teamSection}>
          <Typography.Title level={3}>1. Add your team</Typography.Title>
          <p>
            Invite your team to join the Circular platform. They will be able to
            track supplier searches, communicate with suppliers, and manage your
            projects with you. You can always add people later as we go, too.
          </p>
          <p>
            Team mates listed here will receive an invite to our kick-off call
            to learn how the platform works and start the first search together.
            They will receive login access to Circular via email after the call.{' '}
          </p>
          {(teamDone && (
            <div className="done-status">
              {`${formatEmailList(onboardingData.team)} ${
                onboardingData.team.length === 1 ? 'has' : 'have'
              } been added to your team.`}
            </div>
          )) || (
            <Form
              name="invite-team-members"
              form={teamForm}
              scrollToFirstError
              onFieldsChange={(changed, all) => teamFormChanged(changed, all)}
              onFinish={(values) => updateOnboarding(buildTeamPayload(values))}
            >
              {[...Array(inviteNumber).keys()].map((index) => (
                <Row className="team-member-row" key={index} gutter={8}>
                  <Col lg={16}>
                    <Form.Item
                      name={['emails', index]}
                      rules={[
                        { type: 'email', message: 'Email address is invalid' }
                      ]}
                    >
                      <Input placeholder="name@company.com" />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item
                      name={['roles', index]}
                      rules={[
                        {
                          validator: (_, value) =>
                            validateTeamRole(value, index),
                          message: 'Role must be selected'
                        }
                      ]}
                    >
                      <Select options={teamRoles} placeholder="Select Role" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <div className="button-bar">
                <Button
                  type="link"
                  onClick={() => setInviteNumber(inviteNumber + 1)}
                >
                  Invite more team members
                </Button>
                <div className="stretch" />
                <Button
                  type="primary"
                  style={sectionButtonStyle}
                  loading={busy}
                  htmlType="submit"
                  disabled={inviteNumber === 0}
                >
                  Invite Team
                </Button>
              </div>
            </Form>
          )}
        </section>
        <section className="why-circular" ref={whySection}>
          <Typography.Title level={3}>
            2. Tell us why you chose Circular
          </Typography.Title>
          {(whyDone && (
            <div className="done-status">Thank you for your feedback</div>
          )) || (
            <>
              <p>
                Which pain points in sourcing PCR suppliers do you hope to ease
                with the help of the Circular team?
              </p>
              <p>Select all that apply</p>
              <Form
                name="why"
                form={whyForm}
                onFieldsChange={(changed, all) => whyFormChanged(changed, all)}
                onFinish={(values) => updateOnboarding(buildWhyPayload(values))}
              >
                <Form.Item name="reason_chose_circular">
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value="Reducing cost">Reducing cost</Checkbox>
                      <Checkbox value="Managing supply chain risk">
                        Managing supply chain risk
                      </Checkbox>
                      <Checkbox value="Poor supplier experience">
                        Poor supplier experience
                      </Checkbox>
                      <Checkbox value="Material quality">
                        Material quality
                      </Checkbox>
                      <Checkbox value="Other (please specify)">
                        Other (please specify)
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
                <p>
                  Describe any experiences you might have with the pain points
                  you selected.
                </p>
                <Form.Item
                  name="reason_chose_circular_detail"
                  rules={[
                    {
                      required: true,
                      message: 'We would greatly appreciate brief feedback.'
                    }
                  ]}
                >
                  <Input.TextArea rows={6} />
                </Form.Item>

                <div className="button-bar">
                  <div className="stretch" />
                  <Button
                    type="primary"
                    loading={busy}
                    htmlType="submit"
                    style={sectionButtonStyle}
                  >
                    Submit answer
                  </Button>
                </div>
              </Form>
            </>
          )}
        </section>

        <section className="project-details" ref={projecttSection}>
          <Typography.Title level={3}>
            3. Start your first project
          </Typography.Title>
          {(projectDone && (
            <div className="done-status">
              We&apos;ll review your project details. If you have any questions
              or change requests before your meeting, please reach out to&nbsp;
              <a href="mailto:mariah@circular.co">mariah@circular.co</a>
            </div>
          )) || (
            <>
              <p>
                To get started as soon as possible we want the kick off call to
                not only orient you to the platform but also start your first
                search project. Please upload key project documents, such as
                spec or technical data sheets, and tell us about what you are
                aiming to create with the material we will help you source.
                We&apos;ll synthesize the information into our standardized
                digital Request for Quote (RFQ) format. During the kick-off
                call, we will review this RFQ with you to expand on any details
                to make this first project a success.
              </p>
              <Form
                name="project"
                form={projectForm}
                onFieldsChange={(changed, all) =>
                  projectFormChanged(changed, all)
                }
                onFinish={(values) => createRfq(values)}
              >
                <Typography.Title level={5}>
                  Upload your project documents
                </Typography.Title>
                <p>
                  Upload project documents that you would typically use to start
                  your own PCR search here (e.g. Application description,
                  technical data sheets, material quality and testing
                  requirements, purchase orders, etc.).
                </p>
                <Form.Item name="documents">
                  <UploadDocuments />
                </Form.Item>
                <Typography.Title level={5}>
                  Tell us your project goals{' '}
                </Typography.Title>
                <p>
                  Describe the goals you have for this project and please
                  include any specifics about what the PCR will be used for, ie
                  the application or end product you will create. This extra
                  information helps us hone our search results.
                </p>
                <Form.Item
                  name="insight"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide the goals of your project.'
                    }
                  ]}
                >
                  <Input.TextArea rows={6} />
                </Form.Item>
                <div className="button-bar">
                  <div className="stretch" />
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={busy}
                    style={sectionButtonStyle}
                  >
                    Submit Project
                  </Button>
                </div>
              </Form>
            </>
          )}
        </section>
        <section className="kickoff-section" ref={eventSection}>
          <Typography.Title level={3}>
            4. Schedule Kickoff Call
          </Typography.Title>
          {(eventDone && (
            <div className="done-status">
              <p>
                See you&nbsp;
                <CalendlyEventTime eventId={eventBookedId} />
                &nbsp;for your project kick-off call! Please complete all the
                sections in this form to continue.
              </p>
            </div>
          )) || (
            <>
              <p>
                Use the calendar below to schedule a kick-off call with our
                team. We will use this kick-off call to walk you and your team
                through the Circular platform and discuss your first project in
                detail.
              </p>
              <InlineWidget
                url={`${process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_URL}?hide_gdpr_banner=1`}
                styles={{
                  height: '1090px',
                  minWidth: '320px'
                }}
                prefill={{
                  email: user?.email,
                  name: user?.name,
                  guests: teamEmails(),
                  customAnswers: {
                    a1: user?.company?.name
                  }
                }}
              />
            </>
          )}
        </section>
        <div className="error-section">
          {Object.keys(formErrors).map((k) => (
            <p key={k}>{formErrors[k]}</p>
          ))}
        </div>
        {/* <Button
          type="primary"
          className="submit-all-button"
          loading={busy}
          onClick={() => submitAll()}
        >
          Submit onboarding materials
        </Button> */}
      </div>
    </BarePageLayout>
  );
}

export default OnboardingPortal;

function CalendlyEventTime({ eventId, dateOnly }) {
  const { event, isLoading, isError, dateTime, timezone } =
    useCalendlyEvent(eventId);

  if ((!isLoading && !event) || isError) return null;

  if (isLoading) return 'Loading details...';

  return (
    <span>
      {dateTime} ({timezone} timezone)
    </span>
  );
}
CalendlyEventTime.propTypes = {
  eventId: PropTypes.string,
  dateOnly: PropTypes.bool
};
