import React from 'react';
import PropTypes from 'prop-types';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import { useSupplierName } from './SupplierName';

export default function SupplierAvatar({ supplier }) {
  const name = useSupplierName(supplier);
  const avatarSetting = useConciergeContext(['supplier', 'logos']);

  const src =
    avatarSetting === 'anonymize' ? supplier.anonymized_logo : supplier.avatar;

  if (!src) return null;

  return (
    <img src={src} alt={name} className={`supplier-avatar ${avatarSetting}`} />
  );
}

SupplierAvatar.propTypes = {
  supplier: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    anonymized_logo: PropTypes.string
  })
};
