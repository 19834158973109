import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import { fetch } from 'src/utils/client';

function ObjectInput({
  appName,
  modelName,
  query,
  attribute,
  value: valueIn,
  onChange,
  placeholder,
  additionalParams = {},
  getValue,
  parseData,
  getLabel,
  ...props
}) {
  const [options, setOptions] = useState([]);

  const path = `${appName !== 'api' ? `${appName}/` : ''}${modelName}`;

  const onSearch = async (value) => {
    if (!value || value.length < 4) {
      setOptions([]);
      return;
    }
    const params = { search: value, page_size: 25, ...additionalParams };
    let result = await fetch(
      `${process.env.REACT_APP_CIRCLE_API}/${path}/?${new URLSearchParams(
        params
      ).toString()}`
    );

    if (parseData) result = parseData(result);

    const resultList =
      (Array.isArray(result) && result) ||
      (Array.isArray(result?.results) && result.results) ||
      [];
    const list = resultList.map((o) => ({
      value: getValue ? getValue(o) : o.uuid,
      label: getLabel ? getLabel(o) : o.name || o.title
    }));
    setOptions(list);
  };

  const handleInput = (value, option) => {
    onChange(option);
    // onChange({ address });
  };

  const handleSelect = async (value, option) => {
    onChange(option);
  };

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      placeholder={placeholder}
      value={valueIn?.label || valueIn?.title}
      options={options}
      onChange={handleInput}
      onSelect={handleSelect}
      onSearch={onSearch}
      className="location-input"
      {...props}
    >
      <Input.Search size="large" enterButton />
    </Select>
  );
}

ObjectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  appName: PropTypes.string,
  modelName: PropTypes.string,
  query: PropTypes.string,
  attribute: PropTypes.string,
  onChange: PropTypes.func,
  getValue: PropTypes.func,
  getLabel: PropTypes.func,
  parseData: PropTypes.func,
  additionalParams: PropTypes.object
};

export default ObjectInput;
