import React, { useMemo, useState } from 'react';
import minMax from 'dayjs/plugin/minMax';

import dayjs from 'dayjs';
import { Select, Space, Table } from 'antd';
import { FORMS, REGIONS } from './dimensions';
import SupplierTdssCount from './SupplierTdssCount';
import { useSupplierAnalyticsData } from './SupplierAnalyticsGlobal';

dayjs.extend(minMax);

const regionOptions = Object.keys(REGIONS).map((value) => ({
  label: REGIONS[value].label,
  value
}));

function SupplierAnalyticsRegion() {
  const [region, setRegion] = useState(Object.keys(REGIONS)[0]);

  const {
    data: data_pet,
    isLoading: isLoading_pet,
    isError: isError_pet
  } = useSupplierAnalyticsData(['rPET']);
  const {
    data: data_pe,
    isLoading: isLoading_pe,
    isError: isError_pe
  } = useSupplierAnalyticsData(['rHDPE', 'rLDPE/rLLDPE']);
  const {
    data: data_pp,
    isLoading: isLoading_pp,
    isError: isError_pp
  } = useSupplierAnalyticsData(['rPP']);
  const {
    data: data_other,
    isLoading: isLoading_other,
    isError: isError_other
  } = useSupplierAnalyticsData(['rPET', 'rPP', 'rHDPE', 'rLDPE/rLLDPE'], true);
  const {
    data: data_total,
    isLoading: isLoading_total,
    isError: isError_total
  } = useSupplierAnalyticsData();

  const isLoading =
    isLoading_pet ||
    isLoading_pe ||
    isLoading_pp ||
    isLoading_other ||
    isLoading_total;

  const latest = useMemo(
    () =>
      dayjs.max(
        [
          ...Object.values(data_pet || {}),
          ...Object.values(data_pe || {}),
          ...Object.values(data_pp || {}),
          ...Object.values(data_other || {}),
          ...Object.values(data_total || {})
        ].map((item) => dayjs(item.latest))
      ),
    [data_pet, data_pe, data_pp, data_other, data_total]
  );

  const columns = [
    {
      title: '',
      className: 'row-header',
      dataIndex: 'type',
      key: 'type'
    },
    ...FORMS.map((formKey) => ({
      title: formKey,
      dataIndex: ['forms', formKey.replace(/ /g, '_')],
      key: formKey,
      render: (counts) => <SupplierTdssCount {...counts} />
    })),
    {
      title: 'Total',
      dataIndex: 'suppliers',
      key: 'total',
      render: (_suppliersCount, record) => <SupplierTdssCount {...record} />
    }
  ];

  const timestamp = latest ? dayjs(latest).format('hh:mm MMM DD, YYYY') : '';
  return (
    <div className="supplier-analytics-region">
      <Space direction="horizontal">
        <h2>Total Suppliers by Region</h2>
        <Select onChange={setRegion} value={region} options={regionOptions} />
      </Space>
      <div>
        <small>Updated {timestamp}</small>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={[
          { ...data_pet[region], type: 'rPET' },
          { ...data_pe[region], type: 'rHDPE/rLDPE' },
          { ...data_pp[region], type: 'rPP' },
          { ...data_other[region], type: 'Other' },
          { ...data_total[region], type: 'Total' }
        ]}
        pagination={false}
      />
    </div>
  );
}

export default SupplierAnalyticsRegion;
