import { useMemo } from 'react';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

function formatValue(v) {
  if (typeof v === 'number') return v;
  return `"${v}"`;
}

function csvRow(row) {
  return `${row.map((o) => formatValue(o)).join(',')}\n`;
}

export function useDownloadAntdTable(columns, data, title) {
  return useMemo(
    () => () => {
      const headers = csvRow(columns.map((c) => c.title));
      const body = data.map((row) =>
        csvRow(columns.map((c) => row[c.dataIndex]))
      );
      const blob = new Blob([headers, ...body], {
        type: 'text/csv;charset=utf-8'
      });
      const now = dayjs().format('YY-MM-DD-hh-mm-ss');
      saveAs(blob, `${title}-${now}.csv`);
    },

    [columns, data]
  );
}
