import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Skeleton, Typography } from 'antd';

import { useParams } from 'react-router-dom';
import LightPanel from 'src/components/layout/LightPanel';
import BarProgress from 'src/components/BarProgress';
/* eslint-disable import/no-unresolved */
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */

// I took this from patternomaly source because I needed to alter stroke width

function diagonal(color = 'black') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const size = 10;
  const stroke = 4;
  const strokeOffset = stroke / 2;
  canvas.width = size;
  canvas.height = size;

  context.strokeStyle = color; // 'rgba(0, 0, 0, 1)';
  context.lineWidth = stroke;

  // the offset is needed because of antialisaing. We always want to draw outside the edge
  context.moveTo(size / 2 - strokeOffset, -strokeOffset);
  context.lineTo(size + strokeOffset, size / 2 + strokeOffset);
  context.moveTo(-strokeOffset, size / 2 - strokeOffset);
  context.lineTo(size / 2 + strokeOffset, size + strokeOffset);
  context.stroke();
  // context.scale(1 / 10, 1 / 10);

  // return canvas;
  return context.createPattern(canvas, 'repeat');
}

export default function ManageReportsTab({ project, requestForQuote }) {
  const { rfqId } = useParams();

  // const { search: upNextSearch } = useUpNext(requestForQuote, project);

  if (!project || !requestForQuote) return <Skeleton />;
  const emissionsBreakdown = [
    {
      value: 180,
      type: 'high',
      label: (
        <>
          <strong>Material</strong> 36%
        </>
      )
    },
    {
      value: 109,
      type: 'medium',
      label: (
        <>
          <strong>Logistics</strong> 28%
        </>
      )
    },
    {
      value: 175,
      type: 'hatch',
      label: (
        <>
          <strong>Avoided</strong> 36%
        </>
      )
    }
  ];
  const emissionsChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: { top: 30, bottom: 30, left: 20, right: 20 }
    },
    plugins: {
      legend: {
        display: false
      },
      customCanvasBackgroundColor: {
        color: '#F3F7FB'
      }
    },
    scales: {
      yAxis: {
        stacked: true
      },
      x: {
        stacked: true,
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          font: {
            size: 14
          }
        }
      },
      y: {
        display: false,
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false
        }
      }
    }
  };
  const labels = [
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan'
  ];

  const emissionsChartData = {
    labels,
    datasets: [
      {
        barPercentage: 0.85,
        categoryPercentage: 1,
        label: 'Values',
        data: [150, 60, 60, 60, 150, 150, 60, 60, 150, 60, 60, 150],
        backgroundColor: '#0D33CC'
        // yAxisID: 'yAxis'
      },
      {
        barPercentage: 0.85,
        categoryPercentage: 1,
        label: 'Values',
        data: [390, 340, 380, 320, 410, 360, 315, 370, 330, 340, 360, 340],
        backgroundColor: '#3395FF'
        // yAxisID: 'yAxis'
      },
      {
        barPercentage: 0.85,
        categoryPercentage: 1,
        label: 'Delta',
        data: [330, 340, 360, 390, 340, 380, 320, 410, 360, 315, 370, 380],
        backgroundColor: diagonal('#cae4f7')
        // yAxisID: 'yAxis'
      }
    ]
  };
  return (
    <div className="reports-tab">
      <LightPanel className="tight-md breath-lg" style={{ height: '100%' }}>
        <Row>
          <Col span={5}>
            <Typography.Text className="xs upper breath">
              Total Project Emissions
            </Typography.Text>
            <div>
              <Typography.Text className="xxl">594&nbsp;</Typography.Text>
              <Typography.Text className="lg">tCO2e</Typography.Text>
              <Typography.Text className="xs light breath">
                36% avoided emissions
              </Typography.Text>
            </div>
          </Col>
          <Col span={7}>
            <Typography.Text className="xs upper breath">
              Emissions Credits
            </Typography.Text>
            <div>
              <Typography.Text className="xxl">$247,749</Typography.Text>
              <Typography.Text className="xs light breath">
                $2.47 per ton avoided
              </Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            <Typography.Text className="xs upper breath">
              Project Emissions Breakdown
            </Typography.Text>
            <BarProgress value={emissionsBreakdown} />
          </Col>
        </Row>
      </LightPanel>
      <Typography.Title level={5} className="breath-xl">
        Deal Emissions Distribution
      </Typography.Title>
      <div style={{ height: 480 }}>
        <Bar data={emissionsChartData} options={emissionsChartOptions} />
      </div>
    </div>
  );
}

ManageReportsTab.propTypes = {
  requestForQuote: PropTypes.object,
  project: PropTypes.array
};
