import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Typography } from 'antd';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';

import CaretUpDownIcon from 'src/components/icons/CarretUpDown';
import { currencyValuePrice } from 'src/utils/currency';

export default function PriceInsight({ tds, cohort, cohortPriceData }) {
  const cohortPriceAvg = cohortPriceData?.cohort_avg;
  const tdsLatestPrice = cohortPriceData?.latest_price;

  const tdsCohortPriceRelation = tdsLatestPrice / cohortPriceAvg;
  const tdsHasHigherPrice = tdsCohortPriceRelation > 1;
  const tdsCohortEqualPrice = tdsCohortPriceRelation === 1;

  const difference = Math.abs(tdsLatestPrice - cohortPriceAvg);

  const tdsBarClasses = tdsCohortEqualPrice
    ? 'equal-price'
    : tdsHasHigherPrice
    ? 'price-over'
    : 'price-under';

  const compareString = tdsCohortEqualPrice
    ? 'equal to'
    : tdsHasHigherPrice
    ? 'higher than'
    : 'lower than';

  const canCompare = tdsLatestPrice && cohortPriceAvg;
  return (
    <ExplorePanel className="tds-price-tile" pad>
      <Typography.Title className="tile-title mb-0" level={4}>
        Price Summary
      </Typography.Title>

      <div className="subheading">
        {canCompare ? (
          `Your price is ${currencyValuePrice(
            difference,
            cohortPriceData?.currency,
            cohortPriceData?.units
          )} ${compareString} the average material price in this supplier cohort.`
        ) : (
          <span className="warning-label">
            We currently do not have enough data to give you a cohort comparison
          </span>
        )}
      </div>

      <Flex vertical>
        <Typography.Text className="mb">Your Price</Typography.Text>
        <Typography.Text className="your-price mb-lg">
          {currencyValuePrice(
            tdsLatestPrice,
            cohortPriceData?.currency,
            cohortPriceData?.units
          )}
        </Typography.Text>
        {canCompare && (
          <>
            <div>
              <Typography.Text
                className={`comparison-percent ${tdsBarClasses} mr-xs`}
              >
                <CaretUpDownIcon
                  className="mx-xxs"
                  down={!tdsCohortEqualPrice && !tdsHasHigherPrice}
                  height={12}
                  width={12}
                />{' '}
                {Number.isNaN(tdsCohortPriceRelation)
                  ? '--'
                  : `${Math.round(
                      Math.abs((tdsCohortPriceRelation - 1) * 100)
                    )}%`}
              </Typography.Text>
              <Typography.Text>vs the Cohort Average Price</Typography.Text>
            </div>
            <Typography.Text className="mt-xl">
              Cohort Average Price
            </Typography.Text>
            <Typography.Text className="cohort-price mt-sm">
              {currencyValuePrice(
                cohortPriceAvg,
                cohortPriceData?.currency,
                cohortPriceData?.units
              )}
            </Typography.Text>
          </>
        )}
      </Flex>
    </ExplorePanel>
  );
}

PriceInsight.propTypes = {
  tds: PropTypes.object,
  cohort: PropTypes.object,
  cohortPriceData: PropTypes.object
};
