import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Timeline from 'src/components/timeline/Timeline';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TestLabSelect } from 'src/pages/testing/TestLab';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

function ProcureTestingTab({ project, requestForQuote }) {
  const demo = requestForQuote.status === 'demo';
  const labSelected = !!project?.deal.test_labs.find((o) => o.selected);
  const sampleResultId = project?.deal.sample_results[0]?.uuid;
  const fastForwardToResults = demo && labSelected && sampleResultId;
  const navigate = useNavigate();
  return (
    <div>
      <Timeline>
        <Timeline.Item>
          <Section>
            <h1>Lab Testing</h1>
            <StepCard number={1} title="Select testing lab">
              {project?.deal?.uuid && (
                <TestLabSelect dealId={project?.deal?.uuid} />
              )}
            </StepCard>
            <StepCard
              number={2}
              title="Samples shipped"
              inactive={!fastForwardToResults}
            >
              Shipped on {dayjs().format('YYYY-MM-DD')}
            </StepCard>
            <StepCard
              number={3}
              title="Testing complete"
              inactive={!fastForwardToResults}
            >
              Testing completed on {dayjs().format('YYYY-MM-DD')}
            </StepCard>
            <StepCard
              number={4}
              title="Test results ready to review"
              inactive={!fastForwardToResults}
            >
              <p>
                The results to your lab tests have been uploaded and are ready
                to review.
              </p>
              <Button
                type="default"
                onClick={() =>
                  navigate(
                    `/project/procure/${requestForQuote.uuid}/testing/${project?.deal.uuid}/results/${sampleResultId}`
                  )
                }
              >
                Review results
              </Button>
            </StepCard>
            <StepCard number={5} title="Batch approved" inactive />
          </Section>
        </Timeline.Item>
        <Timeline.Item inactive>
          <Section>
            <h1>Production Testing</h1>
            <StepCard number={1} title="Order samples" inactive />
            <StepCard number={2} title="Samples shipped" inactive />
            <StepCard
              number={3}
              title="Upload production test results"
              inactive
            />
          </Section>
        </Timeline.Item>
      </Timeline>
    </div>
  );
}

ProcureTestingTab.propTypes = {
  requestForQuote: PropTypes.object,
  project: PropTypes.array
};

export default ProcureTestingTab;

function StepCard({ title, number, children, inactive }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="testing-step-card">
      <div
        className={`testing-step-card--header ${inactive ? 'inactive' : ''}`}
        onClick={() => setExpanded(!expanded && !inactive)}
        role="presentation"
      >
        <span className="number">{number}</span>
        <h2>{title}</h2>
        {expanded ? <DownOutlined /> : <RightOutlined />}
      </div>
      <div
        style={{ display: expanded ? 'block' : 'none' }}
        className="testing-step-card--body"
      >
        {children}
      </div>
    </div>
  );
}

StepCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  inactive: PropTypes.bool,
  number: PropTypes.number
};

function Section({ title, children }) {
  return <div className="testing-section">{children}</div>;
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string
};
