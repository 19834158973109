import { Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useMap from 'src/pages/maps/hooks/useMap';
import { Marker } from 'src/pages/maps/Marker';
import getBoundingCoords from 'src/pages/maps/utils/getBoundingCoords';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';

/* eslint-disable react/no-array-index-key */

export default function SupplyMap({ rfq, tds }) {
  const [bounds, setBounds] = useState(undefined);
  const { map, mapContainer } = useMap({
    mapProps: { bounds }
  });

  const markers = useMemo(() => {
    if (!map) return [];

    const mapMarkers = [
      ...(tds.locations?.length
        ? tds.locations
            .map((loc, i) => (
              <ProposalMapMarker
                key={`${tds.uuid}-${i}`}
                id={`${tds.uuid}-${i}`}
                company={tds.company}
                location={loc}
                map={map}
                companyRole="supplier"
              />
            ))
            .slice(0, 1)
        : []),

      ...(rfq.shipping_addresses?.length
        ? rfq.shipping_addresses.map((loc, i) => (
            <ProposalMapMarker
              key={`${rfq.uuid}-${i}`}
              id={`${rfq.uuid}-${i}`}
              company={rfq.company}
              location={loc}
              map={map}
              companyRole="buyer"
            />
          ))
        : []
      ).slice(0, 1)
    ];

    const allPoints = [
      ...(tds.locations?.length ? tds.locations.slice(0, 1) : []),

      ...(rfq.shipping_addresses?.length
        ? rfq.shipping_addresses.slice(0, 1)
        : [])
    ].map((loc) => [Number(loc.latitude), Number(loc.longitude)]);

    setBounds(getBoundingCoords(allPoints));

    return mapMarkers;
  }, [rfq, tds, map]);

  return (
    <div className="single-rfq-proposal--supply--map" ref={mapContainer}>
      {markers}
    </div>
  );
}
SupplyMap.propTypes = {
  rfq: PropTypes.object,
  tds: PropTypes.object
};

function ProposalMapMarker({ company, location, map, id, companyRole }) {
  const avatarSetting = useConciergeContext([companyRole, 'logos']);
  if (!location?.latitude || !location?.longitude || !company) return null;
  const src =
    avatarSetting === 'anonymize' ? company.anonymized_logo : company.avatar;
  const name =
    avatarSetting === 'anonymize' ? company.anonymized_name : company.name;

  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={20}
      latitude={Number(location.latitude)}
      longitude={Number(location.longitude)}
    >
      <Tooltip
        className="map-marker-tooltip"
        color="white"
        title={name}
        overlayInnerStyle={{ color: '#323640' }}
      >
        {src ? <img src={src} alt={name} /> : null}
      </Tooltip>
    </Marker>
  );
}
ProposalMapMarker.propTypes = {
  company: PropTypes.object,
  location: PropTypes.object,
  map: PropTypes.object,
  companyRole: PropTypes.string,
  id: PropTypes.string
};
