import { bucketStepSize, normalizeMinMax } from 'src/utils/bucket';

const useChartSpecs = (min, max, bucketCount) => {
  const [normalizedMin, normalizedMax] = normalizeMinMax(min, max);

  const bucketSize = bucketStepSize(normalizedMin, normalizedMax, bucketCount);

  return {
    bucketSize,
    normalizedMax,
    normalizedMin
  };
};

export default useChartSpecs;
