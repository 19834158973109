import { Button, Modal, Row, Col, Typography, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DarkPanel from 'src/components/layout/DarkPanel';
import GoodnessRating from 'src/components/GoodnessRating';
import RainbowProgress from 'src/components/RainbowProgress';
import CheckedIcon from 'src/components/icons/Checked';
import FiltersSidebar from 'src/components/project/FiltersSidebar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

import 'src/pages/project/styles/Search.less';

import SearchContent from 'src/components/project/search/Content';
import useConfidenceSummary from 'src/hooks/useConfidenceSummary';
import ProjectHeader from 'src/components/project/ProjectHeader';
import { getProject } from 'src/Query';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { updateProject } from 'src/Mutation';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

function ProjectSearch() {
  const [start, setStart] = useState(true);
  const closeModal = () => {
    setStart(false);
  };
  const { pushCascade, popCascade } = useSidebarContext();
  useEffect(() => {
    pushCascade(undefined, 'search', 0);
    return () => popCascade('search');
  }, []);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');
  const isConcierge = useIsConcierge();

  const queryClient = useQueryClient();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      navigate(`/project/explore?project=${response.uuid}`);
    },
    onError: (e) => {
      navigate(`/project/explore?project=${projectUuid}`);
      // Send error to Sentry
      captureException(e);
    }
  });
  const [hideExplore] = useConciergeContextState(['explore', 'hide']);

  if (hideExplore) return undefined;

  return (
    <div className="search-page">
      <ProjectHeader
        title="Search"
        right={
          <Space direction="horizontal" size={12}>
            {projectUuid && isConcierge && (
              <Button
                onClick={() =>
                  updateProjectMutation({ uuid: projectUuid, stage: 'explore' })
                }
                htmlType="button"
                disabled={updating}
              >
                {updating ? 'Loading...' : 'Go back to Explore'}
              </Button>
            )}
            <Button disabled type="primary" htmlType="button">
              Proceed to Procure
            </Button>
          </Space>
        }
      />
      <Row wrap={false}>
        <Col flex="288px">
          <FiltersSidebar />
        </Col>
        <Col flex="1">
          <div className="content search-content">
            <SearchContent />
          </div>
        </Col>
      </Row>
      {/* <ProjectSearchStart closeModal={closeModal} modalOpen={start} /> */}
    </div>
  );
}

function ProjectSearchStart({ closeModal, modalOpen }) {
  const [exploreCtx, setExploreCtx] = useConciergeContextState(['explore']);

  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');
  const { data: project } = useQuery(
    ['project', projectUuid],
    () => getProject(projectUuid),
    { enabled: !!projectUuid }
  );

  useEffect(() => {
    if (project?.filters) {
      setExploreCtx({
        ...exploreCtx,
        filters: project.filters
      });
    }
  }, [project]);

  const { name, filters } = project || { name: '', filters: {} };

  const {
    locationRating,
    locationText,
    overallRating,
    overallRatingPercentage,
    pricingRating,
    pricingText,
    specContent,
    specRating
  } = useConfidenceSummary({ filters });

  return (
    <Modal
      open={modalOpen}
      show
      onOk={() => {}}
      footer={null}
      onCancel={closeModal}
      closeIcon={false}
      width={1000}
      className="search-page__start"
    >
      <h2>Welcome to Search</h2>
      <div className="welcome-message">
        You have been invited to continue the search for sustainable materials
        for your organization. Here is a recap of what your team found during
        their exploration.
      </div>
      <div>
        <DarkPanel>
          <div className="recap-header">
            <div>
              <h2>Explore Recap</h2>
              <div className="light-text">{name}</div>
            </div>
            <GoodnessRating pill rating={overallRating} />
          </div>
          <RainbowProgress progress={(overallRatingPercentage ?? 0) / 100} />
          <div className="boxes">
            <ProjectSearchStartPanel
              title="Location"
              content={locationText}
              rating={locationRating}
            />
            <ProjectSearchStartPanel
              title="Specifications"
              content={specContent}
              rating={specRating}
            />
            <ProjectSearchStartPanel
              title="Pricing"
              content={pricingText}
              rating={pricingRating}
            />
          </div>
        </DarkPanel>
        <div className="checked-items">
          <CheckedItem content="Create a digital RFQ based on your Explore dataset" />
          <CheckedItem content="Engage with suppliers through facilitated outreach" />
          <CheckedItem content="Compare and contrast supplier proposals" />
        </div>
      </div>
      <div className="button-container">
        <Button onClick={() => closeModal()} type="primary">
          Start Search
        </Button>
      </div>
    </Modal>
  );
}

ProjectSearchStart.propTypes = {
  closeModal: PropTypes.func,
  modalOpen: PropTypes.bool
};

export default ProjectSearch;

export function ProjectSearchStartPanel({ title, content, rating }) {
  return (
    <DarkPanel.Box>
      <Typography.Title className="box-title" level={5}>
        {title}
      </Typography.Title>
      <div className="panel-content">
        {Array.isArray(content) ? (
          content.map((item, index) => (
            <p className="light-text" key={index}>
              {item}
            </p>
          ))
        ) : (
          <p className="light-text">{content}</p>
        )}
      </div>
      <GoodnessRating rating={rating} />
    </DarkPanel.Box>
  );
}

ProjectSearchStartPanel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  rating: PropTypes.string
};

function CheckedItem({ content }) {
  return (
    <div>
      <CheckedIcon />
      {content}
    </div>
  );
}

CheckedItem.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
