import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from 'antd';

export default function LoadingErrorOrContent({ isLoading, error, children }) {
  if (isLoading) return <Skeleton />;
  if (error)
    return <Alert type="error" message={JSON.stringify(error)} showIcon />;

  return children;
}
LoadingErrorOrContent.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node
};
