import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from 'src/utils/authentication';
import { isConcierge } from 'src/utils/permissions';
import { useIsFetching } from '@tanstack/react-query';
import { Dropdown } from 'antd';

const matchStates = [true, false, null];
const matchStateLabels = ['Match', 'No match', 'Unknown'];
const matchIcons = [
  <CheckCircleFilled
    key="match"
    style={{
      color: '#51ce92'
    }}
  />,
  <CloseCircleFilled
    key="nomatch"
    style={{
      color: '#8c8c8c'
    }}
  />,
  <MinusOutlined
    key="unknown"
    style={{
      color: '#8c8c8c'
    }}
  />
];

export default function SupplyComparisonMatchIcon({ match, onChange }) {
  const { data: user } = useUser();
  const editable = isConcierge(user) && onChange;

  const currentMatchStateIndex = matchStates.findIndex(
    (state) => state === match
  );
  const [matchIndex, setMatchIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [hovering, setHovering] = useState(false);

  const isFetchingRfqDeals = useIsFetching({ queryKey: ['rfqDeals'] });

  useEffect(
    () => setMatchIndex(currentMatchStateIndex),
    [currentMatchStateIndex]
  );

  return (
    <Dropdown
      open={!editable ? false : undefined}
      menu={{
        items: matchIcons.map((mi, i) => ({
          key: i,
          icon: mi,
          label: matchStateLabels[i],
          onClick: async ({ key }) => {
            if (!onChange || loading || isFetchingRfqDeals) return;

            setLoading(true);

            await onChange(matchStates[key]);

            setLoading(false);
          }
        }))
      }}
    >
      <button
        type="button"
        className={`bare match-icon ${
          loading || isFetchingRfqDeals ? 'loading' : ''
        } ${editable ? 'editable' : ''}`}
        onClick={null}
      >
        {hovering && !(loading || isFetchingRfqDeals)
          ? matchIcons[
              matchIndex + 1 >= matchStates.length ? 0 : matchIndex + 1
            ]
          : matchIcons[matchIndex]}
      </button>
    </Dropdown>
  );
}
SupplyComparisonMatchIcon.propTypes = {
  match: PropTypes.oneOf([true, false, null]),
  onChange: PropTypes.func
};
