import React from 'react';
import { ReactComponent as Icon } from './svg/upNext.svg';
import SvgIconComponent from './SvgIconComponent';

function UpNextIcon() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default UpNextIcon;
