import { useQuery } from '@tanstack/react-query';
import { Alert, Divider, Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getRFQ, getDeals } from 'src/Query';
import ConciergeToolbar from 'src/components/concierge/ConciergeToolbar';
import { rfqModelName } from 'src/utils/model_names';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import NoData from 'src/components/busy/NoData';
import BuyerAvatar from './buyer/BuyerAvatar';
import SingleProposal from './SingleProposal';
import ProposalComparison from './comparison';

import './Proposal.less';

function tabLabel(index) {
  return `${dayjs
    .localeData()
    .ordinal(index + 1)
    .replace(/[[\]]/g, '')} Choice`;
}

function Proposal() {
  const { rfqId } = useParams();
  const {
    data: rfq,
    isLoading: rfqLoading,
    error: rfqError
  } = useQuery({
    queryKey: ['requestForQuote', rfqId],
    queryFn: () => getRFQ(rfqId)
  });
  const {
    data: deals,
    isLoading: dealsLoading,
    error: dealsError
  } = useQuery({
    queryKey: ['rfqDeals', rfqId],
    queryFn: () => getDeals({ rfq: rfqId, all: true })
  });

  const viewAsGuest = useConciergeContext('viewAsGuest');

  if (rfqLoading || dealsLoading) return <NoData />;

  if (rfqError || dealsError) {
    window.console.log('rfqError', rfqError, dealsError);
    return (
      <Alert
        type="error"
        message="Error fetching RFQ Proposal. Details in the console."
      />
    );
  }

  if (!rfq || !deals) return undefined;

  const validDeals = viewAsGuest
    ? deals.filter((d) => d.status !== 'draft')
    : deals;

  return (
    <div className="rfq-proposals-wrap" data-test-id="pop">
      <div className="rfq-proposals">
        <AdminConsoleLink app="api" type="requestforquote" uuid={rfq.uuid} />
        <div className="rfq-proposals--header">
          {rfq.company.logo && (
            <>
              <BuyerAvatar buyer={rfq.company} />
              <Divider type="vertical" />
            </>
          )}
          <h3>{rfq.title}</h3>
        </div>

        <Tabs
          className="rfq-proposals--tabs"
          data-testid="rfq-proposals--tabs"
          items={[
            {
              key: '__comparison',
              label: 'Comparison',
              children: <ProposalComparison rfq={rfq} deals={validDeals} />
            },
            ...(validDeals?.length
              ? validDeals.map((p, i) => ({
                  key: p.uuid,
                  label: p.label || tabLabel(i),
                  children: <SingleProposal deal={p} />
                }))
              : [
                  {
                    key: 'no-deals',
                    label: '',
                    children: <Alert type="warning" message="No deals found" />
                  }
                ])
          ]}
        />
      </div>
      <ConciergeToolbar
        magicLinkPermissions={[
          {
            model: rfqModelName,
            uuid: rfqId
          }
        ]}
      />
    </div>
  );
}

export default Proposal;
