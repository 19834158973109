import { StarFilled, StarOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';

import './Stars.less';

function Stars({ percent, emptyStarsStyle = 'filled' }) {
  const EmptyStar = emptyStarsStyle === 'outlined' ? StarOutlined : StarFilled;
  return (
    <div className="stars-score stars-score-bg">
      <EmptyStar />
      <EmptyStar />
      <EmptyStar />
      <EmptyStar />
      <EmptyStar />
      <div style={{ width: `${percent * 100}%` }} className="stars-score-fill">
        <StarFilled />
        <StarFilled />
        <StarFilled />
        <StarFilled />
        <StarFilled />
      </div>
    </div>
  );
}

Stars.propTypes = {
  percent: PropTypes.number,
  emptyStarsStyle: PropTypes.oneOf(['filled', 'outlined'])
};

export default Stars;
