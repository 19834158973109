import React from 'react';
import SupplierAnalyticsGlobal from './SupplierAnalyticsGlobal';
import SupplierAnalyticsRegion from './SupplierAnalyticsRegion';

function SupplierAnalytics() {
  return (
    <>
      <SupplierAnalyticsGlobal />
      <SupplierAnalyticsRegion />
    </>
  );
}

export default SupplierAnalytics;
