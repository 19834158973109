import React, { useMemo, useState } from 'react';
import { Col, Divider, Row, Select, Space } from 'antd';

import AnalyticsTickerPrice from 'src/components/analytics/AnalyticsTickerPrice';
import AnalyticsTickerLineChart from 'src/components/analytics/AnalyticsTickerLineChart';
import AnalyticsTickerBarChart from 'src/components/analytics/AnalyticsTickerBarChart';
import MapPage from 'src/pages/maps/MapPage';

import './Analytics.less';
import { boundsToMapBoxBounds, regionMap } from 'src/utils/regions';

import { materialPropertyCodesForType } from 'src/utils/marketPrice';

const materialOptions = [
  {
    label: 'rPET',
    value: 'rPET'
  },
  {
    label: 'rPP',
    value: 'rPP'
  },
  {
    label: 'rHDPE',
    value: 'rHDPE'
  },
  {
    label: 'rLDPE',
    value: 'rLDPE/rLLDPE'
  }
];

const SPARKLINE_WEEKS = 13; // 3 months

const regionOptions = Object.keys(regionMap).map((key) => ({
  value: key,
  label: regionMap[key].name
}));

const virginType = (type) => type.replace(/^r/, '');

function Analytics() {
  // const typeProperty = useMetaProperty('type');
  const [type, setType] = useState('rPET');
  const [region, setRegion] = useState('us');
  const isType = (types) => types.indexOf(type) >= 0;
  const regionData = region && regionMap[region];
  const regionName = regionData?.name;
  const subRegionTickers = useMemo(
    () =>
      regionData?.subRegions?.map((r) => ({
        title: regionMap[r].name,
        material_property_codes: materialPropertyCodesForType(
          type,
          `form.Resin / Pellets,source-type.Post-consumer`
        ),
        region: r
      })) || undefined,
    [region, type]
  );
  const comparisons = useMemo(
    () =>
      (regionData?.comparisons && [region, ...regionData.comparisons]) ||
      undefined,
    [type, region]
  );
  const comparisonTickers = useMemo(
    () =>
      comparisons?.map((r) => ({
        title: regionMap[r].name,
        material_property_codes: materialPropertyCodesForType(
          type,
          `form.Resin / Pellets,source-type.Post-consumer`
        ),
        region: r
      })) || undefined,
    [comparisons]
  );
  return (
    <div className="analytics-page">
      <h1>Market Insights</h1>
      <div className="filter-bar">
        <Space>
          <Select
            showArrow
            showSearch
            value={type}
            onChange={setType}
            options={materialOptions}
          />
          <Select
            showArrow
            showSearch
            value={region}
            onChange={setRegion}
            options={regionOptions}
          />
        </Space>
      </div>

      <Divider />
      <div className="analytics-page-body">
        <MapPage
          mapProps={{
            bounds: boundsToMapBoxBounds(regionData?.boundingRectangle)
          }}
          // filters={filters}
          // additionalMarkers={rfqMarkers || []}
        />

        <Row gutter={[36, 36]}>
          <Col span={16}>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <div className="analytics-ticker-wrapper">
                  <AnalyticsTickerPrice
                    ticker={{
                      title: `${virginType(type)} Virgin ${regionName}`,
                      query: {
                        virgin: true,
                        type
                      },
                      sample_count: SPARKLINE_WEEKS,
                      region
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="analytics-ticker-wrapper">
                  <AnalyticsTickerPrice
                    ticker={{
                      title: `${type} Resin ${regionName}`,
                      query: {
                        virgin: false,
                        form_code: 'resin_pellets',
                        type
                      },
                      sample_count: SPARKLINE_WEEKS,
                      region
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="analytics-ticker-wrapper">
                  <AnalyticsTickerPrice
                    ticker={{
                      title: `${type} Flake ${regionName}`,
                      query: {
                        form_code: 'regrind_flakes',
                        type
                      },
                      sample_count: SPARKLINE_WEEKS,
                      region
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="analytics-ticker-wrapper">
                  <AnalyticsTickerPrice
                    ticker={{
                      title: `${virginType(type)} Bale ${regionName}`,
                      query: {
                        form_code: 'bales',
                        type
                      },
                      sample_count: SPARKLINE_WEEKS,
                      region
                    }}
                  />
                </div>
              </Col>
            </Row>
            <AnalyticsTickerLineChart
              tickers={[
                {
                  title: `${virginType(type)} Bales ${regionName}`,
                  material_property_codes: materialPropertyCodesForType(
                    type,
                    `form.Bales,source-type.Post-consumer`
                  ),
                  region
                },
                {
                  title: `${type} Regrind / Flakes ${regionName}`,
                  material_property_codes: materialPropertyCodesForType(
                    type,
                    `form.Regrind / Flakes,source-type.Post-consumer`
                  ),
                  region
                },
                {
                  title: `${type} Resin ${regionName}`,
                  material_property_codes: materialPropertyCodesForType(
                    type,
                    `form.Resin / Pellets,source-type.Post-consumer`
                  ),
                  region
                }
              ]}
              colorSeed={0}
            />
            <Row gutter={16}>
              <Col span={12}>
                <AnalyticsTickerLineChart
                  tickers={[
                    {
                      title: `${virginType(type)} Virgin ${regionName}`,
                      material_property_codes: materialPropertyCodesForType(
                        type,
                        `source-type.Virgin`
                      ),
                      region
                    },
                    {
                      title: `${type} ${regionName}`,
                      material_property_codes: materialPropertyCodesForType(
                        type,
                        `form.Resin / Pellets,source-type.Post-consumer`
                      ),
                      region
                    }
                  ]}
                  colorSeed={3}
                />
              </Col>
              {isType(['rPET']) && (
                <Col span={12}>
                  <AnalyticsTickerLineChart
                    tickers={[
                      {
                        title: `${type} Food Grade ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,grade.Food Grade`
                        ),
                        region
                      },
                      {
                        title: `${type} Non-Food Grade ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,grade.Non-Food Grade`
                        ),
                        region
                      }
                    ]}
                    colorSeed={5}
                  />
                </Col>
              )}
              {isType(['rPP']) && (
                <Col span={12}>
                  <AnalyticsTickerLineChart
                    tickers={[
                      {
                        title: `${type} HoPP ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,polymer-type.Homopolymer`
                        ),
                        region
                      },
                      {
                        title: `${type} CoPP ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,polymer-type.Copolymer`
                        ),
                        region
                      }
                    ]}
                    colorSeed={5}
                  />
                </Col>
              )}
              {isType(['rHDPE']) && (
                <Col span={12}>
                  <AnalyticsTickerLineChart
                    tickers={[
                      {
                        title: `${type} Natural color ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,color.Natural`
                        ),
                        region
                      },
                      {
                        title: `${type} Multi colors ${regionName}`,
                        material_property_codes: materialPropertyCodesForType(
                          type,
                          `form.Resin / Pellets,source-type.Post-consumer,color.Multi`
                        ),
                        region
                      }
                    ]}
                    colorSeed={5}
                  />
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              {subRegionTickers && (
                <Col span={12}>
                  <AnalyticsTickerBarChart
                    titles={`${type} Regional Price Comparison`}
                    tickers={subRegionTickers}
                    colorSeed={5}
                  />
                </Col>
              )}
              {comparisonTickers && (
                <Col span={12}>
                  <AnalyticsTickerBarChart
                    titles={`${type} Price Comparison`}
                    tickers={comparisonTickers}
                    colorSeed={6}
                  />
                </Col>
              )}
            </Row>
          </Col>
          {/* <Col span={8}>
          <h2>{type} News</h2>
        </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default Analytics;
