import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { Button } from 'antd';
/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */
import { currencySymbol } from 'src/utils/currency';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#F3F7FB';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

export const options = {
  // responsive: true,
  aspectRatio: 1.25,
  layout: {
    padding: { top: 30, bottom: 30, left: 20, right: 20 }
  },
  plugins: {
    legend: {
      display: false
    },
    customCanvasBackgroundColor: {
      color: '#F3F7FB'
    }
  },
  scales: {
    yAxis: {
      display: false,
      stacked: true
    },
    x: {
      stacked: true,
      border: {
        display: false
      },
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false
      },
      ticks: {
        font: {
          size: 14
        }
      }
    },
    y: {
      display: false,
      border: {
        display: false
      },
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false
      }
    }
  }
};

// I took this from patternomaly source because I needed to alter stroke width
function diagonal(color = 'black') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const size = 10;
  const stroke = 4;
  const strokeOffset = stroke / 2;
  canvas.width = size;
  canvas.height = size;

  context.strokeStyle = color; // 'rgba(0, 0, 0, 1)';
  context.lineWidth = stroke;

  // the offset is needed because of antialisaing. We always want to draw outside the edge
  context.moveTo(size / 2 - strokeOffset, -strokeOffset);
  context.lineTo(size + strokeOffset, size / 2 + strokeOffset);
  context.moveTo(-strokeOffset, size / 2 - strokeOffset);
  context.lineTo(size / 2 + strokeOffset, size + strokeOffset);
  context.stroke();
  // context.scale(1 / 10, 1 / 10);

  // return canvas;
  return context.createPattern(canvas, 'repeat');
}

const labels = ['Virgin', 'PCR'];

export const chartData = (virgin, pcr) => ({
  labels,
  datasets: [
    {
      barPercentage: 0.85,
      categoryPercentage: 1,
      label: 'Values',
      data: [virgin, pcr],
      backgroundColor: '#096DD9'
      // yAxisID: 'yAxis'
    },
    {
      barPercentage: 0.85,
      categoryPercentage: 1,
      label: 'Delta',
      data: [0, Math.max(virgin - pcr, 0)],
      backgroundColor: diagonal('#cae4f7')
      // yAxisID: 'yAxis'
    }
  ]
});

function DealEmissions({ emissions, materialType }) {
  if (!emissions) {
    return (
      <div className="deal-emissions-none">
        There is no current proposal for emission certification on this deal. If
        you are interested in exploring ways to monetize your emission
        reductions or to improve emissions reporting, please contact us.
      </div>
    );
  }
  const {
    virgin_emissions_tco2e,
    pcr_emissions_tco2e,
    cost_per_tco2e,
    currency,
    certificate,
    virgin_type,
    quantity,
    quantity_units
  } = emissions;
  const avoided_emissions_tco2e = Math.max(
    virgin_emissions_tco2e - pcr_emissions_tco2e,
    0
  );
  const total_value = avoided_emissions_tco2e * cost_per_tco2e;
  const TCO2E = 'tCO2e';
  const NUMBER_FORMAT = '0,0';
  const PRICE_FORMAT = '0.00';
  return (
    <div className="deal-emissions">
      {certificate && (
        <Button
          onClick={() => window.open(certificate, '_blank')}
          type="primary"
        >
          Download Certificate
        </Button>
      )}
      <h2>Avoided Emissions Opportunity</h2>
      <h4>Measure and monetize your avoided emissions.</h4>
      <div className="deal-emissions-body">
        <div className="deal-emissions-chart">
          <Bar
            options={options}
            data={chartData(
              virgin_emissions_tco2e || 0,
              pcr_emissions_tco2e || 0
            )}
            plugins={[plugin]}
          />
        </div>

        <table className="deal-emissions-details">
          <tbody>
            <tr>
              <td>
                {numeral(quantity).format(NUMBER_FORMAT)} {quantity_units} of
                Virgin {virgin_type || ''} emissions
              </td>
              <td>
                <span className="value">
                  {numeral(virgin_emissions_tco2e).format(NUMBER_FORMAT)}
                </span>{' '}
                {TCO2E}
              </td>
            </tr>
            <tr>
              <td>
                {numeral(quantity).format(NUMBER_FORMAT)} {quantity_units} of
                PCR {materialType || ''} emissions
              </td>
              <td>
                <span className="value">
                  {numeral(pcr_emissions_tco2e).format(NUMBER_FORMAT)}
                </span>{' '}
                {TCO2E}
              </td>
            </tr>
            <tr>
              <td>Avoided emissions</td>
              <td>
                <span className="value">
                  {numeral(avoided_emissions_tco2e).format(NUMBER_FORMAT)}
                </span>{' '}
                {TCO2E}
              </td>
            </tr>
            <tr>
              <td>Value per ton of avoided emissions</td>
              <td>
                <span className="value">
                  {currencySymbol(currency)}{' '}
                  {numeral(cost_per_tco2e).format(PRICE_FORMAT)}
                </span>
              </td>
            </tr>
            <tr>
              <td>Market Value of Abated Emissions</td>
              <td>
                <span className="total-value">
                  {currencySymbol(currency)}{' '}
                  {numeral(total_value).format(NUMBER_FORMAT)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

DealEmissions.propTypes = {
  emissions: PropTypes.object,
  materialType: PropTypes.string
};

export default DealEmissions;
