import { Divider } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'src/components/Logo';
import UserMenu from './UserMenu';
import AdminMenu from './AdminMenu';

function Header({ left, right }) {
  return (
    <div className="old-project-header">
      <div className="old-project-header-contents">
        <div>{left || <Logo />}</div>
        {right || (
          <>
            <AdminMenu />
            <Divider type="vertical" />
            <UserMenu />
          </>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node
};

export default Header;
