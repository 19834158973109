import { Checkbox, Divider, Skeleton } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { applyFilterToPropertyOptions } from 'src/utils/properties';

export default function PropertyFilter({ propertyCode, filterOptions }) {
  const property = useMetaProperty(propertyCode);
  const [propertyFilter, setPropertyFilter, clearPropertyFilter] =
    useConciergeContextState([
      'explore',
      'filters',
      'properties',
      property?.code
    ]);

  const filteredOptions = useMemo(
    () =>
      property
        ? filterOptions
          ? applyFilterToPropertyOptions(
              property.options,
              filterOptions,
              propertyFilter
            )
          : property.options
        : [],
    [filterOptions, property, propertyFilter]
  );

  if (!property) return <Skeleton />;

  const onPropertyFilterValuesChange = (values) => {
    if (!values || values.length === 0) {
      clearPropertyFilter();
      return;
    }
    setPropertyFilter(values);
  };

  return (
    <>
      <Checkbox
        onChange={(e) =>
          onPropertyFilterValuesChange(
            e.target.checked ? filteredOptions.map((o) => o.code) : []
          )
        }
        indeterminate={
          propertyFilter?.length > 0 &&
          propertyFilter?.length < filteredOptions.length
        }
        checked={propertyFilter?.length === filteredOptions.length}
      >
        Select all
      </Checkbox>
      <Divider style={{ margin: '10px 0' }} />
      <Checkbox.Group
        options={filteredOptions.map((o) => ({
          label: o.value,
          value: o.code
        }))}
        value={propertyFilter}
        onChange={onPropertyFilterValuesChange}
      />
    </>
  );
}

PropertyFilter.propTypes = {
  propertyCode: PropTypes.string,
  filterOptions: PropTypes.func
};
