import { Alert, Button, Card, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { ArrowRightOutlined, WarningFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getRfqProject } from 'src/Query';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { currencyValuePrice } from 'src/utils/currency';
import UpsellModal from './UpsellModal';

import emissionsBreakdown from './demo/emissionsBreakdown.png';
import emissionsBarChart from './demo/emissionsBarChart.png';
import shipmentProgressBar from './demo/shipmentProgressBar.png';
import shipmentMap from './demo/shipmentMap.png';

export default function ManageTab() {
  const { rfqId } = useParams();

  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  if (isLoading) return <Skeleton />;

  const { request_for_quote: requestForQuote } = rfqProject;

  return (
    <div className="manage-tab">
      {requestForQuote.status === 'demo' && <UpsellModal active={2} />}

      <Row gutter={[44, 0]}>
        <Col span={16}>
          <h2>Project Carbon Forecast</h2>
          <Card className="search-highlights">
            <Row>
              <Col span={6}>
                <h4 className="search-highlights-header">
                  Total Project Emissions
                </h4>
                <div className="search-highlights-value">
                  {prettyNumberRound(594)}
                  <span className="small thin"> tCO2e</span>
                </div>
                <div className="search-highlights-extra">
                  36% avoided emissions
                </div>
              </Col>
              <Col span={6}>
                <h4 className="search-highlights-header">Emissions Credits</h4>
                <div className="search-highlights-value">
                  {currencyValuePrice(247749, 'USD')}
                </div>
                <div className="search-highlights-extra">
                  $2.47 per ton avoided
                </div>
              </Col>
              <Col span={12}>
                <h4 className="search-highlights-header">
                  Project Emissions Breakdown
                </h4>
                <div className="search-highlights-value">
                  <img src={emissionsBreakdown} alt="" width="100%" />
                </div>
              </Col>
            </Row>
          </Card>
          <div className="emissions-bar-chart">
            <img src={emissionsBarChart} alt="" width="100%" />
          </div>
        </Col>
        <Col span={8}>
          <Card style={{ marginTop: 24 }}>
            <h3 style={{ marginBottom: 8 }}>Shipment Progress</h3>
            <div style={{ marginBottom: 24 }} className="subtitle">
              <strong>Next shipment:</strong> October 27, 2023
            </div>
            <div className="shipment-progress">
              <img src={shipmentProgressBar} alt="" width="100%" />
              <div className="shipment-progress-details">
                Shipments delivered <span className="thin">4/12</span>
              </div>
            </div>
            <Alert
              type="warning"
              message={
                <>
                  <WarningFilled /> Action needed
                </>
              }
              action={
                <Button htmlType="button">
                  Manage shipments
                  <ArrowRightOutlined />
                </Button>
              }
            />
            <div className="shipment-map">
              <img src={shipmentMap} alt="" width="100%" />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
