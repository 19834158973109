import React from 'react';
import PropTypes from 'prop-types';

function SidebarSteps({ steps, active, activeSlide }) {
  return (
    <div className="sidebar-steps">
      {steps.map((s, i) => {
        const isActive = i === active;
        const isCompleted = i < active;
        return (
          <div
            key={s.title}
            className={`step ${isActive ? 'active' : ''}  ${
              isCompleted ? 'completed' : ''
            }`}
          >
            <div className="step-header">
              <div className="step-number">{i + 1}</div>
              <div className="step-title">{s.title}</div>
            </div>
            <div className="step-content">
              {s.activeText && (
                <div className="active-text">{s.activeText}</div>
              )}
              {Array.isArray(s.substeps) && (
                <div className="sub-steps">
                  {s.substeps.map((substep, index) => (
                    <p
                      key={substep}
                      className={`sub-step ${
                        index === activeSlide ? 'active' : ''
                      }`}
                    >
                      {substep}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

SidebarSteps.propTypes = {
  steps: PropTypes.array,
  active: PropTypes.number,
  activeSlide: PropTypes.number
};

export default SidebarSteps;
