import React, { useState } from 'react';
import { Alert, Button, Form, Input, Modal, Select, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { CopyOutlined } from '@ant-design/icons';
import { createCompany, createSesameLink } from 'src/Mutation';
import ObjectInput from 'src/components/form/ObjectInput';
import useIsConcierge from 'src/hooks/useIsConcierge';

const { useForm } = Form;

export default function useSesameInvite(permissions) {
  const userIsConcierge = useIsConcierge();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [creatingNewCompany, setCreatingNewCompany] = useState(false);
  const [link, setLink] = useState();
  const [form] = useForm();

  const { mutateAsync } = useMutation({
    mutationFn: createSesameLink,
    onError: (e) => {
      setError('Error creating the invitation link');
    }
  });

  const { mutateAsync: createCompanyAsync } = useMutation({
    mutationFn: createCompany,
    onError: (e) => {
      setError('Error creating company');
    }
  });

  if (!userIsConcierge) {
    return {};
  }

  const createLink = async ({ name, email, company, url, groups }) => {
    setLink();
    setError();

    const trimmedName = name ? name.trim() : name;
    const trimmedEmail = email ? email.trim() : email;

    let companyUuid;

    if (company.name) {
      const companyRes = await createCompanyAsync({
        name: company.name,
        contact_name: trimmedName,
        email: trimmedEmail
      });
      companyUuid = companyRes.uuid;
      setCreatingNewCompany(false);
      form.setFieldValue('company', {
        label: companyRes.name,
        value: companyUuid
      });
    } else {
      companyUuid = company.value;
    }

    const response = await mutateAsync({
      name: trimmedName,
      email: trimmedEmail,
      company_uuid: companyUuid,
      permissions,
      groups
    }).catch(() => {
      setError('Error creating the invitation link');
    });

    const redirect = encodeURIComponent(
      url && url.startsWith('/') ? url : `/${url}`
    );

    setLink(
      `${window.origin}/sesame/login${response.query_string}&next=${redirect}`
    );
  };

  const ModalComponent = (
    <Modal
      className="sesame-invite-modal"
      width={600}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      {error && <Alert message={error} type="error" closable showIcon />}
      {link && (
        <Alert
          message={`Magic link for user ${form.getFieldValue(
            'email'
          )} to access ${window.origin}${form.getFieldValue('url')}`}
          description={
            <>
              <span data-testid="magicLink">{link}</span>
              <br />
              <Button
                size="small"
                type="default"
                style={{ marginTop: 10 }}
                onClick={() => {
                  global.navigator.clipboard.writeText(link);
                }}
              >
                <CopyOutlined /> Copy link
              </Button>
            </>
          }
          type="success"
          closable
          showIcon
        />
      )}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={createLink}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="URL"
          name="url"
          initialValue="/supplier/onboarding"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          getValueFromEvent={(e) => {
            const { value } = e.target;
            return value.replace(window.origin, '');
          }}
        >
          <Input addonBefore={window.origin} />
        </Form.Item>
        <Form.Item
          label="User Name"
          name="name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Company Email"
          name="email"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email address'
            },
            {
              required: true,
              message: 'Please input your email address'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="groups"
          initialValue="Supplier"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            options={[
              {
                label: 'Buyer',
                value: 'Buyer'
              },
              {
                label: 'Supplier',
                value: 'Supplier'
              }
            ]}
          />
        </Form.Item>
        {!creatingNewCompany ? (
          <Form.Item
            label="Company"
            name="company"
            extra={
              <>
                Wrap phrases in 'single quotes' to return exact matches only.
                <br />
                Can't find the company you're looking for?
                <Button
                  onClick={() => setCreatingNewCompany(true)}
                  htmlType="button"
                  type="link"
                >
                  Create a new one
                </Button>
              </>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <ObjectInput appName="api" modelName="company" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Company Name"
            name={['company', 'name']}
            extra={
              <>
                This will create a new company in our database
                <Button
                  onClick={() => setCreatingNewCompany(false)}
                  htmlType="button"
                  type="link"
                >
                  Search for an existing company
                </Button>
              </>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
        )}
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Create link
          </Button>
          <Button type="default" onClick={() => form.resetFields()}>
            Clear
          </Button>
          <Button type="default" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );

  return {
    ModalComponent,
    link,
    openModal: () => setOpen(true)
  };
}
