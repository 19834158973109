import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography } from 'antd';
import LogPricingForm from 'src/components/supplier/LogPricingForm';
import PriceLogs from 'src/components/supplier/PriceLogs';
import useIsConcierge from 'src/hooks/useIsConcierge';

export default function LogPricingModal({ tdsLocationId }) {
  const { ModalComponent, openModal } = useLogPricingModal(tdsLocationId);
  return (
    <>
      <Button onClick={openModal} size="small" htmlType="button">
        Log Pricing
      </Button>
      {ModalComponent}
    </>
  );
}
LogPricingModal.propTypes = {
  tdsLocationId: PropTypes.string
};

export function LogPricingModalTrigger({ tdsLocationId, children }) {
  const { ModalComponent, openModal } = useLogPricingModal(tdsLocationId);
  const isConcierge = useIsConcierge();

  if (isConcierge)
    return (
      <>
        <button
          onClick={openModal}
          title="Log price for this material"
          className="bare"
          style={{ display: 'block', width: '100%' }}
          type="button"
        >
          {children}
        </button>
        {ModalComponent}
      </>
    );

  return children;
}
LogPricingModalTrigger.propTypes = {
  tdsLocationId: PropTypes.string,
  children: PropTypes.node
};

export function useLogPricingModal(tdsLocationId) {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const ModalComponent = (
    <Modal
      width={817}
      open={open}
      onCancel={closeModal}
      cancelText="Close"
      // eslint-disable-next-line react/no-unstable-nested-components
      footer={(_, { CancelBtn }) => <CancelBtn />}
      title="Log Material Transactions"
    >
      <Typography.Paragraph>
        Log your most recent transaction prices for this material, which we'll
        use to benchmark you against other comparable suppliers that you can
        reference. Going forward we recommend adding a transaction per week to
        keep prices current and relevant.
      </Typography.Paragraph>
      <LogPricingForm tdsLocationId={tdsLocationId} />
      <Typography.Title style={{ marginTop: 40, marginBottom: 25 }} level={4}>
        Pricing History
      </Typography.Title>
      <PriceLogs tdsLocationId={tdsLocationId} />
    </Modal>
  );

  return {
    ModalComponent,
    openModal,
    closeModal
  };
}
