import './utils/logger-init';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import App from './App';

import reportWebVitals from './reportWebVitals';

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 2000
});

const ONE_HOUR = 1000 * 60 * 60;
const TEN_MINUTES = 1000 * 60 * 10;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: (failureCount, err) => err?.status !== 404 && failureCount < 2,
      staleTime: TEN_MINUTES
    }
  }
});

// persistQueryClient({
//   queryClient,
//   persister: localStoragePersister,
//   maxAge: ONE_HOUR
// });

// import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV_NAME
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    {/* <Helmet> */}
    {/*  <meta */}
    {/*    httpEquiv="Content-Security-Policy" */}
    {/*    content="default-src *.sendbird.com; manifest-src 'self'; connect-src 'self' *.sendbird.com; img-src 'self' circular-branding.s3.amazonaws.com  circular-public-dev.s3.amazonaws.com; media-src circular-branding.s3.amazonaws.com; script-src 'self' 'unsafe-inline' script.hotjar.com static.hotjar.com cdn.pendo.io pendo-io-static.storage.googleapis.com; style-src 'self' 'unsafe-inline' fonts.googleapis.com; object-src 'none'; font-src fonts.gstatic.com; frame-src vars.hotjar.com;" */}
    {/*  /> */}
    {/* </Helmet> */}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
