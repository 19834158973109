import { useQuery } from '@tanstack/react-query';
import { Alert, Skeleton } from 'antd';
import { LockFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRfqProject } from 'src/Query';
import TabsWithRoutes from 'src/components/tabs/TabsWithRoutes';
import ProcureOverviewTab from './ProcureOverviewTab';
import ProcureTestingTab from './ProcureTestingTab';
import ProcureComplianceTab from './ProcureComplianceTab';
import ProcureContractTab from './ProcureContractTab';
import ProjectDetailsSidebar from './ProjectDetailsSidebar';

function ProjectProcure() {
  const { rfqId, tab } = useParams();
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);
  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  if (isLoading) return <Skeleton />;
  if (error)
    return (
      <Alert
        type="error"
        showIcon
        description={error.message || 'Not found.'}
      />
    );

  const { projects, request_for_quote: requestForQuote } = rfqProject;

  const activeProject = projects[activeProjectIndex];

  return (
    <div className="project-procure">
      <ProjectDetailsSidebar
        requestForQuote={requestForQuote}
        projects={projects}
        activeProjectIndex={activeProjectIndex}
        setActiveProjectIndex={setActiveProjectIndex}
      >
        <div className="project-procure--body">
          <TabsWithRoutes
            activeKey={tab}
            items={[
              {
                key: 'overview',
                label: <>Overview</>,
                children: (
                  <ProcureOverviewTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'testing',
                label: <>Testing</>,
                children: (
                  <ProcureTestingTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'compliance',
                label: (
                  <>
                    {requestForQuote.status !== 'demo' && <LockFilled />}
                    Compliance
                  </>
                ),
                disabled: requestForQuote.status !== 'demo',
                children: (
                  <ProcureComplianceTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'contract',
                label: (
                  <>
                    <LockFilled />
                    Contract
                  </>
                ),
                disabled: true,
                children: (
                  <ProcureContractTab
                    requestForQuote={requestForQuote}
                    projects={projects}
                  />
                )
              }
            ]}
          />
        </div>
      </ProjectDetailsSidebar>
    </div>
  );
}

export default ProjectProcure;
