import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { applyFilterToPropertyOptions } from 'src/utils/properties';

function MetaPropertyButtonGroup({
  propertyName,
  value,
  onChange,
  filter,
  multiple,
  className,
  showIcons,
  ...props
}) {
  const property = useMetaProperty(propertyName);
  const filteredOptions = useMemo(
    // we don't pass value into applyFilterToPropertyOptions because for buttons
    // if it is no longer a valid choice then it will not be selected
    () => applyFilterToPropertyOptions(property?.options, filter),
    [property, filter]
  );
  const buttonClicked = (option) => {
    if (!multiple) onChange(option.uuid);
    else {
      const values = (value && [...value]) || [];
      const filtered = values.filter((o) => o !== option.uuid); // remove item if it is already selected
      if (values.length === filtered.length) filtered.push(option.uuid); // otherwise add it
      onChange(filtered);
    }
  };
  const isSelected = (uuid) =>
    (multiple && Array.isArray(value) && value.find((o) => o === uuid)) ||
    (!multiple && uuid === value);

  return (
    <div
      className={`select-button-group ${className} ${showIcons ? 'icon' : ''}`}
      // We need to do this for the Antd form to work properly
      {...props}
    >
      {filteredOptions.map((option) => (
        <Button
          key={option.uuid}
          className={isSelected(option.uuid) && 'selected'}
          type="default"
          onClick={() => buttonClicked(option)}
          icon={showIcons && <img src={option.image} alt={option.value} />}
        >
          {option.value}
        </Button>
      ))}
    </div>
  );
}

MetaPropertyButtonGroup.propTypes = {
  propertyName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string), // multiple
    PropTypes.string // not multiple
  ]),
  className: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  filter: PropTypes.func,
  showIcons: PropTypes.bool
};

export default MetaPropertyButtonGroup;
