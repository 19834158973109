import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getKpiHeatmap } from 'src/Query/warehouse';
import Heatmap from 'src/components/viz/Heatmap';

function HeatmapPage() {
  const kpiCodeX = 'MFI';
  const kpiCodeY = 'D';
  const unitsX = 'g/10min';
  const unitsY = 'g/cm^3';
  const filters = {
    type_code__in: ['rpp'],
    properties: {
      grade: ['food_grade'],
      'processing-method': [
        'pr_blow_molding',
        'pr_injection_molding',
        'pr_extrusion'
      ]
    },
    kpis: { MFI: {}, D: { min: 0.2, max: 3 } },
    heatmap_options: {
      width: 50,
      height: 20,
      kpi_x: kpiCodeX,
      kpi_y: kpiCodeY
    }
  };
  const { data } = useQuery(['warehouse', 'heatmap', filters], () =>
    getKpiHeatmap(filters)
  );
  return (
    <div className="heatmap-page">
      <Heatmap
        width={800}
        height={400}
        kpiX={kpiCodeX}
        kpiY={kpiCodeY}
        unitsX={unitsX}
        unitsY={unitsY}
        data={data}
      />
    </div>
  );
}

export default HeatmapPage;
