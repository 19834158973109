import React from 'react';
import PropTypes from 'prop-types';

import optimalShippingRoute from 'src/utils/optimalShippingRoute';
import prettyNumber from 'src/components/utils/prettyNumber';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import { locationObjectPropTypes } from './ShippingMode';

export default function ShippingRoute({
  possibleDestinations,
  possibleOrigins
}) {
  const [origin, destination, distanceKm] = optimalShippingRoute(
    possibleOrigins,
    possibleDestinations
  );

  const distanceMi = distanceKm ? prettyNumber(0.621371 * distanceKm) : '-';

  return (
    <>
      <span>{distanceMi}</span>
      <span className="subcontent">
        {origin && destination
          ? `${locationRender(origin)} ➝ ${locationRender(destination)}`
          : '(Unknown)'}
      </span>
    </>
  );
}
ShippingRoute.propTypes = {
  possibleDestinations: PropTypes.arrayOf(locationObjectPropTypes),
  possibleOrigins: PropTypes.arrayOf(locationObjectPropTypes)
};
