import { Button, Form, message } from 'antd';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { createOpportunityLink } from 'src/Mutation';
import PropTypes from 'prop-types';
import ObjectInput from 'src/components/form/ObjectInput';

const { useForm } = Form;

export default function RfqOpportunityLink({ onChange, opportunityId }) {
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: mutateLink, isMutating: isMutatingLink } = useMutation({
    mutationFn: createOpportunityLink,
    onSuccess: (response) => onChange(),
    onError: (e) => {
      messageApi.error('Error linking salesflare');
    }
  });
  const createLink = ({ rfq }) => {
    mutateLink({ uuid: rfq.value, opportunityId });
  };
  return (
    <div className="rfq-opportunity-link">
      {contextHolder}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={createLink}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="RFQ"
          name="rfq"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <ObjectInput appName="api" modelName="request-for-quote" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isMutatingLink}>
          Link opportunity
        </Button>
      </Form>
    </div>
  );
}

RfqOpportunityLink.propTypes = {
  opportunityId: PropTypes.string,
  onChange: PropTypes.func
};
