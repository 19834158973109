import React from 'react';
import PropTypes from 'prop-types';
import * as AntdIcons from '@ant-design/icons';

/**
 * Allows antd icons to be rendered based on their string name
 * Typically used when the backend must return an icon to be displayed
 *
 * It is quite heavy, because it bundles all icons regardless of use.
 * Test showed that this adds 2Mb to the bundle
 */

export default function Icon({ name, ...rest }) {
  const AntdIcon = AntdIcons[name];
  return AntdIcon && <AntdIcon {...rest} />;
}

Icon.propTypes = {
  name: PropTypes.string
};
