import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { Input, Select, Space } from 'antd';
import { supportCurrencyCodes } from 'src/utils/currency';
import { useAvailableUnitsForType } from 'src/utils/units';

const currencyOptions = supportCurrencyCodes.map((o) => ({
  label: o,
  value: o
}));

// managed component (onChange and value must be used)
function SelectableCurrencyUnitsInput({
  value,
  onChange,
  unitType,
  per,
  ...props
}) {
  const unitList = useAvailableUnitsForType(unitType);
  // see here for why we add the <span/> https://ant.design/components/input/#FAQ
  const onChangeValue = (v) =>
    onChange({ ...(value || {}), value: v.target.value });
  const onChangeUnits = (v) => onChange({ ...(value || {}), units: v });
  const onChangeCurrency = (v) => onChange({ ...(value || {}), currency: v });
  return (
    <Space.Compact className="selectable-currency-units-input">
      <Select
        className="currency-select"
        style={{ width: 80 }}
        value={value?.currency}
        onChange={onChangeCurrency}
        defaultValue="USD"
        options={currencyOptions}
      />
      <Input
        {...props}
        value={value?.value}
        onChange={onChangeValue}
        addonAfter={
          <>
            {per ? '/' : ''}
            <Select
              onChange={onChangeUnits}
              value={value?.units}
              style={{ width: 65 }}
              options={unitList?.map((unit) => ({
                label: unit,
                value: unit
              }))}
            />
          </>
        }
      />
    </Space.Compact>
  );
}

/* eslint-disable prefer-promise-reject-errors */

export const rules = (required) => [
  { required, message: 'Please enter an amount' },
  () => ({
    validator(_, value) {
      if (value && !numeral(value).value()) {
        return Promise.reject('Please enter a valid number');
      }
      return Promise.resolve();
    }
  })
];

SelectableCurrencyUnitsInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  per: PropTypes.bool,
  unitType: PropTypes.string
};

export default SelectableCurrencyUnitsInput;

export function SelectableCurrencyWeightUnitsInput({ ...props }) {
  return <SelectableCurrencyUnitsInput unitType="weight" {...props} />;
}

export function SelectableCurrencyPerWeightUnitsInput({ ...props }) {
  return <SelectableCurrencyUnitsInput per unitType="weight" {...props} />;
}
