import React from 'react';
import { List, Row, Typography, Col, ConfigProvider } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { getTDS } from 'src/Query';
import { QueryStatus } from 'src/components/error/QueryStatus';
import Logo from 'src/components/Logo';
import SesameInvite from 'src/components/sesame/SesameInvite';
import { rfqModelName } from 'src/utils/model_names';
import TdsReadOnly from 'src/components/tds/TdsReadOnly';

export const compareProperties = (propertyValue1, propertyValue2) => {
  const property1 = propertyValue1?.property || propertyValue1?.meta_property;
  const property2 = propertyValue2?.property || propertyValue2?.meta_property;
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) return 0;
  return property1.order - property2.order;
};

export default function TechnicalDataSheetView() {
  const { id } = useParams();
  const [params] = useSearchParams();
  const updated = params.get('updated') === 'true';

  const { data, error, isLoading } = useQuery({
    queryKey: ['technicalDataSheet', id],
    queryFn: () => getTDS(id)
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: process.env.REACT_APP_BRAND_PRIMARY_COLOR,
          fontFamily: 'Poppins, sans-serif'
        }
      }}
    >
      <div id="request-for-quote-view" data-testid="request-for-quote">
        <QueryStatus
          title="Request for Quote"
          isLoading={isLoading}
          error={error}
          skeleton
        >
          {data && (
            <>
              {updated && (
                <div className="rfq__approved-banner">
                  This RFQ has been approved.
                </div>
              )}
              <div className="rfq__header">
                <Row justify="space-between" align="middle">
                  <Col className="print-content">
                    <Logo />
                  </Col>
                  <Col>
                    <Typography.Title level={1}>
                      TDS: {data.title}
                    </Typography.Title>
                  </Col>
                  <Col className="print-hide">
                    <SesameInvite
                      permissions={[
                        {
                          model: rfqModelName,
                          uuid: id
                        }
                      ]}
                    />
                  </Col>
                </Row>
              </div>
              <TdsReadOnly id={id} />
            </>
          )}
        </QueryStatus>
      </div>
    </ConfigProvider>
  );
}

function RfqDetailRow({ label, value, isOpenText }) {
  return (
    <List.Item>
      {isOpenText ? (
        <Row>
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col span={24}>{value}</Col>
        </Row>
      ) : (
        <Row wrap={false} justify="space-between">
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{value}</Typography.Text>
          </Col>
        </Row>
      )}
    </List.Item>
  );
}

RfqDetailRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpenText: PropTypes.bool
};
