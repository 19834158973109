import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import Stars from 'src/components/utils/Stars';

function DealParty({ companyName, dealContact, companyLogo, rating }) {
  return (
    <div className="deal-party">
      {(companyLogo && (
        <div className="company-avatar">
          <img src={companyLogo} alt="Company Logo" />
        </div>
      )) || <Skeleton avatar={{ size: 26 }} paragraph={false} title={false} />}
      <div className="deal-party-text">
        <h5>{companyName}</h5>
        <p>{dealContact}</p>
      </div>
      <div className="deal-party-rating">
        {rating === undefined || <Stars percent={Number(rating) / 5} />}
      </div>
    </div>
  );
}

DealParty.propTypes = {
  companyName: PropTypes.string.isRequired,
  dealContact: PropTypes.string,
  companyLogo: PropTypes.string,
  rating: PropTypes.number
};

export default DealParty;
