import { useQuery } from '@tanstack/react-query';
import { getProjectWithTdss } from 'src/Query';

export default function useGetPopulatedProject(projectUuid, filters = {}) {
  return useQuery(
    ['project', projectUuid, 'populated', filters],
    () => getProjectWithTdss(projectUuid, filters),
    { enabled: !!projectUuid }
  );
}
