import React from 'react';
import dayjs from 'dayjs';
import { List, Row, Typography, Col, Skeleton } from 'antd';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { getTDS } from 'src/Query';
import prettyNumber from 'src/components/utils/prettyNumber';
import numericRange from 'src/components/utils/numericRange';
import { formatAddress } from 'src/components/form/LocationInput';
import { findPropertiesValues } from 'src/utils/properties';
import { mapMerge } from 'src/utils/mapMerge';

export const compareProperties = (propertyValue1, propertyValue2) => {
  const property1 = propertyValue1?.property || propertyValue1?.meta_property;
  const property2 = propertyValue2?.property || propertyValue2?.meta_property;
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) return 0;
  return property1.order - property2.order;
};

function formatQuantity(quantity, quantity_units, duration_months) {
  const quantityString = `${prettyNumber(quantity)} ${quantity_units}`;
  if (!duration_months) return quantityString;
  if (duration_months < 12) {
    const today = dayjs();
    const future = today.add(duration_months || 0, 'month');
    return `${quantityString} over ${today.to(future, true)}`;
  }
  const annualizedQuantity = (quantity / duration_months) * 12;
  return `${prettyNumber(annualizedQuantity)} ${quantity_units} / year`;
}

export default function TdsReadOnly({ id }) {
  const { data, isLoading } = useQuery({
    queryKey: ['technicalDataSheet', id],
    queryFn: () => getTDS(id),
    enabled: !!id
  });

  if (id && isLoading) return <Skeleton />;

  return (
    <div className="rfq__content">
      <Typography.Title level={3}>{data.title}</Typography.Title>
      <List
        header={
          <Typography.Title level={3}>Material & Quantity</Typography.Title>
        }
      >
        <RfqDetailRow
          label="Material"
          value={findPropertiesValues(data, 'type')}
        />
        <RfqDetailRow label="Form" value={findPropertiesValues(data, 'form')} />
        <RfqDetailRow
          label="Source"
          value={findPropertiesValues(data, 'source-type')}
        />
        <RfqDetailRow
          label="Grade"
          value={findPropertiesValues(data, 'grade')}
        />
        <RfqDetailRow
          label="Application"
          value={findPropertiesValues(data, 'application')}
        />
        <RfqDetailRow
          label="Total Quantity"
          value={formatQuantity(
            data.quantity,
            data.quantity_units,
            data.duration_months
          )}
        />
        <RfqDetailRow
          label="Color"
          value={findPropertiesValues(data, 'color')}
        />
        <RfqDetailRow
          label="Polymer type"
          value={findPropertiesValues(data, 'polymer-type')}
        />
        <RfqDetailRow
          label="Location(s)"
          value={
            <>
              {data.locations
                .map((address) => formatAddress(address))
                .join('\n')}
            </>
          }
        />
      </List>

      <List
        header={<Typography.Title level={3}>Specs & Testing</Typography.Title>}
      >
        {mapMerge(
          data.material_properties?.filter(
            (mp) =>
              ![
                'type',
                'form',
                'source-type',
                'color',
                'grade',
                'application'
              ].includes(mp.meta_property.code)
          ) || [],
          (mp) => (
            <RfqDetailRow
              key={mp.uuid}
              label={mp.meta_property.name}
              value={mp.value}
            />
          ),
          data.material_numerical_properties || [],
          (mnp) => (
            <RfqDetailRow
              key={mnp.uuid}
              label={mnp.property.name}
              value={
                <>
                  {numericRange(mnp)} {mnp.units}
                </>
              }
            />
          ),
          compareProperties
        )}
      </List>

      {/* <Button
        htmlType="button"
        type="primary"
        onClick={() => window.print()}
        icon={<DownloadOutlined />}
      >
        Download PDF
      </Button> */}
    </div>
  );
}
TdsReadOnly.propTypes = {
  id: PropTypes.string
};

function RfqDetailRow({ label, value, isOpenText }) {
  return (
    <List.Item>
      {isOpenText ? (
        <Row>
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col span={24}>{value}</Col>
        </Row>
      ) : (
        <Row wrap={false} justify="space-between">
          <Col>
            <Typography.Text strong>{label}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{value}</Typography.Text>
          </Col>
        </Row>
      )}
    </List.Item>
  );
}
RfqDetailRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpenText: PropTypes.bool
};
