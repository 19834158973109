import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import useMetaProperty from 'src/hooks/useMetaProperty';

// managed component (onChange and value must be used)
export default function PropertyMultiInput({ value, onChange }) {
  // see here for why we add the <span/> https://ant.design/components/input/#FAQ
  const property = useMetaProperty(value.propertyCode);
  const options = useMemo(() =>
    (property?.options || []).map(
      (o) => ({
        label: o.value,
        value: o.code
      }),
      [property]
    )
  );
  const optionMap = useMemo(
    () =>
      options.reduce((acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }),
    [options]
  );
  const onChangeSelect = (v) =>
    onChange({
      ...(value || {}),
      codes: v,
      names: v.map((o) => optionMap[o])
    });
  return (
    <Select
      onChange={onChangeSelect}
      mode="multiple"
      value={value?.codes}
      options={options}
      style={{ minWidth: 150 }}
    />
  );
}

PropertyMultiInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};
