import { Form, Select, InputNumber } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { useAvailableUnitsForType, usePreferredUnits } from 'src/utils/units';

export default function QuantityWithUnitsInput({ unitsFieldName, ...props }) {
  const weightUnits = useAvailableUnitsForType('weight');
  const defaultUnits = usePreferredUnits('weight');

  return (
    <InputNumber
      min={0}
      formatter={(value) => {
        // Add commas every 3 nums but only before decimal point
        const [wholeNumbers] = value.split('.');
        const wholeNumbersWithCommas = wholeNumbers.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );

        return value.replace(wholeNumbers, wholeNumbersWithCommas);
      }}
      parser={(value) => value?.replace(/(,*)/g, '')}
      addonAfter={
        weightUnits && defaultUnits ? (
          <Form.Item
            initialValue={defaultUnits}
            name={unitsFieldName}
            rules={[{ required: true }]}
            noStyle
          >
            <Select
              style={{ width: 65 }}
              options={weightUnits?.map((unit) => ({
                label: `${unit}`,
                value: unit
              }))}
            />
          </Form.Item>
        ) : (
          ''
        )
      }
      {...props}
    />
  );
}
QuantityWithUnitsInput.propTypes = {
  unitsFieldName: PropTypes.string
};
