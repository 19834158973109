import { fetch } from 'src/utils/client';

export const getSupplierAnalytics = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/supplier-analytics/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getRegionalAnalytics = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/regional-analytics/?${new URLSearchParams(
      params
    ).toString()}`
  );
