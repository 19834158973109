import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from 'src/Query';

export const SidebarContext = createContext();

const DEFAULT_PROJECTS = [];

export default function SidebarContextProvider({ children }) {
  // each cascade is { key, component, visible }
  const [cascades, setCascades] = useState([]);
  const [wide, setWide] = useState(false);

  const keyList = useMemo(() => cascades.map((c) => c.key), [cascades]);

  const {
    data: projects = DEFAULT_PROJECTS,
    isLoading: isLoadingProjects,
    refetch
  } = useQuery({
    queryKey: ['projects', { all: true }],
    queryFn: () => getProjects({ all: true }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 10 * 60,
    cacheTime: 1000 * 15 * 60
  });

  const getProjectbyId = useCallback(
    (id) => projects.find((pjct) => pjct.uuid === id),
    [projects]
  );

  // currently active project
  const [activeProjectId, setActiveProjectId] = useState(undefined);
  const activeProject = useMemo(
    () => getProjectbyId(activeProjectId),
    [projects, activeProjectId]
  );

  const pushCascade = useCallback(
    (component, key, level) => {
      const visible = true;
      const item = { component, key, visible };
      const keyItemIndex = cascades.findIndex((c) => c.key === key);
      if (keyItemIndex >= 0) {
        // if (cascades[keyItemIndex]) {
        //   // toggle off
        //   return setCascades(cascades.slice(0, keyItemIndex));
        // }
        return setCascades([...cascades.slice(0, keyItemIndex), item]);
      }
      if (level === undefined) {
        return setCascades([...cascades, item]);
      }
      return setCascades([...cascades.slice(0, level), item]);
    },
    [cascades]
  );
  const popCascade = useCallback(
    (key) => {
      const keyItemIndex = cascades.findIndex((c) => c.key === key);
      if (keyItemIndex >= 0) {
        setCascades(cascades.slice(0, keyItemIndex));
      } else setCascades((c) => c.slice(0, -1));
    },
    [cascades, setCascades]
  );
  const clearCascades = useCallback(
    () => setCascades([]),
    [cascades, setCascades]
  );

  const hideCascades = useCallback(
    () =>
      setCascades(
        [...cascades].forEach((c) => {
          c.visible = False; // eslint-disable-line
        })
      ),
    [cascades, setCascades]
  );

  const cascadesToDisplay = useMemo(
    () =>
      wide
        ? cascades.filter((c) => c?.visible).map((c) => c.component)
        : [
            cascades
              .slice()
              .reverse()
              .find((c) => c?.visible && c?.component)
          ].filter((c) => c),
    [cascades, wide]
  );

  const value = useMemo(
    () => ({
      cascades,
      cascadesToDisplay,
      pushCascade,
      popCascade,
      clearCascades,
      hideCascades,
      activeProject,
      isLoadingProjects,
      projects,
      activeProjectId,
      setActiveProjectId,
      getProjectbyId,
      refetchProjects: refetch,
      wide,
      setWide,
      keyList
    }),
    [
      cascades,
      cascadesToDisplay,
      popCascade,
      pushCascade,
      clearCascades,
      hideCascades,
      activeProject,
      isLoadingProjects,
      projects,
      activeProjectId,
      setActiveProjectId,
      getProjectbyId,
      wide,
      setWide,
      keyList
    ]
  );
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}
SidebarContextProvider.propTypes = {
  children: PropTypes.node
};

export function useSidebarContext() {
  const value = useContext(SidebarContext);

  return value;
}
