import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
/* eslint-enable import/no-unresolved */

import FiltersSidebar from 'src/components/project/FiltersSidebar';
import { ConditionalContent as ExploreContent } from 'src/components/project/explore/Content';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { createProject, updateProject } from 'src/Mutation';

import 'src/pages/project/styles/Explore.less';
import ProjectHeader from 'src/components/project/ProjectHeader';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = 'Roboto';
ChartJS.defaults.font.size = 12;
ChartJS.defaults.color = '#63748D';
ChartJS.defaults.plugins.title.color = '#323640';
ChartJS.defaults.plugins.title.font.size = 18;
ChartJS.defaults.plugins.title.font.weight = '500';
ChartJS.defaults.plugins.title.align = 'start';
ChartJS.defaults.plugins.title.padding = 0;

const demoSteps = [
  { label: 'Prioritize Supplier List', key: '1', done: true },
  { label: 'Edit & Send Digital RFQ', key: '2', done: true },
  { label: 'Manage Quotes', key: '3', done: false }
];

export default function ProjectExplore() {
  const navigate = useNavigate();
  const isConcierge = useIsConcierge();

  const [searchParams] = useSearchParams();
  const { activeProjectId: projectId, activeProject } = useSidebarContext();
  const newProject = searchParams.get('new');

  const [newProjectModalIsOpen, setNewProjectModalIsOpen] =
    useState(newProject);

  const [form] = Form.useForm();
  const typeProperty = useMetaProperty('type');
  const formProperty = useMetaProperty('form');

  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      setNewProjectModalIsOpen(false);
      navigate(`/project/explore/${response.uuid}/`);
    },
    onError: (e) => {
      setNewProjectModalIsOpen(false);
      // Send error to Sentry
      captureException(e);
    }
  });

  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      navigate(`/project/source/${response.uuid}/quotes`);
    },
    onError: (e) => {
      messageApi.error('Error updating project. Please try again.', 8);
      // Send error to Sentry
      captureException(e);
    }
  });

  const hideExplore = !isConcierge;

  if (hideExplore) return undefined;

  return (
    <div className="explore-page">
      {contextHolder}
      <ProjectHeader>
        {activeProject?.stage && (
          <>
            <Typography.Text strong>
              {activeProject?.tdss?.length} suppliers selected
            </Typography.Text>
            {['search', 'procure', 'manage'].includes(activeProject.stage) ? (
              <Link to={`/project/source/${projectId}/quotes`}>
                <Button>
                  <SendOutlined rotate={-45} />
                  View in Source
                </Button>
              </Link>
            ) : (
              <Button
                disabled={!(activeProject?.tdss?.length > 1)}
                loading={updating}
                type="primary"
                htmlType="button"
                onClick={() => {
                  updateProjectMutation({
                    uuid: projectId,
                    stage: 'search'
                  });
                }}
              >
                {updating ? 'Loading....' : 'Proceed to Source'}
              </Button>
            )}
          </>
        )}
      </ProjectHeader>
      <Row wrap={false}>
        <Col flex="288px">
          <FiltersSidebar />
        </Col>
        <Col flex="1" style={{ maxWidth: 'calc(100% - 239px)' }}>
          <div className="content explore-content">
            <ExploreContent />
          </div>
        </Col>
      </Row>
      <Modal
        width={500}
        title="Create Project"
        open={newProjectModalIsOpen}
        onCancel={() => setNewProjectModalIsOpen(false)}
        okText={isLoading ? 'Creating project...' : 'Create Project'}
        okButtonProps={{ loading: isLoading }}
        onOk={form.submit}
        // eslint-disable-next-line react/no-unstable-nested-components
        footer={(_, { OkBtn }) => (
          <Space direction="vertical" size={12}>
            <OkBtn />
            <span>
              Or{' '}
              <Button
                style={{ padding: 0 }}
                htmlType="button"
                type="link"
                size="small"
                onClick={() => setNewProjectModalIsOpen(false)}
              >
                continue without saving
              </Button>
            </span>
          </Space>
        )}
      >
        <Form
          validateMessages={{
            required: 'This field is required.'
          }}
          layout="vertical"
          form={form}
          disabled={isLoading}
          onFinish={async (values) => {
            await form.validateFields();

            mutateAsync(values);
          }}
        >
          <p>Name your project to save your exploration.</p>
          <Form.Item label="Name this project" name="name">
            <Input />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 40 }}
            label="Pick a material"
            name={['filters', 'type_code__in']}
            rules={[{ required: true }]}
            disabled={!typeProperty}
          >
            <Select
              options={typeProperty?.options?.map((o) => ({
                label: o.value,
                value: o.code
              }))}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 40 }}
            label="Pick a material form"
            name={['filters', 'form_code__in']}
            rules={[{ required: true }]}
            disabled={!formProperty}
          >
            <Select
              options={formProperty?.options?.map((o) => ({
                label: o.value,
                value: o.code
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
