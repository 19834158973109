import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function SplashPage({ children, className }) {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, []);

  return <div className={`splash-page ${className || ''}`}>{children}</div>;
}

export default SplashPage;

SplashPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};
