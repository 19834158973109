import qs from 'qs';
import { useContext, useEffect, useState } from 'react';
import { useUser } from 'src/utils/authentication';
import {
  CookiePolicyContext,
  PolicyOptions
} from 'src/components/cookiePolicy';

/**
 * Hook that encapsulates the logic of whether to enable performance tracking
 * based on the ?ax=off query param and on the user-selected cookie preferences
 */

export const usePerformance = () => {
  const [disable, setDisable] = useState(false);
  const { data: user } = useUser();
  const { allowCookie } = useContext(CookiePolicyContext);
  const { search } = window.location;
  const p = qs.parse(search, { ignoreQueryPrefix: true });
  const disabledOnPath = p.ax === 'off';
  useEffect(() => {
    if (disabledOnPath) setDisable(disabledOnPath);
  }, []);
  // users have accepted our T&Cs
  const optOut = !user && !allowCookie(PolicyOptions.PERFORMANCE);
  // return true if we do not want to track
  return optOut || disabledOnPath || disable;
};
