import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Space, Table } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  EyeInvisibleTwoTone,
  EyeTwoTone
} from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { currencyValuePrice } from 'src/utils/currency';
import { findProperties } from 'src/utils/properties';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useIsDealPitch from 'src/hooks/useIsDealPitch';
import { patchRFQ } from 'src/Mutation';
import useDealData from 'src/pages/rfq/proposal/useDealData';
import useProposalSupplyData from 'src/pages/rfq/proposal/supply/useProposalSupplyData';
import SupplyRoute, {
  locationRender
} from 'src/pages/rfq/proposal/supply/SupplyRoute';
import BuyerAvatar from 'src/pages/rfq/proposal/buyer/BuyerAvatar';
import BuyerName from 'src/pages/rfq/proposal/buyer/BuyerName';
import EditTdsModal from 'src/pages/supplier/TechnicalDataSheet';
import {
  className,
  colSpanDeal,
  colSpanLabel,
  colSpanRfq,
  dealColumnSpans,
  dealDataRow,
  getRowClassname,
  supplyAvatarRow,
  supplyColumnKeys,
  supplyDataKpiRow,
  supplyDataRow
} from './util';
import Summary from './Summary';
import StarsCell from './StarsCell';

const dealPriceFormatted = (rfq, deal) => {
  const { useIndexPrice, currency } = deal;
  if (useIndexPrice) {
    return deal.priceAboveIndex === 1 ? (
      <span className="price-direction-up">Above</span>
    ) : deal.priceAboveIndex === -1 ? (
      <span className="price-direction-down">Below</span>
    ) : (
      <span className="price-direction-down">Equal</span>
    );
  }

  const useLb = false; // rfq.units_price_lbs;
  const price = useLb ? deal.pricePerLb : deal.price;
  if (!price) return '';
  const weightUnits = useLb ? 'lb' : rfq.price_weight_units;
  return `${currencyValuePrice(price, currency)} /${weightUnits}`;
};

/* eslint-disable react/no-unstable-nested-components */
export default function ProposalComparison({ rfq, deals }) {
  const isConcierge = useIsConcierge();
  const isDealPitch = useIsDealPitch();
  const viewAsGuest = useConciergeContext('viewAsGuest');

  const adminMode = isConcierge && !isDealPitch && !viewAsGuest;

  const [tdsBeingEdited, setTdsBeingEdited] = useState();

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: patchRFQ,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['requestForQuote', rfq.uuid]
      });
    }
  });

  const dealsData = useDealData(deals);
  const suppliesPerDeal = deals.map((deal) => deal.supplies.length);
  const showAllProperties = true;
  const useLbForPrice = true; // FIXME
  const supplyData = useProposalSupplyData(
    rfq,
    showAllProperties,
    useLbForPrice,
    undefined,
    deals
  );

  const [sections, setSections] = useState([]);

  const columns = [
    {
      dataIndex: 'label',
      onCell: (record) => ({
        colSpan: colSpanLabel(record, columns.length)
      }),
      render: (label, record) => {
        if (!record.section) return label;

        const index = sections.findIndex(
          (section) => record.label === section.label
        );
        const expanded = index >= 0 ? sections?.[index]?.expanded : false;

        return (
          <>
            {expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}&nbsp;
            {label}
          </>
        );
      }
    },
    {
      dataIndex: 'rfq',
      className: 'rfq first last',
      align: 'center',
      onCell: (record) => ({
        colSpan: colSpanRfq(record, columns.length)
      })
    },
    ...deals
      .map((deal, dealIndex) =>
        deal.supplies.map((supply, supplyIndex) => ({
          dataIndex: [deal.uuid, supplyIndex.toString()],

          align: 'center',
          onCell: (record) => ({
            colSpan: colSpanDeal(
              record,
              supplyIndex,
              suppliesPerDeal[dealIndex]
            ),
            className: className(
              record,
              supplyIndex,
              suppliesPerDeal[dealIndex]
            )
          })
        }))
      )
      .flat()
  ];
  const supplyColumnKeysArray = supplyColumnKeys(deals);
  const dealColumnSpansArray = dealColumnSpans(deals);
  const summaryData = useMemo(
    () => [
      dealDataRow({
        dealsData: deals,
        label: undefined,
        format: (deal) =>
          deal.tag && (
            <div className="badge-container">
              <div className={`column-badge ${deal.category}`}>{deal.tag}</div>
            </div>
          ),
        rfqValue: (
          <div className="badge-container">
            <div className="column-badge rfq">RFQ</div>
          </div>
        ),
        className: 'tag'
      }),
      supplyAvatarRow({
        deals,
        label: undefined,
        rfqValue: rfq.company.avatar && (
          <Space direction="vertical">
            <AdminConsoleLink app="api" type="requestforquote" uuid={rfq.uuid}>
              <BuyerAvatar buyer={rfq.company} />
            </AdminConsoleLink>
            <BuyerName buyer={rfq.company} />
          </Space>
        ),
        editTdsCallback: !viewAsGuest ? setTdsBeingEdited : null
      }),
      dealDataRow({
        dealsData: deals,
        label: undefined,
        format: (deal) => deal.pitch_content,
        rfqValue: null,
        className: 'pitch-content'
      })
    ],
    [deals]
  );

  const showHideToggle = (label, keyOverride) => {
    const key =
      keyOverride ||
      label
        .toLowerCase()
        .replace(/[^0-9a-zA-Z_\s]/g, '')
        .replace(/\s+/g, '-');
    const shKey = `showhide-${key}`;
    const currentShowHide = rfq.extra?.[shKey];
    const iconsMap = {
      show: <EyeTwoTone />,
      hide: <EyeInvisibleTwoTone />
    };

    return {
      label: adminMode ? (
        <>
          {label}&nbsp;{iconsMap[currentShowHide] || null}
        </>
      ) : (
        label
      ),
      key,
      showHide: currentShowHide,
      showHideUpdateFunc: adminMode
        ? (showHide) =>
            mutateAsync({
              uuid: rfq.uuid,
              extra: {
                ...(rfq.extra || {}),
                [shKey]: showHide
              }
            })
        : null
    };
  };

  const useLb = false; // rfq.units_price_lbs
  const rfqPrice = useLb ? rfq.price_per_lb : rfq.price;
  const rfqPriceWeightUnits = useLb ? 'lb' : rfq.price_weight_units;
  const useIndexPrice = rfq.price_is_index;
  const indexPriceText = useIndexPrice
    ? `${currencyValuePrice(rfqPrice, rfq.currency)} /${rfqPriceWeightUnits}`
    : null;
  const dataSourceAll = useMemo(
    () => [
      dealDataRow({
        key: 'dealSummary',
        dealsData: deals,
        format: (deal) => (
          <div className="deal-score">{deal.overall_score}</div>
        ),
        label: 'Deal Summary',
        section: true
      }),
      supplyDataRow({
        supplyData,
        ...showHideToggle('Annual Capacity', 'summary-annual-capacity'),
        key: 'annual-capacity'
      }),
      supplyDataRow({
        supplyData,
        format: (item) => (
          <SupplyRoute origin={item.value} destination={item.referenceValue} />
        ),
        rfqValue: rfq.shipping_addresses?.map((address, i) =>
          i ? (
            <span key={`supply-location-${i}`}>
              <br />
              {locationRender(address)}
            </span>
          ) : (
            <span key={`supply-location-${i}`}>{locationRender(address)}</span>
          )
        ),
        ...showHideToggle('Location', 'summary-supply-location'),
        key: 'location'
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Years In Business', 'summary-years-in-business'),
        key: 'years-in-business'
      }),
      dealDataRow({
        dealsData,
        format: (deal) =>
          `${prettyNumberRound(deal.quantity * (rfq.duration_months || 1))} ${
            deal.quantityUnits
          }`,
        rfqValue: `${prettyNumberRound(rfq.quantity)} ${rfq.quantity_units}`,
        ...showHideToggle('Total Quantity')
      }),
      dealDataRow({
        dealsData,
        format: (deal) => dealPriceFormatted(rfq, deal),
        rfqValue:
          rfqPrice && !indexPriceText
            ? `${currencyValuePrice(
                rfqPrice,
                rfq.currency
              )} /${rfqPriceWeightUnits}`
            : '',
        ...showHideToggle(
          <>
            Material Price
            {indexPriceText && (
              <div className="sub-text">vs. index {indexPriceText}</div>
            )}
          </>,
          'deal-level-price'
        )
      }),
      dealDataRow({
        key: 'dealFee',
        dealsData,
        format: (deal) => currencyValuePrice(deal.fee, deal.currency),
        ...showHideToggle('Deal Fee')
      }),
      // dealDataRow(dealsData, 'Shipping cost', (deal) =>
      //   currencyValuePrice(deal.shippingCost, deal.currency)
      // ),
      dealDataRow({
        key: 'shippingPrice',
        dealsData,
        format: (deal) =>
          `${currencyValuePrice(deal.shippingUnitCost, deal.currency)} /${
            deal.priceWeightUnits
          }`,
        ...showHideToggle('Shipping Price')
      }),
      dealDataRow({
        key: 'deliveredPrice',
        dealsData,
        format: (deal) =>
          `${currencyValuePrice(deal.deliveredCost, deal.currency)} /${
            deal.priceWeightUnits
          }`,
        ...showHideToggle('Delivered Price')
      }),
      dealDataRow({
        key: 'durationMonths',
        dealsData,
        format: (deal) => deal.durationMonths,
        ...showHideToggle('Duration (months)')
      }),
      dealDataRow({
        dealsData,
        format: (deal) => currencyValuePrice(deal.monthlyCost, deal.currency),
        ...showHideToggle('Monthly cost')
      }),
      dealDataRow({
        // key: 'deposit',
        dealsData,
        format: (deal) => currencyValuePrice(deal.deposit, deal.currency),
        ...showHideToggle('Deposit')
      }),
      dealDataRow({
        // key: 'totalDeliveredCost',
        dealsData,
        format: (deal) => currencyValuePrice(deal.totalCost, deal.currency),
        rowClassName: 'total-cost',
        ...showHideToggle('Total Delivered Cost')
      }),
      dealDataRow({
        key: 'performance',
        dealsData,
        format: (deal) => <StarsCell deal={deal} title="Performance" />,
        label: 'Performance',
        section: true
      }),
      supplyDataRow({
        key: 'pcrPercent',
        supplyData,
        rfqValue: `${
          rfq.percent_pcr_by_volume
            ? `${prettyNumberRound(rfq.percent_pcr_by_volume)}%`
            : ''
        }`,
        ...showHideToggle('% Recycled By Volume', 'pcr-percent-by-volume')
      }),
      ...(supplyData && supplyData[0]
        ? supplyData[0].kpis.map((kpi, index) =>
            supplyDataKpiRow({
              key: `kpi-${kpi.uuid}`,
              supplyData,
              index,
              showHideToggle
            })
          )
        : []),
      dealDataRow({
        key: 'supplierPrice',
        dealsData,
        format: (deal) => <StarsCell deal={deal} title="Price" />,
        label: 'Supplier Price',
        section: true
      }),
      supplyDataRow({
        // key: 'materialPrice',
        supplyData,
        rfqValue: `${currencyValuePrice(rfq.price, rfq.currency)} /${
          rfq.price_weight_units
        }`,
        ...showHideToggle('Material Price', 'price')
      }),
      dealDataRow({
        key: 'capacityAvailable',
        dealsData,
        format: (deal) => <StarsCell deal={deal} title="Capacity Available" />,
        label: 'Capacity Available',
        section: true
      }),
      supplyDataRow({
        key: 'capacityAnnual',
        supplyData,
        ...showHideToggle('Annual Capacity')
      }),
      supplyDataRow({
        // key: 'spotCapacity',
        supplyData,
        ...showHideToggle('Spot capacity')
      }),
      supplyDataRow({
        // key: 'materials',
        supplyData,
        rfqValue: findProperties(rfq, 'type')
          .map(({ value }) => value)
          .join(', '),
        ...showHideToggle('Materials')
      }),
      supplyDataRow({
        // key: 'forms',
        supplyData,
        rfqValue: findProperties(rfq, 'form')
          .map(({ value }) => value)
          .join(', '),
        ...showHideToggle('Forms')
      }),
      dealDataRow({
        key: 'brandFit',
        dealsData,
        format: (deal) => <StarsCell deal={deal} title="Brand Fit" />,
        label: 'Brand Fit',
        section: true
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Annual Capacity', 'brand-fit-capacity'),
        key: 'annual-capacity'
      }),
      dealDataRow({
        dealsData,
        format: (deal) =>
          `${currencyValuePrice(deal.deliveredCost, deal.currency)} /${
            deal.priceWeightUnits
          }`,
        ...showHideToggle('Delivered Price', 'brand-fit-delivered-price')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Years In Business', 'years-in-business')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle(
          '% Recycled By Volume',
          'brand-fit-pcr-percent-by-volume'
        )
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Certifications', 'certifications')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Strategic Services', 'strategic-vision')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Business risk')
      }),
      supplyDataRow({
        key: 'supply-location-brand-fit',
        supplyData,
        format: (item) => (
          <SupplyRoute origin={item.value} destination={item.referenceValue} />
        ),
        rfqValue: rfq.shipping_addresses?.map((address, i) =>
          i ? (
            <span key={`supply-location-${i}`}>
              <br />
              {locationRender(address)}
            </span>
          ) : (
            <span key={`supply-location-${i}`}>{locationRender(address)}</span>
          )
        ),
        ...showHideToggle('Location')
      }),
      dealDataRow({
        key: 'emissions',
        dealsData,
        format: (deal) => <StarsCell deal={deal} title="Emissions" />,
        label: 'Emissions',
        section: true
      }),
      supplyDataRow({
        supplyData,
        ...showHideToggle('Material Emissions (mt)', 'material-emissions-mt'),
        hideMatch: true
      }),
      supplyDataRow({
        supplyData,
        ...showHideToggle('Logistics Emissions (mt)', 'logistics-emissions-mt'),
        hideMatch: true
      }),
      supplyDataRow({
        supplyData,
        ...showHideToggle('Total Emissions (mt)', 'total-emissions-mt'),
        hideMatch: true
      }),
      supplyDataRow({
        supplyData,
        ...showHideToggle('Avoided Emissions (%)', 'avoided-emissions-percent'),
        hideMatch: true
      }),
      dealDataRow({
        key: 'logistics-complexity',
        dealsData,
        format: (deal) => (
          <StarsCell deal={deal} title="Logistical Complexity" />
        ),
        label: 'Logistical Complexity',
        section: true
      }),
      supplyDataRow({
        supplyData,
        format: (item) => (
          <SupplyRoute origin={item.value} destination={item.referenceValue} />
        ),
        rfqValue: rfq.shipping_addresses?.map((address, i) =>
          i ? (
            <span key={`supply-location-${i}`}>
              <br />
              {locationRender(address)}
            </span>
          ) : (
            <span key={`supply-location-${i}`}>{locationRender(address)}</span>
          )
        ),
        ...showHideToggle('Location')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Distance')
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Shipping Price'),
        key: 'shipping-cost'
      }),
      supplyDataRow({
        supplyData,
        hideMatch: true,
        ...showHideToggle('Annual Capacity', 'logistical-complexity-capacity'),
        key: 'annual-capacity'
      })
    ],
    [dealsData, supplyData]
  );

  useEffect(() => {
    const sectionArray = dataSourceAll.reduce((acc, row, index) => {
      if (row.section) {
        acc.push({
          label: row.label,
          labelText: row.labelText,
          key: row.key,
          index,
          // Prevent sections collapsing on every refetch
          expanded:
            row.label === sections?.[index]?.label
              ? sections[index].expanded
              : index === 0, // Expand summary section by default
          next: undefined
        });
      }
      return acc;
    }, []);
    sectionArray.forEach((element, index) => {
      if (index < sectionArray.length - 1) {
        // eslint-disable-next-line no-param-reassign
        element.next = sectionArray[index + 1].index;
      } else {
        // eslint-disable-next-line no-param-reassign
        element.next = dataSourceAll.length;
      }
    });
    setSections(sectionArray);
  }, []);

  const sectionClick = (record, clickedIndex) => {
    const index = sections.findIndex(
      (element) => record.labelText === element.labelText
    );
    if (index >= 0) {
      const newSections = [...sections];
      newSections[index].expanded = !newSections[index].expanded;
      setSections(newSections);
    }
  };

  const dataSource = useMemo(() => {
    // 0 means visible
    // 1 means collapsed
    // 2 means (always) hidden
    const active = dataSourceAll.map((row) =>
      row.showHide === 'hide' ? 2 : 0
    );

    sections.forEach((section) => {
      let allFieldsHidden = true;

      for (let index = section.index + 1; index < section.next; index += 1) {
        if (dataSourceAll[index]?.showHide !== 'show' && !section.expanded)
          active[index] = 1;

        if (dataSourceAll[index]?.showHide !== 'hide') allFieldsHidden = false;
      }

      active[section.index] = allFieldsHidden ? 2 : 0;
    });

    return dataSourceAll.map((row, index) => ({
      ...row,
      active: active[index]
    }));
  }, [dataSourceAll, sections]);

  const summary = useMemo(
    () => Summary(summaryData, supplyColumnKeysArray, dealColumnSpansArray),
    [summaryData, supplyColumnKeysArray, dealColumnSpansArray]
  );

  return (
    <>
      <div
        className="deal-comparison-container"
        data-testid="deal-comparison-container"
      >
        <Table
          className="deal-comparison-table"
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          onRow={(record, index) => ({
            className: getRowClassname(record, adminMode),
            onClick: record.section
              ? () => sectionClick(record, index)
              : undefined
          })}
          scroll={{
            y: 1000
          }}
          summary={() => summary}
          sticky
        />
      </div>
      {tdsBeingEdited && (
        <EditTdsModal
          open={!!tdsBeingEdited}
          tdsId={tdsBeingEdited}
          hideModal={() => setTdsBeingEdited(null)}
        />
      )}
    </>
  );
}
ProposalComparison.propTypes = {
  deals: PropTypes.array,
  rfq: PropTypes.object
};
/* eslint-enable react/no-unstable-nested-components */
