import React, { useState, useMemo } from 'react';
import { Row, Col, Typography, Table, Spin } from 'antd';

import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { getSuppliers } from 'src/Query';

import useGetRfq from 'src/Query/useGetRfq';
import MapPage, { SupplyMarker } from 'src/pages/maps/MapPage';
import SearchPage from 'src/pages/search/SearchFilteredPage';
import ProposalBuilderHeader from './Header';
import useProposalBuilderFilters from './useProposalBuilderFilters';
import usePbColumns from './columns/usePbColumns';
import TableActions from './TableActions';

import 'src/pages/concierge/Concierge.less';

/* eslint-disable react/no-unstable-nested-components */
export default function ProposalBuilder() {
  // Fetch Meta properties to use in table columns

  // Table controls
  const [selectedRows, setSelectedRows] = useState([]);

  const [params] = useSearchParams();

  // Get RFQ to filter by from URL
  const rfqId = params.get('rfqId');
  const { data: rfqFilter, isFetched: rfqFetched } = useGetRfq();

  // TDS filtering
  const {
    FiltersRow,
    filters,
    setFilters,
    display,
    pagination,
    setPagination
  } = useProposalBuilderFilters();

  const allFilters = {
    ...pagination,
    ...filters
  };

  // Actual API call to fetch filtered TDSs which will be displayed
  const { data: suppliers = {}, isLoading: suppliersLoading } = useQuery({
    queryKey: ['suppliers', allFilters],
    queryFn: () => getSuppliers(allFilters)
  });

  const columns = usePbColumns();

  // Map markers to display RFQ location
  const rfqMarkers = useMemo(
    () =>
      rfqFilter?.shipping_addresses
        ?.map((address) => {
          if (!address.latitude || !address.longitude) return null;

          return (
            <SupplyMarker
              supply={{
                ...address
              }}
              id={rfqFilter.uuid}
              key={rfqFilter.uuid}
              size={30}
              style={{ background: 'red' }}
              title={<strong>RFQ: {rfqFilter.title}</strong>}
            />
          );
        })
        .filter((marker) => !!marker),
    [rfqFilter]
  );

  if (rfqId && !rfqFetched)
    return (
      <div className="proposal-builder">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="proposal-builder">
      <ProposalBuilderHeader selectedRows={selectedRows} />
      <div className="proposal-builder--inner">
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>
              {suppliers.count} Suppliers found
            </Typography.Title>
          </Col>
        </Row>
        {FiltersRow}
        <TableActions
          suppliersCount={suppliers.count}
          selectedRows={selectedRows}
        />
        <Row>
          <Col span={24}>
            {(display === 'table' && (
              <Table
                className="proposal-builder--table"
                loading={suppliersLoading}
                columns={columns}
                dataSource={suppliers.results}
                expandable={{
                  childrenColumnName: 'tdss'
                }}
                pagination={{
                  current: pagination.page,
                  pageSize: pagination.page_size,
                  total: suppliers.count,
                  onChange: (page, page_size) =>
                    setPagination({ page, page_size }),
                  showSizeChanger: true,
                  pageSizeOptions: [5, 10, 25, 50],
                  showTotal: (total, [from, to]) =>
                    `Displaying items ${from} - ${to} of ${total} total`
                }}
                onChange={(_pagination, _filters, sorter) => {
                  if (
                    !['ascend', 'descend'].includes(sorter?.order) ||
                    (!sorter.column.sortKey &&
                      typeof sorter.column.dataIndex !== 'string')
                  ) {
                    setFilters((currentFilters) => {
                      const filtersClone = { ...currentFilters };
                      if (currentFilters.ordering) delete filtersClone.ordering;

                      return filtersClone;
                    });
                  } else {
                    setFilters((currentFilters) => ({
                      ...currentFilters,
                      ordering: `${sorter.order === 'descend' ? '-' : ''}${
                        sorter.column.sortKey || sorter.column.dataIndex
                      }`
                    }));
                  }
                }}
                rowKey="uuid"
                rowSelection={{
                  type: 'checkbox',
                  onChange: (_, newSelectedRows) =>
                    setSelectedRows(newSelectedRows),
                  preserveSelectedRowKeys: true
                }}
                scroll={{
                  scrollToFirstRowOnChange: true,
                  x: 'scroll'
                }}
                sticky
                summary={() =>
                  rfqFilter && (
                    <Table.Summary fixed="top">
                      <Table.Summary.Row>
                        {/* Placeholder for checkbox column */}
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        {columns.map((c, i) => {
                          const value = Array.isArray(c.dataIndex)
                            ? c.dataIndex.reduce(
                                (currentValue, currentIndex) =>
                                  currentValue[currentIndex],
                                rfqFilter
                              )
                            : rfqFilter[c.dataIndex];
                          let renderedValue = value;
                          try {
                            if (c.rfqRender)
                              renderedValue = c.rfqRender(rfqFilter);
                            else if (c.render) renderedValue = c.render(value);
                          } catch (_) {
                            renderedValue = 'error';
                          }
                          return (
                            <Table.Summary.Cell
                              key={
                                c.key ||
                                (Array.isArray(c.dataIndex)
                                  ? c.dataIndex.join('.')
                                  : c.dataIndex)
                              }
                              index={i + 1}
                            >
                              <Typography.Text strong>
                                {renderedValue}
                              </Typography.Text>
                            </Table.Summary.Cell>
                          );
                        })}
                      </Table.Summary.Row>
                    </Table.Summary>
                  )
                }
              />
            )) ||
              (display === 'map' && (
                <MapPage
                  mapProps={{ zoom: 2 }}
                  filters={filters}
                  additionalMarkers={rfqMarkers || []}
                />
              )) ||
              (display === 'search' && <SearchPage filters={filters} />)}
          </Col>
        </Row>
      </div>
    </div>
  );
}
