import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, List, message, Space } from 'antd';
import { defaultUnitForType } from 'src/utils/units';
import { spaceSpecToDescription } from 'src/utils/colorSpace';
import { useMutation } from '@tanstack/react-query';
import { createDealSampleResult } from 'src/Mutation';
import SampleResultFormItem from './SampleResultFormItem';

const ignoreProperties = ['type', 'form', 'grade'];

const formatNumber = (number) => {
  const n = parseFloat(number);
  return n;
};

function DealSampleForm({ deal, sampleResult, seller }) {
  const [form] = Form.useForm();
  const [statusPressed, setStatusPressed] = useState();
  const rfq = deal?.rfq;
  const { material_properties, material_numerical_properties } = rfq || {};
  const [messageApi, contextHolder] = message.useMessage();

  const materialProperties =
    useMemo(
      () =>
        Array.isArray(material_properties) &&
        material_properties.filter(
          (o) => ignoreProperties.indexOf(o?.meta_property.code) < 0
        ),
      [material_properties]
    ) || [];

  const {
    mutate,
    isLoading: isMutating,
    error: errorStore
  } = useMutation({
    mutationFn: createDealSampleResult,
    onSuccess: () => {
      messageApi.success('Your sample results have been recorded');
    },
    onError: () => {
      messageApi.error(
        'Error recording your sample results, please contact us.'
      );
    }
  });

  const materialNumericalProperties = material_numerical_properties || [];
  const targetProperty = (property) =>
    property.meta_property.code === 'color' && property.value === 'Custom'
      ? spaceSpecToDescription(rfq.color_space_spec)
      : property.value;
  const targetNumericalProperty = (property) => {
    const unit = defaultUnitForType(property.property.unit_type);
    if (property.min && !property.max)
      return `> ${formatNumber(property.min)} ${unit}`;
    if (!property.min && property.max)
      return `< ${formatNumber(property.max)} ${unit}`;
    if (property.min && property.max)
      return `${formatNumber(property.min)} - ${formatNumber(
        property.max
      )} ${unit}`;
    return 'Unknown';
  };
  const submit = (status) => {
    setStatusPressed(status);
    const values = form.getFieldsValue();
    const body = {
      dealId: deal.uuid,
      seller,
      status,
      material_property_results: Object.values(
        values.material_properties || {}
      ).map((o) => ({
        passed: o.passed,
        material_property_id: o.property.uuid,
        comment: o.value
      })),
      material_numerical_property_results: Object.values(
        values.material_numerical_properties || {}
      ).map((o) => ({
        passed: o.passed,
        material_numerical_property_id: o.property?.property.uuid,
        comment: o.value,
        min: parseFloat(o.value) || 0,
        max: parseFloat(o.value) || 0
      }))
    };
    mutate(body);
  };

  return (
    <div className="deal-sample-form">
      {contextHolder}
      <Form form={form}>
        <List size="large" key="description">
          {materialProperties.map((property) => (
            <SampleResultFormItem
              key={property.meta_property.uuid}
              name={['material_properties', property.meta_property.uuid]}
              target={targetProperty(property)}
              label={property.meta_property.name}
              valueComponent={Input}
              promptFail="What was the reason it failed?"
              property={property}
            />
          ))}
          {materialNumericalProperties.map((property) => (
            <SampleResultFormItem
              key={property.uuid}
              name={['material_numerical_properties', property.uuid]}
              target={targetNumericalProperty(property)}
              label={property.property.name}
              valueComponent={Input}
              suffix={defaultUnitForType(property.property.unit_type)}
              promptFail="What was the actual value?"
              property={property}
            />
          ))}
          <List.Item />
        </List>
        <Space>
          <Button
            danger
            onClick={() => submit('rejected')}
            loading={isMutating && statusPressed === 'rejected'}
          >
            Reject Sample
          </Button>
          <Button
            type="primary"
            onClick={() => submit('approved')}
            loading={isMutating && statusPressed === 'approved'}
          >
            Approve Sample
          </Button>
        </Space>
      </Form>
    </div>
  );
}

DealSampleForm.propTypes = {
  sampleResult: PropTypes.object,
  seller: PropTypes.string,
  deal: PropTypes.object
};

export default DealSampleForm;
