import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { paramCase } from 'change-case';
import numeral from 'numeral';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getDeal, getEvents } from 'src/Query';
import { Tag, Tabs, Button, Table, message } from 'antd';
import {
  EllipsisOutlined,
  FileOutlined,
  RightOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useUser } from 'src/utils/authentication';
import { joinDeal } from 'src/Mutation';
import { QueryStatus } from 'src/components/error/QueryStatus';
import Icon from 'src/components/icons/Icon';
import { ReactComponent as ChatIcon } from 'src/images/icons/chat.svg';
import { currencySymbol, currencyValuePrice } from 'src/utils/currency';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { ActivityList } from './ActivityList';
import DealRoomHeader from './DealRoomHeader';
import DealRoomBody from './DealRoomBody';
import DealDelivery from './DealDelivery';
import DealEmissions from './DealEmissions';

const TAB_PARAM_NAME = 'step';
const ACTION_PARAM_NAME = 'action';

const roleMap = {
  decision_maker: 'Primary',
  logistics: 'Logistics',
  operations: 'Operations',
  procurement: 'Procurement',
  sales: 'Sales',
  finance: 'Finance',
  esg: 'ESG',
  manufacturing: 'Manufacturing',
  legal: 'Legal',
  platform: 'Platform'
};

const platformRoleMap = {
  administrator: 'Admin',
  member: 'Member'
};

const statusMap = {
  draft: { type: 'warning', label: 'Draft' },
  accepted: { type: 'success', label: 'Open' },
  withdrawn: { type: 'error', label: 'Closed' },
  default: { type: 'error', label: 'Unknown' }
};

const participantStatusMap = {
  invited: { type: 'default', label: 'Invited' },
  active: { type: 'success', label: 'Active' },
  withdrawn: { type: 'error', label: 'Withdrawn' }
};

function DealRoomOpen({ status }) {
  const statusDetails = statusMap[status] || statusMap.default;
  return <Tag color={statusDetails.type}>{statusDetails.label}</Tag>;
}

DealRoomOpen.propTypes = {
  status: PropTypes.string
};

function WhatsNext({ icon, title, details, action, actionText }) {
  return (
    <div className="deal-room-whats-next">
      {icon}
      <div>
        <h2>{title}</h2>
        <p>{details}</p>
        {action && (
          <Button type="primary" onClick={action}>
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );
}

WhatsNext.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  details: PropTypes.string,
  action: PropTypes.func,
  actionText: PropTypes.string
};

const stageMap = {
  review: undefined,
  sampling: 0,
  terms: 1,
  contract: 2,
  delivery: 3
};

const stages = [
  { label: 'Sampling' },
  { label: 'Confirm Deal Terms' },
  { label: 'Contract' },
  { label: 'Delivery' }
];

function ChatButton({ onClick, active }) {
  return (
    <button
      className={`chat-button ${(active && 'active') || ''}`}
      type="button"
      onClick={onClick}
    >
      <ChatIcon />
    </button>
  );
}

ChatButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool
};

function DealRoomProgress({ stage }) {
  const stageIndex = stageMap[stage] || 0;
  return (
    <div className="deal-progress">
      {stages.map((stageDetails, index) => (
        <div
          key={stageDetails.label}
          className={`deal-progress-step ${
            index === stageIndex ? 'current' : ''
          }`}
        >
          {index !== 0 && <RightOutlined />}
          <span className="step-number">{index + 1}</span>
          {stageDetails.label}
        </div>
      ))}
    </div>
  );
}

DealRoomProgress.propTypes = {
  stage: PropTypes.string
};

function DealRoomProperty({ label, value, units }) {
  return (
    <div className="deal-room-property">
      <label>{label}</label>
      {value}
      <span>{units}</span>
    </div>
  );
}

DealRoomProperty.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  units: PropTypes.string
};

const memberColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    fixed: 'left',
    render: (value, row) => (
      <div className="member-name">
        <div className="member-avatar">
          <UserOutlined />
        </div>
        <div className="member-name-text">
          <label>{value}</label>
          {row.email}
        </div>
      </div>
    )
  },
  {
    title: 'Organization',
    dataIndex: 'company',
    fixed: 'left'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    fixed: 'left',
    render: (status) => (
      <Tag color={status?.type || 'error'}>{status?.label || '-'}</Tag>
    )
  },
  {
    title: 'Role',
    dataIndex: 'role',
    fixed: 'left'
  },
  {
    title: 'Account Type',
    dataIndex: 'type',
    fixed: 'left'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    fixed: 'left',
    render: (_, row) => (
      <div className="member-actions">
        <a href={`mailto:${row.email}`}>
          <Button type="default">Email</Button>
        </a>
        <Button type="default">
          <EllipsisOutlined />
        </Button>
      </div>
    )
  }
];

const documentColumns = [
  {
    title: 'Document',
    dataIndex: 'name',
    fixed: 'left',
    render: (value) => (
      <div className="document-name">
        <div className="document-avatar">
          <FileOutlined />
        </div>
        {value}
      </div>
    )
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    fixed: 'left'
  },
  {
    title: 'Size',
    dataIndex: 'size',
    fixed: 'left'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    fixed: 'left',
    render: (_, row) => (
      <div className="document-actions">
        <a href={row.url} download={row.name} target="_blank">
          <Button type="default">Open</Button>
        </a>
        <Button type="default">
          <EllipsisOutlined />
        </Button>
      </div>
    )
  }
];

function DealRoom() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get(TAB_PARAM_NAME);
  const actionParam = searchParams.get(ACTION_PARAM_NAME);
  const [activeTab, setActiveTab] = useState('overview');
  const {
    data: deal,
    error,
    isLoading
  } = useQuery(['deal', id], () => getDeal(id));

  const {
    data: events,
    error: errorEvents,
    isLoading: isLoadingEvents
  } = useQuery(['event', id], () =>
    getEvents({ uuid: id, model: 'deal', all: true, ordering: '-created_at' })
  );

  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const {
    mutate: mutateJoin,
    isLoading: isMutatingJoin,
    error: errorJoining
  } = useMutation({
    mutationFn: joinDeal,
    onSuccess: () => {
      messageApi.success('You have joined this deal');
      queryClient.invalidateQueries({ queryKey: ['deal'] });
    }
  });

  const { data: user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(step || undefined);
  }, [step]);

  useEffect(() => {
    if (searchParams.get(ACTION_PARAM_NAME)) {
      // handle the action
      searchParams.delete(ACTION_PARAM_NAME);
      setSearchParams(searchParams);
    }
  }, [actionParam]);

  const location = useLocation();

  const tabChange = (key) => {
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    if (searchParams.get(TAB_PARAM_NAME)) {
      searchParams.delete(TAB_PARAM_NAME);
      setSearchParams(searchParams);
    }
  };

  const suppliers =
    (deal?.supplies?.length > 1 && '& Multiple Suppliers') ||
    (deal?.supplies?.length === 1 &&
      `& ${deal.supplies[0]?.technical_data_sheet?.company?.name}`) ||
    (deal?.supplies?.length === 0 && '');
  const title = `${deal?.rfq?.company?.name} ${suppliers}`;
  const getMaterialProperty = (code) =>
    deal?.rfq.material_properties.find(
      (o) => paramCase(o.meta_property.code) === code
    );
  const material = getMaterialProperty('type')?.value;
  const form = getMaterialProperty('form')?.value;
  const durationMonths = deal?.duration_months;
  const useLbForPrice = false; // deal?.units_price_lbs;
  const priceValue = useLbForPrice ? deal?.price_per_lb : deal?.price;
  const price = `${currencySymbol(deal?.currency)}${numeral(priceValue).format(
    '0.00'
  )}`;
  const priceWeightUnits = useLbForPrice ? 'lb' : deal?.price_weight_units;
  const quantity = deal?.quantity;
  const totalQuantity =
    (deal?.quantity || 0) * (deal?.rfq?.duration_months || 1);
  const quantity_units = deal?.quantity_units;

  const members = useMemo(
    () =>
      Array.isArray(deal?.participants) &&
      deal.participants.map((member) => ({
        id: member.user?.id,
        name: member.user?.name,
        email: member.user?.email,
        company: member.user?.company?.name,
        status: participantStatusMap[member?.status],
        statusCode: member?.status,
        role: roleMap[member.role] || '-',
        type: platformRoleMap[member.platform_role] || '-'
      })),
    [deal]
  );

  const me = useMemo(
    () => members && members.find((member) => user && user.id === member.id),
    [members, user]
  );

  useEffect(() => {
    if (me && me.statusCode === 'invited') {
      mutateJoin(id);
    }
  }, [deal, me]);

  const tabItems = useMemo(() => {
    const nextActions = deal?.next_actions || [];
    const documents =
      Array.isArray(deal?.documents) &&
      deal.documents.map((document) => {
        const nameParts = document.document.split('/');
        const name = nameParts && nameParts[nameParts.length - 1];
        return {
          name,
          owner: document.company?.name,
          url: document.document
        };
      });
    return [
      {
        key: 'overview',
        label: 'Overview',
        children: (
          <div className="deal-room-overview">
            <h4>What&apos;s next</h4>
            {nextActions.map((action) => (
              <WhatsNext
                key={`${action.title}-${action.description}`}
                icon={<Icon name={action.icon || 'HomeOutlined'} />}
                title={action.title}
                details={action.description}
                actionText={action.action_name}
                action={() => action.path && navigate(action.path)}
              />
            ))}
            <h4>Deal room activity</h4>
            <QueryStatus
              title="Deal activity"
              error={errorEvents}
              isLoading={isLoadingEvents}
            >
              <ActivityList activity={events} />
            </QueryStatus>
          </div>
        )
      },
      {
        key: 'members',
        label: 'Members',
        children: (
          <div className="deal-room-members">
            <Button type="primary">Invite New Member</Button>
            <h4>Members</h4>
            <p>
              This is where you can find information on members of this deal
            </p>
            <div className="members-list">
              <Table
                columns={memberColumns}
                dataSource={members}
                pagination={false}
              />
            </div>
          </div>
        )
      },
      {
        key: 'documents',
        label: 'Documents',
        children: (
          <div className="deal-room-documents">
            <Button type="primary">Upload Document</Button>
            <h4>Documents</h4>
            <p>
              This is where you can find all of your documents related to this
              deal.
            </p>
            <div className="documents-list">
              <Table
                columns={documentColumns}
                dataSource={documents}
                pagination={false}
              />
            </div>
          </div>
        )
      },
      {
        key: 'delivery',
        label: 'Delivery',
        children: (
          <DealDelivery
            deliveries={deal?.deliveries}
            suppliers={deal?.suppliers}
          />
        )
      },
      {
        key: 'emissions',
        label: 'Emissions',
        children: (
          <DealEmissions
            materialType={material}
            emissions={deal?.emissions_certifications[0]}
          />
        )
      }
    ];
  }, [events, deal, members, errorEvents, isLoadingEvents]);
  return (
    <div className="deal-room">
      {contextHolder}
      <QueryStatus isLoading={isLoading} title="Deal" error={error}>
        {deal && (
          <>
            <DealRoomHeader>
              <h4>Deal room</h4>
              <DealRoomOpen status={deal.status} />
              <DealRoomProgress status={deal.status} />
            </DealRoomHeader>
            <AdminConsoleLink app="api" type="deal" uuid={id} />
            <DealRoomBody>
              <div className="deal-room-banner">
                <div className="deal-room-title-area">
                  <h1>{title}</h1>
                  <span>{dayjs(deal.updated_at).format('MM/DD/YY')}</span>
                </div>
                <div className="deal-room-properties">
                  <DealRoomProperty label="Material" value={material} />
                  <DealRoomProperty label="Form" value={form} />
                  <DealRoomProperty
                    label="Quantity"
                    value={prettyNumberRound(totalQuantity)}
                    units={quantity_units}
                  />
                  <DealRoomProperty
                    label="Duration"
                    value={durationMonths}
                    units="mo"
                  />
                  <DealRoomProperty
                    label="Desired price"
                    value={currencyValuePrice(price, deal.currency)}
                    units={`/${priceWeightUnits}`}
                  />
                </div>
              </div>
              <Tabs
                activeKey={activeTab}
                onChange={tabChange}
                defaultActiveKey="overview"
                items={tabItems}
              />
            </DealRoomBody>
          </>
        )}
      </QueryStatus>
    </div>
  );
}

export default DealRoom;
