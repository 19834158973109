import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useToken, useUser } from 'src/utils/authentication';
import { DASHBOARD_URL } from './SignIn';

function Private({ children, permissions = [] }) {
  const { data: user, isFetched } = useUser();
  const { access, refresh } = useToken();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const sesame = searchParams.get('sesame');

  if (!isFetched) return <Skeleton />;

  const signInRedirect = !sesame
    ? `/login?next=${window.location.pathname}`
    : `/sesame/login?sesame=${sesame}&next=${window.location.pathname}`;

  if (!access && !refresh && !user && pathname !== '/login')
    return <Navigate to={signInRedirect} />;

  if (
    !permissions.every((permissionFunc) => permissionFunc(user)) &&
    pathname !== '/project/dashboard'
  )
    return <Navigate to={DASHBOARD_URL} />;

  // we want to guarantee to the children of <Private> that
  // the user data is avaialble.
  // the downside is we serialize some data loads, but only once when the app starts
  return user && children;
}

Private.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.func)
};

export default Private;
