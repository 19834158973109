import React from 'react';
import { Form } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchRFQ } from 'src/Mutation';

export default function usePatchRfqForm({
  rfqId,
  children,
  onFinish,
  parseValues,
  ...props
}) {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: patchRFQ,
    onSuccess: (data) => {
      onFinish();
      queryClient.invalidateQueries({
        queryKey: ['requestForQuote', rfqId]
      });
    }
  });
  const [form] = Form.useForm();

  const comp = (
    <Form
      form={form}
      disabled={isLoading}
      onFinish={(values) => {
        if (parseValues)
          return mutateAsync({
            uuid: rfqId,
            ...parseValues(values)
          });

        return mutateAsync({
          uuid: rfqId,
          ...values
        });
      }}
      // layout="vertical"
      {...props}
    >
      {children}
    </Form>
  );

  return {
    PatchRfqForm: comp,
    form
  };
}
