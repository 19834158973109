import React from 'react';
import PropTypes from 'prop-types';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';

export function useSupplierName(supplier) {
  const value = useConciergeContext(['supplier', 'names']);

  return value === 'anonymize' ? supplier.anonymized_name : supplier.name;
}

export default function SupplierName({ supplier }) {
  const companyName = useSupplierName(supplier);

  return <span className="company-name">{companyName}</span>;
}

SupplierName.propTypes = {
  supplier: PropTypes.shape({
    name: PropTypes.string,
    anonymized_name: PropTypes.string
  })
};
