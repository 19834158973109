import { useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// import 'mapbox-gl/dist/svg/mapboxgl-ctrl-compass.svg';
// import 'mapbox-gl/dist/svg/mapboxgl-ctrl-geolocate.svg';
// import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-in.svg';
// import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-out.svg';

mapboxgl.accessToken =
  'pk.eyJ1Ijoiam9yZGFuY2lyY3VsYXIiLCJhIjoiY2xmanNxbm41MDQwYTN2bXN2eW53cTQ2dCJ9.noJDgL9kVI-Pzk0O4ga-vQ';

const mapboxThemes = {
  dark: 'mapbox://styles/jordancircular/clflaooaj00ek01nxkt26727r',
  light: 'mapbox://styles/jordancircular/clfjst0fv000x01p6ufho71sj',
  light_new: 'mapbox://styles/jordancircular/clyx995bz00dt01pe2psj3c5m'
};

export default function useMap({
  mapProps,
  onMove,
  onZoom,
  disableControls,
  theme = 'light'
} = {}) {
  const [map, setMap] = useState();
  const [latLong, setLatLong] = useState([0, 0]);
  const [zoom, setZoom] = useState(0);

  const onMapContainerChange = useCallback((node) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
      map?.remove();
      setMap(null);
    } else {
      // DOM node referenced by ref has changed and exists
      const m = new mapboxgl.Map({
        container: node,
        style: mapboxThemes[theme], // style URL
        // center: [-74.5, 40], // starting position [lng, lat]
        projection: {
          name: 'mercator',
          center: [0, 30],
          parallels: [30, 30]
        },
        zoom: 2, // starting zoom
        ...mapProps
      });

      if (!disableControls) {
        m.addControl(new mapboxgl.NavigationControl(), 'top-right');
        m.addControl(
          new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
          }),
          'bottom-left'
        );
      }

      m.on('move', () => {
        const { lng, lat } = m.getCenter();
        const newZoom = m.getZoom();
        setLatLong([lat, lng]);
        setZoom(newZoom);
        updateBounds(m);

        if (onMove) {
          onMove({ lng, lat, newZoom });
        }
      });

      m.on('zoomend', () => {
        const newZoom = m.getZoom();
        setZoom(newZoom);
        updateBounds(m);

        if (onZoom) {
          onZoom(newZoom);
        }
      });

      m.on('load', () => {
        // hello
      });

      setMap(m);
    }
  }, []);

  useEffect(() => {
    if (mapProps?.bounds && map) map.fitBounds(mapProps?.bounds);
  }, [mapProps?.bounds]);

  return { map, mapContainer: onMapContainerChange, latLong, zoom };
}

function updateBounds(m) {
  const canvas = m.getCanvas();
  const w = canvas.width;
  const h = canvas.height;
  const cUL = m.unproject([0, 0]).toArray();
  const cUR = m.unproject([w, 0]).toArray();
  const cLR = m.unproject([w, h]).toArray();
  const cLL = m.unproject([0, h]).toArray();
  const coordinates = [cUL, cUR, cLR, cLL];
}
