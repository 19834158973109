import { useMemo } from 'react';

/**
 * Formats the deal paramters for display on a proposal
 *
 * @param {Deal} deal
 * @returns object with formatted attributes of deal
 */

function getDealData(deal) {
  const { uuid, currency, scores } = deal;
  const useLbForPrice = false; // deal.units_price_lbs; this doesn't work because of currency conversions
  const pricePerLb = Number(deal.price_per_lb);
  const rfqPricePerLb = Number(deal.rfq.price_per_lb);
  const rfqPrice = useLbForPrice ? rfqPricePerLb : Number(deal.rfq.price);
  const useIndexPrice = deal.rfq.price_is_index;
  const price = useIndexPrice
    ? rfqPrice
    : useLbForPrice
    ? pricePerLb
    : Number(deal.price);
  const priceAboveIndex =
    deal.price === deal.rfq.price ? 0 : deal.price > deal.rfq.price ? 1 : -1;
  const priceWeightUnits = useLbForPrice ? 'lb' : deal.price_weight_units;
  const quantity = Number(deal.quantity);
  const quantityLb = Number(deal.quantity_lbs);
  const quantityUnits = deal.quantity_units;
  const fee = Number(deal.fee);
  const shippingCost = Number(deal.shipping_cost);
  const quantityPreferred = useLbForPrice ? quantityLb : quantity;
  const shippingUnitCost = shippingCost / quantityPreferred;
  const deliveredCost = price + shippingUnitCost;
  const durationMonths = Number(deal.duration_months);
  const monthlyCost = Number(deal.price) * quantity + shippingCost;
  const deposit = Number(deal.deposit);
  const totalCost = monthlyCost * (durationMonths || 1) + (fee || 0);
  const totalMaterialCost =
    Number(deal.price) * quantity * (durationMonths || 1) + (fee || 0);
  return {
    uuid,
    useLbForPrice,
    pricePerLb,
    currency,
    price,
    priceWeightUnits,
    priceAboveIndex,
    useIndexPrice,
    quantity,
    quantityLb,
    quantityUnits,
    fee,
    shippingCost,
    shippingUnitCost,
    deliveredCost,
    durationMonths,
    monthlyCost,
    deposit,
    totalCost,
    totalMaterialCost,
    scores
  };
}

export default function useDealData(deal) {
  return useMemo(() => {
    if (Array.isArray(deal)) {
      return deal.map((dealItem) => getDealData(dealItem));
    }
    return getDealData(deal);
  }, [deal]);
}
