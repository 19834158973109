import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable import/no-unresolved */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */
import { Typography } from 'antd';

import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { MinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { highlightFilters } from 'src/components/project/explore/charts/chartShared';
import { useKpiUnit } from 'src/components/project/explore/filters/KpiFilter';
import falsyNot0 from 'src/components/utils/falsyNot0';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import { getKpiAnalytics } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import { generateHistogram, splitHistogram } from './histogram';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1
    }
  },
  responsive: true,
  plugins: {
    legend: false,
    title: false,
    tooltip: false
  },
  scales: {
    x: {
      display: true,
      ticks: {
        stepSize: 1
      },
      beginAtZero: true
    },
    y: {
      display: true,
      reverse: true,
      beginAtZero: false
    }
  }
};

function SpecCapabilityHistogram({
  decimalPrecision,
  filters,
  kpiCode,
  kpiFilters,
  max,
  min,
  step
}) {
  const chartRef = useRef(null);

  const { min: specMinFilter, max: specMaxFilter } =
    kpiFilters?.[kpiCode] || {};

  const { data: kpiData, isLoading } = useDebouncedQuery({
    queryKey: ['warehouse', 'kpi-analytics', filters],
    queryFn: () => getKpiAnalytics(filters)
  });

  const supplier = useConciergeContext(['explore', 'insights', 'company']);

  const loadingStateClass = useLoadingStateClass(isLoading);

  const specData = kpiData?.[kpiCode] ?? undefined;

  const defaultUnits = useKpiUnit(kpiCode);
  const kpiUnit =
    specData?.units || kpiFilters?.[kpiCode]?.units || defaultUnits;

  const computedOptions = {
    ...options,
    rangeStart: specMinFilter,
    rangeEnd: specMaxFilter,
    step,
    scales: {
      ...options.scales,
      y: {
        ...options.scales.y,
        max: (max || 1).toFixed(
          decimalPrecision < 0 ? decimalPrecision * -1 : 0
        ),
        min: (min || 0).toFixed(
          decimalPrecision < 0 ? decimalPrecision * -1 : 0
        ),
        offset: false,
        ticks: {
          stepSize: step
        },
        stacked: true
      }
    }
  };
  const highlightSelectedFilters = useMemo(
    () => ({
      id: 'highlightSelectedFilters',
      afterDatasetsDraw: highlightFilters
    }),
    [step, isLoading]
  );

  const data = useMemo(() => {
    const [histogram, labels] = generateHistogram(
      specData?.values,
      decimalPrecision,
      min,
      max,
      step
    );
    const [insideData, outsideData] = splitHistogram(
      histogram,
      decimalPrecision,
      min,
      max,
      step,
      specMinFilter,
      specMaxFilter
    );
    return {
      labels,
      datasets: [
        {
          label: 'Spec Count (match)',
          data: insideData,
          borderRadius: 2,
          borderColor: '#2F54EB',
          backgroundColor: '#2F54EB'
        },
        {
          label: 'Spec Count',
          data: outsideData,
          borderRadius: 2,
          borderColor: 'rgba(184, 197, 215, 0.50)',
          backgroundColor: 'rgba(184, 197, 215, 0.50)'
        }
      ]
    };
  }, [specData]);

  const noValues = falsyNot0(specMaxFilter) && falsyNot0(specMinFilter);

  const kpiObj = useMetaNumericalProperty(kpiCode);

  return (
    <ExplorePanel className={loadingStateClass} pad>
      <Typography.Paragraph strong className="font-size-4 mb-sm">
        {kpiObj ? kpiObj.name : kpiCode}
        {kpiCode ? ' Values' : ''}
      </Typography.Paragraph>

      {/* TODO: extract to a separate component, common to charts */}
      <p className="chart-info-label">
        <span className="chart-info-bullet">&nbsp;</span>
        {supplier ? (
          <span className="mr-xxs">
            {supplier.name}{' '}
            <strong>{kpiObj ? kpiObj.name : kpiCode} Value</strong>
          </span>
        ) : null}
        {!noValues ? (
          <>
            <MinMaxRangeString
              max={specMaxFilter}
              min={specMinFilter}
              kpiUnit={kpiUnit}
            />
            {!supplier && `: `}
          </>
        ) : null}
        {!supplier && (
          <>
            <strong>{specData?.values?.length || 0} Records</strong>{' '}
            {noValues ? '' : 'have values that match your range'}
          </>
        )}
      </p>

      <p className="axis-label">
        {kpiCode} Values{kpiUnit ? ` (${kpiUnit})` : ''}
      </p>
      {!isLoading && (
        <Bar
          data={data}
          options={computedOptions}
          plugins={[highlightSelectedFilters]}
          ref={chartRef}
        />
      )}
      <p className="axis-label right-aligned">
        {supplier
          ? '# Values from Records'
          : '# Values matching your range from records'}
      </p>
    </ExplorePanel>
  );
}

SpecCapabilityHistogram.propTypes = {
  decimalPrecision: PropTypes.number.isRequired,
  filters: PropTypes.object,
  kpiCode: PropTypes.string.isRequired,
  kpiFilters: PropTypes.object,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired
};

export default SpecCapabilityHistogram;
