const SUBMITTED = 'submitted';
const PROPOSAL = 'proposal';
const REJECTED_BY_CIRCULAR = 'rejected_by_circular';
const REJECTED_BY_BUYER = 'rejected_by_buyer';
const APPROVED = 'approved';

export const ProjectTDSStatus = {
  SUBMITTED,
  PROPOSAL,
  REJECTED_BY_CIRCULAR,
  REJECTED_BY_BUYER,
  APPROVED
};
