import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Alert, Typography, Space } from 'antd';
import { fetch } from 'src/utils/client';
import { useAuthentication, useUser } from 'src/utils/authentication';
import BackgroundPageLayout from 'src/components/layout/BackgroundPageLayout';
import { policyDescription } from './policy';
import { DASHBOARD_URL } from './SignIn';
// labels above controls
// https://github.com/ant-design/ant-design/issues/14063

const { useForm } = Form;

function SignUp({ next }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const { signIn } = useAuthentication();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailParam = searchParams.get('email');
  const nameParam = searchParams.get('name');
  const company = searchParams.get('company');
  const rfi = searchParams.get('request-for-information');
  const { data: user, isLoading: isLoadingUser } = useUser();

  const nextParam = searchParams.get('next');
  const isOnboardingParam = !!searchParams.get('onboarding');

  useEffect(() => {
    if (!isLoadingUser && !user && (!company || !rfi))
      navigate('/request-for-information');
  }, [isLoadingUser, user]);

  const [form] = useForm();

  const onSignUp = async (values) => {
    try {
      const json = await fetch(
        `${process.env.REACT_APP_CIRCLE_API}/user/signup/`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: values.email,
            name: values.name,
            password: values.password,
            company,
            rfi,
            send_email: isOnboardingParam ? 1 : 0
          })
        }
      );

      if (json) {
        setEmail(values.email);
        signIn.mutate(values, {
          onSuccess: () => {
            navigate(nextParam ? decodeURIComponent(nextParam) : DASHBOARD_URL);
          },
          onError: (err) => {
            setError('Error signing in, please contact us.');
          }
        });
      } else {
        window.console.error('error signing up', json);
        const message = json?.detail || 'Error signing up, please try again';
        setError(message);
      }
    } catch (err) {
      window.console.error('error signing up', err);
      setError(err.message);
    }
  };
  const onSignUpFailed = (errorInfo) => {
    window.console.error('SignUp failed:', errorInfo);
  };

  const nameKnown = nameParam || user?.name;
  const emailKnown = emailParam || user?.email;

  return (
    <BackgroundPageLayout>
      {!isLoadingUser && (
        <Form
          className="signup-form auth-form"
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSignUp}
          initialValues={{ email: emailKnown, name: nameKnown }}
          onFinishFailed={onSignUpFailed}
          autoComplete="off"
        >
          {email && (
            <Alert
              message="A confirmation link has been sent to your email."
              type="success"
              showIcon
              closable
            />
          )}
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Typography.Title level={3}>
              {nameKnown && emailKnown
                ? 'Finish setting up your account'
                : 'Sign up for Circular'}
            </Typography.Title>
            <div>
              <Form.Item
                label="First and last name"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please input your name'
                  }
                ]}
              >
                <Input disabled={!!nameKnown} />
              </Form.Item>
              <Form.Item
                label="Company email"
                name="email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateTrigger="onSubmit"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not a valid email address'
                  },
                  {
                    required: true,
                    message: 'Please input your email address'
                  }
                ]}
              >
                <Input
                  placeholder="your.name@company.com"
                  disabled={!!emailKnown}
                />
              </Form.Item>

              <Form.Item
                label="New Password"
                help={policyDescription}
                rules={[
                  { required: true, message: 'Please input your password' },
                  { min: 8, message: 'Password must be minimum 8 characters.' }
                ]}
                name="password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="passwordConfirm"
                rules={[
                  {
                    validator: (_, value) =>
                      value === form.getFieldValue('password')
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error('The password must be the same')
                          )
                  }
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="agree"
                className="accept-toc"
                valuePropName="checked"
                wrapperCol={{
                  span: 24
                }}
                rules={[
                  // eslint-disable-next-line
                  {
                    // eslint-disable-next-line
                    validator: (
                      _,
                      value // eslint-disable-next-line
                    ) =>
                      value // eslint-disable-next-line
                        ? Promise.resolve() // eslint-disable-next-line
                        : Promise.reject(
                            // eslint-disable-next-line
                            new Error(
                              'You must agree to the terms and conditions'
                            ) // eslint-disable-next-line
                          ) // eslint-disable-next-line
                  }
                ]}
              >
                <Checkbox>
                  <Typography.Text>
                    I agree to the{' '}
                    <Link to="/terms" target="_blank">
                      Terms &amp; Conditions
                    </Link>{' '}
                    and{' '}
                    <Link to="/privacy" target="_blank">
                      Privacy Policy
                    </Link>
                  </Typography.Text>
                </Checkbox>
              </Form.Item>
            </div>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form>
      )}
    </BackgroundPageLayout>
  );
}

SignUp.propTypes = {
  next: PropTypes.string
};

export default SignUp;
