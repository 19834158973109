import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Icon } from './svg/marker-small-icon.svg';
import SvgIconComponent from './SvgIconComponent';

function MarkerSmallIcon({ style, color, ...props }) {
  const combinedStyle = { ...(style || {}), color: color || '#2F54EB' };
  return (
    <SvgIconComponent {...props}>
      <Icon style={{ height: 26, width: 26 }} />
    </SvgIconComponent>
  );
}

MarkerSmallIcon.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string
};

export default MarkerSmallIcon;
