import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_URL } from 'src/pages/auth/SignIn';
import TechnicalDataSheet from './TechnicalDataSheet';

function TdsCreatePage() {
  const { supplierId } = useParams();
  const navigate = useNavigate();

  const hideModal = () => {
    navigate(DASHBOARD_URL);
  };
  const onSuccess = (response) => {
    navigate(`/seller-deal/quote/${response.uuid}/review`);
    return true;
  };
  return (
    <div className="tds-create-page">
      {supplierId && (
        <TechnicalDataSheet
          hideModal={hideModal}
          onSuccess={onSuccess}
          open
          supplierId={supplierId}
          canDuplicate={false}
        />
      )}
    </div>
  );
}

export default TdsCreatePage;
