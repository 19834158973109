import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

function Page({ children, className }) {
  return (
    <div className={`page-container ${className || ''}`}>
      <div className="page-body">
        <Col
          lg={{ offset: 1, span: 22 }}
          md={{ offset: 1, span: 22 }}
          sm={{ offset: 1, span: 22 }}
          xs={{ offset: 1, span: 22 }}
        >
          {children}
        </Col>
      </div>
    </div>
  );
}

export default Page;

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};
