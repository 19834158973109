import React from 'react';
import { ReactComponent as Icon } from './svg/searchCircular.svg';
import SvgIconComponent from './SvgIconComponent';

function IconComponent() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default IconComponent;
