import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Avatar, Spin, Tooltip } from 'antd';
import {
  BarChartOutlined,
  FilterOutlined,
  LeftOutlined,
  LinkOutlined,
  LockFilled,
  OrderedListOutlined,
  RightOutlined
} from '@ant-design/icons';
import TruncatableLabel from 'src/components/utils/TruncatableLabel';
import CircularLogo from 'src/components/icons/CircularLogo';
import DashboardIcon from 'src/components/icons/Dashboard';
import ArrowEnd from 'src/components/icons/ArrowEnd';
import ArrowStart from 'src/components/icons/ArrowStart';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularLogoSymbol from 'src/components/icons/CircularLogoSymbol';
import ThunderboltIcon from 'src/components/icons/Thunderbolt';
import useSesameInvite from 'src/components/sesame/useSesameInvite';
import useIsConcierge from 'src/hooks/useIsConcierge';
import Lock from 'src/components/icons/Lock';
import { useAuthentication, useUser } from 'src/utils/authentication';
import useIsSupplier from 'src/hooks/useIsSupplier';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import CompassOutlined from 'src/components/icons/CompassOutlined';
import SendOutlined from 'src/components/icons/SendOutlined';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import CubeOutlined from 'src/components/icons/CubeOutlined';
import PieChart from 'src/components/icons/PieChart';
import BuildingOutlined from 'src/components/icons/BuildingOutlined';
import BarChart from 'src/components/icons/BarChart';
import ProjectExploreCascade from 'src/components/layout/sidebar/ProjectExploreCascade';
import ProjectSourceCascade from 'src/components/layout/sidebar/ProjectSourceCascade';

export const SidebarMenuContext = createContext();

const demoSteps = [
  { label: 'Prioritize Supplier List', key: '1', done: true },
  { label: 'Edit & Send Digital RFQ', key: '2', done: true },
  { label: 'Manage Quotes', key: '3', done: false }
];

function Menu({ selected, items, className, disable, sidebarExpanded }) {
  const [expanded, setExpanded] = useState([]);
  const expandCollapse = (index) => {
    const ex = expanded[index];
    const expandedNew = [...expanded];
    expandedNew[index] = !ex;
    setExpanded(expandedNew);
  };

  const menuClick = (item, index) => {
    if (!item.disabled) {
      if (item.subitems) {
        expandCollapse(index);
      } else {
        item.onClick(item.key, item);
      }
    }
  };
  return (
    <div className={`sidebar-menu ${className || ''}`}>
      {items.map((item, index) =>
        item.type === 'divider' ? (
          <Divider key={`divider-${index}`} />
        ) : (
          <React.Fragment key={item.key}>
            <Tooltip
              // Without this style passing the mouse over the tooltip will trigger
              // mouseleave and collapse the sidebar
              overlayStyle={{ pointerEvents: 'none' }}
              placement="right"
              title={!sidebarExpanded && item.name}
            >
              <div
                className={`sidebar-menu-item ${
                  item.key === selected || item.active ? 'selected' : ''
                } ${item.disabled ? 'disabled' : ''} sidebar-menu-item-${
                  item.key
                }`}
                onClick={() => !disable && menuClick(item, index)}
                role="presentation"
              >
                {item.icon}
                <TruncatableLabel text={item.name} className="name" />
                {item.disabled && <LockFilled />}
                {item.tag && <div className="tag">{item.tag}</div>}
                {item.subitems && (
                  <Button type="bare" onClick={() => expandCollapse(index)}>
                    {expanded[index] ? <LeftOutlined /> : <RightOutlined />}
                  </Button>
                )}
              </div>
            </Tooltip>
            {item.subitems && expanded[index] && (
              <div className="sidebar-submenu">
                {item.subitems.map((subitem, indexSub) => (
                  <div
                    key={subitem.name}
                    className="sidebar-menu-subitem"
                    role="presentation"
                    onClick={() =>
                      disable ||
                      item.disabled ||
                      item.onClick(item.key, subitem)
                    }
                  >
                    {subitem.icon}
                    <TruncatableLabel text={subitem.name} className="subitem" />
                    {subitem.disabled && <LockFilled />}
                    {subitem.tag && <div className="tag">{subitem.tag}</div>}
                  </div>
                ))}
                {item.isLoading && <Spin />}
              </div>
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  disable: PropTypes.bool,
  sidebarExpanded: PropTypes.bool,
  className: PropTypes.string
};

const DEFAULT_PROJECTS = [];

function Sidebar({ disable }) {
  const { data: user } = useUser();
  const concierge = useIsConcierge();
  const [expanded, setExpanded] = useState(false);
  const supplier = useIsSupplier() || concierge;
  const { signOut } = useAuthentication();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [hide] = useConciergeContextState(['explore', 'hide']);

  const { cascades, keyList, isLoadingProjects, pushCascade, clearCascades } =
    useSidebarContext();
  const [collapseCascades, setCollapseCascades] = useState(false);
  useEffect(() => setCollapseCascades(false), [cascades]);

  // Hide on mouseleave
  const sidebarRef = useRef();
  useEffect(() => {
    if (sidebarRef?.current) {
      sidebarRef.current.addEventListener('mouseleave', clearCascades);
      // FIXME this is causing crashes - and removing it is not ideal
      // return () =>
      //   sidebarRef.current.removeEventListener('mouseleave', clearCascades);
    }

    // return () => true;
  }, [sidebarRef]);

  const menuClick = (key, subitem) => {
    if (subitem?.onClick) subitem.onClick();
    else navigate(`/project/${key}${subitem?.key ? `/${subitem.key}` : ''}`);
  };

  const signOutClick = () => {
    signOut();
    navigate('/');
  };

  const {
    openModal: openSesameInviteModal,
    ModalComponent: SesameInviteModal
  } = useSesameInvite();

  const adminMenuItems = [
    {
      key: 'sesameInvite',
      name: 'Magic Link Generator',
      icon: <LinkOutlined />,
      onClick: openSesameInviteModal
    },
    {
      key: 'proposalBuilder',
      name: 'Proposal Builder',
      icon: <FilterOutlined />,
      onClick: () => {
        navigate('/concierge/proposal-builder');
      }
    },
    {
      key: 'marketsPlayground',
      name: 'Markets Playground',
      icon: <BarChartOutlined />,
      onClick: () => {
        navigate('/concierge/markets-playground');
      }
    },
    {
      key: 'changeLog',
      name: 'Change Log',
      icon: <OrderedListOutlined />,
      onClick: () => {
        window
          .open(
            'https://devops.circular.co/changelog/circular-exchange/circular-react/main',
            '_blank'
          )
          .focus();
      }
    },
    {
      key: 'version',
      name: `Version ${process.env.REACT_APP_GITHUB_SHA?.slice(0, 8) || '-'}`,
      onClick: () => {}
    }
  ];

  const menuItems = useMemo(
    () => [
      ...((!supplier || concierge) && !hide
        ? [
            {
              key: 'dashboard',
              name: 'Dashboard',
              icon: <DashboardIcon />,
              active: pathname.includes('/project/dashboard/'),
              onClick: () => navigate('/project/dashboard')
            },
            {
              type: 'divider'
            },
            {
              key: 'explore',
              name: 'Explore',
              icon: <CompassOutlined />,
              onClick: () => pushCascade(<ProjectExploreCascade />, 'explore'),
              active: pathname.includes('/project/explore/'),
              isLoading: isLoadingProjects
            },
            {
              key: 'source',
              name: 'Source',
              icon: <SendOutlined />,
              onClick: () => pushCascade(<ProjectSourceCascade />, 'source'),
              active: pathname.includes('/project/source/'),
              isLoading: isLoadingProjects
            },
            {
              key: 'procure',
              name: 'Procure',
              icon: <CubeOutlined />,
              active: pathname.includes('/project/procure/'),
              onClick: () => navigate('/project/procure')
            },
            {
              key: 'manage',
              name: 'Manage',
              icon: <PieChart />,
              active: pathname.includes('/project/manage/'),
              onClick: () => {
                navigate('/project/manage');
              }
            }
            // {
            //   key: 'portfolio',
            //   name: 'Portfolio',
            //   icon: <CubeOutlined />,
            //   active: pathname.includes('/project/portfolio/'),
            //   onClick: () => navigate('/project/portfolio')
            // },
            // {
            //   key: 'market-insights',
            //   name: 'Market Insights',
            //   icon: <PieChart />,
            //   active: pathname.includes('/project/market-insights/'),
            //   onClick: () => {
            //     navigate('/project/market-insights');
            //   }
            // }
            // {
            //   key: 'manage',
            //   name: 'Manage',
            //   icon: <ManageIcon />,
            //   projects: rfqProjectMenuItems,
            //   onClick: () => navigate('/project/manage'),
            //   isLoading: isLoadingRfqProjects
            // }
          ]
        : []),
      ...(supplier || concierge
        ? [
            { type: 'divider' },
            {
              key: 'profile',
              name: 'Profile',
              icon: <BuildingOutlined />,
              active: pathname.includes(
                `/project/supplier/${user?.company?.uuid}`
              ),
              onClick: () => {
                navigate(`/project/supplier/${user?.company?.uuid}`);
              }
            },
            {
              key: 'supplier-insights',
              name: 'Supplier Insights',
              icon: <BarChart />,
              active: pathname.includes(`/project/supplier-insights/`),
              onClick: () => {
                navigate(`/project/supplier-insights`);
              }
            }
          ]
        : []),
      { type: 'divider' },
      {
        key: 'signOut',
        name: 'Sign out',
        icon: <Lock />,
        onClick: signOutClick
      },
      ...(concierge
        ? [
            {
              key: 'admin',
              name: 'Admin',
              icon: (
                <>
                  <ThunderboltIcon />
                  {SesameInviteModal}
                </>
              ),
              subitems: adminMenuItems,
              onClick: menuClick
            }
          ]
        : [])
    ],
    [adminMenuItems, collapseCascades]
  );

  const selectedMenu = keyList[0];

  return (
    <div className="project-sidebar" ref={sidebarRef}>
      <div className={`project-sidebar-main ${expanded ? 'expanded' : ''}`}>
        <div className="sidebar-header">
          <CircularLogoSymbol />
          <CircularLogo />
        </div>
        <div className="sidebar-body">
          <Menu
            disable={disable}
            selected={selectedMenu}
            items={menuItems}
            sidebarExpanded={expanded}
          />
        </div>
        <Divider />
        <div className="sidebar-footer">
          <div className="cs-card">
            <div className="cs-rep">
              <Avatar alt="User avatar" src={user?.avatar}>
                {user?.name.slice(0, 1)}
              </Avatar>
              <div className="cs-name">
                {user?.name}
                <span>{user?.email}</span>
              </div>
            </div>
          </div>
          <Button
            style={{
              padding: 0,
              marginTop: 8,
              width: 24,
              float: expanded ? 'right' : 'none'
            }}
            type="bare"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ArrowStart /> : <ArrowEnd />}
          </Button>
        </div>
      </div>
      {!collapseCascades && cascades.length
        ? cascades[cascades.length - 1].component
        : null}
    </div>
  );
}

Sidebar.propTypes = {
  disable: PropTypes.bool
};

export default Sidebar;
