import React, { useMemo } from 'react';
import { Button } from 'antd';
import { ClockCircleOutlined, PhoneFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormPageLayout from 'src/components/layout/FormPageLayout';
import { StepList } from 'src/components/steps/StepList';
import { useIsHead } from 'src/hooks/userCompanyType';

export default function ProcessOverview() {
  const isHead = useIsHead();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rfqId = searchParams.get('rfqId');
  const getStartedParams = rfqId ? `?rfqId=${rfqId}` : '';

  const steps = useMemo(
    () => [
      {
        title: 'Tell us what you need',
        duration: isHead ? '1 hr' : '15 min',
        icon: <PhoneFilled />,
        background: 'We draft a detailed Request for Quote (RFQ)',
        backgroundIcon: <ClockCircleOutlined />,
        backgroundDuration: '2-3 days'
      },
      {
        title: isHead ? 'RFQ review call' : 'Review and approve your RFQ',
        duration: isHead ? '1 hr' : '15 min',
        icon: <PhoneFilled />,
        background: 'We find the best supplier solutions to create a proposal',
        backgroundIcon: <ClockCircleOutlined />,
        backgroundDuration: isHead ? '1-2 weeks' : '1-2 weeks'
      },
      {
        title: isHead ? 'Review our proposal' : 'Review our proposal',
        duration: isHead ? '1 hr' : '30 min',
        icon: <PhoneFilled />
      },
      {
        title: 'Test samples & confirm deal terms'
      },
      {
        title: isHead ? 'Contract & delivery' : 'Finalize contract & delivery'
      }
    ],
    [isHead]
  );
  return (
    <FormPageLayout className="buyer-process-overview" back>
      <h1>Let&apos;s get started</h1>
      <p>Here&apos;s what to expect:</p>
      <StepList steps={steps} />
      <Button
        type="primary"
        onClick={() =>
          navigate(`/buyer-deal/tell-us-what-you-need${getStartedParams}`)
        }
      >
        Get Started
      </Button>
    </FormPageLayout>
  );
}
