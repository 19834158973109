import PropTypes from 'prop-types';
import { Button, Form, Popover, Typography } from 'antd';
import React, { useState } from 'react';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

export default function ValueInputPopover({
  children,
  ValueComponent,
  value,
  formTitle,
  loading,
  onChange,
  mutate
}) {
  const [open, setOpen] = useState(false);

  const loadingStateClass = useLoadingStateClass(loading);
  // Popover needs a clickable child, so we use a div here
  // const onLocalChange =
  return (
    <Popover
      trigger="click"
      open={open}
      content={
        open ? (
          <>
            {formTitle && (
              <Typography.Text className="heavy pb-sm inline-block">
                {formTitle}
              </Typography.Text>
            )}
            <ValueInputForm
              ValueComponent={ValueComponent}
              initialValue={value}
              onChange={onChange}
              mutate={mutate}
              close={() => setOpen(false)}
            />
          </>
        ) : (
          <span />
        )
      }
      onOpenChange={(o) => setOpen(o)}
      // Necessary so initialValues reload after an update
      destroyTooltipOnHide
    >
      <div
        className={`value-input-popover--container ${loadingStateClass}`}
        role="presentation"
      >
        {children}
      </div>
    </Popover>
  );
}
ValueInputPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  value: PropTypes.any,
  loading: PropTypes.bool,
  formTitle: PropTypes.string,
  mutate: PropTypes.func,
  onChange: PropTypes.func,
  ValueComponent: PropTypes.elementType
};

const inputItemKey = 'input';
function ValueInputForm({
  initialValue,
  onChange,
  ValueComponent,
  mutate,
  close
}) {
  const onFinish = (e) => {
    const value = e[inputItemKey];
    if (onChange) onChange(value);
    if (mutate) mutate(value);
    close();
  };
  const initialValues = {};
  initialValues[inputItemKey] = initialValue;
  // Popover needs a clickable child, so we use a div here
  return (
    <Form
      className="value-input-form"
      initialValues={{ input: initialValue }}
      onFinish={onFinish}
    >
      <Form.Item className="value-input-form-item" name="input">
        <ValueComponent />
      </Form.Item>
      <Button type="primary" size="small" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}
ValueInputForm.propTypes = {
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  mutate: PropTypes.func,
  close: PropTypes.func,
  ValueComponent: PropTypes.elementType
};
