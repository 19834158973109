import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { captureException } from '@sentry/react';

import { createOrUpdateProject } from 'src/Mutation';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

function ProjectSettings({ open, close, uuid, callback, projectData }) {
  const filters = useRelevantFiltersForForm();
  const [settings] = useConciergeContextState(['explore', 'projectSettings']);
  const [form] = Form.useForm();
  const { getProjectbyId, refetchProjects } = useSidebarContext();

  const project = useMemo(() => getProjectbyId(uuid), [uuid, getProjectbyId]);

  useEffect(() => {
    form.setFieldsValue(project);
  }, [project]);

  const { mutate: createOrUpdateProjectMutation, isLoading: updatingProject } =
    useMutation({
      mutationFn: createOrUpdateProject,
      onSuccess: (response) => {
        refetchProjects();

        if (callback) callback(response);

        close();
      },
      onError: (e) => {
        // Send error to Sentry
        captureException(e);
      }
    });

  const isLoading = (!project && uuid) || updatingProject;

  return (
    <Modal
      width={500}
      title={uuid ? 'Edit Project' : 'New Project'}
      open={open}
      onCancel={() => close()}
      okText={isLoading ? 'Saving project...' : 'Save Project'}
      okButtonProps={{ loading: isLoading }}
      onOk={form.submit}
      // eslint-disable-next-line react/no-unstable-nested-components
      footer={(_, { OkBtn }) => (
        <Space direction="vertical" size={12}>
          <OkBtn />
        </Space>
      )}
    >
      <Form
        validateMessages={{
          required: 'This field is required.'
        }}
        layout="vertical"
        form={form}
        disabled={isLoading}
        onFinish={async (values) => {
          await form.validateFields();

          createOrUpdateProjectMutation({
            uuid,
            ...values,
            filters,
            settings,
            ...(projectData || {})
          });
        }}
      >
        <Form.Item label="Name this project" name="name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ProjectSettings;

ProjectSettings.propTypes = {
  open: PropTypes.bool,
  uuid: PropTypes.string,
  close: PropTypes.func,
  callback: PropTypes.func,
  projectData: PropTypes.object
};
