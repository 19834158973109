import React from 'react';
import PropTypes from 'prop-types';

function LightPanel({ children, className, style }) {
  return (
    <div className={`light-panel ${className || ''}`} style={style}>
      <div className="light-panel--contents">{children}</div>
    </div>
  );
}

LightPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

function LightPanelBox({ children }) {
  return <div className="light-panel-box">{children}</div>;
}

LightPanelBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

LightPanel.Box = LightPanelBox;

export default LightPanel;
