import React from 'react';
import { useParams } from 'react-router-dom';
import TabsWithRoutes from 'src/components/tabs/TabsWithRoutes';
import Lock from 'src/components/icons/Lock';
import SupplierAnalytics from './SupplierAnalytics';
import News from './News';
import Market from './market';

function InsightsPage() {
  const { tab } = useParams();

  const items = [
    {
      key: 'markets',
      label: 'Market',
      children: <Market />
    },
    {
      key: 'supply',
      label: 'Supply',
      children: <SupplierAnalytics />
    },
    {
      key: 'futures',
      label: (
        <>
          <Lock />
          Futures
        </>
      ),
      disabled: true,
      children: undefined
    },
    {
      key: 'demand',
      label: (
        <>
          <Lock />
          Demand
        </>
      ),
      disabled: true,
      children: undefined
    },
    {
      key: 'freight',
      label: (
        <>
          <Lock />
          Freight
        </>
      ),
      disabled: true,
      children: undefined
    },
    {
      key: 'news',
      label: 'News',
      children: <News />
    }
  ];
  return (
    <div className="insights-page">
      <h1>Insights</h1>
      <TabsWithRoutes activeKey={tab} items={items} />
    </div>
  );
}

export default InsightsPage;
