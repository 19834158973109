import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Space, Tooltip } from 'antd';

import { currencyValuePrice } from 'src/utils/currency';
import {
  Sparklines,
  SparklinesCurve,
  SparklinesReferenceLine
} from 'react-sparklines';
import useMarkerPriceSeriesReference from './useMarkerPriceSeriesReference';

/** referenceTicker is provided if we want a spreadh ticker - referenceTicker
 * e.g. green premium
 * */

export default function AnalyticsTickerPrice({ ticker, referenceTicker }) {
  const {
    graphTrend,
    isLoading,
    curve,
    mid,
    sourceDetails,
    currency,
    units,
    title
  } = useMarkerPriceSeriesReference(ticker, referenceTicker);
  if (isLoading || mid === 0) return undefined;
  return (
    <div className="analytics-price">
      <span className="price-title">
        <Tooltip
          title={sourceDetails?.split('\n').map((line) => (
            <p key={line}>{line}</p>
          ))}
        >
          {title}
        </Tooltip>
      </span>
      {(isLoading && <Skeleton paragraph={false} active />) || (
        <div className="price-details">
          {currencyValuePrice(mid, currency, units)}
          <Space>
            <div className={`sparkline-wrap ${graphTrend}`}>
              <Sparklines data={curve} svgHeight={17}>
                <SparklinesCurve
                  style={{
                    strokeWidth: 6,
                    fill: 'none'
                  }}
                />
                <SparklinesReferenceLine
                  type="mean"
                  style={{
                    stroke: '#99999',
                    strokeOpacity: 1,
                    strokeDasharray: '4'
                  }}
                />
              </Sparklines>
            </div>
          </Space>
        </div>
      )}
    </div>
  );
}

AnalyticsTickerPrice.propTypes = {
  ticker: PropTypes.object,
  referenceTicker: PropTypes.object
};
