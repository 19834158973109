import 'src/pages/concierge/Concierge.less';

const propertyColumn = (p, width) => ({
  title: p.name,
  width: width || 100,
  dataIndex: 'material_properties',
  key: p.uuid,
  render: (allTdsProperties) => {
    const relevantProperties =
      allTdsProperties?.filter(
        ({ meta_property }) => meta_property.uuid === p.uuid
      ) || [];
    return relevantProperties.length
      ? relevantProperties.map((rp) => rp.value).join(', ')
      : '-';
  }
});

export default propertyColumn;
