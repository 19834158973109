import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select
} from 'antd';
import {
  CloseOutlined,
  FieldTimeOutlined,
  SendOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { getProjectTdsActivity } from 'src/Query';
import LoadingErrorOrContent from 'src/components/utils/LoadingErrorOrContent';
import UploadDocumentsCompact from 'src/components/form/UploadDocumentsCompact';
import { createProjectTdsActivity } from 'src/Mutation';
import FileList from 'src/components/form/FileList';

export function useQuoteDetails({ onClose, tds, open }) {
  const {
    data: activity,
    isLoading,
    error
  } = useQuery(
    ['project-tds-activity', tds?.project_tds_id],
    () =>
      getProjectTdsActivity({ project_tds: tds?.project_tds_id, all: true }),
    { enabled: !!tds }
  );

  useEffect(() => {
    if (open) document.body.classList.add('hide-intercom');
    else document.body.classList.remove('hide-intercom');
  }, [open]);

  const [filter, setFilter] = useState('all');

  const filteredActivity = useMemo(() => {
    if (!activity?.length) return [];

    if (filter === 'attachments')
      return activity.filter(
        (activityItem) => !!activityItem.attachments?.length
      );

    return activity;
  }, [filter, activity]);

  return {
    filter,
    setFilter,
    QuoteDetails: (
      <Drawer
        open={open}
        className="project-tds-activity"
        title={
          <Row align="middle" wrap={false} gutter={[8, 0]}>
            <Col style={{ lineHeight: 1 }}>
              <FieldTimeOutlined style={{ fontSize: 18, color: '#AAB0BB' }} />
            </Col>
            <Col style={{ marginRight: 4 }}>Project Activity</Col>
            <Col>
              <Select
                value={filter}
                onChange={setFilter}
                options={[
                  {
                    label: 'Show All Types',
                    value: 'all'
                  },
                  {
                    label: 'Show Attachments',
                    value: 'attachments'
                  }
                ]}
                size="small"
              />
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <button className="bare" type="button" onClick={onClose}>
                <CloseOutlined style={{ fontSize: 18 }} />
              </button>
            </Col>
          </Row>
        }
        onClose={onClose}
        closeIcon={false}
        footer={<ActivityItemForm project_tds_id={tds?.project_tds_id} />}
        width={450}
      >
        <LoadingErrorOrContent isLoading={isLoading} error={error}>
          {filteredActivity?.length ? (
            filteredActivity.map((activityItem, i) => (
              <ActivityItem
                key={activityItem.uuid}
                item={activityItem}
                scrollIntoView={i === filteredActivity.length - 1}
              />
            ))
          ) : (
            <Empty />
          )}
        </LoadingErrorOrContent>
        <div id="anchor" />
      </Drawer>
    )
  };
}

function ActivityItem({ item, scrollIntoView }) {
  const ref = useRef();
  useEffect(() => {
    if (scrollIntoView && ref.current) ref.current.scrollIntoView();
  }, [scrollIntoView, ref?.current]);
  return (
    <div className="single-item mb-sm" ref={ref}>
      <Row align="middle" className="mb-xs">
        <Col>
          <Avatar size="small" alt="User avatar" src={item.created_by.avatar}>
            {item.created_by.name.slice(0, 1)}
          </Avatar>{' '}
        </Col>
        <Col className="font-size-2 ml-xs">{item.created_by.name}</Col>
        <Col
          className="font-size-2 single-item--date"
          style={{ marginLeft: 'auto' }}
        >
          {dayjs(item.updated_at).fromNow()}
        </Col>
      </Row>
      <Row>{item.text}</Row>
      {item.attachments?.length ? <FileList value={item.attachments} /> : null}
    </div>
  );
}
ActivityItem.propTypes = {
  item: PropTypes.object.isRequired,
  scrollIntoView: PropTypes.bool
};

function ActivityItemForm({ project_tds_id }) {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: createProjectTdsActivity,
    onSuccess: (response) => {
      queryClient.setQueryData(
        ['project-tds-activity', project_tds_id],
        (oldActivity) => {
          if (Array.isArray(oldActivity)) return [...oldActivity, response];

          return [response];
        }
      );
      form.resetFields();
    }
  });

  if (!project_tds_id) return null;

  return (
    <Form
      form={form}
      layout="inline"
      disabled={isLoading}
      onFinish={(values) =>
        mutate({
          project_tds: project_tds_id,
          text: values.text,
          attachments_ids: values.attachments?.map(({ uuid }) => uuid)
        })
      }
    >
      <Form.Item name="text" style={{ flexGrow: 1 }}>
        <Input.TextArea autoSize placeholder="Add a note or attachment" />
      </Form.Item>
      <Form.Item name="attachments">
        <UploadDocumentsCompact />
      </Form.Item>
      <Form.Item style={{ marginInlineEnd: 0 }}>
        <Button type="primary" htmlType="submit">
          <SendOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
}
ActivityItemForm.propTypes = {
  project_tds_id: PropTypes.number.isRequired
};
