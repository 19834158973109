import calcCrow from './calcCrow';

/**
 * Given 2 arrays of locations, returns closest pair
 *
 * @param {Object[]} possibleOrigins Array of objects with latitude and longitude props
 * @param {Object[]} possibleDestinations  Array of objects with latitude and longitude props
 * @returns {Array} Array containing optimal route origin, destination, and distance
 */
export default function optimalShippingRoute(
  possibleOrigins = [],
  possibleDestinations = []
) {
  let shortestDistance = null;
  let shortestDistanceOrigin = null;
  let shortestDistanceDestination = null;

  possibleOrigins.forEach((origin) => {
    if (!origin.latitude || !origin.longitude) return;

    possibleDestinations.forEach((destination) => {
      if (!destination.latitude || !destination.longitude) return;

      const distance = calcCrow(
        origin.latitude,
        origin.longitude,
        destination.latitude,
        destination.longitude
      );

      if (distance < shortestDistance)
        [
          shortestDistance,
          shortestDistanceOrigin,
          shortestDistanceDestination
        ] = [distance, origin, destination];
    });
  });

  return [
    shortestDistanceOrigin,
    shortestDistanceDestination,
    shortestDistance
  ];
}
