import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { Input } from 'antd';

// Currently only works as a managed component (onChange and value must be used)
function NumberInput({ value, onChange, suffix, ...props }) {
  // see here for why we add the <span/> https://ant.design/components/input/#FAQ
  const suffixElement =
    typeof suffix === 'string' ? (
      <span>{suffix}</span>
    ) : (
      suffix || <span></span>
    );
  return (
    <Input
      value={value}
      onChange={onChange}
      suffix={suffixElement}
      {...props}
    />
  );
}

/* eslint-disable prefer-promise-reject-errors */

export const rules = (required) => [
  { required, message: 'Please enter an amount' },
  () => ({
    validator(_, value) {
      if (value && !numeral(value).value()) {
        return Promise.reject('Please enter a valid number');
      }
      return Promise.resolve();
    }
  })
];

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default NumberInput;
