import React from 'react';
import { ReactComponent as Icon } from './svg/send-outlined.svg';
import SvgIconComponent from './SvgIconComponent';

function SendOutlined() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default SendOutlined;
