import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export default function Sorter({ sortOrder }) {
  return (
    <div className="search-results--table--sorter proposal-builder--table--sorter sorter">
      <UpOutlined className={sortOrder === 'ascend' ? 'active' : ''} />
      <DownOutlined className={sortOrder === 'descend' ? 'active' : ''} />
    </div>
  );
}

Sorter.propTypes = {
  sortOrder: PropTypes.string
};
