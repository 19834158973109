import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPriceLog } from 'src/Mutation';
import { useAvailableUnitsForType, usePreferredUnits } from 'src/utils/units';
import { SelectableCurrencyPerWeightUnitsInput } from 'src/components/form/SelectableCurrencyUnitsInput';

export default function LogPricingForm({ tdsLocationId, currency }) {
  const [form] = Form.useForm();

  const weightUnits = useAvailableUnitsForType('weight');
  const defaultUnits = usePreferredUnits('weight');
  const selectedUnits = Form.useWatch('units', form);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: createPriceLog,
    onSuccess: () => {
      queryClient.invalidateQueries(['price-log']);
      queryClient.invalidateQueries(['warehouse', 'technical-data-sheet']);
      form.resetFields();
    }
  });

  if (!tdsLocationId) return null;

  return (
    <Form
      onFinish={async (values) => {
        await form.validateFields();

        mutate({
          price: values.price?.value,
          currency: values.price?.currency,
          units: values.price?.units,
          quantity: values.quantity,
          date: values.date.format('YYYY-MM-DD'),
          tds_location: tdsLocationId
        });
      }}
      form={form}
      layout="vertical"
      disabled={isLoading}
    >
      <Row gutter={[16, 0]}>
        <Col xs={8}>
          <Form.Item label="Date" name="date" rules={[{ required: true }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            label="Unit Price"
            name="price"
            rules={[{ required: true }]}
          >
            <SelectableCurrencyPerWeightUnitsInput
              value={{ units: defaultUnits, currency: currency || 'USD' }}
            />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item dependencies={['units']} label="Volume" name="quantity">
            <Input addonAfter={selectedUnits} />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit">
        <PlusOutlined /> {isLoading ? 'Logging...' : 'Log Transaction'}
      </Button>
    </Form>
  );
}

LogPricingForm.propTypes = {
  tdsLocationId: PropTypes.string,
  currency: PropTypes.string
};
