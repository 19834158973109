import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, Grid, Menu, Row, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useUser } from 'src/utils/authentication';
import Logo from 'src/components/Logo';

const createMenuMap = (
  menuItemsLeft,
  menuItemsSignedOut,
  menuItemsSignedIn
) => {
  const menuMap = {};
  const fillMenuMap = (map, items) =>
    items.forEach((o) => {
      menuMap[o.key] = o;
      if (o.children) fillMenuMap(map, o.children);
    });
  fillMenuMap(menuMap, menuItemsLeft);
  fillMenuMap(menuMap, menuItemsSignedOut);
  fillMenuMap(menuMap, menuItemsSignedIn);
  return menuMap;
};

export default function SimpleHeader({
  menuItemsLeft = [],
  menuItemsSignedOut = [],
  menuItemsSignedIn = [],
  logoLink = '/',
  fixedHeaderScrollY = 80,
  className = ''
}) {
  const [atTop, setAtTop] = useState(true);
  const { hash } = useLocation();
  const { data: user, isLoading, isFetching } = useUser();
  const navigate = useNavigate();
  const breakpoint = Grid.useBreakpoint();

  const scrollDown = () => {
    if (window.scrollY >= fixedHeaderScrollY) {
      setAtTop(false);
    } else {
      setAtTop(true);
    }
  };
  useEffect(() => {
    scrollDown();
    window.addEventListener('scroll', scrollDown);
    return () => window.removeEventListener('scroll', scrollDown);
  }, []);

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [hash]);

  const collapseMenu = !breakpoint.lg;

  const menuMap = useMemo(
    () => createMenuMap(menuItemsLeft, menuItemsSignedOut, menuItemsSignedIn),
    [menuItemsLeft, menuItemsSignedOut, menuItemsSignedIn]
  );

  const menuClick = ({ key }) => {
    if (!key) return;
    const item = menuMap[key];

    if (typeof item.before === 'function') {
      item.before();
    }

    if (typeof item.path === 'string') {
      if (item.path.match(/^https?:\/\//i)) {
        window.open(item.path, '_blank');
      } else {
        navigate(item.path);
      }
    }
  };

  const menuItemsRight = user ? menuItemsSignedIn : menuItemsSignedOut;

  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */

  return (
    <div
      className={`simple-header-container ${atTop ? 'at-top' : 'scrolling'}`}
    >
      <div className="gradient-border" />
      <Row
        span={24}
        className={`simple-header ${className}`}
        justify="space-between"
        align="middle"
      >
        <Col className="row-item logo-container">
          <Logo light={atTop} link={logoLink} />
        </Col>
        {!collapseMenu ? (
          <>
            <Col className="row-item center-items-container">
              <Menu
                theme="light"
                mode="horizontal"
                items={cleanMenuItemsForAntd([...menuItemsLeft])}
                onClick={menuClick}
              />
            </Col>
            <Col className="row-item right-items-container">
              {/* eslint-disable-next-line no-nested-ternary */}
              {isLoading && isFetching ? undefined : (
                <Menu
                  theme="light"
                  mode="horizontal"
                  items={cleanMenuItemsForAntd([...menuItemsRight])}
                  onClick={menuClick}
                />
              )}
            </Col>
          </>
        ) : (
          <Col className="hamburger-container">
            <Dropdown
              getPopupContainer={(trigger) => trigger.parentNode}
              menu={{
                items: cleanMenuItemsForAntd([
                  ...menuItemsLeft,
                  ...menuItemsRight
                ]),
                onClick: menuClick
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <MenuOutlined className="hamburger" />
                </Space>
              </a>
            </Dropdown>
          </Col>
        )}
      </Row>
    </div>
  );
}

SimpleHeader.propTypes = {
  menuItemsLeft: PropTypes.array,
  menuItemsSignedIn: PropTypes.array,
  menuItemsSignedOut: PropTypes.array,
  logoLink: PropTypes.string,
  fixedHeaderScrollY: PropTypes.number,
  className: PropTypes.string
};

function cleanMenuItemsForAntd(items) {
  return items.map((item) => {
    const itemClone = { ...item };
    delete itemClone.before;
    return itemClone;
  });
}
