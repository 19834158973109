import React from 'react';
import { Button } from 'antd';
import { ClockCircleOutlined, PhoneFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import FormPageLayout from 'src/components/layout/FormPageLayout';
import { StepList } from 'src/components/steps/StepList';

const steps = [
  {
    title: 'Tell us what you’re selling',
    duration: '30 min',
    icon: <PhoneFilled />,
    backgroundIcon: <ClockCircleOutlined />,
    backgroundDuration: '24 hr'
  },
  {
    title: 'Respond to matching buyer RFQs within 24 hours'
  },
  {
    title: 'Review quote',
    duration: '15 min',
    icon: <PhoneFilled />,
    background: 'We use your approved quote in a proposal for buyer',
    backgroundIcon: <ClockCircleOutlined />,
    backgroundDuration: '1 week'
  },
  {
    title: 'Connect with buyer in deal room to finalize deal'
  }
];

export default function SellerProcessOverview() {
  const navigate = useNavigate();
  return (
    <FormPageLayout
      className="buyer-process-overview"
      back
      type={FormPageLayout.Types.SELLER}
    >
      <h1>Let&apos;s get started</h1>
      <p>Here&apos;s what to expect:</p>
      <StepList steps={steps} />
      <Button type="primary" onClick={() => navigate('/seller-deal')}>
        Get Started
      </Button>
    </FormPageLayout>
  );
}
