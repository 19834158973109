import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, List, Row, Select, Typography } from 'antd';
import { useAvailableUnitsForType } from 'src/utils/units';
import { KpiTestConditionsSelect } from 'src/components/project/explore/filters/KpiFilter';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';

// managed component (onChange and value must be used)
export default function KpiRangeInput({ value, onChange }) {
  const property = useMetaNumericalProperty(value?.kpiCode);
  const localUnitType = value?.unit_type || property?.unit_type;
  const unitList = useAvailableUnitsForType(localUnitType);
  // see here for why we add the <span/> https://ant.design/components/input/#FAQ
  const onChangeMinValue = (v) =>
    onChange({ ...(value || {}), min: v.target.value });
  const onChangeMaxValue = (v) =>
    onChange({ ...(value || {}), max: v.target.value });
  const onChangeUnits = (v) => onChange({ ...(value || {}), units: v });
  const onChangeTestConditions = (v) =>
    onChange({ ...(value || {}), test_conditions: v });

  const onToggleDelete = () =>
    onChange({ ...(value || {}), DELETE: !value?.DELETE });

  return (
    <div
      style={
        value.DELETE
          ? {
              opacity: 0.5
            }
          : {}
      }
    >
      <Row
        className="mb-sm"
        justify="space-between"
        align="middle"
        wrap={false}
      >
        <Col span={12}>
          <Typography.Text style={{ fontSize: 10 }} strong>
            Test Conditions
          </Typography.Text>
          <KpiTestConditionsSelect
            value={value?.test_conditions}
            onChange={onChangeTestConditions}
            kpiCode={value?.kpiCode}
          />
        </Col>
        <Col>
          <Button
            type={value.DELETE ? 'primary' : 'default'}
            size="small"
            shape="circle"
            htmlType="button"
            onClick={onToggleDelete}
            danger
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={8}>
          <Typography.Text style={{ fontSize: 10 }} strong>
            Min
          </Typography.Text>
          <Input size="small" value={value?.min} onChange={onChangeMinValue} />
        </Col>
        <Col span={8}>
          <Typography.Text style={{ fontSize: 10 }} strong>
            Max
          </Typography.Text>
          <Input size="small" value={value?.max} onChange={onChangeMaxValue} />
        </Col>
        <Col span={8}>
          <Typography.Text style={{ fontSize: 10 }} strong>
            Units
          </Typography.Text>
          <Select
            size="small"
            onChange={onChangeUnits}
            value={value?.units}
            options={unitList?.map((unit) => ({
              label: unit,
              value: unit
            }))}
          />
        </Col>
      </Row>
    </div>
  );
}
KpiRangeInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};

export const KpiRangeInputContext = createContext();

// managed component (onChange and value must be used)
export function MultipleKpiRangeInput({ value: values, onChange }) {
  const property = useContext(KpiRangeInputContext);
  const onChangeGenerator = (link_id, index) => (newValues) => {
    const valuesClone = [...values];
    valuesClone[index] = newValues;

    if (newValues.DELETE && !link_id) valuesClone.splice(index, 1);

    onChange(valuesClone);
  };

  return (
    <div className="multiple-kpi-range-input">
      <List>
        {values
          // .filter((value) => value.link_id )
          .map((value, i) => (
            <List.Item className="single-kpi-range-input" key={value.link_id}>
              <KpiRangeInput
                value={value}
                onChange={onChangeGenerator(value.link_id, i)}
              />
            </List.Item>
          ))}
        <List.Item className="single-kpi-range-input">
          <Button
            onClick={() => {
              onChange([
                ...values,
                {
                  property,
                  property_id: property?.uuid,
                  kpiCode: property?.code
                } // Add a new blank entry into values
              ]);
            }}
            htmlType="button"
          >
            <PlusCircleFilled /> Add KPI value
          </Button>
        </List.Item>
      </List>
    </div>
  );
}
MultipleKpiRangeInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};
