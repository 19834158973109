import React from 'react';
import PropTypes from 'prop-types';
import DocumentIcon from 'src/components/icons/Document';
import { Popover } from 'antd';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import useAllProperties from 'src/components/project/hooks/useAllProperties';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';
import usePropertyFilters from 'src/components/project/hooks/usePropertyFilters';

const BIG_NUM = 1e12;

export default function Tag({ text, highlight, hidden, type, ...props }) {
  const style = { display: hidden ? 'none' : undefined };
  return (
    <div
      {...props} // Needed for popovers to work
      style={style}
      className={`explore-suppliers-table-tag ${highlight} ${type || 'normal'}`}
    >
      {text}
    </div>
  );
}
Tag.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool
};

export function Count({ count }) {
  return (
    <div className="explore-suppliers-table--count" style={{ width: '32px' }}>
      <DocumentIcon />
      {count}
    </div>
  );
}
Count.propTypes = {
  count: PropTypes.number
};

export function ResinTags({ resultDetails }) {
  return (
    <>
      <MatchingPrimariesTag match={resultDetails?.primary} />
      <MatchingSecondariesTag match={resultDetails?.secondary} />
    </>
  );
}
ResinTags.propTypes = {
  resultDetails: PropTypes.object
};

export function MatchingPrimariesTag({ match }) {
  const highlightLevel = ['high', 'low', 'none'][
    ['full', 'partial', 'none'].findIndex(
      (matchType) => matchType === (match || 'none')
    )
  ];

  return (
    <Popover
      content={
        ['full', 'partial'].includes(match) &&
        `Meets ${match === 'full' ? 'all' : '1'} primary spec${
          match === 'full' ? 's' : ''
        }`
      }
      trigger="hover"
    >
      <Tag text="Primary" highlight={highlightLevel} />
    </Popover>
  );
}
MatchingPrimariesTag.propTypes = {
  match: PropTypes.string
};

export function MatchingSecondariesTag({ match }) {
  const secondaryKpis = useSecondaryKpis();
  const properties = useAllProperties();
  const highlightLevel =
    secondaryKpis.length || properties.length
      ? ['high', 'low', 'none'][
          ['full', 'partial', 'none'].findIndex(
            (matchType) => matchType === (match || 'none')
          )
        ]
      : 'none';

  return (
    <Popover
      content={
        secondaryKpis.length
          ? ['full', 'partial'].includes(match) &&
            `Meets ${match === 'full' ? '2+' : '1'} secondary spec${
              match === 'full' ? 's' : ''
            }`
          : null
      }
      trigger="hover"
    >
      <Tag text="Secondary" highlight={highlightLevel} />
    </Popover>
  );
}
MatchingSecondariesTag.propTypes = {
  match: PropTypes.string
};

export function MatchingExpandedTag({ match }) {
  return (
    <Popover
      content={match && 'Meets expanded search specifications'}
      trigger="hover"
    >
      <Tag text="Expanded" type="alert" highlight={match ? 'high' : 'none'} />
    </Popover>
  );
}
MatchingExpandedTag.propTypes = {
  match: PropTypes.bool
};

export function BalesTags({ supplier }) {
  const propertyFilters = usePropertyFilters();

  return Object.keys(propertyFilters).map((propertyCode) => (
    <MatchingPropertyTag
      key={propertyCode}
      propertyCode={propertyCode}
      tds={supplier.tds_list[0]}
    />
  ));
}
BalesTags.propTypes = {
  supplier: PropTypes.object
};

export function MatchingPropertyTag({ tds, propertyCode }) {
  const propertyFilter = usePropertyFilters(propertyCode);

  const tdsPropertyValues = tds[`property_${propertyCode}_code`];

  const matchingValues = Array.isArray(tdsPropertyValues)
    ? tdsPropertyValues.filter(
        (tdsValue) =>
          Array.isArray(propertyFilter) && propertyFilter.includes(tdsValue)
      )
    : [];
  const match = !!matchingValues.length;

  return (
    <Popover
      content={match && `Meets ${propertyCode} specifications`}
      trigger="hover"
    >
      <Tag
        text={<MetaPropertyName property={propertyCode} />}
        highlight={match ? 'high' : 'none'}
      />
    </Popover>
  );
}
MatchingPropertyTag.propTypes = {
  tds: PropTypes.object,
  propertyCode: PropTypes.string
};
