export const sourcesListNames = (tickers) =>
  [...new Set(tickers?.map((t) => t.source))].filter((t) => t).join(', ');

const sourceNamesMap = {
  opis: 'OPIS',
  spg: 'SPG',
  argus: 'Argus'
};

export const sourcesListNamesArray = (tickersArray) =>
  [
    ...new Set(
      tickersArray
        .map((t) => t?.map((s) => sourceNamesMap[s.source] || s.source))
        .filter((t) => t)
        .flat()
    )
  ].join(', ');

export const sourcesListDetails = (tickers) =>
  tickers
    .map((t) =>
      t
        ? `${t.source}: ${t.source_name} (${
            t.location?.city || t.location?.country
          })`
        : undefined
    )
    .filter((t) => t)
    .join('\n');

export const sourcesListDetailsArray = (tickersArray) =>
  tickersArray
    .map((p) => sourcesListDetails(p || []))
    .filter((t) => t)
    .join('\n---\n');
