import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import Sidebar from 'src/components/layout/sidebar/Sidebar';
import ProjectDashboard from 'src/pages/project/ProjectDashboard';
import ProjectDetails from 'src/pages/project/details';
import InsightsPage from 'src/pages/insights';
import { DealTestPage } from 'src/pages/testing/TestLab';
import TestResults from 'src/pages/project/details/TestResults';

import 'src/pages/project/Project.less';
import ProjectSearch from 'src/pages/project/ProjectSearch';
import ProjectProcure from 'src/pages/project/details/ProjectProcure';
import ProjectManage from 'src/pages/project/details/ProjectManage';
import ProjectQuotes from 'src/pages/project/ProjectQuotes';
import { useUser } from 'src/utils/authentication';
import { isConcierge, isSupplier } from 'src/utils/permissions';
import Portfolio from 'src/components/portfolio';
import ProposalModal from 'src/components/project/search/ProposalModal';
import MarketInsights from 'src/components/marketInsights';
import SupplierInsights from 'src/pages/supplier/SupplierInsights';
import SupplierProfile from 'src/pages/supplier/Profile';
import ExploreRoutes from 'src/components/project/explore/ExploreRoutes';
import SourceRoutes from 'src/components/project/source/SourceRoutes';
import SidebarContextProvider from './sidebar/SidebarContext';

function AppLayoutSidebar() {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const supplier = isSupplier(user) && !isConcierge(user);

  return (
    <div className="project-page">
      <SidebarContextProvider>
        <Sidebar disable={!user} />

        <div className="project-page-contents">
          {user ? undefined : <Skeleton />}
          <Routes>
            <Route path="/dashboard" element={<ProjectDashboard />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/market-insights" element={<MarketInsights />} />
            <Route path="/explore/*" element={<ExploreRoutes />} />
            <Route path="/search" element={<ProjectSearch />} />
            <Route path="/source/*" element={<SourceRoutes />} />
            <Route path="/quotes/:projectId" element={<ProjectQuotes />} />
            <Route path="/proposal" element={<ProposalModal />} />
            <Route path="/procure" element={<ProjectProcure />} />
            <Route path="/procure/:rfqId/:tab?" element={<ProjectProcure />} />
            <Route path="/manage" element={<ProjectManage />} />
            <Route path="/manage/:rfqId/:tab?" element={<ProjectManage />} />
            <Route
              path="/supplier-insights/:supplierId?"
              element={<SupplierInsights />}
            />
            <Route
              path="/supplier/:supplierId?"
              element={<SupplierProfile />}
            />
            <Route path="/:rfqId/testing/:dealId" element={<DealTestPage />} />
            <Route
              path="procure/:rfqId/testing/:dealId/results/:sampleResultId"
              element={<TestResults />}
            />
            <Route path="/insights/:tab?" element={<InsightsPage />} />
            <Route path="/:rfqId/:tab?" element={<ProjectDetails />} />
          </Routes>
        </div>
      </SidebarContextProvider>
    </div>
  );
}

export default AppLayoutSidebar;
