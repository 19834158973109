import { Button, Col, Form, Modal, Row, Skeleton, Table } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { FileFilled } from '@ant-design/icons';

import { getRFQ, getUser } from 'src/Query';
import UploadDocuments from 'src/components/form/UploadDocuments';
import usePatchRfqForm from 'src/components/form/PatchRfqForm';

export default function DocumentsTab() {
  const { rfqId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    data: rfq,
    error,
    isLoading
  } = useQuery({
    queryKey: ['requestForQuote', rfqId],
    queryFn: () => getRFQ(rfqId)
  });

  const { PatchRfqForm, form } = usePatchRfqForm({
    rfqId,
    parseValues: (values) => ({
      documents: [...rfq.documents, ...values.documents].map((document) => ({
        uuid: document.uuid
      }))
    }),
    children: (
      <Form.Item name="documents">
        <UploadDocuments />
      </Form.Item>
    ),
    onFinish: () => setModalOpen(false)
  });

  if (isLoading) return <Skeleton />;

  return (
    <div className="documents-tab">
      <Row className="tab--title" justify="space-between">
        <Col>
          <h2>Documents</h2>
        </Col>
        <Col>
          <Button onClick={() => setModalOpen(true)} type="primary">
            Upload Documents
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={[
              {
                title: 'Document name',
                dataIndex: 'title',
                render: (title) => (
                  <>
                    <FileFilled /> {title}
                  </>
                )
              },
              {
                title: 'Owner',
                dataIndex: 'created_by',
                width: 130,
                render: (userId) => <UserName userId={userId} />
              },
              {
                title: 'Modified',
                dataIndex: 'updated_at',
                width: 160,
                render: (dateString) =>
                  dayjs(dateString).format('MM/DD/YYYY h:mm a')
              }
            ]}
            dataSource={rfq.documents}
          />
        </Col>
      </Row>
      <Modal
        title="Upload Documents"
        open={modalOpen}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => setModalOpen(false)}
      >
        {PatchRfqForm}
      </Modal>
    </div>
  );
}

function UserName({ userId }) {
  const {
    data: user,
    error,
    isLoading
  } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUser(userId)
  });

  if (isLoading) return <Skeleton />;

  if (error) return userId;

  return user.name;
}

UserName.propTypes = {
  userId: PropTypes.number
};
