import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Avatar } from 'antd';
import React from 'react';
import { getCompany } from 'src/Query';

export default function SupplierAvatar({ tds, company }) {
  const companyId = tds?.company_id || company?.company_id;
  const companyName = tds?.company_name || company?.company_name;
  const { data } = useQuery(['company', companyId], () =>
    getCompany(companyId)
  );

  return data?.avatar ? (
    <div>
      <img
        src={data?.avatar}
        alt={companyName}
        className="supplier-compare--company-avatar"
      />
    </div>
  ) : (
    <div>
      <Avatar size={35} icon={<UserOutlined />} />
    </div>
  );
}
SupplierAvatar.propTypes = {
  tds: PropTypes.object,
  company: PropTypes.object
};
