import React from 'react';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import countries from 'i18n-iso-countries';

export const primitiveValue = (value) => {
  if (typeof value === 'boolean') {
    if (value) return <CheckCircleFilled />;
    return <CloseCircleFilled />;
  }
  return value;
};

export function Location({ value }) {
  return (
    <span className="special-location">
      {[
        value.street,
        value.city,
        value.state,
        value.zip || value.postalCode,
        countries.getName(value.country, 'en', { select: 'alias' })
      ]
        .filter((v1) => v1)
        .join(', ')}
    </span>
  );
}

Location.propTypes = {
  value: PropTypes.object
};

Location.canDisplay = (key, value) => value.country && value.city;

export function ValueUnit({ value }) {
  return (
    <span className="special-value-unit">{`${value.value} ${value.unit}`}</span>
  );
}

ValueUnit.propTypes = {
  value: PropTypes.object
};

ValueUnit.canDisplay = (key, value) =>
  Object.keys(value).length === 2 && value.value && value.unit;

export function HunterLab({ value }) {
  return (
    <span className="special-color hunter-lab">
      {[value.l, value.a, value.b].join(', ')}
    </span>
  );
}

HunterLab.propTypes = {
  value: PropTypes.object
};

HunterLab.canDisplay = (key, value) => key === 'hunter_lab';

export function Rgb({ value }) {
  return (
    <span className="special-color rgb">
      {[value.r, value.g, value.b].join(', ')}
    </span>
  );
}

Rgb.propTypes = {
  value: PropTypes.object
};

Rgb.canDisplay = (key, value) => key === 'rgb';

const specialComponentList = [ValueUnit, Location, HunterLab, Rgb];

/**
 * Certain common composite objects must be displayed as a custome element for compactness or styling
 * examples are values with units or locations
 *
 * @param {string} key
 * @param {object} value
 * @returns component or undefined
 */
export const specialComponent = (key, value) =>
  specialComponentList.find((c) => c.canDisplay(key, value));

/**
 * Returns a list of <tr> tags, one for each element in the object
 * passed in `value`, recursively calls to get all nested levels of
 * the object
 * @param {string} key the key of the current node in the object
 * @param {object | string | number} value the value of the current node in the object
 * @param {*} level the level of the current node relative to the root)
 * @param {*} count  a counter to give a unique id to each node
 * @returns a list of <tr> elements
 */
export const objectToRows = (key, value, level, count, arrayElement) => {
  const tdClassName = `level-${level}`;
  const rowKey = `${count}`;
  let countAcc = count;
  const rows = [];
  const humaneReadableKey = arrayElement
    ? parseInt(key, 10) + 1
    : key && sentenceCase(key);
  if (typeof value === 'object') {
    const isArray = Array.isArray(value);
    const SpecialFormat = specialComponent(key, value);
    if (SpecialFormat) {
      // handle values with units
      rows.push(
        <tr key={rowKey}>
          <td
            className={`${tdClassName} ${arrayElement ? 'array-element' : ''}`}
          >
            <div>{humaneReadableKey}</div>
          </td>
          <td className="value-unit">
            <SpecialFormat value={value} />
          </td>
        </tr>
      );
      countAcc += 1;
    } else {
      if (key) {
        // on the top level we won't have a key, all other levels do
        // the attributes of the object will be shown on subsequent rows
        rows.push(
          <tr key={rowKey}>
            <td
              className={`${tdClassName} key-only ${
                arrayElement ? 'array-element' : ''
              }`}
            >
              <div>{humaneReadableKey}</div>
            </td>
            <td></td>
          </tr>
        );
        countAcc += 1;
      }
      Object.keys(value).forEach((childKey) => {
        // recursively add the rows of children
        const childRows = objectToRows(
          childKey,
          value[childKey],
          level + 1,
          countAcc,
          isArray
        );
        rows.push(...childRows);
        countAcc += childRows.length;
      });
    }
  } else {
    // for a primitive value, add it as a single row
    rows.push(
      <tr key={rowKey}>
        <td className={tdClassName}>
          <div>{humaneReadableKey}</div>
        </td>
        <td>{primitiveValue(value)}</td>
      </tr>
    );
  }
  return rows;
};

function VerifiedCertification({ data }) {
  return (
    <div className="verified-certificate">
      <div className="verified-certificate-container">
        <table>
          <tbody>{data && objectToRows(undefined, data, 0, 0)}</tbody>
        </table>
      </div>
    </div>
  );
}

VerifiedCertification.propTypes = {
  data: PropTypes.object
};

export default VerifiedCertification;
