import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { updateTds } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import { WeightUnitsInput } from 'src/components/form/UnitsInput';
import { updateResultAttribute } from 'src/components/project/source/editable-cells/helpers';
import { Box } from 'src/components/project/explore/summary/helpers';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { Typography } from 'antd';

export default function WeightCell({
  tds,
  allTdss,
  fieldName,
  setQueryData,
  messageApi
}) {
  const { mutate: updateTdsMutation, isLoading: updating } = useMutation({
    mutationFn: updateTds,
    onSuccess: (res) => {
      const newVal = res[fieldName];
      setQueryData((o) =>
        updateResultAttribute(
          o,
          ({ tds_id }) => tds_id === tds.tds_id,
          fieldName,
          newVal
        )
      );
      // messageApi.info('Supplier details updated');
    },
    onError: () => {
      messageApi.error('Failed to update supplier details');
    }
  });

  const tdsWeightMutate = useCallback(
    (v) => {
      if (!v || !tds?.tds_id) return null;

      updateTdsMutation({
        uuid: tds.tds_id,
        [fieldName]: v.value,
        [`${fieldName}_units`]: v.units
      });

      return null;
    },
    [allTdss, fieldName]
  );

  return (
    <ValueInputPopover
      ValueComponent={WeightUnitsInput}
      loading={updating}
      mutate={(v) => tdsWeightMutate(v, `${fieldName}_per_lb`)}
      value={{
        value: tds[fieldName] || tds[`supply_${fieldName}`],
        units: tds[`${fieldName}_units`]
      }}
    >
      <Box>
        <Typography.Text
          ellipsis={{ tooltip: true }}
          className="heavy font-size-2"
        >
          {prettyNumberRound(tds[fieldName] || tds[`supply_${fieldName}`])}{' '}
          {tds[`${fieldName}_units`]}
        </Typography.Text>
      </Box>
    </ValueInputPopover>
  );
}
WeightCell.propTypes = {
  tds: PropTypes.object,
  allTdss: PropTypes.array,
  fieldName: PropTypes.string,
  setQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
