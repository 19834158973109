import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
  CaretUpFilled,
  CaretDownFilled
} from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getLatestIndexPrice } from 'src/Query';
import { useMarketPriceReference } from 'src/Query/marketPrice';
import { sentenceCase } from 'change-case';
import { prettyNumberRound } from './prettyNumber';

export default function MarketPriceDelta({
  price,
  properties,
  location,
  priceIndex
}) {
  const propertiesString = properties
    .map((mp) => `${mp.meta_property.code}.${mp.value}`)
    .join(',');
  const params = {
    material_property_codes: propertiesString
  };
  if (priceIndex) {
    // we explcitily set the index - other parameters will be ignored
    // but we provide them anyway
    params.price_index = priceIndex;
  }
  if (location) {
    params.lat = location.latitude;
    params.lng = location.longitude;
  }
  const { isLoading, price: reference } = useMarketPriceReference(params);

  if (isLoading) {
    return <Spin size="small" />;
  }

  if (!reference) {
    return null;
  }

  const mid = (reference.high + reference.low) / 2;

  const diff = ((price - mid) / mid) * 100;

  let deltaIndicator = <MinusOutlined style={{ color: '#999999' }} />;
  if (diff < 0) {
    deltaIndicator = <ArrowDownOutlined style={{ color: '#52c41a' }} />;
  } else if (diff > 0) {
    deltaIndicator = <ArrowUpOutlined style={{ color: '#f5222d' }} />;
  }

  return (
    <Tooltip
      className="tooltip-wrap"
      title={reference.tickers.map((ticker) => (
        <p key={ticker.source_name}>{`${sentenceCase(ticker.source)}: ${
          ticker.source_name
        } (${ticker.location?.city || ticker.location?.country})`}</p>
      ))}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        {deltaIndicator}&nbsp;{prettyNumberRound(diff)}%&nbsp;vs&nbsp;mkt
      </div>
    </Tooltip>
  );
}
MarketPriceDelta.propTypes = {
  price: PropTypes.number.isRequired,
  properties: PropTypes.array.isRequired,
  priceIndex: PropTypes.string,
  location: PropTypes.object
};

export function PriceDeltaCompact({ delta }) {
  if (typeof delta !== 'number' || !Number.isFinite(delta)) return null;

  const red = '#F5222D';
  const green = '#389E0D';
  const deltaText = Math.round(delta * 100);

  let deltaIndicator = (
    <span className="price-delta compact" style={{ color: '#999999' }}>
      <MinusOutlined /> 0%
    </span>
  );

  if (delta > 0) {
    deltaIndicator = (
      <span className="price-delta compact" style={{ color: green }}>
        <CaretUpFilled /> {deltaText}%
      </span>
    );
  } else if (delta < 0) {
    deltaIndicator = (
      <span className="price-delta compact" style={{ color: red }}>
        <CaretDownFilled /> {deltaText}%
      </span>
    );
  }

  return deltaIndicator;
}

PriceDeltaCompact.propTypes = {
  delta: PropTypes.number
};

export function useLatestMarketPrice(properties) {
  const propertiesString = properties.map(({ uuid }) => uuid).join(',');
  const { data, error, isLoading } = useQuery({
    queryKey: ['latestIndexPrice', { material_properties: propertiesString }],
    queryFn: () =>
      getLatestIndexPrice({ material_properties: propertiesString })
  });

  if (error || isLoading) return null;

  return data.price;
}
