const shippingModeFactors = {
  air: 2.57,
  truck: 0.4,
  water: 0.14,
  pipeline: 0.13,
  rail: 0.05
};

/**
 * Given a distance, a quantity, and a mode, returns estimated CO2 emissions in Mt
 * @param {Number} distanceKm Distance in km
 * @param {Number} quantityLbs Quantity in lbs
 * @param {String} mode One of "air", "truck", "water", "pipeline", "rail"
 * @returns {null|Number} null if invalid arguments, CO2e in Mt otherwise
 */
export default function shippingEmmissions(distanceKm, quantityLbs, mode) {
  if (!distanceKm || !quantityLbs || !shippingModeFactors[mode]) return null;

  const tons = quantityLbs / 2000;
  const distanceMi = 0.621371 * distanceKm;

  const emissionsLb = tons * distanceMi * shippingModeFactors[mode];

  return emissionsLb / 2204.62; // Convert to Mt
}
