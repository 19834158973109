import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TaskStatus from 'src/components/icons/TaskStatus';
import TaskStatusEmpty from 'src/components/icons/TaskStatusEmpty';

/* The antd radio group does not work in the context where the radio buttons are not
 * direct children of the group. We have complex selection components where items to be selected are on a card
 *
 * this is not currently nestabled - but can be made nestable if neede
 */

export const RadioComponentContext = createContext();

function RadioComponentGroup({ value, onChange, children }) {
  // this state only is relevant for a non-controlled component
  // for controlled components, value is the state
  const [selectedIndex, setSelectedIndex] = useState(value);

  const ctxValue = useMemo(
    () => ({
      value: value || selectedIndex,
      setValue: (index) => {
        onChange(index);
        setSelectedIndex(index);
      }
    }),
    [selectedIndex, value]
  );

  return (
    <RadioComponentContext.Provider value={ctxValue}>
      {children}
    </RadioComponentContext.Provider>
  );
}

RadioComponentGroup.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

function RadioComponent({ index, children, className }) {
  const ctx = useContext(RadioComponentContext) || {};
  const { value, setValue } = ctx;

  return (
    <div
      className={`radio-embedded ${value === index ? 'selected' : ''} ${
        className || ''
      }`}
      onClick={setValue && (() => setValue(index))}
      role="presentation"
    >
      {children}
    </div>
  );
}

RadioComponent.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

function RadioComponentIndicator({ index }) {
  const ctx = useContext(RadioComponentContext);
  if (!ctx) return undefined;
  const { value } = ctx;
  return value === index ? <TaskStatus /> : <TaskStatusEmpty />;
}

RadioComponentIndicator.propTypes = {
  index: PropTypes.number
};

export default RadioComponent;

RadioComponent.Group = RadioComponentGroup;
RadioComponent.Indicator = RadioComponentIndicator;
