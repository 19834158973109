import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { updateProject } from 'src/Mutation';

export default function useUpdateProject(projectUuid, filters) {
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      queryClient.invalidateQueries([
        'project',
        projectUuid,
        'populated',
        filters
      ]);
    },
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      messageApi.open({
        type: 'error',
        content: 'An error ocurred. Please try again.'
      });
    }
  });

  return {
    ...mutation,
    messageContext: contextHolder
  };
}
