import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import PropTypes from 'prop-types';
import { Radio, Tooltip } from 'antd';
import { BuildOutlined, ExperimentOutlined } from '@ant-design/icons';
import { generateDeliverySchedule } from 'src/utils/deliveries';

dayjs.extend(quarterOfYear);

const initialBucketData = {};

const BUCKET_WIDTH = 270;
const HEADER_HEIGHT = 54;
const BUCKET_HEIGHT = 53;
const SUPPLIER_LABEL_WIDTH = 320;

const frequencyDays = {
  month: (1 / 12) * 365,
  quarter: (1 / 4) * 365
};

const typeIcon = {
  material: <BuildOutlined />,
  sample: <ExperimentOutlined />
};

const supplierColors = ['#2f54eb', '#51ce92', '#ff4d4f'];
function SupplierAvatar({ avatar, index }) {
  if (avatar) return <img src={avatar} alt="supplier avatar" />;
  return (
    <span
      style={{ backgroundColor: supplierColors[index] || '#ababab' }}
      className="img-placeholder"
    />
  );
}

SupplierAvatar.propTypes = {
  avatar: PropTypes.string,
  index: PropTypes.number
};

const formatDelivery = (delivery) => (
  <table>
    <tbdoy>
      <tr>
        <td>Quantity</td>
        <td>
          {delivery.quantity} {delivery.quantity_units}
        </td>
      </tr>
      <tr>
        <td>Status</td>
        <td>{delivery.status}</td>
      </tr>
      <tr>
        <td>Type</td>
        <td>{delivery.type}</td>
      </tr>
      <tr>
        <td>Shipped</td>
        <td>
          {(delivery.shipped_at && delivery.shipped_at.format('YYYY-MM-DD')) ||
            '-'}
        </td>
      </tr>
      <tr>
        <td>Delivered</td>
        <td>
          {(delivery.delivered_at &&
            delivery.delivered_at.format('YYYY-MM-DD')) ||
            '-'}
        </td>
      </tr>
    </tbdoy>
  </table>
);

function DealDelivery({ deliveries, suppliers }) {
  const [frequency, setFrequency] = useState('month'); // bucket size in months
  const [bucketData, setBucketData] = useState(initialBucketData);
  const containerRef = useRef();
  useEffect(() => {
    setBucketData(generateDeliverySchedule(deliveries, suppliers, frequency));
  }, [deliveries, frequency]);

  useEffect(() => {}, [bucketData]);

  // the algorithm guarantees that all buckets are the same for all suppliers
  const buckets = useMemo(
    () => Object.values(bucketData)[0]?.buckets || [],
    [bucketData]
  );
  const numBuckets = buckets.length;
  const startDate = buckets[0]?.start;
  const supplierKeys = useMemo(() => Object.keys(bucketData), [bucketData]);
  const supplierDataArray = useMemo(
    () => supplierKeys.map((o) => bucketData[o]),
    [supplierKeys]
  );
  const supplierDeliveryArray = useMemo(
    () => supplierDataArray.map((o) => o.deliveries),
    [supplierDataArray]
  );
  const numTicks = useMemo(
    () => buckets.map((bucket) => bucket.labels.ticks.length),
    [buckets]
  );
  // the column widths of the table, with columns being the "ticks" that make up the lowest level
  // of the bucket divisions.
  const columnWidths = useMemo(
    () =>
      [
        SUPPLIER_LABEL_WIDTH,
        buckets.map((bucket, bucketIndex) =>
          bucket.labels.ticks.map(
            (tick) => BUCKET_WIDTH / numTicks[bucketIndex]
          )
        )
      ].flat(2),
    [buckets]
  );
  const daysPerBucket = frequencyDays[frequency];
  const today = dayjs();
  const todayOffset =
    (today.diff(startDate, 'days') / daysPerBucket) * (BUCKET_WIDTH + 3) +
    SUPPLIER_LABEL_WIDTH;
  const deliveryWidth = (delivery) => {
    if (delivery.delivered_at && delivery.shipped_at) {
      return (
        (delivery.delivered_at.diff(delivery.shipped_at, 'days') /
          daysPerBucket) *
        BUCKET_WIDTH
      );
    }
    return 0;
  };
  const deliveryOffset = (delivery) => {
    if (delivery.shipped_at) {
      return (
        (delivery.shipped_at.diff(startDate, 'days') / daysPerBucket) *
        (BUCKET_WIDTH + 3)
      );
    }
    return 0;
  };
  return (
    <div className="deal-delivery">
      <Radio.Group
        value={frequency}
        onChange={(e) => setFrequency(e.target.value)}
      >
        <Radio.Button value="month">Month</Radio.Button>
        <Radio.Button value="quarter">Quarter</Radio.Button>
      </Radio.Group>
      <h2>Delivery Schedule</h2>
      <div className="deal-delivery-table-container" ref={containerRef}>
        <div
          className="deal-delivery-table"
          style={{ width: numBuckets * BUCKET_WIDTH + SUPPLIER_LABEL_WIDTH }}
        >
          <table
            style={{ width: numBuckets * BUCKET_WIDTH + SUPPLIER_LABEL_WIDTH }}
          >
            <colgroup>
              {columnWidths.map(
                (
                  width,
                  index /* eslint-disable react/no-array-index-key */
                ) => (
                  <col key={index} width={width}></col>
                ) /* eslint-enable react/no-array-index-key */
              )}
            </colgroup>
            <thead>
              <tr
                className="delivery-schedule-header"
                height={HEADER_HEIGHT / 2}
              >
                <th className="supplier-header" aria-labelledby="header"></th>
                {buckets.map((bucket, bucketIndex) => (
                  <th
                    colSpan={numTicks[bucketIndex]}
                    key={bucket.labels.header}
                    style={{
                      width: BUCKET_WIDTH
                    }}
                  >
                    {bucket.labels.header}
                  </th>
                ))}
              </tr>
              <tr
                className="delivery-schedule-subheader"
                height={HEADER_HEIGHT / 2}
              >
                <th className="supplier-header" aria-labelledby="header" />
                {buckets.map((bucket, bucketIndex) =>
                  bucket.labels.ticks.map((tick, tickIndex) => (
                    <th
                      className={`tick-header ${
                        tickIndex === 0 ? 'start' : ''
                      }`}
                      key={`${bucket.labels.header}} ${tick.label}`}
                    >
                      {tick.label}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {supplierDataArray.map((data, index) => (
                <tr key={data.key} className="delivery-schedule-row">
                  <td className="supplier-details">
                    <SupplierAvatar avatar={data.avatar} index={index} />{' '}
                    {data.name} ({data.purpose})
                  </td>
                  {data.buckets.map((bucket) => (
                    <td
                      colSpan={bucket.labels.ticks.length}
                      key={bucket.label}
                      className="delivery-schedule-bucket"
                      style={{ height: BUCKET_HEIGHT }}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {supplierDeliveryArray.map((deliveryArray, supplierIndex) =>
            deliveryArray.map((delivery, deliveryIndex) => (
              <Tooltip
                key={delivery.uuid}
                title={formatDelivery(delivery)}
                getPopupContainer={() => containerRef.current}
              >
                <div
                  key={delivery.uuid}
                  style={{
                    width: deliveryWidth(delivery),
                    left: SUPPLIER_LABEL_WIDTH + deliveryOffset(delivery),
                    top:
                      HEADER_HEIGHT + supplierIndex * (BUCKET_HEIGHT + 3) + 10,
                    height: 34
                  }}
                  className={`delivery ${delivery.purpose} ${delivery.status} ${delivery.type}`}
                >
                  {typeIcon[delivery.type]}
                </div>
              </Tooltip>
            ))
          )}
          <div
            className="today"
            style={{
              width: 0,
              left: todayOffset,
              top: HEADER_HEIGHT,
              height: supplierKeys.length * (BUCKET_HEIGHT + 3)
            }}
          />
          <div
            className="today-marker"
            style={{
              width: 8,
              left: todayOffset - 4,
              top: HEADER_HEIGHT - 4,
              height: 8
            }}
          />
        </div>
      </div>
    </div>
  );
}

DealDelivery.propTypes = {
  deliveries: PropTypes.array,
  suppliers: PropTypes.array
};

export default DealDelivery;
