import React, { useMemo } from 'react';

import { Button, Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getTdsPricesForCohort } from 'src/Query/marketPrice';
import { useSearchParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadAntdTable } from 'src/hooks/useDownloadAntdTable';

function CohortPrices() {
  const [searchParams] = useSearchParams();

  const cohort_id = searchParams.get('cohort_id');
  const market_price_ticker_id = searchParams.get('market_price_ticker_id');
  const params = {
    all: true
  };
  if (cohort_id) params.cohort_id = cohort_id;
  if (market_price_ticker_id)
    params.market_price_ticker_id = market_price_ticker_id;
  const { data, isLoading: isActivityLoading } = useQuery(
    ['tds-price-for-cohort', params],
    () => getTdsPricesForCohort(params)
  );

  const columns = useMemo(
    () => [
      {
        title: 'TDS ID',
        dataIndex: 'tds_id',
        sorter: (a, b) => (a.tds_id ? a.tds_id.localeCompare(b.tds_id) : -1)
      },
      {
        title: 'Company ID',
        dataIndex: 'company_id',
        sorter: (a, b) =>
          a.company_id ? a.company_id.localeCompare(b.company_id) : -1
      },
      {
        title: 'Distributor ID',
        dataIndex: 'distributor_id',
        sorter: (a, b) =>
          a.distributor_id
            ? a.distributor_id.localeCompare(b.distributor_id)
            : -1
      },
      {
        title: 'Capacity lbs',
        dataIndex: 'capacity_lbs',
        sorter: (a, b) =>
          a.capacity_lbs ? a.capacity_lbs.localeCompare(b.capacity_lbs) : -1
      },
      {
        title: 'Company name',
        dataIndex: 'company_name',
        sorter: (a, b) =>
          a.company_name ? a.company_name.localeCompare(b.company_name) : -1
      },
      {
        title: 'Sku',
        dataIndex: 'sku',
        sorter: (a, b) => (a.sku ? a.sku.localeCompare(b.sku) : -1)
      },
      {
        title: 'Form',
        dataIndex: 'form',
        sorter: (a, b) => (a.form ? a.form.localeCompare(b.form) : -1)
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : -1)
      },
      {
        title: 'Region',
        dataIndex: 'region',
        sorter: (a, b) => (a.region ? a.region.localeCompare(b.region) : -1)
      },
      {
        title: 'Country',
        dataIndex: 'country',
        sorter: (a, b) => (a.country ? a.country.localeCompare(b.country) : -1)
      },
      {
        title: 'State',
        dataIndex: 'state',
        sorter: (a, b) => (a.state ? a.state.localeCompare(b.state) : -1)
      },
      {
        title: 'City',
        dataIndex: 'city',
        sorter: (a, b) => (a.city ? a.city.localeCompare(b.city) : -1)
      },
      {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => (a.date ? a.date.localeCompare(b.date) : -1)
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: (a, b) => (a.price ? a.price - b.price : -1)
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        sorter: (a, b) => (a.price ? a.price - b.price : -1)
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        sorter: (a, b) =>
          a.currency ? a.currency.localeCompare(b.currency) : -1
      },
      {
        title: 'Units',
        dataIndex: 'units',
        sorter: (a, b) => (a.units ? a.units.localeCompare(b.units) : -1)
      },
      {
        title: 'Terms',
        dataIndex: 'terms',
        sorter: (a, b) => (a.terms ? a.terms.localeCompare(b.terms) : -1)
      }
    ],
    []
  );

  const download = useDownloadAntdTable(
    columns,
    data,
    `price-cohort-${(cohort_id || market_price_ticker_id || '').slice(8)}`
  );

  return (
    <div className="p-xl">
      <Button onClick={download}>
        <DownloadOutlined />
      </Button>
      <Table pagination={false} dataSource={data} columns={columns} />
    </div>
  );
}

export default CohortPrices;
