import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { Button, Divider, Modal, Row, Typography } from 'antd';
import VisibilityIcon from 'src/components/icons/Visibility';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';
import rangesOverlap from 'src/components/utils/rangesOverLap';
import numericRange from 'src/components/utils/numericRange';
import TdsReadOnly from 'src/components/tds/TdsReadOnly';
import {
  PropertyTdsRowHeaderCell,
  PropertyCompare
} from 'src/components/project/explore/supplier-list/BalesTdsRow';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';
import usePropertyFilters from 'src/components/project/hooks/usePropertyFilters';
import falsyNot0 from 'src/components/utils/falsyNot0';
import { currencyValuePrice } from 'src/utils/currency';

const EMPTY_OPTIONS = {};
const BIG_NUM = 1e12;

export default function TdsRow({ tds }) {
  const id = tds.sku || tds.tds_id.slice(0, 8);

  const [kpiFilters = EMPTY_OPTIONS] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);
  const [viewingTds, setViewingTds] = useState();
  const kpisPrimary = usePrimaryKpis();
  const kpisSecondary = useSecondaryKpis();
  const propertyFilters = usePropertyFilters();

  return (
    <>
      <Modal
        width={900}
        open={viewingTds}
        onCancel={() => setViewingTds(false)}
        footer={null}
        title={tds.title}
      >
        <TdsReadOnly id={tds.tds_id} />
      </Modal>
      <Row
        className="explore-suppliers-table--row--tds-content"
        gutter={8}
        align="middle"
        wrap={false}
        // This will make this row stretch to its children size, thus
        // allowing for horizontal scroll in parent
        style={{ display: 'inline-flex' }}
      >
        <BoxColumn
          width={180}
          style={{ marginRight: 'auto', textAlign: 'left' }}
        >
          <Button size="small" onClick={() => setViewingTds(true)}>
            <VisibilityIcon />
          </Button>
          &nbsp;&nbsp;
          <Typography.Text className="heavy sm">{id}</Typography.Text>
        </BoxColumn>
        {kpisPrimary.map((kpiCode) => (
          <KpiRangeCompare
            key={kpiCode}
            kpiCode={kpiCode}
            kpiFilter={kpiFilters[kpiCode]}
            tds={tds}
          />
        ))}
        <Divider type="vertical" />
        {kpisSecondary.map((kpiCode) => (
          <KpiRangeCompare
            key={kpiCode}
            kpiCode={kpiCode}
            kpiFilter={kpiFilters[kpiCode]}
            tds={tds}
          />
        ))}
        {Object.keys(propertyFilters).map((propertyCode) => (
          <PropertyCompare
            key={propertyCode}
            propertyCode={propertyCode}
            propertyFilter={propertyFilters[propertyCode]}
            tds={tds}
          />
        ))}
      </Row>
    </>
  );
}
TdsRow.propTypes = {
  tds: PropTypes.object
};

export function TdsRowHeaderCell({ kpiCode }) {
  return (
    <BoxColumn width={112} key={kpiCode}>
      <Typography.Text className="font-size-1 lh-12">
        <KpiName codeOrUuid={kpiCode} />
      </Typography.Text>
    </BoxColumn>
  );
}
TdsRowHeaderCell.propTypes = {
  kpiCode: PropTypes.number
};

export function TdsRowHeader() {
  const kpisPrimary = usePrimaryKpis();
  const kpisSecondary = useSecondaryKpis();
  const propertyFilters = usePropertyFilters();

  return (
    <Row
      gutter={8}
      className="explore-suppliers-table--row--tds-headers"
      wrap={false}
      style={{ display: 'inline-flex' }}
    >
      <BoxColumn width={180} style={{ marginRight: 'auto', textAlign: 'left' }}>
        <Typography.Text className="heavy sm">Matching Records</Typography.Text>
      </BoxColumn>
      {kpisPrimary.map((kpiCode) => (
        <TdsRowHeaderCell key={kpiCode} kpiCode={kpiCode} />
      ))}
      <Divider type="vertical" />
      {kpisSecondary.map((kpiCode) => (
        <TdsRowHeaderCell key={kpiCode} kpiCode={kpiCode} />
      ))}
      {Object.keys(propertyFilters).map((propertyCode) => (
        <PropertyTdsRowHeaderCell
          key={propertyCode}
          propertyCode={propertyCode}
        />
      ))}
    </Row>
  );
}

export function kpiMid(kpiCode, tds) {
  const {
    [`kpi_${kpiCode}.min`]: tdsMin,
    [`kpi_${kpiCode}.max`]: tdsMax,
    [`kpi_${kpiCode}.units`]: units
  } = tds;
  if (tdsMin && tdsMax) return (tdsMin + tdsMax) / 2;
  if (tdsMin) return tdsMin;
  if (tdsMax) return tdsMax;
  return 0;
}

export function KpiRangeCompare({ kpiFilter = EMPTY_OPTIONS, kpiCode, tds }) {
  const { min: filteredMin = -BIG_NUM, max: filteredMax = BIG_NUM } = kpiFilter;
  const {
    [`kpi_${kpiCode}.min`]: tdsMin,
    [`kpi_${kpiCode}.max`]: tdsMax,
    [`kpi_${kpiCode}.units`]: units
  } = tds;

  const match = rangesOverlap([filteredMin, filteredMax], [tdsMin, tdsMax]);

  // Either TDS or Filter is empty
  const isEmpty = (!tdsMin && !tdsMax) || (!kpiFilter.min && !kpiFilter.max);

  return (
    <BoxColumn width={112}>
      <Box type={isEmpty ? 'neutral' : match ? 'success' : 'danger'}>
        <Typography.Text
          ellipsis={{ tooltip: true }}
          className="heavy font-size-2"
        >
          {numericRange({
            min: tdsMin,
            max: tdsMax,
            empty: '--',
            rangeRender: (min, max) => `${min} - ${max} ${units}`,
            noMaxRender: (v) => `> ${v} ${units}`,
            noMinRender: (v) => `< ${v} ${units}`,
            singleValueRender: (v) => `${v} ${units}`
          })}
        </Typography.Text>
      </Box>
    </BoxColumn>
  );
}
KpiRangeCompare.propTypes = {
  tds: PropTypes.object,
  kpiFilter: PropTypes.object,
  kpiCode: PropTypes.string
};

export function PriceCompare({ value, target, currency, units }) {
  const isEmpty = falsyNot0(value) || falsyNot0(target);
  const match = !isEmpty && value <= target * 1.05;

  return (
    <BoxColumn width={112}>
      <Box type={isEmpty ? 'neutral' : match ? 'success' : 'danger'}>
        <Typography.Text className="heavy font-size-2">
          {falsyNot0(value) ? '--' : currencyValuePrice(value, currency, units)}
        </Typography.Text>
      </Box>
    </BoxColumn>
  );
}
PriceCompare.propTypes = {
  value: PropTypes.string,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  units: PropTypes.string
};

export function ValueCompare({ label, type }) {
  return (
    <BoxColumn width={112}>
      <Box type={type}>
        <Typography.Text className="heavy font-size-2">{label}</Typography.Text>
      </Box>
    </BoxColumn>
  );
}
ValueCompare.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string
};
