import React from 'react';
import PropTypes from 'prop-types';

function RainbowProgress({ progress }) {
  return (
    <div className="rainbow-progress">
      <div
        className="unfinished"
        style={{ width: `${(1 - progress) * 100}%` }}
      ></div>
    </div>
  );
}

RainbowProgress.propTypes = {
  progress: PropTypes.number
};

export default RainbowProgress;
