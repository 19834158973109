import React from 'react';
import PropTypes from 'prop-types';

function DarkPanel({ children, className, style }) {
  return (
    <div
      className={`dark-panel dark-background ${className || ''}`}
      style={style}
    >
      <div className="dark-panel--contents">{children}</div>
    </div>
  );
}

DarkPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

function DarkPanelBox({ children }) {
  return <div className="dark-panel-box">{children}</div>;
}

DarkPanelBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

DarkPanel.Box = DarkPanelBox;

export default DarkPanel;
