import { Col, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import SupplyComparisonMatchIcon from './SupplyComparisonMatchIcon';

export default function SupplyComparison({ fields }) {
  return (
    <div className="supply-comparison">
      {fields?.map((field, i) => {
        if (field.hide) return null;

        if (field.label)
          return (
            <SupplyComparisonPlainField
              // eslint-disable-next-line
              key={i}
              field={field}
            />
          );

        return (
          <Row
            // eslint-disable-next-line
            key={i}
            justify="space-between"
            wrap={false}
          >
            {field.match !== undefined ? (
              <Col span={1}>
                <SupplyComparisonMatchIcon
                  match={field.match}
                  onChange={field.matchUpdateFunc}
                />
              </Col>
            ) : null}
            <Col flex="1">{field.content}</Col>
          </Row>
        );
      })}
    </div>
  );
}
SupplyComparison.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      match: PropTypes.oneOf([true, false, null]),
      fullWidth: PropTypes.bool,
      hide: PropTypes.bool,
      matchUpdateFunc: PropTypes.func
    }).isRequired
  )
};

function SupplyComparisonPlainField({ field }) {
  return (
    <Row className="plain-field" wrap={!!field.fullWidth}>
      {field.match !== undefined ? (
        <Col span={1}>
          <SupplyComparisonMatchIcon
            match={field.match}
            onChange={field.matchUpdateFunc}
          />
        </Col>
      ) : null}
      <Col>{field.label}</Col>
      <Col>{field.content}</Col>
    </Row>
  );
}
SupplyComparisonPlainField.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    match: PropTypes.oneOf([true, false, null]),
    matchUpdateFunc: PropTypes.func,
    fullWidth: PropTypes.bool
  }).isRequired
};
