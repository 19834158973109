import React, { useState } from 'react';
import { Alert, Col, Row, Typography } from 'antd';
import './OnboardingPortal.less';
import { getOnboarding } from 'src/Query';
import BarePageLayout from 'src/components/layout/BarePageLayout';
import CircularLogo from 'src/components/icons/CircularLogo';
import useCalendlyEvent from 'src/hooks/useCalendlyEvent';
import { useQuery } from '@tanstack/react-query';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';

function OnboardingPortalStatus() {
  const [error, setError] = useState();
  const { data, isLoading, isError } = useQuery(['onboarding'], () =>
    getOnboarding()
  );

  const onboardingData = data?.[0];
  const {
    event,
    isLoading: isLoadingCalendly,
    isError: isErrorCalendly,
    dateTime,
    timezone,
    minutes
  } = useCalendlyEvent(onboardingData?.kickoff_event_id);

  return (
    <BarePageLayout>
      <div className="onboarding-portal">
        {error && <Alert message={error} type="error" />}
        <div className="top-logo">
          <CircularLogo light />
        </div>
        <section>
          <Typography.Title level={2}>See you soon!</Typography.Title>
          <p>
            Thank you for taking a moment to submit the onboarding materials. We
            have the information we need and look forward to connecting on your
            kick-off call.
          </p>
          <div className="kickoff-call-details">
            {event && (
              <Row gutter={[32, 32]}>
                <Col lg={3}>
                  <span className="icon-style">
                    <CalendarOutlined />
                  </span>
                </Col>
                <Col lg={21} className="minutes">
                  {minutes} Minute Meeting
                </Col>
                <Col lg={3}>
                  <span className="icon-style">
                    <ClockCircleOutlined />
                  </span>
                </Col>
                <Col lg={21}>
                  <div>{dateTime}</div>
                  <div className="timezone">{timezone}</div>
                </Col>
              </Row>
            )}
          </div>
          <Typography.Title level={5}>What&apos;s next?</Typography.Title>
          <ul>
            <li>
              We have received the emails you submitted on behalf of your team.
              You and your team will be given access to the Circular platform
              immediately following the kick-off call.
            </li>
            <li>
              During the kick-off call, we will walk you and your team through
              how to use key features and actions on the Circular platform.
            </li>
            <li>
              Our team will review the project information you provided. Once
              our review is complete, we will standardize the information into
              our RFQ process. We will review the RFQ with you in detail during
              our kick-off call.
            </li>
          </ul>
          <p>You may close this screen when you are ready.</p>
        </section>
      </div>
    </BarePageLayout>
  );
}

export default OnboardingPortalStatus;
