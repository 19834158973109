import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getCompanyFunctions } from 'src/Query';

const DEFAULT_VALUE = [];

// Currently only works as a managed component (onChange and value must be used)
function CompanyType({ value, onChange }) {
  const { data: companyFunction = DEFAULT_VALUE } = useQuery(
    ['companyFunction'],
    () => getCompanyFunctions(),
    { retry: false }
  );

  const options = useMemo(() => {
    if (Array.isArray(companyFunction)) {
      return companyFunction.map((o) => ({ label: o.name, value: o.uuid }));
    }
    return [];
  }, [companyFunction]);

  return (
    <div className="radio-image-group">
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          {options.map((option) => (
            <Radio
              key={option.value}
              data-testid={option.value}
              value={option.value}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
}

CompanyType.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default CompanyType;
