import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getProperties } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

function useMetaPropertyOptionNameFromCode(optionCodes) {
  const { data: metaProperties = DEFAULT_LOADING } = useQuery({
    queryKey: ['properties'],
    queryFn: () => getProperties()
  });

  const optionNameMap = useMemo(
    () =>
      metaProperties?.reduce((agg, property) => {
        property.options.forEach((option) => {
          // eslint-disable-next-line
          agg[option.code] = option.value;
        });
        return agg;
      }, {}),
    [metaProperties]
  );

  if (!optionNameMap || !optionCodes || !Array.isArray(optionCodes))
    return optionCodes;
  return optionCodes.map((code) => optionNameMap[code]);
}

export default useMetaPropertyOptionNameFromCode;
