import React from 'react';
import { ReactComponent as Icon } from './svg/sourceMultipleOutlined.svg';
import SvgIconComponent from './SvgIconComponent';

function SourceMultipleOutlined() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default SourceMultipleOutlined;
