import React from 'react';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { currencyValuePrice } from 'src/utils/currency';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';

function currencyValuePriceWithBlank(value, currency, units) {
  if (!value) return '--';
  return currencyValuePrice(value, currency, units);
}

export default function PriceSummary({
  supplierPrice: { material, tariff, freight, currency, terms },
  buyerPrice: {
    material: materialBuyer,
    tariff: tariffBuyer,
    freight: freightBuyer,
    currency: currencyBuyer,
    terms: termsBuyer
  },
  buyerIndex,
  units
}) {
  const indexString = buyerIndex ? ` (${buyerIndex})` : '';
  const total =
    (Number(material) || 0) + (Number(tariff) || 0) + (Number(freight) || 0);
  const totalBuyer =
    (Number(materialBuyer) || 0) +
    (Number(tariffBuyer) || 0) +
    (Number(freightBuyer) || 0);
  const deltaMaterial =
    materialBuyer && material
      ? ` (${prettyNumberRound((material / materialBuyer - 1) * 100)}%)`
      : '';
  const deltaTotal =
    totalBuyer && total
      ? ` (${prettyNumberRound((total / totalBuyer - 1) * 100)}%)`
      : '';
  return (
    <div className="proposal-price-summary p mb-xxl">
      <Flex vertical className="mt" gap={12}>
        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <h6 className="ant-typography heavy mb-0">Total Cost Breakdown</h6>
          </Col>
          <Col span={6}>
            <Typography.Text>Target</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text>Supplier</Typography.Text>
          </Col>
        </Row>

        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <Typography.Text>Raw Material{indexString}</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text>
              {currencyValuePriceWithBlank(materialBuyer, currencyBuyer, units)}
            </Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              <Typography.Text className="heavy">
                {currencyValuePriceWithBlank(material, currency, units)}
              </Typography.Text>{' '}
              {deltaMaterial}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <Typography.Text>Tariffs &amp; Fees</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text>
              {currencyValuePriceWithBlank(tariffBuyer, currencyBuyer, units)}
            </Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text className="heavy">
              {currencyValuePriceWithBlank(tariff, currency, units)}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <Typography.Text>Freight</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text>
              {currencyValuePriceWithBlank(freightBuyer, currencyBuyer, units)}
            </Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text className="heavy">
              {currencyValuePriceWithBlank(freight, currency, units)}
            </Typography.Text>
          </Col>
        </Row>
        <Divider className="m-0" />
        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <h6 className="ant-typography heavy mb-0">Total Landed Cost</h6>
          </Col>
          <Col span={6}>
            <Typography.Text className="heavy">
              {currencyValuePriceWithBlank(totalBuyer, currency, units)}
            </Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              <Typography.Text className="heavy">
                {currencyValuePriceWithBlank(total, currency, units)}
              </Typography.Text>{' '}
              {deltaTotal}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          className="proposal-price-summary-row"
          gutter={[8, 0]}
          wrap={false}
        >
          <Col span={12}>
            <Typography.Text>Pricing Terms</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text>{termsBuyer || '--'}</Typography.Text>
          </Col>
          <Col span={6}>
            <Typography.Text className="heavy">{terms || '--'}</Typography.Text>
          </Col>
        </Row>
      </Flex>
    </div>
  );
}

PriceSummary.propTypes = {
  supplierPrice: PropTypes.object,
  buyerPrice: PropTypes.object,
  buyerIndex: PropTypes.string,
  units: PropTypes.string
};
