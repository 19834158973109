import React from 'react';
import PropTypes from 'prop-types';

function SvgIconComponent({ children, style, ...props }) {
  return (
    <span role="img" className="circular-icon" style={style} {...props}>
      {children}
    </span>
  );
}

SvgIconComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default SvgIconComponent;
