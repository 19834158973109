import { ConfigProvider } from 'antd';
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';

import './Supplier.less';

import SupplierOnboarding from 'src/pages/supplier/Onboarding';

export default function SupplierExperience() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto',
          fontFamilyCode: 'Roboto Mono',
          fontSizeHeading1: 22,
          fontSizeHeading2: 24,
          lineHeightHeading1: 28 / 22,
          lineHeightHeading2: 32 / 24,
          fontSize: 14,
          lineHeight: 22 / 14,
          margin: 12
        }
      }}
    >
      <div className="supplier-page">
        <Routes>
          <Route path="/onboarding" element={<SupplierOnboarding />} />
          <Route path="*" element={<SupplierPagesWithSidebar />} />
        </Routes>
      </div>
    </ConfigProvider>
  );
}

function SupplierInsightsRedirect() {
  const navigate = useNavigate();
  const { supplierId } = useParams();
  useEffect(() => {
    navigate(`/project/supplier-insights/${supplierId || ''}`);
  }, []);
}

function SupplierProfileRedirect() {
  const navigate = useNavigate();
  const { supplierId } = useParams();
  useEffect(() => {
    navigate(`/project/supplier/${supplierId || ''}`);
  }, []);
}

function SupplierPagesWithSidebar() {
  return (
    <Routes>
      <Route
        path="/insights/:supplierId?"
        element={<SupplierInsightsRedirect />}
      />
      <Route path="/:supplierId?" element={<SupplierProfileRedirect />} />
    </Routes>
  );
}
