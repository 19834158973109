import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { applyFilterToPropertyOptions } from 'src/utils/properties';

const addOtherOptionToValue = (value, addOther) => {
  if (!addOther) return value;

  if (Array.isArray(value)) {
    return [...value.filter((o) => o !== 'other'), 'other'];
  }

  return 'other';
};

// autocomplete prop indicates that the value returned from this control
// will be the test of the selected option rather than it's uuid
// this is sued for when we use properties to create a list of possible
// values when the the underlying field is a free form text field
function MetaPropertySelectFormItem({
  propertyName,
  filter,
  multiple,
  autocomplete,
  otherOptionFieldName,
  ...props
}) {
  const property = useMetaProperty(propertyName);
  const filteredOptions = useMemo(
    () => applyFilterToPropertyOptions(property?.options, filter),
    [property, filter]
  );

  const isRequired =
    props.required || props.rules?.find(({ required }) => !!required);

  const supportOtherOption = !!otherOptionFieldName;
  const [otherSelected, setOtherSelected] = useState();

  const form = Form.useFormInstance();
  const otherOptionValue = Form.useWatch(otherOptionFieldName, {
    form,
    preserve: true
  });

  useEffect(() => {
    if (supportOtherOption && otherOptionValue && !otherSelected) {
      setOtherSelected(true);
    }
  }, [otherOptionValue]);

  return (
    <>
      <Form.Item
        {...props}
        rules={
          isRequired
            ? [
                {
                  required: true,
                  message: `This field is required`,
                  transform: (v) => (otherSelected ? ['someValue'] : v),
                  validator: (_, v) => {
                    if (otherSelected) return Promise.resolve();

                    if (!v || !v?.length) return Promise.reject();

                    return Promise.resolve();
                  }
                }
              ]
            : []
        }
        getValueFromEvent={(val) => {
          if (!supportOtherOption) return val;

          if (Array.isArray(val) && val.includes('other')) {
            setOtherSelected(true);
            return val.filter((o) => o !== 'other');
          }
          if (val === 'other') {
            setOtherSelected(true);
            return null;
          }

          setOtherSelected(false);
          return val;
        }}
        getValueProps={(val) => ({
          value: addOtherOptionToValue(val, otherSelected)
        })}
      >
        <Select
          loading={!property}
          mode={multiple && 'multiple'}
          optionFilterProp="label"
        >
          {filteredOptions.map((option) => (
            <Select.Option
              value={autocomplete ? option.value : option.uuid}
              key={option.uuid}
              label={option.value}
            >
              {option.value}
            </Select.Option>
          ))}
          {supportOtherOption && (
            <Select.Option value="other" label="Other">
              Other
            </Select.Option>
          )}
        </Select>
      </Form.Item>
      {otherSelected && (
        <Form.Item
          className="other-option"
          label="Please specify"
          rules={[
            {
              required: true,
              message: 'This field is required when "other" is selected.'
            }
          ]}
          name={otherOptionFieldName}
        >
          <Input size="small" />
        </Form.Item>
      )}
    </>
  );
}

MetaPropertySelectFormItem.propTypes = {
  propertyName: PropTypes.string,
  filter: PropTypes.func,
  autocomplete: PropTypes.bool,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.array,
  otherOptionFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default MetaPropertySelectFormItem;
