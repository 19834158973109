import { useMemo } from 'react';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';

export const useSecondaryKpis = () => {
  const primaryKpis = usePrimaryKpis();
  const [allKpis] = useConciergeContextState(['explore', 'filters', 'kpis']);
  return useMemo(() => {
    if (!allKpis) return [];

    return Object.keys(allKpis).filter(
      (kpiCode) => !primaryKpis.includes(kpiCode)
    );
  }, [allKpis, primaryKpis]);
};
