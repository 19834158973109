import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetch } from 'src/utils/client';
import { handleTokenPair } from 'src/utils/authentication';

export default function SesameLogin() {
  const [searchParams] = useSearchParams();
  const sesame = searchParams.get('sesame');
  const next = searchParams.get('next');

  const { data, error } = useQuery(['sesame-login', sesame], () =>
    fetch(`${process.env.REACT_APP_CIRCLE_API}/user/sesame/?sesame=${sesame}`)
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const { access, refresh } = data;
      if (access && refresh) {
        handleTokenPair({ access, refresh, restricted: 1 });
        navigate(decodeURIComponent(next));
      }
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      navigate(`/login?next=${next}`);
    }
  }, [error]);

  return undefined;
}
