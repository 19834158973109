import React, { useState } from 'react';
import { Form, Button, Modal, Checkbox, Badge } from 'antd';
import PropTypes from 'prop-types';

import RangeInput from 'src/components/form/RangeInput';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';

import './NumericalPropertyFilter.less';

export function NumericalPropertyFilterGroup({ label, propertyIds }) {
  const [open, setOpen] = useState(false);
  const allProperties = useMetaNumericalProperty();
  const form = Form.useFormInstance();

  if (!allProperties?.length) return null;

  const properties = allProperties.filter(({ uuid }) =>
    propertyIds.includes(uuid)
  );
  const propertiesFilteredOn = properties.filter(({ uuid }) =>
    form.getFieldValue(`mnp.${uuid}`)
  );

  return (
    <Form.Item label=" " colon={false}>
      <Badge count={propertiesFilteredOn.length} color="#2F54EB">
        <Button onClick={() => setOpen(true)} htmlType="button">
          {label}
        </Button>
      </Badge>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        // mask={false}
      >
        {properties.map((property) => (
          <NumericalPropertyFilter key={property.uuid} property={property} />
        ))}
      </Modal>
    </Form.Item>
  );
}
NumericalPropertyFilterGroup.propTypes = {
  label: PropTypes.string,
  propertyIds: PropTypes.array
};

export default function NumericalPropertyFilter({ property }) {
  return (
    <div className="numerical-property-filter">
      <Form.Item
        className="numerical-property-filter-range"
        label={property.name}
        name={`mnp.${property.uuid}`}
      >
        <RangeInput compact />
      </Form.Item>
      <Form.Item
        className="numerical-property-filter-allow-null"
        name={`mnp.${property.uuid}.allowNull`}
        valuePropName="checked"
      >
        <Checkbox>Allow null</Checkbox>
      </Form.Item>
    </div>
  );
}
NumericalPropertyFilter.propTypes = {
  property: PropTypes.object
};
