import { Alert, Button, Col, Row, Skeleton } from 'antd';
import { LockFilled } from '@ant-design/icons';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getRfqProject } from 'src/Query';
import UsersIcon from 'src/components/icons/UsersIcon';
import ProjectData from 'src/pages/project/ProjectData';
import TabsWithRoutes from 'src/components/tabs/TabsWithRoutes';
import DocumentsTab from './DocumentsTab';
import TeamTab from './TeamTab';
import ProcureTab from './ProcureTab';
import ManageTab from './ManageTab';
import OverviewTab from './OverviewTab';

function ProjectDetails() {
  const { rfqId, tab } = useParams();

  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  if (isLoading) return <Skeleton />;
  if (error)
    return (
      <Alert
        type="error"
        showIcon
        description={error.message || 'Not found.'}
      />
    );

  const { projects, request_for_quote: requestForQuote } = rfqProject;
  const project = projects[0];

  return (
    <div className="project-details">
      <div className="project-details--top">
        <Row justify="space-between">
          <Col>
            <h1>
              {project.title}{' '}
              {project.demo && <span className="demo-badge">Demo</span>}
            </h1>
          </Col>
          <Col>
            <Row align="middle">
              <Button type="default">Invite</Button>
              <div className="project-details--top--members">
                <UsersIcon /> {project.num_participants}
              </div>
            </Row>
          </Col>
        </Row>
        <ProjectData requestForQuote={requestForQuote} />
      </div>
      <div className="project-details--body">
        <TabsWithRoutes
          activeKey={tab}
          items={[
            {
              key: 'overview',
              label: <>Overview</>,
              children: <OverviewTab />
            },
            {
              key: 'procure',
              label: (
                <>
                  {requestForQuote.status !== 'demo' && <LockFilled />}
                  Procure
                </>
              ),
              disabled: requestForQuote.status !== 'demo',
              children: <ProcureTab />
            },
            {
              key: 'manage',
              label: (
                <>
                  {requestForQuote.status !== 'demo' && <LockFilled />}
                  Manage
                </>
              ),
              disabled: requestForQuote.status !== 'demo',
              children: <ManageTab />
            },
            {
              key: 'documents',
              label: 'Documents',
              children: <DocumentsTab />
            },
            {
              key: 'team',
              label: 'Team',
              children: <TeamTab />
            },
            {
              key: 'settings',
              label: 'Settings',
              children: undefined
            }
          ]}
        />
      </div>
    </div>
  );
}

export default ProjectDetails;
