import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Row, Col, Button, Select, Form, Input } from 'antd';
import {
  FilterFilled,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import useMetaProperty from 'src/hooks/useMetaProperty';
import RangeInput from 'src/components/form/RangeInput';
import { ConciergeContext } from 'src/components/concierge/ConciergeContext';
import { getNumericalPropertyGroups, getProperties } from 'src/Query';
import debounce from 'src/utils/debounce';
import useGetRfq from 'src/Query/useGetRfq';
import { continentMap, countryNames } from 'src/utils/regions';
import { NumericalPropertyFilterGroup } from './NumericalPropertyFilter';
import RemovableFilterTag from './RemovableFilterTag';

const getPbFiltersKey = (rfq) => `pbfilters_${rfq?.uuid || 'default'}`;

export default function useProposalBuilderFilters() {
  // Get RFQ to filter by from URL
  const [params] = useSearchParams();
  const rfqId = params.get('rfqId');
  const { data: rfqFilter } = useGetRfq();

  // Form controls
  const [form] = Form.useForm();

  // We want to share latest filters across multiple components, so we store globally
  const {
    values: { proposalBuilder: { filters = {}, display = 'table' } = {} } = {},
    patchValues
  } = useContext(ConciergeContext);

  const setFilters = (newFiltersValOrFunc, updateForm = false) => {
    let newFiltersVal;

    if (typeof newFiltersValOrFunc === 'function') {
      newFiltersVal = newFiltersValOrFunc(filters);
    } else {
      newFiltersVal = newFiltersValOrFunc;
    }

    if (updateForm) {
      form.resetFields();
      form.setFieldsValue(unparseValues(newFiltersVal));
    }

    patchValues(['proposalBuilder', 'filters'], newFiltersVal);
  };

  const setDisplay = (newDisplayValOrFunc) => {
    let newDisplayVal;

    if (typeof newDisplayValOrFunc === 'function') {
      newDisplayVal = newDisplayValOrFunc(display);
    } else {
      newDisplayVal = newDisplayValOrFunc;
    }

    patchValues(['proposalBuilder', 'display'], newDisplayVal);
  };

  // Fetch all meta properties to use in table columns
  const { data: properties } = useQuery(['properties'], () => getProperties());
  const typeProperty = useMetaProperty('type');
  const formProperty = useMetaProperty('form');
  const colorProperty = useMetaProperty('color');
  const methodProperty = useMetaProperty('processing method');

  const { data: numericalPropertyGroups } = useQuery(
    ['numerical-property-groups'],
    () => getNumericalPropertyGroups()
  );

  // Table controls
  const [pagination, setPagination] = useState({ page: 1, page_size: 25 });
  const [expandFilters, setExpandFilters] = useState(false);

  const defaultFilters = useMemo(
    () => () => {
      const parsedRfqFilters = {};

      if (rfqFilter) {
        // Type
        const rfqTypes = rfqFilter?.material_properties
          ?.filter(({ meta_property }) => ['type'].includes(meta_property.code))
          ?.map((propertyOption) => propertyOption.uuid);
        if (rfqTypes && rfqTypes.length)
          parsedRfqFilters['material_properties[0]'] = rfqTypes;

        // Form
        const rfqForms = rfqFilter?.material_properties
          ?.filter(({ meta_property }) => ['form'].includes(meta_property.code))
          ?.map((propertyOption) => propertyOption.uuid);
        if (rfqForms && rfqForms.length)
          parsedRfqFilters['material_properties[1]'] = rfqForms;

        // Country
        // if (rfqFilter.shipping_addresses?.length) {
        //   parsedRfqFilters.query = Array.from(
        //     new Set(rfqFilter.shipping_addresses?.map(({ country }) => country))
        //   ).join(', ');
        // }
      }

      setFilters(parsedRfqFilters);

      form.resetFields();
      form.setFieldsValue(parsedRfqFilters);
    },
    [rfqFilter]
  );

  const waitForRfq = rfqId && !rfqFilter;

  // Removable filter tags
  const filterTags = useMemo(
    () => (
      <Row>
        {Object.keys(filters).map((filterKey) => (
          <RemovableFilterTag
            key={filterKey}
            keyName={filterKey}
            value={filters[filterKey]}
            setFilters={setFilters}
          />
        ))}
      </Row>
    ),
    [filters]
  );

  // Populate filters based on newly loaded RFQ
  useEffect(() => {
    if (!form) return;

    // wait for the RFQ to finish loading before setting filters otherwise
    // we set them to local storage and then set them to RFQ
    if (waitForRfq) return;

    const latestSearch = getLatestSearchFromLocalStorage(rfqFilter);

    // Populate filters from latest search
    if (latestSearch) {
      const latestFilters = unparseValues(latestSearch);
      if (Object.keys(latestFilters).length > 0) {
        setFilters(latestSearch);
        form.resetFields();
        form.setFieldsValue(latestFilters);
        return;
      }
    }

    if (!rfqFilter || !defaultFilters) return;

    defaultFilters();
  }, [rfqFilter, form, waitForRfq]);

  // Update filters in localStorage
  useEffect(() => {
    if (filters && Object.keys(filters).length) {
      localStorage.setItem(getPbFiltersKey(rfqFilter), JSON.stringify(filters));
    }
  }, [filters]);

  const regionOptions = useMemo(
    () =>
      Object.keys(continentMap).map((k) => ({
        value: k,
        label: continentMap[k].name
      })),
    []
  );

  const countryOptions = useMemo(() =>
    countryNames.map((o) => ({ value: o, label: o }))
  );

  return {
    filters,
    setFilters,
    pagination,
    setPagination,
    display,
    setDisplay,
    wait: waitForRfq,
    FiltersRow: (
      <>
        <Form
          className="proposal-builder--filters"
          form={form}
          layout="inline"
          preserve
          onValuesChange={debounce((_, values) => {
            const filterValues = { ...values };

            if (
              Array.isArray(filterValues.company__rfq_evaluation__status__in) &&
              filterValues.company__rfq_evaluation__status__in.length > 0
            ) {
              filterValues.company__rfq_evaluation__rfq__uuid = rfqFilter.uuid;
            } else {
              delete filterValues.company__rfq_evaluation__rfq__uuid;
            }

            if (filters.ordering) {
              filterValues.ordering = filters.ordering;
            }

            setFilters(parseValues(filterValues));
            setPagination({ ...pagination, page: 1 });
          }, 500)}
          size="small"
        >
          <Row wrap={false} justify="space-between">
            <Col className="left">
              <Form.Item name="query" label="Query">
                <Input
                  placeholder="Search prospects"
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item label="Material" name="material_properties[0]">
                <Select
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  showSearch
                  optionFilterProp="filterProp"
                  options={
                    typeProperty?.options.map((o) => ({
                      label: o.value,
                      value: o.uuid,
                      filterProp: `${o.value} ${o.uuid}`
                    })) || []
                  }
                />
              </Form.Item>
              <Form.Item label="Form" name="material_properties[1]">
                <Select
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  showSearch
                  optionFilterProp="filterProp"
                  options={
                    formProperty?.options.map((o) => ({
                      label: o.value,
                      value: o.uuid,
                      filterProp: `${o.value} ${o.uuid}`
                    })) || []
                  }
                />
              </Form.Item>
              <Form.Item label="Color" name="material_properties[2]">
                <Select
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  showSearch
                  optionFilterProp="filterProp"
                  options={
                    colorProperty?.options.map((o) => ({
                      label: o.value,
                      value: o.uuid,
                      filterProp: `${o.value} ${o.uuid}`
                    })) || []
                  }
                />
              </Form.Item>
              <Form.Item
                label="Processing Method"
                name="material_properties[3]"
              >
                <Select
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  showSearch
                  optionFilterProp="filterProp"
                  options={
                    methodProperty?.options.map((o) => ({
                      label: o.value,
                      value: o.uuid,
                      filterProp: `${o.value} ${o.uuid}`
                    })) || []
                  }
                />
              </Form.Item>
              <Form.Item label="Region" name="regions">
                <Select
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  showSearch
                  options={regionOptions}
                />
              </Form.Item>
              <Button
                style={{ margin: '15px 10px 0 0' }}
                type="primary"
                ghost
                htmlType="button"
                onClick={() => setExpandFilters(!expandFilters)}
              >
                {expandFilters ? (
                  <>
                    <FilterFilled /> Collapse
                  </>
                ) : (
                  <>
                    <FilterOutlined /> More Filters
                  </>
                )}
              </Button>
              <Button
                style={{ margin: '15px 0 0' }}
                htmlType="button"
                onClick={defaultFilters}
              >
                <ReloadOutlined /> Reset
              </Button>
              <br />
              {expandFilters && (
                <>
                  <Form.Item label="Country" name="locations__country">
                    <Select
                      mode="multiple"
                      showArrow
                      maxTagCount={1}
                      showSearch
                      options={countryOptions}
                    />
                  </Form.Item>
                  {rfqFilter && (
                    <Form.Item
                      label="Status"
                      name="company__rfq_evaluation__status__in"
                    >
                      <Select
                        mode="multiple"
                        showArrow
                        maxTagCount={1}
                        showSearch
                        optionFilterProp="filterProp"
                        options={[
                          { label: 'Matched', value: 'matched' },
                          { label: 'Not Contacted', value: '-contacted' },
                          { label: 'Contacted', value: 'contacted' },
                          { label: 'Not Engaged', value: '-engaged' },
                          { label: 'Engaged', value: 'engaged' },
                          { label: 'Not Screened', value: '-screened' },
                          { label: 'Screened', value: 'screened' },
                          { label: 'Not Approved', value: '-approved' },
                          { label: 'Approved', value: 'approved' },
                          { label: 'Not Disqualified', value: '-disqualified' },
                          { label: 'Disqualified', value: 'disqualified' }
                        ]}
                      />
                    </Form.Item>
                  )}
                  {properties
                    ?.filter(
                      ({ code }) =>
                        ![
                          'type',
                          'form',
                          'color',
                          'processing-method'
                        ].includes(code)
                    )
                    ?.sort((a, b) =>
                      // eslint-disable-next-line no-nested-ternary
                      a.order === 0 ? 1 : b.order === 0 ? -1 : a.order - b.order
                    )
                    .map((prop, i) => (
                      <Form.Item
                        key={prop.uuid}
                        label={prop.name}
                        name={`material_properties[${i + 4}]`}
                      >
                        <Select
                          mode="multiple"
                          showArrow
                          maxTagCount={1}
                          showSearch
                          optionFilterProp="filterProp"
                          options={
                            prop?.options.map((o) => ({
                              label: o.value,
                              value: o.uuid,
                              filterProp: `${o.value} ${o.uuid}`
                            })) || []
                          }
                        />
                      </Form.Item>
                    ))}
                  <Form.Item label="Quantity (lbs)" name="capacity">
                    <RangeInput compact />
                  </Form.Item>
                  {numericalPropertyGroups &&
                    numericalPropertyGroups.map((group, i) => (
                      <NumericalPropertyFilterGroup
                        key={group.uuid}
                        label={group.name}
                        propertyIds={group.meta_numerical_properties}
                      />
                    ))}
                </>
              )}
            </Col>
          </Row>
        </Form>
        <div>{filterTags}</div>
      </>
    )
  };
}

export function getLatestSearchFromLocalStorage(rfq) {
  const lsValue = localStorage.getItem(getPbFiltersKey(rfq));

  if (!lsValue) return null;

  return JSON.parse(lsValue);
}

function parseValues(values) {
  const valuesClone = { ...values };

  Object.keys(valuesClone).forEach((key) => {
    // Filter out empty values
    if (
      [undefined, ''].includes(valuesClone[key]) ||
      valuesClone[key]?.length === 0
    )
      delete valuesClone[key];

    // Parse range values into backend-friendly format
    if (
      typeof valuesClone[key] === 'object' &&
      !Array.isArray(valuesClone[key])
    ) {
      if (valuesClone[key].min || valuesClone[key].max) {
        const { min, max } = valuesClone[key];

        if (key.startsWith('mnp.')) {
          const allowNull = valuesClone[`${key}.allowNull`];

          valuesClone[key] = `${min || ''},${max || ''},${
            allowNull ? '1' : '0'
          }`;

          delete valuesClone[`${key}.allowNull`];
        } else {
          if (min) valuesClone[`${key}__gte`] = min;

          if (max) valuesClone[`${key}__lte`] = max;

          delete valuesClone[key];
        }
      } else {
        delete valuesClone[key];
      }
    }
  });

  return valuesClone;
}

function unparseValues(values) {
  const valuesClone = { ...values };

  valuesClone.capacity = {};

  if (valuesClone.capacity__gte) {
    valuesClone.capacity.min = valuesClone.capacity__gte;
    delete valuesClone.capacity__gte;
  }

  if (valuesClone.capacity__lte) {
    valuesClone.capacity.max = valuesClone.capacity__lte;
    delete valuesClone.capacity__lte;
  }

  return valuesClone;
}
