import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const VisibilityContext = createContext();
export const useVisibility = () => {
  const context = useContext(VisibilityContext);
  return context !== undefined ? context : true;
};

function Visible({ visible, children, lazy }) {
  // if lazy is set, then the children are not rendered until they are visible
  // this is to support certain content that does not size correctly until it is visible
  // e.g. mapbox
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    if (visible && lazy) {
      setRendered(true);
    }
  }, [lazy, visible]);
  return (
    <VisibilityContext.Provider value={visible}>
      <div style={{ display: !visible && 'none' }}>
        {(!lazy || rendered || visible) && children}
      </div>
    </VisibilityContext.Provider>
  );
}

Visible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  visible: PropTypes.bool,
  lazy: PropTypes.bool
};

export default Visible;
