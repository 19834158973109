import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingFilled,
  TableOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  message
} from 'antd';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getTdsCsv } from 'src/Query/warehouse';
import useGetWarehouseTdss, {
  useGetWarehouseTdssKey
} from 'src/Query/useGetWarehouseTdss';
import MapIcon from 'src/components/icons/MapIcon';
import EditTdsModal from 'src/pages/supplier/TechnicalDataSheet';
import OutreachResults from 'src/components/project/search/OutreachResults';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { useTdsTable } from 'src/components/project/source/TdsTable';
import MapPageMarkers from 'src/pages/maps/MapPageMarkers';
import { useQueryClient } from '@tanstack/react-query';
import { useDebounce } from 'src/hooks/useDebounce';

const DEFAULT_PAGE_SIZE = 20;

export default function SearchContent({ pageSize }) {
  const filters = useRelevantFiltersForForm();

  const [view, setView] = useState('table');

  const [selectedRows, setSelectedRows] = useState([]);

  // Pagination shouldn't propagate to Explore, so we store in local state only
  const [pagination, setPagination] = useState();

  // Open text search shouldn't propagate to Explore, so we store in local state only
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 800);
  const [orderBy, setOrderBy] = useState();
  const [tdsBeingEdited, setTdsBeingEdited] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const fields = [
    'tds_id',
    'form',
    'form_code',
    'type',
    'type_code',
    'company_id',
    'company_name',
    'region_code',
    'region',
    'sku',
    'capacity_lbs',
    'supply_capacity_lbs'
  ];

  const agg_fields = ['city', 'state', 'country', 'lat', 'long', 'location_id'];

  const completeFilters = useMemo(
    () => ({
      ...filters,
      fields,
      agg_fields,
      augment_prices: {},
      page_size: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      page: pagination?.current || 1,
      search: debouncedSearch,
      ...(orderBy ? { order_by: orderBy } : {})
    }),
    [filters, debouncedSearch, pagination, orderBy]
  );

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useGetWarehouseTdss(completeFilters, {
      refetchOnMount: true
    });
  const queryKey = useGetWarehouseTdssKey(completeFilters);
  const queryClient = useQueryClient();
  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );
  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );
  const onPaginationChange = useCallback(
    (page, pSize) => {
      setPagination({
        current: page,
        pageSize: pSize,
        total: filteredRecords?.count,
        showSizeChanger: false
      });
    },
    [filteredRecords]
  );

  useEffect(() => {
    if (pagination && !pagination.onChange)
      setPagination((v) => ({ ...v, onChange: onPaginationChange }));
  }, [pagination]);

  useEffect(() => {
    setPagination((v) => ({
      current: v?.current || 1,
      pageSize: v?.pageSize || pageSize || DEFAULT_PAGE_SIZE,
      total: filteredRecords?.count,
      showSizeChanger: false
    }));
  }, [filteredRecords]);

  const tdsRecordCount = filteredRecords?.count || 0;

  useEffect(() => {
    setSelectedRows([]);
  }, [filters]);

  const download = () => {
    const allFilters = {
      ...filters,
      fields,
      agg_fields,
      all: 'true'
    };
    getTdsCsv(allFilters)
      .then((response) => response.text())
      .then((text) => {
        const blob = new Blob([text], { type: 'text/csv;charset=utf-8' });
        saveAs(
          blob,
          `technical-data-sheet-search-${dayjs().format(
            'YYYY-MM-DD-HH-mm' || ''
          )}.csv`
        );
      })
      .catch((error) =>
        messageApi.error(`Error downloading CSV file [${error.message}]`)
      );
  };

  const funnel = [
    {
      label: 'Sent',
      description: 'The number of suppliers that have been sent your RFQ.',
      count:
        filteredRecords?.meta_data?.project?.funnel_sent ||
        filteredRecords?.count ||
        0
    },
    {
      label: 'Opens',
      count: filteredRecords?.meta_data?.project?.funnel_opened || 0,
      rateName: 'open rate'
    },
    {
      label: 'Clicked',
      count: filteredRecords?.meta_data?.project?.funnel_clicked || 0,
      rateName: 'click-through rate'
    },
    {
      label: 'Responses',
      description: 'Suppliers who have responded to our outreach.',
      count: filteredRecords?.meta_data?.project?.funnel_responses || 0,
      rateName: 'response rate'
    },
    {
      label: 'Quotes',
      description: 'The number of quotes we have received from our outreach.',
      count: filteredRecords?.meta_data?.project?.funnel_quotes || 0,
      rateName: 'completed quotes'
    }
  ];

  const title = filteredRecords?.meta_data?.title || 'Search Results';

  const mapMarkers = useMemo(
    () =>
      filteredRecords?.results
        .map((o) => ({
          lat: o.lat,
          long: o.long,
          verified: 1,
          label: o.company_name
        }))
        .flatMap((o) =>
          Array.isArray(o.lat)
            ? o.lat.map((p, i) => ({
                verified: 1,
                lat: p,
                long: o.long[i],
                label: o.label
              }))
            : o
        ),
    [filteredRecords]
  );
  const referenceMarkers = useMemo(
    () =>
      filteredRecords?.meta_data?.project?.locations.map((o) => ({
        label: 'Destination',
        lat: o.latitude,
        long: o.longitude,
        verified: 1
      })),
    [filteredRecords]
  );

  const [showColumnsManager, setShowColumnsManager] = useState();
  const { TableComponent, ColumnsManager } = useTdsTable({
    tdss: filteredRecords?.results || [],
    project: filteredRecords?.meta_data?.project,
    isLoading: isLoadingFilteredRecords,
    setQueryData,
    invalidateQueryData,
    pagination,
    localSort: true
  });

  return (
    <div className="search-results">
      {contextHolder}
      <Typography.Title level={4} className="mt-lg mb-sm">
        {title}
      </Typography.Title>
      <OutreachResults funnel={funnel} />
      <Row className="search-results--controls" justify="space-between">
        <Col>
          <Input
            size="small"
            placeholder="Search prospects"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Space direction="horizontal" size={8}>
            <Button onClick={download} size="small">
              <DownloadOutlined />
            </Button>
            <Link
              to={`/project/explore/${filteredRecords?.meta_data?.project?.uuid}/`}
            >
              <Button size="small">
                <PlusOutlined /> Add more suppliers
              </Button>
            </Link>
            <Button
              onClick={() => setShowColumnsManager(!showColumnsManager)}
              size="small"
            >
              <SettingFilled /> Columns
            </Button>
            <Radio.Group
              onChange={(e) => setView(e.target.value)}
              value={view}
              size="small"
            >
              <Radio.Button value="table">
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value="map">
                <MapIcon />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
      {tdsBeingEdited && (
        <EditTdsModal
          open={!!tdsBeingEdited}
          showCompany
          showAdminLinks
          tdsId={tdsBeingEdited}
          hideModal={() => setTdsBeingEdited(null)}
        />
      )}

      {showColumnsManager && ColumnsManager}

      {view === 'table' ? (
        TableComponent
      ) : (
        <MapPageMarkers
          markers={mapMarkers}
          referenceMarkers={referenceMarkers}
        />
      )}
    </div>
  );
}

SearchContent.propTypes = {
  pageSize: PropTypes.number
};
