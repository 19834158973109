import PropTypes from 'prop-types';
import React from 'react';
import prettyNumber from 'src/components/utils/prettyNumber';

export default function TdsQuantity({ tds, greatestOnly }) {
  const supply_capacity = tds.supply_capacity_lbs;
  const capacity = tds.capacity_lbs || 0;
  if (!capacity && !supply_capacity) return undefined;

  const units = tds.capacity_units || '';

  if (greatestOnly)
    return (
      <span>
        {prettyNumber(Math.max(capacity, supply_capacity))} {units}
      </span>
    );

  return (
    <>
      <span>
        {prettyNumber(capacity)} {units}
      </span>
      <br />
      {supply_capacity && (
        <span className="subcontent">{prettyNumber(supply_capacity)}</span>
      )}
    </>
  );
}
TdsQuantity.propTypes = {
  tds: PropTypes.object,
  greatestOnly: PropTypes.bool
};
