export function valueWithCurrencyUnits(value, currency, units) {
  return {
    value,
    currency,
    units
  };
}

export function updateResultArrayAttribute(
  resultArray,
  indexOrFindFunc,
  attribute,
  value
) {
  const array = [...resultArray];

  const index =
    typeof indexOrFindFunc === 'function'
      ? array.findIndex(indexOrFindFunc)
      : indexOrFindFunc;

  array[index] = { ...resultArray[index] };
  array[index][attribute] = value;
  return array;
}

export function updateResultAttribute(
  result,
  indexOrFindFunc,
  attribute,
  value
) {
  if (!result) return result;
  if (Array.isArray(result.results)) {
    return {
      ...result,
      results: updateResultArrayAttribute(
        result.results,
        indexOrFindFunc,
        attribute,
        value
      )
    };
  }
  if (Array.isArray(result)) {
    return updateResultArrayAttribute(
      result,
      indexOrFindFunc,
      attribute,
      value
    );
  }
  return result;
}
