import { Col, Row } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Logo from 'src/components/Logo';

import './LandingMenu.less';

export default function LandingMenu({ items, light, mobile }) {
  const [open, setOpen] = useState(false);

  return (
    <Row
      className={`landing-menu ${light ? 'light' : ''} ${
        mobile ? 'mobile' : ''
      }`}
      justify="space-between"
      align="middle"
    >
      <Col className="landing-menu-logo-wrap">
        <Logo light={light} link="/#hero" />
      </Col>
      <Col>
        {mobile ? (
          <button
            className="bare hamburger"
            type="button"
            onClick={() => setOpen(true)}
          >
            <MenuOutlined />
          </button>
        ) : null}
        <motion.div
          initial={{ height: mobile ? 0 : 'auto' }}
          animate={{
            height: mobile && !open ? 0 : 'auto',
            transition: { ease: 'easeOut' }
          }}
          className="menu-items"
        >
          <div className="menu-items-inner">
            {mobile ? (
              <button
                className="bare close"
                type="button"
                onClick={() => setOpen(false)}
              >
                <CloseOutlined />
              </button>
            ) : null}
            {items.map((item) => (
              <a
                href={item.href}
                title={item.label}
                className={`menu-item ${item.class || ''}`}
                key={item.key}
              >
                {item.label}
              </a>
            ))}
          </div>
        </motion.div>
      </Col>
    </Row>
  );
}

LandingMenu.propTypes = {
  items: PropTypes.array,
  light: PropTypes.bool,
  mobile: PropTypes.bool
};
