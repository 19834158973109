import { LinkedinFilled, RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuthentication, useUser } from 'src/utils/authentication';
import { generalMenuItems, signedInMenuItems } from './LandingHeader';
import LandingMenu from './LandingMenu';

import './LandingFooter.less';

const signedOutMenuItems = [
  {
    label: (
      <>
        Sign in <RightOutlined />
      </>
    ),
    href: '/login',
    key: 'signIn',
    class: 'sign-in'
  }
];

export default function LandingFooter({ borderTop }) {
  const { isRestricted } = useAuthentication();
  const { data: user } = useUser();

  const menuItems = useMemo(
    () => [
      ...generalMenuItems,
      {
        label: 'Articles & Press',
        href: '/company#latest-news',
        key: 'pressKit'
      },
      ...(user && !isRestricted ? signedInMenuItems : signedOutMenuItems)
    ],
    [user]
  );

  return (
    <div id="landing-footer" className={borderTop ? 'border-top' : ''}>
      <div className="landing-footer-inner">
        <LandingMenu items={menuItems} />
        <Row
          justify="flex-start"
          align="middle"
          className="landing-footer-bottom"
        >
          <Col className="landing-footer-legal">
            <span>© 2023 Circular. All Rights Reserved.</span>
          </Col>
          <Col className="landing-footer-legal">
            <a title="Privacy Policy" href="/privacy">
              Privacy Policy
            </a>
          </Col>
          <Col className="landing-footer-legal">
            <a title="Terms & Conditions" href="/terms">
              Terms & Conditions
            </a>
          </Col>
          <Col className="landing-footer-social">
            <a
              href="https://www.linkedin.com/company/circularco"
              title="Circular.co LinkedIn"
              target="_blank"
            >
              <LinkedinFilled />
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}

LandingFooter.propTypes = {
  borderTop: PropTypes.bool
};
