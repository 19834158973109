export const reduceRegionalAnalyticsByRegion = (prev, curr) => {
  const { region_code, tdss, suppliers } = curr;
  const next = {
    ...prev
  };

  if (!(region_code in next)) {
    next[region_code] = {
      tdss: 0,
      suppliers: 0
    };
  }

  next[region_code].tdss += tdss;
  next[region_code].suppliers += suppliers;

  return next;
};

export const reduceRegionalAnalytics = (prev, curr) => {
  const { tdss, suppliers } = curr;

  const next = { ...prev };

  if (next.tdss === undefined) {
    next.tdss = 0;
    next.suppliers = 0;
  }

  next.tdss += tdss;
  next.suppliers += suppliers;

  return next;
};
