import { useEffect } from 'react';

// FIXME we can replace this with the useCalendlyEventListener from react-calendly
export default function useCalendlyCallback(callback) {
  useEffect(() => {
    const calendlyMsgListener = (e) => {
      if (e.data.event === 'calendly.event_scheduled') {
        const eventUri = e.data.payload.event.uri;
        const uuidMatches = eventUri.match(
          /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
        );
        const eventId = uuidMatches[0];
        callback(eventId);
        window.Calendly.closePopupWidget();
      }
    };

    window.addEventListener('message', calendlyMsgListener);

    return () => window.removeEventListener('message', calendlyMsgListener);
  });
}
