import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useUser } from 'src/utils/authentication';
import { Button, notification, Space } from 'antd';

export const CookiePolicyContext = createContext({});

/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME, I think the above eslint rule is wrong here

export const PolicyOptions = {
  NONE: 'none',
  ESSENTIAL: 'essential',
  FUNCTIONAL: 'functional',
  PERFORMANCE: 'performance'
};

export const PolicyOptionAllow = {
  none: () => true,
  essential: (option) => option !== PolicyOptions.NONE,
  functional: (option) =>
    option === PolicyOptions.FUNCTIONAL || option === PolicyOptions.PERFORMANCE,
  performance: (option) => option === PolicyOptions.PERFORMANCE
};

const COOKIE_SELECTED_NAME = 'cookie-policy-selected';

export function CookiePolicyProvider({ children }) {
  const [policySelection, setPolicySelection] = useState(PolicyOptions.NONE);
  const [currentNotificationKey, setCurrentNotificationKey] = useState();
  const [ready, setReady] = useState(false);
  const [cookies, setCookie] = useCookies([COOKIE_SELECTED_NAME]);
  const { data: user } = useUser();
  const select = (option, key) => {
    setPolicySelection(option);
    setCookie(COOKIE_SELECTED_NAME, option, { maxAge: 365 * 24 * 60 * 60 });
    notification.destroy(key);
    // setCurrentNotificationKey();
  };
  const allowCookie = (option) => {
    const f = PolicyOptionAllow[option];
    if (f) return f(policySelection);
    return false;
  };
  const cookieNotification = (key) => ({
    message: 'Cookie Policy',
    className: 'cookie-policy',
    // we put this on its own node, so that we can style (make it fit the full width of the screen)
    // without affecting other notifications
    getContainer: () => document.getElementById('circular-cookie-root'),
    description: (
      <>
        <div className="cookie-policy-body">
          <p>
            This site uses cookies to store information on your computer. Some
            of these cookies are essential to make our site work and others help
            us to improve by giving us some insight into how the site is being
            used.
          </p>
          <p>
            By using our site you accept the terms of our{' '}
            <a href="/privacy">Privacy Policy</a>.
          </p>
        </div>
        <Space direction="vertical" wrap>
          <Button
            onClick={() => select(PolicyOptions.PERFORMANCE, key)}
            type="primary"
          >
            Accept all cookies
          </Button>
          <Button onClick={() => select(PolicyOptions.ESSENTIAL, key)}>
            Accept essential cookies only
          </Button>
        </Space>
      </>
    ),
    duration: 0,
    placement: 'bottomRight',
    key
  });

  const makeSelection = () => {
    if (!user && policySelection === PolicyOptions.NONE) {
      const key = (Math.random() + 1).toString(36).substring(2);
      notification.destroy(currentNotificationKey);
      notification.open(cookieNotification(key));
      setCurrentNotificationKey(key);
    }
  };
  useEffect(() => {
    const elected = cookies[COOKIE_SELECTED_NAME] || PolicyOptions.NONE;
    setPolicySelection(elected);
    // allow time for authentication information to load before making decision
    setTimeout(() => setReady(true), 5000);
  }, []);
  useEffect(() => {
    if (ready) makeSelection();
  }, [ready]);
  return (
    <CookiePolicyContext.Provider
      value={{
        allowCookie,
        policySelection
      }}
    >
      {children}
    </CookiePolicyContext.Provider>
  );
}

CookiePolicyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
