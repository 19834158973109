import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const header = [
  'Expand Your Search',
  'Negotiate With Confidence',
  'Manage Your Project'
];

const description = [
  'Using our expansive supplier network, our team will curate a custom proposal of supplier that best fit your project.',
  'Once your supplier search is complete, select which suppliers you want to negotiate with. Use Procure to organize supplier compliance, track lab and production tests, and manage contracting with your suppliers.',
  'After contracts have been signed, monitor your shipments, report on CO2e, and manage contingency buying in one place.'
];

export default function UpsellModal({ active = 0 }) {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <Modal
      open={modalOpen}
      footer={null}
      closeIcon={null}
      className="upsell-modal dark"
      centered
      width={565}
      mask={false}
      onCancel={() => setModalOpen(false)}
    >
      <div className="stages">
        <div className={`stage ${active === 0 ? 'active' : ''}`}>Search</div>
        <div className="divider" />
        <div className={`stage ${active === 1 ? 'active' : ''}`}>Procure</div>
        <div className="divider" />
        <div className={`stage ${active === 2 ? 'active' : ''}`}>Manage</div>
      </div>
      <h2>{header[active]}</h2>
      <p>{description[active]}</p>
      <Button
        onClick={() => setModalOpen(false)}
        type="primary"
        htmlType="button"
      >
        View Demo
      </Button>
    </Modal>
  );
}

UpsellModal.propTypes = {
  active: PropTypes.number
};
