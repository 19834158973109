import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Space, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleTokenPair } from 'src/utils/authentication';
import { fetch } from 'src/utils/client';
import BackgroundPageLayout from 'src/components/layout/BackgroundPageLayout';
import { policyDescription } from './policy';
import { DASHBOARD_URL } from './SignIn';

// labels above controls
// https://github.com/ant-design/ant-design/issues/14063

function ResetPassword({ next }) {
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get('email');
  const codeParam = searchParams.get('code');
  const [error, setError] = useState();
  const navigate = useNavigate();
  const onChangePassword = async ({ email, code, password }) => {
    try {
      const { token } = await fetch(
        `${process.env.REACT_APP_CIRCLE_API}/auth/token/`,
        {
          method: 'POST',
          body: JSON.stringify({ token: code, email })
        }
      );

      const json = await fetch(
        `${process.env.REACT_APP_CIRCLE_API}/user/reset-password/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`
          },
          body: JSON.stringify({ password })
        }
      );

      handleTokenPair(json);

      if (next) {
        navigate(next, { state: { ignore: true } });
      } else {
        navigate(DASHBOARD_URL, { state: { reset: true } });
      }
    } catch (ex) {
      window.console.error('Reset password error: ', ex);
      setError(
        ex?.message || ex?.error || ex?.detail || 'Error resetting password'
      );
    }
  };
  const onFail = (errorInfo) => {
    window.console.error('Reset Password failed:', errorInfo);
  };

  return (
    <BackgroundPageLayout>
      <Space style={{ maxWidth: '420px' }} align="center" direction="vertical">
        {error && <div className="error">{error}</div>}

        <Form
          className="reset-password-form auth-form"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onChangePassword}
          onFinishFailed={onFail}
          autoComplete="off"
        >
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Typography.Title level={3}>Reset Password</Typography.Title>

            <Typography.Paragraph>
              Please check your email for the confirmation code that we have
              sent you. Enter the confirmation code and a new password below.
            </Typography.Paragraph>
            <div>
              <Form.Item
                label="Company Email"
                name="email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValue={emailParam}
              >
                <Input readOnly={!!emailParam} disabled={!!emailParam} />
              </Form.Item>
              <Form.Item
                label="Confirmation Code"
                name="code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValue={codeParam}
              >
                <Input readOnly={!!codeParam} disabled={!!codeParam} />
              </Form.Item>
              <Form.Item
                label="New Password"
                help={policyDescription}
                name="password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.Password />
              </Form.Item>
              {error && <p className="error">{error}</p>}
            </div>
            <Button type="primary" htmlType="submit">
              Set Password
            </Button>
          </Space>
        </Form>
      </Space>
    </BackgroundPageLayout>
  );
}

ResetPassword.propTypes = {
  next: PropTypes.string
};

export default ResetPassword;
