import React from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import FormPageLayout from 'src/components/layout/FormPageLayout';

export default function GetStarted() {
  return (
    <FormPageLayout className="get-started" back>
      <Row align="center" className="explanation-container">
        <Col>
          <Typography.Title level={2}>
            What would you like to do first?
          </Typography.Title>
          <Typography.Text subtitle>
            You can buy, sell or explore other solutions.
          </Typography.Text>
        </Col>
      </Row>
      <Row justify="space-around" className="go-to-container">
        <Col>
          <Link to="/request-for-information">
            <Button
              type="text"
              name="buy"
              color="primary"
              className="go-to-buy-button"
            >
              <Space className="go-to-buy-button-content">
                <Typography.Title level={3}>Buy</Typography.Title>
                <Typography.Text>
                  Create a Request for Quote (RFQ)
                </Typography.Text>
              </Space>
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to="/request-for-information">
            <Button
              type="text"
              name="sell"
              color="primary"
              className="go-to-sell-button"
            >
              <Space className="go-to-sell-button-content">
                <Typography.Title level={3}>Sell</Typography.Title>
                <Typography.Text>Connect with buyers</Typography.Text>
              </Space>
            </Button>
          </Link>
        </Col>
      </Row>
    </FormPageLayout>
  );
}
