import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Select, Skeleton } from 'antd';
import usePlacesAutocomplete from 'use-places-autocomplete';

import {
  parseApiLocation,
  parseInternalLocation,
  parseGoogleAddress
} from './LocationInput';

const { Option } = Select;

/**
 * Shape of the value of this component:
 * {
 *         address
 *         latitude
 *         longitude
 *         zip
 *         country
 *         street_one
 *         state
 *         city
 *         internal_location
 * }
 */

// Currently only works as a managed component (onChange and value must be used)
function MultipleLocationInput({
  value: valueIn,
  onChange,
  placeholder,
  ...props
}) {
  const {
    suggestions: { loading, data, status },
    setValue
  } = usePlacesAutocomplete({
    debounce: 300
  });

  const [inputText, setInputText] = useState();

  useEffect(() => {
    if (!Array.isArray(valueIn) || valueIn.length === 0) setInputText();
  }, [valueIn]);

  const handleInput = (address) => {
    setInputText(address);
    setValue(address);
  };

  const hasPlaceId = (placeId) =>
    Array.isArray(valueIn) &&
    valueIn.find((location) => location.placeId === placeId);

  const handleSelect = async (address) => {
    try {
      const location = await parseGoogleAddress(address);
      if (!hasPlaceId(location.placeId)) {
        const newValue = (Array.isArray(valueIn) && [...valueIn]) || [];
        newValue.push(location);
        onChange(newValue);
        setInputText();
        setValue();
      }
    } catch (ex) {
      window.console.error('Error calling google API');
    }
  };

  const removeLocation = (value) => {
    const [_cityOrCountry, index] = value.split('-');
    const newValue = Array.isArray(valueIn) ? [...valueIn] : [];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <div className="multiple-location-input">
      <Select
        showSearch
        placeholder={placeholder}
        // We add index to value to allow ford uplicate cities / countries
        value={valueIn?.map((loc, i) => `${loc.city || loc.country}-${i}`)}
        // eslint-disable-next-line react/no-unstable-nested-components
        tagRender={({ value }) => (
          <div className="ant-select-selection-overflow-item">
            <span title="rPET" className="ant-select-selection-item">
              <span className="ant-select-selection-item-content">
                {value?.split('-')?.[0]}
              </span>
              <span
                onClick={() => removeLocation(value)}
                className="ant-select-selection-item-remove"
                unselectable="on"
                aria-hidden="true"
                style={{ userSelect: 'none' }}
              >
                <CloseOutlined />
              </span>
            </span>
          </div>
        )}
        searchValue={inputText}
        onSearch={handleInput}
        onSelect={handleSelect}
        onDeselect={removeLocation}
        className="location-input"
        mode="multiple"
        loading={loading}
        notFoundContent={
          !inputText ? (
            'Start typing...'
          ) : status === 'ZERO_RESULTS' ? (
            'No addresses found'
          ) : (
            <Skeleton active paragraph={false} />
          )
        }
        options={data.map((o) => ({
          value: o.description,
          label: o.description
        }))}
        {...props}
      ></Select>
    </div>
  );
}

MultipleLocationInput.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

export default MultipleLocationInput;
export { parseInternalLocation, parseApiLocation };
