import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getRfqProject } from 'src/Query';
import { Alert, Skeleton, Space, Typography } from 'antd';
import { LockFilled, TagFilled } from '@ant-design/icons';
import TabsWithRoutes from 'src/components/tabs/TabsWithRoutes';
import SupplierAvatar from 'src/pages/rfq/proposal/supply/SupplierAvatar';
import LogisticsIcon from 'src/components/icons/LogisticsIcon';
import ChartIcon from 'src/components/icons/ChartIcon';
import MapMarkerIcon from 'src/components/icons/MapMarkerIcon';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { currencyValuePrice } from 'src/utils/currency';
import ProjectDetailsSidebar from './ProjectDetailsSidebar';
import ManageOverviewTab from './ManageOverviewTab';
import ManageReportsTab from './ManageReportsTab';
import ManageLogisticsTab from './ManageLogisticsTab';

const formatLocation = (location) => {
  if (location.city && location.country)
    return `${location.city}, ${location.country}`;
  if (location.city) return location.city;
  if (location.country) return location.country;
  return '';
};

function ProjectManage() {
  const { rfqId, tab } = useParams();
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);

  const { pushCascade, popCascade } = useSidebarContext();

  useEffect(() => {
    pushCascade(undefined, 'manage', 0);
    return () => popCascade('manage');
  }, []);
  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  if (isLoading) return <Skeleton />;
  if (error)
    return (
      <Alert
        type="error"
        showIcon
        description={error.message || 'Not found.'}
      />
    );

  const { projects, request_for_quote: requestForQuote } = rfqProject;

  const activeProject = projects[activeProjectIndex];
  const deal = activeProject?.deal;
  const supplier = deal?.suppliers[0];
  const locations = (requestForQuote?.shipping_addresses || [])
    .map((o) => formatLocation(o))
    .join('/');

  return (
    <div className="project-manage">
      <ProjectDetailsSidebar
        requestForQuote={requestForQuote}
        projects={projects}
        activeProjectIndex={activeProjectIndex}
        setActiveProjectIndex={setActiveProjectIndex}
      >
        <div className="project-manage--body">
          <div className="project-manage--supplier-details">
            <Space>
              <SupplierAvatar supplier={supplier} />
              <Typography.Title level={2}>{supplier.name}</Typography.Title>
            </Space>
            <div className="supplier-metrics">
              <Space size="large">
                <span>
                  <ChartIcon />
                  &nbsp;
                  {currencyValuePrice(deal.price_per_lb, deal.currency, 'lb')}
                </span>
                <span>
                  <LogisticsIcon />
                  &nbsp;
                  {currencyValuePrice(
                    deal.shipping_cost / deal.quantity_lbs,
                    deal.currency,
                    'lb'
                  )}
                </span>
                <span>
                  <TagFilled />
                  &nbsp;
                  {currencyValuePrice(
                    deal.shipping_cost + deal.price_per_lb * deal.quantity_lbs,
                    deal.currency,
                    'lb'
                  )}
                </span>
                <span>
                  <MapMarkerIcon />
                  &nbsp;{locations}
                </span>
              </Space>
            </div>
          </div>
          <TabsWithRoutes
            activeKey={tab}
            items={[
              {
                key: 'overview',
                label: <>Overview</>,
                children: (
                  <ManageOverviewTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'reports',
                label: <>Reports</>,
                children: (
                  <ManageReportsTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'logistics',
                label: <>Logistics</>,
                children: (
                  <ManageLogisticsTab
                    requestForQuote={requestForQuote}
                    project={activeProject}
                  />
                )
              },
              {
                key: 'tracktrace',
                label: (
                  <>
                    <LockFilled />
                    &nbsp;Track and Trace
                  </>
                ),
                disabled: true,
                children: undefined
              }
            ]}
          />
        </div>
      </ProjectDetailsSidebar>
    </div>
  );
}

export default ProjectManage;
