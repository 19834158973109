import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

// must match models/shared.py Frequency
const options = [
  { value: 1, label: '1m' },
  { value: 3, label: '3m' },
  { value: 6, label: '6m' },
  { value: 12, label: '1y' },
  { value: 24, label: '2y' },
  { value: 60, label: '5y' }
];

function PeriodSelect({ value, onChange }) {
  return (
    <Select value={value} onChange={onChange}>
      {options.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}

PeriodSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default PeriodSelect;
