import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Button, Spin } from 'antd';
import Logo from 'src/components/Logo';
import { getCompanies, getRFQs } from 'src/Query';
import CompanyAdmin from 'src/components/company/CompanyAdmin';
import CompanySalesflareLink from 'src/components/company/CompanySalesflareLink';
import RfqOpportunityLink from 'src/components/rfq/RfqOpportunityLink';
import RfqAdmin from 'src/components/rfq/RfqAdmin';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

function Account({ id }) {
  const queryClient = useQueryClient();
  const { data: companyList, isLoading: isCompanyLoading } = useQuery(
    ['company', id],
    () => getCompanies({ salesflare_id: id }),
    { retry: false, enabled: !!id }
  );
  const refresh = () =>
    queryClient.invalidateQueries({ queryKey: ['company', id] });
  const company = Array.isArray(companyList?.results) && companyList.results[0];
  if (isCompanyLoading) return <Spin />;
  if (company) return <CompanyAdmin company={company} />;
  return <CompanySalesflareLink onChange={refresh} salesflareId={id} />;
}

Account.propTypes = {
  id: PropTypes.string
};

function Opportunity({ id }) {
  const queryClient = useQueryClient();
  const { data: rfqList, isLoading: isCompanyLoading } = useQuery(
    ['rfq', id],
    () => getRFQs({ opportunity_id: id }),
    { retry: false, enabled: !!id }
  );
  const refresh = () =>
    queryClient.invalidateQueries({ queryKey: ['rfq', id] });
  const rfq = Array.isArray(rfqList?.results) && rfqList.results[0];
  if (isCompanyLoading) return <Spin />;
  if (rfq) return <RfqAdmin rfq={rfq} />;
  return <RfqOpportunityLink onChange={refresh} opportunityId={id} />;
}

Opportunity.propTypes = {
  id: PropTypes.string
};

function ChromeExtension() {
  const [child, setChild] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [searchParams] = useSearchParams();
  const href = searchParams.get('href');

  const resize = (active, collapsedIn) => {
    const activeWidth = collapsedIn ? '16px' : '320px';
    const message = JSON.stringify({
      active,
      width: active ? activeWidth : '0px'
    });
    window.parent.postMessage(message, '*');
  };

  const activate = (active) => {
    resize(active, collapsed);
  };

  const pageMap = [
    {
      pathRegexp: /#\/accounts\/([0-9]*)/,
      Component: Account,
      defaultCollapsed: false
    },
    {
      pathRegexp: /#\/opportunities\/[a-z]*\/([0-9]*)/,
      Component: Opportunity,
      defaultCollapsed: true
    }
  ];

  const toggleCollapse = () => {
    const updatedCollapsed = !collapsed;
    setCollapsed(updatedCollapsed);
    resize(true, updatedCollapsed);
  };

  useEffect(() => {
    const h = decodeURIComponent(href);
    try {
      const u = new URL(h);
      const { hash } = u;
      const rexp = /#\/accounts\/([0-9]*)/;
      if (hash) {
        let HandlerComponent;
        pageMap.every((item) => {
          const match = hash.match(item.pathRegexp);
          if (match) {
            window.console.log('matched', match);
            HandlerComponent = <item.Component id={match[1]} />;
            setCollapsed(item.defaultCollapsed);
            return false;
          }
          return true;
        });
        if (HandlerComponent) {
          setChild(HandlerComponent);
          activate(true);
        } else {
          setChild(null);
          activate(false);
        }
      }
    } catch (ex) {
      window.console.log(ex);
      activate(false);
      setChild(null);
    }
  }, [href]);
  return (
    <div className="chrome-extension">
      <div className="fixed-buttons">
        <Button type="bare" onClick={toggleCollapse}>
          {(collapsed && <CaretLeftOutlined />) || <CaretRightOutlined />}
        </Button>
      </div>
      <Logo link="/chrome-extension" />
      {child}
    </div>
  );
}

export default ChromeExtension;
