import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/utils/authentication';
import { isConcierge } from 'src/utils/permissions';
import { DASHBOARD_URL } from './SignIn';

export default function Lounge() {
  const { data: user, isLoading, isFetched } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;
    if (!user && isFetched) navigate('/login');

    if (isConcierge(user)) {
      navigate('/concierge');
    } else {
      navigate(DASHBOARD_URL);
    }
  }, [user, isLoading, isFetched]);

  return undefined;
}
