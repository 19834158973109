export const virginType = (type) => type.replace(/^r/, '');

// to customize properties that must be added by type and other properties
export const propertyByType = {
  rPP: (codes) =>
    // we always want homopolymer for rPP, but not applicable for Bales
    codes.includes('form.Bale') || codes.includes('polymer-type.')
      ? []
      : ['polymer-type.Homopolymer']
};

export const materialPropertyCodesForType = (type, codes) => {
  const propFn = propertyByType[type];
  const extra = (propFn && propFn(codes)) || [];
  const codesList = [`type.${type}`, codes, ...extra];
  return codesList.join(',');
};
