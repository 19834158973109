import React from 'react';
import PropTypes from 'prop-types';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';

export function useBuyerName(buyer) {
  const value = useConciergeContext(['buyer', 'names']);

  return value === 'anonymize' ? buyer.anonymized_name : buyer.name;
}

export default function BuyerName({ buyer }) {
  const companyName = useBuyerName(buyer);

  return <span className="company-name">{companyName}</span>;
}

BuyerName.propTypes = {
  buyer: PropTypes.shape({
    name: PropTypes.string,
    anonymized_name: PropTypes.string
  })
};
