import { CheckCircleFilled } from '@ant-design/icons';
import { Row, Col, Typography, Button, Space, Skeleton } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from 'src/components/Logo';
import { useUser } from 'src/utils/authentication';

export default function DigitalRfqSubmitted() {
  const { data: user } = useUser();
  const navigate = useNavigate();

  if (!user) return <Skeleton active />;

  return (
    <div className="digital-rfq">
      <Row
        className="digital-rfq--header"
        justify="center"
        align="middle"
        wrap={false}
      >
        <Col>
          <Logo />
        </Col>
      </Row>
      <Row className="digital-rfq--body" justify="center" align="middle">
        <Col className="digital-rfq--confirmation">
          <div className="digital-rfq--confirmation--inner">
            <Typography.Title level={1}>
              <CheckCircleFilled style={{ color: '#52C41A' }} /> Your response
              has been sent!
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              We sent a confirmation to your inbox for review.
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary">
              <Button
                onClick={() => navigate(-1)}
                type="link"
                htmlType="button"
              >
                Click here to submit another TDS
              </Button>
            </Typography.Paragraph>
            {!user.has_submitted_intake ? (
              <div className="color-card color-card--info">
                <Typography.Title level={3}>
                  Want to be discovered on the Circular platform?
                </Typography.Title>
                <Typography.Paragraph type="secondary">
                  Creating a supplier profile will allow you to get discovered
                  by more buyers on the Circular platform!
                </Typography.Paragraph>
                <Space direction="vertical" size={8}>
                  <Link to="/supplier/onboarding">
                    <Button size="large" type="primary">
                      Create a Supplier Profile
                    </Button>
                  </Link>
                  <Link to="https://circular.co/">
                    <Button size="large" type="text">
                      Learn more
                    </Button>
                  </Link>
                </Space>
              </div>
            ) : (
              <Link to={`/supplier/${user?.company?.uuid}`}>
                <Button size="large" type="primary">
                  Go to my profile
                </Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
