import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'antd';

// A Timeline.Item currently requires two children that
// that are the left and right component of the timeline item
// respectively. Arguably should use render props, but this
// seems simple for the current case
//
// offset is a vertical offsets for the first child
//
// the responsive layouts are quite varied, and my css-fu was
// not strong enough to do it all in flex, so there are
// changes to the html for less than md breakpoints

function ChildComponents({ children, offset, alternate }) {
  const left = Array.isArray(children);
  const leftChild = (left && children[0]) || undefined;
  const rightChild = (left && children[1]) || children;
  return (
    <>
      {leftChild && (
        <div className="timeline-item-content-left" style={{ top: offset }}>
          {leftChild}
        </div>
      )}
      <div
        className={`timeline-item-content-right ${
          alternate ? 'alternate' : ''
        }`}
      >
        {rightChild}
      </div>
    </>
  );
}

ChildComponents.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  offset: PropTypes.number,
  alternate: PropTypes.bool
};

function TimelineItem({ children, offset, alternate, heading, inactive }) {
  const breakpoint = Grid.useBreakpoint();
  const stacked = breakpoint.xs || !breakpoint.md;
  return (
    <li
      className={`timeline-item  ${heading ? 'heading' : ''} ${
        stacked ? 'stacked' : ''
      } ${alternate ? 'alternate' : ''}`}
    >
      {stacked ? (
        <div className="stack-container">
          <ChildComponents alternate={alternate}>{children}</ChildComponents>
        </div>
      ) : (
        <ChildComponents offset={offset} alternate={alternate}>
          {children}
        </ChildComponents>
      )}
      <div className="line-container">
        <div
          className={`timeline-item-tail ${heading ? 'no-head' : 'head'} ${
            inactive ? 'inactive' : ''
          }`}
        ></div>
        {!heading && (
          <div
            className={`timeline-item-head ${inactive ? 'inactive' : ''}`}
          ></div>
        )}
      </div>
    </li>
  );
}

TimelineItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  offset: PropTypes.number,
  heading: PropTypes.bool,
  inactive: PropTypes.bool,
  alternate: PropTypes.bool
};

function Timeline({ children }) {
  return <ul className="timeline">{children}</ul>;
}

Timeline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Timeline.Item = TimelineItem;

export default Timeline;
