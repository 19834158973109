import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Col, ConfigProvider, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { getExploreSummary } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

export const BLEND = 'blend';
export const PCR = 'pcr';
export const ALL = 'all';

const BLEND_BREAKDOWN = [
  { id: BLEND, kpis: { PCRBV: { min: 30, max: 99.99899 } } },
  { id: PCR, kpis: { PCRBV: { min: 99.999, max: 100, allow_null: true } } }
];

const DEFAULT_BREAKDOWN = [
  { id: ALL } // No breakdown, just return complete count for each KPI
];

export function useSummaryData({ breakdownFilters, queryProps } = {}) {
  const { properties: _, ...filters } = useRelevantFiltersForForm();
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  const summaryFilters = useMemo(
    () => ({
      summary_options: {
        breakdown_filters: breakdownFilters || DEFAULT_BREAKDOWN,
        broad_suppliers: true,
        ...projectSettings?.summary_options
      },
      ...filters
    }),
    [filters]
  );

  return useDebouncedQuery(
    ['explore-summary', summaryFilters],
    () => getExploreSummary(summaryFilters),
    { ...(queryProps || {}), keepPreviousData: true }
  );
}

export function useKpiSummaryData({ kpiCode, ...summaryProps } = {}) {
  const { data } = useSummaryData(summaryProps);
  return useMemo(() => data?.kpis?.[kpiCode], [data]);
}

export function ViabilityIconBox({ recordsCount, ...props }) {
  const type = recordsCount >= 3 ? 'success' : 'danger';
  const icon = recordsCount >= 3 ? <CheckCircleFilled /> : <InfoCircleFilled />;

  return (
    <Tooltip
      title={`${recordsCount} record${recordsCount === 1 ? '' : 's'}`}
      {...props}
    >
      <Box type={type}>{icon}</Box>
    </Tooltip>
  );
}
ViabilityIconBox.propTypes = {
  recordsCount: PropTypes.number
};

export function Box({ children, type, ...props }) {
  return (
    <div
      {...props}
      className={`explore-summary--value-box ${type} ${props.className || ''}`}
    >
      {children}
    </div>
  );
}
Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string
};

export function BoxColumn({ children, width, span, style, ...props }) {
  return (
    <Col
      span={span}
      flex={`0 0 ${width || 90}px`}
      style={{
        textAlign: 'center',
        lineHeight: 1,
        minWidth: width || 90,
        maxWidth: width || 90,
        ...style
      }}
      {...props}
    >
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12
          }
        }}
      >
        {children}
      </ConfigProvider>
    </Col>
  );
}
BoxColumn.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  span: PropTypes.number,
  style: PropTypes.object
};

export function HistogramColumn({ children }) {
  return (
    <Col flex="0 0 308px">
      <ConfigProvider
        theme={{
          token: {
            fontSize: 12
          }
        }}
      >
        {children}
      </ConfigProvider>
    </Col>
  );
}
HistogramColumn.propTypes = {
  children: PropTypes.node
};
