import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getVerifiedCertification } from 'src/Query';
import { useParams } from 'react-router-dom';
import Page from 'src/components/layout/Page';
import { Alert, Skeleton } from 'antd';
import Certificate from './Certificate';

function VerifiedCertificationPage() {
  const { id } = useParams();
  const {
    data = {},
    isLoading,
    error
  } = useQuery(['verifiedCertification', id], () =>
    getVerifiedCertification(id)
  );

  return (
    <Page className="veirified-certification-page">
      {error && (
        <Alert
          message="An error occurred while fetching the certificate."
          description={error}
          type="error"
          closable
        />
      )}
      {isLoading ? <Skeleton /> : data && <Certificate data={data.body} />}
    </Page>
  );
}

export default VerifiedCertificationPage;
