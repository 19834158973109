import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Skeleton, Typography } from 'antd';

import { useParams } from 'react-router-dom';
import DarkPanel from 'src/components/layout/DarkPanel';
import { RightOutlined } from '@ant-design/icons';
import Task from './Task';

export default function ProcureOverviewTab({ project, requestForQuote }) {
  const { rfqId } = useParams();

  // const { search: upNextSearch } = useUpNext(requestForQuote, project);

  if (!project || !requestForQuote) return <Skeleton />;

  return (
    <div className="overview-tab">
      <Row gutter={18} style={{ alignItems: 'stretch' }}>
        <Col span={24}>
          <DarkPanel className="project-task-list" style={{ height: '100%' }}>
            <div className="task-list-header">
              <Typography.Title level={4}>My Tasks</Typography.Title>
              <span className="task-count">8 tasks</span>
            </div>
            <Task
              title="Order samples for Plastic Bank production tests"
              danger
              due="Overdue"
              subtitle="rPP Crates - PROD 2024"
            />
            <Task
              title="Select testing lab for Terni Polimeri samples"
              due="Today"
              subtitle="rPP Bulk Containers - PROD 2024"
            />
            <Task
              title="Review Beologic Contract - V3"
              due="Due Friday"
              subtitle="rPP Battery Cases - PROD2025"
            />
            <Button type="bare">
              View 5 more tasks <RightOutlined />
            </Button>
          </DarkPanel>
        </Col>
      </Row>
    </div>
  );
}

ProcureOverviewTab.propTypes = {
  requestForQuote: PropTypes.object,
  project: PropTypes.object
};
