import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProjectQuotes from 'src/pages/project/ProjectQuotes';
import BuyerDigitalRfq from 'src/pages/project/BuyerDigitalRfq';
import ConciergeContextProvider from 'src/components/concierge/ConciergeContext';
import SupplierList from 'src/components/project/source/SupplierList';
import ProjectSourceWorkflow from 'src/pages/project/ProjectSourceWorkflow';

export default function SourceRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<SourceSubroutes />} />
    </Routes>
  );
}

function SourceSubroutes() {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { getProjectbyId } = useSidebarContext();

  const project = getProjectbyId(projectId);

  if (!project || project.uuid !== projectId) return <Skeleton />;

  const filtersForWorkflow = {
    ...(project?.workflow?.['prioritize-suppliers']?.filters ||
      project?.filters ||
      {})
  };

  const drfqSent = project && ['procure', 'manage'].includes(project.stage);
  const isQuotesPage = pathname.includes('/quotes');

  const projectKey = isQuotesPage
    ? { id: projectId, use_filters: true }
    : undefined;

  if (projectKey) {
    // remove all filters that filter the result set because we are filtering on suppliers/
    // tds in the project
    if (filtersForWorkflow.kpis) {
      Object.keys(filtersForWorkflow.kpis).forEach((code) => {
        filtersForWorkflow.kpis[code] = { allow_null: true };
      });
    }
    if (filtersForWorkflow.properties) {
      Object.keys(filtersForWorkflow.properties).forEach((code) => {
        filtersForWorkflow.properties[code] = [];
      });
    }
    if (filtersForWorkflow.form_code__in) {
      filtersForWorkflow.form_code__in = [];
    }
    if (filtersForWorkflow.type_code__in) {
      filtersForWorkflow.type_code__in = [];
    }
    if (filtersForWorkflow.region_code__in) {
      filtersForWorkflow.region_code__in = [];
    }
    filtersForWorkflow.kpi_operator = 'none';
  }
  return (
    <ConciergeContextProvider
      values={{
        explore: {
          filters: {
            ...filtersForWorkflow,
            project: projectKey
          }
        }
      }}
    >
      <div className="project-source">
        <Row wrap={false} className="individually-scrolling-cols">
          <Col className="project-source--sidebar">
            <ProjectSourceWorkflow projectId={projectId} />
          </Col>
          <Col className="project-source--content">
            <Routes>
              <Route
                path="/prioritize-suppliers"
                element={<SupplierList locked={drfqSent} />}
              />
              <Route
                path="/buyer-digital-rfq"
                element={
                  <BuyerDigitalRfq project={project} locked={drfqSent} />
                }
              />
              <Route path="/quotes" element={<ProjectQuotes />} />
            </Routes>
          </Col>
        </Row>
      </div>
    </ConciergeContextProvider>
  );
}
