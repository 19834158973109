import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@tanstack/react-query';
import { submitCarbonCreditInformationRequest } from 'src/Mutation';

import SplashPage from 'src/components/layout/SplashPage';
import SplashBanner from 'src/components/splash/SplashBanner';
import SplashContent from 'src/components/splash/SplashContent';
import TextArea from 'antd/lib/input/TextArea';
import SidebarSteps from 'src/components/layout/sidebar/SidebarSteps';
import BackgroundImage from 'src/images/windfarm.jpg';
import CompanyType from 'src/components/form/CompanyType';
import { useUser } from 'src/utils/authentication';

const sidebarSteps = [
  { title: 'Submit RFQ' },
  { title: 'Select matching seller, test & complete purchase' },
  {
    title: 'Measure the value of your eligible credits with easy platform tools'
  },
  { title: 'Monetize your ESG credits in a rebate after purchase *' },
  { title: 'Report avoided carbon emissions' }
];

function EsgCredits() {
  const [form] = useForm();
  const { data: user } = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate, isLoading } = useMutation({
    mutationFn: submitCarbonCreditInformationRequest,
    onError: (e) => {
      const errorMessage = `An error occurred. Please talk to the concierge directly [${e.message}]`;
      messageApi.error(errorMessage);
    },
    onSuccess: () =>
      messageApi.success('We have noted your interest and will be in touch')
  });

  const onFinish = async (values) => {
    const body = {
      company_type: values.companyType,
      title: values.jobTitle,
      additional_information: values.additionalInformation,
      requesting_user: user?.id
    };
    mutate(body);
  };

  return (
    <SplashPage className="credits banner">
      <div className="splash-container">
        <SplashBanner
          title="Explore ESG Credits"
          subtitle="We monetize your carbon and plastic credits for you, saving you an average of 10% on project costs."
          footer="* Can be either internal (transfer of carbon credit purchase) or external on standards markets"
          backgroundImage={`linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, rgba(24, 57, 23, 0.6), rgba(24, 57, 23, 0.6)), url(${BackgroundImage})`}
        >
          <SidebarSteps steps={sidebarSteps} active={0} />
        </SplashBanner>
        <SplashContent>
          {contextHolder}
          <div className="splash-content-body">
            <div className="sc-header">Get Started</div>
            <div className="sc-subheader">
              Contact us to start monetizing your ESG credits.
            </div>
            <Form
              name="credits-form"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={() =>
                message.error('Please complete the fields with errors')
              }
              autoComplete="off"
            >
              <Form.Item
                label="Company Type"
                name="companyType"
                rules={[
                  { required: true, message: 'Company Type is required' }
                ]}
              >
                <CompanyType />
              </Form.Item>
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[{ required: true, message: 'Job Title is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Additional Information"
                name="additionalInformation"
              >
                <TextArea
                  placeholder="Please provide any additional information regarding your outreach to Circular today."
                  maxLength={2048}
                  rows={4}
                />
              </Form.Item>
              <Button
                loading={isLoading}
                shape="round"
                type="primary"
                htmlType="submit"
              >
                Connect with a specialist
              </Button>
            </Form>
          </div>
        </SplashContent>
      </div>
    </SplashPage>
  );
}

export default EsgCredits;
