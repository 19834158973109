import { useQuery } from '@tanstack/react-query';
import { getTdsPriceInsights } from 'src/Query/index';

export function useTdsPriceInsights(uuid, locationId) {
  return useQuery(
    ['tdsPriceInsights', uuid],
    () => getTdsPriceInsights(uuid, locationId),
    {
      retry: false,
      enabled: !!uuid
    }
  );
}
