import React from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    if (delay > 0) {
      const handler = setTimeout(() => {
        if (valueHasChanged(debouncedValue, value)) setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }
    return undefined;
  }, [value, delay]);

  if (delay === 0) return value;

  return debouncedValue;
}

function valueHasChanged(oldVal, newVal) {
  if (typeof oldVal === 'object' && typeof newVal === 'object') {
    return JSON.stringify(oldVal) !== JSON.stringify(newVal);
  }

  return oldVal !== newVal;
}
