import React, { useMemo } from 'react';

import { useAuthentication, useUser } from 'src/utils/authentication';
import SimpleHeader from 'src/components/header/SimpleHeader';
import { useNotification } from 'src/components/notification/NotificationContext';
import { isConcierge } from 'src/utils/permissions';
import { DASHBOARD_URL } from 'src/pages/auth/SignIn';

function Header() {
  const { signOut, isRestricted } = useAuthentication();
  const { data: user } = useUser();
  const { count: countNotification } = useNotification();
  const unreadCount = countNotification(['chat', 'unread']);
  const concierge = isConcierge(user);

  // these are the antd menu items, with our own data added
  // path is the path to navigate to
  // before is a function that is run before navigation
  const signedInMenuItems = useMemo(
    () => [
      ...(isRestricted
        ? []
        : [
            {
              label: 'Dashboard',
              path: DASHBOARD_URL,
              key: 'dashboard'
            }
          ]),
      ...(isRestricted
        ? []
        : [
            {
              label: 'Insights',
              path: '/analytics',
              key: 'analytics'
            }
          ]),
      {
        label: 'About Circular',
        path: '/',
        key: 'about'
      },
      ...(concierge
        ? [
            {
              label: 'Admin',
              key: 'admin',
              children: [
                {
                  label: 'Proposal builder',
                  path: '/concierge/proposal-builder',
                  key: 'proposalBuilder'
                },
                {
                  label: 'Markets playground',
                  path: '/concierge/markets-playground',
                  key: 'marketsPlayground'
                }
              ]
            }
          ]
        : []),
      ...(isRestricted
        ? []
        : [
            {
              label: 'Sign Out',
              key: 'signout',
              path: '/login',
              before: () => signOut()
            }
          ])
    ],
    [unreadCount, user]
  );

  const signedOuMenuItems = [
    {
      label: 'About Circular',
      path: '/',
      key: 'about'
    },
    {
      label: 'Sign In',
      path: '/login',
      key: 'signin'
    }
  ];

  return (
    <SimpleHeader
      menuItemsSignedIn={signedInMenuItems}
      menuItemsSignedOut={signedOuMenuItems}
      fixedHeaderScrollY={0}
      className="compact"
      logoLink={isRestricted ? '/' : DASHBOARD_URL}
    />
  );
}

export default Header;
