import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export function ActivityList({ activity, box }) {
  return (
    <div className={`activity-list ${box ? 'box' : ''}`}>
      {activity?.map((s, i) => (
        <div key={s.uuid} className="step">
          <div className="step-header">
            <div className="step-number"></div>
            <div className="step-title-row">
              <div className="step-title">{s.description}</div>
              <div className="step-time">{dayjs(s.created_at).fromNow()}</div>
            </div>
          </div>
          <div className="step-content"></div>
        </div>
      ))}
    </div>
  );
}

ActivityList.propTypes = {
  activity: PropTypes.arrayOf(PropTypes.object),
  box: PropTypes.bool
};
