import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import useBack from 'src/hooks/useBack';
import CircularLogoMonoColor from 'src/components/icons/CircularLogoMonoColor';

import backgroundImage from './Ellipses-2x.png';
import SplashPage from './SplashPage';

const titleMap = {};

export default function FormPageLayout({
  children,
  back = false,
  type = FormPageLayout.Types.BUYER,
  className,
  ...props
}) {
  const goBack = useBack();
  return (
    <SplashPage>
      <Row className={`form-page-layout ${className}`} wrap={false} {...props}>
        <Col
          className="sidebar"
          xs={0}
          sm={0}
          lg={10}
          xl={10}
          style={{
            backgroundImage: `linear-gradient(140.29deg, rgba(23, 47, 80, 0) 57.13%, rgba(27, 41, 60, 0.8) 90.37%), url(${backgroundImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom 0 right 0',
            backgroundColor: '#172F50'
          }}
        >
          <div className="fpl-background">
            <CircularLogoMonoColor />
            <p>{titleMap[type] || titleMap[FormPageLayout.Types.BUYER]}</p>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          lg={14}
          xl={14}
          style={{ backgroundColor: 'white', overflowY: 'auto' }}
        >
          {back && (
            <div className="navigate-back">
              <Button type="text" icon={<LeftOutlined />} onClick={goBack}>
                Back
              </Button>
            </div>
          )}

          <Row align="middle" justify="center">
            <Col
              className="form-page-layout-content"
              style={{
                padding: '2rem 4rem',
                overflowY: 'auto',
                alignContent: 'center'
              }}
            >
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </SplashPage>
  );
}

FormPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  back: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string
};

FormPageLayout.Types = {
  BUYER: 'Buyer',
  SELLER: 'Seller'
};

titleMap[FormPageLayout.Types.BUYER] =
  'Making PCR available, effective and affordable';
titleMap[FormPageLayout.Types.SELLER] = 'We make selling PCR easier for you';
