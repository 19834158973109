export const regionMap = {
  americas_north: {
    name: 'North America',
    boundingRectangle: {
      lat_min: 19,
      lat_max: 84,
      lng_min: -179.2,
      lng_max: -52
    },
    subRegions: ['us_west', 'us_east_of_rockies', 'us_south']
  },
  americas_south: {
    name: 'South America',
    boundingRectangle: { lat_min: -55, lat_max: 16, lng_min: -89, lng_max: -28 }
  },
  us: {
    name: 'US',
    boundingRectangle: {
      lat_min: 24,
      lat_max: 50,
      lng_min: -125,
      lng_max: -66
    },
    subRegions: ['us_west', 'us_east_of_rockies', 'us_south'],
    comparisons: ['eu', 'asia']
  },
  us_west: {
    name: 'US West',
    boundingRectangle: {
      lat_min: 24,
      lat_max: 50,
      lng_min: -125,
      lng_max: -114
    },
    parent: 'us'
  },
  us_east_of_rockies: {
    name: 'US East of Rockies',
    boundingRectangle: {
      lat_min: 39,
      lat_max: 50,
      lng_min: -114,
      lng_max: -66
    },
    parent: 'us'
  },
  us_south: {
    name: 'US South',
    boundingRectangle: {
      lat_min: 24,
      lat_max: 39,
      lng_min: -106,
      lng_max: -66
    },
    parent: 'us'
  },
  eu: {
    name: 'Europe',
    boundingRectangle: { lat_min: 37, lat_max: 55, lng_min: 2, lng_max: 15 },
    subRegions: ['eu_north_west', 'eu_north', 'eu_uk'],
    comparisons: ['us', 'asia']
  },
  eu_north_west: {
    name: 'Europe North West',
    boundingRectangle: { lat_min: 47, lat_max: 55, lng_min: 2, lng_max: 15 },
    parent: 'eu'
  },
  eu_north: {
    name: 'Europe North',
    boundingRectangle: { lat_min: 55, lat_max: 81, lng_min: 3, lng_max: 32 },
    parent: 'eu'
  },
  eu_uk: {
    name: 'UK',
    boundingRectangle: { lat_min: 49, lat_max: 59, lng_min: -8, lng_max: 2 },
    parent: 'eu'
  },
  asia_north_east: {
    name: 'Asia North East',
    boundingRectangle: { lat_min: 18, lat_max: 53, lng_min: 73, lng_max: 146 },
    parent: 'asia'
  },
  asia_south_east: {
    name: 'Asia South East',
    boundingRectangle: { lat_min: 8, lat_max: 23, lng_min: 92, lng_max: 109 },
    parent: 'asia'
  },
  asia_south: {
    name: 'Asia South',
    boundingRectangle: { lat_min: 7, lat_max: 36, lng_min: 60, lng_max: 97 },
    parent: 'asia'
  },
  asia: {
    name: 'Asia',
    boundingRectangle: { lat_min: 7, lat_max: 53, lng_min: 60, lng_max: 146 },
    subRegions: ['asia_north_east', 'asia_south_east', 'asia_south'],
    comparisons: ['us', 'eu']
  },
  middleeast: {
    name: 'Middle East',
    boundingRectangle: { lat_min: 12, lat_max: 37, lng_min: 34, lng_max: 63 }
  },
  africa: {
    name: 'Africa',
    boundingRectangle: { lat_min: -34, lat_max: 37, lng_min: -17, lng_max: 51 },
    comparisons: ['asia', 'eu']
  },
  australasia: {
    name: 'Australasia',
    boundingRectangle: {
      lat_min: -46,
      lat_max: -10,
      lng_min: 113,
      lng_max: 179
    }
  }
};

export const boundsToMapBoxBounds = (bounds) => [
  [bounds.lng_min, bounds.lat_min],
  [bounds.lng_max, bounds.lat_max]
];

export const continentMap = {
  americas_north: {
    name: 'North America',
    latitude: 40.097924,
    longitude: -100.592172
  },
  eu: {
    name: 'Europe',
    latitude: 48.7617256,
    longitude: 11.6048759
  },
  asia: {
    name: 'Asia',
    latitude: 24.831241,
    longitude: 104.938369
  },
  africa: {
    name: 'Africa',
    latitude: 12.535674,
    longitude: 14.4020431
  },
  americas_central: {
    name: 'Central America and the Caribbean',
    latitude: 17,
    longitude: -81.772825
  },
  americas_south: {
    name: 'South America',
    latitude: -7.790561,
    longitude: -58.151782
  },
  oceania: {
    name: 'Oceania',
    latitude: -23.687293,
    longitude: 134.991892
  }
};

export const countryNames = [
  'Albania',
  'Algeria',
  'Argentina',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Bulgaria',
  'Burma',
  'Cameroon',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Czechia',
  'Denmark',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Guatemala',
  'Guernsey',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'India',
  'Indonesia',
  'Iran',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kosovo',
  'Kuwait',
  'Latvia',
  'Lebanon',
  'Libya',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Malaysia',
  'Mali',
  'Malta',
  'Martinique',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Myanmar',
  'Namibia',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Réunion',
  'Romania',
  'Russia',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'The Netherlands',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Türkiye',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Zimbabwe'
];
