import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';

// Currently only works as a managed component (onChange and value must be used)
function RadioButtons({ options, value, onChange }) {
  return (
    <div className="radio-image-group">
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          {options.map((option) => (
            <Radio
              key={option.value}
              data-testid={option.value}
              value={option.value}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
}

RadioButtons.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default RadioButtons;
