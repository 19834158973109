export const isSupplier = (user) =>
  user?.groups?.find((group) => group.name === 'Supplier');

export const isBuyer = (user) =>
  user?.groups?.find((group) => group.name === 'Buyer');

export const isConcierge = (user) =>
  user?.groups?.find((group) => group.name === 'Concierge');

export const isDealPitch = (user) =>
  user?.groups?.find((group) => group.name === 'Deal Pitch');

export const isRestricted = (user) =>
  user?.groups?.find((group) => group.name === 'Restricted');

export const isOutdated = (user) =>
  user?.groups.length === 0 || user?.permissions.length === 0;
