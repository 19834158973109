import React from 'react';
import { QueryStatus } from 'src/components/error/QueryStatus';
import { Tabs } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getDeal } from 'src/Query';
import DealSampleForm from './DealSampleForm';
import DealRoomHeader from './DealRoomHeader';
import DealRoomBody from './DealRoomBody';

function DealSampling() {
  const { id } = useParams();
  const {
    data: deal,
    error,
    isLoading
  } = useQuery({
    queryKey: ['deal', id],
    queryFn: () => getDeal(id),
    refetchOnWindowFocus: false
  });

  const sampleResult = (supply) =>
    deal.sample_results.find((r) => r.deal_supply === supply.uuid);
  const tabItems = deal?.supplies.map((supply) => ({
    key: supply?.uuid,
    label: supply?.technical_data_sheet?.company?.name,
    children: (
      <DealSampleForm
        seller={supply?.technical_data_sheet?.company?.uuid}
        deal={deal}
        sampleResult={sampleResult(supply)}
      />
    )
  }));
  return (
    <div className="deal-sampling">
      <DealRoomHeader>
        <h4>Sample Results</h4>
      </DealRoomHeader>
      <QueryStatus isLoading={isLoading} title="Deal" error={error}>
        {deal && (
          <DealRoomBody>
            <Tabs items={tabItems} />
          </DealRoomBody>
        )}
      </QueryStatus>
    </div>
  );
}

export default DealSampling;
