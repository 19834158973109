import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, Row, Skeleton, Typography, message } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { getDealSampleResults } from 'src/Query';
import useGetRfq from 'src/Query/useGetRfq';
import FullScreenModal from 'src/components/layout/FullScreenModal';

import prettyNumber from 'src/components/utils/prettyNumber';
import { findNumericalProperty } from 'src/utils/properties';
import rangesOverlap from 'src/components/utils/rangesOverLap';
import { WarningFilled } from '@ant-design/icons';

import './TestResults.less';
import { TestLabCard } from 'src/pages/testing/TestLab';
import { patchDealSampleResults } from 'src/Mutation';

export default function TestResults() {
  const { dealId, sampleResultId } = useParams();

  const { data: requestForQuote, isLoading: rfqLoading } = useGetRfq();

  const { data: sampleResult, isLoading } = useQuery(
    ['sample-result', sampleResultId],
    () => getDealSampleResults(dealId, sampleResultId)
  );

  const [messageApi, contextHolder] = message.useMessage();
  const { mutate, isMutating } = useMutation({
    mutationFn: (status) =>
      patchDealSampleResults({ dealId, sampleResultId, status }),
    onSuccess: () => {
      messageApi.success('Successfully evaluated test results');
    },
    onError: (e) => {
      messageApi.error('Error evaluating test results');
    }
  });

  if (isLoading || rfqLoading)
    return (
      <FullScreenModal title="Lab Test Results">
        <Skeleton />
      </FullScreenModal>
    );

  return (
    <FullScreenModal title="Lab Test Results">
      <div className="lab-test-results">
        <h1>Lab Test Results</h1>
        <p>
          Review the lab test results below, or download the full report
          provided by the testing lab. Please review our recommendations below
          for how we might address approvals, re-tests, and mitigations.
        </p>
        {sampleResult.material_numerical_property_results.map((mnpResult) => (
          <ResultsCompare
            key={mnpResult.uuid}
            mnpResult={mnpResult}
            mnpRfq={findNumericalProperty(
              requestForQuote,
              mnpResult.material_numerical_property.code
            )}
          />
        ))}
        {sampleResult.recommendation && (
          <div className="lab-test-results--recommendation">
            <Typography.Text strong>Our Recommendations</Typography.Text>
            <p>{sampleResult.recommendation}</p>
          </div>
        )}
        <div className="lab-test-results--lab">
          <Typography.Text strong>Lab Tests Performed by:</Typography.Text>
          <p>
            <TestLabCard
              lab={{ company: sampleResult.lab }}
              report={sampleResult.report}
            />
          </p>
        </div>
        <Row
          gutter={[12, 12]}
          justify="end"
          className="lab-test-results--action"
        >
          {contextHolder}
          <Col>
            <Button
              loading={isMutating}
              onClick={() => mutate('rejected')}
              htmlType="button"
            >
              <Typography.Text type="danger">Reject</Typography.Text>
            </Button>
          </Col>
          <Col>
            <Button
              loading={isMutating}
              onClick={() => mutate('retest')}
              htmlType="button"
            >
              Test again
            </Button>
          </Col>
          <Col>
            <Button
              loading={isMutating}
              onClick={() => mutate('approved')}
              htmlType="button"
              type="primary"
            >
              Approve
            </Button>
          </Col>
        </Row>
      </div>
    </FullScreenModal>
  );
}

function ResultsCompare({ mnpResult, mnpRfq }) {
  const hasOverlap =
    mnpRfq &&
    !!rangesOverlap(
      [mnpResult.result, mnpResult.result],
      [mnpRfq.min, mnpRfq.max]
    );

  return (
    <div className="lab-test-results--single" key={mnpResult.uuid}>
      <Row justify="space-between">
        <Col>
          <Typography.Text strong>
            {mnpResult.material_numerical_property.name}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text type={hasOverlap ? 'success' : undefined}>
            {!hasOverlap && mnpRfq && (
              <>
                <WarningFilled
                  style={{
                    color: '#FAAD14'
                  }}
                />{' '}
              </>
            )}
            <Typography.Text type={hasOverlap ? 'success' : undefined} strong>
              {prettyNumber(mnpResult.result)}
            </Typography.Text>{' '}
            {mnpResult.result_units}
          </Typography.Text>
        </Col>{' '}
      </Row>
      <Row justify="space-between">
        <Col>
          <Typography.Text type="secondary"></Typography.Text>
        </Col>
        <Col>
          {mnpRfq && (
            <Typography.Text type="secondary">
              {hasOverlap ? (
                <>
                  {prettyNumber(mnpResult.result)} {mnpResult.result_units} is
                  in the optimal range.
                </>
              ) : (
                <>Re-test recommended</>
              )}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <div className="lab-test-results--visual">
            {mnpRfq && (
              <div
                style={{
                  ...(!mnpRfq.min ? { left: 0 } : {}),
                  ...(!mnpRfq.max ? { right: 0 } : {}),
                  ...(!hasOverlap ? { background: '#FAAD14' } : {})
                }}
                className="desired-range"
              />
            )}
            <div
              style={
                mnpRfq && !hasOverlap
                  ? {
                      left: mnpResult.result > mnpRfq.max ? '80%' : '20%'
                    }
                  : {}
              }
              className="test-result"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

ResultsCompare.propTypes = {
  mnpResult: PropTypes.object,
  mnpRfq: PropTypes.object
};
