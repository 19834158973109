import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getCertifications } from 'src/Query';

function CertificationSelect({ value, multiple, ...props }) {
  const { data = [] } = useQuery({
    queryKey: ['certifications'],
    queryFn: () => getCertifications()
  });

  const isMultiple = multiple || Array.isArray(value);
  return (
    <Select
      value={data.length > 0 && value}
      {...props}
      loading={!data?.length}
      mode={isMultiple && 'multiple'}
      optionFilterProp="searchProp"
    >
      {data.map((cert) => (
        <Select.Option
          value={cert.uuid}
          key={cert.uuid}
          searchProp={`${cert.name} ${cert.uuid} ${cert.description}`}
        >
          {cert.name}
        </Select.Option>
      ))}
    </Select>
  );
}

CertificationSelect.propTypes = {
  propertyName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  filter: PropTypes.func,
  autocomplete: PropTypes.bool,
  multiple: PropTypes.bool
};

export default CertificationSelect;
