import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Tag, Tooltip } from 'antd';
import usePlacesAutocomplete from 'use-places-autocomplete';

import {
  parseApiLocation,
  parseInternalLocation,
  parseGoogleAddress,
  formatAddressMultiline
} from './LocationInput';

const { Option } = AutoComplete;

/**
 * Shape of the value of this component:
 * {
 *         address
 *         latitude
 *         longitude
 *         zip
 *         country
 *         street_one
 *         state
 *         city
 *         internal_location
 * }
 */

// Currently only works as a managed component (onChange and value must be used)
function MultipleLocationInput({
  value: valueIn,
  onChange,
  placeholder,
  ...props
}) {
  const {
    suggestions: { data },
    setValue
  } = usePlacesAutocomplete({
    debounce: 300
  });

  const [inputText, setInputText] = useState();

  useEffect(() => {
    if (!Array.isArray(valueIn) || valueIn.length === 0) setInputText('');
  }, [valueIn]);

  const handleInput = (address) => {
    setValue(address);
    setInputText(address);
  };

  const hasPlaceId = (placeId) =>
    Array.isArray(valueIn) &&
    valueIn.find((location) => location.placeId === placeId);

  const handleSelect = async (address) => {
    try {
      const location = await parseGoogleAddress(address);
      if (location.city && !hasPlaceId(location.placeId)) {
        const newValue = (Array.isArray(valueIn) && [...valueIn]) || [];
        newValue.push(location);
        onChange(newValue);
        setInputText();
      }
    } catch (ex) {
      window.console.error('Error calling google API');
    }
  };

  const removeLocation = (index) => {
    const newValue = valueIn.filter((o, i) => i !== index);
    onChange(newValue);
  };

  return (
    <div className="multiple-location-input">
      <AutoComplete
        allowClear
        placeholder={placeholder}
        value={inputText}
        searchValue={inputText}
        onChange={handleInput}
        onSelect={handleSelect}
        className="location-input"
        {...props}
      >
        {data.map((o) => (
          <Option key={o.uuid} value={o.description}>
            {o.description}
          </Option>
        ))}
      </AutoComplete>
      <div className="multiple-location-input-tags">
        {Array.isArray(valueIn) &&
          valueIn.map((location, index) => (
            <Tooltip
              key={location.uuid}
              title={formatAddressMultiline(location)}
            >
              <Tag closable onClose={() => removeLocation(index)}>
                {location.city || location.country}
              </Tag>
            </Tooltip>
          ))}
      </div>
    </div>
  );
}

MultipleLocationInput.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

export default MultipleLocationInput;
export { parseInternalLocation, parseApiLocation };
