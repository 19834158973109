import React, { useMemo, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { SupplierMarker } from 'src/pages/maps/SupplierMarker';

import useMap from './hooks/useMap';
import { MapContext } from './MapContext';

// for the popup on a marker, we can get away with raw html
const popupHtml = (spot) =>
  `<div>
<h3 style="margin: 4px;">${spot.label}</h3>
</div>`;

export default function MapPageMarkers(props) {
  const { MapComponent } = useMapPage(props);

  return MapComponent;
}

MapPageMarkers.propTypes = {
  additionalMarkers: PropTypes.array,
  useMapProps: PropTypes.object
};

export function useMapPage({
  markers,
  referenceMarkers,
  useMapProps,
  className,
  ...props
}) {
  const { map, mapContainer } = useMap({
    mapProps: {
      zoom: 1 // Start zoomed out
    },
    ...(useMapProps || {})
  });
  const mapRef = useRef(map);
  mapRef.current = map;
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true
  });

  const onMarkerMouseEnter = (spot) => {
    if (!mapRef.current || !mapRef.current.getCanvas()) return;
    popup.remove();
    mapRef.current.getCanvas().style.cursor = 'pointer';
    popup
      .setLngLat([spot.long, spot.lat])
      .setHTML(popupHtml(spot))
      .addTo(mapRef.current);
  };
  const onMarkerMouseLeave = (spot) => {
    if (!mapRef.current || !mapRef.current.getCanvas()) return;
    mapRef.current.getCanvas().style.cursor = '';
    popup.remove();
  };

  const markerComponents = useMemo(
    () => [
      ...(markers || []).map((spot) => (
        <SupplierMarker
          supplier={spot}
          primary={spot.verified > 0}
          id={spot.id}
          label={spot.label}
          key={spot.id}
          map={map}
          data={spot}
          onMouseEnter={() => onMarkerMouseEnter(spot)}
          onMouseLeave={() => onMarkerMouseLeave(spot)}
          suppliers={1}
        />
      )),
      ...(referenceMarkers || []).map((spot) => (
        <SupplierMarker
          supplier={spot}
          primary={spot.verified > 0}
          color="#cf1322"
          id={spot.id}
          label={spot.label}
          key={spot.id}
          map={map}
          data={spot}
          onMouseEnter={() => onMarkerMouseEnter(spot)}
          onMouseLeave={() => onMarkerMouseLeave(spot)}
          suppliers={1}
        />
      ))
    ],
    [markers, referenceMarkers]
    /* eslint-enable react/no-array-index-key */
  );
  return {
    map,
    markers: markerComponents,
    MapComponent: (
      <MapContext.Provider value={map}>
        <div ref={mapContainer} {...props} className={`risk-map ${className}`}>
          {markerComponents}
        </div>
      </MapContext.Provider>
    )
  };
}
