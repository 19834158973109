import { useMemo } from 'react';
import { useMarketPriceSeries } from 'src/Query/marketPrice';
import { sourcesListDetails } from './utils';

const priceMid = (price) =>
  (price && (Number(price.high) + Number(price.low)) / 2) || 0;

const isNumber = (o) => typeof o === 'number';
// Get trend based on first v last element in array
export const getGraphTrend = (prices) => {
  if (
    !prices ||
    prices.length <= 1 ||
    !isNumber(prices[prices.length - 1]) ||
    !isNumber(prices[0])
  )
    return 'unchanged';

  const diff = prices[prices.length - 1] - prices[0];
  const percent = Math.abs(diff / prices[0]);

  // Changes <5% from oldest to newest value are considered no change
  if (percent < 0.05) return 'unchanged';

  if (Math.sign(diff) === 1) return 'up';

  return 'down';
};

const pointDiff = (p1, p2) => ({
  high: (p1?.high || 0) - (p2?.high || 0),
  low: (p1?.low || 0) - (p2?.low || 0)
});

// we assume both curves are fully populated
const curveDiff = (curve1, curve2) =>
  (curve1 && curve1.map((p, i) => pointDiff(p, curve2 && curve2[i]))) || [];

export default function useMarkerPriceSeriesReference(ticker, referenceTicker) {
  const { isLoading: isLoading1, series: series1 } = useMarketPriceSeries(
    ticker?.query
  );
  const { isLoading: isLoading2, series: series2 } = useMarketPriceSeries(
    referenceTicker?.query
  );
  const isLoading = isLoading1 || isLoading2;
  const series = useMemo(() => {
    if (!referenceTicker) return series1;
    if (referenceTicker && series1 && series2) {
      return {
        latest:
          (series2.latest &&
            series1.latest &&
            pointDiff(series1.latest, series2.latest)) ||
          0,
        curve: curveDiff(series1.curve, series2.curve),
        tickers: [...(series1?.tickers || []), ...(series2?.tickers || [])],
        units: series1.units,
        currency: series1.currency
      };
    }
    return undefined;
  }, [series1, series2]);
  const { title } = ticker;
  const mid = (series && priceMid(series.latest)) || 0;
  const { currency, units } = series || {};
  const tickersArray = series?.tickers || [];
  const sourceDetails = sourcesListDetails(tickersArray);
  const curve = useMemo(() => series?.curve?.map((o) => priceMid(o)), [series]);
  const graphTrend = getGraphTrend(curve);
  return {
    graphTrend,
    isLoading,
    series,
    curve,
    mid,
    sourceDetails,
    currency,
    units,
    title
  };
}
