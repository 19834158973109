import React, { useEffect, useState } from 'react';
import { Input, Form } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getSearch } from 'src/Query/search';
import { SearchOutlined } from '@ant-design/icons';
import SearchResults from './SearchResults';

const { useForm } = Form;

/* eslint-disable no-underscore-dangle */

function SearchPage() {
  const group_by = 'url';
  const [query, setQuery] = useState();
  const class_name = 'OnlineSourceV2_0';
  const { data, isFetching, refetch } = useQuery(
    ['search', query],
    () => getSearch({ query, class_name }),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false
    }
  );
  const [form] = useForm();
  const search = (values) => {
    setQuery(values.search);
  };
  const onPressEnter = () => {
    form.submit();
  };
  useEffect(() => {
    if (query) refetch();
  }, [query]);

  return (
    <div className="search-page">
      <Form name="basic" onFinish={search}>
        <Form.Item
          name="search"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            onPressEnter={onPressEnter}
            prefix={<SearchOutlined />}
            placeholder="Search Circular"
          />
        </Form.Item>
      </Form>
      <SearchResults data={data} isFetching={isFetching} query={query} />
    </div>
  );
}

export default SearchPage;
