import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';

/* eslint-disable import/no-unresolved */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useHistogramSettings from 'src/components/project/explore/hooks/useHistogramSettings';
import prettyNumber from 'src/components/utils/prettyNumber';

import { generateHistogram, splitHistogram } from './histogram';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 1
    }
  },
  responsive: true,
  plugins: {
    legend: false,
    title: false
  },
  barPercentage: 0.8,
  categoryPercentage: 1,
  scales: {
    x: {
      display: false,
      border: {
        display: false
      },
      beginAtZero: false,
      position: 'top',
      bounds: 'ticks',
      grid: {
        display: false,
        lineWidth: 0
      }
    },
    y: {
      display: false,
      beginAtZero: true,
      bounds: 'ticks',
      grid: {
        display: false
      },
      ticks: {
        display: false
      }
    }
  }
};

const NUMBER_OF_BARS = 30;
function useMinimalHistogramSettings(specData, kpiCode) {
  const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);
  const { min: specMinFilter, max: specMaxFilter } = kpiFilters[kpiCode];

  // Remove the n smallest and largest values (n = dataPoints / 70)
  const normalizedValues = useMemo(() => {
    const boundsToRemove = Math.floor(specData.values.length / 70);
    const valuesCopy = [...specData.values];

    if (!boundsToRemove) return valuesCopy;

    // First we sort by mins and remove smallest outliers
    valuesCopy.sort(({ min: aMin, max: aMax }, { min: bMin, max: bMax }) => {
      let a;
      let b;

      if (!aMin) a = aMax;
      else if (!aMax) a = aMin;
      else a = Math.min(aMin, aMax);

      if (!bMin) b = bMax;
      else if (!bMax) b = bMin;
      else b = Math.min(bMin, bMax);

      if (!a) return -1;
      if (!b) return 1;

      return a - b;
    });
    for (let index = 0; index < boundsToRemove; index++) {
      valuesCopy.shift();
    }

    // Then we sort by maxes and remove the largest outliers
    valuesCopy.sort(({ min: aMin, max: aMax }, { min: bMin, max: bMax }) => {
      let a;
      let b;

      if (!aMin) a = aMax;
      else if (!aMax) a = aMin;
      else a = Math.max(aMin, aMax);

      if (!bMin) b = bMax;
      else if (!bMax) b = bMin;
      else b = Math.max(bMin, bMax);

      if (!a) return -1;
      if (!b) return 1;

      return a - b;
    });
    for (let index = 0; index < boundsToRemove; index++) {
      valuesCopy.pop();
    }

    return valuesCopy;
  }, [specData]);

  const { decimalPrecision, min, max } = useHistogramSettings(
    normalizedValues,
    specMinFilter,
    specMaxFilter,
    NUMBER_OF_BARS
  );
  const step = (max - min) / NUMBER_OF_BARS;

  return {
    decimalPrecision,
    min,
    max,
    step
  };
}

function MinimalHistogram({ specData, kpiCode }) {
  const chartRef = useRef(null);

  const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);
  const { min: specMinFilter, max: specMaxFilter } = kpiFilters[kpiCode];

  const { units } = specData;

  // Remove the n smallest and largest values (n = dataPoints / 70)
  const normalizedValues = useMemo(() => {
    const boundsToRemove = Math.floor(specData.values.length / 70);
    const valuesCopy = [...specData.values];

    if (!boundsToRemove) return valuesCopy;

    // First we sort by mins and remove smallest outliers
    valuesCopy.sort(({ min: aMin, max: aMax }, { min: bMin, max: bMax }) => {
      let a;
      let b;

      if (!aMin) a = aMax;
      else if (!aMax) a = aMin;
      else a = Math.min(aMin, aMax);

      if (!bMin) b = bMax;
      else if (!bMax) b = bMin;
      else b = Math.min(bMin, bMax);

      if (!a) return -1;
      if (!b) return 1;

      return a - b;
    });
    for (let index = 0; index < boundsToRemove; index++) {
      valuesCopy.shift();
    }

    // Then we sort by maxes and remove the largest outliers
    valuesCopy.sort(({ min: aMin, max: aMax }, { min: bMin, max: bMax }) => {
      let a;
      let b;

      if (!aMin) a = aMax;
      else if (!aMax) a = aMin;
      else a = Math.max(aMin, aMax);

      if (!bMin) b = bMax;
      else if (!bMax) b = bMin;
      else b = Math.max(bMin, bMax);

      if (!a) return -1;
      if (!b) return 1;

      return a - b;
    });
    for (let index = 0; index < boundsToRemove; index++) {
      valuesCopy.pop();
    }

    return valuesCopy;
  }, [specData]);

  const { decimalPrecision, min, max, step } = useMinimalHistogramSettings(
    specData,
    kpiCode
  );

  const computedOptions = {
    ...options,
    step,
    plugins: {
      ...options.plugins,
      // Display the value of the bar on hover
      tooltip: {
        enabled: true,
        displayColors: false,
        caretSize: 0,
        callbacks: {
          title: ([{ label }]) => `${label} ${units}`,
          label: (context) => `${context.raw} records`
        }
      }
    },
    scales: {
      ...options.scales,
      x: {
        ...options.scales.x,
        max: max.toFixed(decimalPrecision < 0 ? decimalPrecision * -1 : 0),
        min: min.toFixed(decimalPrecision < 0 ? decimalPrecision * -1 : 0),
        stacked: true,
        ticks: {
          display: false,
          font: {
            size: 12,
            lineHeight: 1
          },
          maxRotation: 0,
          padding: 0,
          maxTicksLimit: 6,

          showLabelBackdrop: false,

          // mirror: true,
          // z: 1,

          // Only show the min and max labels
          callback: ticksCallback
        }
      }
    }
  };

  const data = useMemo(() => {
    const [histogram, labels] = generateHistogram(
      normalizedValues,
      decimalPrecision,
      min,
      max,
      step
    );
    const [insideData, outsideData] = splitHistogram(
      histogram,
      decimalPrecision,
      min,
      max,
      step,
      specMinFilter || min,
      specMaxFilter || max
    );

    return {
      labels,
      datasets: [
        {
          label: 'Spec Count (match)',
          data: insideData,
          borderRadius: 2,
          borderColor: '#A4B5FB',
          backgroundColor: '#A4B5FB'
        },
        {
          label: 'Spec Count',
          data: outsideData,
          borderRadius: 2,
          borderColor: '#D8DBEACC',
          backgroundColor: '#D8DBEACC'
        }
      ]
    };
  }, [
    normalizedValues,
    specMinFilter,
    specMaxFilter,
    min,
    max,
    decimalPrecision,
    step
  ]);

  return (
    <Bar
      width="332px"
      height="50px"
      data={data}
      options={computedOptions}
      ref={chartRef}
    />
  );
}
MinimalHistogram.propTypes = {
  specData: PropTypes.object.isRequired,
  kpiCode: PropTypes.string.isRequired
};

export default MinimalHistogram;

function ticksCallback(value, index, ticks) {
  if (index === 0 || index + 1 === ticks.length) {
    return this.getLabelForValue(value);
  }

  return null;
}

export function HistogramSlider({ specData, kpiCode }) {
  const [kpiValues, setKpiValues] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpiCode
  ]);

  const { decimalPrecision, min, max, step } = useMinimalHistogramSettings(
    specData,
    kpiCode
  );
  return (
    <div className="histogram-slider mt-xs">
      <div style={{ height: 50 }}>
        <MinimalHistogram specData={specData} kpiCode={kpiCode} />
      </div>
      <Slider
        range
        min={min}
        max={max}
        step={step}
        value={[kpiValues?.min || min, kpiValues?.max || max]}
        onChange={([newMin, newMax]) => {
          setKpiValues({
            ...kpiValues,
            min: newMin,
            max: newMax
          });
        }}
        tooltip={{
          formatter: (val) => prettyNumber(val)
        }}
      />
    </div>
  );
}
HistogramSlider.propTypes = {
  specData: PropTypes.object.isRequired,
  kpiCode: PropTypes.string.isRequired
};
