import { Table, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import {
  DollarCircleOutlined,
  FileOutlined,
  LinkOutlined,
  PlusOutlined
} from '@ant-design/icons';

import { getCompanyActivity } from 'src/Query';

import './CompanyAdmin.less';

const EMPTY_ARRAY = [];

export default function CompanyAdmin({ company }) {
  const { data: activity, isLoading: isActivityLoading } = useQuery(
    ['company/activity', company?.uuid],
    () => getCompanyActivity(company?.uuid),
    { retry: false, enabled: !!company?.uuid }
  );

  const technicalDataSheets = activity?.technical_data_sheet || EMPTY_ARRAY;
  const requestsForQuote = activity?.request_for_quote || EMPTY_ARRAY;
  const dealsBuyer = activity?.deal_buyer || EMPTY_ARRAY;
  const dealsSeller = activity?.deal_seller || EMPTY_ARRAY;

  return (
    <div className="company-admin">
      <a
        target="_blank"
        href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/company/${company?.uuid}/`}
      >
        <Typography.Title level={2}>{company?.name}</Typography.Title>
      </a>
      <a href={`/tds-create/${company?.uuid}`} target="_blank">
        <PlusOutlined />
        Create TDS
      </a>
      <Table
        pagination={false}
        dataSource={technicalDataSheets}
        columns={[
          {
            title: 'Technical Data Sheets',
            dataIndex: 'uuid',
            render: (uuid, record) => (
              <>
                <a
                  target="_blank"
                  href={`${window.location.origin}/seller-deal/quote/${uuid}/review`}
                >
                  <FileOutlined />
                </a>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/technicaldatasheet/${uuid}/`}
                >
                  <LinkOutlined />
                </a>
                {record.title}
              </>
            )
          }
        ]}
      />
      <Table
        pagination={false}
        dataSource={requestsForQuote}
        columns={[
          {
            title: 'Requests for Quote',
            dataIndex: 'uuid',
            render: (uuid, record) => (
              <>
                <a
                  target="_blank"
                  href={`${window.location.origin}/request-for-quote/${uuid}`}
                >
                  <FileOutlined />
                </a>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/requestforquote/${uuid}/`}
                >
                  <LinkOutlined />
                </a>
                <a
                  target="_blank"
                  href={`${window.location.origin}/buyer-deal/quote/${uuid}/proposals`}
                >
                  <DollarCircleOutlined />
                </a>
                {record.title}
              </>
            )
          }
        ]}
      />
      <Table
        pagination={false}
        dataSource={dealsBuyer}
        columns={[
          {
            title: 'Deals as Buyer',
            dataIndex: 'uuid',
            render: (uuid, record) => (
              <>
                <a
                  target="_blank"
                  href={`${window.location.origin}/deal/${uuid}`}
                >
                  <FileOutlined />
                </a>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/deal/${uuid}/`}
                >
                  <LinkOutlined />
                </a>
                {record.title}
              </>
            )
          }
        ]}
      />
      <Table
        pagination={false}
        dataSource={dealsSeller}
        columns={[
          {
            title: 'Deals as Seller',
            dataIndex: 'uuid',
            render: (uuid, record) => (
              <>
                <a
                  target="_blank"
                  href={`${window.location.origin}/deal/${uuid}`}
                >
                  <FileOutlined />
                </a>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/deal/${uuid}/`}
                >
                  <LinkOutlined />
                </a>
                {record.title}
              </>
            )
          }
        ]}
      />
    </div>
  );
}

CompanyAdmin.propTypes = {
  company: PropTypes.object
};
