import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import { currencySymbol } from 'src/utils/currency';
import { useAvailableUnitsForType } from 'src/utils/units';

// managed component (onChange and value must be used)
function CurrencyUnitsInput({ value, onChange, unitType, per, ...props }) {
  const unitList = useAvailableUnitsForType(unitType);
  // see here for why we add the <span/> https://ant.design/components/input/#FAQ
  const onChangeValue = (v) =>
    onChange({ ...(value || {}), value: v.target.value });
  const onChangeUnits = (v) => onChange({ ...(value || {}), units: v });
  return (
    <Input
      {...props}
      prefix={currencySymbol(value?.currency)}
      value={value?.value}
      onChange={onChangeValue}
      addonAfter={
        <>
          {per ? '/' : ''}
          <Select
            onChange={onChangeUnits}
            value={value?.units}
            style={{ width: 65 }}
            options={unitList?.map((unit) => ({
              label: unit,
              value: unit
            }))}
          />
        </>
      }
    />
  );
}

/* eslint-disable prefer-promise-reject-errors */

export const rules = (required) => [
  { required, message: 'Please enter an amount' },
  () => ({
    validator(_, value) {
      if (value && !numeral(value).value()) {
        return Promise.reject('Please enter a valid number');
      }
      return Promise.resolve();
    }
  })
];

CurrencyUnitsInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  per: PropTypes.bool,
  unitType: PropTypes.string
};

export default CurrencyUnitsInput;

export function CurrencyWeightUnitsInput({ ...props }) {
  return <CurrencyUnitsInput unitType="weight" {...props} />;
}

export function CurrencyPerWeightUnitsInput({ ...props }) {
  return <CurrencyUnitsInput per unitType="weight" {...props} />;
}
