const demoSteps = [
  {
    label: 'Prioritize Supplier List',
    key: 'prioritize-suppliers',
    done: true
  },
  {
    label: 'Edit & Send Digital RFQ',
    key: 'buyer-digital-rfq',
    done: true
  },
  {
    label: 'Manage Quotes',
    key: 'quotes',
    done: false
  }
];

export function projectSteps(project) {
  const drfqSent = ['procure', 'manage'].includes(project?.stage);

  const steps =
    project?.workflow?.source ||
    demoSteps.map((step) => ({
      ...step,
      done: drfqSent && step.key !== 'quotes'
    }));

  return steps;
}

export function getNextStep(project) {
  const steps = projectSteps(project);
  return steps.find(({ done }) => !done) || steps[0];
}
