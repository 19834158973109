import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from 'src/utils/authentication';
import { usePerformance } from './hooks';

// I think that there is only one script for all origins in pendo

const pendoScript = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('8beeab33-93ac-4189-6015-82e144f0750c');`;

const UNKNOWN_USER_ID = -1;
const UNKNOWN_COMPANY_ID = 'unknown';

function Pendo() {
  const optOut = usePerformance();
  const { data: user } = useUser();

  useEffect(() => {
    // window.console.log('pendo effect');
    const params = {
      visitor: {
        id: user?.id || UNKNOWN_USER_ID
      },
      account: {
        id: user?.company?.uuid || UNKNOWN_COMPANY_ID,
        name: user?.company?.name || UNKNOWN_COMPANY_ID
      }
    };
    if (window.pendo) {
      window.pendo.initialize(params);
      // window.console.log('pendo init', params);
    }
  }, [user, optOut, window.pendo]);

  // window.console.log('pendo optOut', optOut, user, window.pendo);
  return (
    !optOut && (
      <Helmet>
        <script>{pendoScript}</script>
      </Helmet>
    )
  );
}

export default Pendo;
