import { Empty, Skeleton, Tabs, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import Location from 'src/components/project/explore/Location';
import Pricing from 'src/components/project/explore/Pricing';
import Specifications from 'src/components/project/explore/Specifications';
import emptyExplore from 'src/images/exploreEmpty.png';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { SpecMinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';
import { useLatestRelevantPriceForExplore } from 'src/components/analytics/MarketPriceByFormLineChart';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { currencyValuePrice } from 'src/utils/currency';
import useSupplierList from './hooks/useSupplierList';
import useIsBalesMode from './hooks/useIsBalesMode';
import useDetailedSupplierCountBreakdown from './hooks/useDetailedSupplierCountBreakdown';
import BalesExplore from './BalesExplore';

export function ConditionalContent() {
  const filters = useRelevantFiltersForForm();
  const noFilters = Object.keys(filters).length === 0;
  return noFilters ? <EmptyExplore /> : <Content />;
}

const EMPTY_ARRAY = [];

export default function Content() {
  const [activeKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);
  const filters = useRelevantFiltersForForm();
  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const isBales = useIsBalesMode();

  // Returns an array of kpi codes
  const primaryKpis = usePrimaryKpis();

  const { suppliers, isLoadingSuppliers } = useSupplierList();

  const latestPriceData = useLatestRelevantPriceForExplore();

  const isLoading = isLoadingSuppliers;

  const detailedCounts = suppliers?.detailed_counts || EMPTY_ARRAY;
  const { tdsCountBase, supplierCountBase } =
    useDetailedSupplierCountBreakdown(detailedCounts);

  const recordCount = tdsCountBase;
  const supplierCount = supplierCountBase;

  if (isLoading && !suppliers) return <Skeleton />;
  if (!form) return <EmptyExplore />;
  if (isBales) return <BalesExplore />;
  return (
    <Tabs
      className="bordered-tabs"
      items={[
        {
          key: 'specs',
          label: (
            <TabTitle
              title="Specifications"
              subtitle={primaryKpis.map((kpiCode, i) => (
                <React.Fragment key={kpiCode}>
                  {kpiCode} <SpecMinMaxRangeString kpiCode={kpiCode} />
                  {i < primaryKpis.length - 1 && ' • '}
                </React.Fragment>
              ))}
            />
          ),
          children: (
            <Specifications kpiTabs={activeKpiFilters} filters={filters} />
          )
        },
        {
          key: 'suppliers',
          label: (
            <TabTitle
              title="Suppliers"
              subtitle={`${
                supplierCount ? prettyNumberRound(supplierCount) : '-'
              } Suppliers • ${
                recordCount ? prettyNumberRound(recordCount) : '-'
              } Records`}
            />
          ),
          children: <Location />
        },
        {
          key: 'market',
          label: (
            <TabTitle
              title="Market"
              subtitle={
                latestPriceData &&
                `${currencyValuePrice(
                  latestPriceData.price,
                  latestPriceData.currency,
                  latestPriceData.units
                )} (${latestPriceData.label})`
              }
            />
          ),
          children: <Pricing />
        }
      ]}
    />
  );
}

function EmptyExplore() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Explore what's possible</Typography.Title>
      <Typography.Paragraph>
        Leverage Circular's database of over 50,000 records to get a real
        picture of what is possible with PCR.
      </Typography.Paragraph>
    </Empty>
  );
}

function TabTitle({ title, subtitle }) {
  return (
    <>
      <span className="font-size-4">{title}</span>
      <br />
      <Typography.Text
        type="secondary"
        className="font-size-2"
        style={{ fontWeight: 400 }}
      >
        {subtitle}
      </Typography.Text>
    </>
  );
}
TabTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
