import React from 'react';
import { ReactComponent as Icon } from './svg/checked-primary.svg';
import SvgIconComponent from './SvgIconComponent';

function CheckedPrimary() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default CheckedPrimary;
