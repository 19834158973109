import { Button, Form, message } from 'antd';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { createCompanySalesflare, createSalesflareLink } from 'src/Mutation';
import PropTypes from 'prop-types';
import ObjectInput from 'src/components/form/ObjectInput';

const { useForm } = Form;

export default function CompanySalesflareLink({ onChange, salesflareId }) {
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: mutateLink, isMutating: isMutatingLink } = useMutation({
    mutationFn: createSalesflareLink,
    onSuccess: (response) => onChange(),
    onError: (e) => {
      messageApi.error('Error linking salesflare');
    }
  });
  const { mutate: mutateCreate, isMutating: isMutatingCreate } = useMutation({
    mutationFn: createCompanySalesflare,
    onSuccess: (response) => onChange(),
    onError: (e) => {
      messageApi.error('Error creating company from salesflare');
    }
  });
  const createLink = ({ company }) => {
    mutateLink({ uuid: company.value, salesflareId });
  };
  const createCompany = () => {
    mutateCreate({ salesflareId });
  };
  return (
    <div className="company-salesflare-link">
      {contextHolder}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={createLink}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Company"
          name="company"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <ObjectInput appName="api" modelName="company" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isMutatingLink}>
          Link company
        </Button>
      </Form>
      <p>or</p>
      <Button type="primary" onClick={createCompany}>
        Create new company
      </Button>
    </div>
  );
}

CompanySalesflareLink.propTypes = {
  salesflareId: PropTypes.string,
  onChange: PropTypes.func
};
