import { useQuery } from '@tanstack/react-query';
import { Alert, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { getPressContent } from 'src/Query';

import './LatestNews.less';

export default function LatestNews() {
  return (
    <div id="latest-news">
      <div className="latest-news-inner">
        <h2>Articles & Press Releases</h2>
        <p>
          <a
            href="mailto:press@Circular.co?subject=Inquiry about Circular.co"
            title="Get in touch via email"
            target="_blank"
          >
            Get in touch
          </a>{' '}
          with our communications team for inquiries or information about
          Circular.{' '}
        </p>
        <LatestNewsItems />
      </div>
    </div>
  );
}

function LatestNewsItems() {
  const { data, isLoading, error } = useQuery(['pressContent'], () =>
    getPressContent()
  );

  if (isLoading) return <Skeleton />;
  if (error)
    return <Alert type="danger" message="Error fetching news items." />;

  return (
    <Row className="latest-news-items" gutter={[60, 90]}>
      {data.slice(0, 12).map((p) => (
        <Col xs={24} md={12} lg={8} key={p.uuid}>
          <a href={p.link} title={p.title} target="_blank">
            <img src={p.image} alt={p.title} />
            <h3>{p.title}</h3>
          </a>
        </Col>
      ))}
    </Row>
  );
}
