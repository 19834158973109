import { useQuery } from '@tanstack/react-query';
import { getNumericalPropertyTestConditions } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

function useMetaNumericalPropertyTestConditions(codeOrUuid) {
  const { data: testConditions = DEFAULT_LOADING } = useQuery({
    queryKey: ['numerical-property', codeOrUuid, 'test-conditions'],
    queryFn: () => getNumericalPropertyTestConditions(codeOrUuid)
  });

  return testConditions;
}

export default useMetaNumericalPropertyTestConditions;
