import React, { useMemo, useState } from 'react';
import { Image, Upload } from 'antd';
import PropTypes from 'prop-types';
import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined
} from '@ant-design/icons';

const imageExtensions = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'heic',
  'heif',
  'bmp',
  'tiff',
  'tif',
  'webp'
];

const docExtensions = ['pdf', 'doc', 'docx'];

const getExtension = (file) => {
  const urlParts = file.url.split('.');
  return urlParts[urlParts.length - 1].toLowerCase();
};

const isImage = (file) => imageExtensions.includes(getExtension(file));
const isDoc = (file) => docExtensions.includes(getExtension(file));

// format the modify column
function FileList({ value }) {
  const fileList = useMemo(
    () => (value ? djangoFilestoAntdFiles(value) : []),
    [value]
  ); // these are the antd file objects
  const imagesList = useMemo(
    () => fileList.filter((file) => isImage(file)),
    [fileList]
  );

  const [previewing, setPreviewing] = useState();
  const [current, setCurrent] = useState();

  return (
    <div className="upload-documents">
      <Upload
        name="file"
        iconRender={(file) => {
          if (isImage(file)) return <FileImageOutlined />;

          if (isDoc(file)) return <FilePdfOutlined />;

          return <FileOutlined />;
        }}
        multiple
        onPreview={(file) => {
          if (isImage(file)) {
            setPreviewing(true);
            setCurrent(imagesList.findIndex((img) => img.uid === file.uid));
          } else window.open(file.url, '_blank');
        }}
        fileList={fileList}
        showUploadList={{
          showPreviewIcon: false,
          showDownloadIcon: false,
          showRemoveIcon: false
        }}
      />
      <div style={{ height: 0 }}>
        <Image.PreviewGroup
          preview={{
            visible: previewing,
            current,
            onVisibleChange: (visible) => setPreviewing(visible),
            onChange: (newIndex) => setCurrent(newIndex)
          }}
        >
          {imagesList.map((img) => (
            <Image
              style={{ display: 'none' }}
              key={img.uid}
              title={img.name}
              src={img.url}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
}

export const validate = () => true;

export default FileList;

FileList.propTypes = {
  value: PropTypes.array
};

function djangoFilestoAntdFiles(files) {
  return files.map(
    (file) =>
      file && {
        uid: file.uuid,
        name: file.title || file.file_name, // Newly uploaded docs have file_name instead of title
        status: 'done',
        url: file.url,
        response: { ...file }
      }
  );
}
