const MIN_DIMENSION_SIZE = 8;

const MIN_LATITUDE = -90;
const MAX_LATITUDE = 90;
const MIN_LONGITUDE = -180;
const MAX_LONGITUDE = 180;

/**
 * Takes in an array of points in [lat, lng] format and returns bounding rectangle's
 * southwestern and northeastern corner using the same format
 *
 * @param {[[Number]]} points Array of points in [lat, lng] format
 * @param {Number} padding padding **percent** to add around rectangle. Defaults to 0.3
 * @returns {[[Number]]} Array with two points: [southwestern corner, northeastern corner]
 */
export default function getBoundingCoords(points, padding = 0.3) {
  let southBound = null;
  let northBound = null;
  let eastBound = null;
  let westBound = null;

  points.forEach(([lat, lng]) => {
    if (northBound === null || lat > northBound) northBound = lat;
    if (southBound === null || lat < southBound) southBound = lat;
    if (eastBound === null || lng > eastBound) eastBound = lng;
    if (westBound === null || lng < westBound) westBound = lng;
  });

  const width = eastBound - westBound;
  const height = northBound - southBound;

  if (width < MIN_DIMENSION_SIZE) {
    westBound -= (MIN_DIMENSION_SIZE - width) / 2;
    eastBound += (MIN_DIMENSION_SIZE - width) / 2;
  }

  if (height < MIN_DIMENSION_SIZE) {
    southBound -= (MIN_DIMENSION_SIZE - height) / 2;
    northBound += (MIN_DIMENSION_SIZE - height) / 2;
  }

  return [
    [
      Math.max(westBound - padding * (eastBound - westBound), MIN_LONGITUDE),
      Math.max(southBound - padding * (northBound - southBound), MIN_LATITUDE)
    ],
    [
      Math.min(eastBound + padding * (eastBound - westBound), MAX_LONGITUDE),
      Math.min(northBound + padding * (northBound - southBound), MAX_LATITUDE)
    ]
  ];
}
