import React from 'react';
import PropTypes from 'prop-types';
import SvgIconComponent from './SvgIconComponent';

function CirclePlain({ diameter = 16, color }) {
  return (
    <SvgIconComponent>
      <div
        style={{
          width: diameter,
          height: diameter,
          border: `1px solid ${color || '#AAB0BB'}`,
          borderRadius: '50%',
          boxSizing: 'border-box'
        }}
      />
    </SvgIconComponent>
  );
}
CirclePlain.propTypes = {
  diameter: PropTypes.number,
  color: PropTypes.string
};

export default CirclePlain;
