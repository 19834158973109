import React from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { getInsightsAnalysis, getInsightsNews } from 'src/Query';
import { Divider } from 'antd';

function NewsCurrentStory({ index, story }) {
  const avatar = story.submitter?.avatar;

  return (
    <a target="_blank" href={story.url}>
      <div className="insights-news--current-story">
        <div className="story-index">{index + 1}</div>
        <div className="story">
          <h3>{story.title}</h3>
          <div className="source">
            {story.source}
            <span>{dayjs(story.timestamp).format('MMMM DD, YYYY')}</span>
          </div>
          <div className="submitter">
            <div>{avatar && <img src={avatar} alt="Submitter" />}</div>
            <div>{story.comment}</div>
          </div>
        </div>
      </div>
      <Divider />
    </a>
  );
}

NewsCurrentStory.propTypes = {
  story: PropTypes.object,
  index: PropTypes.number
};

function NewsPreviousStory({ index, story }) {
  const timestamp = new Date();
  return (
    <a target="_blank" href={story.url}>
      <div className="insights-news--previous-story">
        <div>{story.source}</div>
        <h4>{story.title}</h4>
        <Divider />
      </div>
    </a>
  );
}

NewsPreviousStory.propTypes = {
  story: PropTypes.object,
  index: PropTypes.number
};

function NewsCurrentPicks({ stories }) {
  const timestamp = new Date();
  return (
    <div className="insights-news--current">
      {stories.map((story, index) => (
        <NewsCurrentStory key={story.uuid} story={story} index={index} />
      ))}
    </div>
  );
}

NewsCurrentPicks.propTypes = {
  stories: PropTypes.array
};

function NewsPreviousPicks({ stories }) {
  const timestamp = new Date();
  return (
    <div className="insights-news--previous">
      {stories.map((story, index) => (
        <NewsPreviousStory key={story.uuid} story={story} index={index} />
      ))}
    </div>
  );
}

NewsPreviousPicks.propTypes = {
  stories: PropTypes.array
};

function Analysis() {
  const { data, isLoading, isError } = useQuery(['insights-analysis'], () =>
    getInsightsAnalysis({ page_size: 1 })
  );
  return (
    <div className="insights-news--analysis">
      <ReactMarkdown className="markdown" linkTarget="_blank">
        {data?.results[0].content}
      </ReactMarkdown>
    </div>
  );
}

function News() {
  const { data, isLoading, isError } = useQuery(['insights-news'], () =>
    getInsightsNews({ page_size: 10 })
  );
  const news = data ? data.results : [];
  return (
    <div className="insights-news">
      <div className="insights-news--current-bar">
        <h2>Our analysis</h2>
        <Analysis />
        <h2>Latest picks</h2>
        <NewsCurrentPicks stories={news.slice(0, 3) || []} />
      </div>
      <div className="insights-news--previous-bar">
        <h2>Previous picks</h2>
        <NewsPreviousPicks stories={news.slice(3) || []} />
      </div>
    </div>
  );
}

export default News;
