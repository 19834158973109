import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

/**
 * Displays tooltip if the text is truncated by css (e.g. with)
 *   text-overflow: ellipsis;
 *   overflow: hidden;
 *   white-space: nowrap;
 */
function TruncatableLabel({ text, className }) {
  const ref = useRef();
  const [truncated, setTruncated] = useState(false);
  useEffect(() => {
    setTruncated(ref.current.offsetWidth < ref.current.scrollWidth);
  });
  return (
    <span className={className} ref={ref}>
      {truncated ? <Tooltip title={text}>{text}</Tooltip> : text}
    </span>
  );
}

TruncatableLabel.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default TruncatableLabel;
