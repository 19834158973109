/**
 * When displaying physical values in units, we want to display a value that is
 * greater than 1, but otherwise as small as possible,
 * for example:
 *    1.3m rather than 1300mm or 0.0013km.
 *    2.3h rather than 8280s or 0.00026 years
 *
 * These utilities determine the units that work best and convert values to these units
 * The conversions are based on a map (per unit type), the key of which must be the unit_type
 * defined in the API and the sub-keys being the possible unit values plus any others we want to add to improve
 * scaling. For example, if the time unit_type has s and h units, then these must both be in the map,
 * and we can also add day and year
 *
 * Currently we only do this for time. When we add other unit types, we need to handle different unit systems
 * which this currently does not do, because there is only one system we use for time.
 */

const unitMap = {
  time: {
    s: 1,
    h: 3600,
    day: 86400,
    year: 86400 * 365.25
  }
};

// returnns true if a is a better number than b for display purposes
function better(a, b) {
  if (a < 1 && b > 1) return false;
  if (a > 1 && b < 1) return true;
  return Math.abs(Math.log(a)) < Math.abs(Math.log(b));
}

// converts min max pair from one unit to another given a specific unit map
function convert(map, min, max, unitFrom, unitTo) {
  const conversion = map[unitFrom] / map[unitTo];
  const minOut = min && conversion ? min * conversion : min;
  const maxOut = max && conversion ? max * conversion : max;
  return [minOut, maxOut, unitTo];
}

// converts min max pair to the units that display the best using given a specific unit map
function normalize(map, min, max, unit) {
  const testValue = max || min;
  let bestUnit = null;
  let bestValue = 0;
  Object.keys(map).forEach((u) => {
    const [testValueConverted, _, unitConverted] = convert(
      map,
      testValue,
      null,
      unit,
      u
    );

    if (!bestUnit || better(testValueConverted, bestValue)) {
      bestValue = testValueConverted;
      bestUnit = u;
    }
  });
  return convert(map, min, max, unit, bestUnit);
}

export function useUnitNormalize(min, max, unit, unitType, override) {
  if ((!min && !max) || !unit) return [min, max, unit];
  const map = unitMap[unitType];
  if (map) {
    if (override) return convert(map, min, max, unit, override);
    return normalize(map, min, max, unit);
  }
  return [min, max, unit];
}
