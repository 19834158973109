import { Checkbox } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';

export default function useColumnManager(originalColumns) {
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    const previosulyHiddenColumns =
      localStorage.getItem('tdsTableHiddenColumns')?.split(',') || [];

    if (Array.isArray(originalColumns)) {
      setCheckedList(
        originalColumns
          .map((col) => col.dataIndex)
          .filter((dataIndex) => !previosulyHiddenColumns.includes(dataIndex))
      );
    }
  }, [originalColumns]);

  const updatedColumns = useMemo(() => {
    const hiddenColumnsDataIndex = [];
    const columnsWithHiddenAttribute = originalColumns.map((item) => {
      const hidden = !checkedList.includes(item.dataIndex);

      if (hidden) hiddenColumnsDataIndex.push(item.dataIndex);
      return {
        ...item,
        hidden
      };
    });

    return columnsWithHiddenAttribute;
  }, [checkedList]);

  const Checkboxes = useMemo(
    () => (
      <Checkbox.Group
        className="mb-sm px-sm columns-manager"
        value={checkedList}
        options={originalColumns.map((col) => ({
          label:
            typeof col.title === 'function'
              ? col.title({})
              : col.title
              ? col.title
              : String(col.hiddenTitle),
          value: col.dataIndex
        }))}
        onChange={(value) => {
          setCheckedList(value);
          const hiddenColumns = originalColumns
            .filter((item) => !value.includes(item.dataIndex))
            .map(({ dataIndex }) => dataIndex);
          localStorage.setItem(
            'tdsTableHiddenColumns',
            hiddenColumns.join(',')
          );
        }}
      />
    ),
    [checkedList, originalColumns]
  );

  return {
    Checkboxes,
    updatedColumns
  };
}
