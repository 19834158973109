import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  CaretDownFilled,
  CaretUpFilled,
  CloudDownloadOutlined,
  MinusOutlined
} from '@ant-design/icons';
/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
/* eslint-enable import/no-unresolved */

import { useMultipleMarketPriceSeries } from 'src/Query/marketPrice';
import AnalyticsTile from 'src/components/analytics/AnalyticsTile';
import { currencyValuePrice } from 'src/utils/currency';
import { sourcesListDetailsArray, sourcesListNamesArray } from './utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
);

const colors = [
  '#D48806',
  '#0D33CC',
  '#13C2C2',
  '#EB2F96',
  '#2F54EB',
  '#08979C',
  '#EB2F96'
];

const lineChartOptions = {
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5
      },
      border: {
        dash: [2, 2]
      }
    },
    y: {
      position: 'right',
      ticks: {
        maxTicksLimit: 5
      },
      border: {
        dash: [2, 2]
      }
    }
  },
  elements: {
    line: {
      borderWidth: 1
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const dataSkeleton = {
  datasets: [
    {
      data: [
        { x: 0, y: 0 },
        { x: 1, y: 1 }
      ],
      fill: false,
      borderColor: '#eee',
      tension: 0.05
    }
  ]
};

const skeletonColor = '#e8e8e8';

const lineChartOptionsSkeleton = {
  // animations: false,
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      grid: {
        display: false
      },
      border: {
        color: skeletonColor
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      border: {
        color: skeletonColor
      }
    }
  },
  elements: {
    point: {
      pointStyle: false
    },
    line: {
      borderWidth: 1
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const priceMid = (price) =>
  (price && (Number(price.high) + Number(price.low)) / 2) || undefined;

const generateCsv = (seriesList, titleList) => {
  const descendingCurves = seriesList
    .filter((p) => !!p)
    .map((series) => [...series.curve].reverse());
  let results = `timestamp,${titleList.join(',')}\n`;
  const maxLength = Math.max(...descendingCurves.map((curve) => curve.length));
  if (maxLength) {
    const longestCurve = descendingCurves.find((p) => p.length === maxLength);
    longestCurve.forEach((point, line) => {
      results += `${point.timestamp},${descendingCurves
        .map((curve) => priceMid(curve[line]) || '')
        .join(',')}\n`;
    });
  }
  return new Blob([results], { type: 'text/csv;charset=utf-8' });
};

export default function AnalyticsTickerLineChart({
  tickers,
  colorSeed,
  tileTitle,
  allowDownload = true,
  highlightFirstTicker = true
}) {
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const { isLoading, seriesList } = useMultipleMarketPriceSeries(tickers);

  const data = useMemo(
    () => ({
      datasets: seriesList.map((series, index) => ({
        data:
          series?.curve?.map((point) => ({
            x: dayjs(point.timestamp).unix() * 1000,
            y: priceMid(point)
          })) || [],
        fill: false,
        borderColor: colors[index + (colorSeed || 0)],
        borderDash: index !== 0 ? [4, 3] : undefined,
        borderWidth: index !== 0 ? 1 : 1.5,
        tension: 0.05,
        elements: {
          point: {
            pointStyle: true,
            backgroundColor: (ctx) =>
              index !== 0 ? 'transparent' : ctx?.element?.options?.borderColor,
            pointRadius: (ctx) =>
              series?.curve?.length && ctx.dataIndex === series.curve.length - 1
                ? 2
                : 0
          }
        }
      }))
    }),
    [seriesList]
  );

  const seriesDates = seriesList
    .map((series) => {
      if (!series?.latest?.timestamp) return undefined;

      return new Date(series.latest.timestamp);
    })
    .filter((p) => !!p);

  const latestDate =
    seriesDates.length > 0
      ? dayjs(Math.max(...seriesDates)).format('MMM DD, YYYY' || '')
      : undefined;

  const latestPrice =
    isLoading || !seriesList?.[0]?.curve
      ? null
      : priceMid(seriesList[0].curve.slice(-1)[0]);
  const previousPrice =
    isLoading || !seriesList?.[0]?.curve
      ? null
      : priceMid(seriesList[0].curve.slice(-2)[0]);

  const titles = tickers.map((ticker, index) => ({
    text: ticker.title,
    color: colors[index + (colorSeed || 0)]
  }));
  const tickersArray = seriesList?.map((p) => p?.tickers) || [];
  const sourceDetails = sourcesListDetailsArray(tickersArray);
  const source = sourcesListNamesArray(tickersArray);
  const download = async () => {
    try {
      const titleList = titles.map((t) => t.text);
      setDownloadingCsv(true);
      const blob = generateCsv(seriesList, titleList);
      saveAs(blob, `${titleList.join('--')} ${latestDate}.csv`);
    } finally {
      setDownloadingCsv(false);
    }
  };

  return (
    <AnalyticsTile
      tileTitle={tileTitle}
      highlight={
        highlightFirstTicker ? (
          <>
            <span className="price">
              {currencyValuePrice(latestPrice, 'USD')}
            </span>
            <PriceDifference
              previousPrice={previousPrice}
              latestPrice={latestPrice}
            />
          </>
        ) : null
      }
      source={source}
      sourceDetails={sourceDetails}
      latest={latestDate}
      titles={titles}
      action={
        allowDownload ? <CloudDownloadOutlined onClick={download} /> : null
      }
    >
      {isLoading ? (
        <Line data={dataSkeleton} options={lineChartOptionsSkeleton} />
      ) : (
        <Line data={data} options={lineChartOptions} />
      )}
    </AnalyticsTile>
  );
}

AnalyticsTickerLineChart.propTypes = {
  tickers: PropTypes.arrayOf(PropTypes.object),
  colorSeed: PropTypes.number,
  tileTitle: PropTypes.string,
  allowDownload: PropTypes.bool,
  highlightFirstTicker: PropTypes.bool
};

function PriceDifference({ previousPrice, latestPrice }) {
  // amount difference
  const difference = latestPrice - previousPrice;
  // percent difference
  const delta = difference / previousPrice;

  const red = '#F5222D';
  const green = '#389E0D';
  const deltaText = Math.round(delta * 100);

  let deltaIndicator = (
    <span className="price-delta compact" style={{ color: '#999999' }}>
      <MinusOutlined /> 0%
    </span>
  );

  if (deltaText > 0) {
    deltaIndicator = (
      <span className="price-delta compact" style={{ color: green }}>
        <CaretUpFilled /> {currencyValuePrice(Math.abs(difference))}&nbsp;&nbsp;
        {deltaText}%
      </span>
    );
  } else if (deltaText < 0) {
    deltaIndicator = (
      <span className="price-delta compact" style={{ color: red }}>
        <CaretDownFilled /> {currencyValuePrice(Math.abs(difference))}
        &nbsp;&nbsp;{deltaText}%
      </span>
    );
  }

  return deltaIndicator;
}
PriceDifference.propTypes = {
  previousPrice: PropTypes.number,
  latestPrice: PropTypes.number
};
