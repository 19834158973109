import React from 'react';
import FormPageLayout from 'src/components/layout/FormPageLayout';
import { useLocation } from 'react-router-dom';

function MagicLink() {
  const { state } = useLocation();
  return (
    <FormPageLayout>
      <div className="magic-link-waiting-page">
        <p>
          An email has been sent to {state?.email || 'your email address'} to
          verify that you are authorized to access this data. Please click on
          the link in the email.
        </p>
      </div>
    </FormPageLayout>
  );
}

export default MagicLink;
