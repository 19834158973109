import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SplashPage from 'src/components/layout/SplashPage';
import Review from './Review';
import Proposals from './proposal/Proposal';

function BuyerDeal() {
  return (
    <SplashPage>
      <div id="rfq" className="onboard">
        <div className="onboard-content">
          <Routes>
            <Route path="/quote/:rfqId/review" element={<Review />} />
            <Route path="/quote/:rfqId/proposals" element={<Proposals />} />
          </Routes>
        </div>
      </div>
    </SplashPage>
  );
}

export default BuyerDeal;
