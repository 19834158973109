import { Grid } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAuthentication, useUser } from 'src/utils/authentication';
import useAtTop from 'src/hooks/useAtTop';
import { DASHBOARD_URL } from 'src/pages/auth/SignIn';
import LandingMenu from './LandingMenu';

import './LandingHeader.less';

export const generalMenuItems = [
  {
    label: 'Solutions',
    href: '/#solutions',
    key: 'solutions'
  },
  {
    label: 'How It Works',
    href: '/#how-it-works',
    key: 'how-it-works'
  },
  {
    label: 'Company',
    href: '/company',
    key: 'company'
  }
];

export const signedOutMenuItems = [
  {
    label: 'Get Started',
    href: '/request-for-information',
    key: 'get-started',
    class: 'button'
  },
  {
    label: 'Sign in',
    href: '/login',
    key: 'signIn'
  }
];

export const signedInMenuItems = [
  {
    label: 'Dashboard',
    href: DASHBOARD_URL,
    key: 'dashboard'
  }
];

function LandingHeader({
  light,
  atTopBreakpoint = 50,
  itemsOverride,
  noCollapse
}) {
  const atTop = useAtTop(atTopBreakpoint);
  const { isRestricted } = useAuthentication();
  const { data: user } = useUser();
  const menuItems = useMemo(
    () =>
      Array.isArray(itemsOverride)
        ? itemsOverride
        : [
            ...generalMenuItems,
            ...(user && !isRestricted ? signedInMenuItems : signedOutMenuItems)
          ],
    [user, itemsOverride]
  );
  const bp = Grid.useBreakpoint();

  return (
    <div className={`landing-header-wrap ${atTop ? 'transparent' : ''}`}>
      <LandingMenu
        mobile={noCollapse ? false : !bp.lg}
        light={atTop && light}
        items={menuItems}
      />
    </div>
  );
}

LandingHeader.propTypes = {
  light: PropTypes.bool,
  noCollapse: PropTypes.bool,
  atTopBreakpoint: PropTypes.number,
  itemsOverride: PropTypes.array
};

export default LandingHeader;
