import React from 'react';
import PropTypes from 'prop-types';

export default function DisableableContent({ disabled, children }) {
  return (
    <div className={`disableable-content ${disabled ? 'is-disabled' : ''}`}>
      {children}
    </div>
  );
}
DisableableContent.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};
