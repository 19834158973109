import { Row, Col, Grid } from 'antd';
import React from 'react';
import ImagesGrid from 'src/components/utils/ImagesGrid';

import bluePellets from 'src/pages/landing/images/blue_pellets.jpeg';
import carInterior from 'src/pages/landing/images/carInterior2.jpeg';
import womanTablet from 'src/pages/landing/images/womanTablet.png';

import './About.less';

export default function About() {
  const bp = Grid.useBreakpoint();

  return (
    <div id="about">
      <div className="about-inner">
        <Row justify="center" align="top">
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 20 }}
            lg={{ span: 12, order: 0, offset: 0 }}
          >
            <p>
              Climate change is rapidly impacting consumer demand, legislation,
              technology and global economics. It is inevitable that
              manufacturers will transition to sustainable commodities and
              efficient digital workflows. The question is not if but when.
              Circular&apos;s mission is to power and expedite that industrial
              evolution.
            </p>
            <p>
              We solve the core issues blocking manufacturers from adopting
              sustainable materials, namely availability, price and consistent
              quality. Our digital platform includes a marketplace to connect
              buyers and sellers easily at network scale; supply chain tools to
              manage testing, logistics and de-risk material inconsistencies;
              plus emissions measurement tools to increase the total value of
              using PCR.
            </p>
            <p>
              As we grow we will expand to adjacent sustainable categories
              beyond our initial focus on recycled plastics, and add
              sophisticated financial tools to help foster global trade. Our
              platform makes sustainable materials more economically attractive,
              powers a more efficient circular supply chain, and helps
              transition global manufacturers to 100% sustainable commodities.
            </p>

            <p>
              Toward a circular future,
              <br />
              Ian Arthurs
              <br />
              Founder & CEO
            </p>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 20 }} lg={{ span: 11, offset: 1 }}>
            <ImagesGrid
              columns={2}
              rows={`repeat(2, ${!bp.sm ? '150px' : '240px'})`}
            >
              <ImagesGrid.Image src={womanTablet} column={1} row={1} />
              <ImagesGrid.Image src={bluePellets} column={1} row={2} />
              <ImagesGrid.Image src={carInterior} column={2} row="1 / -1" />
            </ImagesGrid>
          </Col>
        </Row>
      </div>
    </div>
  );
}
