import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';

const useAllProperties = () => {
  const primaryKpis = usePrimaryKpis();
  const [allProperties] = useConciergeContextState([
    'explore',
    'filters',
    'properties'
  ]);
  return Object.keys(allProperties || {});
};
export default useAllProperties;
