import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getNumericalProperties } from 'src/Query';

// it is important to make this a constant or otherwise the hook returns a new instance of an empty array
// on every call
const DEFAULT_LOADING = [];

function useMetaNumericalPropertyNameFromCode(codes) {
  const { data: metaProperties = DEFAULT_LOADING } = useQuery({
    queryKey: ['numerical-properties'],
    queryFn: () => getNumericalProperties()
  });
  const optionNameMap = useMemo(
    () =>
      metaProperties?.reduce((agg, property) => {
        // eslint-disable-next-line
        agg[property.code] = property.name;
        return agg;
      }, {}),
    [metaProperties]
  );
  if (!optionNameMap || !codes) return codes;
  return codes.map((code) => optionNameMap[code]);
}

export default useMetaNumericalPropertyNameFromCode;
