const colorParse = (input) => {
  const m3 = input.match(/^#([0-9a-f]{3})$/i);
  if (m3) {
    const n3 = m3[1];
    // in three-character format, each value is multiplied by 0x11 to give an
    // even scale from 0x00 to 0xff
    return [
      parseInt(n3.charAt(0), 16) * 0x11,
      parseInt(n3.charAt(1), 16) * 0x11,
      parseInt(n3.charAt(2), 16) * 0x11,
      0
    ];
  }

  const m6 = input.match(/^#([0-9a-f]{6})$/i);
  if (m6) {
    const n6 = m6[1];
    return [
      parseInt(n6.substr(0, 2), 16),
      parseInt(n6.substr(2, 2), 16),
      parseInt(n6.substr(4, 2), 16),
      0
    ];
  }
  const m8 = input.match(/^#([0-9a-f]{8})$/i);
  if (m8) {
    const n8 = m8[1];
    return [
      parseInt(n8.substr(0, 2), 16),
      parseInt(n8.substr(2, 2), 16),
      parseInt(n8.substr(4, 2), 16),
      parseInt(n8.substr(6, 2), 16)
    ];
  }
  return [255, 255, 255, 255];
};

export default colorParse;
