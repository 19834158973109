import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import calcCrow from './calcCrow';

export default function renderDistance(loc1, loc2) {
  if (
    !loc1?.latitude ||
    !loc1?.longitude ||
    !loc2?.latitude ||
    !loc2?.longitude
  )
    return '(Unknown)';

  const distanceKm = calcCrow(
    loc1.latitude,
    loc1.longitude,
    loc2.latitude,
    loc2.longitude
  );

  return `${prettyNumberRound(distanceKm)} km`;
}
