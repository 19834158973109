import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Icon } from './svg/marker-large-icon.svg';
import SvgIconComponent from './SvgIconComponent';

function MarkerLargeIcon({ style, color, ...props }) {
  const combinedStyle = { ...(style || {}), color: color || '#2F54EB' };
  return (
    <SvgIconComponent style={combinedStyle} {...props}>
      <Icon style={{ height: 50, width: 50 }} />
    </SvgIconComponent>
  );
}

MarkerLargeIcon.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string
};

export default MarkerLargeIcon;
