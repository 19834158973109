// eslint-disable-next-line
import './styles/App.less';

// Polyfill for Promise.withResolvers, used by PDFjs
import '@ungap/with-resolvers';

import React, { useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import countries from 'i18n-iso-countries';
import { Alert, Button, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ErrorBoundary } from '@sentry/react';

import { useUser } from 'src/utils/authentication';
import BuyerProcessOverview from 'src/pages/rfq/BuyerProcessOverview';
import SellerProcessOverview from 'src/pages/supplier/SellerProcessOverview';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import ScrollToTop from './components/utils/ScrollToTop';
import Company from './pages/landing/Company';
import AppLayout from './components/layout/AppLayout';
import RequestForInformation from './pages/leads/RequestForInformation';
import SignUp from './pages/auth/SignUp';
import SignIn, { DASHBOARD_URL } from './pages/auth/SignIn';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import TermsAndConditions from './pages/legal/Terms';
import TermsAndConditionsOld from './pages/legal/TermsOld';
import Privacy from './pages/legal/Privacy';
import LaunchPressRelease from './pages/press/LaunchPressRelease';
import Pendo from './components/tracking/Pendo';
import Hotjar from './components/tracking/Hotjar';
import GoogleAnalytics from './components/tracking/GoogleAnalytics';
import { CookiePolicyProvider } from './components/cookiePolicy';
import GetStarted from './pages/auth/GetStarted';
import Lounge from './pages/auth/Lounge';
import MagicLink from './pages/auth/MagicLink';
import MagicLinkWaiting from './pages/auth/MagicLinkWaiting';
import { NotificationProvider } from './components/notification/NotificationContext';
import Salesflare from './components/tracking/Salesflare';
import SesameLogin from './components/sesame/SesameLogin';
import ChromeExtension from './pages/chromeExtension';
import PlasticsUncovered from './pages/reports/PlasticsUncovered';
import IndustryBriefRedirect from './pages/reports/IndustryBriefRedirect';
import HeatmapPage from './pages/viz/Heatmap';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
dayjs.extend(relativeTime);

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools
    })
  )
);

function AppPagesWithFooter() {
  return (
    <Routes>
      <Route exact path="/magic-link/waiting" element={<MagicLinkWaiting />} />
      <Route path="/magic-link/:id" element={<MagicLink />} />
      <Route path="/lounge" element={<Lounge />} />
      <Route path="/launch-release" element={<LaunchPressRelease />} />
      <Route path="/chrome-extension" element={<ChromeExtension />} />
      <Route path="*" element={<AppLayout />} />
    </Routes>
  );
}

function AppPages() {
  const { data: user } = useUser();
  const { boot, shutdown } = useIntercom();

  const intercomBootParams = useMemo(
    () =>
      user && {
        email: user.email,
        name: user.name,
        userHash: user.intercom_hmac_hash,
        customAttributes: {
          company_uuid: user.company?.uuid,
          company_name: user.company?.name
        }
      },
    [user]
  );
  useEffect(() => {
    shutdown();
    boot(intercomBootParams);
  }, [intercomBootParams, boot]);
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/project/dashboard" />} />
      <Route exact path="/company" element={<Company />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/terms-old" element={<TermsAndConditionsOld />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/sesame/login" element={<SesameLogin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/signup" element={<SignUp />} />
      <Route
        path="/buyer-process-overview"
        element={<BuyerProcessOverview />}
      />
      <Route
        path="/seller-process-overview"
        element={<SellerProcessOverview />}
      />
      <Route
        path="/request-for-information"
        element={<RequestForInformation next="/" />}
      />
      <Route path="/get-started" element={<GetStarted />} />
      <Route
        path="/reports/industry-brief-plastics-uncovered"
        element={<IndustryBriefRedirect />}
      />
      <Route path="/industrybrief" element={<PlasticsUncovered />} />
      <Route path="/viz-heatmap" element={<HeatmapPage />} />

      <Route path="*" element={<AppPagesWithFooter />} />
    </Routes>
  );
}

function Tracker({ user }) {
  useEffect(() => {
    if (window.hj && user) {
      const attributes = {
        Role: user.is_staff ? 'Staff' : 'User',
        'Signed up': user.created_at
      };
      if (user.company) {
        attributes.Company = user.company.name;
      }
      window.hj('identify', user.id, attributes);
    }
  }, [user]);
  return <span></span>;
}

Tracker.propTypes = {
  user: PropTypes.object
};

function ErrorFallback({ error, componentStack, resetError }) {
  // const navigate = useNavigate();
  return (
    <div className="app-error-fallback">
      <p>
        That is embarassing, it looks like we had an error. We have received an
        error report and will work on fixing it. Please contact us if it
        persists.
      </p>
      <Button
        type="primary"
        onClick={() => {
          resetError();
        }}
      >
        Continue
      </Button>
      <p>
        Or <a href={DASHBOARD_URL}>click here</a> to return to the dashboard.
      </p>
      <Alert
        type="error"
        message={error.message}
        description={<pre>{componentStack}</pre>}
      ></Alert>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  componentStack: PropTypes.string,
  resetError: PropTypes.func
};

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

function App() {
  const [showDevtools, setShowDevtools] = useState(false);
  const { data: user } = useUser();
  const isDev =
    window.location?.origin?.includes('localhost') ||
    window.location?.origin?.includes('127.0.0.1') ||
    window.location?.origin?.includes('https://dev') ||
    window.location?.origin?.includes('https://preview');

  useEffect(() => {
    // this can be disabled or hidden under a code and a password that changes on every deploy
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  /* eslint-disable prefer-arrow-callback */

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: process.env.REACT_APP_BRAND_PRIMARY_COLOR,
          controlHeight: 36,
          fontFamily: 'Roboto, Arial, sans-serif',
          borderRadius: 2
        },
        components: {
          Select: {
            optionSelectedBg: 'rgb(209, 218, 226)',
            controlItemBgActive: 'rgb(209, 218, 226)' // v 5.5.1 can remove after upgrade
          },
          Button: {
            borderRadius: 2
          }
        }
      }}
    >
      <HelmetProvider>
        {isDev && (
          <Helmet>
            <title>(DEV) Circular.co</title>
          </Helmet>
        )}
        <IntercomProvider
          // autoBoot is only used for non-logged-in users. For logged in
          // users we manually boot in <AppPages/>
          autoBoot
          appId={INTERCOM_APP_ID}
        >
          <ErrorBoundary fallback={ErrorFallback}>
            <CookiePolicyProvider>
              <Router>
                <Pendo />
                <Salesflare />
                <Hotjar />
                <GoogleAnalytics />
                <ScrollToTop />
                <div className={`main ${isDev && 'dev'}`}>
                  <div className="app-content">
                    <NotificationProvider>
                      <AppPages />
                    </NotificationProvider>
                  </div>
                </div>
              </Router>
              <Tracker user={{ user }} />
              {showDevtools && (
                <React.Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction />
                </React.Suspense>
              )}
            </CookiePolicyProvider>
          </ErrorBoundary>
        </IntercomProvider>
      </HelmetProvider>
    </ConfigProvider>
  );
}

export default App;
