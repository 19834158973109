import { Avatar, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useMap from 'src/pages/maps/hooks/useMap';
import { Marker } from 'src/pages/maps/Marker';
import getBoundingCoords from 'src/pages/maps/utils/getBoundingCoords';
import { UserOutlined } from '@ant-design/icons';

/* eslint-disable react/no-array-index-key */

export default function CompanyLocationsMap({ company }) {
  const [bounds, setBounds] = useState(undefined);
  const { map, mapContainer } = useMap({
    mapProps: { bounds },
    disableControls: true
  });

  const markers = useMemo(() => {
    if (!map) return [];

    const mapMarkers = [
      ...(company.locations?.length
        ? company.locations.map((loc, i) => (
            <MapMarker
              key={`${company.uuid}-${i}`}
              id={`${company.uuid}-${i}`}
              company={company}
              location={loc}
              map={map}
            />
          ))
        : [])
    ];

    const allPoints = [
      ...(company.locations?.length ? company.locations : [])
    ].map((loc) => [Number(loc.latitude), Number(loc.longitude)]);

    setBounds(getBoundingCoords(allPoints, 0.3));

    return mapMarkers;
  }, [company, map]);

  return <div ref={mapContainer}>{markers}</div>;
}
CompanyLocationsMap.propTypes = {
  company: PropTypes.object
};

function MapMarker({ company, location, map, id }) {
  if (!location?.latitude || !location?.longitude || !company) return null;

  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={20}
      latitude={Number(location.latitude)}
      longitude={Number(location.longitude)}
    >
      <Tooltip
        className="map-marker-tooltip"
        color="white"
        title={company.name}
        overlayInnerStyle={{ color: '#323640' }}
      >
        <Avatar
          icon={<UserOutlined />}
          src={company.avatar}
          alt={company.name}
          size={30}
        />
      </Tooltip>
    </Marker>
  );
}
MapMarker.propTypes = {
  company: PropTypes.object,
  location: PropTypes.object,
  map: PropTypes.object,
  id: PropTypes.string
};
