import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

const EMPTY_FILTERS = {};

const usePropertyFilters = (propertyCode) => {
  const filters = useRelevantFiltersForForm();

  if (!filters?.properties) return EMPTY_FILTERS;

  if (propertyCode) return filters.properties[propertyCode];

  return filters.properties;
};

export default usePropertyFilters;
