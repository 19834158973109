import React from 'react';
import PropTypes from 'prop-types';

export default function SingleCertification({ cert }) {
  return (
    <div className="supplier-profile--certs--single">
      {cert.image ? (
        <img src={cert.image.url} alt={cert.image.title} />
      ) : (
        <div className="dummy-cert-img">{cert.name}</div>
      )}
    </div>
  );
}

SingleCertification.propTypes = {
  cert: PropTypes.object
};
