import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDeal } from 'src/Query';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { StepList } from 'src/components/steps/StepList';
import { QueryStatus } from 'src/components/error/QueryStatus';
import DealParty from './DealParty';

function DealWelcome() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: deal,
    error,
    isLoading
  } = useQuery(['deal', id], () => getDeal(id));
  const steps = [
    {
      title: 'Sample testing & approval'
    },
    {
      title: 'Finalize deal terms'
    },
    {
      title: 'Sign contract'
    },
    {
      title: 'Facilitate delivery'
    }
  ];

  const dealContact = useMemo(
    () =>
      deal?.participants.find(
        (o) => o.entity === 'buyer' && o.role === 'decision_maker'
      )?.user,
    [deal]
  );

  const supplierContacts = useMemo(
    () =>
      deal?.participants
        .filter((o) => o.entity === 'seller' && o.role === 'decision_maker')
        .map((o) => o.user) || [],
    [deal]
  );

  const supplierContactForCompany = (uuid) =>
    supplierContacts.find((o) => o?.company.uuid === uuid);

  return (
    <div className="deal-welcome">
      <QueryStatus isLoading={isLoading} error={error} title="Deal room">
        {deal && (
          <>
            <div className="deal-welcome-banner">
              <h2>Welcome to your deal room</h2>
              <p>
                This is where we manage the sample testing process, finalize the
                terms of the deal, sign contracts, and work out logistics with
                suppliers.
              </p>
            </div>
            <div className="deal-welcome-details">
              <div className="deal-welcome-parties">
                <h4>Buyer</h4>
                <DealParty
                  companyName={deal?.rfq?.company?.name}
                  companyLogo={deal?.rfq?.company?.avatar}
                  dealContact={dealContact?.name}
                />
                <h4 className="sellers">Sellers</h4>
                {deal?.supplies.map((supply) => (
                  <DealParty
                    key={supply.uuid}
                    companyName={supply?.technical_data_sheet?.company?.name}
                    companyLogo={supply?.technical_data_sheet?.company?.avatar}
                    dealContact={
                      supplierContactForCompany(
                        supply?.technical_data_sheet?.company.uuid
                      )?.name
                    }
                    rating={supply?.rating}
                  />
                ))}
              </div>
              <div className="deal-welcome-steps">
                <h4>What happens next</h4>
                <StepList steps={steps} />
              </div>
            </div>
            <Button onClick={() => navigate(`/deal/${id}`)} type="primary">
              Let&apos;s get started
            </Button>
          </>
        )}
      </QueryStatus>
    </div>
  );
}

export default DealWelcome;
