import React from 'react';
import PropTypes from 'prop-types';
import logoDark from './Logo-CircularX.svg';
import logoLight from './Logo-CircularX-Light.svg';

function CircularLogo({ light }) {
  const logo = light
    ? logoLight
    : process.env.REACT_APP_BRAND_LOGO_FULL_DARK || logoDark;
  return <img className="circular-logo" src={logo} alt="Logo" />;
}

CircularLogo.propTypes = {
  light: PropTypes.bool
};

export default CircularLogo;
