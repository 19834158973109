import React from 'react';
import { ReactComponent as Icon } from './svg/quote.svg';
import SvgIconComponent from './SvgIconComponent';

function QuoteIcon() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default QuoteIcon;
