import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_VALUE = [
  {
    label: undefined,
    value: 1,
    type: 'empty'
  }
];

function BarProgress({ value = DEFAULT_VALUE }) {
  const total = value.reduce((acc, o) => acc + o.value, 0);
  return (
    <div className="bar-progress">
      <div className="bar-progress--bars">
        {value.map((cell, index) => (
          <div
            style={{ flex: `0 0 ${(cell.value / total) * 100}%` }}
            className={`box ${cell.type}`}
            key={index}
          ></div>
        ))}
      </div>
      <div className="bar-progress--labels">
        {value.map((cell, index) => (
          <div
            style={{ flex: `0 0 ${(cell.value / total) * 100}%` }}
            className={`box ${cell.type}`}
            key={index}
          >
            {cell.label}
          </div>
        ))}
      </div>
    </div>
  );
}

BarProgress.propTypes = {
  value: PropTypes.array
};

export default BarProgress;
