import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

function ProgressBar({ bucketData, colors }) {
  let background = '#eeeeee';
  if (bucketData) {
    const total = bucketData[bucketData.length - 1];
    /* eslint-disable no-return-assign,no-param-reassign */
    // const cumulativeSum = (
    //   (sum) => (value) =>
    //     (sum += value)
    // )(0);
    // /* eslint-enable no-return-assign,no-param-reassign */
    // const accumulated = bucketData.map(cumulativeSum);
    const accumulatedPercent = bucketData.map((v) => v / total);
    const colorList = accumulatedPercent
      .map((p, i) => `${colors[i]} ${Math.round((p === 1 ? 0 : p) * 100)}%`)
      .join(', ');
    background = `linear-gradient( to right, ${colorList})`;
  }
  return (
    <div className="progress-bar" style={{ height: '80px', background }}></div>
  );
}

ProgressBar.propTypes = {
  bucketData: PropTypes.array,
  colors: PropTypes.array
};

function SearchProgressPage() {
  const activity = 'index-supplier-website';
  // const { data } = useQuery(
  //   ['index-progress'],
  //   () => getProgress({ activity }),
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: true
  //   }
  // );

  const data = [
    {
      scrappy_log__created_at__date: null,
      count: 8227
    },
    {
      scrappy_log__created_at__date: '2023-09-30',
      count: 459
    },
    {
      scrappy_log__created_at__date: '2023-09-29',
      count: 597
    }
  ];

  const bucketData = useMemo(() => {
    if (!data) return undefined;
    const now = dayjs();
    const bigNum = 100000;
    const dataInDays = data.map((item) => ({
      days: item.scrappy_log__created_at__date
        ? now.diff(dayjs(item.scrappy_log__created_at__date), 'days')
        : bigNum,
      count: item.count
    }));
    const buckets = [0, 1, 7, 14, 31, 60, 90, bigNum + 1];
    return buckets.map((max_days) =>
      dataInDays.reduce(
        (acc, item) => acc + (item.days < max_days ? item.count : 0),
        0
      )
    );
  }, [data]);
  const colors = [
    '#4b73d8',
    '#2699ac',
    '#00bf80',
    '#60c24d',
    '#bfc519',
    '#ae38da',
    '#b77f7a',
    '#888888'
  ];

  return (
    <div className="index-progress-page">
      <div className="index-progress">
        <ProgressBar bucketData={bucketData} colors={colors} />
      </div>
    </div>
  );
}

export default SearchProgressPage;
