import React from 'react';
import { Col, Divider, Typography } from 'antd';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import CommodityIcon from 'src/components/icons/CommodityIcon';
import prettyNumber from 'src/components/utils/prettyNumber';
import { findProperty } from 'src/utils/properties';
import { currencySymbol } from 'src/utils/currency';

function Summary({ requestForQuote }) {
  const type = findProperty(requestForQuote, 'type');
  const form = findProperty(requestForQuote, 'form');

  return (
    <>
      <Col className="rfq-detail">
        <Typography.Paragraph>Material</Typography.Paragraph>
        <div>
          <img width={28} height={28} src={type?.image} alt={type?.value} />{' '}
          {type?.value}
        </div>
      </Col>
      <Divider type="vertical" />
      <Col className="rfq-detail">
        <Typography.Paragraph>Form</Typography.Paragraph>
        <div>
          <CommodityIcon symbol={form?.value} color="black" /> {form?.value}
        </div>
      </Col>
      <Divider type="vertical" />
      <Col className="rfq-detail">
        <Typography.Paragraph>Total Quantity</Typography.Paragraph>
        <div>
          {prettyNumber(requestForQuote?.quantity)}{' '}
          <span className="units">{requestForQuote?.quantity_units}</span>
        </div>
      </Col>
      <Divider type="vertical" />
      <Col className="rfq-detail">
        <Typography.Paragraph>Frequency</Typography.Paragraph>
        <div>
          {(requestForQuote?.frequency &&
            sentenceCase(requestForQuote?.frequency)) ||
            '—'}
        </div>
      </Col>
      <Divider type="vertical" />
      <Col className="rfq-detail">
        <Typography.Paragraph>Price</Typography.Paragraph>
        <div>
          {currencySymbol(requestForQuote?.currency)}
          {(requestForQuote?.price && prettyNumber(requestForQuote?.price)) ||
            '—'}{' '}
          <span className="units">{`/${requestForQuote?.quantity_units}`}</span>
        </div>
      </Col>
    </>
  );
}

Summary.propTypes = {
  requestForQuote: PropTypes.object
};

export default Summary;
