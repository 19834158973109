import React from 'react';
import PropTypes from 'prop-types';

export default function BarePageLayout({ children, className }) {
  return (
    <div className={`bare-page-layout ${className}`}>
      <div className="gradient-border" />
      {children}
    </div>
  );
}

BarePageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};
