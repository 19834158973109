import React from 'react';
import PropTypes from 'prop-types';
import { Form, List } from 'antd';

/* eslint-disable react/prop-types */

export function FormControlEmbeddedLabel({
  label,
  before,
  children,
  ...props
}) {
  return (
    <>
      <label htmlFor={props.id} className="form-control-embedded-label">
        {label}
      </label>
      {before && <div className="form-control--before">{before}</div>}
      {children(props)}
    </>
  );
}

FormControlEmbeddedLabel.propTypes = {
  label: PropTypes.string,
  before: PropTypes.string,
  children: PropTypes.func.isRequired
};

export default function ReviewFormItem({
  label,
  before,
  children,
  name,
  wide,
  ...formItemProps
}) {
  const child = Array.isArray(children) ? children[0] : children;
  const other_childs = Array.isArray(children) ? [...children].splice(1) : [];
  return (
    <List.Item>
      <Form.Item
        name={name}
        {...formItemProps}
        className={`${wide && 'wide'} ${formItemProps.className}`}
      >
        <FormControlEmbeddedLabel label={label} before={before}>
          {(props) => (
            <>
              {React.cloneElement(child, props)}
              {other_childs}
            </>
          )}
        </FormControlEmbeddedLabel>
      </Form.Item>
    </List.Item>
  );
}

ReviewFormItem.propTypes = {
  label: PropTypes.string,
  before: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  wide: PropTypes.bool
};
