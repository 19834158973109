import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTDSs } from './warehouse';

export function useGetWarehouseTdssKey(filters) {
  return useMemo(
    () => ['warehouse', 'technical-data-sheet', filters],
    [filters]
  );
}

export default function useGetWarehouseTdss(filters, options = {}) {
  const key = useGetWarehouseTdssKey(filters);
  return useQuery(key, () => getTDSs(filters), {
    keepPreviousData: true,
    // The table needs a unique ID for each TDS, so we generate one here
    select: addUniqueIdToTdss,
    ...options
  });
}

function getChunks(tds) {
  return [
    tds.type_code ?? null,
    tds.form_code ?? null,
    tds.city ?? null,
    tds.state ?? null,
    tds.country ?? null,
    tds.region_code ?? null,
    tds.property_additive_code ?? null,
    tds.property_application_code ?? null,
    tds.property_certs_code ?? null,
    tds.property_color_code ?? null,
    tds.property_flammability_code ?? null,
    tds.property_grade_code ?? null,
    tds.property_industry_code ?? null,
    tds['property_polymer-type_code'] ?? null,
    tds['property_processing-method_code'] ?? null,
    tds['property_source-type_code'] ?? null,
    tds.tds_id ?? null
  ].filter((chunk) => chunk !== null);
}

export function addUniqueIdToTdss(data) {
  if (!data?.results?.length) return data;

  const newResults = data.results.map((tds) => ({
    ...tds,
    id: getChunks(tds).join('-')
  }));

  return { ...data, results: newResults };
}
