import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

import './ImagesGrid.less';

export default function ImagesGrid({ rows, columns, children, style = {} }) {
  let gridTemplateRows = rows;
  if (typeof rows === 'number') {
    gridTemplateRows = `repeat(${rows}, minmax(0px, 1fr))`;
  }

  let gridTemplateColumns = columns;
  if (typeof columns === 'number') {
    gridTemplateColumns = `repeat(${columns}, minmax(0px, 1fr))`;
  }

  return (
    <div
      className="images-grid"
      style={{
        gridTemplateRows,
        gridTemplateColumns,
        ...style
      }}
    >
      {children}
    </div>
  );
}
ImagesGrid.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  style: PropTypes.object
};

function Item({ row, column, children, className, style = {} }) {
  return (
    <div
      className={className}
      style={{ gridRow: row, gridColumn: column, ...style }}
    >
      {children}
    </div>
  );
}

Item.propTypes = {
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
};

ImagesGrid.Item = Item;

function ImageGroup({ row, column, children, offset, iteration = 20000 }) {
  const [activeImage, setActiveImage] = useState(0);

  const nextImg = (prevActiveImage) =>
    prevActiveImage < children.length - 1 ? prevActiveImage + 1 : 0;

  useEffect(() => {
    if (offset && offset < iteration) {
      setTimeout(() => {
        setActiveImage(1);
      }, offset);
    }

    const interval = setInterval(() => {
      if (offset > 0) {
        setTimeout(() => {
          setActiveImage(nextImg);
        }, offset);
      } else {
        setActiveImage(nextImg);
      }
    }, iteration);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{ gridRow: row, gridColumn: column }}
      className="images-grid--group"
    >
      <AnimatePresence mode="wait" initial={false}>
        {children[activeImage]}
      </AnimatePresence>
    </div>
  );
}

ImageGroup.propTypes = {
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  offset: PropTypes.number,
  iteration: PropTypes.number
};

ImagesGrid.ImageGroup = ImageGroup;

function Image({ row, column, src, alt, style = {}, transition = {} }) {
  return (
    <motion.div
      key={src}
      style={{ gridRow: row, gridColumn: column }}
      className="images-grid--image"
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{
        opacity: { duration: 0.3 },
        ...transition
      }}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
      }}
    >
      <img src={src} alt={alt || src} style={style} />
    </motion.div>
  );
}

Image.propTypes = {
  row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  transition: PropTypes.object
};

ImagesGrid.Image = Image;

export { ImageGroup, Image };
