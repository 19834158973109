import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from 'src/utils/authentication';
import { usePerformance } from './hooks';

const prod = `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:3013526,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

const dev = `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2951083,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

const hotjarScripts = {
  'dev.circular.co': dev,
  'circular.co': prod,
  'www.circular.co': prod
};

function Hotjar() {
  const optOut = usePerformance();
  const { data: user } = useUser();
  useEffect(() => {
    if (typeof global.hj === 'function' && user) {
      global.hj('identify', user.uuid, {
        is_buyer: user.is_buyer,
        has_name: !!user.name,
        is_staff: user.is_staff,
        is_seller: user.is_seller,
        company_name: user.company && user.company.name
      });
    }
  }, [user]);

  const { hostname } = window.location;
  const script =
    hotjarScripts[hostname] || 'console.log("No hotjar script installed")';
  return (
    !optOut && (
      <Helmet>
        <script>{script}</script>
      </Helmet>
    )
  );
}

export default Hotjar;
