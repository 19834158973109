import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import { fetch } from 'src/utils/client';
import FormPageLayout from 'src/components/layout/FormPageLayout';
import './RequestForInformation.less';
import Confirmed from 'src/components/icons/Confirmed';

const { useForm } = Form;
const successMesssageTitle = () => `Thank you for your interest in Circular.`;
const successMesssageBody = () =>
  'We will review your information and respond via email shortly.';

const errorMesssageTitle = () => 'Something went wrong!';
const errorMesssageBody = () =>
  `It seems like we are having a technical problem. Please email ${process.env.REACT_APP_CONTACT_EMAIL} and a member of our team will reach out to you in the coming days.`;

const primaryBusiness = [
  'Unknown',
  'Broker',
  'Recycler',
  'Manufacturer',
  'Waste collector',
  'Processor',
  'Other'
];

// these radio buttons are in two columns
const primaryBusinessRow = primaryBusiness.length / 2;

const buyerOrSeller = [
  { value: 'Unknown', label: 'Unknown' },
  {
    value: 'Buyer',
    label: <span>Buyer</span>
  },
  {
    value: 'Seller',
    label: <span>Seller</span>
  },
  {
    value: 'Buyer and Seller',
    label: <span>Both buyer and seller</span>
  }
];

// replace indices of radio options with their string labels
const normalizeValues = (values) => {
  const v = Object.assign(values);
  v.primary_business = primaryBusiness[v.primaryBusiness];
  v.buyer_or_seller = buyerOrSeller[v.buyerOrSeller].value;
  v.company_name = values.companyName;
  v.company_website = values.companyWebsite;
  v.join_mailing_list = values.joinMailingList;
  v.phone_number = values.phoneNumber;
  v.other_primary_business = values.otherPrimaryBusiness;
  return v;
};

function RequestForInformation() {
  const [result, setResult] = useState();
  const [isOther, setIsOther] = useState();
  const [valid, setValid] = useState(false);
  const [form] = useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const company = searchParams.get('c');
  const isBuyerOrSeller = searchParams.get('buyer_or_seller');
  const gotoSignUp = !!company;

  const onRequestForInformation = async (values) => {
    const { name } = values;
    try {
      const companyRelation = company ? { company } : {};
      const body = JSON.stringify({
        ...normalizeValues(values),
        ...companyRelation
      });
      const response_ok = await fetch(
        `${process.env.REACT_APP_CIRCLE_API}/request-for-information/`,
        {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/json' }
        }
      );

      if (response_ok) {
        const json = response_ok;
        setResult({
          title: successMesssageTitle(name),
          body: successMesssageBody(name)
        });
        if (gotoSignUp && json?.uuid) {
          navigate(
            `/signup?email=${encodeURIComponent(
              values.email
            )}&name=${encodeURIComponent(
              values.name
            )}&company=${company}&request-for-information=${json.uuid}`,
            {
              state: { ignore: true }
            }
          );
        }
      } else {
        setResult({
          title: errorMesssageTitle(name),
          body: errorMesssageBody(name)
        });
      }
    } catch (error) {
      setResult({
        title: errorMesssageTitle(name),
        body: errorMesssageBody(name)
      });

      window.console.error('onRequestForInformation error', error);
    }
  };
  const onRequestForInformationFailed = (errorInfo) => {
    window.console.error('RequestForInformation failed:', errorInfo);
  };
  if (result)
    return (
      <RequestForInformationResponse body={result.body} title={result.title} />
    );
  const onFieldsChange = (changedFields, fields) => {
    // validation errors only trigger if a field if touched and then cleared
    // and forcing all validation errors to start as true makes the form look ugly
    // so we check the values directly
    const completeNoErrors = fields
      .filter((o) => o.name[0] !== 'joinMailingList')
      .reduce(
        (acc, curr) => acc && curr.value && curr.errors.length === 0,
        true
      );
    setValid(completeNoErrors);
    const primaryBusinessField = changedFields.find(
      (o) => o.name[0] === 'primaryBusiness'
    );
    if (primaryBusinessField) {
      setIsOther(primaryBusinessField.value === primaryBusiness.length - 1);
    }
  };

  return (
    <FormPageLayout back>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          primaryBusiness: 0,
          buyerOrSeller: isBuyerOrSeller ? 1 : 0,
          joinMailingList: true
        }}
        onFinish={onRequestForInformation}
        onFinishFailed={onRequestForInformationFailed}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
      >
        <h3>Request for Information</h3>
        <Form.Item
          label="First and last name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Company name"
          name="companyName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Company website"
          name="companyWebsite"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Company email"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
              message: '"Company email" is not a valid email'
            }
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder="your.name@company.com" />
        </Form.Item>
        <Form.Item
          label="Mobile phone"
          name="phoneNumber"
          rules={[{ required: true }]}
        >
          <Input placeholder="123-456-7890" />
        </Form.Item>
        <Form.Item
          label="What is the primary function of your company?"
          name="primaryBusiness"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            {Array.from({ length: primaryBusinessRow }, (x, i) => i).map(
              (i) => (
                <Row key={primaryBusiness[i * 2 + 1]}>
                  <Col span={12}>
                    <Radio value={i * 2 + 1}>
                      {primaryBusiness[i * 2 + 1]}
                    </Radio>
                  </Col>
                  {i * 2 + 2 < primaryBusiness.length && (
                    <Col span={12}>
                      <Radio value={i * 2 + 2}>
                        {primaryBusiness[i * 2 + 2]}
                      </Radio>
                    </Col>
                  )}
                </Row>
              )
            )}
          </Radio.Group>
        </Form.Item>
        {isOther && (
          <Form.Item
            label="Business Type"
            name="otherPrimaryBusiness"
            rules={[{ required: true }]}
          >
            <Input placeholder="What is the function of your business?" />
          </Form.Item>
        )}

        <div className="mt-10 mb-30">
          <Form.Item
            label="Are you a buyer or seller?"
            name="buyerOrSeller"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {buyerOrSeller
                .filter((v, i) => i !== 0)
                .map((v, i) => (
                  <Col key={buyerOrSeller[i + 1].value} span={24}>
                    <Radio value={i + 1}>{buyerOrSeller[i + 1].label}</Radio>
                  </Col>
                ))}
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="mt-10 mb-30">
          <Form.Item name="joinMailingList" valuePropName="checked">
            <Checkbox>Sign up for Circular mailing list.</Checkbox>
          </Form.Item>
        </div>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button disabled={!valid} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        <Col span={12}></Col>
      </Form>
    </FormPageLayout>
  );
}

RequestForInformation.propTypes = {};

function RequestForInformationResponse({ body, title }) {
  return (
    <FormPageLayout headerless className="request-for-information-response">
      <div style={{ textAlign: 'center' }}>
        <Confirmed />
        <h3>Thank you.</h3>
        <p>Someone from our team will be in touch with you shortly.</p>
      </div>
    </FormPageLayout>
  );
}

RequestForInformationResponse.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string
};

export default RequestForInformation;
