import { useQuery } from '@tanstack/react-query';
import { getProjectWorkflows } from 'src/Query';

export default function useGetProjectWorkflows(projectUuid) {
  return useQuery(
    ['project', projectUuid, 'workflows'],
    () => getProjectWorkflows(projectUuid),
    { enabled: !!projectUuid }
  );
}
