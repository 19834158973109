import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, List } from 'antd';

export function SampleResultInput({
  value,
  onChange,
  label,
  target,
  promptFail,
  valueComponent,
  ...props
}) {
  const selectPass = (selection) => {
    onChange({ ...value, passed: selection });
  };
  const ValueComponent = valueComponent;
  const { passed } = value || {};
  const valueComponentChange = (event) => {
    onChange({ ...value, value: event.target ? event.target.value : event });
  };
  return (
    <>
      <div className="verdict">
        <label>{label}</label>
        <span>{target}</span>
        <Button
          className={`failed ${passed ? '' : 'active'}`}
          onClick={() => selectPass(false)}
        >
          Failed
        </Button>
        <Button
          className={`passed ${passed ? 'active' : ''}`}
          onClick={() => selectPass(true)}
        >
          Passed
        </Button>
      </div>
      {!passed && (
        <div className="actual-value">
          <span>{promptFail}</span>
          <ValueComponent {...props} onChange={valueComponentChange} />
        </div>
      )}
    </>
  );
}

SampleResultInput.propTypes = {
  label: PropTypes.string,
  target: PropTypes.string,
  promptFail: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  valueComponent: PropTypes.elementType
};

export default function SampleResultFormItem({
  label,
  valueComponent,
  property,
  target,
  name,
  ...props
}) {
  return (
    <List.Item>
      <Form.Item name={name} initialValue={{ passed: true, property }}>
        <SampleResultInput
          label={label}
          target={target}
          valueComponent={valueComponent}
          {...props}
        />
      </Form.Item>
    </List.Item>
  );
}

SampleResultFormItem.propTypes = {
  label: PropTypes.string,
  target: PropTypes.string,
  property: PropTypes.object,
  valueComponent: PropTypes.elementType,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  wide: PropTypes.bool
};
