import { useQuery } from '@tanstack/react-query';

import { getRegions } from 'src/Query';

export default function useGetRegions(props) {
  return useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24 * 7,
    cacheTime: 1000 * 60 * 60 * 24 * 7,
    ...props
  });
}
