import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Table } from 'antd';
import reactStringReplace from 'react-string-replace';

const paragraphHighlight = (paragraph) =>
  encodeURIComponent(paragraph.split('... ')[0]).replace('-', '%2D');

/* eslint-disable no-underscore-dangle */

function SearchResults({ data, isFetching, query }) {
  const words = query?.split(' ') || [];
  const formatParagraph = (paragraph) =>
    words.reduce(
      (p, word) =>
        reactStringReplace(p, new RegExp(word, 'gi'), (match, i) => (
          <span className="highlight-match">{match}</span>
        )),
      [paragraph]
    );
  return (
    <div className="search-results">
      {isFetching && (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      )}
      {data && (
        <Table
          showHeader={false}
          columns={[
            {
              title: 'Avatar',
              dataIndex: 'avatar',
              width: '80px',
              render: (avatar) => avatar && <img src={avatar} alt="avatar" />
            },
            {
              title: 'Name',
              dataIndex: 'name',
              width: '180px'
            },
            {
              title: 'Paragraph',
              dataIndex: 'paragraph',
              render: (paragraph, record) => (
                <>
                  <div>{paragraph}</div>
                  <div className="search-url">
                    <span className="distance">
                      {Math.round(record._additional.distance * 1000) / 1000}
                    </span>{' '}
                    <a
                      target="_blank"
                      href={`${record.url}#:~:text=${
                        record.snippet || paragraphHighlight(paragraph)
                      }`}
                    >
                      {record.url}
                    </a>
                  </div>
                </>
              )
            }
          ]}
          dataSource={data}
        />
      )}
    </div>
  );
}

SearchResults.propTypes = {
  data: PropTypes.array,
  query: PropTypes.string,
  isFetching: PropTypes.bool
};

export default SearchResults;
