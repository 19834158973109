import React from 'react';
import { Typography } from 'antd';
import { HashLink } from 'react-router-hash-link';
import SimpleHeader from 'src/components/header/SimpleHeader';

function LaunchPressRelease() {
  const { Title, Paragraph } = Typography;

  return (
    <>
      <SimpleHeader />

      <div className="launch-press-release press-section">
        <img
          className="banner"
          src="https://circular-branding.s3.amazonaws.com/Circular-sustainable-platform.png"
          alt="banner"
        />
        <Title level={1}>
          Circular Launches Digital Platform for Recycled Plastics{' '}
        </Title>
        <Title level={3}>
          New Exchange Drives Future of Buying and Selling Sustainable
          Commodities at Global Scale
        </Title>
        <Paragraph>
          (Palo Alto, California, June 16, 2022) --- Today,{' '}
          <a
            target="_blank"
            href="src/pages/press/LaunchPressRelease?utm_source=wire&utm_campaign=launch1"
          >
            Circular.co
          </a>{' '}
          announced the public launch of its full-service digital platform that
          introduces a new way to buy and sell sustainable commodities at scale,
          starting with a focus on recycled plastics. The platform helps
          essential industries evolve to a more sustainable future by offering
          efficient online trading, accessible data and transparent economics.
          Circular&rsquo;s digital infrastructure tools simplify supply chain
          logistics by doing all the sourcing, vetting, matching, contracting,
          shipping and billing for its customers. Buyers and sellers can trade
          with confidence backed by a full guarantee, and a dedicated concierge
          to facilitate the entire process.
        </Paragraph>
        <Paragraph>
          &ldquo;Circular&rsquo;s focus is on the big economic and environmental
          picture behind helping brands and major manufacturers access the
          materials they need to meet sustainability goals,&rdquo; said
          Circular&rsquo;s founder and CEO,{' '}
          <a target="_blank" href="src/pages/press/LaunchPressRelease">
            Ian Arthurs
          </a>
          . &ldquo;There is more than enough [recyclable] material out there to
          meet demand, but the industry has lacked the infrastructure and
          economic transparency to support greater recovery and
          circularity,&rdquo; he added.&nbsp;
        </Paragraph>
        <Paragraph>
          &ldquo;Circular selected recycled plastics to start because of the
          scale of opportunity and challenge this industry faces,&rdquo; said{' '}
          <a target="_blank" href="src/pages/press/LaunchPressRelease">
            Aidan Madigan-Curtis
          </a>
          , Partner,{' '}
          <a target="_blank" href="http://eclipse.vc">
            Eclipse Ventures
          </a>
          &nbsp;and Board Member of Circular. Over 380 million tons of virgin
          plastic are produced each year and global recycling rates remain under
          9%
          <sup className="c25 c1">
            <HashLink to="#ftnt1">[1]</HashLink>
          </sup>
          , dropping to under 5% recently in the US. This results in oceans of
          waste, microplastics in our bloodstream, and roughly 2 gigatons per
          year of CO2&nbsp;pumped into our atmosphere
          <sup className="c1 c25">
            <HashLink to="#ftnt2">[2]</HashLink>
          </sup>
          .&rdquo;{' '}
        </Paragraph>
        <Paragraph>
          &ldquo;The plastics industry is essential to the global economy and
          clearly needs to evolve sustainably as fast as possible,&rdquo; said
          Madigan-Curtis. &ldquo;The digital tools required to help this
          sustainable evolution already exist in other industries; it&rsquo;s
          inevitable they will apply to the plastic industry; our aim is to
          expedite the process,&rdquo; she added.
        </Paragraph>
        <Paragraph>
          As a leading early adopter of sustainable packaging, Method and Ripple
          Foods founder,{' '}
          <a target="_blank" href="src/pages/press/LaunchPressRelease">
            Adam Lowry
          </a>
          , is no stranger to the challenges companies face sourcing quality
          recycled material. &ldquo;We applaud major brands and manufacturers
          setting sustainability goals to reduce their dependence on virgin
          plastic. Now is the time to take real action on those pledges,&rdquo;
          Lowry said. &ldquo;With positive economics, recyclers are investing in
          more capacity, and Circular&rsquo;s digital platform gives buyers and
          sellers more options, making trading easier. For the first time in
          over a decade, I feel confident we&rsquo;ve got the right ingredients
          for real change,&rdquo; he said.
        </Paragraph>
        <Paragraph>
          &ldquo;By delivering&nbsp;a transparent&nbsp;digital trading
          experience, brands and manufacturers can now confidently buy
          sustainable materials at scale,&rdquo; said Arthurs. &ldquo;This
          creates a clear economic opportunity which in turn stimulates
          innovation to create more supply, more liquidity, and ultimately
          greater circularity, less waste and less CO2. Marrying the best of
          technology with the reality of how plastic is bought, creates a
          real&nbsp;win-win, which is ultimately Circular&rsquo;s goal,&rdquo;
          he added.
        </Paragraph>
        <Paragraph>
          Circular&rsquo;s leadership team is uniquely positioned to deliver on
          their objectives, bringing decades of technology platform experience
          from the likes of Google, Airbnb, TaskRabbit, Bloomberg, Snap and
          Medium. Complementing that, Arthurs added a deep bench of senior
          industry advisors, including Lowry and executives in the recycling and
          manufacturing spaces. Rounding out the team, Circular secured{' '}
          <a target="_blank" href="http://www.eclipse.vc">
            Eclipse Ventures
          </a>
          , a top venture capital firm helping entrepreneurs build exceptional
          companies that make physical industries more efficient, resilient, and
          profitable, as their lead investor. The combination brings
          unparalleled depth of expertise in digitizing physical industries,
          including manufacturing, supply chain and logistics.{' '}
        </Paragraph>
        <Paragraph>
          For the past year, Circular and Eclipse have been conducting research
          and development, and testing the digital platform among buyers and
          sellers. Circular.co is now prepared to launch and offer real scale
          for global buyers and sellers.
        </Paragraph>
        <Paragraph>
          For more information and to arrange a demo visit{' '}
          <a
            target="_blank"
            href="src/pages/press/LaunchPressRelease?utm_source=wire&utm_campaign=launch1"
          >
            circular.co
          </a>
          .
        </Paragraph>
        <Title level={3}> MEDIA INQUIRIES</Title>
        <Paragraph>
          Contact: &nbsp;Linda Rigano
          <a target="_blank" href="mailto:linda@circular.co">
            &nbsp; linda@circular.co
          </a>
          &nbsp;or +1 914-815-0396
        </Paragraph>
        <Title level={3}>About Circular Co.</Title>
        <Paragraph>
          <a
            target="_blank"
            href="src/pages/press/LaunchPressRelease?utm_source=wire&utm_campaign=launch1"
          >
            Circular.co
          </a>
          &nbsp;is a full-service digital platform that introduces a new way to
          buy and sell global sustainable commodities at scale &ndash; starting
          with recycled plastics. Circular&rsquo;s digital infrastructure tools
          simplify supply chain logistics by doing all the sourcing, vetting,
          matching, contracting, shipping and billing for its customers. Buyers
          and sellers can trade with confidence backed by a full guarantee, and
          a dedicated concierge to facilitate the entire process. &nbsp;For more
          information and to arrange a demo visit{' '}
          <a
            target="_blank"
            href="src/pages/press/LaunchPressRelease?utm_source=wire&utm_campaign=launch1"
          >
            circular.co
          </a>
          .{' '}
        </Paragraph>
        <Title level={3}>About Eclipse Ventures</Title>
        <Paragraph>
          With over $2 billion in assets under management, 70 portfolio
          companies, and a team of investors with deep expertise in technology,
          manufacturing, supply chain, logistics, healthcare and consumer
          products, Eclipse is one of the US&rsquo; leading venture capital
          organizations. Its leadership team has the experience and deep
          expertise necessary to create and scale complex operations &ndash;
          with partners coming from industry giants, such as Flextronics, Tesla,
          Apple, Samsara, Intel and GE. Eclipse partners with entrepreneurs
          boldly transforming the essential industries that define and propel
          economies. For more information, visit&nbsp;
          <a target="_blank" href="https://www.eclipse.vc">
            www.eclipse.vc
          </a>
          .
        </Paragraph>
        <hr className="c6" />
        <div>
          <Paragraph>
            <a
              target="_blank"
              href="src/pages/press/LaunchPressRelease#ftnt_ref1"
              id="ftnt1"
            >
              [1]
            </a>
            &nbsp;
            <a
              target="_blank"
              href="https://www.epa.gov/facts-and-figures-about-materials-waste-and-recycling/national-overview-facts-and-figures-materials%23R&Ctrends"
            >
              U.S. Environmental Protection Agency: National Overview: Facts and
              Figures on Materials, Wastes and Recycling
            </a>
          </Paragraph>
        </div>
        <div>
          <Paragraph>
            <a
              target="_blank"
              href="src/pages/press/LaunchPressRelease#ftnt_ref2"
              id="ftnt2"
            >
              [2]
            </a>
            &nbsp;
            <a
              target="_blank"
              href="https://www.nationalacademies.org/news/2021/12/u-s-should-create-national-strategy-by-end-of-2022-to-reduce-its-increasing-contribution-to-global-ocean-plastic-waste-says-new-report"
            >
              National Academies: Science, Engineering, Medicine
            </a>
          </Paragraph>
        </div>
      </div>
    </>
  );
}

export default LaunchPressRelease;
