import { Button, Card, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';

import { getRfqProject } from 'src/Query';
import UpNextIcon from 'src/components/icons/UpNextIcon';

import ProjectActivity from './ProjectActivity.png';
// import useUpNext from './useUpNext';

export default function OverviewTab() {
  const { rfqId } = useParams();

  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  // const { search: upNextSearch } = useUpNext();

  const upNextSearch = undefined;

  if (isLoading) return <Skeleton />;

  const { projects, request_for_quote: requestForQuote } = rfqProject;

  const project = projects[0];

  return (
    <div className="overview-tab">
      <Row gutter={[55, 0]}>
        <Col span={15}>
          <Card className="dark" style={{ marginTop: 20 }}>
            <h2>
              <UpNextIcon /> Up Next:
            </h2>
            {requestForQuote.status === 'demo' ? (
              <>
                <div className="up-next-action">
                  <span>
                    <strong>Procure:</strong> Order samples for production tests
                    for Supplier 1
                  </span>
                  <Button type="link" htmlType="button">
                    Order Samples <ArrowRightOutlined />
                  </Button>
                </div>
                <div className="up-next-action">
                  <span>
                    <strong>Procure:</strong> Sign contract for Supplier 2
                  </span>
                  <Button type="link" htmlType="button">
                    Sign contract <ArrowRightOutlined />
                  </Button>
                </div>
                <div className="up-next-action">
                  <span>
                    <strong>Procure:</strong> Review compliance submissions for
                    Supplier 3
                  </span>
                  <Button type="link" htmlType="button">
                    Review documents <ArrowRightOutlined />
                  </Button>
                </div>
                <div className="up-next-action">
                  <span>
                    <strong>Procure:</strong> Waiting on samples for Supplier 4
                  </span>
                  <Button type="link" htmlType="button">
                    Track Shipment <ArrowRightOutlined />
                  </Button>
                </div>
              </>
            ) : (
              upNextSearch && (
                <div className="up-next-action">
                  <span>
                    <strong>Search:</strong> {upNextSearch.label}
                  </span>
                  {upNextSearch.action && (
                    <Button
                      onClick={upNextSearch.action}
                      type="link"
                      htmlType="button"
                    >
                      {upNextSearch.label} <ArrowRightOutlined />
                    </Button>
                  )}
                </div>
              )
            )}
          </Card>
          {project?.demo && (
            <img
              src={ProjectActivity}
              alt="project activity"
              className="project-activity"
            />
          )}
        </Col>
        <Col span={9}>
          <Card style={{ marginTop: 24 }}>
            <h3 style={{ marginBottom: 28 }}>Project Progress</h3>
            {/* [project.search, project.procure, project.manage].map(
              (stage, j) => (
                <Card
                  key={j}
                  className="progress-subcard"
                  title={
                    <Row justify="space-between">
                      <Col>{stage.title}</Col>
                      <Col>
                        <span className="thin">{stage.steps.length} Tasks</span>
                      </Col>
                    </Row>
                  }
                >
                  <div className="tasks">
                    <ol>
                      {stage.steps.map((step, i) => (
                        <li
                          className={step.done ? 'done' : ''}
                          key={step.description}
                        >
                          
                          <div>
                            {step.description}
                            {step.done ? (
                              <span className="step-status done">
                                <CheckOutlined /> Complete
                              </span>
                            ) : (i === 0 && j === 0) ||
                              (i > 0 && stage.steps[i - 1].done) ? (
                              <span className="step-status in-progress">
                                In progress
                              </span>
                            ) : (
                              <span className="step-status pending">
                                Not started
                              </span>
                            )}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </Card>
              )
                            ) */}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
