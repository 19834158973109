import { Button, Col, Image, Row, Tabs, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import SupplyComparison from './SupplyComparison';
import SupplyRoute from './SupplyRoute';
import SupplyMap from './SupplyMap';
import useProposalSupplyData from './useProposalSupplyData';
import SupplierAvatar from './SupplierAvatar';
import SupplierName from './SupplierName';

const DEFAULT_KPIS_DISPLAYED = 5;

export default function SingleSupply({ supplyData, tabs, rfq, useLbForPrice }) {
  const { technical_data_sheet: tds } = supplyData;
  const [showAllProperties, setShowAllProperties] = useState(false);

  const { rfqId } = useParams();

  const valuesMap = useProposalSupplyData(
    rfq,
    showAllProperties,
    useLbForPrice,
    supplyData
  );
  const useIndexPrice = rfq.price_is_index;

  const fieldsMap = useMemo(() => {
    const kpis = valuesMap.kpis.map((value) => ({
      match: value.match,
      content: (
        <SinglePropertyCompare
          leftValue={value.referenceValue}
          name={value.name}
          rightValue={value.value}
        />
      ),
      matchUpdateFunc: value.matchUpdateFunc
    }));

    return {
      Performance: [
        {
          content: (
            <Row className="properties-table-header" wrap={false}>
              <Col span={1}></Col>
              <Col span={23}>
                <Row wrap={false}>
                  <Col span={10}>Spec</Col>
                  <Col span={7}>Buyer</Col>
                  <Col span={7}>Supplier</Col>
                </Row>
              </Col>
            </Row>
          )
        },
        ...kpis.slice(
          0,
          showAllProperties ? undefined : DEFAULT_KPIS_DISPLAYED
        ),
        {
          content: (
            <Button
              onClick={() => setShowAllProperties(!showAllProperties)}
              type="link"
              htmlType="button"
              className="show-all-properties"
            >
              {showAllProperties ? (
                <>
                  - Show {kpis.length - DEFAULT_KPIS_DISPLAYED} less spec
                  {kpis.length - DEFAULT_KPIS_DISPLAYED > 1 ? 's' : ''}
                </>
              ) : (
                <>
                  + Show {kpis.length - DEFAULT_KPIS_DISPLAYED} more spec
                  {kpis.length - DEFAULT_KPIS_DISPLAYED > 1 ? 's' : ''}
                </>
              )}
            </Button>
          ),
          hide: kpis.length <= DEFAULT_KPIS_DISPLAYED
        },
        {
          content: <Row className="properties-table-footer"></Row>
        },
        {
          content: (
            <>
              <Typography.Text strong>Documents</Typography.Text>
              <br />
              {tds.documents.map((doc) => (
                <div key={doc.uuid}>
                  {doc.url ? (
                    <a title={doc.title} href={doc.url} target="_blank">
                      {doc.title}
                    </a>
                  ) : (
                    <Typography.Text underline>{doc.title}</Typography.Text>
                  )}
                </div>
              ))}
            </>
          ),
          fullWidth: true,
          hide: !tds?.documents?.length
        },
        {
          content: (
            <>
              <Typography.Text strong>Images</Typography.Text>
              <br />
              <div className="single-rfq-proposal-supply--images">
                <Image.PreviewGroup>
                  {tds.images.map((img) => (
                    <Image key={img.uuid} src={img.url} alt={img.title} />
                  ))}
                </Image.PreviewGroup>
              </div>
            </>
          ),
          fullWidth: true,
          hide: !tds?.images?.length
        }
      ],
      Price: [
        {
          content: (
            <Row className="properties-table-header" wrap={false}>
              <Col span={1}></Col>
              <Col span={23}>
                <Row wrap={false}>
                  <Col span={10}>Spec</Col>
                  <Col span={7}>Buyer</Col>
                  <Col span={7}>Supplier</Col>
                </Row>
              </Col>
            </Row>
          )
        },
        {
          ...valuesMap.price,
          content: (
            <SinglePropertyCompare
              leftValue={valuesMap.price.referenceValue}
              name="Price"
              rightValue={valuesMap.price.value}
            />
          ),
          hide: useIndexPrice,
          label: undefined // messy, need to override this to get the right layout
        }
      ],
      'Logistical Complexity': [
        {
          ...valuesMap.location,
          content: (
            <SupplyRoute
              origin={valuesMap.location.value}
              destination={valuesMap.location.referenceValue}
            />
          )
        },
        {
          ...valuesMap.distance,
          content: valuesMap.distance.value
        },
        {
          ...valuesMap['shipping-cost'],
          content: valuesMap['shipping-cost'].value
        },
        ...(valuesMap.logisticsEmissionsMt?.value
          ? [
              {
                ...valuesMap.logisticsEmissionsMt,
                content: valuesMap.logisticsEmissionsMt.value
              }
            ]
          : []),
        {
          content: <SupplyMap rfq={rfq} tds={tds} />
        }
      ],
      'Capacity Available': [
        {
          ...valuesMap['annual-capacity'],
          content: valuesMap['annual-capacity'].value
        },
        {
          ...valuesMap['spot-capacity'],
          content: valuesMap['spot-capacity'].value,
          hide: !valuesMap['spot-capacity'].value
        },
        {
          ...valuesMap.materials,
          content: valuesMap.materials.value
        },
        {
          ...valuesMap.forms,
          content: valuesMap.forms.value
        }
      ],
      'Brand Fit': [
        {
          ...valuesMap['years-in-business'],
          content: valuesMap['years-in-business'].value
        },
        {
          ...valuesMap['pcr-percent-by-volume'],
          content: valuesMap['pcr-percent-by-volume'].value
        },
        {
          ...valuesMap.certifications,
          content: valuesMap.certifications.value
        },
        {
          ...valuesMap['strategic-vision'],
          content: valuesMap['strategic-vision'].value
        },
        {
          ...valuesMap['business-risk'],
          content: valuesMap['business-risk'].value
        }
      ],
      Emissions: [
        ...(valuesMap['material-emissions-mt']?.value
          ? [
              {
                ...valuesMap['material-emissions-mt'],
                content: valuesMap['material-emissions-mt'].value
              }
            ]
          : []),
        ...(valuesMap['logistics-emissions-mt']?.value
          ? [
              {
                ...valuesMap['logistics-emissions-mt'],
                content: valuesMap['logistics-emissions-mt'].value
              }
            ]
          : []),
        ...(valuesMap['total-emissions-mt']?.value
          ? [
              {
                ...valuesMap['total-emissions-mt'],
                content: valuesMap['total-emissions-mt'].value
              }
            ]
          : []),
        ...(valuesMap['avoided-emissions-percent']?.value
          ? [
              {
                ...valuesMap['avoided-emissions-percent'],
                content: valuesMap['avoided-emissions-percent'].value
              }
            ]
          : [])
      ]
    };
  }, [rfq, supplyData, showAllProperties, valuesMap]);

  return (
    <div className="single-rfq-proposal--supply">
      <Row wrap={false} justify="space-between">
        <Col>
          <div className="supplier-card--header">
            <SupplierAvatar supplier={tds.company} />
            <SupplierName supplier={tds.company} />
          </div>
        </Col>
      </Row>

      <Tabs
        items={tabs.map((tab) => ({
          ...tab,
          children: <SupplyComparison fields={fieldsMap[tab.label]} />
        }))}
      />
    </div>
  );
}
SingleSupply.propTypes = {
  supplyData: PropTypes.object,
  tabs: PropTypes.array,
  rfq: PropTypes.object,
  useLbForPrice: PropTypes.bool
};

function SinglePropertyCompare({ leftValue, rightValue, name }) {
  return (
    <Row className="single-property-compare" wrap={false}>
      <Col span={10}>
        <span className="property-name">{name}</span>
      </Col>
      <Col span={7}>{leftValue}</Col>
      <Col span={7}>{rightValue}</Col>
    </Row>
  );
}
SinglePropertyCompare.propTypes = {
  leftValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string
};
