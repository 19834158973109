import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';

function FormLifecycle({
  actionLabel,
  children,
  initialData,
  onFinish,
  onCancel,
  isLoading,
  form,
  className
}) {
  return (
    <Form
      initialValues={initialData}
      form={form}
      layout="vertical"
      className={`form-lifecycle ${className || ''}`}
      onFinish={onFinish}
    >
      {children}
      <div className="form-button-bar">
        <Button htmlType="submit" type="primary" loading={isLoading}>
          {actionLabel}
        </Button>
        {onCancel && (
          <Button type="link" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
}

FormLifecycle.propTypes = {
  actionLabel: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  initialData: PropTypes.object,
  form: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FormLifecycle;
