import React, { useEffect, useState } from 'react';
import { Badge, message, Upload } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useToken } from 'src/utils/authentication';

/* eslint-disable camelcase */

// format the modify column
function UploadDocumentsCompact({ value, onChange, maxCount }) {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState(value || []); // thee are the backend domain objects
  const [fileList, setFileList] = useState(
    value ? djangoFilestoAntdFiles(value) : []
  ); // these are the antd file objects
  const token = useToken();

  useEffect(() => {
    if (!Array.isArray(value) || value.length === 0) {
      setFiles([]);
      setFileList([]);
    } else {
      setFiles(value);
      setFileList(djangoFilestoAntdFiles(value));
    }
  }, [value]);

  return (
    <div className="upload-documents">
      <Upload
        name="file"
        beforeUpload={beforeUpload}
        action={`${process.env.REACT_APP_CIRCLE_API}/document/upload-file/`}
        multiple={!maxCount || maxCount > 1}
        fileList={fileList}
        headers={{ Authorization: `Bearer ${token.access}` }}
        showUploadList={false}
        maxCount={maxCount}
        onRemove={(event) => {
          const internalFileList = files.filter(
            (file) => file.uuid !== event.response.uuid
          );
          setFiles(internalFileList);
          onChange(internalFileList);
        }}
        onChange={(info) => {
          let status = 'done';

          info.fileList.forEach((file) => {
            if (status === 'done' && file.status === 'uploading')
              status = 'uploading';
            if (file.status === 'error') status = 'error';
          });

          if (status === 'uploading') {
            setUploading(true);
          } else if (status === 'done') {
            setUploading(false);
            const internalFileList = info.fileList.map((file) => file.response);
            setFiles(internalFileList);
            onChange(internalFileList);
          } else if (status === 'error') {
            setUploading(false);
            message.error(`${info.file.name} file upload failed.`);
          }
          setFileList(info.fileList);
        }}
      >
        <button className="bare" type="button">
          <Badge
            size="small"
            style={{ fontSize: 10 }}
            count={uploading ? <LoadingOutlined /> : fileList.length}
          >
            <PaperClipOutlined />
          </Badge>
        </button>
      </Upload>
    </div>
  );
}

export const validate = () => true;

export default UploadDocumentsCompact;

UploadDocumentsCompact.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  maxCount: PropTypes.number
};

function beforeUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 50;
  if (!isLt2M) {
    message.error('Document must be smaller than 50MB');
  }
  return isLt2M;
}

function djangoFilestoAntdFiles(files) {
  return files.map(
    (file) =>
      file && {
        uid: file.uuid,
        name: file.title || file.file_name, // Newly uploaded docs have file_name instead of title
        status: 'done',
        url: file.url,
        response: { ...file }
      }
  );
}
