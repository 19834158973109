import { useMemo } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

const COMMON_PROPERTY_FILTERS = ['color'];
const BALES_EXCLUSIVE_PROPERTY_FILTERS = [
  'bale_source',
  'bale_form',
  'bale_grade'
];
const BALES_EXCLUSIVE_FILTERS = ['is_capability'];

export default function useRelevantFiltersForForm() {
  const [filters] = useConciergeContextState(['explore', 'filters']);

  // form can never change in expansions, so it is safe to always use current filters
  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);
  return useMemo(() => {
    if ((Array.isArray(form) && form.length === 0) || !form || !filters)
      return filters;

    if (form === 'bales') {
      // For Bales we want to pick this short list of filters only
      const relevantFilters = {
        form_code__in: form,
        is_capability: false
      };

      [
        'type_code__in',
        'capacity__gte',
        'region_code__in',
        'country_code__in',
        'project',
        'locations',
        ...BALES_EXCLUSIVE_FILTERS
      ].forEach((key) => {
        if (filters[key] !== undefined) relevantFilters[key] = filters[key];
      });

      [...BALES_EXCLUSIVE_PROPERTY_FILTERS, ...COMMON_PROPERTY_FILTERS].forEach(
        (filterKey) => {
          if (filters.properties?.[filterKey]) {
            if (!relevantFilters.properties) relevantFilters.properties = {};

            relevantFilters.properties[filterKey] =
              filters.properties[[filterKey]];
          }
        }
      );

      return relevantFilters;
    }

    // For Resin we want to include everything but exclude Bales exclusive filters
    const relevantFilters = {
      ...filters,

      // Clone first to ensure we don't mutate original
      properties: { ...filters.properties },

      // We want to filter out KPIs which are toggled off
      kpis: {}
    };

    BALES_EXCLUSIVE_FILTERS.forEach((filterKey) => {
      if (filters?.[filterKey]) {
        delete relevantFilters[filterKey];
      }
    });

    BALES_EXCLUSIVE_PROPERTY_FILTERS.forEach((filterKey) => {
      if (filters.properties?.[filterKey]) {
        delete relevantFilters.properties[filterKey];
      }
    });

    // Only add KPIs which aren't toggled off
    if (filters.kpis) {
      Object.keys(filters.kpis).forEach((kpiCode) => {
        if (!filters.kpis[kpiCode].isToggledOff) {
          relevantFilters.kpis[kpiCode] = { ...filters.kpis[kpiCode] };

          // No need to pass isToggledOff to API
          delete relevantFilters.kpis[kpiCode].isToggledOff;
        }
      });
    }

    return relevantFilters;
  }, [filters, form]);
}
