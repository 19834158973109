import React from 'react';
import { Col, Tag } from 'antd';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import numericRange from 'src/components/utils/numericRange';
import { getNumericalProperties, getPropertyOption } from 'src/Query';

const filterRenderers = [
  {
    match: /^query$/,
    func: (val, _) => `"${val}"`
  },
  {
    match: /^material_properties/,
    func: (val, _) => <PropertyName uuid={val} />
  },
  {
    match: /^mnp\.([a-z0-9-]+)$/i,
    func: (val, matches) => (
      <NumericalPropertyValue uuid={matches[1]} value={val} />
    )
  }
];

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
const renderTag = (key, val) => {
  let matches;
  const renderer = filterRenderers.find(
    // eslint-disable-next-line no-return-assign
    ({ match }) => (matches = key.match(match))
  );

  if (renderer) return renderer.func(val, matches);

  return typeof val === 'string' ? capitalize(val) : val;
};

export default function RemovableFilterTag({ keyName, value, setFilters }) {
  if (Array.isArray(value)) {
    return (
      <Col className="filter-tag-group">
        {value.map((val, i) => (
          <Tag
            key={val}
            color="blue"
            closable
            onClose={() => {
              setFilters((oldFilters) => {
                const newFilters = { ...oldFilters };

                if (newFilters[keyName].length > 1) {
                  newFilters[keyName].splice(i, 1);
                } else {
                  delete newFilters[keyName];
                }

                return newFilters;
              }, true);
            }}
          >
            {renderTag(keyName, val)}
          </Tag>
        ))}
      </Col>
    );
  }
  return (
    <Col>
      <Tag
        color="blue"
        closable
        onClose={() => {
          setFilters((oldFilters) => {
            const newFilters = { ...oldFilters };

            delete newFilters[keyName];

            return newFilters;
          }, true);
        }}
      >
        {renderTag(keyName, value)}
      </Tag>
    </Col>
  );
}
RemovableFilterTag.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.any,
  setFilters: PropTypes.func
};

function PropertyName({ uuid }) {
  const { data, isLoading } = useQuery(['property-option', uuid], () =>
    getPropertyOption(uuid)
  );

  if (isLoading) return null;

  return data.value;
}
PropertyName.propTypes = {
  uuid: PropTypes.string
};

function NumericalPropertyValue({ uuid, value }) {
  const { data, isLoading } = useQuery(['numerical-property', uuid], () =>
    getNumericalProperties(uuid)
  );

  if (isLoading) return null;

  const [min, max] = value.split(',');

  return (
    <>
      {data.code}:{' '}
      {numericRange({
        min,
        max,
        noMinRender: (v) => `${v} or -`,
        noMaxRender: (v) => `${v} or +`
      })}
    </>
  );
}
NumericalPropertyValue.propTypes = {
  uuid: PropTypes.string,
  value: PropTypes.string
};
