import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { fetch } from 'src/utils/client';

function MagicLink() {
  const { id } = useParams();
  const [error, setError] = useState();
  const navigate = useNavigate();

  const { isFetching } = useQuery(
    ['magicLink', id],
    () =>
      fetch(`${process.env.REACT_APP_CIRCLE_API}/magic-link/${id}`, {
        method: 'GET'
      }),
    {
      onSuccess: (values) => {
        if (values.redirect_to) navigate(values.redirect_to);
        else
          navigate('/magic-link/waiting', { state: { email: values.email } });
      },
      onError: (err) => {
        setError(
          err?.message || 'Invalid magic link, try again. or contact support'
        );
      }
    }
  );

  return (
    <div className="magic-link-page">
      {isFetching && <Spin size="large" />}
      {error && <Alert type="error">{JSON.stringify(error)}</Alert>}
    </div>
  );
}

export default MagicLink;
