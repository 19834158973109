import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Empty, Skeleton, Spin } from 'antd';

/**
 * `<QueryStatus/>` shows both the loading and error information for a `useQuery()`
 *
 * These props are intended to come directly from `useQuery()`:
 *   - error
 *   - isLoading
 *
 * Overtime we may want to support more, but it is best for us to standardise on a small number
 *
 * `title` is the name of the data being loaded, for example "TDS", for use in messages like "The *TDS* was not found"
 * `skeleton` indicates that a skeleton should be shown during loading.
 *            It can be `true` or config for a skeleton as defined by antd
 *            If not set, then a spinner is shown during loading
 * `children` is the content that is to be rendered by the results of the query and wil only display if data is defined
 */

function NotFound({ title }) {
  return <Empty />;
}

NotFound.propTypes = {
  title: PropTypes.string
};

function Error({ title, error }) {
  return (
    <Alert
      type="error"
      message={`An error occurred while loading the ${title}`}
      description={error?.message}
    />
  );
}

Error.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object
};

export function QueryStatus({ children, title, error, isLoading, skeleton }) {
  if (!error && !isLoading) return children;
  return (
    <div className="query-status">
      <Alert.ErrorBoundary>
        {error && error.status === 404 && <NotFound title={title} />}
        {error && error.status !== 404 && (
          <Error title={title} error={JSON.stringify(error)} />
        )}
        {isLoading && skeleton && <Skeleton active {...skeleton} />}
        {isLoading && !skeleton && <Spin />}
        {children}
      </Alert.ErrorBoundary>
    </div>
  );
}

QueryStatus.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  error: PropTypes.object,
  skeleton: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool
};
