import { roundToPrecision } from 'src/utils/rounding';

export const generateHistogram = (
  specValues,
  decimalPrecision,
  min,
  max,
  step
) => {
  // Range of values (i.e. min != max) will be converted to a range of points separated by the step size
  // e.g. min: 1, max: 3, with a step of 0.5 becomes [1, 1.5, 2, 2.5, 3].

  const labels = [];
  for (let i = min; i <= max; i += step) {
    let l = roundToPrecision(i, decimalPrecision);
    l = l.toFixed(decimalPrecision < 0 ? decimalPrecision * -1 : 0);
    labels.push(l);
  }

  const labelFromValue = (v) => {
    const i = Math.floor((v - min) / step);
    return labels[i];
  };

  const labelsFromRange = (vMin, vMax) => {
    const iMin = Math.floor((vMin - min) / step);
    const iMax = Math.floor((vMax - min) / step);
    const labelsList = [];
    for (let i = iMin; i <= iMax; i += 1) {
      labelsList.push(labels[i]);
    }
    return labelsList;
  };

  const extendedValues =
    specValues?.flatMap((v) => {
      if (v.min === v.max) {
        return labelFromValue(v.min);
      }

      if (v.min === null) {
        return labelsFromRange(Math.max(v.max * 0.8, min), v.max);
      }

      if (v.max === null) {
        return labelsFromRange(v.min, Math.min(v.min * 1.2, max));
      }

      return labelsFromRange(v.min, v.max);
    }) || [];

  const labelBucketsMap = labels.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: 0
    }),
    {}
  );

  extendedValues.forEach((v) => {
    const labelKey = labelFromValue(v);

    labelBucketsMap[labelKey] = (labelBucketsMap[labelKey] ?? 0) + 1;
  });

  return [
    Object.keys(labelBucketsMap)
      .sort((a, b) => a - b)
      .map((o) => labelBucketsMap[o]),
    labels
  ];
};

export const splitHistogram = (
  data,
  decimalPrecision,
  min,
  max,
  step,
  minTarget,
  maxTarget
) => {
  if (!data) {
    return [[], []];
  }
  if (!minTarget && !maxTarget) {
    return [Array(data.length).fill(0), data];
  }
  const dataOutside = [...data];
  const dataInside = [...data];
  let index = 0;
  for (let i = min; i <= max + step / 2; i += step) {
    const l = roundToPrecision(i, decimalPrecision);
    if (
      l + step / 2 >= (minTarget || min) &&
      l - step / 2 <= (maxTarget || max)
    ) {
      dataOutside[index] = 0;
    } else {
      dataInside[index] = 0;
    }
    index += 1;
  }
  return [dataInside, dataOutside];
};
