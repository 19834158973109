import React from 'react';
import numeral from 'numeral';

const defaultFormat = (num) =>
  num >= 10 ? numeral(num).format('0,0.[0]') : numeral(num).format('0,0.0[0]');
const defaultNoMinRender = (v) => <>{v}&nbsp;or&nbsp;lower</>;
const defaultNoMaxRender = (v) => <>{v}&nbsp;or&nbsp;higher</>;
const defaultSingleValueRender = (v) => v;
const defaultRangeRender = (min, max) => (
  <>
    {min}&nbsp;-&nbsp;{max}
  </>
);

export default function numericRange({
  min = undefined,
  max = undefined,
  empty = '(no value)',
  format = defaultFormat,
  noMinRender = defaultNoMinRender,
  noMaxRender = defaultNoMaxRender,
  singleValueRender = defaultSingleValueRender,
  rangeRender = defaultRangeRender
}) {
  if (!min && !max) return empty;

  if (!min) return noMinRender(format(max));
  if (!max) return noMaxRender(format(min));

  if (min === max) return singleValueRender(format(min));

  return rangeRender(format(min), format(max));
}
