import React from 'react';
import PropTypes from 'prop-types';
import useMetaProperty from 'src/hooks/useMetaProperty';
import MetaPropertySelect from './MetaPropertySelect';
import ColorInput from './ColorInput';

function ColorPropertyInput({ value, onChange, filter, ...props }) {
  const customColorProperty = useMetaProperty('color', 'custom');
  const onChangeProperty = (p) => {
    const newValue = { property: p, spec: value?.spec };
    onChange(newValue);
  };
  const onChangeSpec = (s) => {
    onChange({ property: value?.property, spec: s });
  };
  return (
    <div className="color-property-input">
      <MetaPropertySelect
        {...props}
        propertyName="color"
        value={value?.property}
        filter={filter}
        onChange={onChangeProperty}
      />
      {customColorProperty && value?.property === customColorProperty.uuid && (
        <ColorInput value={value?.spec} onChange={onChangeSpec} />
      )}
    </div>
  );
}

ColorPropertyInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  filter: PropTypes.func
};

export default ColorPropertyInput;
