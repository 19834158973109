import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import SendIcon from 'src/components/icons/SendIcon';
import ReplyIcon from 'src/components/icons/ReplyIcon';
import QuoteIcon from 'src/components/icons/QuoteIcon';
import RfqIcon from 'src/components/icons/RfqIcon';

export default function ProposalSummary({
  quotesCount = 0,
  respondedAmount = 0,
  respondedPercent = 0,
  rfqSentCount = 0,
  rfqSentPercent = 0,
  searchCount = 0
}) {
  return (
    <Row
      className="search-results--supplier-outreach mb-xl"
      gutter={[16, 0]}
      align="stretch"
    >
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <RfqIcon /> Explore Results
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            Once approved, your RFQ will be sent to your selected suppliers.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">{searchCount}</span>
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <SendIcon /> Sent
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            The number of suppliers that have been sent your RFQ.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">{rfqSentCount}</span>
            <span className="secondary">{rfqSentPercent}%</span>
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <ReplyIcon /> Responses
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            Suppliers who have responded to our outreach.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">{respondedAmount}</span>
            <span className="secondary">{respondedPercent}%</span>
          </div>
        </ExplorePanel>
      </Col>
      <Col span="6">
        <ExplorePanel pad={21}>
          <Typography.Paragraph className="panel-title" strong>
            <QuoteIcon /> Quotes
          </Typography.Paragraph>
          <Typography.Paragraph className="body">
            The number of quotes we have received from our outreach.
          </Typography.Paragraph>
          <div className="bottom">
            <span className="highlight">{quotesCount}</span>
          </div>
        </ExplorePanel>
      </Col>
    </Row>
  );
}

ProposalSummary.propTypes = {
  quotesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  respondedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  respondedPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rfqSentCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rfqSentPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
