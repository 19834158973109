import React from 'react';
import PropTypes from 'prop-types';
import colorParse from 'src/utils/colorParse';

function CommodityIcon({ color, symbol, borderColor }) {
  const colorUse = color || '#cccccc';
  const rgb = colorParse(colorUse);
  const whiteness = Math.max(
    0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2],
    rgb[3]
  );

  const background = color;
  let borderColorUse = whiteness < 242 ? 'rgba(0,0,0,0)' : '#ccc';
  if (borderColor) borderColorUse = borderColor;
  return (
    <div
      className={`commodity-symbol-outline commodity-symbol-${symbol?.toLowerCase()}-outline`}
      style={{ background: borderColorUse }}
    >
      <div
        className={`commodity-symbol commodity-symbol-${symbol?.toLowerCase()}`}
        style={{ background }}
      ></div>
    </div>
  );
}

CommodityIcon.propTypes = {
  color: PropTypes.string,
  symbol: PropTypes.string,
  borderColor: PropTypes.string
};

export default React.memo(CommodityIcon);
