import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication, useUser } from 'src/utils/authentication';

function UserMenu() {
  const { signOut } = useAuthentication();
  const { data: user } = useUser();
  const navigate = useNavigate();
  const items = [
    {
      key: 'signOut',
      label: 'Sign Out',
      onClick: () => {
        signOut();
        navigate('/');
      }
    }
  ];
  const onClick = ({ key }) => {
    const item = items.find((i) => i.key === key);
    if (item?.onClick) item.onClick();
  };
  return (
    <Dropdown
      className="header-user-menu"
      menu={{ items, onClick }}
      trigger={['click']}
    >
      <Space>
        {user?.name}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

export default UserMenu;
