import React from 'react';
import { ReactComponent as Icon } from './svg/icon-form.svg';
import SvgIconComponent from './SvgIconComponent';

function FormIcon() {
  return (
    <SvgIconComponent
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Icon />
    </SvgIconComponent>
  );
}

export default FormIcon;
