import React from 'react';
import { ReactComponent as Icon } from './svg/chatBubbleNotification.svg';
import SvgIconComponent from './SvgIconComponent';

function ChatNotificationIcon() {
  return (
    <SvgIconComponent>
      <Icon className="chat-notification-icon" />
    </SvgIconComponent>
  );
}

export default ChatNotificationIcon;
