import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';
import {
  colorSpaceCieLabToRgb,
  colorSpaceHunterLabToRgb,
  rgbToHex,
  valueToHex,
  extractNumFromStr
} from 'src/utils/colorSpace';
import RadioButtons from './RadioButtons';

const colorSpaceOptions = [
  { value: 'rgb', label: 'RGB / HSL' },
  { value: 'hunter', label: 'Hunter L, a, b' },
  { value: 'lab', label: 'CIE L*, a*, b*' }
];

const tooltipL = 'L must be a value between 0 and 100';
const tooltipA = 'a must be a value between -128 and 128';
const tooltipB = 'b must be a value between -128 and 128';
const tooltipDelta = 'Δ is the allowed color delta between 0 and 128';

const labValues = (array) => {
  const v = [
    extractNumFromStr(array[0]),
    extractNumFromStr(array[1]),
    extractNumFromStr(array[2])
  ];
  if (Number.isNaN(v[0]) || Number.isNaN(v[0]) || Number.isNaN(v[0]))
    return undefined;
  return v;
};

const rangeMin = [0, -128, -128, 0];
const rangeMax = [100, 128, 128, 128];

const checkRange = (index, v) => {
  const value = extractNumFromStr(v);
  // const value = typeof v === 'string' ? parseFloat(v) : v;
  return value >= rangeMin[index] && value <= rangeMax[index];
};

const convertToRgb = {
  hunter: colorSpaceHunterLabToRgb,
  lab: colorSpaceCieLabToRgb
};

const initialLab = (lab) => {
  if (!lab) return ['', '', '', ''];
  return lab.map((o) => o.toString());
};

const calculateValid = (lab, delta) => {
  if (!lab) return ['', '', '', ''];
  const labValid = lab.map((o, index) => o && checkRange(index, o));
  return [...labValid, checkRange(3, delta)];
};

// Currently only works as a managed component (onChange and value must be used)
function ColorInput({ value, onChange }) {
  // the state management here is because the numeric input fields return strings
  // that may not be value floats, but still need to be the values of these inputs
  // but the `value` of this component treats them as floats and only calls `onChange`
  // when they are valid, so we need internal state that tracks the string values
  // it would be nice to eliminate this, but not sure if that is feasible?
  const valueHex = (value && valueToHex(value)) || '#888';
  const [space, setSpace] = useState(value?.colorSpace || 'rgb');
  const [lab, setLab] = useState(initialLab(value?.lab)); // string values in the L,a,b, inputs
  const [delta, setDelta] = useState(value?.delta?.toString()); // string value for delta
  const [valid, setValid] = useState(
    (value?.lab && calculateValid(value?.lab, value?.delta)) || [
      false,
      false,
      false,
      false
    ]
  ); // validity of the L,a,b, inputs
  const [rgbColor, setRgbColor] = useState({
    hex: valueHex
  }); // hex code for all color spaces
  const extraPrefix = space === 'lab' ? '*' : '';

  useEffect(() => {
    if (value?.lab) {
      const rgbArray = convertToRgb[space](
        value.lab[0],
        value.lab[1],
        value.lab[2]
      );
      const hex = rgbToHex(rgbArray);
      setRgbColor({ hex });
    }
  }, [value]);

  const onRgbChanged = (e) => {
    setRgbColor(e);
    onChange({ colorSpace: space, hex: e.hex });
  };
  const onLabChange = (index, event) => {
    const str = event.target.value;
    const num = extractNumFromStr(str);
    const newValue = [...lab];
    const newValid = [...valid];
    newValue[index] = str;
    setLab(newValue);
    if (index === 3) {
      setDelta(str);
    }
    newValid[index] = checkRange(index, num);
    setValid(newValid);
    if (newValid[0] && newValid[1] && newValid[2] && newValid[3]) {
      const numValues = labValues(newValue);
      const rgbArray = convertToRgb[space](
        extractNumFromStr(numValues[0]),
        extractNumFromStr(numValues[1]),
        extractNumFromStr(numValues[2])
      );
      const hex = rgbToHex(rgbArray);
      setRgbColor({ hex });
      onChange({
        colorSpace: space,
        hex,
        lab: newValue.slice(0, 3),
        delta: index === 3 ? str : delta
      });
    } else {
      setRgbColor({ hex: '#888' });
      onChange(undefined);
    }
  };
  return (
    <Row data-testid="color-input" className="color-input" gutter={16}>
      <Col xs={12} md={8}>
        <RadioButtons
          value={space}
          onChange={(e) => setSpace(e.target.value)}
          options={colorSpaceOptions}
        />
      </Col>
      <Col xs={6} md={4}>
        <div
          className="color-value"
          style={{
            width: '100%',
            height: '100px',
            backgroundColor: rgbColor.hex
          }}
        />
      </Col>
      {space === 'rgb' && (
        <Col xs={12} md={10}>
          <ChromePicker
            defaultView="rgb"
            color={rgbColor}
            onChangeComplete={onRgbChanged}
          />
        </Col>
      )}
      {(space === 'hunter' || space === 'lab') && (
        <Col className="lab-inputs" xs={12} md={10}>
          <Input
            value={lab[0]}
            onChange={(e) => onLabChange(0, e)}
            status={valid[0] || 'error'}
            addonBefore={`L ${extraPrefix}`}
          />
          <div className="input-help">{tooltipL}</div>
          <Input
            value={lab[1]}
            onChange={(e) => onLabChange(1, e)}
            status={valid[1] || 'error'}
            addonBefore={`a ${extraPrefix}`}
          />
          <div className="input-help">{tooltipA}</div>
          <Input
            value={lab[2]}
            onChange={(e) => onLabChange(2, e)}
            status={valid[2] || 'error'}
            addonBefore={`b ${extraPrefix}`}
          />
          <div className="input-help">{tooltipB}</div>
          <Input
            value={delta}
            onChange={(e) => onLabChange(3, e)}
            status={valid[3] || 'error'}
            addonBefore="Δ"
          />
          <div className="input-help">{tooltipDelta}</div>
        </Col>
      )}
    </Row>
  );
}

export const rules = (required) => [
  { required, message: 'Please enter a color' }
];

ColorInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default ColorInput;
