import { Button, Card, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getRfqProject } from 'src/Query';
import ChartIcon from 'src/components/icons/Chart';
import UpNextIcon from 'src/components/icons/UpNextIcon';
import MapMarkerIcon from 'src/components/icons/MapMarkerIcon';
import CalendarIcon from 'src/components/icons/Calendar';
import UsersIcon from 'src/components/icons/UsersIcon';
import ChatNotificationIcon from 'src/components/icons/ChatNotification';
import ChatIcon from 'src/components/icons/Chat';
import { currencyValuePrice } from 'src/utils/currency';
import UpsellModal from './UpsellModal';

import engagementProgress1 from './demo/engagementProgress1.png';
import engagementProgress2 from './demo/engagementProgress2.png';
import engagementProgress3 from './demo/engagementProgress3.png';
import engagementProgress4 from './demo/engagementProgress4.png';
import marketInsightsHeader from './demo/marketInsightsHeader.png';
import marketInsightsChart from './demo/marketInsightsChart.png';
import marketInsightsFooter from './demo/marketInsightsFooter.png';

const procureSteps = [
  {
    title: 'Setup Procure Process',
    steps: [
      {
        description: 'Procure kick-off call',
        done: true
      },
      {
        description: 'Send your first message',
        done: true
      }
    ]
  },
  {
    title: 'Sampling & Testing',
    steps: [
      {
        description: 'Order lab samples',
        done: true
      },
      {
        description: 'Review results',
        done: true
      },
      {
        description: 'Schedule production tests',
        done: true
      },
      {
        description: 'Enter production test results',
        done: true
      }
    ]
  },
  {
    title: 'Compliance Documentation',
    steps: [
      {
        description: 'Request documents',
        done: true
      },
      {
        description: 'Review supplier submissions',
        done: true
      },
      {
        description: 'Confirm a supplier',
        done: true
      }
    ]
  },
  {
    title: 'Contracts & Terms',
    steps: [
      {
        description: 'Start a contract',
        done: true
      },
      {
        description: 'Add a signatory',
        done: true
      },
      {
        description: 'Send a contract',
        done: false
      },
      {
        description: 'Sign a contract',
        done: false
      }
    ]
  }
];

const engagements = [
  {
    uuid: 'Supplier 1',
    supplier: {
      name: 'Supplier 1'
    },
    hasChatNotification: true,
    price: 0.87,
    location: 'Richmond, VA',
    date: '13/01/2023',
    users: 5,
    image: engagementProgress1,
    action: {
      name: 'Order samples',
      description: 'Order samples for production test'
    }
  },
  {
    uuid: 'Supplier 2',
    supplier: {
      name: 'Supplier 2'
    },
    hasChatNotification: false,
    price: 0.84,
    location: 'Stemple, NC',
    date: '13/01/2023',
    users: 3,
    image: engagementProgress2,
    action: {
      name: 'View contract',
      description: 'Sign contract'
    }
  },
  {
    uuid: 'Supplier 3',
    supplier: {
      name: 'Supplier 3'
    },
    hasChatNotification: true,
    price: 0.92,
    location: 'Indianapolis, IN',
    date: '13/02/2023',
    users: 6,
    image: engagementProgress3,
    action: {
      name: 'Review submissions',
      description: 'Review supplier compliance submission'
    }
  },
  {
    uuid: 'Supplier 4',
    supplier: {
      name: 'Supplier 4'
    },
    hasChatNotification: true,
    price: 0.87,
    location: 'Wilsheer, NJ',
    date: '13/02/2023',
    users: 2,
    image: engagementProgress4,
    action: {
      name: 'View tracking',
      description: 'Waiting on samples'
    }
  }
];

export default function ProcureTab() {
  const { rfqId } = useParams();

  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfqId],
    enabled: !!rfqId,
    queryFn: () => getRfqProject(rfqId)
  });

  if (isLoading) return <Skeleton />;

  const { request_for_quote: requestForQuote } = rfqProject;

  return (
    <div className="procure-tab">
      {requestForQuote.status === 'demo' && <UpsellModal active={1} />}

      <Row gutter={[55, 0]}>
        <Col span={16}>
          <h2>Active Engagements</h2>
          {engagements.map((engagement) => (
            <Card key={engagement.uuid} className="engagement-card">
              <div className="engagement-header">
                <Row justify="space-between" align="middle">
                  <Col>
                    <h3>{engagement.supplier.name}</h3>
                  </Col>
                  <Col>
                    {engagement.hasChatNotification ? (
                      <ChatNotificationIcon />
                    ) : (
                      <ChatIcon />
                    )}
                  </Col>
                </Row>
                <div className="engagement-data">
                  <div>
                    <ChartIcon />
                    {currencyValuePrice(engagement.price, 'USD', 'lb')}
                  </div>
                  <div>
                    <MapMarkerIcon />
                    {engagement.location}
                  </div>
                  <div>
                    <CalendarIcon />
                    {engagement.date}
                  </div>
                  <div>
                    <UsersIcon />
                    {engagement.users}
                  </div>
                </div>
              </div>
              <div className="engagement-progress">
                <img src={engagement.image} alt="" width="100%" />
              </div>
              <div className="engagement-action">
                <span>
                  <UpNextIcon /> Up Next:{' '}
                  <span className="thin">{engagement.action.description}</span>
                </span>
                <Button type="default">{engagement.action.name}</Button>
              </div>
            </Card>
          ))}
        </Col>
        <Col span={8}>
          <Card style={{ marginTop: 24 }}>
            <h3 style={{ marginBottom: 28 }}>Procure Progress</h3>
            {procureSteps.map((parentStep, j) => (
              <Card
                key={j}
                className="progress-subcard"
                title={
                  <Row justify="space-between">
                    <Col>{parentStep.title}</Col>
                    <Col>
                      <span className="thin">
                        {parentStep.steps.length} Tasks
                      </span>
                    </Col>
                  </Row>
                }
              >
                <div className="tasks">
                  <ol>
                    {parentStep.steps.map((step, i) => (
                      <li
                        className={step.done ? 'done' : ''}
                        key={step.description}
                      >
                        {/* This inner div is our flexbox container */}
                        <div>
                          {step.description}
                          {step.done ? (
                            <span className="step-status done">
                              <CheckOutlined /> Complete
                            </span>
                          ) : i === 0 || parentStep.steps[i - 1].done ? (
                            <span className="step-status in-progress">
                              In progress
                            </span>
                          ) : (
                            <span className="step-status pending">
                              Not started
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </Card>
            ))}
          </Card>
          <Card style={{ marginTop: 24 }}>
            <h3 style={{ marginBottom: 6 }}>Market Insights: rPET</h3>
            <div
              className="subtitle"
              style={{ color: '#64748D', fontSize: 12, marginBottom: 12 }}
            >
              North America
            </div>
            <div className="market-insights">
              <img src={marketInsightsHeader} alt="" width={130} />
              <img src={marketInsightsChart} alt="" width="100%" />
              <img src={marketInsightsFooter} alt="" width={243} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
