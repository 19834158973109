import React from 'react';
import { Col, Row } from 'antd';

// Radial Strokes svg
import { ReactComponent as RadialStrokes } from 'src/images/RadialStrokes.svg';

import './CompanyHero.less';

export default function Hero() {
  return (
    <div id="company-hero">
      <div className="background-svg">
        <RadialStrokes />
      </div>
      <div className="background-svg-overlay"></div>
      <Row justify="center">
        <Col>
          <h1>
            Transitioning manufacturers to 100% sustainable commodities by 2030
          </h1>
        </Col>
      </Row>
    </div>
  );
}
