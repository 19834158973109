import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import { Pagination, Skeleton } from 'antd';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
// import { useDebounce } from '@uidotdev/usehooks';
import useSupplierList from 'src/components/project/explore/hooks/useSupplierList';
import SupplierRow from 'src/components/project/explore/supplier-list/SupplierRow';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { useDebounce } from 'src/hooks/useDebounce';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import useDetailedSupplierCountBreakdown from './hooks/useDetailedSupplierCountBreakdown';
import useIsBalesMode from './hooks/useIsBalesMode';

export const labelMap = {
  all: ['accepted', null],
  accepted: ['accepted'],
  removed: ['removed']
};

const PAGE_SIZE = 20;
const EMPTY_ARRAY = [];

export default function Suppliers({ supplierFilters, locked }) {
  const {
    companySearch,
    filterResultValue,
    resultIndexFilter,
    parseFilters,
    page,
    setPage
  } = supplierFilters || {};
  // we have temporary state `removed` and `accepted` that record requests from
  // the user to remove or accept suppliers.
  // `supplierLabels` is the actual state of all removed and accepted suppliers
  const [removed, setRemoved] = useState();
  const [accepted, setAccepted] = useState();
  const [supplierLabels, setSupplierLabels] = useState();
  const [supplierLabelsKey, setSupplierLabelsKey] = useState(0);
  // const [supplierLabels, setSupplierLabels] = useConciergeContextState([
  //   'explore',
  //   'projectSettings',
  //   'supplierLabels'
  // ]);
  const { activeProject } = useSidebarContext();
  const projectId = activeProject?.uuid;

  const removedDebounced = useDebounce(removed, 1000);
  const acceptedDebounced = useDebounce(accepted, 1000);
  const debouncedSearch = useDebounce(companySearch, 500);
  const { suppliers, isLoadingSuppliers, resultDetails } = useSupplierList({
    page,
    pageSize: PAGE_SIZE,
    companySearch: debouncedSearch,
    resultIndexFilter,
    parseFilters,
    projectId,
    filterSupplierLabels: labelMap[filterResultValue],
    supplierLabels,
    extraKey: { supplierLabelsKey }
  });

  const currentSupplierLabels = suppliers
    ? suppliers.project_settings?.supplier_labels || {}
    : undefined;

  const isBales = useIsBalesMode();

  const detailedCounts = suppliers?.detailed_counts || EMPTY_ARRAY;
  const { supplierCountBase, supplierCountExpanded, supplierCountUnverified } =
    useDetailedSupplierCountBreakdown(detailedCounts, resultIndexFilter, false);

  useEffect(() => {
    // this method updated `supplierLabels` from the temporary state
    if (
      currentSupplierLabels &&
      (Object.keys(removedDebounced || {}).length ||
        Object.keys(acceptedDebounced || {}).length)
    ) {
      const supplierLabelsNew = { ...(currentSupplierLabels || {}) };
      if (removedDebounced) {
        const removedSet = new Set(currentSupplierLabels.removed);
        Object.keys(removedDebounced).forEach((e) =>
          removedDebounced[e] ? removedSet.add(e) : removedSet.delete(e)
        );
        supplierLabelsNew.removed = Array.from(removedSet);
      }
      if (acceptedDebounced) {
        const acceptedSet = new Set(currentSupplierLabels.accepted);
        Object.keys(acceptedDebounced).forEach((e) =>
          acceptedDebounced[e] ? acceptedSet.add(e) : acceptedSet.delete(e)
        );
        supplierLabelsNew.accepted = Array.from(acceptedSet);
      }
      setSupplierLabels(supplierLabelsNew);
      setSupplierLabelsKey(supplierLabelsKey + 1);
    }
  }, [removedDebounced, acceptedDebounced]);

  useEffect(() => {
    setAccepted((v) => v && []);
    setRemoved((v) => v && []);
  }, [suppliers]);

  const loadingStateClass = useLoadingStateClass(isLoadingSuppliers);

  const isSupplierAccepted = (supplier) =>
    accepted?.[supplier.company_id] ||
    (accepted?.[supplier.company_id] === undefined &&
      supplier.supplier_label === 'accepted');

  const isSupplierRemoved = (supplier) =>
    removed?.[supplier.company_id] ||
    (removed?.[supplier.company_id] === undefined &&
      supplier.supplier_label === 'removed');

  const setSupplierAccepted = (supplier) => {
    if (isSupplierAccepted(supplier)) {
      const newMap = { ...accepted };
      newMap[supplier.company_id] = false;
      setAccepted(newMap);
    } else {
      const newMap = { ...accepted };
      newMap[supplier.company_id] = true;
      setAccepted(newMap);
    }
  };

  const setSupplierRemoved = (supplier) => {
    if (isSupplierRemoved(supplier)) {
      const newMap = { ...removed };
      newMap[supplier.company_id] = false;
      setRemoved(newMap);
    } else {
      const newMap = { ...removed };
      newMap[supplier.company_id] = true;
      setRemoved(newMap);
    }
  };

  if (isLoadingSuppliers && !suppliers) return <Skeleton />;

  return (
    <ExploreSection className={loadingStateClass}>
      <div className="supplier-count-narrative">
        {prettyNumberRound(suppliers.count)} suppliers found{' '}
        <span className="softer">
          (and an additional{' '}
          {prettyNumberRound(
            Math.max(
              0,
              supplierCountUnverified -
                (supplierCountExpanded || supplierCountBase)
            )
          )}{' '}
          unverified suppliers).
        </span>
      </div>
      <div className="explore-suppliers-table">
        {(suppliers?.results || []).map((supplier, index) => (
          <SupplierRow
            key={index}
            supplier={supplier}
            locked={locked}
            setSupplierAccepted={setSupplierAccepted}
            setSupplierRemoved={setSupplierRemoved}
            accepted={isSupplierAccepted(supplier)}
            removed={isSupplierRemoved(supplier)}
            resultDetails={resultDetails?.[supplier.result_index || 0]}
          />
        ))}
      </div>
      <Pagination
        current={page}
        onChange={setPage}
        pageSize={PAGE_SIZE}
        showSizeChanger={false}
        total={suppliers?.count || 0}
      />
    </ExploreSection>
  );
}

Suppliers.propTypes = {
  supplierFilters: PropTypes.object,
  locked: PropTypes.bool
};
