import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

function SelectLabel({ Icon, count, label }) {
  return (
    <div className="select-decorator-container">
      <span className="select-decorator-icon">{Icon && <Icon />}</span>
      <span className="select-decorator-label">{label}</span>
      {count && <span className="select-decorator-count">{count}</span>}
    </div>
  );
}

SelectLabel.propTypes = {
  Icon: PropTypes.elementType,
  label: PropTypes.string,
  count: PropTypes.number
};

function SelectWithDecorators({ Icon, count, labelRender, ...props }) {
  return (
    <Select
      className="select-with-decorators"
      labelRender={
        labelRender ||
        ((v) => <SelectLabel Icon={Icon} count={count} label={v.label} />)
      }
      {...props}
    />
  );
}

SelectWithDecorators.propTypes = {
  Icon: PropTypes.elementType,
  count: PropTypes.number,
  labelRender: PropTypes.func
};

export default SelectWithDecorators;
