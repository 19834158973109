import { useEffect } from 'react';
import { usePerformance } from './hooks';

const salesflareScript = `var flare = new Flare();
flare.track("${process.env.REACT_APP_SALESFLARE_KEY}");`;

function Salesflare() {
  const optOut = usePerformance();

  useEffect(() => {
    if (window.Flare) {
      const flare = new window.Flare();
      flare.track(process.env.REACT_APP_SALESFLARE_KEY);
    }
  }, []);
  return undefined;
}

export default Salesflare;
