import { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Marker as MapboxMarker } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createPortal } from 'react-dom';
import { MapContext } from './MapContext';

export function Marker({
  children,
  id,
  size,
  map: passedMap,
  latitude,
  longitude,
  className
}) {
  const ctxMap = useContext(MapContext);
  const map = passedMap || ctxMap;

  const marker = useMemo(() => {
    const sizePx = `${size}px`;
    const element = document.createElement('div');
    element.className = `map-marker ${className || ''}`;
    element.id = `marker-${id}`;
    element.setAttribute('data-testid', `marker-${id}`);
    // element.style.width = sizePx;
    // element.style.height = sizePx;
    return new MapboxMarker(element).setLngLat([longitude, latitude]);
  }, [size, id, latitude, longitude]);

  useEffect(() => {
    if (map) {
      marker.addTo(map);
    }

    return () => {
      marker.remove();
    };
  }, [marker, map]);

  return createPortal(children, marker.getElement());
}

Marker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  map: PropTypes.object,
  size: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  className: PropTypes.string
};
