import { Button, Col, Collapse, Row, Tag } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FormFilter from 'src/components/project/explore/filters/FormFilter';
import KpiFilter, {
  SingleKpiFilter
} from 'src/components/project/explore/filters/KpiFilter';
import MaterialFilter from 'src/components/project/explore/filters/MaterialFilter';
import RegionFilter from 'src/components/project/explore/filters/RegionFilter';
import CapacityFilter from 'src/components/project/explore/filters/CapacityFilter';
import PropertyFilter from 'src/components/project/explore/filters/PropertyFilter';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { filterOptionByRelationship } from 'src/utils/properties';
import DisableableContent from 'src/components/utils/DisableableContent';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { CheckCircleFilled } from '@ant-design/icons';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import CountryFilter from 'src/components/project/explore/filters/CountryFilter';
import CompareSuppliers from 'src/components/project/source/CompareSuppliers';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import usePrimaryKpis from 'src/components/project/hooks/usePrimaryKpis';
import { useSecondaryKpis } from 'src/components/project/hooks/useSecondaryKpis';
import IsCapabilityFilter from './explore/filters/IsCapabilityFilter';

export default function FiltersSidebar() {
  return (
    <div className="filters-sidebar">
      <Filters />
    </div>
  );
}

const DEFAULT_FILTERS = {};

function Filters() {
  const [filters, setFilters] = useConciergeContextState([
    'explore',
    'filters'
  ]);

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);

  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);

  const balesMode = form === 'bales' || form === 'regrind_flakes';

  // Currently we only filter options for bale_grade property
  const filterByForm = useMemo(
    () => filterOptionByRelationship([form]),
    [form]
  );

  // Returns an array of kpi codes
  const primaryKpis = usePrimaryKpis();
  const secondaryKpis = useSecondaryKpis();
  const allKpis = useMetaNumericalProperty();
  useEffect(() => {
    if (primaryKpis.length && allKpis?.length) {
      const kpiFilters = { ...(filters?.kpis || {}) };
      primaryKpis.forEach((kpiCode) => {
        if (!kpiFilters[kpiCode]) {
          const kpiObj = allKpis.find(({ code }) => code === kpiCode);
          kpiFilters[kpiCode] = {
            units: kpiObj.units,
            unit_type: kpiObj.unit_type,
            allow_null: false
          };
        }
      });
      setFilters({ ...filters, kpis: kpiFilters });
    }
  }, [primaryKpis, allKpis]);

  const relevantFilters = useRelevantFiltersForForm();

  const [stepNumber, setStepNumber] = useState();

  const step1Done = useMemo(
    () =>
      balesMode
        ? !!relevantFilters.type_code__in &&
          !!relevantFilters.form_code__in &&
          !!(
            relevantFilters.region_code__in || relevantFilters.country_code__in
          )
        : !!relevantFilters.type_code__in &&
          !!relevantFilters.form_code__in &&
          relevantFilters.kpis &&
          Object.keys(relevantFilters.kpis).length >= primaryKpis.length,
    [relevantFilters, primaryKpis]
  );
  const step2Done = useMemo(
    () =>
      balesMode
        ? step1Done &&
          !!relevantFilters.properties &&
          Object.keys(relevantFilters.properties).length
        : step1Done &&
          relevantFilters.kpis &&
          Object.keys(relevantFilters.kpis).length > primaryKpis.length,
    [relevantFilters, primaryKpis]
  );
  const step3Done = useMemo(() => false, [relevantFilters]);

  const stepsStatus = useMemo(
    () => [step1Done, step2Done, step3Done],
    [step1Done, step2Done, step3Done]
  );

  useEffect(() => {
    if (!stepNumber) {
      const fisrtUndoneStep = stepsStatus.findIndex((done) => !done);
      if (fisrtUndoneStep === -1) setStepNumber(2);
      else setStepNumber(fisrtUndoneStep === 0 ? 0 : fisrtUndoneStep - 1);
    }
  }, [stepsStatus]);

  const tdsFilterOptionsMapResin = {
    'Add Base Data': {
      material: true,
      capacity: true,
      region: true,
      country: true,
      primary_specs: true
    },
    'Refine Your Search': {
      secondary_specs: true,
      color: true,
      grade: true,
      'processing-method': true,
      application: true,
      'source-type': true,
      additive: true,
      'polymer-type': true,
      industry: true,
      certs: true,
      flammability: true
      // verified: true
    }
  };

  const tdsFilterOptionsMapBales = {
    'Add Base Data': {
      material: true,
      capacity: true,
      region: true,
      country: true,
      color: true,
      bale_source: true
    },
    'Refine Your Search': {
      bale_form: true,
      bale_grade: true
    }
    // verified: true
  };

  const tdsFilterOptionsMapFlake = {
    'Add Base Data': {
      material: true,
      capacity: true,
      region: true,
      country: true,
      primary_specs: true
    },
    'Refine Your Search': {
      secondary_specs: true,
      bale_source: true,
      bale_form: true,
      bale_grade: true,
      color: true,
      grade: true,
      'processing-method': true,
      application: true,
      'source-type': true,
      additive: true,
      'polymer-type': true,
      industry: true,
      certs: true,
      flammability: true
      // verified: true
    }
  };

  const tdsFilterOptionsMap = {
    bales: tdsFilterOptionsMapBales,
    resin_pellets: tdsFilterOptionsMapResin,
    regrind_flakes: tdsFilterOptionsMapFlake
  };

  const tdsFilterActiveOptions = useMemo(
    () => tdsFilterOptionsMap[form] || {},
    [form]
  );

  const tdsFilterOptions = useMemo(
    () => ({
      material: () => ({
        label: 'Material',
        extra: materialType && (
          <Tag color="blue">
            <MetaPropertyName property="type" optionValue={materialType} />
          </Tag>
        ),
        children: <MaterialFilter />
      }),
      capacity: () => ({
        label: 'Quantity',
        extra: filters?.capacity__gte && (
          <Tag color="blue">
            &gt; {prettyNumberRound(filters.capacity__gte / 2204)} mt
          </Tag>
        ),
        children: <CapacityFilter />
      }),
      region: () => ({
        label: 'Region',
        extra: filters?.region_code__in?.length && (
          <Tag color="blue">{filters.region_code__in.length}</Tag>
        ),
        children: <RegionFilter />
      }),
      country: () => ({
        label: 'Country',
        extra: filters?.country_code__in?.length && (
          <Tag color="blue">{filters.country_code__in.length}</Tag>
        ),
        children: <CountryFilter />
      }),
      primary_specs: () => ({
        label: 'Primary Specifications',
        extra: primaryKpis?.length ? (
          <Tag color="blue">{primaryKpis.join(', ')}</Tag>
        ) : null,
        children: (
          <div className="kpi-filter">
            {primaryKpis.map((kpiCode) => (
              <SingleKpiFilter key={kpiCode} kpiCode={kpiCode} isPrimary />
            ))}
          </div>
        ),
        className: 'ghost'
      }),
      secondary_specs: () => ({
        label: 'Secondary Specifications',
        extra: primaryKpis?.length ? (
          <Tag style={{ textWrap: 'wrap' }} color="blue">
            {secondaryKpis.join(', ')}
          </Tag>
        ) : null,
        children: <KpiFilter excludeKpis={primaryKpis} />,
        className: 'ghost'
      }),
      bale_source: () => ({
        label: 'Source',
        extra: filters?.properties?.bale_source?.length && (
          <Tag color="blue">{filters.properties.bale_source.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="bale_source" />
      }),
      bale_form: () => ({
        label: 'Form',
        extra: filters?.properties?.bale_form?.length && (
          <Tag color="blue">{filters.properties.bale_form.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="bale_form" />
      }),
      bale_grade: () => ({
        label: 'Grade',
        extra: filters?.properties?.bale_grade?.length && (
          <Tag color="blue">{filters.properties.bale_grade.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="bale_grade" />
      }),
      color: () => ({
        label: 'Color',
        extra: filters?.properties?.color?.length && (
          <Tag color="blue">{filters.properties.color.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="color" />
      }),
      verified: () => ({
        label: 'Verified Suppliers',
        extra: filters?.is_capability === false && <Tag color="blue">Yes</Tag>,
        children: <IsCapabilityFilter />
      }),
      grade: () => ({
        label: 'Grade',
        extra: filters?.properties?.grade?.length && (
          <Tag color="blue">{filters.properties.grade.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="grade" />
      }),
      'processing-method': () => ({
        label: 'Processing Method',
        extra: filters?.properties?.['processing-method']?.length && (
          <Tag color="blue">
            {filters.properties['processing-method'].length}
          </Tag>
        ),
        children: <PropertyFilter propertyCode="processing-method" />
      }),
      application: () => ({
        label: 'Application',
        extra: filters?.properties?.application?.length && (
          <Tag color="blue">{filters.properties.application.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="application" />
      }),
      'source-type': () => ({
        label: 'Source Type',
        extra: filters?.properties?.['source-type']?.length && (
          <Tag color="blue">{filters.properties['source-type'].length}</Tag>
        ),
        children: <PropertyFilter propertyCode="source-type" />
      }),
      additive: () => ({
        label: 'Additives / Fillers',
        extra: filters?.properties?.additive?.length && (
          <Tag color="blue">{filters.properties.additive.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="additive" />
      }),
      'polymer-type': () => ({
        label: 'Polymer Type',
        extra: filters?.properties?.['polymer-type']?.length && (
          <Tag color="blue">{filters.properties['polymer-type'].length}</Tag>
        ),
        children: <PropertyFilter propertyCode="polymer-type" />
      }),
      industry: () => ({
        label: 'Industry',
        extra: filters?.properties?.industry?.length && (
          <Tag color="blue">{filters.properties.industry.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="industry" />
      }),
      certs: () => ({
        label: 'Certifications / Standards',
        extra: filters?.properties?.certs?.length && (
          <Tag color="blue">{filters.properties.certs.length}</Tag>
        ),
        children: (
          <PropertyFilter
            filterOptions={(opt) =>
              ![
                'efsa',
                'philippines_fda',
                'indonesia_fda',
                'fda_lno',
                'fda'
              ].includes(opt.code)
            }
            propertyCode="certs"
          />
        )
      }),
      flammability: () => ({
        label: 'Flammability Rating',
        extra: filters?.properties?.flammability?.length && (
          <Tag color="blue">{filters.properties.flammability.length}</Tag>
        ),
        children: <PropertyFilter propertyCode="flammability" />
      })
    }),
    [materialType, primaryKpis, filters, form]
  );

  const filterActiveOptions = useMemo(() => {
    const results = {};
    Object.keys(tdsFilterActiveOptions).forEach((groupKey) => {
      const optionTestMap = tdsFilterActiveOptions[groupKey];
      const optionList = Object.keys(optionTestMap)
        .map((key) => {
          const optionTest = optionTestMap[key];
          const include =
            typeof optionTest === 'function' ? optionTest() : optionTest;
          if (include) {
            const option = tdsFilterOptions[key]();
            option.key = key;
            return option;
          }
          return undefined;
        })
        .filter((o) => o);
      results[groupKey] = optionList;
    });
    return results;
  }, [tdsFilterActiveOptions, tdsFilterOptions]);

  const changeStep = (s) => {
    if (s > stepNumber) {
      nextStepScrollToElement.current.scrollIntoView();
    }
    setStepNumber(s);
  };

  const nextStepScrollToElement = useRef();

  return (
    <>
      <FormFilter />
      <div ref={nextStepScrollToElement} />

      <StepsProgress
        stepsStatus={
          balesMode ? [step1Done, step2Done] : [step1Done, step2Done, step3Done]
        }
        right={
          <Button
            style={{
              fontWeight: 300,
              padding: '0 8px'
            }}
            onClick={() => setFilters(DEFAULT_FILTERS)}
            htmlType="button"
          >
            Reset
          </Button>
        }
      />
      {Object.keys(filterActiveOptions).map((groupName, i) => (
        <ExplorePanel key={groupName} className="mt-lg" pad={12}>
          <Step
            ordinal={i + 1}
            title={groupName}
            done={stepsStatus[i]}
            collapsed={stepNumber !== i}
            action="Next"
            onDone={() => {
              changeStep(i + 1);
            }}
            revert={() => {
              changeStep(i);
            }}
            disabled={i !== 0 && !stepsStatus[i - 1]}
            actionDisabled={!stepsStatus[i]}
          >
            <div className="filters-box-group">
              <Collapse
                className="filters-box"
                defaultActiveKey={['primary_specs']}
                size="small"
                items={filterActiveOptions[groupName]}
              />
            </div>
          </Step>
        </ExplorePanel>
      ))}
      <ExplorePanel className="mt-lg" pad={12}>
        <Step
          disabled={stepNumber < 2}
          collapsed={stepNumber < 2}
          ordinal={3}
          title="Prepare your Digital RFQ"
          done={stepNumber > 2}
          // action="Review Accepted Suppliers"
          revert={() => {
            changeStep(3);
          }}
        >
          <CompareSuppliers />
        </Step>
      </ExplorePanel>
    </>
  );
}

function StepStatus({ done }) {
  if (!done)
    return (
      <div
        style={{
          width: 16,
          height: 16,
          border: '1px solid #DFE3ED',
          borderRadius: 8,
          display: 'inline-block',
          marginRight: 8
        }}
      />
    );

  return (
    <CheckCircleFilled
      style={{ fontSize: 20, color: '#389E0D', marginRight: 8 }}
    />
  );
}
StepStatus.propTypes = {
  done: PropTypes.bool
};

function StepsProgress({ stepsStatus, right }) {
  return (
    <div
      className="p-sm mb-md box-panel solid"
      style={{
        background: '#F9F0FF'
      }}
    >
      <Row className="mb-sm" justify="space-between">
        <Col>
          <h3>
            {stepsStatus.every((done) => done)
              ? 'Ready to Add Suppliers!'
              : 'Configure Your Search'}
          </h3>
        </Col>
        <Col>{right}</Col>
      </Row>
      <Row wrap={false} style={{ borderRadius: 2, overflow: 'hidden' }}>
        {stepsStatus.map((done, i) => (
          <Col
            key={i}
            style={{
              height: 6,
              flex: '1',
              background: done ? '#722ED1' : '#D3ADF7',
              marginRight: i + 1 === stepsStatus.length ? 0 : 3
            }}
          />
        ))}
      </Row>
    </div>
  );
}
StepsProgress.propTypes = {
  stepsStatus: PropTypes.arrayOf(PropTypes.bool),
  right: PropTypes.node
};

function Step({
  children,
  done,
  title,
  ordinal,
  revert,
  action,
  onDone,
  disabled,
  actionDisabled,
  collapsed
}) {
  return (
    <DisableableContent disabled={disabled}>
      <Row className="mb-sm" align="middle">
        <Col style={{ lineHeight: 0 }}>
          <StepStatus done={done} />
        </Col>
        <Col flex="1">
          <h3 className="m-0">
            Step {ordinal}: {title}
          </h3>
        </Col>
        <Col>
          {done && (
            <Button type="default" onClick={revert} size="small">
              Edit
            </Button>
          )}
        </Col>
      </Row>
      {!disabled && (
        <>
          {!collapsed ? children : null}
          {!collapsed && action && onDone && (
            <Button
              disabled={actionDisabled}
              className="mt-sm"
              type="primary"
              onClick={onDone}
            >
              {action}
            </Button>
          )}
        </>
      )}
    </DisableableContent>
  );
}

Step.propTypes = {
  done: PropTypes.bool,
  disabled: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  collapsed: PropTypes.bool,
  revert: PropTypes.func,
  onDone: PropTypes.func,
  ordinal: PropTypes.number,
  title: PropTypes.string,
  action: PropTypes.string,
  children: PropTypes.node
};
