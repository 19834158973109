import { useMemo } from 'react';

import useChartSpecs from 'src/hooks/useChartSpecs';

const DEFAULT_BUCKET_COUNT = 20;

const filterNotNull = (v) => v !== null;

export default function useHistogramSettings(
  kpiValues,
  specMinFilter,
  specMaxFilter,
  bucketCount = DEFAULT_BUCKET_COUNT
) {
  const minMin = useMemo(
    () =>
      Math.min(...kpiValues.map((v) => v.min).filter(filterNotNull)) ??
      specMinFilter,
    [kpiValues, specMinFilter]
  );

  const minMax = useMemo(
    () =>
      Math.min(...kpiValues.map((v) => v.max).filter(filterNotNull)) ??
      specMinFilter,
    [kpiValues, specMinFilter]
  );

  const maxMax = useMemo(
    () =>
      Math.max(...kpiValues.map((v) => v.max).filter(filterNotNull)) ??
      specMaxFilter,
    [kpiValues, specMaxFilter]
  );

  const maxMin = useMemo(
    () =>
      Math.max(...kpiValues.map((v) => v.min).filter(filterNotNull)) ??
      specMaxFilter,
    [kpiValues, specMaxFilter]
  );

  const minMinFinite = Number.isFinite(minMin) ? minMin : minMax;
  const minMaxFinite = Number.isFinite(minMax) ? minMax : minMin;
  const maxMinFinite = Number.isFinite(maxMin) ? maxMin : maxMax;
  const maxMaxFinite = Number.isFinite(maxMax) ? maxMax : maxMin;

  const { bucketSize, normalizedMax, normalizedMin } = useChartSpecs(
    Math.min(minMinFinite, minMaxFinite),
    Math.max(maxMinFinite, maxMaxFinite),
    bucketCount
  );

  let decimalPrecision;
  decimalPrecision = Math.log10(Math.abs(bucketSize));
  decimalPrecision =
    decimalPrecision < 0
      ? Math.floor(decimalPrecision) - 1
      : Math.ceil(decimalPrecision) - 1;

  return {
    decimalPrecision,
    min: normalizedMin,
    max: normalizedMax,
    step: bucketSize
  };
}
