import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import produce from 'immer';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

export const NotificationContext = createContext(undefined);

// this context records actions that the user needs to be aware of (e.g. unread
// messages or process steps that are waiting for their attention)
//
// the state of is a nested object, for example:
// {
//     chat: {
//          group1 : { unread: 1 },
//          group2 : { unread: 0 }
//     deal: {
//           dealid1: { actions: [] },
//     }
// }
//
// for simplicity, the state is set like this:
//
//    set(['chat', 'group1', 'unread'], 1);
//
// and retrieved as:
//
//    get(['chat', 'group1', 'unread']);

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState({});
  const set = useCallback(
    (path, value) => {
      const notificationUpdate = produce((draft) => {
        const { length } = path;
        path.reduce((acc, key, index) => {
          if (!acc[key]) acc[key] = index === length - 1 ? value : {};
          return acc[key];
        }, draft);
      });
      setNotifications(notificationUpdate);
    },
    [notifications]
  );
  const get = useCallback(
    (path) => path.reduce((acc, key, index) => acc && acc[key], notifications),
    [notifications]
  );
  const count = useCallback(
    (path, key) => {
      const base = path.reduce(
        (acc, keyIn, index) => acc && acc[keyIn],
        notifications
      );
      if (!base) return 0;
      const values = Object.keys(base).map((o) => (key ? base[o][key] : o));
      return values.reduce((acc, o) => acc + (Number.isFinite(o) ? o : 1), 0);
    },
    [notifications]
  );
  const value = useMemo(() => ({ set, get, count }), [notifications]);
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export function useNotification() {
  return useContext(NotificationContext);
}
