import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Skeleton, Space, Typography } from 'antd';

import { useParams } from 'react-router-dom';
import DarkPanel from 'src/components/layout/DarkPanel';
import GoodnessRating from 'src/components/GoodnessRating';
import RainbowProgress from 'src/components/RainbowProgress';
import LightPanel from 'src/components/layout/LightPanel';
import BlockProgress from 'src/components/BlockProgress';
import dayjs from 'dayjs';
import BarProgress from 'src/components/BarProgress';
import MarketPriceByFormLineChart from 'src/components/analytics/MarketPriceByFormLineChart';
import { useMarketPriceLatest } from 'src/Query/marketPrice';
import { currencyValuePrice } from 'src/utils/currency';
import { findProperties } from 'src/utils/properties';

export default function ManageOverviewTab({ project, requestForQuote }) {
  const { rfqId } = useParams();
  const { code: materialTypeCode, value: materialTypeName } =
    (requestForQuote && findProperties(requestForQuote, 'type')[0]) || {};
  const priceChart1Filters = {
    region_code__in: 'americas_north',
    type_code__in: materialTypeCode,
    form_code__in: 'resin_pellets'
  };
  const priceChart2Filters = {
    region_code__in: 'eu',
    type_code__in: materialTypeCode,
    form_code__in: 'resin_pellets'
  };
  const { isLoading: isLoadingPrice1, price: price1 } =
    useMarketPriceLatest(priceChart1Filters);
  const { isLoading: isLoadingPrice2, price: price2 } =
    useMarketPriceLatest(priceChart2Filters);

  // const { search: upNextSearch } = useUpNext(requestForQuote, project);

  if (!project || !requestForQuote) return <Skeleton />;
  const shipmentProgress = [
    'normal',
    'normal',
    'normal',
    'empty',
    'empty',
    'empty',
    'empty',
    'amber',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty',
    'empty'
  ];
  const emissionsBreakdown = [
    {
      value: 180,
      type: 'high',
      label: (
        <>
          <strong>Material</strong> 36%
        </>
      )
    },
    {
      value: 109,
      type: 'medium',
      label: (
        <>
          <strong>Logistics</strong> 28%
        </>
      )
    },
    {
      value: 175,
      type: 'hatch',
      label: (
        <>
          <strong>Avoided</strong> 36%
        </>
      )
    }
  ];
  const nextShipment = dayjs().add(18, 'day').format('MMMM D, YYYY');
  return (
    <div className="overview-tab">
      <Row gutter={32}>
        <Col span={12}>
          <DarkPanel style={{ height: '100%' }}>
            <Typography.Title level={5}>Project Health</Typography.Title>
            <Typography.Text>
              Pricing, distribution, and overall health of this deal is still
              very high. Only 3 suppliers compare on pricing.
            </Typography.Text>
            <div>
              <br />
              <GoodnessRating dark pill rating="verygood" />
            </div>
            <RainbowProgress progress={0.83} />
            <Space direction="horizontal">
              <Button type="default">Supplier Search</Button>
              <Button type="primary">Repeat Buy</Button>
            </Space>
          </DarkPanel>
        </Col>
        <Col span={12}>
          <LightPanel style={{ height: '100%' }}>
            <div></div>
            <Typography.Title level={5}>Shipment Progress</Typography.Title>
            <div>
              <BlockProgress value={shipmentProgress} />
              <Typography.Text className="xs upper">
                <strong>Shipments delivered</strong> 3/14
              </Typography.Text>
            </div>
            <Typography.Text className="xs upper breath-xl">
              Next shipment
            </Typography.Text>
            <Typography.Text className="xxl">{nextShipment}</Typography.Text>
          </LightPanel>
        </Col>
      </Row>
      <Typography.Title level={5} className="breath-xl">
        Project Emissions Overview
      </Typography.Title>
      <LightPanel className="tight-md breath-lg" style={{ height: '100%' }}>
        <Row>
          <Col span={5}>
            <Typography.Text className="xs upper breath">
              Total Project Emissions
            </Typography.Text>
            <div>
              <Typography.Text className="xxl">594&nbsp;</Typography.Text>
              <Typography.Text className="lg">tCO2e</Typography.Text>
              <Typography.Text className="xs light breath">
                36% avoided emissions
              </Typography.Text>
            </div>
          </Col>
          <Col span={7}>
            <Typography.Text className="xs upper breath">
              Emissions Credits
            </Typography.Text>
            <div>
              <Typography.Text className="xxl">$247,749</Typography.Text>
              <Typography.Text className="xs light breath">
                $2.47 per ton avoided
              </Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            <Typography.Text className="xs upper breath">
              Project Emissions Breakdown
            </Typography.Text>
            <BarProgress value={emissionsBreakdown} />
          </Col>
        </Row>
      </LightPanel>
      <Typography.Title level={5} className="breath-xl">
        Compare with the market
      </Typography.Title>
      <Row gutter={24}>
        <Col span={12}>
          <LightPanel className="tight-md breath-lg">
            <Typography.Title level={4}>
              North America - {materialTypeName} Resin/Pellets
            </Typography.Title>
            <Typography.Text className="xxl breath-md">
              {currencyValuePrice(
                price1?.high,
                price1?.currencyValuePrice,
                price1?.units
              )}
            </Typography.Text>
            <MarketPriceByFormLineChart
              months={6}
              filters={priceChart1Filters}
            />
          </LightPanel>
        </Col>
        <Col span={12}>
          <LightPanel className="tight-md breath-lg">
            <Typography.Title level={4}>
              Europe - {materialTypeName} Resin/Pellets
            </Typography.Title>
            <Typography.Text className="xxl breath-md">
              {currencyValuePrice(
                price2?.high,
                price2?.currencyValuePrice,
                price2?.units
              )}
            </Typography.Text>
            <MarketPriceByFormLineChart
              months={6}
              filters={priceChart2Filters}
            />
          </LightPanel>
        </Col>
      </Row>
    </div>
  );
}

ManageOverviewTab.propTypes = {
  requestForQuote: PropTypes.object,
  project: PropTypes.array
};
