import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { paramCase, sentenceCase } from 'change-case';
import numeral from 'numeral';
// eslint-disable-next-line import/no-unresolved
import { useMutation } from '@tanstack/react-query';
import MetaPropertySelect from 'src/components/form/MetaPropertySelect';
import SwitchableInput from 'src/components/form/SwitchableInput';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { mapMerge } from 'src/utils/mapMerge';
import { filterOptionByRelationship } from 'src/utils/properties';
import { generateRfqDefaults } from 'src/Mutation';
import ReviewFormItem from './ReviewFormItem';
import PropertyRangeInput from './PropertyRangeInput';

const BIG_INT = 1_000_000_000_000;

export const compareProperties = (property1, property2) => {
  if (property1 && !property2) return -1;
  if (!property1 && property2) return 1;
  if (!property1 && !property2) return 0;
  if (property1.order && !property2.order) return -1;
  if (!property1.order && property2.order) return 1;
  if (!property1.order && !property2.order) {
    if (property1.name > property2.name) return 1;
    if (property1.name < property2.name) return -1;
    return 0;
  }
  return property1.order - property2.order;
};

export const normalizeNumericalProperty = (property) => ({
  uuid: property?.uuid,
  name: property?.property?.name,
  min: numeral(property.min).value()?.toString(),
  max: numeral(property.max).value()?.toString(),
  property_id: property?.property?.uuid,
  unit_type: property?.property?.unit_type,
  order: property?.property?.order,
  units: property?.units
});

export const normalizeNumericalProperties = (material_numerical_properties) => {
  const result = {};
  result.existing = material_numerical_properties.map(
    normalizeNumericalProperty
  );
  result.unused = [];
  return result;
};

export const normalizeProperties = (material_properties) => {
  const result = {};
  material_properties.forEach((o) => {
    // if there are multiple options set for the same property, then
    // we store them in an array, otherwise we store them as a primitive value
    // the select field can handle either and will adapt to multiple select if
    // passed an array as its value
    if (Array.isArray(result[paramCase(o.meta_property.code)])) {
      result[paramCase(o.meta_property.code)].push(o.uuid);
    } else if (result[paramCase(o.meta_property.code)]) {
      result[paramCase(o.meta_property.code)] = [
        result[paramCase(o.meta_property.code)],
        o.uuid
      ];
    } else {
      result[paramCase(o.meta_property.code)] = o.uuid;
    }
  });
  return result;
};

/* eslint-disable prefer-arrow-callback */

const defaultMutationMap = {
  rfq: generateRfqDefaults,
  tds: generateRfqDefaults
};

const formatDefault = (n) =>
  numeral(numeral(n).value().toPrecision(3)).format('0[.]00');

const generateDefaultMutation = (objectType) => defaultMutationMap[objectType];

export default function MaterialPropertyForm({
  id,
  requiredProperties,
  form,
  materialNumericalProperties,
  materialProperties,
  setOnFieldsChange,
  multiple,
  objectType
}) {
  const [numericalDefaults, setNumericalDefaults] = useState([]);
  const [unusedNumericalProperties, setUnusedNumericalProperties] = useState(
    []
  );
  const [unusedProperties, setUnusedProperties] = useState([]);
  const numericalProperties = useMetaNumericalProperty();
  const properties = useMetaProperty();

  useEffect(() => {
    if (Array.isArray(materialNumericalProperties) && numericalProperties) {
      const usedNumericalPropertyIds =
        materialNumericalProperties.map((o) => o.property?.uuid) || [];
      setUnusedNumericalProperties(
        numericalProperties.filter(
          (o) => usedNumericalPropertyIds.indexOf(o.uuid) < 0
        )
      );
    }
  }, [materialNumericalProperties, numericalProperties]);

  useEffect(() => {
    if (Array.isArray(materialProperties) && properties) {
      const usedPropertyIds =
        materialProperties.map((o) => o.meta_property?.uuid) || [];
      const propertyList = properties.filter(
        (o) =>
          usedPropertyIds.indexOf(o.uuid) < 0 && // is not a property that is already on the RFQ
          requiredProperties.indexOf(paramCase(o.code)) < 0 // is not one of the required properties that is hardcoded on the form
      );
      setUnusedProperties(propertyList);
      updateDefaults();
    }
  }, [materialProperties, properties]);

  const updateDefaults = () => {
    const values = form.getFieldsValue();
    const uuids = Object.values(values.material_properties).filter((o) => !!o);
    mutateRfqDefault({ uuid: id, properties: uuids.flat() });
  };

  const { mutate: mutateRfqDefault, isMutating: rfqDefaultsLoading } =
    useMutation({
      mutationFn: generateDefaultMutation(objectType),
      onSuccess: (response) => {
        if (Array.isArray(response))
          setNumericalDefaults(
            response.map((property) => ({
              ...property,
              property_id: property?.property.uuid,
              unit_type: property?.property.unit_type,
              min: property.min && formatDefault(property.min),
              max: property.max && formatDefault(property.max)
            }))
          );
      },
      onError: (e) => {
        window.console.error('Error loading defaults', e);
      }
    });

  const onFieldsChange = (changedFields) => {
    const propertyChanged = changedFields.find(
      (o) => o.name[0] === 'material_properties'
    );
    if (propertyChanged) {
      updateDefaults();
    }
  };

  useEffect(() => {
    setOnFieldsChange(onFieldsChange);
  }, []);

  const numericalPropertyInitialValue = (property) => {
    const defaultValue = numericalDefaults.find(
      (o) => o.property.uuid === property.uuid
    );
    return {
      units: defaultValue?.units,
      property_id: defaultValue?.property_id,
      unit_type: defaultValue?.unit_type,
      min: defaultValue?.min,
      max: defaultValue?.max
    };
  };

  // properties that are set on the loaded RFQ and that
  // are not one of the hard coded properties e.g. type or form
  // we do not return relationships for these properties when they are part of the RFQ/TDS
  // so we map them to ones with relationships from the `properties` list
  const existingOptionalProperties = useMemo(
    () =>
      (Array.isArray(materialProperties) &&
        properties &&
        properties.length > 0 &&
        materialProperties
          .filter(
            // remove required properties that are already on the form
            (property) =>
              property &&
              requiredProperties.indexOf(property?.meta_property.code) < 0
          )
          .map((property) =>
            properties.find((prop) => prop.code === property.meta_property.code)
          )
          .filter(
            // remove duplicates
            (property, index, array) =>
              array.findIndex((prop) => prop.code === property.code) === index
          )) ||
      [],
    [properties, materialProperties]
  );

  const existingOptionalNumericalProperties = useMemo(
    () =>
      (Array.isArray(materialNumericalProperties) &&
        numericalProperties &&
        numericalProperties.length > 0 &&
        materialNumericalProperties.map((property) => property.property)) ||
      [],
    [numericalProperties, materialNumericalProperties]
  );

  // should we handle this inside a onFieldsChange callback?
  // it seems to work like this
  const selectedPropertiesMap = form.getFieldsValue()?.material_properties;

  // all properties that have been set in the current form (array of ids only)
  const selectionProperties = useMemo(
    () =>
      (selectedPropertiesMap &&
        Object.values(selectedPropertiesMap).filter((o) => !!o)) ||
      [],
    [selectedPropertiesMap]
  );

  const optionFilter = useMemo(
    () => filterOptionByRelationship(selectionProperties),
    [selectionProperties]
  );

  const multiplePropertyValue = (property) =>
    multiple && multiple.indexOf(property?.uuid) >= 0;

  return (
    <>
      {
        /* interleave the numerical properties and regular properties based on order
         * There should be no reason why property is undefined, but in the tests, it sometimes is
         * I think it is because of the way the test data is generated from OpenApi specs (lack of key integrity)
         * We need to improve the tests - but for now I use ?. on all property attributes
         */
        mapMerge(
          existingOptionalNumericalProperties,
          (property, index) => (
            <ReviewFormItem
              key={property?.uuid}
              label={property?.name}
              name={['material_numerical_properties', 'existing', index]}
            >
              <SwitchableInput
                Component={PropertyRangeInput}
                componentProps={{ property }}
              />
            </ReviewFormItem>
          ),
          existingOptionalProperties,
          (property) => (
            <ReviewFormItem
              key={property?.uuid}
              label={
                property &&
                sentenceCase(property.name, { stripRegexp: /[^A-Z0-9/,;-]/gi })
              }
              name={['material_properties', paramCase(property.code)]}
            >
              <SwitchableInput
                Component={MetaPropertySelect}
                componentProps={{
                  propertyName: property.name,
                  multiple: multiplePropertyValue(property)
                }}
              />
            </ReviewFormItem>
          ),
          compareProperties
        )
      }
      {mapMerge(
        unusedNumericalProperties,
        (property, index) => (
          <ReviewFormItem
            key={property?.uuid}
            label={property?.name}
            name={['material_numerical_properties', 'unused', index]}
          >
            <SwitchableInput
              Component={PropertyRangeInput}
              componentProps={{ property }}
              initialValue={numericalPropertyInitialValue(property)}
            />
          </ReviewFormItem>
        ),
        unusedProperties,
        (property, index) => (
          <ReviewFormItem
            key={property?.uuid}
            label={property?.name}
            name={['material_properties', property && paramCase(property.name)]}
          >
            <SwitchableInput
              Component={MetaPropertySelect}
              componentProps={{
                filter: optionFilter,
                multiple: multiplePropertyValue(property),
                propertyName: property?.name
              }}
            />
          </ReviewFormItem>
        ),
        compareProperties
      )}
    </>
  );
}

MaterialPropertyForm.propTypes = {
  requiredProperties: PropTypes.array,
  setOnFieldsChange: PropTypes.func,
  id: PropTypes.string,
  form: PropTypes.object,
  materialNumericalProperties: PropTypes.array,
  multiple: PropTypes.array,
  materialProperties: PropTypes.array,
  objectType: PropTypes.string.isRequired
};
