import React from 'react';
import { Table } from 'antd';

import { isLast } from './util';

/* eslint-disable react/no-array-index-key */
export default function Summary(
  summaryData,
  supplyColumnKeysArray,
  dealColumnSpansArray
) {
  return (
    <Table.Summary fixed="top">
      {summaryData.map((row, rowIndex) => (
        <Table.Summary.Row key={rowIndex}>
          {supplyColumnKeysArray.map((keyFn, index) => (
            <Table.Summary.Cell
              colSpan={row.dealLevel ? dealColumnSpansArray[index] : 1}
              className={`${dealColumnSpansArray[index] > 0 ? 'first' : ''} ${
                isLast(row, index, supplyColumnKeysArray, dealColumnSpansArray)
                  ? 'last'
                  : ''
              } ${index === 1 ? 'rfq' : ''} ${index > 1 ? 'deal' : ''} ${
                row.className || ''
              }`}
              index={index}
              key={index}
              align={index > 0 ? 'center' : 'left'}
            >
              {keyFn(row)}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      ))}
    </Table.Summary>
  );
}
/* eslint-enable react/no-array-index-key */
