import React from 'react';
import { ReactComponent as Icon } from './svg/rfq.svg';
import SvgIconComponent from './SvgIconComponent';

function RfqIcon() {
  return (
    <SvgIconComponent>
      <Icon />
    </SvgIconComponent>
  );
}

export default RfqIcon;
