import React from 'react';
import PropTypes from 'prop-types';

import CircularLogo from 'src/components/icons/CircularLogo';

export default function BackgroundPageLayout({ children, className }) {
  return (
    <div
      className={`background-page ${className}`}
      style={{
        background: `linear-gradient(0deg, ${process.env.REACT_APP_BRAND_BACKGROUND_GRADIENT_0} 0%, ${process.env.REACT_APP_BRAND_BACKGROUND_GRADIENT_100} 100%)`
      }}
    >
      <CircularLogo />
      <div className="background-page--content">{children}</div>
    </div>
  );
}

BackgroundPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

BackgroundPageLayout.Types = {
  BUYER: 'Buyer',
  SELLER: 'Seller'
};
