import React, { useState } from 'react';
import { Row, Col, Typography, Layout, Table, Button } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';
import { BuildOutlined } from '@ant-design/icons';
import { getRFQs, getRequestsForInformation } from 'src/Query';
import './Concierge.less';

const rfqsColumns = [
  {
    title: 'RFQ',
    dataIndex: 'uuid'
  },
  {
    title: 'Title',
    dataIndex: 'title'
  },
  {
    title: 'Price',
    dataIndex: 'price'
  },
  {
    title: 'Currency',
    dataIndex: 'currency'
  },
  {
    title: 'Quantity in Lbs',
    dataIndex: 'quantity_lbs'
  },
  {
    title: 'Deliver By',
    dataIndex: 'delivery_date'
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];

const rfiColumns = [
  {
    title: 'RFI',
    dataIndex: 'uuid'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Company Name',
    dataIndex: 'company_name'
  },
  {
    title: 'Primary Business',
    dataIndex: 'primary_business'
  },
  {
    title: 'Intent',
    dataIndex: 'buyer_or_seller'
  }
];

export default function ConciergePage() {
  const [rfqsPage, setRfqsPage] = useState({ page: 1, page_size: 10 });
  const [rfiPage, setRfiPage] = useState({ page: 1, pageSize: 10 });
  const navigate = useNavigate();

  const { data: rfqs, isLoading: rfqsLoading } = useQuery(
    ['request-for-quotes', rfqsPage],
    () => getRFQs(rfqsPage)
  );
  const { data: rfi, isLoading: rfiLoading } = useQuery(
    ['request-for-information', rfiPage],
    () => getRequestsForInformation(rfiPage)
  );

  return (
    <Layout>
      <Layout.Header
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography.Title style={{ color: 'white', margin: 'auto' }}>
          Concierge Lounge
        </Typography.Title>
      </Layout.Header>
      <Layout.Content style={{ minWidth: '1024px', margin: '0 auto' }}>
        <Row className="concierge-links">
          <Col>
            <Link to="proposal-builder">
              <Button htmlType="button" type="primary" icon={<BuildOutlined />}>
                Proposal Builder
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Typography.Title level={3}>
              Requests for Information
            </Typography.Title>
            <Table
              columns={rfiColumns}
              dataSource={rfi?.results}
              loading={rfiLoading}
              pagination={{ ...rfiPage, total: rfi?.count }}
              onChange={(page) => {
                setRfiPage(page);
              }}
              rowKey="uuid"
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={3}>Requests for Quotes</Typography.Title>
            <Table
              columns={rfqsColumns}
              dataSource={rfqs?.results}
              loading={rfqsLoading}
              pagination={{ ...rfqsPage, total: rfqs?.count }}
              onChange={(page) => {
                setRfqsPage(page);
              }}
              rowKey="uuid"
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
