import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getRFQ } from '.';

export default function useGetRfq(passedRfqId) {
  // If no RFQ ID passed, try to get from search params or URL path
  const [params] = useSearchParams();
  const queryRfqId = params.get('rfqId');

  const { rfqId: urlRfqId } = useParams();

  const rfqId = passedRfqId || urlRfqId || queryRfqId;
  const enabled = !!rfqId && rfqId !== 'undefined';

  const useQueryObj = useQuery(
    ['requestForQuote', rfqId],
    () => getRFQ(rfqId),
    {
      enabled
    }
  );

  return {
    ...useQueryObj,
    /**
     * isLoading is always true for disabled queries with no cached data. This means
     * we have to also check the enabled prop if we don't want to be left with an
     * infinite loading state when there isn't anything to load. To prevent this,
     * we're overwriting the isLoading prop returned by this hook to be false if
     * enabled is false.
     *
     * See: https://github.com/TanStack/query/issues/3584
     */
    isLoading: useQueryObj.isLoading && enabled,
    enabled
  };
}
