import React, { useEffect, useState } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@tanstack/react-query';
import { submitExpertInformationRequest } from 'src/Mutation';

import SplashPage from 'src/components/layout/SplashPage';
import BulletList from 'src/components/splash/BulletList';
import SplashBanner from 'src/components/splash/SplashBanner';
import SplashContent from 'src/components/splash/SplashContent';
import TextArea from 'antd/lib/input/TextArea';
import BackgroundImage from 'src/images/lattice.jpg';
import CompanyType from 'src/components/form/CompanyType';
import { useUser } from 'src/utils/authentication';

const queryTypeOptions = [
  {
    value: 'expeditedTesting',
    label: 'Expedited PCR sample testing solutions'
  },
  {
    value: 'design',
    label: 'Packaging design strategy for recyclability'
  },
  {
    value: 'pcrSupply',
    label: 'PCR supply variability guidance'
  },
  {
    value: 'esgReporting',
    label: 'Traceability/ESG reporting'
  },
  {
    value: 'other',
    label: 'Other (please specify)'
  }
];

function ExpertAsAService() {
  const [form] = useForm();
  const [isOther, setIsOther] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { data: user } = useUser();
  const { mutate, isLoading } = useMutation({
    mutationFn: submitExpertInformationRequest,
    onError: (e) => {
      const errorMessage = `An error occurred. Please talk to the concierge directly [${e.message}]`;
      messageApi.error(errorMessage);
    },
    onSuccess: () =>
      messageApi.success('We have noted your interest and will be in touch')
  });

  const onFinish = async (values) => {
    const body = {
      company_type: values.companyType,
      title: values.jobTitle,
      additional_information: values.additionalInformation,
      query_type: values.queryType
        .map((o) => queryTypeOptions.find((qt) => qt.value === o).label)
        .join(', '),
      requesting_user: user?.id
    };
    mutate(body);
  };

  const onFieldsChange = (changedFields) => {
    const queryType = changedFields.find((o) => o.name[0] === 'queryType');
    if (queryType) {
      const other = queryType.value.indexOf('other');
      setIsOther(other >= 0);
    }
  };

  useEffect(() => {
    if (user) {
      const { company, title } = user;
      if (company) {
        if (company.company_functions && company.company_functions.length > 0) {
          form.setFieldsValue({
            companyType: company.company_functions[0].uuid
          });
        }
      }
      form.setFieldsValue({
        jobTitle: title
      });
    }
  }, [user]);

  return (
    <SplashPage className="expert banner">
      <div className="splash-container">
        <SplashBanner
          title="Expertise for end-to-end PCR Solutions"
          subtitle="We connect our network of industry experts to find solutions for your specific needs"
          backgroundImage={`linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(20, 31, 52, 0.6), rgba(20, 31, 52, 0.6)), url(${BackgroundImage})`}
        >
          <BulletList>
            <BulletList.Item icon={QuestionCircleFilled}>
              How can I reduce my product test time?
            </BulletList.Item>
            <BulletList.Item icon={QuestionCircleFilled}>
              Which plastic and source is best for my FMCG application?
            </BulletList.Item>
            <BulletList.Item icon={QuestionCircleFilled}>
              How can I reduce the variability inherent in PCR supply?
            </BulletList.Item>
          </BulletList>
        </SplashBanner>
        <SplashContent>
          {contextHolder}
          <div className="splash-content-body">
            <div className="sc-header">Get Started</div>
            <div className="sc-subheader">
              Contact us to learn more about Circular’s expert network.
            </div>
            <Form
              name="expert-form"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={() =>
                message.error('Please complete the fields with errors')
              }
              onFieldsChange={onFieldsChange}
              autoComplete="off"
            >
              <Form.Item
                label="Company Type"
                name="companyType"
                rules={[
                  { required: true, message: 'Company Type is required' }
                ]}
              >
                <CompanyType />
              </Form.Item>
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[{ required: true, message: 'Job Title is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="What types of expertise or PCR management solutions are you interested in?"
                name="queryType"
                rules={[
                  { required: true, message: 'Expertise Type is required' }
                ]}
                className="query-type"
              >
                <Checkbox.Group options={queryTypeOptions} />
              </Form.Item>
              <Form.Item
                className="query-type-other"
                style={isOther ? undefined : { display: 'none' }}
                rules={[{ required: isOther, message: 'Other is required' }]}
                name="other"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Additional Information"
                name="additionalInformation"
              >
                <TextArea
                  placeholder="Please provide any additional information regarding your outreach to Circular today."
                  maxLength={2048}
                  rows={4}
                />
              </Form.Item>
              <Button
                loading={isLoading}
                shape="round"
                type="primary"
                htmlType="submit"
              >
                Connect with a specialist
              </Button>
            </Form>
          </div>
        </SplashContent>
      </div>
    </SplashPage>
  );
}

export default ExpertAsAService;
