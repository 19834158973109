import { Table } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import './RfqAdmin.less';

const EMPTY_ARRAY = [];

export default function RfqAdmin({ rfq }) {
  const uuid = rfq?.uuid;

  const dataSource = [
    {
      url: `${window.location.origin}/request-for-quote/${uuid}`,
      label: 'RFQ'
    },
    {
      url: `${window.location.origin}/buyer-deal/quote/${uuid}/proposals`,
      label: 'Proposal'
    },
    {
      url: `${window.location.origin}/concierge/proposal-builder?rfqId=${uuid}`,
      label: 'Proposal builder'
    }
  ];
  return (
    <div className="rfq-admin">
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: 'RFQ links',
            dataIndex: 'url',
            render: (_, record) => (
              <a target="_blank" href={record.url}>
                {record.label}
              </a>
            )
          }
        ]}
      />
    </div>
  );
}

RfqAdmin.propTypes = {
  rfq: PropTypes.object
};
