import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getTDS, getTDSCohorts } from '.';

export default function useGetTds(passedTdsId) {
  // If no TDS ID passed, try to get from URL
  const [params] = useSearchParams();
  const urlTdsId = params.get('tdsId');

  const tdsId = passedTdsId || urlTdsId;

  return useQuery(['technicalDataSheet', tdsId], () => getTDS(tdsId), {
    enabled: !!tdsId
  });
}

export function useTdsCohorts(tdsId, locationId) {
  return useQuery(
    ['technicalDataSheet', tdsId, locationId, 'cohorts'],
    () => getTDSCohorts(tdsId, locationId),
    {
      enabled: !!tdsId && !!locationId
    }
  );
}
