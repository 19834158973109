import React from 'react';
import { Space, Button } from 'antd';

import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import SupplyComparisonMatchIcon from 'src/pages/rfq/proposal/supply/SupplyComparisonMatchIcon';
import SupplierAvatar from 'src/pages/rfq/proposal/supply/SupplierAvatar';
import SupplierName from 'src/pages/rfq/proposal/supply/SupplierName';
import ShowHideControl from './ShowHideControl';

export function supplyAvatarRow({ deals, label, rfqValue, editTdsCallback }) {
  return {
    label,
    dealLevel: false,
    className: 'supplier-avatar-cell',
    rfq: rfqValue,
    ...deals.reduce((acc, deal) => {
      acc[deal.uuid] = {};
      deal.supplies.forEach((supply, supplyIndex) => {
        acc[deal.uuid][supplyIndex.toString()] = (
          <Space direction="vertical">
            <AdminConsoleLink app="api" type="deal" uuid={deal.uuid}>
              <SupplierAvatar supplier={supply.technical_data_sheet.company} />
            </AdminConsoleLink>
            <SupplierName supplier={supply.technical_data_sheet.company} />
            {editTdsCallback ? (
              <>
                <Button
                  onClick={() =>
                    editTdsCallback(supply.technical_data_sheet.uuid)
                  }
                  block
                >
                  Edit TDS
                </Button>
                <AdminConsoleLink
                  app="api"
                  type="company"
                  uuid={supply.technical_data_sheet.company.uuid}
                >
                  <Button block>Edit Supplier</Button>
                </AdminConsoleLink>
              </>
            ) : null}
          </Space>
        );
      });
      return acc;
    }, {})
  };
}

export function dealDataRow({
  dealsData,
  label,
  key,
  format,
  rfqValue,
  showHide,
  showHideUpdateFunc,
  section,
  className,
  rowClassName
}) {
  return {
    showHide,
    key,
    section,
    label: <ShowHideControl {...{ showHideUpdateFunc, label }} />,
    dealLevel: true,
    rfq: rfqValue,
    ...dealsData.reduce((acc, deal) => {
      acc[deal.uuid] = {};
      acc[deal.uuid]['0'] = format(deal);
      return acc;
    }, {}),
    labelText: label,
    className,
    rowClassName
  };
}

export function supplyColumnKeys(deals) {
  return [
    (row) => row.label,
    (row) => row.rfq,
    ...deals
      .map((deal) =>
        deal.supplies.map(
          (supply, supplyIndex) => (row) => row[deal.uuid][supplyIndex]
        )
      )
      .flat()
  ];
}

export function dealColumnSpans(dealsData) {
  return [
    1,
    1,
    ...dealsData.map((deal) =>
      deal.supplies.map((s, index) => (index === 0 ? deal.supplies.length : 0))
    )
  ].flat();
}

export function supplyDataRow({
  supplyData,
  label,
  key,
  format,
  rfqValue,
  showHide,
  showHideUpdateFunc,
  hideMatch,
  section
}) {
  return {
    key,
    showHide,
    section,
    labelText: label,
    label: <ShowHideControl {...{ showHideUpdateFunc, label }} />,
    dealLevel: false,
    rfq: rfqValue,
    ...supplyData.reduce((acc, supply) => {
      if (!acc[supply.deal]) acc[supply.deal] = {};
      acc[supply.deal][supply.supplyIndex.toString()] = supply[key]
        ?.matchUpdateFunc && (
        <>
          {hideMatch || (
            <>
              <SupplyComparisonMatchIcon
                match={supply[key].match}
                onChange={supply[key].matchUpdateFunc}
              />{' '}
            </>
          )}
          {(format && format(supply[key])) || supply[key].value || ''}
        </>
      );

      return acc;
    }, {})
  };
}

/**
 * We can assume that the KPIs on each supply are in the same order
 * because useProposalSupplyData bases the KPI list for each supply on the RFQ
 */
export function supplyDataKpiRow({ supplyData, index, showHideToggle, key }) {
  const { name, referenceValue, uuid } = supplyData[0].kpis[index];

  const { label, showHide, showHideUpdateFunc } = showHideToggle(name, uuid);
  return {
    key,
    showHide,
    label: <ShowHideControl {...{ showHideUpdateFunc, label }} />,
    labelText: label,
    dealLevel: false,
    rfq: referenceValue,
    ...supplyData.reduce((acc, supply) => {
      if (!acc[supply.deal]) acc[supply.deal] = {};
      acc[supply.deal][supply.supplyIndex.toString()] = (
        <>
          <SupplyComparisonMatchIcon
            match={supply.kpis[index].match}
            onChange={supply.kpis[index].matchUpdateFunc}
          />{' '}
          {supply.kpis[index].value}
        </>
      );
      return acc;
    }, {})
  };
}

export const colSpanDeal = (record, supplyIndex, supplyCount) => {
  if (!record.dealLevel) return undefined;
  if (supplyIndex === 0) return supplyCount;
  return 0;
};

export const colSpanLabel = (record, columns) => undefined;

export const colSpanRfq = (record, columns) => 1;

export const className = (record, supplyIndex, supplyCount) =>
  `deal ${supplyIndex === 0 ? 'first' : ''} ${
    (record.dealLevel && supplyIndex === 0) || supplyIndex === supplyCount - 1
      ? 'last'
      : ''
  } ${record.className || ''}`;

export const isLast = (
  row,
  index,
  supplyColumnKeysArray,
  dealColumnSpansArray
) =>
  index + 1 === supplyColumnKeysArray.length ||
  dealColumnSpansArray[index + 1] > 0 ||
  (row.dealLevel
    ? dealColumnSpansArray[index] > 1
    : dealColumnSpansArray[index] === 1);

export function getRowClassname(record, adminMode) {
  const showHideClasses = adminMode
    ? [
        '', // Visible
        'collapsed', // Section is collapsed
        'hidden' // Always hidden (visible but grayed out in admin mode)
      ]
    : ['', 'collapsed', 'collapsed'];

  return `${record.section ? 'section' : ''} ${
    showHideClasses[record.active]
  } ${record.rowClassName || ''}`;
}
