import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultCurrency } from 'src/config';
import { Switch } from 'antd';

/* Wrapper for a controlled component to
 * allow the attribute to be toggled on and off
 * using a <Switch />
 */

function SwitchableInput({
  value,
  onChange,
  Component,
  initialValue,
  componentProps,
  size,
  ...props
}) {
  const [componentValue, setComponentValue] = useState();
  const [on, setOn] = useState(false);
  const onComponentChange = (newValue) => {
    setComponentValue(newValue);
    onChange(newValue);
  };
  const onSwitchChange = (isOn) => {
    setOn(isOn);
    if (isOn && !componentValue) {
      setComponentValue(initialValue);
    }
    onChange(isOn && (componentValue || initialValue));
  };
  useEffect(() => {
    if (value) {
      setComponentValue(value);
      setOn(true);
    }
  }, [value]);
  return (
    <>
      <Switch size={size} checked={on} onChange={onSwitchChange} />
      {on && (
        <div className="switchable-component">
          <Component
            prefix={defaultCurrency}
            value={componentValue}
            onChange={onComponentChange}
            {...componentProps}
            {...props}
          />
        </div>
      )}
    </>
  );
}

SwitchableInput.propTypes = {
  Component: PropTypes.elementType.isRequired,
  value: PropTypes.any,
  componentProps: PropTypes.object,
  onChange: PropTypes.func,
  size: PropTypes.string,
  initialValue: PropTypes.any
};

export default SwitchableInput;
