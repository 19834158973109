import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export default function useIsBalesMode() {
  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);
  return form === 'bales';
}
