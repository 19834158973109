import React, { useMemo, useState, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getTdsGeoAnalytics } from 'src/Query';
import { useQuery } from '@tanstack/react-query';
import { Tooltip } from 'antd';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import useMap from './hooks/useMap';
import { MapContext } from './MapContext';
import { Marker } from './Marker';

const ZOOM_LEVEL_TDS = 3;
const ZOOM_LEVEL_COUNTRY = 2;
const ZOOM_LEVEL_REGION = 1;

export default function MapPage({ filters, mapProps, additionalMarkers }) {
  const [bounds, setBounds] = useState();
  const { map, mapContainer, latLong, zoom } = useMap({
    mapProps: { ...(mapProps || {}), bounds }
  });

  useEffect(() => {
    if (mapProps?.bounds) {
      setBounds(mapProps?.bounds);
    }
  }, [mapProps?.bounds]);

  const zoomLevel =
    (zoom < 3 && ZOOM_LEVEL_REGION) ||
    (zoom < 5 && ZOOM_LEVEL_COUNTRY) ||
    ZOOM_LEVEL_TDS;
  const nextZoomLevel = (level) => level + 1;
  const zoomLevelIsTdsLevel = zoomLevel === ZOOM_LEVEL_TDS;
  const zoomLevelIsRegionalLevel = zoomLevel === ZOOM_LEVEL_REGION;
  const {
    data: tdsData,
    error,
    isLoading
  } = useQuery({
    queryKey: [
      'technical-data-sheet-geo-analytics',
      {
        zoom_level: zoomLevelIsTdsLevel ? ZOOM_LEVEL_TDS : ZOOM_LEVEL_REGION,
        ...filters
      }
    ],
    queryFn: () =>
      getTdsGeoAnalytics({
        zoom_level: zoomLevelIsTdsLevel ? ZOOM_LEVEL_TDS : ZOOM_LEVEL_REGION,
        ...filters
      }),
    refetchOnWindowFocus: false
  });

  const supplyData = useMemo(
    () =>
      ((zoomLevel === 1 ? aggregateByRegion(tdsData) : tdsData) || []).filter(
        (o) => o.capacity_lbs && o.latitude && o.longitude
      ),
    [tdsData, zoomLevelIsRegionalLevel]
  );
  const quantityMax = useMemo(
    () =>
      supplyData.reduce(
        (max, supply) => Math.max(quantitySize(supply), max),
        0
      ),
    [supplyData]
  );

  const handleMarkerClick = (m, lat, lng) => {
    m.flyTo({
      center: [lng, lat],
      zoom: nextZoomLevel(m.getZoom()),
      speed: 0.5
    });
  };

  useEffect(() => {
    let minLat = bounds?.[0][1];
    let maxLat = bounds?.[1][1];
    let minLng = bounds?.[0][0];
    let maxLng = bounds?.[1][0];

    setTimeout(() => {
      supplyData.forEach((supply) => {
        if (supply.latitude < minLat) minLat = supply.latitude;
        else if (supply.latitude > maxLat) maxLat = supply.latitude;

        if (supply.longitude < minLng) minLng = supply.longitude;
        else if (supply.longitude < maxLng) maxLng = supply.longitude;

        if (
          minLat !== bounds?.[0][1] ||
          maxLat !== bounds?.[1][1] ||
          minLng !== bounds?.[0][0] ||
          maxLng !== bounds?.[1][0]
        )
          setBounds([
            [minLng, minLat],
            [maxLng, maxLat]
          ]);
      });
    }, 1000);
  }, [filters]);

  const markers = useMemo(
    /* eslint-disable react/no-array-index-key */
    () => {
      const allMarkers = [
        ...(map && supplyData?.length
          ? supplyData.map((supply, index) => (
              <SupplyMarker
                supply={supply}
                id={`${
                  supply.tds_uuid || supply.country || supply.region
                }-${index}`}
                key={`${
                  supply.tds_uuid || supply.country || supply.region
                }-${index}`}
                map={map}
                size={markerScale(quantitySize(supply), quantityMax)}
                handleMarkerClick={handleMarkerClick}
              />
            ))
          : []),
        ...(additionalMarkers || [])
      ];

      return allMarkers;
    },
    [map, supplyData, additionalMarkers]
    /* eslint-enable react/no-array-index-key */
  );
  return (
    <MapContext.Provider value={map}>
      <div ref={mapContainer} className="risk-map">
        {markers}
      </div>
    </MapContext.Provider>
  );
}

MapPage.propTypes = {
  filters: PropTypes.object,
  mapProps: PropTypes.object,
  additionalMarkers: PropTypes.array
};

export function SupplyMarker({
  supply,
  id,
  map,
  size,
  handleMarkerClick,
  style,
  title
}) {
  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={size}
      latitude={Number(supply.latitude)}
      longitude={Number(supply.longitude)}
    >
      <Tooltip
        className="map-marker-tooltip"
        color="white"
        title={title || formatSupply(supply)}
        overlayInnerStyle={{ color: '#323640' }}
      >
        <div
          onClick={() => {
            if (handleMarkerClick)
              handleMarkerClick(map, supply.latitude, supply.longitude);
          }}
          role="presentation"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(50, 50, 220, 0.5)',
            borderRadius: '50%',
            ...(style || {})
          }}
          className="supply-marker-content"
        ></div>
      </Tooltip>
    </Marker>
  );
}

SupplyMarker.propTypes = {
  supply: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  map: PropTypes.object,
  size: PropTypes.number.isRequired,
  handleMarkerClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.element
};

function aggregateByRegion(supplyData) {
  if (!supplyData) return [];
  const regionMap = {};
  supplyData.forEach((supply) => {
    if (
      supply.region &&
      supply.latitude &&
      supply.longitude &&
      supply.capacity_lbs
    ) {
      if (!regionMap[supply.region]) {
        regionMap[supply.region] = {
          name: supply.region,
          latitude: 0,
          longitude: 0,
          capacity_lbs: 0,
          price_per_lb: 0,
          price_per_lb_count: 0,
          count: 0
        };
      }
      const regionData = regionMap[supply.region];
      if (supply.price_per_lb) {
        regionData.price_per_lb += supply.price_per_lb;
        regionData.price_per_lb_count += 1;
      }
      regionData.capacity_lbs += supply.capacity_lbs || 0;
      regionData.latitude += supply.latitude;
      regionData.longitude += supply.longitude;
      regionData.count += 1;
    }
  });
  const regions = Object.values(regionMap);
  /* eslint-disable no-param-reassign */
  regions.forEach((regionData) => {
    if (regionData.price_per_lb_count) {
      regionData.price_per_lb /= regionData.price_per_lb_count;
    }
    // weighted average
    regionData.latitude /= regionData.count;
    regionData.longitude /= regionData.count;
    delete regionData.price_per_lb_count;
  });
  /* eslint-enable no-param-reassign */
  return regions;
}

const formatSupplyMap = {
  'Lat , Lng': (supply) =>
    supply.latitude &&
    supply.longitude &&
    `${numeral(supply.latitude).format('0.00')} , ${numeral(
      supply.longitude
    ).format('0.00')}`,
  'TDS admin': (supply) =>
    supply.tds_uuid && (
      <a
        href={`${process.env.REACT_APP_CIRCLE_API}/admin/api/technicaldatasheet/${supply.tds_uuid}/`}
        target="_blank"
      >
        {supply.tds_uuid.slice(0, 6)}
      </a>
    ),
  'Capacity (lbs)': (supply) =>
    supply.capacity_lbs && numeral(supply.capacity_lbs).format('0,0'),
  'Price (/lb)': (supply) =>
    supply.price_per_lb && numeral(supply.price_per_lb).format('0.00'),
  Count: (supply) => supply.count
};

const formatSupplyTitleMap = {
  Country: (supply) => supply.country,
  Region: (supply) => !supply.country && (supply.region || supply.name),
  Supplier: (supply) => supply.supplier
};

function formatSupply(supply) {
  return (
    supply && (
      <table className="map-marker-table">
        <thead>
          {Object.keys(formatSupplyTitleMap).map((key) => {
            const value = formatSupplyTitleMap[key](supply);
            return (
              !!value && (
                <tr key={key}>
                  <th>{key}</th>
                  <th>{value}</th>
                </tr>
              )
            );
          })}
        </thead>
        <tbody>
          {Object.keys(formatSupplyMap).map((key) => {
            const value = formatSupplyMap[key](supply);
            return (
              !!value && (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    )
  );
}

// calculates a quantity based on the whether quantity or capacity is available for the supply
function quantitySize(record) {
  return record.capacity_lbs || 20000;
}

// calculates the radius of the marker based on the quantity and the maxQuantity of all markers
// use a log scale to avoid a few large markers and most markers being the same small size
function markerScale(quantity, maxQuantity) {
  return Math.sqrt((quantity || 0) / maxQuantity) * 200 + 10;
}
