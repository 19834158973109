import React from 'react';
import PropTypes from 'prop-types';
import { Space, Tooltip } from 'antd';

export default function AnalyticsTile({
  children,
  source,
  sourceDetails,
  latest,
  titles,
  action,
  tileTitle,
  highlight
}) {
  return (
    <div className="analytics-tile">
      <div className="analytics-tile-chart">
        <div className="analytics-tile-header">
          {tileTitle && <div className="analytics-tile-title">{tileTitle}</div>}
          <div className="analytics-tile-source">
            <Tooltip
              className="tooltip-wrap"
              title={sourceDetails?.split('\n').map((line) => (
                <p key={line}>{line}</p>
              ))}
            >
              Sourced from {source}. Last updated {latest}
            </Tooltip>
          </div>
          <div className="analytics-tile-highlight">{highlight}</div>
        </div>
        <div className="analytics-tile-line-titles">
          <Space direction="horizontal">
            {(Array.isArray(titles) &&
              titles.map((title, i) => (
                <div
                  className="analytics-tile-line-titles--single-title"
                  key={title.text}
                >
                  <span
                    style={{
                      backgroundColor: i === 0 ? title.color : 'transparent',
                      borderColor: title.color
                    }}
                  />
                  {title.text}
                </div>
              ))) ||
              titles ||
              ''}
          </Space>
          <span className="action">{action}</span>
        </div>
        {children}
      </div>
    </div>
  );
}

AnalyticsTile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  source: PropTypes.string,
  sourceDetails: PropTypes.string,
  latest: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.node,
  tileTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  highlight: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
