import React from 'react';
import PropTypes from 'prop-types';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';

function SupplierTdssCount({ suppliers, tdss }) {
  return (
    <>
      {prettyNumberRound(suppliers)}
      {/* <br />
      <span
        style={{
          color: '#64748d',
          fontSize: 13,
          lineHeight: '14px',
          fontWeight: 300
        }}
      >
        {prettyNumberRound(tdss)}
      </span> */}
    </>
  );
}

SupplierTdssCount.propTypes = {
  suppliers: PropTypes.number,
  tdss: PropTypes.number
};

export default SupplierTdssCount;
