import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DealWelcome from './DealWelcome';
import DealRoom from './DealRoom';
import './Deal.less';
import DealSampling from './DealSampling';

function DealRouter() {
  return (
    <Routes>
      <Route path="/welcome/:id" element={<DealWelcome />} />
      <Route exact path="/:id" element={<DealRoom />} />
      <Route path="/:id/sampling" element={<DealSampling />} />
    </Routes>
  );
}

export default DealRouter;
