import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_VALUE = Array(10).fill('empty');

function BlockProgress({ value = DEFAULT_VALUE }) {
  return (
    <div className="block-progress">
      <div className="block-progress--blocks">
        {value.map((type, index) => (
          <div className={`box ${type}`} key={index}></div>
        ))}
      </div>
    </div>
  );
}

BlockProgress.propTypes = {
  value: PropTypes.array
};

export default BlockProgress;
