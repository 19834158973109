import React, { useState } from 'react';
import { Form, Select, Input, Button, Row, Col, Space } from 'antd';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  EnvironmentOutlined,
  SearchOutlined,
  TableOutlined
} from '@ant-design/icons';
import { saveAs } from 'file-saver';

import { evaluateSupplierList } from 'src/Mutation';
import { getTdsCsv } from 'src/Query';
import useGetRfq from 'src/Query/useGetRfq';
import useProposalBuilderFilters from './useProposalBuilderFilters';

/* eslint-disable react/no-unstable-nested-components */
export default function TableActions({ selectedRows, suppliersCount }) {
  const { data: rfqFilter } = useGetRfq();
  const [form] = Form.useForm();

  const { filters, display, setDisplay } = useProposalBuilderFilters();

  const queryClient = useQueryClient();
  const {
    mutate: mutateEvaluateSupplierList,
    isLoading: isLoadingEvaluateSupplierList
  } = useMutation({
    mutationFn: evaluateSupplierList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['requestForQuote', rfqFilter?.uuid]
      });
    }
  });

  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const download = async () => {
    try {
      setDownloadingCsv(true);
      const blob = await getTdsCsv({
        all: true,
        ...filters
      });
      const title = rfqFilter?.title || 'Proposal';
      const date = dayjs().format('YY-MM-DD HHmm');
      saveAs(blob, `${title} ${date}.csv`);
    } finally {
      setDownloadingCsv(false);
    }
  };

  const markAll = () => {
    const { comment, markAs } = form.getFieldsValue();
    if (!markAs) return;
    const attribute = markAs[0] === '-' ? markAs.slice(1) : markAs;
    const value = markAs[0] !== '-';
    mutateEvaluateSupplierList({
      params: {
        rfqId: rfqFilter.uuid,
        notes: comment || '',
        attribute,
        value,
        ...filters
      },
      body: selectedRows?.length
        ? {
            companies: selectedRows.map((row) => row.uuid)
          }
        : undefined
    });
  };

  return (
    <Row justify="space-between" className="table-actions">
      <Col>
        {display === 'table' && rfqFilter && (
          <Form
            layout="inline"
            className="evaluate"
            size="small"
            form={form}
            onFinish={markAll}
            disabled={selectedRows.length === 0 && suppliersCount === 0}
          >
            <Form.Item
              className="mark-as"
              label="Mark as"
              name="markAs"
              initialValue="matched"
            >
              <Select
                options={[
                  {
                    label: 'Matched',
                    value: 'matched'
                  },
                  {
                    label: 'Undo matched',
                    value: '-matched'
                  },
                  {
                    label: 'Contacted',
                    value: 'contacted'
                  },
                  {
                    label: 'Undo contacted',
                    value: '-contacted'
                  },
                  {
                    label: 'Engaged',
                    value: 'engaged'
                  },
                  {
                    label: 'Undo engaged',
                    value: '-engaged'
                  },
                  {
                    label: 'Screened',
                    value: 'screened'
                  },
                  {
                    label: 'Undo screened',
                    value: '-screened'
                  },
                  {
                    label: 'Approved',
                    value: 'approved'
                  },
                  {
                    label: 'Undo approved',
                    value: '-approved'
                  },
                  {
                    label: 'Disqualified',
                    value: 'disqualified'
                  },
                  {
                    label: 'Undo disqualified',
                    value: '-disqualified'
                  }
                ]}
              />
            </Form.Item>
            <Form.Item name="comment" className="comment">
              <Input placeholder="Comment" />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingEvaluateSupplierList}
            >
              {selectedRows.length > 0 || suppliersCount > 0 ? (
                <>Mark {selectedRows.length || suppliersCount} suppliers</>
              ) : (
                <>No suppliers to mark</>
              )}
            </Button>
          </Form>
        )}
      </Col>
      <Col>
        <Form layout="inline" size="small">
          <Space direction="horizontal">
            <Select
              value={display}
              onChange={(val) => setDisplay(val)}
              options={[
                {
                  label: (
                    <>
                      <TableOutlined /> Table
                    </>
                  ),
                  value: 'table'
                },
                {
                  label: (
                    <>
                      <EnvironmentOutlined /> Map
                    </>
                  ),
                  value: 'map'
                },
                {
                  label: (
                    <>
                      <SearchOutlined /> Search
                    </>
                  ),
                  value: 'search'
                }
              ]}
            />
            <Button
              loading={downloadingCsv}
              type="link"
              onClick={() => download()}
            >
              Download CSV
            </Button>
          </Space>
        </Form>
      </Col>
    </Row>
  );
}

TableActions.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  suppliersCount: PropTypes.number
};
