import React from 'react';
import { Alert, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import Location from 'src/components/project/explore/Location';

export default function SupplierList({ locked }) {
  return (
    <div className="supplier-list">
      <Row className="mt-lg" justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} className="mb-xs">
            Prioritize Supplier List
          </Typography.Title>
          <Typography.Paragraph>
            Remove or edit suppliers and approve list to continue.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row className="mb-lg" justify="space-between" align="middle">
        {locked ? (
          <Col span={24}>
            <Alert
              showIcon
              message="Your Digital RFQ has been sent. This supplier list is locked."
            />
          </Col>
        ) : null}
      </Row>
      <Location locked={locked} />
    </div>
  );
}

SupplierList.propTypes = {
  locked: PropTypes.bool
};
