import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

export const compareDate = (d1, d2) => {
  const dayjs1 = dayjs(d1);
  const dayjs2 = dayjs(d2);
  if (dayjs1.isBefore(dayjs2)) return -1;
  if (dayjs1.isAfter(dayjs2)) return 1;
  return 0;
};

/* 
   If both are shipped (but not delivered) then order is in ascending shipping date
   If only one is shipped then it comes first
   Otherwise order is based on created_at
*/

export const compareDeliveryByDate = (d1, d2) => {
  if (d1.shipped_at && d2.shipped_at) {
    return compareDate(d1.shipped_at, d2.shipped_at);
  }
  if (d1.shipped_at) {
    return -1;
  }
  if (d2.shipped_at) {
    return 1;
  }
  return compareDate(d1.created_at, d2.created_at);
};

const nextSunday = (date) =>
  date.day() === 0 ? date : date.add(1, 'weeks').day(0);

const monthTicks = (date) => {
  const daysInPeriod = date.add(1, 'month').diff(date, 'days');
  let sunday = nextSunday(date);
  const ticks = [];
  while (sunday.month() === date.month()) {
    const day = sunday.date();
    const proportion = (day - 1) / daysInPeriod;
    ticks.push({ label: `${day}`, proportion });
    sunday = sunday.add(7, 'days');
  }
  return ticks;
};

const quarterTicks = (date) => {
  const daysInPeriod = date.add(1, 'quarter').diff(date, 'days');
  let next = date;
  const ticks = [];
  while (next.quarter() === date.quarter()) {
    const daysFromStart = next.diff(date, 'days');
    const proportion = daysFromStart / daysInPeriod;
    ticks.push({ label: next.format('MMM'), proportion });
    next = next.add(1, 'month');
  }
  return ticks;
};

export const bucketLabel = (startDate, frequency) => {
  if (frequency === 'month') {
    return {
      header: startDate.format("MMM [']YY"),
      ticks: monthTicks(startDate)
    };
  }
  if (frequency === 'quarter') {
    const q = dayjs(startDate).quarter();
    return {
      header: q === 1 ? `${startDate.format('YYYY')} Q${q}` : `Q${q}`,
      ticks: quarterTicks(startDate)
    };
  }
  return undefined;
};

export const deliveryRange = (indices) => {
  if (indices.length === 0) return '';
  if (indices.length === 1) return indices[0];
  return `${indices[0]} - ${indices[indices.length - 1]}`;
};

export const deliveryBucketStartDate = (delivery) =>
  dayjs(delivery.shipped_at || delivery.created_at);

export const deliveryBucketEndDate = (delivery) =>
  dayjs(delivery.delivered_at || delivery.shipped_at || delivery.created_at);

export const generateDeliverySchedule = (deliveries, suppliers, frequency) => {
  const d = deliveries.map((delivery) => ({
    ...delivery,
    created_at: delivery.created_at && dayjs(delivery.created_at),
    updated_at: delivery.updated_at && dayjs(delivery.updated_at),
    shipped_at: delivery.shipped_at && dayjs(delivery.shipped_at),
    delivered_at: delivery.delivered_at && dayjs(delivery.delivered_at)
  }));
  d.sort(compareDeliveryByDate);
  if (d.length > 0) {
    const minDate = deliveryBucketStartDate(d[0]);
    const maxDateFromDelivery = d.reduce((acc, current) => {
      const end = deliveryBucketEndDate(current);
      if (end.isAfter(acc)) return end;
      return acc;
    }, dayjs());
    // we always want to see at least one year
    const firstBucketStartDate = minDate.startOf(frequency);
    const maxDate =
      maxDateFromDelivery.diff(firstBucketStartDate, 'days') > 365
        ? maxDateFromDelivery
        : firstBucketStartDate.add(1, 'year');
    const bucketList = [];
    for (
      let nextBucketDate = minDate.startOf(frequency);
      nextBucketDate.isBefore(maxDate);
      nextBucketDate = nextBucketDate.add(1, frequency)
    ) {
      bucketList.push({
        start: nextBucketDate,
        labels: bucketLabel(nextBucketDate, frequency)
      });
    }
    const deliveryBuckets = {};
    d.forEach((delivery, deliveryIndex) => {
      if (!deliveryBuckets[delivery.supplier]) {
        const supplier = suppliers.find((o) => o.uuid === delivery.supplier);
        const supplierData = {
          name: supplier?.name,
          avatar: supplier?.avatar,
          key: delivery.supplier,
          deliveries: [],
          buckets: bucketList.map((o) => ({
            ...o,
            quantity: {},
            quantitySample: 0,
            deliveryIndices: []
          }))
        };
        deliveryBuckets[delivery.supplier] = supplierData;
      }

      const supplierData = deliveryBuckets[delivery.supplier];
      supplierData.deliveries.push(delivery);
      const deliveryDate = deliveryBucketEndDate(delivery);
      const bucket = supplierData.buckets.find(
        (o, index) =>
          index + 1 === supplierData.buckets.length ||
          supplierData.buckets[index + 1].start.isAfter(deliveryDate)
      );

      bucket.quantity[delivery.type] =
        (bucket.quantity[delivery.type] || 0) + delivery.quantity;
      bucket.deliveryIndices.push(deliveryIndex);
      if (!supplierData.purpose) supplierData.purpose = delivery.purpose; // all deliveries from the same supplier have the same purpose
    });
    return deliveryBuckets;
  }
  return {};
};
