export const REGION_AMERICAS_NORTH = 'americas_north';
export const REGION_AMERICAS_CENTRAL = 'americas_central';
export const REGION_AMERICAS_SOUTH = 'americas_south';
export const REGION_EU = 'eu';
export const REGION_ASIA = 'asia';
export const REGION_OCEANIA = 'oceania';
export const REGION_AFRICA = 'africa';

export const REGIONS = {
  [REGION_AMERICAS_NORTH]: { label: 'North America' },
  [REGION_AMERICAS_CENTRAL]: { label: 'Central America' },
  [REGION_AMERICAS_SOUTH]: { label: 'South America' },
  [REGION_EU]: { label: 'Europe' },
  [REGION_ASIA]: { label: 'Asia' },
  [REGION_OCEANIA]: { label: 'Oceania' },
  [REGION_AFRICA]: { label: 'Africa' }
};

export const TYPES = ['rPET', 'rHDPE/rLDPE', 'rPP', 'Other'];
export const FORMS = ['Resin / Pellets', 'Regrind / Flakes', 'Bales'];
