import PropTypes from 'prop-types';
import { Button, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import numericRange from 'src/components/utils/numericRange';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';
import TdsQuantity from 'src/components/project/search/TdsQuantity';
import SupplierAvatar from 'src/components/project/search/SupplierAvatar';
import useSupplierList from 'src/components/project/explore/hooks/useSupplierList';
import { labelMap } from 'src/components/project/explore/Suppliers';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';

export default function CompareSuppliers({ btnText, btnProps }) {
  const [open, setOpen] = useState();
  const [kpis] = useConciergeContextState(['explore', 'filters', 'kpis']);
  const { activeProject } = useSidebarContext();
  const projectId = activeProject?.uuid;

  const { results, isLoadingSuppliers, resultDetails } = useSupplierList({
    filterSupplierLabels: labelMap.accepted,
    projectId
  });

  return (
    <>
      <Button
        disabled={!results.length}
        onClick={() => setOpen(true)}
        {...(btnProps || {})}
      >
        {btnText || 'Review Accepted Suppliers'}
      </Button>
      <Modal
        title="Compare Suppliers"
        onCancel={() => setOpen(false)}
        width={950}
        footer={null}
        open={open}
      >
        <div
          className="supplier-compare"
          style={{
            gridTemplateColumns: `repeat(${
              1 + results.length
            },minmax(150px, 1fr))`
          }}
        >
          <div className="supplier-compare--row">
            <div />
            {results.map((supplier) => (
              <SupplierAvatar key={supplier.company_id} company={supplier} />
            ))}
          </div>
          <div className="supplier-compare--row">
            <div />
            {results.map(({ company_name }) => (
              <Typography.Title key={company_name} level={5}>
                {company_name}
              </Typography.Title>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Material</Typography.Text>
            {results.map(({ tds_list: [tds] }) => (
              <div key={tds.tds_id}>{tds.type}</div>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Form</Typography.Text>
            {results.map(({ tds_list: [tds] }) => (
              <div key={tds.tds_id}>{tds.form}</div>
            ))}
          </div>
          <div className="supplier-compare--row">
            <Typography.Text>Quantity</Typography.Text>
            {results.map(({ tds_list: [tds] }) => (
              <div key={tds.tds_id}>
                <TdsQuantity tds={tds} />
              </div>
            ))}
          </div>
          {/* <div className="supplier-compare--row">
            <Typography.Text>Material Cost /lb</Typography.Text>
            {results.map(({tds_list: [tds]}) => (
              <div key={tds.tds_id}>{currencyValuePrice(tds.price_per_lb)}</div>
            ))}
          </div> */}
          {/* KPIs */}
          {kpis &&
            Object.keys(kpis).map((kpiCode) => (
              <div className="supplier-compare--row" key={kpiCode}>
                <div>
                  <KpiName codeOrUuid={kpiCode} />
                </div>
                {results.map(({ tds_list: [tds] }) => {
                  const kpiKey = `kpi_${kpiCode}`;
                  const tdsKpiMin = tds[`${kpiKey}.min`];
                  const tdsKpiMax = tds[`${kpiKey}.max`];
                  const tdsKpiUnits = tds[`${kpiKey}.units`];
                  return (
                    <div key={tds.tds_id}>
                      {numericRange({
                        min: tdsKpiMin,
                        max: tdsKpiMax,
                        empty: '–',
                        rangeRender: (min, max) =>
                          `${min} - ${max} ${tdsKpiUnits}`,
                        noMinRender: (v) => `${v} or - ${tdsKpiUnits}`,
                        noMaxRender: (v) => `${v} or + ${tdsKpiUnits}`,
                        singleValueRender: (v) => `${v} ${tdsKpiUnits}`
                      })}
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
}
CompareSuppliers.propTypes = {
  btnText: PropTypes.string,
  btnProps: PropTypes.object
};
