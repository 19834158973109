function parse(x) {
  // eslint-disable-next-line eqeqeq
  return x == x * 1 ? x * 1 : x;
}

export function highlightFilters(chart) {
  const {
    rangeStart: paramStart,
    rangeEnd: paramEnd,
    step: paramStep
  } = chart.config.options;

  const { ctx } = chart;

  const xAxis = chart.scales.x;
  const yAxis = chart.scales.y;

  if ((!paramStart && !paramEnd) || yAxis.ticks.length === 0) return;

  const minTick = yAxis.ticks[0];
  const maxTick = yAxis.ticks[yAxis.ticks.length - 1];

  if (!minTick || !maxTick) return;

  const graphMinValue = parse(minTick.label.toString().replace(/,/g, ''));
  const graphMaxValue = parse(maxTick.label.toString().replace(/,/g, ''));

  let rangeStart;
  let rangeEnd;
  if (paramStart === paramEnd) {
    rangeStart = paramStart - paramStep / 2.0;
    rangeEnd = paramStart + paramStep / 2.0;
  } else {
    rangeStart = paramStart || graphMinValue - paramStep;
    rangeEnd = paramEnd || graphMaxValue + paramStep;
  }

  const slope =
    (maxTick.value - minTick.value) / (graphMaxValue - graphMinValue);

  const scaledStart = minTick.value + slope * (rangeStart - graphMinValue);
  const scaledEnd = minTick.value + slope * (rangeEnd - graphMinValue);

  const yRangeBeginPixel = yAxis.getPixelForValue(scaledStart);
  const yRangeEndPixel = yAxis.getPixelForValue(scaledEnd);

  ctx.save();

  ctx.fillStyle = 'rgba(47, 84, 235, 0.10)';
  ctx.fillRect(
    xAxis.left,
    yRangeBeginPixel,
    xAxis.right - xAxis.left,
    yRangeEndPixel - yRangeBeginPixel
  );

  ctx.restore();
}
