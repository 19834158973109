import jwtDecode from 'jwt-decode';
import AsyncLock from 'async-lock';

const refreshLock = new AsyncLock();

const isTokenExpired = (token) => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  // add buffer in case of clock sync differences
  return Date.now() >= (decoded.exp - 120) * 1000;
};

const refreshToken = async (token, url) =>
  // if there are multiple calls to `fetch()` concurrently we do not want
  // them all refreshing the token, so we block while the first one refreshes
  // the token and then have the others detect the token has changed
  // and use the new token

  refreshLock
    .acquire('refreshToken', async (done) => {
      const refresh_token = window.localStorage.getItem('refreshToken');
      if (refresh_token !== token) {
        // the token was already refreshed, so we can just return the new access token
        const accessToken = window.localStorage.getItem('accessToken');
        done(null, accessToken);
      } else {
        try {
          const headers = {
            'Content-Type': 'application/json'
          };
          const response = await window.fetch(
            `${process.env.REACT_APP_CIRCLE_API}/auth/refresh/`,
            {
              method: 'POST',
              headers,
              body: JSON.stringify({ refresh: token })
            }
          );
          if (response.status === 401) {
            window.localStorage.removeItem('accessToken');
            window.localStorage.removeItem('refreshToken');
            window.console.error('401 removing token');
            done('Refresh token expired');
          } else {
            const json = await response.json();
            if (!json?.access || !json?.refresh) {
              const message = `Error refreshing accessToken, response does not contain expected tokens\ntoken: ${token}\nresponse: ${JSON.stringify(
                json
              )}`;
              window.localStorage.removeItem('accessToken');
              window.localStorage.removeItem('refreshToken');
              window.console.error(message);
              done(message);
            } else {
              window.localStorage.setItem('accessToken', json.access);
              window.localStorage.setItem('refreshToken', json.refresh);
              done(null, json.access);
            }
          }
        } catch (ex) {
          window.console.error(
            `Exception refreshing accessToken ${ex?.detail || ex}`
          );
          done(ex);
        }
      }
    })
    .catch((err) => {
      window.console.error(
        `Error refreshing token before request to ${url}:`,
        err
      );
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      const signInRedirect = `/login?next=${window.location.pathname}`;
      if (window.location.pathname !== '/login') {
        // I don't love using the browser API here? is it better to use react router navigation?
        // If so we need to make this a hook
        window?.location?.replace?.(signInRedirect);
      }
      throw err;
    });

// if the token refetch fails it redirects to the login screen
// and does not return an exception
export const fetch = async (url, options) => {
  let token = window.localStorage.getItem('accessToken');
  const signInRedirect = `/login?next=${window.location.pathname}`;
  const refresh_token = window.localStorage.getItem('refreshToken');
  if (token && refresh_token && isTokenExpired(token)) {
    token = await refreshToken(refresh_token, url);
  }

  const headers = {
    // Default to JSON, but allow overwriting via options.headers
    'Content-Type': 'application/json',
    ...(options && options.headers)
  };

  if (token && !headers.Authorization) {
    headers.Authorization = `Bearer ${token}`;
  } else if (options?.mustAuthenticate) {
    window.console.log('Unauthorized');
    const error = new Error('Unauthorized');
    error.status = 401;
    return error;
  }

  const response = await window.fetch(url, {
    ...options,
    headers
  });

  let data;

  try {
    if (headers.Accept) data = await response.blob();
    else data = await response.json();
  } catch (e) {
    data = {};
  }

  if (response.ok) {
    return data;
  }

  if (!response.ok && url.indexOf('/user/me/') !== -1 && !token) return null;
  if (window.location.pathname !== '/login') {
    if (
      data?.detail === 'Authentication credentials were not provided.' ||
      data?.detail === 'Unauthorized.' ||
      data?.detail === 'User not found'
    ) {
      window?.location?.replace?.(signInRedirect);
    }
  }
  let error;
  if (data) error = new Error(data?.detail || data?.token?.[0] || data?.error);
  else error = new Error('Unknown error in fetch');
  error.status = response.status;
  error.detail = data;
  throw error;
};
