import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AlignLeftOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { InlineWidget } from 'react-calendly';
import { Link } from 'react-router-dom';

import { submitOrPatchRFQ } from 'src/Mutation';
import { getDemoProject } from 'src/Query';
import { useUser } from 'src/utils/authentication';
import UploadDocuments from 'src/components/form/UploadDocuments';
import useCalendlyCallback from 'src/hooks/useCalendlyCallback';
import useCalendlyEvent from 'src/hooks/useCalendlyEvent';
import DarkPanel from 'src/components/layout/DarkPanel';
import TaskStatusEmpty from 'src/components/icons/TaskStatusEmpty';
import { findProperties } from 'src/utils/properties';
import prettyNumber from 'src/components/utils/prettyNumber';
import UsersIcon from 'src/components/icons/UsersIcon';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import { isConcierge, isSupplier } from 'src/utils/permissions';

const DEFAULT_PROJECTS = [];

const formatPropertyList = (list) => {
  if (Array.isArray(list)) {
    return list.map((o) => o.value).join(', ');
  }
  return '-';
};

const formatLocation = (location) => {
  if (location.city && location.country)
    return `${location.city}, ${location.country}`;
  if (location.city) return location.city;
  if (location.country) return location.country;
  return '';
};

function ProjectDashboard() {
  const { data: demoProject, isLoading: demoProjectLoading } = useQuery({
    queryKey: ['demo-project'],
    queryFn: () => getDemoProject(),
    staleTime: 1000 * 3 * 60,
    cacheTime: 1000 * 5 * 60
  });
  const { pushCascade, popCascade } = useSidebarContext();

  useEffect(() => {
    pushCascade(undefined, 'dashboard', 0);
    return () => popCascade('dashboard');
  }, []);

  const projects = DEFAULT_PROJECTS;

  const allProjects = useMemo(() => {
    const rfqProjects = projects
      .map((rfqProject) =>
        rfqProject.projects.map((project) => ({
          project,
          request_for_quote: rfqProject.request_for_quote
        }))
      )
      .flat();
    const allProjectList = demoProject
      ? [demoProject, ...rfqProjects]
      : rfqProjects;
    return allProjectList;
  }, [projects, demoProject]);

  const [hideExplore] = useConciergeContextState(['explore', 'hide']);

  const projectTableData = useMemo(
    () =>
      allProjects.map((project) => ({
        title: project.project.title,
        phase: 'explore',
        material: formatPropertyList(
          findProperties(project.request_for_quote, 'type')
        ),
        quantity: prettyNumber(project.request_for_quote.quantity),
        destination: project.request_for_quote.shipping_addresses
          .map((o) => formatLocation(o))
          .join('/'),
        participants: project.project.num_participants
      })),
    [projects, demoProject]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'title'
      },
      {
        title: 'Project Phase',
        dataIndex: 'phae'
      },
      {
        title: 'Material',
        dataIndex: 'material'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity'
      },
      {
        title: 'Destination',
        dataIndex: 'destination'
      },
      {
        title: 'Suppliers',
        dataIndex: 'suppliers'
      },
      {
        dataIndex: 'participants',
        render: (participants) => (
          <>
            <UsersIcon /> <span>{participants}</span>
          </>
        )
      }
    ],
    []
  );

  const { data: user } = useUser();
  const supplier = isSupplier(user) && !isConcierge(user);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: process.env.REACT_APP_BRAND_PRIMARY_COLOR,
          controlHeight: 36,
          fontFamily: 'Poppins, sans-serif',
          borderRadius: 2
        },
        components: {
          Button: {
            borderRadius: 2
          }
        }
      }}
    >
      <div className="project-dashboard">
        <div className="project-dashboard-top">
          <Row>
            <Typography.Title level={3}>Dashboard</Typography.Title>
            {!hideExplore && !supplier && (
              <Link to="/project/explore/?new=1">
                <Button type="primary" htmlType="button">
                  New project
                </Button>
              </Link>
            )}
          </Row>
        </div>
        <div className="project-table">
          <Space direction="vertical" size={16}>
            <Typography.Title level={5}>All Projects</Typography.Title>
            <Table
              dataSource={projectTableData}
              columns={columns}
              pagination={false}
            />
          </Space>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default ProjectDashboard;

function ProjectCreateModal({ closeModal, modalOpen, close }) {
  const [form] = Form.useForm();

  const [step, setStep] = useState(0);
  const [eventId, setEventId] = useState();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: submitOrPatchRFQ,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects']
      });
    }
  });

  useCalendlyCallback((newEventId) => {
    setEventId(newEventId);
    form.setFieldValue('review_event_id', newEventId);
    setStep(2);
    form.submit();
  });

  const { data: user } = useUser();

  const createRfq = () => {
    const values = form.getFieldsValue(true);

    const documents_ids = values.documents?.map((o) => o.uuid) || [];

    mutate({
      documents_ids,
      goal_description: values.goal_description,
      product_description: values.product_description,
      sourcing_priorities: values.sourcing_priorities,
      review_event_id: values.review_event_id
    });
  };

  const titleMap = ['Start new project', 'Schedule RFQ Review', undefined];
  return (
    <Modal
      title={titleMap[step]}
      open={modalOpen}
      show
      onOk={() => {
        if (step === 0) {
          setStep(1);
        } else {
          form.submit();
        }
      }}
      footer={null}
      onCancel={closeModal}
      width={684}
      className="project-page__project-create"
    >
      <Form
        name="project"
        layout="vertical"
        form={form}
        disabled={isLoading}
        onFinish={createRfq}
        preserve
      >
        <Form.Item hidden name="review_event_id">
          <Input />
        </Form.Item>
        {step === 0 && (
          <>
            <Form.Item
              label="Upload your project documents"
              extra={
                <>
                  <p>
                    <Typography.Text>
                      Upload project documents that you would typically use to
                      start your own PCR search here (e.g. Application
                      description, technical data sheets, material quality and
                      testing requirements, purchase orders, etc.).
                    </Typography.Text>
                  </p>
                  <p>
                    <Typography.Text italic type="secondary">
                      Please provide the (PCR) specifications, if available,
                      rather than virgin material specs.
                    </Typography.Text>
                  </p>
                </>
              }
              name="documents"
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
              preserve
            >
              <UploadDocuments />
            </Form.Item>

            <Form.Item
              name="goal_description"
              label="Project Insight"
              extra={
                <p>
                  <Typography.Text>
                    What's a must-have or deal-breaker for you when sourcing
                    material? For instance, are there specific KPIs or location
                    preferences we should be aware of?
                  </Typography.Text>
                </p>
              }
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
              preserve
            >
              <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item
              name="product_description"
              label="Project Details"
              extra={
                <p>
                  <Typography.Text>
                    Can you describe the product you're aiming to create?
                    Highlight any specific specifications or tests vital for
                    you.
                  </Typography.Text>
                </p>
              }
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
              preserve
            >
              <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item
              name="sourcing_priorities"
              label="Priorities in Sourcing"
              extra={
                <p>
                  <Typography.Text>
                    What's most important to you in this process? Whether it's
                    getting a more competitive price, exploring new suppliers,
                    emphasizing low carbon emissions, requiring full-service
                    supplier onboarding, or managing supply chain and logistics
                    effectively - please let us know your top priorities.
                  </Typography.Text>
                </p>
              }
              rules={[
                {
                  required: true,
                  message: 'This field is required.'
                }
              ]}
              preserve
            >
              <Input.TextArea rows={6} />
            </Form.Item>
            <div className="button-bar">
              <Button
                type="primary"
                htmlType="button"
                onClick={async () => {
                  await form.validateFields();
                  setStep(1);
                }}
                loading={isLoading}
              >
                Next
              </Button>
            </div>
          </>
        )}
        {step === 1 && (
          <InlineWidget
            url={`${process.env.REACT_APP_CALENDLY_MEETING_URL}?hide_gdpr_banner=1`}
            styles={{
              height: '690px',
              minWidth: '620px'
            }}
            prefill={{
              name: user?.name,
              email: user?.email
            }}
          />
        )}
        {step === 2 && <CreateProjectStatus eventId={eventId} close={close} />}
      </Form>
    </Modal>
  );
}
ProjectCreateModal.propTypes = {
  closeModal: PropTypes.func,
  modalOpen: PropTypes.bool,
  close: PropTypes.func
};

export function CreateProjectStatus({ eventId, close }) {
  const {
    event,
    isLoading: isLoadingCalendly,
    isError: isErrorCalendly,
    dateTime,
    date,
    timezone,
    minutes
  } = useCalendlyEvent(eventId);

  return (
    <section className="project-status">
      <Typography.Title level={2}>Thank you!</Typography.Title>
      <p>
        We have all the details we need. We will have your project RFQ ready for
        review on {date}.
      </p>
      <div className="kickoff-call-details">
        {event && (
          <Row gutter={[32, 32]}>
            <Col lg={3}>
              <span className="icon-style">
                <CalendarOutlined />
              </span>
            </Col>
            <Col lg={21} className="minutes">
              {minutes} Minute Meeting
            </Col>
            <Col lg={3}>
              <span className="icon-style">
                <ClockCircleOutlined />
              </span>
            </Col>
            <Col lg={21}>
              <div>{dateTime}</div>
              <div className="timezone">{timezone}</div>
            </Col>
            <Col lg={3}>
              <span className="icon-style">
                <AlignLeftOutlined />
              </span>
            </Col>
            <Col lg={21}>
              <div>
                We’ll review your project details. If you have any questions or
                change requests before your meeting, please reach out to&nbsp;
                <a href="mailto:mariah@circular.co">mariah@circular.co</a>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Button type="primary" htmlType="button" onClick={() => close()}>
        Close
      </Button>
    </section>
  );
}

CreateProjectStatus.propTypes = {
  eventId: PropTypes.string,
  close: PropTypes.func
};

function Task({ title, subtitle, due, danger }) {
  return (
    <DarkPanel.Box>
      <TaskStatusEmpty />
      <div>
        <div className="task-title">{title}</div>
        <div className="task-subtitle">
          <span className={danger ? 'danger' : undefined}>{due}</span>
          &nbsp;&nbsp;
          {subtitle}
        </div>
      </div>
    </DarkPanel.Box>
  );
}

Task.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  due: PropTypes.string,
  danger: PropTypes.bool
};
