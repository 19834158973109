import { ReactComponent as CircularLogo } from 'src/images/CircularLogo.svg';
import { ReactComponent as CircularLogoDark } from 'src/images/CircularLogoDark.svg';
import React from 'react';
import PropTypes from 'prop-types';
import { DASHBOARD_URL } from 'src/pages/auth/SignIn';

function Logo({ light = false, link = DASHBOARD_URL, ...props }) {
  return (
    <a className="logo" href={link} {...props}>
      {light ? <CircularLogo /> : <CircularLogoDark />}
    </a>
  );
}

Logo.propTypes = {
  light: PropTypes.bool,
  link: PropTypes.string
};

export default Logo;
