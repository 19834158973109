import React from 'react';
import PropTypes from 'prop-types';

function DealRoomBody({ children }) {
  return <div className="deal-room-body">{children}</div>;
}

DealRoomBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default DealRoomBody;
