import { Checkbox, Radio, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SesameInvite from 'src/components/sesame/SesameInvite';
import { useUser } from 'src/utils/authentication';
import { isConcierge, isDealPitch } from 'src/utils/permissions';
import { ConciergeContext } from './ConciergeContext';

import './ConciergeToolbar.less';

export default function ConciergeToolbar({ magicLinkPermissions }) {
  const { data: user } = useUser();
  const { values, patchValues } = useContext(ConciergeContext);

  if (!isConcierge(user) || isDealPitch(user)) return null;

  return (
    <div id="concierge-toolbar">
      <div>
        <Typography.Text strong>Concierge Toolbar</Typography.Text>
      </div>

      <Space direction="vertical" size="large">
        <SesameInvite permissions={magicLinkPermissions} />

        <Checkbox
          onChange={(e) => patchValues(['viewAsGuest'], e.target.checked)}
        >
          View as guest
        </Checkbox>

        <Typography.Text strong>Suppliers</Typography.Text>
        <Radio.Group
          value={values?.supplier?.logos || ''}
          onChange={(e) => patchValues(['supplier', 'logos'], e.target.value)}
        >
          <Space direction="vertical">
            <Radio value="show">Show logos </Radio>
            <Radio value="blur">Blur logos </Radio>
            <Radio value="anonymize">Anonymize logos </Radio>
          </Space>
        </Radio.Group>
        <Checkbox
          onChange={(e) =>
            patchValues(
              ['supplier', 'names'],
              e.target.checked ? 'anonymize' : 'show'
            )
          }
        >
          Anonymize names
        </Checkbox>

        <Typography.Text strong>Buyers</Typography.Text>
        <Radio.Group
          value={values?.buyer?.logos || ''}
          onChange={(e) => patchValues(['buyer', 'logos'], e.target.value)}
        >
          <Space direction="vertical">
            <Radio value="show">Show logos </Radio>
            <Radio value="blur">Blur logos </Radio>
            <Radio value="anonymize">Anonymize logos </Radio>
          </Space>
        </Radio.Group>
        <Checkbox
          onChange={(e) =>
            patchValues(
              ['buyer', 'names'],
              e.target.checked ? 'anonymize' : 'show'
            )
          }
        >
          Anonymize names
        </Checkbox>
      </Space>
    </div>
  );
}

ConciergeToolbar.propTypes = {
  magicLinkPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      permission: PropTypes.string
    })
  )
};
