import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconUp } from './svg/caret-up.svg';
import { ReactComponent as IconDown } from './svg/caret-down.svg';
import SvgIconComponent from './SvgIconComponent';

function CaretUpDownIcon({
  color,
  down = false,
  height = 24,
  width = 24,
  ...props
}) {
  const computedStyles = {
    height: typeof height === 'number' ? `${height}px` : height,
    width: typeof width === 'number' ? `${width}px` : width
  };
  return (
    <SvgIconComponent style={{ color }} {...props}>
      {down ? <IconDown {...computedStyles} /> : <IconUp {...computedStyles} />}
    </SvgIconComponent>
  );
}

CaretUpDownIcon.propTypes = {
  down: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CaretUpDownIcon;
