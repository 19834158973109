import React from 'react';
import { Typography, Tooltip, Skeleton } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import { getRfqProject } from 'src/Query';
import pSBC from 'src/utils/pSBC';

import 'src/pages/concierge/styles/Funnel.less';

const STAGES_DISPLAY_NAME_MAP = {
  matched: 'Search',
  contacted: 'Qualified',
  engaged: 'Engaged',
  screened: 'Prioritized',
  approved: 'Approved'
};

export default function ProjectFunnel({ rfq }) {
  // const funnel = rfq?.extra?.funnel || [];

  const {
    data: rfqProject,
    error,
    isLoading
  } = useQuery({
    queryKey: ['project', rfq.uuid],
    enabled: !!rfq.uuid,
    queryFn: () => getRfqProject(rfq.uuid)
  });

  if (isLoading) return <Skeleton />;

  const { funnel } = rfqProject.project;

  // We're temporarily hiding the first step of the funnel
  delete funnel.matched;

  const maxCount = funnel
    ? Math.max(...Object.values(funnel).map(({ count }) => count))
    : 0;

  const minCount = funnel
    ? Math.min(
        ...Object.values(funnel)
          .map(({ count }) => count)
          .filter((count) => count > 0)
      )
    : 0;

  return (
    <div className="funnel">
      <div
        className="funnel--grid"
        style={{
          gridTemplateColumns: `repeat(${Object.keys(funnel).length}, 1fr)`
        }}
      >
        {maxCount > 0 ? (
          Object.keys(funnel).map((stageKey, i) => {
            const stage = funnel[stageKey];
            const nextStage = funnel[Object.keys(funnel)[i + 1]] || stage;
            const countDiff = Math.abs(
              stage.count - (nextStage.count || stage.count)
            );

            const isEmpty = stage.count === 0;

            return (
              <React.Fragment key={stageKey}>
                <div
                  className="funnel--item--tag"
                  style={{
                    color: pSBC(-0.4, stage.color),
                    backgroundColor: pSBC(0.82, stage.color)
                  }}
                >
                  {stage.count}
                </div>
                <div className="funnel--item--label">
                  {STAGES_DISPLAY_NAME_MAP[stageKey]}
                  {stage.details && (
                    <Tooltip title={stage.details}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                </div>
                <div className="funnel--item">
                  <div className="funnel--item--bg--wrap">
                    <div
                      className="funnel--item--bg"
                      style={{
                        background: isEmpty ? 'transparent' : stage.color,
                        borderTop: isEmpty ? '1px dashed #323640' : '',
                        height: `${isEmpty ? 'calc(' : ''}${
                          ((isEmpty ? minCount : stage.count) / maxCount) * 100
                        }%${isEmpty ? ' - 1px)' : ''}`,
                        clipPath: `polygon(0 0, 100% ${
                          (countDiff / stage.count) * 100
                        }%, 100% 100%, 0 100%)`
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <Typography.Text>No existing funnel for this RFQ.</Typography.Text>
        )}
      </div>
    </div>
  );
}
ProjectFunnel.propTypes = {
  rfq: PropTypes.object
};
