import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, Input, Typography, message } from 'antd';
import LocationInput, {
  parseInternalLocation
} from 'src/components/form/LocationInput';
import { getDeal } from 'src/Query';
import { patchDealTestLab } from 'src/Mutation';
import RadioComponent from 'src/components/form/RadioComponent';
import Calendar from 'src/components/icons/Calendar';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import FormLifecycle from 'src/components/form/FormLifecycle';

function Location({ type, location }) {
  return (
    <div className="location-display">
      {location.city}, {location.state}, {location.country}
    </div>
  );
}
Location.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string
};

function TestLabCreate({ onFinish, onCancel }) {
  const [form] = Form.useForm();
  return (
    <FormLifecycle
      className="test-lab-create"
      actionLabel="Confirm"
      onCancel={onCancel}
      form={form}
      onFinish={(values) => {
        onFinish(values);
      }}
    >
      <h4>Add your preferred testing lab</h4>
      <Form.Item label="Lab Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Lab Address" name="address">
        <LocationInput />
      </Form.Item>
    </FormLifecycle>
  );
}
TestLabCreate.propTypes = {
  onFinish: PropTypes.func,
  onCancel: PropTypes.func
};

export function TestLabCard({ lab, index, onRemove, report }) {
  const tat = lab.turn_around_days_min
    ? lab.turn_around_days_max
      ? `${prettyNumberRound(lab.turn_around_days_min)} - ${prettyNumberRound(
          lab.turn_around_days_max
        )}`
      : prettyNumberRound(lab.turn_around_days_min)
    : undefined;

  const location = lab.location || lab.company.locations[0];
  return (
    <div className="test-lab-card--content">
      {lab.company.avatar && <img src={lab.company.avatar} alt="avatar" />}
      <div className="test-lab-card--details">
        <h4>{lab.company.name}</h4>
        {location && <Location type="city" location={location} />}
        {tat && (
          <div className="turn-around">
            <Calendar />
            &nbsp;{tat}
            &nbsp;business days
          </div>
        )}
        {report && (
          <a target="_blank" href={report} title="Test Report PDF">
            <Button htmlType="button" style={{ marginTop: 16 }}>
              <Typography.Text strong>DOWNLOAD FULL REPORT</Typography.Text>
            </Button>
          </a>
        )}
        {onRemove && (
          <Button
            type="link"
            onClick={(event) => {
              onRemove(event);
              event.stopPropagation();
            }}
          >
            Remove Lab
          </Button>
        )}
      </div>
      <RadioComponent.Indicator index={index} />
    </div>
  );
}
TestLabCard.propTypes = {
  lab: PropTypes.object,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  report: PropTypes.string
};

export function TestLabSelect({ dealId }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [creating, setCreating] = useState(false);
  const [newLab, setNewLab] = useState();
  const [preferredLab, setPrefferedLab] = useState();
  const [selectedLab, setSelectedLab] = useState();
  const {
    data: deal,
    error,
    isLoading
  } = useQuery({ queryKey: ['deal', dealId], queryFn: () => getDeal(dealId) });
  useEffect(() => {
    const labPreferred = deal?.test_labs?.find((o) => o.preferred);
    if (labPreferred) {
      setPrefferedLab(labPreferred);
    } else {
      setPrefferedLab();
    }
    const labSelected = deal?.test_labs?.find((o) => o.selected);
    if (labSelected) {
      setSelectedLab(labSelected);
    } else {
      setSelectedLab();
    }
  }, [deal]);
  const { mutate, isMutating } = useMutation({
    mutationFn: patchDealTestLab,
    onSuccess: (data, variables, context) => {
      setSelectedLab(data);
    },
    onError: (e) => {
      messageApi.error(
        `Error adding the new lab. Please try again or contact us. [${
          e.message || e
        }]`
      );
    }
  });
  const [form] = Form.useForm();

  const testLabs = deal?.test_labs || [];
  const preferredLabObject = useMemo(
    () =>
      preferredLab ||
      (newLab && {
        company: {
          name: newLab.name
        },
        location: newLab.address
      }) ||
      undefined,
    [newLab, preferredLab]
  );
  const onFinish = (values) => {
    if (values.testLab === -1) {
      mutate({
        uuid: dealId,
        company: { name: newLab.name },
        location: parseInternalLocation(newLab.address),
        selected: true,
        preferred: true
      });
    } else {
      mutate({
        uuid: dealId,
        selected_lab: testLabs[values.testLab].uuid
      });
    }
  };
  if (selectedLab) return <TestLabSelected lab={selectedLab} />;
  return (
    <div className="test-lab-select">
      {contextHolder}
      <h2>Select a Testing Lab</h2>
      <p>
        Please confirm your preference for which lab performs the lab tests. For
        efficiency, we have provided a recommendation in proximity of the
        supplier.
      </p>
      {!creating && (
        <Button type="link" onClick={() => setCreating(true)}>
          Do you have a preferred testing lab?
        </Button>
      )}
      {creating && (
        <TestLabCreate
          onCancel={() => setCreating(false)}
          onFinish={(values) => {
            setNewLab(values);
            setCreating(false);
            form.setFieldsValue({ testLab: -1 });
          }}
        />
      )}
      <FormLifecycle
        actionLabel="Select lab"
        actionDescription="select a testing lab"
        initialData={{ testLab: 0 }}
        onFinish={onFinish}
        isLoading={isLoading || isMutating}
        form={form}
      >
        <Form.Item name="testLab">
          <RadioComponent.Group>
            {preferredLabObject && (
              <RadioComponent index={-1} className="test-lab-card">
                <TestLabCard
                  lab={preferredLabObject}
                  index={-1}
                  onRemove={() => {
                    setNewLab();
                    setPrefferedLab();
                    form.setFieldsValue({ testLab: 0 });
                  }}
                />
              </RadioComponent>
            )}
            <h4>Recommended Testing Labs</h4>
            <span>Based on proximity to the supplier</span>
            {testLabs
              .filter((o) => !o.preferred)
              .map((o, i) => (
                <RadioComponent
                  key={o.uuid}
                  index={i}
                  className="test-lab-card"
                >
                  <TestLabCard lab={o} index={i} />
                </RadioComponent>
              ))}
          </RadioComponent.Group>
        </Form.Item>
      </FormLifecycle>
    </div>
  );
}

TestLabSelect.propTypes = {
  dealId: PropTypes.string
};

function TestLabSelected({ lab }) {
  return (
    <div className="test-lab-select">
      <h2>Testing Lab Selected</h2>
      <p>
        Thank you for selecting a testing lab. We will notify you once you
        samples have been shipped.
      </p>
      <TestLabCard lab={lab} />
    </div>
  );
}

TestLabSelected.propTypes = {
  lab: PropTypes.object
};

function TestLab({ testLabs }) {
  const selected = testLabs.find((o) => o.selected);
  return (
    <div className="test-lab-page">
      {(selected && <TestLabSelected lab={selected} />) || (
        <TestLabSelect testLabs={testLabs} />
      )}
    </div>
  );
}

TestLab.propTypes = {
  testLabs: PropTypes.array
};

export function DealTestPage() {
  const { rfqId, dealId } = useParams();
  return (
    <div className="test-lab-page">
      <TestLabSelect index={0} dealId={dealId} />
    </div>
  );
}

export default TestLab;
