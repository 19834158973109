import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyOutlined, UserAddOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal, Space, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useUser } from 'src/utils/authentication';
import { createSesameLink } from 'src/Mutation';
import ObjectInput from 'src/components/form/ObjectInput';
import { isConcierge } from 'src/utils/permissions';

const { useForm } = Form;

export default function SesameInvite({ permissions }) {
  const { data: user } = useUser();
  const userIsConcierge = isConcierge(user);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [link, setLink] = useState();
  const [form] = useForm();
  // const n = useNotification
  const [notificationApi, contextHolder] = notification.useNotification();
  const location = useLocation();
  const { mutate, isMutating } = useMutation({
    mutationFn: createSesameLink,
    onSuccess: (response) => {
      const redirect = encodeURIComponent(
        `${location.pathname}${location.search}`
      );
      setLink(
        `${window.origin}/sesame/login${response.query_string}&next=${redirect}`
      );
    },
    onError: (e) => {
      setError('Error creating the invitation link');
    }
  });

  const createLink = ({ name, email, company }) => {
    setLink();
    setError();
    mutate({
      name,
      email,
      company_uuid: company.value,
      permissions
    });
    setOpen(false);
  };

  if (userIsConcierge) {
    return (
      <div className="sesame-invite">
        {contextHolder}
        {link && (
          <Alert
            message="Invitation link"
            description={link}
            type="info"
            closable
            showIcon
            action={
              <Button
                size="small"
                type="default"
                onClick={() => {
                  global.navigator.clipboard.writeText(link);
                }}
              >
                <CopyOutlined />
              </Button>
            }
          />
        )}
        {error && <Alert message={error} type="error" closable showIcon />}
        <Modal
          className="sesame-invite-modal"
          width={600}
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={createLink}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Company Email"
              name="email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Company"
              name="company"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <ObjectInput appName="api" modelName="company" />
            </Form.Item>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit">
                Create link
              </Button>
              <Button type="default" onClick={() => form.resetFields()}>
                Clear
              </Button>
              <Button type="default" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Space>
          </Form>
        </Modal>
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
          loading={isMutating}
        >
          <UserAddOutlined />
          &nbsp;Invite
        </Button>
      </div>
    );
  }
  return undefined;
}

SesameInvite.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      permission: PropTypes.string
    })
  )
};
