import React from 'react';
import PropTypes from 'prop-types';

export const locationRender = (loc) =>
  `${String(loc.state) ? `${loc.state}, ` : ''}${loc.country}`;

export default function SupplyRoute({ origin, destination }) {
  return (
    <>
      {origin ? locationRender(origin) : '(Unknown)'} ➝{' '}
      {destination ? locationRender(destination) : '(Unknown)'}
    </>
  );
}
SupplyRoute.propTypes = {
  origin: PropTypes.object,
  destination: PropTypes.object
};
