import { Dropdown, Space } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ThunderboltIcon from 'src/components/icons/Thunderbolt';
import useSesameInvite from 'src/components/sesame/useSesameInvite';
import useIsConcierge from 'src/hooks/useIsConcierge';

function AdminMenu() {
  const concierge = useIsConcierge();
  const navigate = useNavigate();

  const {
    openModal: openSesameInviteModal,
    ModalComponent: SesameInviteModal
  } = useSesameInvite();

  const items = [
    {
      key: 'sesameInvite',
      label: 'Magic Link Generator',
      onClick: openSesameInviteModal
    },
    {
      key: 'proposalBuilder',
      label: <Link to="/concierge/proposal-builder">Proposal Builder</Link>,
      onClick: () => {
        navigate('/concierge/proposal-builder');
      }
    },
    {
      key: 'marketsPlayground',
      label: <Link to="/concierge/markets-playground">Markets Playground</Link>,
      onClick: () => {
        navigate('/concierge/markets-playground');
      }
    },
    {
      key: 'changeLog',
      label: 'Change Log',
      onClick: () => {
        window
          .open(
            'https://devops.circular.co/changelog/circular-exchange/circular-react/main',
            '_blank'
          )
          .focus();
      }
    },
    {
      key: 'version',
      label: process.env.REACT_APP_GITHUB_SHA || 'v'
    }
  ];
  const onClick = ({ key }) => {
    const item = items.find((i) => i.key === key);
    if (item?.onClick) item.onClick();
  };
  if (!concierge) return undefined;
  return (
    <>
      <Dropdown menu={{ items, onClick }}>
        <Space>
          <ThunderboltIcon />
        </Space>
      </Dropdown>
      {SesameInviteModal}
    </>
  );
}

export default AdminMenu;
