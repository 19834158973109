import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { PrinterFilled } from '@ant-design/icons';
import ian from 'src/pages/landing/images/ian.png';
import shannon from 'src/pages/landing/images/shannon.png';
import james from 'src/pages/landing/images/james.png';
import ryan from 'src/pages/landing/images/ryan.png';
import mariah from 'src/pages/landing/images/mariah.png';
import gilbert from 'src/pages/landing/images/gilbert.png';
import linda from 'src/pages/landing/images/linda.png';
import aidan from 'src/pages/landing/images/aidan.png';
import adam from 'src/pages/landing/images/adam.png';

import './MeetTheTeam.less';

const leadershipTeam = [
  {
    imgUrl: ian,
    name: 'Ian Arthurs',
    title: 'Founder',
    description: 'Google, Airbnb, TaskRabbit, Medium, Eclipse Ventures'
  },
  {
    imgUrl: shannon,
    name: 'Shannon Gordon',
    title: 'Growth',
    description: 'McKinsey, Walmart, theBoardlist'
  },
  {
    imgUrl: james,
    name: 'James Nicolson',
    title: 'Engineering',
    description: 'IOUze, Standard Bank Group'
  },
  {
    imgUrl: ryan,
    name: 'Ryan Gibbs',
    title: 'Product Management',
    description: 'Bloomberg, NuArx / VikingCloud'
  },
  {
    imgUrl: mariah,
    name: 'Mariah Pinckney',
    title: 'Customer Success',
    description: 'Attune Insurance'
  },
  {
    imgUrl: gilbert,
    name: 'Gilbert Polassi',
    title: 'Business Development',
    description: 'Excel Plastic Materials'
  },
  {
    imgUrl: linda,
    name: 'Linda Rigano',
    title: 'Communications',
    description: 'ThomasNet, Creston, Rigano Group'
  },
  {
    imgUrl: aidan,
    name: 'Aidan Madigan-Curtis',
    title: 'Investor, Board Member',
    description: 'Eclipse Ventures, Samsara, Apple'
  },
  {
    imgUrl: adam,
    name: 'Adam Lowry',
    title: 'Investor, Board Member',
    description: 'Method, Ripple'
  }
];

export default function MeetTheTeam() {
  return (
    <div id="meet-the-team">
      <div className="meet-the-team-container">
        <Row justify="center">
          <Col>
            <h2>Leadership Team</h2>
          </Col>
        </Row>
        <Row justify="center">
          {leadershipTeam.map((el) => (
            <Col xs={24} sm={12} lg={8} key={el.name}>
              <LeadershipTile profile={el} />
            </Col>
          ))}
        </Row>
      </div>

      <div />
    </div>
  );
}

function LeadershipTile({ profile }) {
  return (
    <div className="team-member">
      <div>
        <img src={profile.imgUrl} alt={PrinterFilled.name} />
      </div>
      <div>
        <h6>{profile.name}</h6>
        <div className="member-role">{profile.title}</div>
        <div className="member-experience">{profile.description}</div>
      </div>
    </div>
  );
}

LeadershipTile.propTypes = {
  profile: PropTypes.object
};
