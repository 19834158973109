import numericRange from 'src/components/utils/numericRange';
import 'src/pages/concierge/Concierge.less';

const findMnpValue = (mnpId, mnpValues) =>
  mnpValues?.find(({ property }) => property.uuid === mnpId);

const numericalPropertyColumn = (p) => ({
  title: p.code,
  width: 120,
  dataIndex: 'material_numerical_properties',
  key: p.uuid,
  render: (allTdsMnpValues) => {
    const thisMnp = findMnpValue(p.uuid, allTdsMnpValues);
    return numericRange({
      min: thisMnp?.min,
      max: thisMnp?.max,
      empty: '',
      noMinRender: (v) => `${v} or -`,
      noMaxRender: (v) => `${v} or +`
    });
  }
});

export default numericalPropertyColumn;
