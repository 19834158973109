import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Header from './Header';

import './FullScreenModal.less';

export default function FullScreenModal({
  children,
  title,
  footer,
  onBack,
  width,
  className,
  headerProps,
  stickyHeader,
  ...props
}) {
  const navigate = useNavigate();
  const headerStyle = stickyHeader
    ? {
        position: 'sticky',
        top: '0px',
        zIndex: 2000
      }
    : {};
  return (
    <div className={`full-screen-modal ${className || ''}`} {...props}>
      <div className="full-screen-modal--header" style={headerStyle}>
        <Header
          left={
            <>
              <button
                type="button"
                onClick={() => (onBack ? onBack() : navigate(-1))}
                className="bare"
              >
                <ArrowLeftOutlined />
              </button>{' '}
              <span className="full-screen-modal--header--title">{title}</span>
            </>
          }
          {...(headerProps || {})}
        />
      </div>
      <div
        className="full-screen-modal--body"
        style={{ width: width || 'auto' }}
      >
        {children}
      </div>
      <div className="full-screen-modal--footer">{footer}</div>
    </div>
  );
}

FullScreenModal.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  onBack: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  headerProps: PropTypes.object,
  stickyHeader: PropTypes.bool,
  title: PropTypes.node
};
