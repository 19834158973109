import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getEvent } from 'src/Query';

export default function useCalendlyEvent(eventId) {
  const {
    data: event,
    isLoading,
    isError
  } = useQuery(['event', eventId], () => getEvent(eventId), {
    enabled: !!eventId
  });
  const start = event && dayjs(event.start_time);
  const end = event && dayjs(event.end_time);
  const dateTime =
    event &&
    `${start.format('h:mma')} - ${end.format('h:mma')} on ${end.format(
      'dddd, MMMM Do, YYYY'
    )}`;
  const date = event && dayjs(event.start_time).format('dddd, MMMM Do');
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const minutes = event ? end.diff(start, 'minute') : 0;
  return {
    event,
    start,
    end,
    isLoading,
    isError,
    dateTime,
    timezone,
    date,
    minutes
  };
}
