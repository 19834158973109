import React from 'react';
import PropTypes from 'prop-types';

import Stars from 'src/components/utils/Stars';

export default function StarsCell({ deal, title }) {
  const score = deal.scores.find((s) => s.title === title);
  return (
    <div className="stars-container">
      <Stars percent={Number(score?.score || 0) / 5} />
    </div>
  );
}

StarsCell.propTypes = {
  deal: PropTypes.object,
  title: PropTypes.string
};
