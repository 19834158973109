import React from 'react';
import PropTypes from 'prop-types';

function DealRoomHeader({ children }) {
  return <div className="deal-room-header">{children}</div>;
}

DealRoomHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default DealRoomHeader;
