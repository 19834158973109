import React from 'react';
import { Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import LandingHeader from 'src/pages/landing/components/LandingHeader';
import LandingFooter from 'src/pages/landing/components/LandingFooter';

function Privacy() {
  const { Text, Title, Paragraph } = Typography;

  return (
    <>
      <LandingHeader atTopBreakpoint={-1} />
      <div className="privacy-policy legal-section">
        <div style={{ textAlign: 'center' }}>
          <Title level={5}>CIRCULAR EXCHANGE, INC.</Title>
          <Title underline level={5}>
            Privacy Policy
          </Title>
        </div>
        <Paragraph>Effective: May 11, 2022</Paragraph>
        <Paragraph>
          This Privacy Policy (the <Text strong>“Privacy Policy”</Text>) serves
          to inform you of our policies and procedures regarding the collection,
          use and disclosure of the information we receive when you access and
          use the website (the <Text strong>“Site”</Text>), services, software,
          mobile applications, content, and all other products and services (all
          of the foregoing collectively, the <Text strong>“Services”</Text>)
          owned, controlled or offered, directly or indirectly, by Circular
          Exchange, Inc. (together with its subsidiaries and affiliates,{' '}
          <Text strong>“Circular”, “we,” “our”</Text> or{' '}
          <Text strong>“us”</Text>
          ). Circular believes that the privacy of its users is paramount and
          strives to use Personal Information (as defined below) only in ways
          outlined in this Privacy Policy. The Privacy Policy is incorporated by
          reference into our Terms of Service, currently available at
          <Link to="/terms"> our terms of service page </Link> (the{' '}
          <Text strong>“Terms of Service”</Text>). Any terms used herein and not
          defined will have the meanings given to them in the Terms of Service.
          By using the Services, you hereby warrant and represent that you have
          read, understand and agree to this Privacy Policy and the Terms of
          Service, that you are a resident of the United States, and that you
          are over 18 years of age. PLEASE DO NOT USE, INSTALL OR ACCESS THE
          SERVICES IF YOU DO NOT AGREE TO THIS PRIVACY POLICY.
        </Paragraph>
        <Title strong level={5}>
          1.&emsp;&emsp;What We Mean by Personal Information
        </Title>
        <Paragraph>
          For purposes of this Privacy Policy,{' '}
          <Text strong>“Personal Information”</Text> means any information from
          or about a person that either identifies that person directly or that
          makes that person identifiable when it is combined with other
          information from or about that person from any source.
        </Paragraph>
        <Title strong level={5}>
          2.&emsp;&emsp;Information We Collect
        </Title>
        <Title strong italic level={5}>
          Information You Provide To Us
        </Title>
        <Paragraph>
          When you use the Services or otherwise communicate with us, we collect
          information that you provide to us directly. For example, we collect
          information in the following circumstances: you (i) use our mobile
          apps or visit the Site, (ii) register for the Services or create an
          account or profile or accept the creation of an account or profile on
          your behalf (an <Text strong>“Account”</Text>), (iii) access the
          Services through social networking sites or third party services, (iv)
          subscribe to newsletters; (v) purchase a product or service on the
          Site or in our mobile apps; (vi) invite your friends to join the
          Services, “share” the Services on social networking sites or perform
          other similar actions; (vii) request technical support; (viii)
          otherwise use our mobile apps or online Services where Personal
          Information is required for such use and/or participation, (ix) when
          you sign up for a newsletter, and (x) when you contact us via the Site
          or when you otherwise communicate with us. You can choose not to
          provide information that is requested of you by us; however, this may
          limit your ability to use or access the Services.
        </Paragraph>
        <Paragraph>
          The information you provide to us directly may include, without
          limitation, the following information that may, alone or in
          combination with other data, constitute Personal Information:
          <Tooltip title="The listed items are examples and should always reflect what is actually collected. Do not be over inclusive for the sake of throwing in the kitchen sink, which could be a violation of FTC Act Section 5 “deceptive acts or practices” and GDPR requirements.">
            <sup> 1</sup>
          </Tooltip>
        </Paragraph>
        <ul>
          <li>
            Information you provide in connection with completing a transaction
            or purchase using the Services (including submitting purchase
            orders, submitting specification sheets, or entering into
            Contracts), including your name, e-mail, physical address, phone
            number, photos of the Products, and any other information you decide
            to provide;
          </li>
          <li>
            Information you provide via email, through the “Contact” section on
            the Site or by using the contact details listed on various parts of
            the Site, including your name, company name, e-mail, phone number,
            inquiry, and any other information you decide to provide;
          </li>
          <li>
            Information you provide in order to subscribe to our newsletters and
            updates, including your email address, the topic for which you wish
            to receive updates, or any other information you decide to provide
            us with. You may always unsubscribe from these emails by following
            the instructions included;
          </li>
          <li>
            If you are one of our customers, suppliers or prospects, we may
            process limited Personal Information in the course of our business
            relation with you, for example when you place an order, request a
            demo or vice versa. Such Personal Information may include your name,
            company, title, e-mail address, telephone number, address, order
            details, and where applicable and relevant, credit registrations and
            credit limits;
          </li>
          <li>Transaction and limited (non-PCI) payment data; and</li>
          <li>
            Any other information you may want to share with us, such as
            Personal Information related to recruitment / job applications.
          </li>
        </ul>
        <Paragraph>
          Moreover, if you contact us, a record of such correspondence may be
          kept.
        </Paragraph>
        <Paragraph>
          Additionally, if another user refers you to us by providing us with
          your e-mail address, we may send you a message. However, we will use
          your e-mail address only to send such other user’s message unless you
          otherwise affirmatively provide your information to us.
        </Paragraph>
        <Title strong italic level={5}>
          Automatically Collected Information
        </Title>
        <Paragraph>
          Most of the data we collect in and through the Site and the Services
          is technical in nature and is collected and processed automatically
          through so-called application programming interfaces, software
          development kits, cookies and similar software-based technologies.
          Alone or in combination with other data, such automatically collected
          data may constitute Personal Information. The data we may collect by
          automated means may include, without limitation:
        </Paragraph>
        <ul>
          <li>
            Usage data: including, but not limited to, search terms entered and
            pages viewed.
          </li>
          <li>
            Information we collect on the use of the Site via cookies: please
            see the “How We Use Cookies and Other Technologies” section below
            for more information.
          </li>
        </ul>
        <Title strong italic level={5}>
          Information You Post on the Services
        </Title>
        <Paragraph>
          The Circular Platform may provide: chat tools to users and the ability
          to upload photos of Products. Circular has no obligation to keep
          private any information that you disclose to other users using these
          functions. You should exercise caution when deciding to disclose
          Personal Information through the use of these tools or functions.
        </Paragraph>
        <Title strong italic level={5}>
          Information You Share on Third Party Websites
        </Title>
        <Paragraph>
          The Services may include links to third party websites and social
          media services where you will be able to post comments, stories,
          reviews or other information. Your use of these third party websites
          and social media services may result in the collection or sharing of
          information about you by these third party websites and social media
          services. We encourage you to review the privacy policies and settings
          on the third party websites and social media services with which you
          interact to make sure you understand the information that may be
          collected, used, and shared by those third party websites and social
          media services.
        </Paragraph>
        <Title strong italic level={5}>
          How We Use Cookies and Other Technologies
        </Title>
        <Paragraph>
          Some of the features on the Site and the Services require the use of
          “cookies” - small text files that are stored on your device’s hard
          drive. We use cookies to measure which pages are being accessed, and
          which features are most frequently used. This enables us to
          continuously improve the Site to meet the needs of our visitors.
        </Paragraph>
        <Paragraph>
          The following sets out how we may use different categories of cookies
          and your options for managing cookie settings:
        </Paragraph>
        <table style={{ width: '100%', margin: '2em 0em' }}>
          <tr>
            <th
              style={{
                width: '20%'
              }}
            >
              <Text strong>Type of Cookies</Text>
            </th>
            <th
              style={{
                width: '50%'
              }}
            >
              <Text strong>Description</Text>
            </th>
            <th>
              <Text strong>Managing Settings</Text>
            </th>
          </tr>
          <tr>
            <td>
              <Text strong>Required Cookies</Text>
            </td>
            <td>
              <Paragraph>
                Required cookies enable you to navigate the Site and use their
                features, such as accessing secure areas of the Site and using
                the Services. If you have chosen to identify yourself to us, we
                use cookies containing encrypted information to allow us to
                uniquely identify you. These cookies allow us to uniquely
                identify you when you are logged into the Site and to process
                your online transactions and requests.
              </Paragraph>
            </td>
            <td>
              Because required cookies are essential to operate the Site, there
              is no option to opt out of these cookies.
            </td>
          </tr>
          <tr>
            <td>
              <Text strong>Performance cookies</Text>
            </td>
            <td>
              <Paragraph>
                These cookies collect information about how you use our Sites,
                including which pages you go to most often and if they receive
                error messages from certain pages. These cookies do not collect
                information that individually identifies you. Information is
                only used to improve how the Site function and perform. From
                time-to-time, we may engage third parties to track and analyze
                usage and volume statistical information relating to individuals
                who visit the Site. We may also utilize Flash cookies for these
                purposes.
              </Paragraph>
            </td>
            <td>
              <Paragraph>
                To learn how to opt out of performance cookies using your
                browser settings,{' '}
                <a href="src/pages/legal/Privacy">click here</a>. To learn how
                to manage privacy and storage settings for Flash cookies,{' '}
                <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                  click here
                </a>
                .
              </Paragraph>
            </td>
          </tr>
          <tr>
            <td>
              <Text strong>Functionality cookies</Text>
            </td>
            <td>
              <Paragraph>
                Functionality cookies allow our Sites to remember information
                you have entered or choices you make (such as your username,
                language, or your region) and provide enhanced, more personal
                features. These cookies also enable you to optimize your use of
                the Site after logging in. These cookies can also be used to
                remember changes you have made to text size, fonts and other
                parts of web pages that you can customize. We may use local
                shared objects, also known as Flash cookies, to store your
                preferences or display content based upon what you view on the
                Site to personalize your visit.
              </Paragraph>
            </td>
            <td>
              <Paragraph>
                To learn how to opt out of functionality cookies using your
                browser settings,{' '}
                <a href="src/pages/legal/Privacy">click here</a>. To learn how
                to manage privacy and storage settings for Flash cookies,{' '}
                <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                  click here
                </a>
                .
              </Paragraph>
            </td>
          </tr>
        </table>
        <Paragraph>
          We and our service providers may also use “pixel tags,” “web beacons,”
          “clear GIFs,” or similar means in connection with the Services and
          HTML-formatted email messages to, among other things, track the
          actions of users, to determine the success of marketing campaigns and
          to compile aggregate statistics about Site usage and response rates.
        </Paragraph>
        <Title strong level={5}>
          3.&emsp;&emsp;Use of Collected Information
        </Title>
        <Paragraph>
          We use the information you provide to us for the following purposes:
          (i) to further our legitimate interests in providing the Services,
          including without limitation to collect and remit payment for paid
          features, (ii) to administer your use of the Services and any Accounts
          you may have with us, (iii) to personalize your experience, (iv) to
          provide to you Service announcements or inform you of new releases and
          features, (v) to provide you with further information and offers from
          us or third parties that we believe you may find useful or
          interesting, such as newsletters, marketing or promotional materials,
          (vi) to perform tasks on behalf of and according to instructions of a
          third party, such as payment processors, third party-service providers
          or our advertising partners, (vii) to enforce our Terms of Service,
          (viii) to resolve any disputes between users of the Site or between
          such users and us, (ix) comply with a legal requirement or process,
          including, but not limited to, civil and criminal subpoenas, court
          orders or other compulsory disclosures; (x) to further our legitimate
          interest in protecting our rights, property, or safety and the rights,
          property and safety of the Services, our users or the public, and (xi)
          to contact you to obtain feedback from you regarding the Site and the
          Services.
        </Paragraph>
        <Paragraph>
          In addition to the purposes described above, we use the information
          collected automatically to (i) to further our legitimate interests in
          monitoring and analyzing the use of the Services and for the technical
          administration of the Site, (ii) improve the Site and the Services,
          (iii) generate and derive useful data and information concerning the
          interests, characteristics and website use behavior of our users, and
          (iv) verify that users of the Services meet the criteria required to
          process their requests.
        </Paragraph>
        <Title strong level={5}>
          4.&emsp;&emsp;Third Parties We Share Personal Information With
        </Title>
        <Paragraph>
          We may disclose Personal Information you provide to us or that we
          collect automatically on the Site and in and through the Services with
          the following categories of third parties:
        </Paragraph>
        <ul>
          <li>
            Shipping and logistics companies necessary to facilitate the
            delivery of Products;
          </li>
          <li>
            Service providers, such as payment processors, web hosting and data
            storage service providers, and helping us deliver and develop the
            Services. data storage service providers, marketing service
            providers, and communications service providers (e.g. Stripe);
          </li>
          <li>
            Public authorities, such as law enforcement, if we are legally
            required to do so or if we need to protect our rights or the rights
            of third parties; and
          </li>
          <li>
            Our subsidiaries and affiliates; or a subsequent owner, co-owner or
            operator of the Site and/or the Services and their advisors in
            connection with a corporate merger, consolidation, restructuring,
            the sale of substantially all of our stock and/or assets, or in
            connection with bankruptcy proceedings, or other corporate
            reorganization, in accordance with this Privacy Policy.
          </li>
          <li>Other Users in connection with the provision of the Services.</li>
        </ul>
        <Title strong level={5}>
          5.&emsp;&emsp;Links to Other Websites
        </Title>
        <Paragraph>
          We frequently make content or services from other websites available
          to you from links located on the Site. We may present links in a
          format that enables us to keep track of whether these links have been
          followed. This Privacy Policy applies only to the Site and the
          Services. We do not exercise control over third party services or
          other websites that provide information or links from within the Site
          or the Services. Your interactions with these third party services are
          governed by the privacy policy of the company providing it. These
          other sites and services may place their own cookies or other files on
          your computer’s browser, collect data or solicit Personal Information
          from you. Other websites and services follow different rules regarding
          the use or disclosure of the Personal Information that you submit, and
          the collection and use of such information and access of any third
          party websites are subject to such third party’s privacy policy. We
          encourage you to read the privacy policies and other terms of such
          third parties before using their services.
        </Paragraph>
        <Title strong level={5}>
          6.&emsp;&emsp;Security
        </Title>
        <Paragraph>
          We understand the importance of privacy and security of Personal
          Information to our users and have made them a priority. Circular uses
          a variety of industry-standard security technologies and procedures to
          help protect Personal Information about you from unauthorized access,
          use, or disclosure and trains Circular employees on privacy and
          security issues. However, we cannot guarantee that unauthorized third
          parties will never be able to overcome those measures or use your
          Personal Information for improper purposes, and we do not promise that
          Personal Information about you or private communications will be
          protected from unauthorized disclosure or use.
        </Paragraph>
        <Title strong level={5}>
          7.&emsp;&emsp;Do Not Track Signals and Similar Mechanisms
        </Title>
        <Paragraph>
          Circular does not track its customers over time and across third party
          websites to provide targeted advertising and therefore does not
          respond to Do Not Track (DNT) signals. However, some third party sites
          do keep track of your browsing activities when they serve you content,
          which enables them to tailor what they present to you.
        </Paragraph>
        <Title strong level={5}>
          8.&emsp;&emsp;Managing Your Privacy
        </Title>
        <Paragraph>
          We keep your data on your behalf and for your benefit. You can correct
          or terminate and delete your Account information by following the
          instructions on the Services or by e-mail at support@circular.co,
          which you may also access for further assistance and questions
          regarding the Privacy Policy or for a copy of your Account data. If
          you cancel your Account or request us to delete your information,
          Circular will limit its access to Personal Information to perform what
          is requested by you and will delete information accessible to Circular
          within seven business days.
        </Paragraph>
        <Title strong level={5}>
          9.&emsp;&emsp;California Residents
        </Title>
        <Paragraph>
          California Civil Code Section 1798.83 requires certain businesses that
          share customer Personal Information with third parties for the third
          parties’ direct marketing purposes to respond to requests from
          California customers asking about the businesses’ practices related to
          such information-sharing. We currently do not share or disclose your
          Personal Information to third parties for the third parties’ direct
          marketing purposes. If we change our practices in the future, we will
          implement an opt-out policy as required under California laws.
        </Paragraph>
        <Paragraph>
          Furthermore, subject to certain exemptions, California residents have
          the following rights with respect to Personal Information we may have
          collected about them:
        </Paragraph>
        <Title strong italic level={5}>
          Requests to Know
        </Title>
        <Paragraph>You have the right to request that we disclose:</Paragraph>
        <ul>
          <li>
            The categories of Personal Information we have collected about you;
          </li>
          <li>
            The categories of Personal Information about you we have sold or
            disclosed for a business purpose;
          </li>
          <li>
            The categories of sources from which we have collected Personal
            Information about you;
          </li>
          <li>
            The business or commercial purposes for selling or collecting
            Personal Information about you;
          </li>
          <li>
            The categories of Personal Information sold or shared, if any, about
            you, as well as the categories of third parties to whom the Personal
            Information was sold, by category of Personal Information for each
            party to whom Personal Information was sold; and
          </li>
          <li>The specific pieces of Personal Information collected.</li>
        </ul>
        <Paragraph>
          You may submit a request to know via{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>. The
          delivery of our response may take place electronically or by mail. We
          are not required to respond to requests to know more than twice in a
          12-month period.
        </Paragraph>
        <Paragraph>
          We do not sell, and have not in the prior 12 months sold, Personal
          Information about California residents. Therefore, we have not
          included a “Do Not Sell My Personal Info” link on our Site. If our
          practices change, we will update this Privacy Policy and take any
          other necessary action to comply with applicable law. We do, however,
          disclose Personal Information for business purposes as described in
          the “Third Parties We Share Personal Information With” section above.
        </Paragraph>
        <Title strong italic level={5}>
          Requests to Delete
        </Title>
        <Paragraph>
          You have the right to request that we delete any Personal Information
          about you that we have collected. Upon receiving a verified request to
          delete Personal Information, we will do so unless otherwise required
          or authorized by law. You may submit a request to delete Personal
          Information via email at{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>.
        </Paragraph>
        <Title strong italic level={5}>
          Authorized Agents
        </Title>
        <Paragraph>
          You may designate an authorized agent to make requests on your behalf.
          You must provide an authorized agent written permission to submit a
          request on your behalf, and we may require that you verify your
          identity directly with us. Alternatively, an authorized agent that has
          been provided power of attorney pursuant to Probate Code sections
          4000-4465 may submit a request on your behalf.
        </Paragraph>
        <Title strong italic level={5}>
          Methods for Submitting Consumer Requests and Our Response to Requests
        </Title>
        <Paragraph>
          You may submit a request for access and requests to delete Personal
          Information about you via email at{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>.
        </Paragraph>
        <Paragraph>
          Upon receipt of a request, we may ask you for additional information
          to verify your identity. Any additional information you provide will
          be used only to verify your identity and not for any other purpose.
        </Paragraph>
        <Paragraph>
          We will acknowledge the receipt of your request within 10 days of
          receipt. Subject to our ability to verify your identity, we will
          respond to your request within 45 days of receipt. If we require more
          time (up to 90 days), we will inform you of the reason and extension
          period in writing. In order to protect your privacy and the security
          of Personal Information about you, we verify your identity by matching
          the identifying information provided by you to the personal
          information already maintained by us, or, in cases where your request
          requires a higher degree of certainty, we may use a third-party
          identity verification service or ask for a photo ID or for more
          information.
        </Paragraph>
        <Paragraph>
          Any disclosures we provide will only cover the 12-month period
          preceding the verifiable consumer request&apos;s receipt. The response
          we provide will also explain the reasons we cannot comply with a
          request, if applicable.
        </Paragraph>
        <Paragraph>
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </Paragraph>
        <Title strong italic level={5}>
          The Right to Non-Discrimination
        </Title>
        <Paragraph>
          You have the right not to be discriminated against for the exercise of
          your California privacy rights described above. Unless permitted by
          the CCPA, we will not:
        </Paragraph>
        <ul>
          <li>Deny you goods or services;</li>
          <li>
            Charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties;
          </li>
          <li>
            Provide you a different level or quality of goods or services; or
          </li>
          <li>
            Suggest that you may receive a different price or rate for goods or
            services or a different level or quality of goods or services.
          </li>
        </ul>
        <Title strong level={5}>
          10.&emsp;&emsp;International Transfers of Personal Information
        </Title>
        <Paragraph>
          Circular is based in the United States. As a result, Personal
          Information that we collect in and through the Services and on the
          Site may be transferred to our U.S. offices. In addition, we may work
          with third-party service providers in the U.S. and in other countries
          to support our business activities. Thus, Personal Information may be
          transferred to, stored on servers in, and accessed from the United
          States and countries other than the country in which the Personal
          Information was initially collected. In all such instances, we use,
          transfer, and disclose Personal Information solely for the purposes
          described in this Privacy Notice and in compliance with applicable
          laws.
        </Paragraph>
        <Title strong level={5}>
          11.&emsp;&emsp;Data Retention
        </Title>
        <Paragraph>
          We keep Personal Information related to your Account for as long as it
          is needed to fulfill the purposes for which it was collected, to
          provide our services, to deal with possible legal claims, to comply
          with our business interests and/or to abide by all applicable laws.
          Thereafter, we either delete Personal Information about you or
          de-identify it. Please note that even if you request the deletion of
          Personal Information about you, we may be required (by law or
          otherwise) to retain the Personal Information and not delete it.
          However, once those requirements are removed, we will delete Personal
          Information about you in accordance with your request.
        </Paragraph>
        <Title strong level={5}>
          12.&emsp;&emsp;Changes to the Privacy Policy
        </Title>
        <Paragraph>
          Our security and Privacy Policy are periodically reviewed and enhanced
          as necessary. This Privacy Policy might change as we update and expand
          the Services. You can tell when this Privacy Policy was last updated
          by reviewing the Last Updated-legend on top of this page. We will
          endeavor to notify you of these changes by email, but will not be
          liable for any failure to do so. We also encourage you to review this
          Privacy Policy periodically. If you do not understand any of the terms
          or conditions of any of our policies, you may inquire regarding the
          same via email at
          <a href="mailto:support@circular.co"> support@circular.co</a>. Your
          continued use of the Services after any change in this Privacy Policy
          will constitute your acceptance of such change.
        </Paragraph>
        <Title strong level={5}>
          13.&emsp;&emsp;Contacting Us
        </Title>
        <Paragraph>
          If you have any concerns or questions about this Privacy Policy,
          please contact us at{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>.
        </Paragraph>
      </div>
      <LandingFooter borderTop />
    </>
  );
}

export default Privacy;
