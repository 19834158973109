import React, { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@tanstack/react-query';
import { submitCarbonCreditInformationRequest } from 'src/Mutation';

import SplashPage from 'src/components/layout/SplashPage';
import BulletList from 'src/components/splash/BulletList';
import SplashBanner from 'src/components/splash/SplashBanner';
import SplashContent from 'src/components/splash/SplashContent';
import TextArea from 'antd/lib/input/TextArea';
import BackgroundImage from 'src/images/containership.jpg';
import CompanyType from 'src/components/form/CompanyType';
import { useUser } from 'src/utils/authentication';

const queryTypeOptions = [
  {
    value: 'productionRisk',
    label: 'Production risk'
  },
  {
    value: 'insurance',
    label: 'Financial risk - Insurance'
  },
  {
    value: 'hedging',
    label: 'Financial risk- Hedging'
  },
  {
    value: 'diversification',
    label: 'Supplier diversification'
  },
  {
    value: 'costControl',
    label: 'Inventory cost control'
  },
  {
    value: 'other',
    label: 'Other (please specify)'
  }
];

function SupplyChainRisk() {
  const [form] = useForm();
  const [isOther, setIsOther] = useState(false);
  const { data: user } = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate, isLoading } = useMutation({
    mutationFn: submitCarbonCreditInformationRequest,
    onError: (e) => {
      const errorMessage = `An error occurred. Please talk to the concierge directly [${e.message}]`;
      messageApi.error(errorMessage);
    },
    onSuccess: () =>
      messageApi.success('We have noted your interest and will be in touch')
  });

  const onFinish = async (values) => {
    const body = {
      company_type: values.companyType,
      title: values.jobTitle,
      additional_information: values.additionalInformation,
      query_type: values.queryType
        .map((o) => queryTypeOptions.find((qt) => qt.value === o).label)
        .join(', '),
      requesting_user: user?.id
    };
    mutate(body);
  };
  const onFieldsChange = (changedFields) => {
    const queryType = changedFields.find((o) => o.name[0] === 'queryType');
    if (queryType) {
      const other = queryType.value.indexOf('other');
      setIsOther(other >= 0);
    }
  };

  useEffect(() => {
    if (user) {
      const { company, title } = user;
      if (company) {
        if (company.company_functions && company.company_functions.length > 0) {
          form.setFieldsValue({
            companyType: company.company_functions[0].uuid
          });
        }
      }
      form.setFieldsValue({
        jobTitle: title
      });
    }
  }, [user]);

  return (
    <SplashPage className="supply-chain-risk banner">
      <div className="splash-container">
        <SplashBanner
          title="Supply Chain Risk Management"
          backgroundImage={`linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(23, 47, 57, 0.6), rgba(23, 47, 57, 0.6)), url(${BackgroundImage})`}
        >
          <div className="bullet-title">Lock in Price</div>
          <BulletList>
            <BulletList.Item icon={CheckCircleFilled}>
              We match based on buyer and seller KPIs, then help manage sampling
              &amp; testing.
            </BulletList.Item>
            <BulletList.Item icon={CheckCircleFilled}>
              We then buy with a deposit to lock in price, while you conduct
              testing.
            </BulletList.Item>
            <BulletList.Item icon={CheckCircleFilled}>
              If testing fails, you exit with only a minor deposit fee to cover
              supplier costs.
            </BulletList.Item>
            <BulletList.Item icon={CheckCircleFilled}>
              Suppliers may offer lower prices and additional incentives for
              larger-quantity contracts.
            </BulletList.Item>
          </BulletList>
          <div className="bullet-title">Dual Sourcing</div>
          <BulletList>
            <BulletList.Item icon={CheckCircleFilled}>
              Circular helps you buy across multiple sellers
            </BulletList.Item>
            <BulletList.Item icon={CheckCircleFilled}>
              Our team of experts helps you manage logistics to ensure JIT
              delivery, should any issues arise along the way.
            </BulletList.Item>
          </BulletList>
        </SplashBanner>
        <SplashContent>
          {contextHolder}
          <div className="splash-content-body">
            <div className="sc-header">Get Started</div>
            <div className="sc-subheader">
              Contact us to start managing your supply chain risk.
            </div>
            <Form
              name="supply-chain-form"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={() =>
                message.error('Please complete the fields with errors')
              }
              onFieldsChange={onFieldsChange}
              autoComplete="off"
            >
              <Form.Item
                label="Company Type"
                name="companyType"
                rules={[
                  { required: true, message: 'Company Type is required' }
                ]}
              >
                <CompanyType />
              </Form.Item>
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[{ required: true, message: 'Job Title is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="What are you most interested in derisking"
                name="queryType"
                rules={[
                  { required: true, message: 'Expertise Type is required' }
                ]}
              >
                <Checkbox.Group options={queryTypeOptions} />
              </Form.Item>
              <Form.Item
                className="query-type-other"
                style={isOther ? undefined : { display: 'none' }}
                rules={[{ required: isOther, message: 'Other is required' }]}
                name="other"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Additional Information"
                name="additionalInformation"
              >
                <TextArea
                  placeholder="Please provide any additional information regarding your outreach to Circular today."
                  maxLength={2048}
                  rows={4}
                />
              </Form.Item>
              <Button
                loading={isLoading}
                shape="round"
                type="primary"
                htmlType="submit"
              >
                Connect with a specialist
              </Button>
            </Form>
          </div>
        </SplashContent>
      </div>
    </SplashPage>
  );
}

export default SupplyChainRisk;
