import { useQuery } from '@tanstack/react-query';
import minMax from 'dayjs/plugin/minMax';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Table } from 'antd';
import { getRegionalAnalytics } from 'src/Query/supplierAnalytics';
import useMetaProperty from 'src/hooks/useMetaProperty';
import { REGIONS, TYPES, FORMS } from './dimensions';
import SupplierTdssCount from './SupplierTdssCount';

dayjs.extend(minMax);

function SupplierAnalyticsGlobal() {
  const { data, isLoading, isError } = useSupplierAnalyticsData();
  const {
    data: data_pe,
    isLoading: isLoading_pe,
    isError: isError_pe
  } = useSupplierAnalyticsData(['rHDPE', 'rLDPE/rLLDPE']);
  const {
    data: data_other,
    isLoading: isLoading_other,
    isError: isError_other
  } = useSupplierAnalyticsData(['rPET', 'rPP', 'rHDPE', 'rLDPE/rLLDPE'], true);

  const latest = useMemo(
    () =>
      dayjs.max(
        [
          ...Object.values(data || {}),
          ...Object.values(data_pe || {}),
          ...Object.values(data_other || {})
        ].map((item) => dayjs(item.latest))
      ),
    [data, data_pe, data_other]
  );

  const columns = [
    ...TYPES.map((type) => ({
      title: type,
      dataIndex: ['types', type],
      render: (counts) => <SupplierTdssCount {...counts} />
    })),
    {
      title: 'Total',
      dataIndex: 'suppliers',
      key: 'total',
      render: (_, regionTotals) => <SupplierTdssCount {...regionTotals} />
    },
    {
      title: '',
      className: 'region-column',
      dataIndex: 'regionLabel',
      key: 'region'
    },
    ...FORMS.map((formLabel, formIndex) => ({
      title: formLabel,
      dataIndex: ['forms', formLabel.replace(/ /g, '_')],
      key: formLabel,
      render: (counts) => <SupplierTdssCount {...counts} />
    }))
  ];

  const timestamp = latest ? dayjs(latest).format('hh:mm MMM DD, YYYY') : '';
  return (
    <div className="supplier-analytics-global">
      <h2>Number of Suppliers by Region</h2>
      <small>Updated {timestamp}</small>
      <Table
        columns={columns}
        dataSource={Object.keys(REGIONS).map((regionKey) => ({
          ...data[regionKey],
          types: {
            ...data[regionKey]?.types,
            'rHDPE/rLDPE': data_pe?.[regionKey],
            Other: data_other?.[regionKey]
          },
          regionLabel: REGIONS[regionKey].label
        }))}
        pagination={false}
        loading={isLoading || isLoading_pe || isLoading_other}
      />
    </div>
  );
}

export default SupplierAnalyticsGlobal;

export function useSupplierAnalyticsData(types = [], exclude = false) {
  const typeProperty = useMetaProperty('type');

  let filters = {};

  const typeUuids = typeProperty?.options
    .filter((opt) =>
      exclude ? !types.includes(opt.value) : types.includes(opt.value)
    )
    .map((opt) => opt.uuid);

  if (types.length) {
    filters = typeUuids
      ? { 'material_properties[99]': typeUuids.join(',') }
      : undefined;
  }

  const queryObj = useQuery(
    ['regional-analytics', filters],
    () => getRegionalAnalytics(filters),
    { enabled: !!filters }
  );

  return {
    ...queryObj,
    data: queryObj.data || {}
  };
}
