import React from 'react';
import PropTypes from 'prop-types';
import RangeInput from 'src/components/form/RangeInput';

export default function PropertyRangeInput({
  value,
  property,
  onChange,
  ...props
}) {
  if (!property) return null;

  const onChangeWrapper = (valueIn) =>
    onChange({
      ...valueIn,
      property_id: property.uuid,
      unit_type: property.unit_type,
      units: valueIn?.units || property.units
    });
  return (
    <RangeInput
      value={value}
      units={value?.units || property.units}
      onChange={onChangeWrapper}
      {...props}
    />
  );
}

PropertyRangeInput.propTypes = {
  value: PropTypes.object,
  property: PropTypes.object,
  onChange: PropTypes.func
};
