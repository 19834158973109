import React from 'react';
import PropTypes from 'prop-types';

function FormSection({ label, extra, children, wide, className }) {
  return (
    <div className={`form-section ${wide ? 'wide' : ''} ${className || ''}`}>
      <div className="form-section-text">
        <label>{label}</label>
        <p>{extra}</p>
      </div>
      <div className="form-section-controls">{children}</div>
    </div>
  );
}

FormSection.propTypes = {
  label: PropTypes.string,
  extra: PropTypes.string,
  className: PropTypes.string,
  wide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FormSection;
