import React from 'react';
import PropTypes from 'prop-types';
import DarkPanel from 'src/components/layout/DarkPanel';
import TaskStatusEmpty from 'src/components/icons/TaskStatusEmpty';

export default function Task({ title, subtitle, due, danger }) {
  return (
    <DarkPanel.Box>
      <TaskStatusEmpty />
      <div className="task-list--item">
        <div className="task-title">{title}</div>
        <div className="task-subtitle">
          <span className={danger ? 'danger' : undefined}>{due}</span>
          &nbsp;&nbsp;
          {subtitle}
        </div>
      </div>
    </DarkPanel.Box>
  );
}

Task.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  due: PropTypes.string,
  danger: PropTypes.bool
};
