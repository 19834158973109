import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import ProjectIcon from 'src/components/icons/Project';

import './ProjectDashboard.less';
import { findProperties } from 'src/utils/properties';
import LogisticsIcon from 'src/components/icons/LogisticsIcon';
import MapMarkerIcon from 'src/components/icons/MapMarkerIcon';
import prettyNumber, {
  prettyNumberRound
} from 'src/components/utils/prettyNumber';

const formatPropertyList = (list) => {
  if (Array.isArray(list)) {
    return list.map((o) => o.value).join(', ');
  }
  return '-';
};

const formatLocation = (location) => {
  if (location.city && location.country)
    return `${location.city}, ${location.country}`;
  if (location.city) return location.city;
  if (location.country) return location.country;
  return '';
};

export default function ProjectData({ requestForQuote }) {
  const types = formatPropertyList(findProperties(requestForQuote, 'type'));
  const forms = formatPropertyList(findProperties(requestForQuote, 'form'));
  const colorProperties = findProperties(requestForQuote, 'color');
  const colors = formatPropertyList(colorProperties);
  const locations = requestForQuote.shipping_addresses
    .map((o) => formatLocation(o))
    .join('/');

  return (
    <Row gutter={[19, 8]} align="middle" className="project-data">
      <Col>
        <ProjectIcon className /> <span className="type">{types}</span> -{' '}
        <span className="form">{forms}</span>
      </Col>
      <Col>
        <span
          style={{ background: colorsToHex(colorProperties) }}
          className="color-icon"
        />{' '}
        <span className="color">{colors}</span>
      </Col>
      <Col>
        <LogisticsIcon />{' '}
        <span className="quantity">
          {prettyNumber(requestForQuote.quantity)}&nbsp;
          {requestForQuote.quantity_units}
        </span>{' '}
        <span className="duration">
          ({prettyNumberRound(requestForQuote.duration_months)} MO.)
        </span>
      </Col>
      <Col>
        {locations && (
          <>
            <MapMarkerIcon /> <span className="shipping">{locations}</span>
          </>
        )}
      </Col>
    </Row>
  );
}

ProjectData.propTypes = {
  requestForQuote: PropTypes.object
};

const DEFAULT_COLOR = '#444444';
const colorsToHex = (colors) => {
  if (colors?.length === 1) return colorToHex(colors[0]);
  return DEFAULT_COLOR;
};

const colorMap = {
  Black: '#101010',
  White: '#f8f8f8',
  Clear: '#e8e8f8',
  Red: '#f5222d',
  'White, blows Clear': '#e8e8f8',
  Natural: '#8c8c20',
  Mixed: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)',
  Purple: '#722ed1',
  Blue: '#2f54eb',
  Grey: '#8c8c8c',
  Green: '#52c41a',
  Yellow: '#fadb14',
  Orange: '#fa8c16',
  Multi: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)',
  Custom: 'linear-gradient(270deg, #51ce92 0%, #1340fa 100%)'
};

const colorToHex = (color) => colorMap[color.value] || DEFAULT_COLOR;
