import { Checkbox } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export default function IsCapabilityFilter() {
  const [isCapabilityFilter, setIsCapabilityFilter] = useConciergeContextState([
    'explore',
    'filters',
    'is_capability'
  ]);

  return (
    <Checkbox
      checked={isCapabilityFilter === false}
      onChange={(e) =>
        setIsCapabilityFilter(e.target.checked ? false : undefined)
      }
    >
      Only show verified suppliers
    </Checkbox>
  );
}
