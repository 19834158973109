import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getPriceLogs } from 'src/Query';
import dayjs from 'dayjs';
import { currencyValuePrice } from 'src/utils/currency';
import prettyNumber from 'src/components/utils/prettyNumber';

export default function PriceLogs({ tdsLocationId }) {
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10
  });

  const filters = useMemo(
    () => ({
      tds_location: tdsLocationId,
      ...pagination
    }),
    [pagination, tdsLocationId]
  );
  const { data, isLoading } = useQuery({
    queryKey: ['price-log', filters],
    queryFn: () => getPriceLogs(filters)
  });

  if (!tdsLocationId) return null;

  return (
    <Table
      className="price-logs-table"
      columns={[
        {
          title: 'Transaction Date',
          // sorter: true,
          dataIndex: 'date',
          render: (date) => dayjs(date).format('MM/DD/YYYY')
        },
        {
          title: 'Unit Price',
          // sorter: true,
          dataIndex: 'price',
          render: (price, { units, currency }) =>
            currencyValuePrice(price, currency, units)
        },
        {
          title: 'Vol.',
          // sorter: true,
          dataIndex: 'quantity',
          render: (quantity, { units }) =>
            quantity ? `${prettyNumber(quantity)} ${units}` : '-'
        },
        {
          title: 'Log Date',
          // sorter: true,
          dataIndex: 'created_at',
          render: (date) => dayjs(date).format('MM/DD/YYYY')
        }
      ]}
      dataSource={data?.results}
      loading={isLoading}
      pagination={{
        current: pagination.page,
        pageSize: pagination.page_size,
        total: data?.count,
        onChange: (page, page_size) => setPagination({ page, page_size }),
        showSizeChanger: true,
        pageSizeOptions: [10, 25],
        showTotal: (total, [from, to]) =>
          `Displaying items ${from} - ${to} of ${total} total`
      }}
    />
  );
}

PriceLogs.propTypes = {
  tdsLocationId: PropTypes.string
};
