import {
  EXCELLENT,
  FAIR,
  GOOD,
  NO_DATA,
  POOR,
  VERY_GOOD
} from 'src/components/GoodnessRating';

const ratingRanges = {
  [EXCELLENT]: { min: 99, max: 100 },
  [VERY_GOOD]: { min: 98, max: 98 },
  [GOOD]: { min: 60, max: 97 },
  [FAIR]: { min: 40, max: 59 },
  [POOR]: { min: 0, max: 39 }
};

const ratingToPercentage = (rating) => {
  switch (rating) {
    case EXCELLENT:
      return 99;
    case VERY_GOOD:
      return 98;
    case GOOD:
      return 60;
    case FAIR:
      return 40;
    case POOR:
      return 20;
    default:
      return null;
  }
};

const getRatingFromPercentage = (percentage) =>
  Object.keys(ratingRanges).find(
    (rating) =>
      percentage >= ratingRanges[rating].min &&
      percentage <= ratingRanges[rating].max
  );

const useOverallConfidenceRating = ({
  filters,
  locationRating,
  specRating,
  pricingRating
}) => {
  const {
    form_code__in: formFilter = null,
    region_code__in: regionsFilter = null,
    type_code__in: typeFilter = null
  } = filters || {
    form_code__in: null,
    region_code__in: null,
    type_code__in: null
  };

  const missingFilters = [formFilter, regionsFilter, typeFilter].some(
    (filter) => filter === null
  );

  // We require form, type and region filters before we weight the overall rating
  if (missingFilters) {
    return {
      overallRating: NO_DATA,
      overallRatingPercentage: null
    };
  }

  const locationPercentageRating =
    locationRating !== NO_DATA ? ratingToPercentage(locationRating) : null;
  const specPercentageRating =
    specRating !== NO_DATA ? ratingToPercentage(specRating) : null;
  const pricingPercentageRating =
    pricingRating !== NO_DATA ? ratingToPercentage(pricingRating) : null;

  const applicableRatings = [
    locationPercentageRating,
    specPercentageRating,
    pricingPercentageRating
  ].filter((percentage) => percentage !== null);

  const overallRatingPercentage =
    applicableRatings.length > 0
      ? applicableRatings.reduce((previous, current) => previous + current, 0) /
        applicableRatings.length
      : null;

  const overallRating =
    overallRatingPercentage === null
      ? NO_DATA
      : getRatingFromPercentage(overallRatingPercentage);

  return {
    overallRating,
    overallRatingPercentage
  };
};

export default useOverallConfidenceRating;
