import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import ProjectExplore from 'src/pages/project/ProjectExplore';
// import ProjectQuotes from 'src/pages/project/ProjectQuotes';

export default function ExploreRoutes() {
  return (
    <Routes>
      <Route path="/:projectId/*" element={<ExploreSubroutes />} />
    </Routes>
  );
}

function ExploreSubroutes() {
  const { setActiveProjectId } = useSidebarContext();
  const { projectId: projectIdOrPlayground } = useParams();
  const projectId =
    projectIdOrPlayground === 'playground' ? undefined : projectIdOrPlayground;

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  return (
    <Routes>
      <Route path="/" element={<ProjectExplore />} />
    </Routes>
  );
}
