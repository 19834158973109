import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Table
} from 'antd';
import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { CheckOutlined, UserOutlined } from '@ant-design/icons';

import { getRFQ, getUsers } from 'src/Query';
import { inviteUser } from 'src/Mutation';

/* eslint-disable no-nested-ternary */
export default function TeamTab() {
  const { rfqId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    data: rfq,
    error,
    isLoading: rfqLoading
  } = useQuery({
    queryKey: ['requestForQuote', rfqId],
    queryFn: () => getRFQ(rfqId)
  });

  const { data: users, isLoading } = useQuery({
    enabled: !!rfq?.company?.uuid,
    queryKey: ['users', { company: rfq?.company?.uuid }],
    queryFn: () => getUsers({ company: rfq?.company?.uuid })
  });

  const queryClient = useQueryClient();

  const { mutate: invite, isLoading: inviteLoading } = useMutation({
    mutationFn: inviteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', { company: rfq?.company?.uuid }]
      });
      setModalOpen(false);
    }
  });

  const [form] = Form.useForm();

  if (rfqLoading || isLoading) return <Skeleton />;

  return (
    <div className="documents-tab">
      <Row className="tab--title" justify="space-between">
        <Col>
          <h2>Team members</h2>
        </Col>
        <Col>
          <Button onClick={() => setModalOpen(true)} type="primary">
            Invite Team Members
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={[
              {
                title: 'Team member name',
                dataIndex: 'name',
                render: (name, user) =>
                  user.avatar ? (
                    <>
                      <Avatar src={user.avatar} /> {name}
                    </>
                  ) : name ? (
                    <>
                      <Avatar>
                        {name
                          .split(/\s/)
                          .map((namePart) => namePart[0])
                          .join('')
                          .toUpperCase()}
                      </Avatar>{' '}
                      {name}
                    </>
                  ) : (
                    <>
                      <Avatar icon={<UserOutlined />} /> {name}
                    </>
                  )
              },
              {
                title: 'Email',
                dataIndex: 'email',
                width: 230
              },
              {
                title: 'Status',
                dataIndex: 'last_login',
                width: 108,
                render: (lastLogin) =>
                  lastLogin ? (
                    <span className="user-status">
                      <CheckOutlined /> Joined
                    </span>
                  ) : (
                    <span className="user-status">Invite Pending</span>
                  )
              }
            ]}
            dataSource={users.results}
          />
        </Col>
      </Row>
      <Modal
        title="Invite New Team Member"
        open={modalOpen}
        onOk={() => form.submit()}
        onCancel={() => setModalOpen(false)}
      >
        <Form
          onFinish={(values) => invite(values)}
          form={form}
          disabled={inviteLoading}
          layout="vertical"
        >
          <Form.Item
            label="First and last name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input their name'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Company email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid email address'
              },
              {
                required: true,
                message: 'Please input their email address'
              }
            ]}
          >
            <Input placeholder="their.name@company.com" />
          </Form.Item>

          <Form.Item
            label="Title / role"
            name="title"
            rules={[
              { required: true, message: 'Please input their title or role' }
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
