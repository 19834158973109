import './RequestForQuote.less';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import produce from 'immer';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  List,
  Row,
  Typography,
  Select,
  Col,
  Radio,
  Checkbox
} from 'antd';
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Summary from 'src/components/rfq/Summary';
import { getRFQ } from 'src/Query';
import { patchRFQ } from 'src/Mutation';
import CurrencyInput from 'src/components/form/CurrencyInput';
import { parseInternalLocation } from 'src/components/form/LocationInput';
import MultipleLocationInput from 'src/components/form/MultipleLocationInput';
import SwitchableInput from 'src/components/form/SwitchableInput';
import ColorPropertyInput from 'src/components/form/ColorPropertyInput';
import { useUser } from 'src/utils/authentication';
import { isConcierge } from 'src/utils/permissions';
import { filterOptionByRelationship } from 'src/utils/properties';
import { QueryStatus } from 'src/components/error/QueryStatus';
import { MutationStatus } from 'src/components/error/MutationStatus';
import MaterialPropertyForm, {
  normalizeProperties,
  normalizeNumericalProperties
} from 'src/components/onboard/MaterialPropertyForm';
import ReviewFormItem from 'src/components/onboard/ReviewFormItem';
import MetaPropertySelect from 'src/components/form/MetaPropertySelect';
import NumberInput from 'src/components/form/NumberInput';
import { spaceSpecToValue, valueToSpaceSpec } from 'src/utils/colorSpace';
import TextArea from 'antd/lib/input/TextArea';
import useMetaPropertyGroup from 'src/hooks/useMetaPropertyGroup';
import AdminConsoleLink from 'src/components/navigate/AdminConsoleLink';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import CircularLogo from 'src/components/icons/CircularLogo';
import { ReactComponent as RadialStrokes } from 'src/images/RadialStrokes.svg';

import './Review.less';

// converts from the structure returned by GET to the structure
// needed for the form
/* eslint-disable no-param-reassign */
const normalizeData = (data) =>
  produce(data, (draft) => {
    if (draft.specific_delivery_date) {
      draft.specific_delivery_date =
        draft.specific_delivery_date && dayjs(draft.specific_delivery_date);
    }
    if (draft.material_properties) {
      const { material_properties } = draft;
      // eslint-disable-next-line no-param-reassign
      draft.material_properties = normalizeProperties(material_properties);
      if (draft.material_properties.color) {
        draft.color = {
          property: draft.material_properties.color,
          spec: spaceSpecToValue(draft.color_space_spec)
        };
      }
    }
    if (draft.material_numerical_properties) {
      const { material_numerical_properties } = draft;
      // eslint-disable-next-line no-param-reassign
      draft.material_numerical_properties = normalizeNumericalProperties(
        material_numerical_properties
      );
      // eslint-disable-next-line no-param-reassign
    }

    if (draft.reasons) draft.reasons = draft.reasons.split(',');
    if (draft.sourcing_priorities)
      draft.sourcing_priorities = draft.sourcing_priorities.split(',');
    if (draft.specs) draft.specs = draft.specs.split(',');
  });
/* eslint-enable no-param-reassign */

// the properties that are hard coded on the form
// must be kebab case (paramCase)
const requiredProperties = [
  'form',
  'type',
  'grade',
  'source-type',
  'color',
  'application'
];

/* eslint-disable prefer-arrow-callback */

const sidebarSsections = [
  'General Project Information',
  'Target Specifications',
  'Pricing & Logistics',
  'Project Preferences'
];

export default function Review() {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const userIsConcierge = isConcierge(user);
  const { rfqId } = useParams();
  const [rfqForm] = Form.useForm();
  const navigate = useNavigate();
  // allow the embedded MaterialPropertyForm to set a callback for when fields change
  const [childOnFieldsChange, setChildOnFieldsChange] = useState();

  const numericalProperties = useMetaNumericalProperty();

  const rfqMultipleGroup = useMetaPropertyGroup('RFQ multiple');

  const { data, error, isLoading } = useQuery({
    queryKey: ['requestForQuote-review', rfqId],
    queryFn: () => getRFQ(rfqId),
    refetchOnWindowFocus: false
  });

  const {
    mutate,
    isLoading: isMutating,
    error: mutationError
  } = useMutation({
    mutationFn: patchRFQ,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ['requestForQuote-review'] });
      navigate(`/request-for-quote/${res?.uuid}/?updated=true`);
    }
  });

  const requestForQuote = useMemo(
    () => data && rfqMultipleGroup && normalizeData(data),
    // we need to wait for the rfqMultipleGroup to load because the
    // form fields change based on whether multiple is set
    [data, rfqMultipleGroup]
  );

  const submitUpdates = (values) => {
    const patch = {
      uuid: rfqId,
      material_properties_ids: [],
      material_numerical_properties: [],
      status: data?.status === 'ready_for_review' ? 'approved' : data?.status,
      price_weight_units: requestForQuote?.price_weight_units,
      quantity_units: requestForQuote?.quantity_units
    };
    Object.keys(values).forEach((attribute) => {
      if (attribute === 'material_properties') {
        Object.keys(values.material_properties).forEach((property) => {
          if (Array.isArray(values.material_properties[property])) {
            // multiple select
            patch.material_properties_ids = [
              ...patch.material_properties_ids,
              ...values.material_properties[property]
            ];
          } else if (values.material_properties[property]) {
            patch.material_properties_ids.push(
              values.material_properties[property]
            );
          }
        });
      } else if (attribute === 'material_numerical_properties') {
        const { existing, unused } = values.material_numerical_properties;
        if (Array.isArray(existing)) {
          patch.material_numerical_properties = [
            ...patch.material_numerical_properties,
            ...existing
              .map((p) => ({
                min: p.min,
                max: p.max,
                property_id: p.property_id,
                unit_type: p.unit_type,
                units: p.units,
                rfq: rfqId, // would prefer not to need this but the API currently does
                uuid: p.uuid
              }))
              .filter((o) => o.min || o.max)
          ];
        }
        if (Array.isArray(unused)) {
          patch.material_numerical_properties = [
            ...patch.material_numerical_properties,
            ...unused
              .map(
                (p, index) =>
                  p && {
                    min: p.min,
                    max: p.max,
                    property_id: p.property_id,
                    units: p.units,
                    unit_type: p.unit_type,
                    rfq: rfqId // would prefer not to need this but the API currently does
                  }
              )
              .filter((o) => !!o)
          ];
        }
      } else if (attribute === 'specific_delivery_date') {
        patch[attribute] =
          (values[attribute] &&
            dayjs(values[attribute]).format('YYYY-MM-DD')) ||
          null;
      } else if (attribute === 'quantity') {
        patch[attribute] = parseFloat(values[attribute]) || 0;
      } else if (attribute === 'shipping_addresses') {
        patch[attribute] = Array.isArray(values[attribute])
          ? values[attribute].map((location) => parseInternalLocation(location))
          : [];
      } else if (attribute === 'frequency') {
        if (!values[attribute]) {
          patch[attribute] = null;
          patch.recurring = false;
        } else {
          patch[attribute] = values[attribute];
          patch.recurring = true;
        }
      } else if (attribute === 'color' && values[attribute]?.property) {
        if (Array.isArray(values[attribute].property)) {
          patch.material_properties_ids.push(...values[attribute].property);
          patch.color_space_spec = valueToSpaceSpec(values[attribute]?.spec);
        } else {
          patch.material_properties_ids.push(values[attribute].property);
          patch.color_space_spec = valueToSpaceSpec(values[attribute]?.spec);
        }
      } else if (
        ['reasons', 'sourcing_priorities', 'specs'].includes(attribute) &&
        Array.isArray(values[attribute])
      ) {
        patch[attribute] = values[attribute].join(',');
      } else {
        patch[attribute] = values[attribute];
      }
    });

    // Prevent wiping existing extra
    if (patch.extra && data.extra) {
      patch.extra = { ...data.extra, ...patch.extra };
    }

    // console.log('PATCH', patch);
    mutate({ ...patch });
  };

  // should we handle this inside a onFieldsChange callback?
  // it seems to work like this
  const selectedPropertiesMap = rfqForm.getFieldsValue()?.material_properties;

  // all properties that have been set in the current form (array of ids only)
  const selectionProperties = useMemo(
    () =>
      (selectedPropertiesMap &&
        Object.values(selectedPropertiesMap).filter((o) => !!o)) ||
      [],
    [selectedPropertiesMap]
  );

  const optionFilter = useMemo(
    () => filterOptionByRelationship(selectionProperties),
    [selectionProperties]
  );

  const onFieldsChange = (changedFields) => {
    if (childOnFieldsChange) childOnFieldsChange(changedFields);
  };

  return (
    <Row
      id="request-for-quote-review"
      className="review"
      data-testid="request-for-quote"
    >
      <Col flex="324px">
        <ReviewSidebar sections={sidebarSsections} currentSection={0} />
      </Col>
      <Col flex="1">
        <MutationStatus
          title="Request for Quote"
          isLoading={isMutating}
          error={mutationError}
        />
        <QueryStatus
          title="Request for Quote"
          isLoading={isLoading}
          error={error}
          skeleton
        >
          {requestForQuote && (
            <Form
              form={rfqForm}
              initialValues={requestForQuote}
              onFinish={submitUpdates}
              onFieldsChange={onFieldsChange}
              preserve
            >
              <Row className="review__header" justify="space-evenly">
                <Summary requestForQuote={data} />
                <AdminConsoleLink
                  app="api"
                  type="requestforquote"
                  uuid={rfqId}
                />
              </Row>
              <div className="review__content">
                <div className="review__content--list">
                  <ReviewSection title="General Project Information">
                    <List size="large" key="description">
                      <ReviewFormItem
                        key="material"
                        label="Material"
                        name={['material_properties', 'type']}
                      >
                        <MetaPropertySelect propertyName="type" />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="quantity"
                        label="Quantity"
                        name="quantity"
                      >
                        <NumberInput suffix={requestForQuote.quantity_units} />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="form"
                        name={['material_properties', 'form']}
                        label="Form"
                      >
                        <MetaPropertySelect propertyName="form" />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="grade"
                        name={['material_properties', 'grade']}
                        label="Grade"
                      >
                        <MetaPropertySelect propertyName="grade" />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="source"
                        name={['material_properties', 'source-type']}
                        label="Source"
                      >
                        <MetaPropertySelect propertyName="source-type" />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="application"
                        name={['material_properties', 'application']}
                        label="Application"
                      >
                        <MetaPropertySelect propertyName="application" />
                      </ReviewFormItem>

                      <ReviewFormItem key="color" label="Color" name="color">
                        <SwitchableInput
                          Component={ColorPropertyInput}
                          componentProps={{ filter: optionFilter }}
                        />
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="polymer type"
                        name={['material_properties', 'polymer type']}
                        label="Polymer type"
                      >
                        <MetaPropertySelect propertyName="polymer-type" />
                      </ReviewFormItem>
                    </List>
                  </ReviewSection>
                  <ReviewSection title="Property Target Specifications">
                    <List size="large" key="specs">
                      <MaterialPropertyForm
                        materialProperties={data?.material_properties}
                        materialNumericalProperties={
                          data?.material_numerical_properties
                        }
                        id={rfqId}
                        requiredProperties={requiredProperties}
                        form={rfqForm}
                        setOnFieldsChange={(fn) =>
                          setChildOnFieldsChange(() => fn)
                        }
                        multiple={rfqMultipleGroup}
                        objectType="rfq"
                      />
                    </List>
                  </ReviewSection>
                  <ReviewSection title="Pricing & Logistics">
                    <List size="large" key="pricing-logidtics">
                      <ReviewFormItem
                        key="price"
                        label="What is your budget?"
                        name="price"
                      >
                        <CurrencyInput
                          currency={requestForQuote?.currency}
                          suffix={`/${requestForQuote?.price_weight_units}`}
                        />
                        <Alert
                          message="Pricing information is never revealed to sellers during the matching process"
                          type="info"
                          showIcon
                        />
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="price_details"
                        name="price_details"
                        label="Pricing Context"
                        wide
                      >
                        <TextArea
                          placeholder="We want to be 100% PCR for our US rPET bottle line by 2025, and our EU line by 2028. Consistency of batches/reducing batch variability risk is of primary importance."
                          rows={4}
                        />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="delivered_by"
                        name="specific_delivery_date"
                        label="First Batch Delivery"
                      >
                        <DatePicker />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="delivered_to"
                        name="shipping_addresses"
                        label="Shipping Destination"
                      >
                        <MultipleLocationInput />
                      </ReviewFormItem>

                      <ReviewFormItem
                        key="frequency"
                        name="frequency"
                        label="Do you wish to split total quantity with multiple shipments?"
                      >
                        <SwitchableInput
                          // toggleComponent={
                          //   <Select
                          //     options={[
                          //       { value: true, label: 'Yes' },
                          //       { value: false, label: 'No' }
                          //     ]}
                          //   />
                          // }
                          initialValue={null}
                          Component={Select}
                          componentProps={{
                            options: [
                              {
                                value: 'daily',
                                label: 'Daily'
                              },
                              {
                                value: 'weekly',
                                label: 'Weekly'
                              },
                              {
                                value: 'monthly',
                                label: 'Monthly'
                              },
                              {
                                value: 'quarterly',
                                label: 'Quarterly'
                              },
                              {
                                value: 'semi_annually',
                                label: 'Semi annually'
                              },
                              {
                                value: 'annualy',
                                label: 'Annually'
                              }
                            ],
                            placeholder: 'Frequency'
                          }}
                        />
                      </ReviewFormItem>
                    </List>
                  </ReviewSection>
                  <ReviewSection title="Additional Information">
                    <List size="large" key="additional-info">
                      <ReviewFormItem
                        key="scores_weight"
                        name={['extra', 'scores_weight']}
                        label="Rate these factors on a scale of importance"
                        wide
                      >
                        <ScoresWeight />
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="important_properties"
                        name={['extra', 'important_properties']}
                        label="What are the top 3 most important material properties for this project?"
                        wide
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          optionFilterProp="label"
                          options={numericalProperties.map((p) => ({
                            label: `${p.code} (${p.name})`,
                            value: `${p.code} (${p.name})`
                          }))}
                          placeholder="Numerical Properties..."
                          style={{ width: '100%' }}
                        />
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="sourcing_priorities"
                        name="sourcing_priorities"
                        label='What supplier characteristics do you consider important in order for the supplier to be considered as a match with you on "brand fit"?'
                        before="Select all that apply"
                        wide
                      >
                        <Checkbox.Group>
                          <Checkbox value="Business/Credit Risk">
                            Business/Credit Risk
                          </Checkbox>
                          <Checkbox value="Corporate Sustainability focus">
                            Corporate Sustainability focus
                          </Checkbox>
                          <Checkbox value="Traceability/ESG reporting focus">
                            Traceability/ESG reporting focus
                          </Checkbox>
                          <Checkbox value="Global presence">
                            Global presence
                          </Checkbox>
                          <Checkbox value="Certifications">
                            Certifications
                          </Checkbox>
                          <Checkbox value="Other">Other</Checkbox>
                        </Checkbox.Group>
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="reasons"
                        name="reasons"
                        label="Which pain points in sourcing PCR suppliers do you hope to ease with the help of the Circular team?"
                        before="Select all that apply"
                        wide
                      >
                        <Checkbox.Group>
                          <Checkbox value="Cost">Cost</Checkbox>
                          <Checkbox value="Supply chain risk">
                            Supply chain risk
                          </Checkbox>
                          <Checkbox value="Poor supplier experience">
                            Poor supplier experience
                          </Checkbox>
                          <Checkbox value="Material quality">
                            Material quality
                          </Checkbox>
                          <Checkbox value="Other">Other</Checkbox>
                        </Checkbox.Group>
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="suppliers_to_avoid"
                        name={['extra', 'suppliers_to_avoid']}
                        label="Are there any suppliers we should avoid in our search?"
                        wide
                      >
                        <TextArea rows={4} />
                      </ReviewFormItem>
                      <ReviewFormItem
                        key="review_comments"
                        name="review_comments"
                        label="Additional Comments"
                        wide
                      >
                        <TextArea
                          placeholder="Please tell us anything else that is important for your requirements"
                          rows={4}
                        />
                      </ReviewFormItem>
                    </List>
                  </ReviewSection>
                  {userIsConcierge && (
                    <ReviewSection title="Questionnaire">
                      <List size="large" key="questionnaire">
                        <ReviewFormItem
                          key="goal_description"
                          name="goal_description"
                          label="Project Goal"
                          wide
                        >
                          <TextArea rows={4} />
                        </ReviewFormItem>
                        <ReviewFormItem
                          key="specs"
                          name="specs"
                          label="Specs Details"
                          wide
                        >
                          <TextArea rows={4} />
                        </ReviewFormItem>
                        <ReviewFormItem
                          key="tests"
                          name="tests"
                          label="Testing Details"
                          wide
                        >
                          <TextArea rows={4} />
                        </ReviewFormItem>
                        <ReviewFormItem
                          key="logistics_details"
                          name="logistics_details"
                          label="Logistics Details"
                          wide
                        >
                          <TextArea rows={4} />
                        </ReviewFormItem>
                      </List>
                    </ReviewSection>
                  )}
                </div>
              </div>
              <div className="actions">
                <div className="action-wrapper">
                  <Button type="primary" htmlType="submit" loading={isMutating}>
                    {data?.status === 'draft' ? 'Update RFQ' : 'Approve RFQ'}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </QueryStatus>
      </Col>
    </Row>
  );
}

function ReviewSidebar({ sections, currentSection }) {
  return (
    <div className="review-sidebar">
      <div className="review-sidebar--header">
        <CircularLogo />
      </div>
      <div className="review-sidebar--body">
        {sections.map((section, i) => (
          <div
            className={`review-sidebar--section-title ${
              currentSection === i ? 'active' : ''
            }`}
            key={i}
          >
            {section}
          </div>
        ))}
      </div>
      <div className="review-sidebar--background">
        <RadialStrokes />
      </div>
    </div>
  );
}
ReviewSidebar.propTypes = {
  currentSection: PropTypes.number,
  sections: PropTypes.array
};

function ReviewSection({ title, children, defaultCollapsed = false }) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const collapsedStyles = {
    visibility: 'hidden',
    pointerEvents: 'none',
    height: 0,
    opacity: 0
  };

  return (
    <>
      <Typography.Title
        style={{ cursor: 'pointer' }}
        onClick={() => setCollapsed(!collapsed)}
        level={2}
      >
        {collapsed ? (
          <CaretRightFilled style={{ fontSize: '0.6em', verticalAlign: 0 }} />
        ) : (
          <CaretDownFilled style={{ fontSize: '0.6em', verticalAlign: 0 }} />
        )}{' '}
        {title}
      </Typography.Title>
      <div style={collapsed ? collapsedStyles : {}}>{children}</div>
    </>
  );
}
ReviewSection.propTypes = {
  title: PropTypes.any,
  children: PropTypes.node,
  defaultCollapsed: PropTypes.bool
};

function ScoresWeight({ onChange, value }) {
  const score_types = [
    'Specs & Testing',
    'Price',
    'Logistical Complexity',
    'Capacity',
    'Brand Fit'
  ];

  const handleScoreChange = (score_type, weight) => {
    onChange({ ...(value || {}), [score_type]: weight });
  };

  return (
    <div className="scores-weight">
      <p>
        Rate each category from 1 (least important) to 5 (most important) for
        your PCR sourcing projects and what you are looking for in a supply
        source
      </p>
      {score_types.map((score_type) => (
        <Row
          className="scores-weight--row"
          key={score_type}
          justify="space-between"
          align="middle"
        >
          <Col>
            <div>{score_type}</div>
          </Col>
          <Col md={12}>
            <Radio.Group
              onChange={(e) => handleScoreChange(score_type, e.target.value)}
              value={value?.[score_type]}
              name={score_type}
              options={[
                {
                  value: 1,
                  label: 1
                },
                {
                  value: 2,
                  label: 2
                },
                {
                  value: 3,
                  label: 3
                },
                {
                  value: 4,
                  label: 4
                },
                {
                  value: 5,
                  label: 5
                }
              ]}
              optionType="button"
              buttonStyle="outline"
            />
          </Col>
        </Row>
      ))}
    </div>
  );
}
ScoresWeight.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
};
