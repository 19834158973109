import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

function SplashBanner({ title, subtitle, footer, children, backgroundImage }) {
  const navigate = useNavigate();
  const onBack = () => navigate(-1);
  return (
    <div
      className="splash-banner"
      style={{
        backgroundImage
      }}
    >
      <Button
        type="text"
        onClick={onBack}
        icon={<LeftOutlined />}
        className="splash-banner-back bare"
      >
        Back
      </Button>
      <div className="splash-banner-body">
        <div className="splash-banner-content">
          <div className="splash-banner-title">{title}</div>
          <div className="splash-banner-subtitle">{subtitle}</div>
          {children}
        </div>
      </div>
      <div className="splash-banner-footer">{footer}</div>
    </div>
  );
}

SplashBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string
};

export default SplashBanner;
