import { Row, Col, Typography, Space, Form, Checkbox, Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircularLogo from 'src/components/icons/CircularLogo';
import useEditSupplierProfile from 'src/pages/supplier/useEditSupplierProfile';
import { useUser } from 'src/utils/authentication';

const { Text, Title, Paragraph } = Typography;

export default function SupplierOnboarding() {
  const { data: user } = useUser();
  const navigate = useNavigate();

  const { FormComponent, form, ErrorAlert, isMutating } =
    useEditSupplierProfile({
      hideTdsUpload: true,
      extraFields: (
        <Col xs={24}>
          <Form.Item
            valuePropName="checked"
            name="terms"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'You must consent to your data being surfaced in the Circular platform'
                        )
                      )
              }
            ]}
          >
            <Checkbox>
              Circular can use this data to create a private profile available
              on the Circular Platform.
            </Checkbox>
          </Form.Item>
        </Col>
      ),
      afterSubmit: () =>
        navigate(`/signup/?next=/supplier/${user.company.uuid}&onboarding=1`),
      sendEmails: true
    });

  return (
    <>
      <Row
        wrap={false}
        justify="space-between"
        className="supplier-page--header"
      >
        <Col>
          <CircularLogo />
        </Col>
      </Row>
      <Row className="supplier-onboarding">
        <Col
          xs={24}
          lg={11}
          className="supplier-onboarding--sidebar"
          style={{
            background: process.env.REACT_APP_BRAND_BACKGROUND_COLOR_LIGHT
          }}
        >
          <Space
            direction="vertical"
            size={26}
            className="supplier-onboarding--sidebar--inner"
          >
            <Text code>Supplier</Text>
            <Title level={1}>
              Create a Supplier Profile on the Circular Platform
            </Title>
            <Paragraph>
              Creating a supplier profile will allow you to get discovered by
              more buyers on the Circular Platform
            </Paragraph>
          </Space>
        </Col>
        <Col xs={24} lg={13} className="supplier-onboarding--content">
          <div className="supplier-onboarding--content--inner">
            {FormComponent}
            <Row>
              <Col xs={24}>
                {ErrorAlert}
                <Button
                  htmlType="button"
                  type="primary"
                  size="large"
                  loading={isMutating}
                  onClick={form.submit}
                >
                  <Text code>
                    {isMutating ? 'Processing...' : 'Create Profile'}
                  </Text>
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
