import React from 'react';
import { ReactComponent as Icon } from './svg/visibility.svg';
import SvgIconComponent from './SvgIconComponent';

function VisibilityIcon() {
  return (
    <SvgIconComponent
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Icon />
    </SvgIconComponent>
  );
}

export default VisibilityIcon;
