import { Typography } from 'antd';
import React from 'react';

import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import { useMapPageWarehouseSupplier } from 'src/pages/maps/MapPageWarehouseSupplier';
import Location from './Location';
import useSupplierList from './hooks/useSupplierList';

const lastDetailedCountValue = (detailedCounts, key) => {
  for (let i = detailedCounts.length - 1; i >= 0; i--) {
    if (detailedCounts[i] !== null) {
      return detailedCounts[i][key]; // Return the last non-null entry
    }
  }
  return 0;
};

export default function BalesExplore() {
  const { MapComponent, activeRecords } = useMapPageWarehouseSupplier({
    useMapProps: { theme: 'light_new' },
    className: 'explore-map bales'
  });

  const { suppliers, isLoadingSuppliers } = useSupplierList();

  const formatCount = (c) => (c ? prettyNumberRound(c) : '-');

  const detailedCounts = suppliers?.detailed_counts || [];
  const smallestSupplierCount = lastDetailedCountValue(
    detailedCounts.slice(0, -1), // skip the last 2 entries
    'supplier'
  );
  const biggestSupplierCount = lastDetailedCountValue(
    detailedCounts,
    'supplier'
  );

  return (
    <div className="bales-explore">
      <Typography.Title level={3}>Bales Explore</Typography.Title>
      <div className="mb-lg" justify="space-between">
        <Typography.Paragraph
          className="mb-0 text-size-2"
          strong
          style={{ alignSelf: 'center' }}
        >
          We found{' '}
          <span data-testid="recordsCount">
            {formatCount(smallestSupplierCount)}
          </span>{' '}
          verified suppliers{' '}
          {biggestSupplierCount && (
            <>
              and{' '}
              <span data-testid="recordsCountUnverified">
                {formatCount(biggestSupplierCount)}
              </span>{' '}
              unverified suppliers
            </>
          )}
        </Typography.Paragraph>
      </div>
      <Location />
    </div>
  );
}
