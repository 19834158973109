import { useMemo } from 'react';
import { sentenceCase } from 'change-case';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { currencyValuePrice } from 'src/utils/currency';
import {
  findNumericalProperty,
  findProperties,
  findProperty
} from 'src/utils/properties';
import prettyNumber, {
  prettyNumberRound
} from 'src/components/utils/prettyNumber';
import rangesOverlap from 'src/components/utils/rangesOverLap';
import renderDistance from 'src/utils/renderDistance';
import { mapMerge } from 'src/utils/mapMerge';
import { renderColorSpec } from 'src/utils/colorSpace';
import { compareProperties } from 'src/components/onboard/MaterialPropertyForm';
import numericRange from 'src/components/utils/numericRange';
import { patchDealSupply } from 'src/Mutation';

const riskIsGood = {
  low: true,
  'moderate low': true,
  moderate: true,
  'moderate high': false,
  high: false
};

/** one and only one of supplyIn or deals must be specified
 * if deals is specified, then an array of supplyData objects is returned
 * this is a convenience because we cannot loop over a hook
 */
export default function useProposalSupplyData(
  rfq,
  showAllProperties,
  useLbForPrice,
  supplyIn,
  deals
) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: patchDealSupply,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['rfqDeals', rfq.uuid] });
    }
  });
  const getMatchKey = (id) => `${id}-match`;

  const getSupplyData = (supply, supplyIndex, useLbForPriceIn) => {
    const getSupplyExtra = (key, defaultValue) =>
      supply.extra?.[key] === undefined ? defaultValue : supply.extra?.[key];

    const getMatchUpdateFunc = (matchKey) => (matchState) =>
      mutateAsync({
        uuid: supply.uuid,
        extra: {
          ...(supply.extra || {}),
          [matchKey]: matchState
        }
      });
    const tds = supply.technical_data_sheet;
    const priceBuyer = useLbForPriceIn
      ? Number(rfq.price_per_lb)
      : Number(rfq.price);
    const priceSeller = useLbForPriceIn
      ? Number(supply.price_per_lb)
      : Number(supply.price);
    const shippingCost =
      Number(supply.shipping_cost) +
      Number(supply.shipping_cost_per_lb) * Number(supply.quantity_lbs);
    const shippingUnitCost = useLbForPriceIn
      ? shippingCost / Number(supply.quantity_lbs)
      : shippingCost / Number(supply.quantity);
    const priceWeightUnits = useLbForPriceIn ? 'lb' : rfq.price_weight_units;
    const logisticsEmissionsMt =
      Number(supply.logistics_carbon_emissions_co2e_mt) || null;
    const materialEmissionsMt =
      Number(supply.material_carbon_emissions_co2e_mt) || null;
    const otherEmissionsMt =
      Number(supply.other_carbon_emissions_co2e_mt) || null;
    const avoidedEmissionsMt =
      Number(supply.avoided_carbon_emissions_co2e_mt) || null;
    const totalEmissionsMt =
      logisticsEmissionsMt + materialEmissionsMt + otherEmissionsMt;
    const avoidedEmissionsPercent =
      (avoidedEmissionsMt &&
        avoidedEmissionsMt / (totalEmissionsMt + avoidedEmissionsMt)) ||
      0;

    return {
      supplyIndex,
      deal: supply.deal,
      currency: supply.currency,
      kpis: mapMerge(
        rfq.material_numerical_properties.map((propertyValue) => ({
          ...propertyValue,
          order: propertyValue.property.order
        })),

        (mnp) => {
          const tdsMnp = findNumericalProperty(tds, mnp.property.code);

          const matchKey = getMatchKey(mnp.uuid);
          const overlap = rangesOverlap(
            [mnp.min, mnp.max],
            [tdsMnp?.min, tdsMnp?.max]
          );
          const matchDefault = overlap == null ? !!overlap : null;
          const match = getSupplyExtra(matchKey, matchDefault);

          const referenceValue = numericRange({
            ...mnp,
            noMinRender: (v) => `< ${v}`,
            noMaxRender: (v) => `> ${v}`
          });
          const value = tdsMnp
            ? numericRange({
                ...tdsMnp,
                noMinRender: (v) => `< ${v}`,
                noMaxRender: (v) => `> ${v}`
              })
            : '';
          const units_suffix = mnp.units ? ` (${mnp.units})` : '';
          const name = `${mnp.property.name}${units_suffix}`;
          return {
            uuid: mnp.property.uuid,
            key: mnp.property.code,
            match,
            referenceValue,
            value,
            name,
            matchKey,
            matchUpdateFunc: getMatchUpdateFunc(matchKey)
          };
        },

        // collect multiple values of the same properties into a single item in the list
        Object.values(
          rfq.material_properties
            ?.filter((mp) => !['type', 'form'].includes(mp.meta_property.code))
            .reduce((acc, propertyValue) => {
              const { code } = propertyValue.meta_property;
              if (!acc[code]) acc[code] = [];
              acc[code].push(propertyValue);
              return acc;
            }, {})
        ).map((propertyValueList) => ({
          list: propertyValueList,
          property: propertyValueList[0].meta_property,
          order: propertyValueList[0].meta_property.order
        })),

        (mp) => {
          const tdsMp = findProperties(tds, mp.property.code)
            ?.map(({ value }) => value)
            .join(', ');

          const matchKey = getMatchKey(mp.list.map((o) => o.uuid));
          const match = getSupplyExtra(matchKey, mp.value === tdsMp);

          let buyerValue = mp.list.map((o) => o.value).join(', ');
          let sellerValue = tdsMp;

          if (mp.property.code === 'color') {
            if (buyerValue === 'Custom' && rfq.color_space_spec)
              buyerValue = renderColorSpec(rfq.color_space_spec);

            if (sellerValue === 'Custom' && tds.color_space_spec)
              sellerValue = renderColorSpec(tds.color_space_spec);
          }
          const referenceValue = buyerValue;
          const value = sellerValue || '';
          const { name } = mp.property;
          return {
            key: mp.property.code,
            uuid: mp.property.uuid,
            match,
            referenceValue,
            name,
            value,
            matchKey,
            matchUpdateFunc: getMatchUpdateFunc(matchKey)
          };
        },
        compareProperties
      ),
      price: {
        label: 'Price',
        match: getSupplyExtra(getMatchKey('price'), priceBuyer >= priceSeller),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('price')),
        referenceValue: (priceBuyer && currencyValuePrice(priceBuyer)) || 'N/A',
        value: `${currencyValuePrice(
          priceSeller,
          rfq.currency
        )} /${priceWeightUnits}`
      },
      location: {
        label: 'Location',
        match: getSupplyExtra(getMatchKey('location'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('location')),
        referenceValue: rfq.shipping_addresses?.[0],
        value: tds.locations?.[0]
      },
      distance: {
        label: 'Shipping Distance',
        match: getSupplyExtra(getMatchKey('distance'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('distance')),
        value: renderDistance(rfq?.shipping_addresses?.[0], tds?.locations?.[0])
      },
      'shipping-cost': {
        label: 'Shipping Costs',
        match: getSupplyExtra(getMatchKey('shipping-cost'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('shipping-cost')),
        value: `${currencyValuePrice(
          shippingUnitCost,
          supply.currency
        )}/${priceWeightUnits} (${prettyNumberRound(
          (100 * shippingUnitCost) / (priceSeller + shippingUnitCost)
        )}% total cost)`
      },
      'logistics-emissions-mt': {
        label: 'Logistics Emissions',
        match: getSupplyExtra(getMatchKey('logistics_emissions'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('logistics_emissions')),
        hide: !logisticsEmissionsMt,
        value: `${prettyNumber(logisticsEmissionsMt)} mt CO2e`
      },
      'material-emissions-mt': {
        label: 'Material Emissions',
        match: getSupplyExtra(getMatchKey('material_emissions'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('material_emissions')),
        hide: !materialEmissionsMt,
        value: `${prettyNumber(materialEmissionsMt)} mt CO2e`
      },
      'other-emissions-mt': {
        label: 'Other Emissions',
        match: getSupplyExtra(getMatchKey('other_emissions'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('other_emissions')),
        hide: !otherEmissionsMt,
        value: `${prettyNumber(otherEmissionsMt)} mt CO2e`
      },
      'total-emissions-mt': {
        label: 'Total Emissions',
        match: getSupplyExtra(getMatchKey('total_emissions'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('total_emissions')),
        hide: !totalEmissionsMt,
        value: `${prettyNumber(totalEmissionsMt)} mt CO2e`
      },
      'avoided-emissions-mt': {
        label: 'Avoided Emissions',
        match: getSupplyExtra(getMatchKey('avoided_emissions'), true),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('avoided_emissions')),
        hide: !avoidedEmissionsMt,
        value: `${prettyNumber(avoidedEmissionsMt)} mt CO2e`
      },
      'avoided-emissions-percent': {
        label: 'Avoided Emissions',
        match: getSupplyExtra(getMatchKey('avoided_emissions_percent'), true),
        matchUpdateFunc: getMatchUpdateFunc(
          getMatchKey('avoided_emissions_percent')
        ),
        hide: !avoidedEmissionsPercent,
        value: `${prettyNumberRound(avoidedEmissionsPercent * 100)} %`
      },
      'annual-capacity': {
        label: 'Annual Capacity',
        match: getSupplyExtra(
          getMatchKey('annual-capacity'),
          tds.capacity >= rfq.quantity
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('annual-capacity')),
        value: tds.capacity
          ? `${prettyNumber(tds.capacity)} ${tds.capacity_units}/yr`
          : ''
      },
      'spot-capacity': {
        label: 'Current Spot Capacity',
        match: getSupplyExtra(
          getMatchKey('spot-capacity'),
          tds.quantity >= rfq.quantity
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('spot-capacity')),
        value: tds.quantity
          ? `${prettyNumber(tds.quantity)} ${tds.quantity_units}`
          : ''
      },
      materials: {
        label: 'Materials',
        match: getSupplyExtra(
          getMatchKey('materials'),
          findProperties(tds, 'type')?.some(
            (p) => p.value === findProperty(rfq, 'type')?.value
          )
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('materials')),
        value: findProperties(tds, 'type')
          ?.map((p) => p.value)
          .join(', ')
      },
      forms: {
        label: 'Forms',
        match: getSupplyExtra(
          getMatchKey('forms'),
          findProperties(tds, 'form')?.some(
            (p) => p.value === findProperty(rfq, 'form')?.value
          )
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('forms')),
        value: findProperties(tds, 'form')
          ?.map((p) => p.value)
          .join(', ')
      },
      'years-in-business': {
        label: 'Years In Business',
        match: getSupplyExtra(
          getMatchKey('years-in-business'),
          !!tds.company.years_in_business || null
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('years-in-business')),
        hide: !tds.company.years_in_business,
        value: tds.company.years_in_business
          ? `${prettyNumberRound(tds.company.years_in_business)} years`
          : ''
      },
      'pcr-percent-by-volume': {
        label: '% Recycled By Volume',
        match: getSupplyExtra(
          getMatchKey('pcr-percent-by-volume'),
          !!tds.percent_pcr_by_volume || null
        ),
        matchUpdateFunc: getMatchUpdateFunc(
          getMatchKey('pcr-percent-by-volume')
        ),
        hide: !tds.percent_pcr_by_volume,
        value: tds.percent_pcr_by_volume
          ? `${prettyNumberRound(tds.percent_pcr_by_volume)}%`
          : ''
      },
      certifications: {
        label: 'Certifications',
        match: getSupplyExtra(
          getMatchKey('certifications'),
          !!tds.company.certifications || null
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('certifications')),
        value: tds.company.certifications?.map((cert) => cert.name).join(', '),
        hide: !tds.company.certifications?.length
      },
      'strategic-vision': {
        label: 'Additional Services',
        match: getSupplyExtra(
          getMatchKey('strategic-vision'),
          !!tds.company.strategic_services || null
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('strategic-vision')),
        value: tds.company.strategic_services,
        hide: !tds.company.strategic_services
      },
      'business-risk': {
        label: 'D&B Business Risk',
        match: getSupplyExtra(
          getMatchKey('business-risk'),
          tds.company.business_risk
            ? riskIsGood[tds.company.business_risk] || false
            : null
        ),
        matchUpdateFunc: getMatchUpdateFunc(getMatchKey('business-risk')),
        value:
          tds.company.business_risk && sentenceCase(tds.company.business_risk),
        hide: !tds.company.business_risk
      }
    };
  };

  return useMemo(
    () =>
      (supplyIn && getSupplyData(supplyIn, undefined, false)) ||
      (Array.isArray(deals) &&
        deals
          .map((deal) =>
            deal.supplies.map((supply, supplyIndex) =>
              getSupplyData(supply, supplyIndex, false)
            )
          )
          .flat()),
    [rfq, supplyIn, showAllProperties, deals]
  );
}
