export const findProperties = (tdsOrRfq, propertyCode) =>
  tdsOrRfq?.material_properties?.filter(
    (o) => o.meta_property.code === propertyCode
  );

export const findPropertiesValues = (tdsOrRfq, propertyCode, join = ' / ') =>
  tdsOrRfq?.material_properties
    ?.filter((o) => o.meta_property.code === propertyCode)
    ?.map((o) => o?.value)
    ?.join(join);

export const findProperty = (tdsOrRfq, propertyCode) =>
  tdsOrRfq?.material_properties?.find(
    (o) => o.meta_property.code === propertyCode
  ); // this returns first match, this needs to be considered

export const findPropertyFromList = (propertyList, propertyCode) =>
  propertyList?.find((o) => o.meta_property.code === propertyCode); // this returns first match, this needs to be considered

export const findPropertyByTypeOption = (
  propertyList,
  propertyCode,
  optionValue
) => {
  const property = propertyList.find((prop) => prop.code === propertyCode);
  return (
    property && property.options.find((option) => option.value === optionValue)
  );
};

export const findNumericalProperty = (tdsOrRfq, propertyCode) =>
  tdsOrRfq?.material_numerical_properties?.find(
    (o) => o.property.code === propertyCode
  ); // this returns first match, this needs to be considered

export const filterOptionByRelationship = (objectIds) => (option) => {
  if (!objectIds || !Array.isArray(objectIds) || objectIds.length === 0) {
    return true;
  }
  // console.log('filterOptionByRelationship', objectIds, option);
  const intersection = option.subject_options
    .map((o) => [o.object_option, o.object_option_code])
    .filter(
      ([optionId, optionCode]) =>
        objectIds.includes(optionId) || objectIds.includes(optionCode)
    );
  return intersection.length > 0;
};

export const applyFilterToPropertyOptions = (
  propertyOptionList,
  filter,
  value
) => {
  const isInValue = (uuidOrCode) => {
    if (Array.isArray(value)) return value.includes(uuidOrCode);
    return value === uuidOrCode;
  };
  const filtered =
    Array.isArray(propertyOptionList) &&
    filter &&
    propertyOptionList.filter(
      (o) => isInValue(o.uuid) || isInValue(o.code) || filter(o)
    );
  // if specified we include the current value regardless of whether it is valid (according to relationships)
  // because without including it in the options it won't display correctly
  // arguably we should reset the value to undefined, but that is not so easy
  if (
    filtered &&
    filtered.length === 1 &&
    (isInValue(filtered[0].uuid) || isInValue(filtered[0].code)) &&
    !filter(filtered[0])
  )
    return propertyOptionList || [];
  return (
    (filtered && filtered.length > 0 && filtered) || propertyOptionList || []
  );
};

export const isTdsFoodGrade = (tds) =>
  tds.material_properties.some((mp) => mp.code === 'food_grade');
