import { Row, Col, Typography, Button, Alert } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParentSize } from '@visx/responsive';

// React PDF
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProject } from 'src/Mutation';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function BuyerDigitalRfq({ project, locked }) {
  const [numPages, setNumPages] = useState();
  const [numPagesCover, setNumPagesCover] = useState();
  const { parentRef, width } = useParentSize({
    initialSize: { width: 750 }
  });

  const queryClient = useQueryClient();
  const { mutate: updateProjectMutation, isLoading: updating } = useMutation({
    mutationFn: updateProject,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['project']);
      queryClient.invalidateQueries(['projects']);
      // navigate(`sent`);
    },
    onError: (e) => {
      // TODO: handle this
    }
  });

  return (
    <div className="buyer-digital-rfq">
      <Row className="mt-lg" justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} className="mb-xs">
            Edit Digital RFQ
          </Typography.Title>
          <Typography.Paragraph>
            Confirm what you want to take to market.
          </Typography.Paragraph>
        </Col>
        {!locked ? (
          <Col>
            <Button
              onClick={() =>
                updateProjectMutation({
                  uuid: project.uuid,
                  stage: 'procure',
                  tdss: []
                })
              }
              type="primary"
              htmlType="button"
              disabled={updating}
            >
              {updating ? 'Loading...' : 'Review & Send Digital RFQ'}
            </Button>
          </Col>
        ) : null}
      </Row>
      <Row className="mb-lg" justify="space-between" align="middle">
        {locked ? (
          <Col span={24}>
            <Alert showIcon message="Your Digital RFQ has been sent." />
          </Col>
        ) : null}
      </Row>
      <div ref={parentRef} style={{ maxWidth: 900 }}>
        <Document
          onLoadSuccess={(data) => setNumPages(data.numPages)}
          file={project?.rfq_file}
          noData={!project ? 'Loading PDF...' : 'No PDF file specified.'}
        >
          {Array(numPages)
            .fill('')
            .map((_, i) => (
              <Page key={i} width={width} pageNumber={i + 1} />
            ))}
        </Document>
      </div>
      <div ref={parentRef} style={{ maxWidth: 900 }}>
        <Document
          onLoadSuccess={(data) => setNumPagesCover(data.numPages)}
          file={project?.cover_letter_file}
          noData={
            project && project.cover_letter_file
              ? 'Loading PDF...'
              : 'No PDF file specified for cover letter.'
          }
        >
          {Array(numPagesCover)
            .fill('')
            .map((_, i) => (
              <Page key={i} width={width} pageNumber={i + 1} />
            ))}
        </Document>
      </div>
    </div>
  );
}
BuyerDigitalRfq.propTypes = {
  project: PropTypes.object,
  locked: PropTypes.bool
};
