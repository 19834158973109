import { fetch } from 'src/utils/client';

export const getSearch = (params) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/search/?${new URLSearchParams(
      params
    ).toString()}`
  );

export const getSearchFiltered = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/technical-data-sheet/search/?${new URLSearchParams(params).toString()}`
  );

export const getProgress = (params) =>
  fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/scrappy-log/progress/?${new URLSearchParams(params).toString()}`
  );
