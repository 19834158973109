import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import { useQuery } from '@tanstack/react-query';
import { getPriceLogs } from 'src/Query';

export default function ProfileHealth() {
  const { supplierId } = useParams();

  const { data } = useGetWarehouseTdss({
    company_id__in: [supplierId],
    is_capability: false,
    source__in: ['supplier_direct'],
    fields: ['updated_at'],
    order_by: '-updated_at'
  });

  const { data: priceLogData } = useQuery({
    queryKey: ['price-log', { tds__company: supplierId }],
    queryFn: () => getPriceLogs({ tds__company: supplierId })
  });

  if (!data || !priceLogData) return null;

  const noTdss = data.count === 0;
  const latestPriceLog =
    priceLogData.results?.length && priceLogData.results[0];

  let health = 'good';
  let headline = 'Profile Up To Date';
  let description =
    'Your materials are actively being surfaced to relevant buyers. Thank you for contributing to Circular!';

  if (noTdss) {
    health = '';
    headline = 'No Material Data';
    description =
      'Your profile will not appear in search without material data. Add materials to get the most out of Circular!';
  } else if (!latestPriceLog) {
    health = 'medium';
    headline = 'No Price Data';
    description =
      'Your profile may appear more in search if you include more historical pricing information and attach certifications to more TDSs.';
  } else {
    const latestPriceLogDate = dayjs(latestPriceLog.created_at);
    const weeksAgo = latestPriceLogDate.diff(new Date(), 'w');
    if (weeksAgo >= 2) {
      health = 'medium';
      headline = 'Stale Data';
      description =
        'Your profile may appear more in search if you include more historical pricing information and attach certifications to more TDSs.';
    }
  }

  return (
    <div className={`profile-health profile-health--${health}`}>
      <Row justify="space-between" wrap={false}>
        <Col>
          <Typography.Title level={5}>Profile Health</Typography.Title>
        </Col>
      </Row>
      <Typography.Paragraph strong>
        <div className="profile-health--indicator" /> {headline}
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        {description}
      </Typography.Paragraph>
    </div>
  );
}
