import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import LightPanel from 'src/components/layout/LightPanel';
import BlockProgress from 'src/components/BlockProgress';
import dayjs from 'dayjs';
import shipmentMap from './demo/shipmentMap.png';

const shipmentProgress = [
  'normal',
  'normal',
  'normal',
  'empty',
  'empty',
  'empty',
  'empty',
  'amber',
  'empty',
  'empty',
  'empty',
  'empty',
  'empty',
  'empty'
];

export default function ManageLogisticsTab({ project, requestForQuote }) {
  const { rfqId } = useParams();

  // const { search: upNextSearch } = useUpNext(requestForQuote, project);

  if (!project || !requestForQuote) return <Skeleton />;
  const nextShipment = dayjs().add(18, 'day').format('MMMM D, YYYY');

  return (
    <div className="logistics-tab">
      <Row gutter={24}>
        <Col span={15}>
          <LightPanel className="shipment">
            <Typography.Text className="xs upper breath">
              Next Shipment
            </Typography.Text>
            <Typography.Text className="xxl breath-sm">
              {nextShipment}
            </Typography.Text>
            <Button type="bare">
              Details <RightOutlined />
            </Button>
            <Divider />
            <Typography.Text className="sm breath">
              <strong>Latest Update</strong> Today 9:23AM EST
            </Typography.Text>
            <img src={shipmentMap} alt="Shipment map" />
          </LightPanel>
        </Col>

        <Col span={9}>
          <LightPanel className="logistics-progress">
            <Typography.Title level={5}>Shipment Progress</Typography.Title>
            <div>
              <BlockProgress value={shipmentProgress} />
              <Typography.Text className="xs upper">
                <strong>Shipments delivered</strong> 3/14
              </Typography.Text>
            </div>
            <br />
            <br />
            <Button type="primary">Manage Shipments</Button>
          </LightPanel>
        </Col>
      </Row>
    </div>
  );
}

ManageLogisticsTab.propTypes = {
  requestForQuote: PropTypes.object,
  project: PropTypes.array
};
