import React from 'react';
import PropTypes from 'prop-types';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';
import { useBuyerName } from './BuyerName';

export default function BuyerAvatar({ buyer }) {
  const name = useBuyerName(buyer);
  const avatarSetting = useConciergeContext(['buyer', 'logos']);

  const src =
    avatarSetting === 'anonymize' ? buyer.anonymized_logo : buyer.avatar;

  if (!src) return null;

  return (
    <img src={src} alt={name} className={`buyer-avatar ${avatarSetting}`} />
  );
}

BuyerAvatar.propTypes = {
  buyer: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    anonymized_logo: PropTypes.string
  })
};
