import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

export default function ProposalComparisonShowHideControl({
  label,
  showHideUpdateFunc
}) {
  return showHideUpdateFunc ? (
    <Dropdown
      menu={{
        items: [
          {
            key: 'show',
            label: 'Always show'
          },
          {
            key: 'hide',
            label: 'Always hide'
          },
          {
            key: 'dynamic',
            label: 'Toggle dynamically'
          }
        ],
        onClick: ({ key }) => showHideUpdateFunc(key)
      }}
    >
      <span>{label}</span>
    </Dropdown>
  ) : (
    label
  );
}
ProposalComparisonShowHideControl.propTypes = {
  label: PropTypes.node,
  showHideUpdateFunc: PropTypes.func
};
