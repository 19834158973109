import falsyNot0 from 'src/components/utils/falsyNot0';

const minVal = (...vals) => Math.min(...vals.filter((val) => !falsyNot0(val)));
const maxVal = (...vals) => Math.max(...vals.filter((val) => !falsyNot0(val)));

export default function rangesOverlap([min1, max1], [min2, max2]) {
  // Either of the ranges has no min nor max
  if (falsyNot0(min1) && falsyNot0(max1) && falsyNot0(min2) && falsyNot0(max2))
    return null;

  // Both have no min
  if (falsyNot0(min1) && falsyNot0(min2)) {
    return [undefined, minVal(max1, max2)];
  }

  // One of the ranges has no min
  if ((falsyNot0(min1) && max1 >= min2) || (falsyNot0(min2) && max2 >= min1)) {
    return [min1 || min2, minVal(max1, max2)];
  }

  // Both have no max
  if (falsyNot0(max1) && falsyNot0(max2)) {
    return [maxVal(min1, min2), undefined];
  }

  // One of the ranges has no max
  if ((falsyNot0(max1) && min1 <= max2) || (falsyNot0(max2) && min2 <= max1)) {
    return [maxVal(min1, min2), max1 || max2];
  }

  // Both ranges have min and max
  if ((min2 <= min1 && max2 >= min1) || (min1 <= min2 && max1 >= min2)) {
    return [maxVal(min1, min2), minVal(max1, max2)];
  }

  // If we get here it means no overlap
  return null;
}
