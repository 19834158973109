import { Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import AnalyticsTickerLineChart from 'src/components/analytics/AnalyticsTickerLineChart';

import './Markets.less';

const materialOptions = [
  {
    label: 'rPET',
    value: 'rPET'
  },
  {
    label: 'rPP',
    value: 'rPP'
  },
  {
    label: 'rHDPE',
    value: 'rHDPE'
  },
  {
    label: 'rLDPE',
    value: 'rLDPE/rLLDPE'
  }
];

const tickersMap = {
  rPET: {
    americas_north: {
      'Resin/Pellets': ['opis.0723'],
      Flakes: ['opis.0889', 'argus.398050001'],
      Bales: ['opis.1172', 'spg.APCLA00', 'spg.APCLB00', 'spg.APCCH00'],
      Virgin: ['opis.131']
    },
    eu: {
      'Resin/Pellets': [
        'argus.372460000',
        'argus.391430000',
        'argus.391420000',
        'spg.ARPGC04',
        'spg.ARPGB04',
        'spg.ARPGA04'
      ],
      Flakes: [
        'argus.391390000',
        'argus.372450000',
        'argus.386800000',
        'argus.391380000',
        'spg.AAVKZ00'
      ],
      Bales: [
        'argus.372380000',
        'argus.372380000',
        'argus.372400001',
        'argus.372370000',
        'argus.372350000',
        'spg.APCBA04'
      ],
      Virgin: ['spg.AAXOD03', 'spg.AACVC00', 'spg.AACWH00', 'spg.AAXOC00']
    },
    asia: {
      'Resin/Pellets': ['argus.392260800'],
      Flakes: ['spg.RPTSE00', 'spg.RPTSF00'],
      Bales: [],
      Virgin: ['spg.AASML03']
    }
  },
  rPP: {
    americas_north: {
      'Resin/Pellets': ['opis.0715'],
      Flakes: ['opis.0840'],
      Bales: ['argus.391110000', 'argus.391120000'],
      Virgin: ['opis.01115']
    },
    eu: {
      'Resin/Pellets': ['spg.ARPEB00', 'argus.372570000'],
      Flakes: [],
      Bales: ['argus.372510000', 'argus.377490000'],
      Virgin: ['spg.PHAHY00', 'spg.PHAHX00', 'spg.HPAND00', 'spg.AAVYS00']
    },
    asia: {
      'Resin/Pellets': [],
      Flakes: [],
      Bales: [],
      Virgin: [
        'spg.PHBII04',
        'spg.PHBIL04',
        'spg.PHBIK00',
        'spg.PHBIJ04',
        'argus.122680000'
      ]
    }
  },
  rHDPE: {
    americas_north: {
      'Resin/Pellets': ['opis.0704'],
      Flakes: ['opis.0835'],
      Bales: ['opis.1169'],
      Virgin: ['opis.01118', 'opis.01134']
    },
    eu: {
      'Resin/Pellets': ['argus.397340000'],
      Flakes: [],
      Bales: ['argus.372500000', 'argus.372490000', 'spg.APCHA00'],
      Virgin: ['spg.AALUD00', 'spg.AAVKE00', 'spg.AALUF00']
    },
    asia: {
      'Resin/Pellets': ['argus.393100800'],
      Flakes: [],
      Bales: ['argus.393090800'],
      Virgin: ['spg.AATYE00']
    }
  },
  'rLDPE/rLLDPE': {
    americas_north: {
      'Resin/Pellets': ['opis.0711'],
      Flakes: ['opis.0837'],
      Bales: ['opis.1183', 'spg.ARNPG00'],
      Virgin: ['opis.01124']
    },
    eu: {
      'Resin/Pellets': ['spg.ARTPA00'],
      Flakes: [],
      Bales: ['spg.ARTPB00'],
      Virgin: ['argus.394070000']
    },
    asia: {
      'Resin/Pellets': ['spg.ARNFA00'],
      Flakes: [],
      Bales: [],
      Virgin: ['spg.PHAHT00', 'spg.PHAGZ00', 'spg.ALDPA00']
    }
  }
};

const regions = [
  ['americas_north', 'North America'],
  ['eu', 'Europe'],
  ['asia', 'Asia']
];

const forms = ['Resin/Pellets', 'Virgin', 'Flakes', 'Bales'];

/* eslint-disable react/no-unstable-nested-components */
export default function Market() {
  const [type, setType] = useState('rPET');

  const { scaleConfig, TimeScaleControl } = useTimeScaleControl();

  return (
    <div id="insights-markets">
      <Row className="markets--header" justify="space-between" align="middle">
        <Col>
          <Select
            showArrow
            showSearch
            value={type}
            onChange={setType}
            options={materialOptions}
            style={{ width: 150 }}
          />
        </Col>
        <Col>{TimeScaleControl}</Col>
      </Row>
      <Row className="markets--body" gutter={[24, 24]}>
        {regions.map(([regionSlug, regionName]) => (
          <Col key={regionSlug} span="12">
            <AnalyticsTickerLineChart
              tileTitle={`${regionName} - ${type} Resin/Pellets`}
              tickers={forms
                .filter(
                  (form) => tickersMap?.[type]?.[regionSlug]?.[form]?.length > 0
                )
                .map((form) => ({
                  title: form,
                  source_id__in: tickersMap[type][regionSlug][form],
                  ...scaleConfig
                }))}
              colorSeed={0}
              allowDownload={false}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export function useTimeScaleControl() {
  const [scale, setScale] = useState('6m');

  const timeScales = {
    '5y': {
      start_date: new Date(new Date().setFullYear(new Date().getFullYear() - 5))
        .toISOString()
        .split('T')[0],
      sample_units: 'weeks'
    },
    '1y': {
      start_date: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        .toISOString()
        .split('T')[0],
      sample_units: 'days'
    },
    '6m': {
      start_date: new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .split('T')[0],
      sample_units: 'days'
    },
    '1m': {
      start_date: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .split('T')[0],
      sample_units: 'days'
    }
  };

  const scaleConfig = timeScales[scale];

  return {
    scaleConfig,
    TimeScaleControl: (
      <div className="markets--time-scale">
        <span>Time Scale:</span>

        {Object.keys(timeScales).map((scaleOption) => (
          <button
            key={scaleOption}
            className={scale === scaleOption ? 'active' : ''}
            onClick={() => setScale(scaleOption)}
            type="button"
          >
            {scaleOption}
          </button>
        ))}
      </div>
    )
  };
}
