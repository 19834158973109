import React, { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { PriceCompare } from 'src/components/project/explore/supplier-list/TdsRow';
import { CurrencyPerWeightUnitsInput } from 'src/components/form/CurrencyUnitsInput';
import { convertWeightUnitsFromTo } from 'src/utils/units';
import { updateProjectTds } from 'src/Mutation';
import ValueInputPopover from 'src/components/project/source/editable-cells/ValueInputPopover';
import {
  updateResultAttribute,
  valueWithCurrencyUnits
} from 'src/components/project/source/editable-cells/helpers';

export default function ProjectPriceCell({
  tds,
  allTdss,
  project,
  rowIndex,
  fieldName,
  setQueryData,
  messageApi
}) {
  const { mutate: updateProjectTdsMutation, isLoading: updating } = useMutation(
    {
      mutationFn: updateProjectTds,
      onSuccess: () => {
        // messageApi.info('Supplier details updated');
      },
      onError: () => {
        messageApi.error('Failed to update supplier details');
      }
    }
  );

  const onPerWeightChange = useCallback(
    (v) => {
      const row = allTdss[rowIndex];
      const attribute = `project_${fieldName}`;
      const value = convertWeightUnitsFromTo(
        parseFloat(v?.value),
        row?.project_weight_units, // inverted
        v?.units
      );
      setQueryData((o) => updateResultAttribute(o, rowIndex, attribute, value));
    },
    [allTdss, rowIndex, fieldName]
  );

  const projectTdsPerWeightMutate = useCallback(
    (v) => {
      const row = allTdss[rowIndex];
      const attribute = `${fieldName}_per_lb`;
      const precision = 1e-8;
      const value =
        Math.round(
          (1 / precision) *
            convertWeightUnitsFromTo(
              parseFloat(v?.value),
              'lb', // inverted
              v?.units
            )
        ) * precision;
      const body = { id: row.project_tds_id };
      body[attribute] = value;
      updateProjectTdsMutation(body);
    },
    [allTdss, rowIndex, fieldName]
  );

  return (
    <ValueInputPopover
      ValueComponent={CurrencyPerWeightUnitsInput}
      onChange={(v) => onPerWeightChange(v)}
      mutate={(v) => projectTdsPerWeightMutate(v)}
      value={valueWithCurrencyUnits(
        tds?.[`project_${fieldName}`],
        tds?.project_currency,
        tds?.project_weight_units
      )}
    >
      <PriceCompare
        value={tds?.[`project_${fieldName}`]}
        target={project[`target_${fieldName}`]}
        currency={project.currency}
        units={project.price_weight_units}
      />
    </ValueInputPopover>
  );
}
ProjectPriceCell.propTypes = {
  tds: PropTypes.object,
  allTdss: PropTypes.array,
  project: PropTypes.object,
  rowIndex: PropTypes.number,
  fieldName: PropTypes.string,
  setQueryData: PropTypes.func,
  messageApi: PropTypes.object
};
