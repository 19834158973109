import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Select, Slider } from 'antd';
import NumberInput from './NumberInput';

const numberToString = (o) => o || '';

// Currently only works as a managed component (onChange and value must be used)
function RangeInput({ value, units, onChange, suffix, showStartEnd, compact }) {
  useEffect(() => {
    if (typeof value === 'string' && value.includes(',')) {
      const [min, max] = value.split(',');
      onChange({ min, max });
    }
  }, [value]);
  const naturalRange = (range) => {
    let max = 10 ** Math.ceil(Math.log10(range[1] * 1.1));
    if (max / range[1] > 3.5) max *= 0.3;
    return [0, max];
  };
  const valueNumber = (value && [
    numeral(value.min).value(),
    numeral(value.max).value()
  ]) || [0.2, 0.8];

  const range = naturalRange(valueNumber) || [0, 1];
  const step = range[1] / 100;
  const onChangeSlider = (v) => {
    onChange({ ...value, min: v[0].toString(), max: v[1].toString() });
  };
  const onChangeMin = (v) => {
    const newValue = { ...value, min: v.target.value };
    onChange(newValue);
  };
  const onChangeMax = (v) => {
    onChange({ ...value, max: v.target.value });
  };
  return (
    <div className={`range-input ${compact ? 'compact' : ''}`}>
      {value?.units || units ? (
        <div className="range-input-units">
          <Select
            className="range-input-units"
            value={value?.units || units}
            disabled
          />
        </div>
      ) : null}
      <div className="range-input-number">
        <NumberInput
          className="range-input-number"
          value={numberToString(value?.min)}
          onChange={onChangeMin}
          suffix={suffix}
        />
      </div>
      {!compact ? (
        <>
          <div className="range-input-label">{showStartEnd && range[0]}</div>
          <Slider
            range
            marks={null}
            step={step}
            min={range[0]}
            max={range[1]}
            onChange={onChangeSlider}
            value={valueNumber}
          />
          <div className="range-input-label">
            {showStartEnd && Math.round(range[1] * 10) / 10}
          </div>
        </>
      ) : (
        <div className="range-input-label">to</div>
      )}
      <div className="range-input-number">
        <NumberInput
          className="range-input-number"
          value={numberToString(value?.max)}
          onChange={onChangeMax}
          suffix={suffix}
        />
      </div>
    </div>
  );
}

/* eslint-disable prefer-promise-reject-errors */

RangeInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showStartEnd: PropTypes.bool,
  compact: PropTypes.bool,
  suffix: PropTypes.string,
  units: PropTypes.string
};

export default RangeInput;
