import React from 'react';
import PropTypes from 'prop-types';

export const EXCELLENT = 'excellent';
export const VERY_GOOD = 'verygood';
export const GOOD = 'good';
export const FAIR = 'fair';
export const POOR = 'poor';
export const NO_DATA = 'nodata';

const Ratings = {
  [EXCELLENT]: { label: 'Excellent', color: '#5CCE8C' },
  [VERY_GOOD]: { label: 'Very good', color: '#A3C862' },
  [GOOD]: { label: 'Good', color: '#F0C234' },
  [FAIR]: { label: 'Fair', color: '#F58658' },
  [POOR]: { label: 'Poor', color: '#EB3097' },
  [NO_DATA]: { label: 'No data', color: '#7D8BA1' }
};

function GoodnessRating({ rating = 'excellent', pill, dark = true }) {
  const ratingCode = rating?.toLocaleLowerCase() || 'excellent';
  return (
    <div
      className={`goodness-rating ${dark ? 'dark' : 'light'} ${
        pill ? 'pill' : ''
      }`}
    >
      <span
        style={{
          backgroundColor: Ratings[ratingCode]?.color || '#888'
        }}
      />
      {Ratings[ratingCode]?.label || ''}
    </div>
  );
}

GoodnessRating.propTypes = {
  rating: PropTypes.oneOf([
    'excellent',
    'verygood',
    'good',
    'fair',
    'poor',
    'nodata'
  ]).isRequired,
  pill: PropTypes.bool,
  dark: PropTypes.bool
};

export default GoodnessRating;
