import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSidebarContext } from 'src/components/layout/sidebar/SidebarContext';
import SidebarCascade, {
  ProjectStep
} from 'src/components/layout/sidebar/SidebarCascade';
import ProjectSourceCascade from 'src/components/layout/sidebar/ProjectSourceCascade';
import { projectSteps } from 'src/components/project/source/helpers';

export default function ProjectSourceWorkflow({
  projectId: projectIdOrPlayground
}) {
  const { pushCascade, setActiveProjectId, activeProject } =
    useSidebarContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const projectId =
    projectIdOrPlayground === 'playground' ? undefined : projectIdOrPlayground;

  const steps = useMemo(() => projectSteps(activeProject), [activeProject]);

  const selectItem = (step) => {
    navigate(`/project/source/${projectId}/${step.key}`);
  };

  const cascadeItems = useMemo(
    () =>
      steps.map((s) => ({
        component: <ProjectStep step={s} />,
        key: s.key,
        active: pathname.includes(`/${projectId}/${s.key}`),
        next: true
      })),
    [steps, projectId, pathname]
  );

  useEffect(() => {
    setActiveProjectId(projectId);
  }, [projectId]);

  return (
    <SidebarCascade
      light
      title={activeProject?.name}
      items={cascadeItems}
      selectItem={selectItem}
      back={{
        label: 'All Source Projects',
        action: () => pushCascade(<ProjectSourceCascade />, 'source')
      }}
    />
  );
}
ProjectSourceWorkflow.propTypes = {
  projectId: PropTypes.string
};
