import React from 'react';
import logo from './Logo-Circular-Symbol.svg';

function CircularLogoSymbol() {
  return (
    <img
      className="circular-logo-symbol"
      src={process.env.REACT_APP_BRAND_LOGO_SYMBOL || logo}
      alt="Logo"
    />
  );
}

export default CircularLogoSymbol;
