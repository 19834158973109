import { Marker } from 'src/pages/maps/Marker';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import MarkerSmallIcon from 'src/components/icons/MarkerSmall';
import MarkerLargeIcon from 'src/components/icons/MarkerLarge';

export function SupplierMarker({
  supplier,
  id,
  map,
  suppliers,
  data,
  handleMarkerClick,
  primary,
  onMouseEnter,
  onMouseLeave,
  color,
  label
}) {
  useEffect(() => () => onMouseLeave());
  return (
    <Marker
      key={id}
      map={map}
      id={id}
      size={22}
      latitude={Number(supplier.lat)}
      longitude={Number(supplier.long)}
      className={primary ? '' : 'black'}
    >
      {primary ? (
        <MarkerLargeIcon
          onClick={() => {
            if (handleMarkerClick)
              handleMarkerClick(map, supplier.lat, supplier.long, data);
          }}
          color={color}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ) : (
        <MarkerSmallIcon
          onClick={() => {
            if (handleMarkerClick)
              handleMarkerClick(map, supplier.lat, supplier.long, data);
          }}
          color={color}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
    </Marker>
  );
}

SupplierMarker.propTypes = {
  supplier: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  map: PropTypes.object,
  data: PropTypes.object,
  suppliers: PropTypes.number.isRequired,
  primary: PropTypes.bool,
  color: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  handleMarkerClick: PropTypes.func
};
