/*
    merges two lists, `list1` and `list2` (each of potentially different objects) 
    by sorting using `compare()`
    and maps each element using `mapFn1` and `mapFn2` which correspond to the two lists.
    An index is passed to each mapFn based on the element's index in the original list.
*/

export const mapMerge = (list1, mapFn1, list2, mapFn2, compare) => {
  const list1Zip = (list1 || []).map((element, index) => ({
    element,
    map: mapFn1,
    index
  }));
  const list2Zip = (list2 || []).map((element, index) => ({
    element,
    map: mapFn2,
    index
  }));
  return [...list1Zip, ...list2Zip]
    .sort((o1, o2) => compare(o1.element, o2.element))
    .map((o) => o.map(o.element, o.index));
};
