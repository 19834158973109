import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';

export function StepList({ steps, box, initial }) {
  return (
    <div className={`process-steps ${box ? 'box' : ''}`}>
      {steps.map((s, i) => (
        <div key={s.title} className="step">
          <div className="step-header">
            <div className={`step-number ${s.complete ? 'complete' : ''}`}>
              {!s.complete ? i + (initial || 1) : <CheckOutlined />}
            </div>
            <div className="step-title-row">
              <div className="step-title">{s.title}</div>
              {s.duration ? (
                <div className="step-duration">{s.duration}</div>
              ) : null}
              {s.icon}
            </div>
          </div>
          <div className="step-content">
            {s.background && (
              <div className="background-row">
                <div className="background-text">{s.background}</div>
                <div className="background-duration">
                  {s.backgroundDuration}
                </div>
                {s.backgroundIcon}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

StepList.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  box: PropTypes.bool,
  initial: PropTypes.number
};
