import { useMemo } from 'react';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export const primaryKpisByMaterial = {
  rpet: ['IV', 'D'],
  rpp: ['MFI', 'D'],
  rhdpe: ['MFI', 'D'],
  rldpe_rlldpe: ['MFI', 'D'],
  DEFAULT: ['MFI', 'D']
};

const usePrimaryKpis = () => {
  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  return useMemo(() => {
    if (!materialType) return [];

    return primaryKpisByMaterial[materialType] || primaryKpisByMaterial.DEFAULT;
  }, [materialType, primaryKpisByMaterial]);
};

export default usePrimaryKpis;
