import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import LandingHeader from 'src/pages/landing/components/LandingHeader';
import LandingFooter from 'src/pages/landing/components/LandingFooter';

function TermsAndConditions() {
  const { Text, Title, Paragraph } = Typography;

  return (
    <>
      <LandingHeader atTopBreakpoint={-1} />

      <div className="terms-and-conditions legal-section">
        <div style={{ textAlign: 'center' }}>
          <Title level={5}>CIRCULAR EXCHANGE, INC.</Title>
          <Title underline level={5}>
            Terms of Service
          </Title>
        </div>
        <Paragraph>Last Updated: Oct 5, 2023</Paragraph>
        <Paragraph>
          <Link target="_blank" to="/terms-old">
            You can see our previous Terms of Service here.
          </Link>
        </Paragraph>
        <Paragraph>
          These Terms of Service (these “<strong>Terms of Service</strong>”)
          constitute a legally binding agreement between you and Circular
          Exchange, Inc. (together with its affiliates,{' '}
          <strong>“Circular”</strong>, <strong>“we”</strong>,{' '}
          <strong>“our”</strong> or <strong>“us”</strong>) governing your use of
          the Circular Platform (as defined below) and our website (the
          <strong>“Site”</strong>). The related services provided by Circular,
          including the Circular Representative (as defined below), to potential
          Suppliers and Customers (each as defined in Section 1) and the Site
          are collectively referred to as the “
          <strong>Circular Platform</strong>”.
        </Paragraph>
        <Paragraph>
          YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “I AGREE” OR
          SIMILAR BUTTON, REGISTERING FOR AN ACCOUNT, OR ACCESSING OR USING THE
          CIRCULAR PLATFORM, YOU ARE INDICATING THAT YOU HAVE READ, UNDERSTAND
          AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE, WHETHER OR NOT YOU
          HAVE REGISTERED WITH THE SITE. IF YOU DO NOT AGREE TO THESE TERMS OF
          SERVICE, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE Circular
          PLATFORM. These Terms are effective as of the date you first click “I
          agree” (or similar button or checkbox) or use or access the Platform,
          whichever is earlier. If you accept or agree to these Terms of Service
          on behalf of your employer or another legal entity, you represent and
          warrant that (i) you have full legal authority to bind your employer
          or such entity to these Terms of Services; (ii) you have read and
          understand these terms of service; and (iii) you agree to these Terms
          of Service on behalf of the party that you represent. In such event,
          “you” and “your” will refer and apply to your employer or such other
          legal entity.
        </Paragraph>
        <Paragraph>
          Any personal data you submit to the Circular Platform or which we
          collect about you is governed by our Privacy Policy (“
          <strong>Privacy Policy</strong>”), available at{' '}
          <Link to="https://circular.co/privacy/" target="_blank">
            https://circular.co/privacy/
          </Link>
          . You acknowledge that by using the Circular Platform you have
          reviewed the Privacy Policy. The Privacy Policy is incorporated by
          reference into these Terms of Service and together form and are
          hereinafter referred to as this “<strong>Agreement</strong>”.
        </Paragraph>
        <Paragraph>
          PLEASE NOTE: THIS AGREEMENT GOVERNS HOW DISPUTES BETWEEN YOU AND
          Circular CAN BE RESOLVED. IT CONTAINS A BINDING AND FINAL ARBITRATION
          PROVISION (SECTION 14). PLEASE READ CAREFULLY AS IT AFFECTS YOUR LEGAL
          RIGHTS, INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF
          ARBITRATION.
        </Paragraph>
        <Title strong level={5}>
          1.&emsp;&emsp;The Circular Platform
        </Title>
        <Paragraph>
          The Circular Platform is a web-based procurement and supply chain
          platform that facilitates end-to-end sales process between companies (
          <strong>“Customers”</strong>) who seek to purchase recycled materials
          (the <strong>“Products”</strong>) and companies who supply such
          materials (<strong>“Suppliers”</strong>) through the provision of the
          following services:
        </Paragraph>
        <ul>
          <li>
            <strong>Search</strong>
            <br />A Customer first submits a specification sheet to the Circular
            Platform, which Circular converts to a digital Request for Quote (
            <strong>“RFQ”</strong>). Circular then uses market data to match the
            Customer with Suppliers based on the RFQ and works with matched
            Suppliers to generate quotes or responses to the RFQ. A member of
            Circular&apos;s team (a “<strong>Circular Representative</strong>”)
            then prepares a proposal (the <strong>“Proposal”</strong>) for the
            Customer for discussion that outlines select Suppliers&apos; initial
            quotes and the terms and scope of the order, together with
            Circular&apos;s analysis and commentary. The Proposal also specifies
            the applicable “Procure” services that Circular will perform for
            order and the applicable fees. If the Customer agrees to the
            Proposal, then Circular and the Customer will enter into an
            agreement for Circular&apos;s “Procure” services.
          </li>
          <li>
            <strong>Procure</strong>
            <br />
            The Circular Representative facilitates testing of the Products,
            Supplier onboarding or compliance vetting, and negotiation of a
            definitive proposal and contract between the Customer and the
            Supplier (a <strong>“Contract”</strong>) as set forth in the
            Proposal, which is managed both on and off of the Circular Platform.
          </li>
          <li>
            <strong>Manage</strong>
            <br />
            Circular provides additional purchasing options and Supplier and
            Product managing tools as set forth on the Circular Platform.
          </li>
        </ul>
        <Paragraph>
          Customers and Suppliers together are hereinafter referred to as{' '}
          <strong>“Users”</strong>. If you agree on the terms of the Contract
          with another User, you and such other User form a binding contract
          directly between the two of you as set forth in more detail in Section
          3 below.
        </Paragraph>
        <Paragraph>
          You agree not to request, make or accept any purchase order or payment
          outside of the Circular Platform to avoid paying fees, taxes or for
          any other reason. You agree not to encourage other Customers or
          Suppliers to take actions outside the Circular Platform in violation
          of these Terms of Service. In the event you purchase the Products
          without engaging Circular in the “Procure” services (a “
          <strong>Direct Purchase</strong>”) from a Supplier that (i) you had
          never purchased from prior to the introduction by Circular and (ii)
          was listed in a Proposal in the 12 months prior to such Direct
          Purchase, then you shall pay Circular a fee equal to 2% of the
          aggregate value of the Direct Purchase within 5 business days of
          receipt of a payment notice from Circular.
        </Paragraph>
        <Paragraph>
          The Circular Platform only enables connection, coordination and
          communication between Users for the fulfillment of the purchase and
          sale of the Products. Circular is not responsible for the performance
          or communications of Users, nor does it have control over the quality,
          timing, legality, failure to provide, or any other aspect whatsoever
          of the Products or Users, nor of the actions or omissions whatsoever
          of any Users. Circular makes no warranties or representations about
          the suitability, reliability, timeliness, or accuracy of the Products
          requested or provided by, or the communications of or between, Users
          identified through the Circular Platform, whether in public, private,
          or offline interactions or otherwise.
        </Paragraph>
        <Paragraph>
          NEITHER SUPPLIERS NOR CUSTOMERS ARE EMPLOYEES, PARTNERS,
          REPRESENTATIVES, AGENTS, JOINT VENTURERS, INDEPENDENT CONTRACTORS OR
          FRANCHISEES OF CIRCULAR. CIRCULAR DOES NOT SUPPLY THE PRODUCTS. USERS
          HEREBY ACKNOWLEDGE THAT CIRCULAR DOES NOT SUPERVISE, DIRECT, CONTROL
          OR MONITOR A SUPPLIER’S PRODUCTS OR RELATED SERVICES AND EXPRESSLY
          DISCLAIMS ANY RESPONSIBILITY AND LIABILITY FOR SUCH PRODUCTS AND
          SERVICES IN ANY MANNER, INCLUDING BUT NOT LIMITED TO WARRANTY OR
          CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR
          COMPLIANCE WITH ANY LAW, REGULATION, OR CODE.
        </Paragraph>
        <Title strong level={5}>
          2.&emsp;&emsp;Account, Password, and Security
        </Title>
        <Paragraph>
          You must register with Circular and create an account to use the
          Circular Platform (an <strong>“Account”</strong>) and as part of that
          process you will be requested to provide certain information,
          including without limitation your name, company name, full address,
          phone number and email address. By using the Circular Platform, you
          agree to provide true, accurate, current and complete information as
          prompted by the registration process and to maintain and promptly
          update the Account information to keep it accurate, current and
          complete. You are the sole authorized user of your Account. You are
          responsible for maintaining the confidentiality of any log-in,
          password, and Account number provided by you or given to you by
          Circular for accessing the Circular Platform. You are solely and fully
          responsible for all activities that occur under your password or
          Account, even if not authorized by you. Circular has no control over
          the use of any User’s Account and expressly disclaims any liability
          derived therefrom. Should you suspect that any unauthorized party may
          be using your password or Account or you suspect any other breach of
          security, you agree to contact Circular immediately.
        </Paragraph>
        <Paragraph>
          The person signing up for the Circular Platform will be the
          contracting party (“<strong>Account Owner</strong>”) for the purposes
          of these Terms of Service and will be the person who is authorized to
          use any corresponding Account we provide to the Account Owner in
          connection with the Circular Platform; provided, however, that if you
          are signing up for the Circular Platform on behalf of your employer,
          your employer shall be the Account Owner. As the Account Owner, you
          are solely responsible for complying with these Terms of Service and
          only you are entitled to all benefits accruing thereto. Your Account
          is not transferable to any other person or account. You must
          immediately notify us of any unauthorized use of your password or
          identification or any other breach or threatened breach of our
          security or the security of your Account.
        </Paragraph>
        <Title strong level={5}>
          3.&emsp;&emsp;Terms of Sale
        </Title>
        <Paragraph>
          All contracts placed on the Circular Platform or otherwise facilitated
          by Circular are governed by the applicable Contract.
        </Paragraph>
        <Paragraph>
          You understand that you are not buying directly from Circular, but
          from the Supplier, or selling directly to Circular, but to the Buyer,
          each an independent party. Circular does not source, manufacture,
          store or inspect the Products sold through the Circular Platform and
          does not guarantee or warrant that the quality of such Products or
          other material purchased or obtained by you will meet your
          expectations. Any legal claim related to a Product you purchase must
          be brought directly against the Supplier. You release Circular from
          any claims related to the Products sold through the Circular Platform,
          including for defective items, misrepresentations by the Supplier, or
          items that caused physical injury (like product liability claims).
        </Paragraph>
        <Paragraph>
          By purchasing a Product or submitting a specification sheet or a
          purchase order on the Circular Platform, such User certifies that it
          has provided accurate Product descriptions, specifications, pricing
          and other material terms. However, typographical errors, inaccuracies
          or omissions relating to such terms may occur. We are not responsible
          if information on the Circular Platform is not accurate, complete or
          current. Any reliance on the Product information is at your own risk.
        </Paragraph>
        <Title strong level={5}>
          4.&emsp;&emsp;Billing and Payment
        </Title>
        <Paragraph>
          Payment for the Products and service fees is made directly from the
          Customer to the Supplier and Circular via the applicable third party
          payment processing system (the <strong>“PSP”</strong>), and not by or
          through Circular. Customers will be solely responsible for paying for
          the Product and Circular is not obligated to pay Supplier for
          Customer&apos;s failure to pay.
        </Paragraph>
        <Paragraph>
          Payment and any other expenses must be paid through the PSP as
          indicated on the Circular Platform. You may be required to register
          with the PSP, agree to terms of service of the PSP, provide your
          payment or remittance details to the PSP and go through a vetting
          process at the request of the PSP to set up an account with the PSP
          (the “<strong>PSP Services Agreement</strong>”). By accepting these
          Terms of Service, each User agrees that they have downloaded or
          printed, and reviewed and agreed to, the PSP Services Agreement.
          Please note that Circular is not a party to the PSP Services Agreement
          and that you, the PSP and any other parties listed in the PSP Services
          Agreement are the parties to the PSP Services Agreement and that
          Circular has no obligations, responsibility or liability to any User
          or any other party under the PSP Services Agreement.
        </Paragraph>
        <Paragraph>
          All amounts paid by Customer to Circular are non-cancelable and
          non-refundable.
        </Paragraph>
        <Title strong level={5}>
          5.&emsp;&emsp;User Generated Content
        </Title>
        <Paragraph>
          “<strong>User Generated Content</strong>” is defined as any content,
          information, and materials that may be textual, audio, or visual that
          you provide, submit, upload, publish, or make otherwise available to
          the Circular Platform and its Users, including without limitation to
          images and information of the Products. You are solely responsible for
          User Generated Content, and we act merely as a passive conduit for
          your online distribution and publication of your User Generated
          Content. You acknowledge and agree that Circular:
        </Paragraph>
        <ul>
          <li>
            Is not involved in the creation or development of User Generated
            Content.
          </li>
          <li>Disclaims any responsibility for User Generated Content.</li>
          <li>
            Cannot be liable for claims arising out of or relating to User
            Generated Content.
          </li>
          <li>
            Is not obligated to monitor, review, or remove User Generated
            Content, but reserves the right to limit or remove User Generated
            Content on the Circular Platform at its sole discretion.
          </li>
        </ul>
        <Paragraph>
          You hereby represent and warrant to Circular that your User Generated
          Content (i) will not be false, inaccurate, incomplete or misleading;
          (ii) will not infringe on any third party’s copyright, patent,
          trademark, trade secret or other proprietary right or rights of
          publicity, personality or privacy; (iii) will not violate any law,
          statute, ordinance, or regulation (including without limitation those
          governing export control, consumer protection, unfair competition,
          anti-discrimination, false advertising, anti-spam or privacy); (iv)
          will not be defamatory, libelous, unlawfully threatening, or
          unlawfully harassing; (v) will not be obscene or contain pornography
          (including but not limited to child pornography) or be harmful to
          minors; (vi) will not contain any viruses, Trojan Horses, worms, time
          bombs, cancelbots or other computer programming routines that are
          intended to damage, detrimentally interfere with, surreptitiously
          intercept or expropriate any system, data or personal information;
          (vii) will not represent you being employed or directly engaged by or
          affiliated with Circular or purport you to act as a representative or
          agent of Circular; and (viii) will not create liability for Circular
          or cause Circular to lose (in whole or in part) the services of its
          ISPs or other suppliers.
        </Paragraph>
        <Paragraph>
          By making available any User Generated Content through the Circular
          Platform, you hereby grant to Circular a worldwide, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free license, with the
          right to sublicense, to use, access, view, copy, adapt, modify,
          distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, stream, broadcast and otherwise exploit such User
          Generated Content on, through or by means of the Circular Platform. We
          do not claim any ownership rights in any such User Generated Content
          and nothing in this Agreement will be deemed to restrict any rights
          that you may have to use and exploit any such User Generated Content.
        </Paragraph>
        <Title strong level={5}>
          6.&emsp;&emsp;Representations and Warranties
        </Title>
        <Paragraph>
          You represent and warrant that: (i) you are 18 years of age or older
          or are at least of the legally required age in the jurisdiction in
          which you reside, and are otherwise capable of entering into binding
          contracts, (ii) you have the right, authority and capacity to enter
          into this Agreement and to abide by the terms and conditions of this
          Agreement, and that you will so abide, and (iii) where you enter into
          this Agreement on behalf of a company or other organization, you
          represent and warrant that you have authority to act on behalf of the
          company or other organization you registered the Account for and to
          bind such entity to this Agreement.
        </Paragraph>
        <Paragraph>
          You further represent and warrant that (i) you have read, understand,
          and agree to be bound by these Terms of Service and the Privacy Policy
          in order to access and use the Circular Platform, (ii) you will
          fulfill the commitments you make to other Users, including paying and
          receiving payment, fulfilling orders agreed upon on the Circular
          Platform, and only utilizing the PSP specified or approved by us to
          make or receive payment on the Circular Platform, (iii) you will act
          professionally and responsibly in your interactions with other Users
          and (iv) when using or accessing the Circular Platform, you will act
          in accordance with any applicable local, state, or federal law or
          custom and in good faith.
        </Paragraph>
        <Paragraph>
          You agree not to engage in any of the following prohibited activities,
          among others: (i) copying, distributing, or disclosing any part of the
          Circular Platform in any medium other than as allowed by the Circular
          Platform and these Terms of Service; (ii) using any automated system
          (other than any functionalities of the Circular Platform), including
          without limitation “robots,” “spiders,” “offline readers,” etc., to
          access the Circular Platform; (iii) transmitting spam, chain letters,
          or other unsolicited email or attempting to phish, pharm, pretext,
          spider, crawl, or scrape; (iv) attempting to interfere with,
          compromise the system integrity or security or decipher any
          transmissions to or from the servers running the Circular Platform;
          (v) violating any international, federal, provincial or state
          regulations, rules, laws, or local ordinances; (vi) conducting any
          unlawful purposes or soliciting others to perform or participate in
          any unlawful acts; (vii) uploading invalid data, viruses, worms, or
          other software agents through the Circular Platform; (viii) infringing
          upon or violate our intellectual property rights or the intellectual
          property rights of others; (ix) impersonating another person or
          otherwise misrepresenting your affiliation with a person or entity,
          conducting fraud, hiding or attempting to hide your identity; (x)
          harassing, insulting, harming, abusing, defaming, abusing, harassing,
          stalking, threatening, intimidating or otherwise violating the legal
          rights (such as of privacy and publicity) of any other users or
          visitors of the Circular Platform or staff member of Circular; (xi)
          interfering with or any activity that threatens the performance,
          security or proper functioning of the Circular Platform; (xii)
          uploading or transmitting viruses or any other type of malicious code;
          (xiii) attempting to decipher, decompile, disassemble or reverse
          engineer any of the software or algorithms used to provide the
          Circular Platform; (xiv) bypassing the security features or measures
          we may use to prevent or restrict access to the Circular Platform,
          including without limitation features that prevent or restrict use or
          copying of any content or enforce limitations on use of the Circular
          Platform or the content therein; (xv) attempting to access
          unauthorized Accounts or to collect or track the personal information
          of others; (xvi) using the Circular Platform for any purpose or in any
          manner that infringes the rights of any third party; or (xvii)
          encouraging or enabling any other individual to do any of the
          foregoing.
        </Paragraph>
        <Paragraph>
          You hereby warrant and represent that, other than as fully and
          promptly disclosed to Circular as set forth below, you do not have any
          motivation, status, or interest which Circular may reasonably wish to
          know about in connection with the Circular Platform, including without
          limitation, if you are using or will or intend to use the Circular
          Platform for any journalistic, investigative, or unlawful purpose. You
          hereby warrant and represent that you will promptly disclose to
          Circular in writing any such motivation, status or interest, whether
          existing prior to registration or as arises during your use of the
          Circular Platform.
        </Paragraph>
        <Title strong level={5}>
          7.&emsp;&emsp;Termination and Suspension
        </Title>
        <Paragraph>
          Unless otherwise agreed to in writing between you and Circular, either
          party may terminate these Terms of Service for any or no cause, at any
          time. You may cancel and delete your Account at any time by either
          using the features on the Circular Platform to do so (if applicable
          and available) or by written notice to{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>. After
          cancellation, you will no longer have access to your Account, your
          profile or any other information through the Circular Platform;
          provided that your access to Search services shall, if applicable,
          continue for the then-current paid term.
        </Paragraph>
        <Paragraph>
          The provisions of these Terms of Service which by their intent or
          meaning intended to survive such termination, including without
          limitation the provisions relating to disclaimer of warranties,
          limitations of liability, and indemnification, shall survive the any
          termination of these Terms of Service and any termination of your use
          of or subscription to the Circular Platform and shall continue to
          apply indefinitely.
        </Paragraph>
        <Paragraph>
          We reserve the right to refuse the Circular Platform to anyone for any
          reason at any time. Circular may terminate or limit your right to use
          the Circular Platform in the event that we are investigating or
          believe that you have breached any provision of this Agreement, by
          providing you with written or email notice. Such termination or
          limitation will be effective immediately upon delivery of such notice.
          If Circular terminates or limits your right to use the Circular
          Platform pursuant to this section, you are prohibited from registering
          and creating a new Account under your name, a fake or borrowed name,
          or the name of any third party, even if you may be acting on behalf of
          the third party.
        </Paragraph>
        <Paragraph>
          Even after your right to use the Circular Platform is terminated or
          limited, this Agreement will remain enforceable against you. Circular
          reserves the right to take appropriate legal action, including but not
          limited to pursuing arbitration in accordance with Section 14 of these
          Terms of Service.
        </Paragraph>
        <Paragraph>
          Circular reserves the right to modify or discontinue, temporarily or
          permanently, all or any portion of the Circular Platform at its sole
          discretion. Circular is not liable to you for any modification or
          discontinuance of all or any portion of the Circular Platform.
          Circular has the right to restrict anyone from completing registration
          as a User if Circular believes such person may threaten the safety and
          integrity of the Circular Platform, or if, in Circular’s discretion,
          such restriction is necessary to address any other reasonable business
          concern.
        </Paragraph>
        <Paragraph>
          Following the termination or cancellation of your Account, we reserve
          the right to delete all your data, including any User Generated
          Content, in the normal course of operation. Your data cannot be
          recovered once your Account is terminated or canceled.
        </Paragraph>
        <Title strong level={5}>
          8.&emsp;&emsp;Links to Third-Party Websites
        </Title>
        <Paragraph>
          The Circular Platform may contain links (such as hyperlinks) to
          third-party websites. Such links do not constitute endorsement by
          Circular or association with those websites, their content or their
          operators. Such links (including without limitation external websites
          that are framed by the Circular Platform as well as any advertisements
          displayed in connection therewith) are provided as an information
          service, for reference and convenience only. Circular does not control
          any such websites, and is not responsible for their (i) availability
          or accuracy, or (ii) content, advertising, products, or services. It
          is your responsibility to evaluate the content and usefulness of the
          information obtained from other websites. You acknowledge and agree
          that Circular is not involved in the creation or development of
          third-party websites and disclaims any responsibility for third-party
          websites, and cannot be liable for claims arising out of or relating
          to third-party websites. Further, you acknowledge and agree that
          Circular has no obligation to monitor, review, or remove links to
          third-party websites, but reserves the right to limit or remove links
          to third-party websites on the Circular Platform at its sole
          discretion.
        </Paragraph>
        <Paragraph>
          The use of any website controlled, owned or operated by third parties
          is governed by the terms and conditions of use and privacy policies
          for those websites. You access such third-party websites at your own
          risk. Circular expressly disclaims any liability arising in connection
          with your use and/or viewing of any websites or other material
          associated with links that may appear on the Circular Platform. You
          hereby agree to hold Circular harmless from any liability that may
          result from the use of links that may appear on the Circular Platform.
        </Paragraph>
        <Title strong level={5}>
          9.&emsp;&emsp;Intellectual Property Rights
        </Title>
        <Paragraph>
          All text, graphics, editorial content, data, formatting, graphs,
          designs, HTML, look and feel, photographs, music, sounds, images,
          software, videos, designs, trademarks, logos, typefaces and other
          content (collectively “<strong>Proprietary Material</strong>”) that
          Users see or read through the Circular Platform is owned by Circular,
          excluding User Generated Content, which Users hereby grant Circular a
          license to use. Proprietary Material is protected in all forms, media
          and technologies now known or hereinafter developed. Circular owns all
          Proprietary Material, as well as the coordination, selection,
          arrangement and enhancement of such Proprietary Materials as a
          Collective Work under the United States Copyright Act, as amended. The
          Proprietary Material is protected by the domestic and international
          laws governing copyright, patents, and other proprietary rights. Users
          may not copy, download, use, redesign, reconfigure, or retransmit
          anything from the Circular Platform without Circular’s express prior
          written consent and, if applicable, the holder of the rights to the
          User Generated Content.
        </Paragraph>
        <Paragraph>
          Any use of such Proprietary Material, other than as permitted therein,
          is expressly prohibited without the prior permission of Circular and,
          if applicable, the holder of the rights to the User Generated Content.
        </Paragraph>
        <Paragraph>
          The service marks and trademarks of Circular, including without
          limitation Circular and Circular logos, are service marks owned by
          Circular.
        </Paragraph>
        <Paragraph>
          Any other trademarks, service marks, logos and/or trade names
          appearing via the Circular Platform are the property of their
          respective owners. You may not copy or use any of these marks, logos
          or trade names without the express prior written consent of the owner.
        </Paragraph>
        <Paragraph>
          Notwithstanding the foregoing, Users grant Circular a nonexclusive,
          non-transferrable, non-sublicensable, license to use, reproduce,
          modify, adapt, publish, translate, and create derivative works from
          such User’s logos, brands, promotional graphics, and related marketing
          designs, as well as such User’s corporate and/or trade name, as
          necessary to (i) market the Products to prospective Customers and (ii)
          facilitate end-to-end sales processes on the Circular Platform.
        </Paragraph>
        <Paragraph>
          Additionally, you may choose to or we may invite you to submit
          comments, ideas, or feedback about the Circular Platform, including
          without limitation about how to improve our services or our products (
          <strong>“Feedback”</strong>). By submitting any Feedback, you agree
          that your disclosure is gratuitous, unsolicited, and without
          restriction and will not place Circular under any fiduciary or other
          obligation, and that we are free to use the Feedback without any
          additional compensation to you, and/or to disclose the Feedback on a
          non-confidential basis or otherwise to anyone. You further acknowledge
          that, by acceptance of your submission, Circular does not waive any
          rights to use similar or related Feedback previously known to
          Circular, developed by its employees, or obtained from sources other
          than you. You acknowledge that all email and other correspondence that
          you submit to us shall become our sole and exclusive property.
        </Paragraph>
        <Title strong level={5}>
          10.&emsp;&emsp;Copyright Complaints and Copyright Agent
        </Title>
        <Paragraph>
          Circular respects the intellectual property of others, and expects
          Users to do the same. If you believe, in good faith, that any
          materials provided on or in connection with the Circular Platform
          infringe upon your copyright or other intellectual property right,
          please send the following information to Circular’s Copyright Agent at{' '}
          <a href="mailto:copyright@circular.co">copyright@circular.co</a>:
        </Paragraph>
        <ul>
          <li>
            A description of the copyrighted work that you claim has been
            infringed, including the URL (Internet address) or other specific
            location on the Circular Platform where the material you claim is
            infringed is located. Include enough information to allow Circular
            to locate the material, and explain why you think an infringement
            has taken place;
          </li>
          <li>
            A description of the location where the original or an authorized
            copy of the copyrighted work exists -- for example, the URL
            (Internet address) where it is posted or the name of the book in
            which it has been published;
          </li>
          <li>Your address, telephone number, and e-mail address;</li>
          <li>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the
            information in your notice is accurate, and that you are the
            copyright owner or authorized to act on the copyright owner&apos;s
            behalf; and
          </li>
          <li>
            An electronic or physical signature of the owner of the copyright or
            the person authorized to act on behalf of the owner of the copyright
            interest.
          </li>
        </ul>
        <Title strong level={5}>
          11.&emsp;&emsp;Confidential Information
        </Title>
        <Paragraph>
          You acknowledge that Confidential Information (as defined below) is a
          valuable, special and unique asset of Circular and agree that you will
          not disclose, transfer, use (or seek to induce others to disclose,
          transfer or use) any Confidential Information for any purpose other
          than using the Circular Platform in accordance with these Terms of
          Service. If relevant, you may disclose the Confidential Information to
          your authorized employees and agents provided that they are also bound
          to maintain the confidentiality of Confidential Information. You shall
          promptly notify Circular in writing of any circumstances that may
          constitute unauthorized disclosure, transfer, or use of Confidential
          Information. You shall use best efforts to protect Confidential
          Information from unauthorized disclosure, transfer or use. You shall
          return all originals and any copies of any and all materials
          containing Confidential Information to Circular upon termination of
          this Agreement for any reason whatsoever.
        </Paragraph>
        <Paragraph>
          The term <strong>“Confidential Information”</strong> shall mean any
          and all of Circular’s trade secrets, confidential and proprietary
          information, and all other information and data of Circular that is
          not generally known to the public or other third parties who could
          derive value, economic or otherwise, from its use or disclosure.
          Confidential Information shall be deemed to include technical data,
          know-how, research, product plans, products, services, customers,
          markets, software, developments, inventions, processes, formulas,
          technology, designs, drawings, engineering, hardware configuration
          information, marketing, finances, strategic and other proprietary and
          confidential information relating to Circular or Circular’s business,
          operations or properties, including information about Circular’s
          staff, Users or partners, or other business information disclosed
          directly or indirectly in writing, orally or by drawings or
          observation.
        </Paragraph>
        <Title strong level={5}>
          12.&emsp;&emsp;Disclaimer of Warranties
        </Title>
        <Paragraph>
          THE CIRCULAR PLATFORM IS PROVIDED ON AN “AS IS” BASIS WITHOUT
          WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. CIRCULAR MAKES NO WARRANTIES OR REPRESENTATIONS
          ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT PROVIDED THROUGH THE
          CIRCULAR PLATFORM OR THE CONTENT OF ANY SITES LINKED TO THE CIRCULAR
          PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY IN CONTRACT,
          WARRANTY OR IN TORT FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
          WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE CIRCULAR
          PLATFORM, (III) ANY ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN; AND (IV) EVENTS BEYOND OUR REASONABLE CONTROL.
        </Paragraph>
        <Paragraph>
          CIRCULAR DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY
          FOR ANY PRODUCT OR SERVICE ADVERTISED ON THE CIRCULAR PLATFORM.
        </Paragraph>
        <Paragraph>
          UNDER NO CIRCUMSTANCES WILL CIRCULAR OR ITS AFFILIATES, INCLUDING
          THEIR RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS, INVESTORS,
          SUBSIDIARIES, ATTORNEYS, REPRESENTATIVES, LICENSORS, INSURERS,
          EMPLOYEES, SUCCESSORS AND ASSIGNS (HEREINAFTER REFERRED TO
          COLLECTIVELY AS “<strong>CIRCULAR AND AFFILIATES</strong>”), OR THEIR
          CORPORATE PARTNERS, BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          ACTUAL, CONSEQUENTIAL, ECONOMIC, SPECIAL OR EXEMPLARY DAMAGES
          (INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF DATA, LOSS OF
          GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE,
          FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR
          TRANSMITTED BY CIRCULAR, OR THE COST OF SUBSTITUTE PRODUCTS OR
          SERVICES) ARISING IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE
          THE CIRCULAR PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF THE SAME.
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT
          APPLY TO YOU IN THEIR ENTIRETY.
        </Paragraph>
        <Paragraph>
          IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT
          CIRCULAR AND AFFILIATES OR THEIR CORPORATE PARTNERS ARE LIABLE FOR
          DAMAGES, IN NO EVENT WILL THE AGGREGATE LIABILITY, WHETHER ARISING IN
          CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EXCEED THE LOWER OF (I)
          THE TOTAL FEES PAID BY YOU TO CIRCULAR DURING THE SIX MONTHS PRIOR TO
          THE TIME SUCH CLAIM AROSE OR (II) ONE HUNDRED DOLLARS ($100), TO THE
          EXTENT PERMITTED BY APPLICABLE LAW.
        </Paragraph>
        <Title strong level={5}>
          13.&emsp;&emsp;Indemnification
        </Title>
        <Paragraph>
          You hereby agree to indemnify, defend, and hold harmless Circular and
          its officers, directors, employees, agents, attorneys, insurers,
          successors and assigns (the “<strong>Indemnified Parties</strong>”)
          from and against any and all Liabilities incurred in connection with
          (i) your use or inability to use the Circular Platform or fulfill a
          purchase or sale order, or (ii) your breach or violation of this
          Agreement; (iii) your violation of any law, or the rights of any User
          or third party and (iv) any content submitted by you or using your
          Account to the Circular Platform, including, but not limited to the
          extent such content may infringe on the intellectual rights of a third
          party or otherwise be illegal or unlawful. You also agree to indemnify
          the Indemnified Parties for any Liabilities resulting from your use of
          software robots, spiders, crawlers, or similar data gathering and
          extraction tools, or any other action you take that imposes an
          unreasonable burden or loan on our infrastructure. Circular reserves
          the right, in its own sole discretion, to assume the exclusive defense
          and control at its own expense of any matter otherwise subject to your
          indemnification. You will not, in any event, settle any claim or
          matter without the prior written consent of Circular.
        </Paragraph>
        <Title strong level={5}>
          14.&emsp;&emsp;Dispute Resolution; Arbitration
        </Title>
        <Paragraph>
          PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND
          GOVERNS HOW YOU AND Circular CAN BRING CLAIMS AGAINST EACH OTHER. THIS
          SECTION WILL, WITH LIMITED EXCEPTION, REQUIRE YOU AND Circular TO
          SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON
          AN INDIVIDUAL BASIS.
        </Paragraph>
        <Paragraph>
          You agree that, in the event any dispute or claim arises out of or
          relating to your use of the Circular Platform, you will contact us at{' '}
          <a href="mailto:support@circular.co">support@circular.co</a> and you
          and Circular will attempt in good faith to negotiate a written
          resolution of the matter directly. You agree that if the matter
          remains unresolved for 30 days after notification (via certified mail
          or personal delivery), such matter will be deemed a “Dispute” as
          defined below. Except for the right to seek injunctive or other
          equitable relief described under the “Binding Arbitration” section
          below, should you file any arbitration claims, or any administrative
          or legal actions without first having attempted to resolve the matter
          by mediation, then you agree that you will not be entitled to recover
          attorneys&apos; fees, even if you may have been entitled to them
          otherwise.
        </Paragraph>
        <Paragraph>
          <strong>Binding Arbitration</strong>. You and Circular agree that any
          dispute, claim or controversy arising out of or relating to this
          Agreement or to your use of the Circular Platform (collectively{' '}
          <strong>“Disputes”</strong>) will be settled by binding arbitration,
          except that each party retains the right to seek injunctive or other
          equitable relief in a court of competent jurisdiction to prevent the
          actual or threatened infringement, misappropriation, or violation of a
          party’s copyrights, trademarks, trade secrets, patents, or other
          intellectual property rights.{' '}
          <strong>
            This means that you and Circular both agree to waive the right to a
            trial by jury.
          </strong>{' '}
          Notwithstanding the foregoing, you may bring a claim against Circular
          in “small claims” court, instead of by arbitration, but only if the
          claim is eligible under the rules of the small claims court and is
          brought in an individual, non-class, and non-representative basis, and
          only for so long as it remains in the small claims court and in an
          individual, non-class, and non-representative basis.
        </Paragraph>
        <Paragraph>
          <strong>Arbitration Administration and Rules</strong>. The arbitration
          will be administered by the American Arbitration Association (
          <strong>“AAA”</strong>) in accordance with the Commercial Arbitration
          Rules and the Supplementary Procedures for Consumer Related Disputes
          (the “<strong>AAA Rules</strong>”) then in effect, except as modified
          by this “Dispute Resolution’ section. (The AAA Rules are available at
          http://www.adr.org or by calling the AAA at 1-800-778-7879).
        </Paragraph>
        <Paragraph>
          <strong>Arbitration Process</strong>. A party who desires to initiate
          the arbitration must provide the other party with a written Demand for
          Arbitration as specified in the AAA Rules. The arbitrator will be
          either a retired judge or an attorney licensed to practice law in the
          state of California and will be selected by the parties from the AAA’s
          roster of arbitrators with relevant experience. If the parties are
          unable to agree upon an arbitrator within seven days of delivery of
          the Demand for Arbitration, then the AAA will appoint the arbitrator
          in accordance with AAA Rules.
        </Paragraph>
        <Paragraph>
          <strong>Arbitration Location and Procedure</strong>. Unless you and
          Circular agree otherwise, the seat of the arbitration shall be in San
          Francisco, California. If your claim does not exceed USD$10,000, then
          the arbitration will be conducted solely on the basis of documents you
          and Circular submit to the arbitrator, unless you request a hearing
          and the arbitrator then determines that a hearing is necessary. If
          your claim exceeds USD$10,000, your right to a hearing will be
          determined by AAA Rules. Subject to AAA Rules, the arbitrator will
          have the discretion to direct a reasonable exchange of information by
          the parties, consistent with the expedited nature of the arbitration.
          Hearings may be conducted by telephone or video conference, if
          requested and agreed to by the parties.
        </Paragraph>
        <Paragraph>
          <strong>Arbitrator’s Decision and Governing Law</strong>. The
          arbitrator shall apply California law consistent with the Federal
          Arbitration Act and applicable statutes of limitations, and shall
          honor claims of privilege recognized by law. The arbitrator will
          render an award within the timeframe specified in the AAA Rules.
          Judgment on the arbitration may be entered in any court having
          jurisdiction thereof. Any award of damages by an arbitrator must be
          consistent with the “Disclaimers and Limitations of Liability” section
          above. The arbitrator may award declaratory or injunctive relief in
          favor of the claimant only to the extent necessary to provide relief
          warranted by the claimant’s individual claim.
        </Paragraph>
        <Paragraph>
          <strong>Fees</strong>. Each party’s responsibility to pay the
          arbitration filing, administrative and arbitrator fees will depend on
          the circumstances of the arbitration and are set forth in the AAA
          Rules.
        </Paragraph>
        <Title strong level={5}>
          15.&emsp;&emsp;Governing Law
        </Title>
        <Paragraph>
          Except as provided in Section 14 or expressly provided in writing
          otherwise, this Agreement and your use of the Circular Platform will
          be governed by, and will be construed under, the laws of the State of
          California, without regard to choice of law principles. This choice of
          law provision is only intended to specify the use of California law to
          interpret this Agreement.
        </Paragraph>
        <Title strong level={5}>
          16.&emsp;&emsp;No Agency; No Employment
        </Title>
        <Paragraph>
          No agency, partnership, joint venture, employer-employee or
          franchiser-franchisee relationship is intended or created by this
          Agreement.
        </Paragraph>
        <Title strong level={5}>
          17.&emsp;&emsp;General Provisions
        </Title>
        <Paragraph>
          Failure by Circular to enforce any provision(s) of this Agreement will
          not be construed as a waiver of any provision or right. This Agreement
          constitutes the complete and exclusive agreement between you and
          Circular with respect to its subject matter, and supersedes and
          governs any and all prior agreements or communications. The provisions
          of this Agreement are intended to be interpreted in a manner which
          makes them valid, legal, and enforceable. In the event any provision
          is found to be partially or wholly invalid, illegal or unenforceable,
          (i) such provision shall be modified or restructured to the extent and
          in the manner necessary to render it valid, legal, and enforceable or,
          (ii) if such provision cannot be so modified or restructured, it shall
          be excised from the Agreement without affecting the validity, legality
          or enforceability of any of the remaining provisions. This Agreement
          may not be assigned or transferred by you without our prior written
          approval. We may assign or transfer this Agreement without your
          consent, including but not limited to assignments: (1) to a parent or
          subsidiary, (2) to an acquirer of assets, or (3) to any other
          successor or acquirer. Any assignment in violation of this section
          shall be null and void. This Agreement will inure to the benefit of
          Circular, its successors and assigns.
        </Paragraph>
        <Title strong level={5}>
          18.&emsp;&emsp;Changes to this Agreement and the Circular Platform
        </Title>
        <Paragraph>
          Circular reserves the right, at its sole and absolute discretion, to
          change, modify, add to, supplement, suspend, discontinue, or delete
          any of the terms and conditions of this Agreement (including these
          Terms of Service and Privacy Policy) and review, improve, modify or
          discontinue, temporarily or permanently, the Circular Platform or any
          content or information through the Circular Platform at any time,
          effective with or without prior notice and without any liability to
          Circular. Circular will endeavor to notify you of material changes by
          email, but will not be liable for any failure to do so. If any future
          changes to this Agreement are unacceptable to you or cause you to no
          longer be in compliance with this Agreement, you must terminate, and
          immediately stop using, the Circular Platform. Your continued use of
          the Circular Platform following any revision to this Agreement
          constitutes your complete and irrevocable acceptance of any and all
          such changes. Circular may also impose limits on certain features or
          restrict your access to part or all of the Circular Platform without
          notice or liability.
        </Paragraph>
        <Title strong level={5}>
          19.&emsp;&emsp;No Rights of Third Parties
        </Title>
        <Paragraph>
          None of the terms of this Agreement are enforceable by any persons who
          are not a party to this Agreement.
        </Paragraph>
        <Title strong level={5}>
          20.&emsp;&emsp;Notices and Consent to Receive Notices Electronically
        </Title>
        <Paragraph>
          You consent to receive any agreements, notices, disclosures and other
          communications (collectively, <strong>“Notices”</strong>) to which
          this Agreement refers electronically including without limitation by
          e-mail or by posting Notices on this Site. You agree that all Notices
          that we provide to you electronically satisfy any legal requirement
          that such communications be in writing. Unless otherwise specified in
          this Agreement, all notices under this Agreement will be in writing
          and will be deemed to have been duly given when received, if
          personally delivered or sent by certified or registered mail, return
          receipt requested; when receipt is electronically confirmed, if
          transmitted by facsimile or email; or the day after it is sent, if
          sent for next day delivery by a recognized overnight delivery service.
        </Paragraph>
        <Title strong level={5}>
          21.&emsp;&emsp;Contacting Us
        </Title>
        <Paragraph>
          If you have any questions about these Terms of Service or about the
          Circular Platform, please contact us by email at{' '}
          <a href="mailto:support@circular.co">support@circular.co</a>.
        </Paragraph>
      </div>
      <LandingFooter />
    </>
  );
}

export default TermsAndConditions;
