import { useEffect, useState } from 'react';

function useAtTop(scrollBreakpoint = 10) {
  const [atTop, setAtTop] = useState(true);

  const scrollDown = () => {
    if (window.scrollY > scrollBreakpoint) {
      setAtTop(false);
    } else {
      setAtTop(true);
    }
  };

  useEffect(() => {
    scrollDown();
    window.addEventListener('scroll', scrollDown);
    return () => window.removeEventListener('scroll', scrollDown);
  }, []);

  return atTop;
}

export default useAtTop;
