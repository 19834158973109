import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

export default function MarkdownEditableText({ onChange, text, ...props }) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  if (editing)
    return (
      <textarea
        rows={8}
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.metaKey && e.key === 'Enter') {
            onChange(value);
            setEditing(false);
          }
        }}
      />
    );

  return (
    <Typography.Text
      onDoubleClick={() => (onChange ? setEditing(true) : null)}
      {...props}
    >
      {text ? (
        <ReactMarkdown className="react-markdown">
          {text.replace(/\n/g, '  \n')}
        </ReactMarkdown>
      ) : (
        '(no content)'
      )}
    </Typography.Text>
  );
}
MarkdownEditableText.propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string
};
