import React from 'react';
import PropTypes from 'prop-types';

function BulletList({ children }) {
  return <ul className="bullet-list">{children}</ul>;
}

BulletList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

function Item({ icon, children }) {
  const Icon = icon;
  return (
    <li className="bullet-list-item">
      <Icon className="bullet-list-icon"></Icon>
      {children}
    </li>
  );
}

Item.propTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

BulletList.Item = Item;

export default BulletList;
