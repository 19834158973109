import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spin } from 'antd';
import { sentenceCase } from 'change-case';

/**
 * `<MutationStatus/>` shows both the loading and error information for a `useMutation()`
 *
 * These props are intended to come directly from `useMutation()`:
 *   - error
 *   - isLoading
 *
 * Overtime we may want to support more, but it is best for us to standardise on a small number
 *
 * `title` is the name of the data being loaded, for example "TDS", for use in messages like "An error occurred saving the *TDS*"
 */

function Error({ title, error }) {
  const parsedErrors =
    (error.detail &&
      Object.keys(error.detail).map(
        (k) => `${sentenceCase(k)}: ${JSON.stringify(error.detail[k])}`
      )) ||
    [];
  return (
    <Alert
      message={`An error occurred while updating your ${title}.`}
      description={parsedErrors.map((errorItem) => (
        <div key={errorItem}>{errorItem}</div>
      ))}
      type="error"
      closable
    />
  );
}

Error.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object
};

export function MutationStatus({ title, error, isLoading, spin }) {
  return (
    <div className="query-status">
      {error && <Error title={title} error={error} />}
      {spin && isLoading && <Spin />}
    </div>
  );
}

MutationStatus.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object,
  spin: PropTypes.bool,
  isLoading: PropTypes.bool
};
