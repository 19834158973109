import React from 'react';
import PropTypes from 'prop-types';

import optimalShippingRoute from 'src/utils/optimalShippingRoute';

export const locationObjectPropTypes = PropTypes.shape({
  latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  country: PropTypes.string,
  state: PropTypes.string
});

export default function ShippingMode({
  possibleDestinations,
  possibleOrigins
}) {
  const [origin, destination] = optimalShippingRoute(
    possibleOrigins,
    possibleDestinations
  );

  const subtitle =
    destination?.country && origin?.country === destination.country
      ? 'Domestic'
      : 'International';

  return (
    <>
      <span>Truck</span>
      <span className="subcontent">
        {origin && destination ? subtitle : '(Unknown)'}
      </span>
    </>
  );
}
ShippingMode.propTypes = {
  possibleDestinations: PropTypes.arrayOf(locationObjectPropTypes),
  possibleOrigins: PropTypes.arrayOf(locationObjectPropTypes)
};
