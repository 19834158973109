import React from 'react';
import PropTypes from 'prop-types';
import { LinkOutlined } from '@ant-design/icons';
import { useUser } from 'src/utils/authentication';
import { isConcierge, isDealPitch } from 'src/utils/permissions';
import { useConciergeContext } from 'src/components/concierge/ConciergeContext';

export default function AdminConsoleLink({ app, type, uuid, children }) {
  const { data: user } = useUser();

  const viewAsGuest = useConciergeContext('viewAsGuest');

  if (!isConcierge(user) || isDealPitch(user)) return children || null;

  const url = `${process.env.REACT_APP_CIRCLE_API}/admin/${app}/${type}/${uuid}/`;
  return (
    <a
      className={`admin-console-link ${children ? 'wrapper' : 'icon'}`}
      href={url}
      target="_blank"
    >
      {children || <LinkOutlined />}
    </a>
  );
}

AdminConsoleLink.propTypes = {
  app: PropTypes.string,
  type: PropTypes.string,
  uuid: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
