import React, { useMemo } from 'react';
/* eslint-disable import/no-unresolved */
import { Radar } from 'react-chartjs-2';
/* eslint-enable import/no-unresolved */

import { getKpiAnalytics } from 'src/Query/warehouse';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import falsyNot0 from 'src/components/utils/falsyNot0';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

// Convert a numeric value to a value bw lowerBound - upperBound based on where
// they land relative to a min and max value
const scaleValue = (val, lowerBound, upperBound, minValue, maxValue) => {
  const diff = maxValue - minValue;
  const valDiff = val - minValue;

  const boundsDiff = upperBound - lowerBound;

  return lowerBound + (valDiff / diff) * boundsDiff;
};

export default function SpecSpiderChart() {
  const filters = useRelevantFiltersForForm();

  // By storing these values in a separate object we can compare against avail
  // universe without necessarily filtering on them
  // const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);

  const { kpis: kpiFilters, ...filtersWithoutKpis } = filters;
  const { data, isLoading } = useDebouncedQuery(
    ['warehouse', 'kpi-analytics', filtersWithoutKpis],
    () => getKpiAnalytics(filtersWithoutKpis)
  );

  const loadingStateClass = useLoadingStateClass(isLoading);

  const chartData = useMemo(() => {
    if (!data) return { labels: [], datasets: [] };

    const filteredKpiCodes = Object.keys(kpiFilters || {});
    const kpisSortedByMostUsed = mostUsedKpisFromAnalytics(
      data,
      filteredKpiCodes
    );
    const labels = [
      ...filteredKpiCodes,
      ...kpisSortedByMostUsed.slice(0, 5 - Math.min(filteredKpiCodes.length, 5))
    ];

    const kpiAvailableRanges = labels.map((kpiCode) => {
      let smallestMin;
      let largestMax;

      data[kpiCode]?.values.forEach((kpiObj) => {
        let { min, max } = kpiObj;

        if (!min && max) min = max;
        if (!max && min) max = min;

        if (min) {
          if (smallestMin === undefined) smallestMin = min;
          else smallestMin = Math.min(smallestMin, min);
        }

        if (max) {
          if (largestMax === undefined) largestMax = max;
          else largestMax = Math.max(largestMax, max);
        }
      });

      return [smallestMin, largestMax];
    });

    const validRangeConfig = {
      label: '',
      borderWidth: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 0,
      // Create a circle using Bezier curves given x amount of points
      tension: (4 / 3) * Math.tan(Math.PI / (2 * labels.length))
    };

    const filteredValueConfig = {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: '#2F54EB',
      pointBorderWidth: 0,
      pointHoverBorderWidth: 0,
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBackgroundColor: '#2F54EB'
    };

    return {
      labels,
      datasets: [
        {
          data: labels.map((kpiCode, i) => {
            const kpiFilterMin = kpiFilters?.[kpiCode]?.min;
            if (falsyNot0(kpiFilterMin)) return null;

            const scaledValue = scaleValue(
              kpiFilterMin,
              0.3,
              0.7,
              ...kpiAvailableRanges[i]
            );

            if (scaledValue < 0) return 0;
            if (scaledValue > 1) return 1;

            return scaledValue;
          }),
          ...filteredValueConfig
        },
        {
          data: labels.map((kpiCode, i) => {
            const kpiFilterMax = kpiFilters?.[kpiCode]?.max;
            if (falsyNot0(kpiFilterMax)) return null;

            const scaledValue = scaleValue(
              kpiFilterMax,
              0.3,
              0.7,
              ...kpiAvailableRanges[i]
            );

            if (scaledValue < 0) return 0;
            if (scaledValue > 1) return 1;

            return scaledValue;
          }),
          ...filteredValueConfig
        },
        {
          ...validRangeConfig,
          data: Array(labels.length).fill(0.3),
          backgroundColor: 'transparent'
        },
        {
          ...validRangeConfig,
          data: Array(labels.length).fill(0.7),
          backgroundColor: 'rgba(210, 220, 235, 0.4)',
          fill: 2
        },
        // Make the chart appear hollow in the middle
        {
          ...validRangeConfig,
          data: Array(labels.length).fill(-0.01),
          backgroundColor: 'white'
        }
      ]
    };
  }, [data, kpiFilters]);

  return (
    <ExplorePanel
      className={loadingStateClass}
      pad={18}
      style={{
        boxSizing: 'border-box',
        height: '100%'
      }}
    >
      <Radar
        data={chartData}
        options={{
          scales: {
            r: {
              min: -0.2,
              max: 1,
              beginAtZero: false,
              ticks: {
                display: false,
                stepSize: 0.1
              },
              grid: {
                circular: true
              }
              // angleLines: {
              //   borderDashOffset: -15,
              //   borderDash: [0, 0, 0, 0, 100]
              // }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Spec Summary'
            },
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }
        }}
      />
    </ExplorePanel>
  );
}

export function mostUsedKpisFromAnalytics(
  kpiAnalyticsResponse,
  excludeKpis = []
) {
  return Object.keys(kpiAnalyticsResponse)
    .filter((kpiCode) => !excludeKpis.includes(kpiCode))
    .sort((kpiA, kpiB) => {
      const tdsCountA = kpiAnalyticsResponse[kpiA].tds_count;
      const tdsCountB = kpiAnalyticsResponse[kpiB].tds_count;
      return tdsCountB - tdsCountA;
    });
}
